import React, { useState, useContext } from "react";
import ResumeSuccess from "./ResumeSuccess";
import { useHttpClient } from "../../../../Components/Hooks/http-hook";
import { AuthContext } from "../../../../context/auth-context";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router";
import WarnSvg from "../../assets/svgs/WarnSvg";
import ProfileProgressChart from "../../components/ProfileProgressChart";

const CreateResume = ({
  templates,
  selectedTemplate,
  templateData,
  setTemplateData,
  finalTemplate,
  generatePDF,
  generateDOCX,
  pdfRef,
  setEditMode,
}) => {
  const templateImage = templates.find(
    (templates) => templates.id === selectedTemplate
  ).image;

  const [showSuccess, setShowSuccess] = useState(false);
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const handleClick = () => {
    sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/resume/generate/${selectedTemplate}`,
      "POST",
      null,
      {
        "Content-Type": "application/json",
        "Refresh-Token": auth.refresh_token,
        Authorization: auth.token,
      }
    )
      .then((res) => {
        if (res && res.data) {
          setTemplateData(res.data);
          setShowSuccess(true);
        }
      })
      .catch((err) => {
        toast.error("Error generating resume");
      });
  };

  return showSuccess ? (
    <ResumeSuccess
      templateImage={templateImage}
      finalTemplate={finalTemplate}
      generatePDF={generatePDF}
      generateDOCX={generateDOCX}
      pdfRef={pdfRef}
      setEditMode={setEditMode}
    />
  ) : (
    <>
      <div className="section-title">Generate Resume</div>

      <div className="section-content">
        <div className="create-resume-container">
          <div style={{ maxWidth: "40%" }}>
            <div className="create-resume-details">
              <div className="content-title">Create Resume</div>
              <div className="content-title-desc">
                Using our simple resume builder, you can stand out from the
                competition and land your ideal job right now.
              </div>
              <div className="generate-resume-button" onClick={handleClick}>
                Generate Resume
              </div>
            </div>
            <div className="gen-resume-incomplete-resume">
              <div className="gen-resume-alert-container">
                <WarnSvg />
                <div className="gen-resume-alert-message">
                  <span className="gen-resume-alert-text">
                    Pending data, to generate this resume template update your
                    profile page with all required information.
                  </span>
                  <br />
                  <div onClick=
                    {() => navigate("/profile")}
                   className="gen-resume-alert-link gen-resume-alert-text">
                    Click here
                  </div>
                  <span className="gen-resume-alert-text"> to view profile page.</span>
                </div>
              </div>
              <div className="gen-resume-missing-data">
                <b>Incomplete/Required data:</b>
                <ul>
                  <li>Education</li>
                  <li>Experience</li>
                  <li>Skills</li>
                </ul>
              </div>
              <ProfileProgressChart />
            </div>
          </div>
          <div>
            <img
              className="template-image"
              src={templateImage}
              alt="template"
            />
          </div>
        </div>
      </div>
      {isLoading && (
        <div className="loading-modal">
          <div className="loading-ring" />
        </div>
      )}
    </>
  );
};

export default CreateResume;
