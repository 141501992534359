import React from "react";
import PopUp from "../UiComponents/Popup/PopUp";
import "./ViewCourse.css";

const ViewCourse = ({ course, showPopup, setShowPopup }) => {
  return course && (
  <PopUp
    title={course.course_name}
    onClose={() => setShowPopup(false)}
    show={showPopup}
  >
    <div className="course_popup__content">
      <div className="course_popup__content-row">
        <div className="course_popup__content-row-title">
          <p>Course ID</p>
        </div>
        <div className="course_popup__content-row-value">
          <p>{course.course_id}</p>
        </div>
      </div>
      <div className="course_popup__content-row">
        <div className="course_popup__content-row-title">
          <p>Course Name</p>
        </div>
        <div className="course_popup__content-row-value">
          <p>{course.course_name}</p>
        </div>
      </div>
      <div className="course_popup__content-row">
        <div className="course_popup__content-row-title">
          <p>Course Description</p>
        </div>
        <div className="course_popup__content-row-value">
          <p>{course.course_description}</p>
        </div>
      </div>
      <div className="course_popup__content-row">
        <div className="course_popup__content-row-title">
          <p>Is Active</p>
        </div>
        <div className="course_popup__content-row-value">
          <p>{course.active_tag ? "No" : "Yes"}</p>
        </div>
      </div>
      <div className="course_popup__content-row">
        <div className="course_popup__content-row-title">
          <p>Start Date</p>
        </div>
        <div className="course_popup__content-row-value">
          <p>{course.course_start_date}</p>
        </div>
      </div>
      <div className="course_popup__content-row">
        <div className="course_popup__content-row-title">
          <p>Created On</p>
        </div>
        <div className="course_popup__content-row-value">
          <p>{course.course_create_timestamp}</p>
        </div>
      </div>
      <div className="course_popup__content-row">
        <div className="course_popup__content-row-title">
          <p>Last Updated</p>
        </div>
        <div className="course_popup__content-row-value">
          <p>{course.course_update_timestamp}</p>
        </div>
      </div>
    </div>
  </PopUp>
);
}

export default ViewCourse;
