import React from "react";

const CutSvg = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.87868 11.9971L7.56215 9.68054C7.08222 9.88434 6.55427 9.99707 6 9.99707C3.79086 9.99707 2 8.20621 2 5.99707C2 3.78793 3.79086 1.99707 6 1.99707C8.20914 1.99707 10 3.78793 10 5.99707C10 6.55135 9.88726 7.07929 9.68347 7.55922L12 9.87575L18.3744 3.50139C19.1554 2.72034 20.4217 2.72034 21.2028 3.50139L21.9099 4.20849L9.68347 16.435C9.88726 16.9149 10 17.4428 10 17.9971C10 20.2062 8.20914 21.9971 6 21.9971C3.79086 21.9971 2 20.2062 2 17.9971C2 15.788 3.79086 13.9971 6 13.9971C6.55427 13.9971 7.08222 14.1098 7.56215 14.3136L9.87868 11.9971ZM6 7.99707C7.10457 7.99707 8 7.10164 8 5.99707C8 4.8925 7.10457 3.99707 6 3.99707C4.89543 3.99707 4 4.8925 4 5.99707C4 7.10164 4.89543 7.99707 6 7.99707ZM6 19.9971C7.10457 19.9971 8 19.1017 8 17.9971C8 16.8925 7.10457 15.9971 6 15.9971C4.89543 15.9971 4 16.8925 4 17.9971C4 19.1017 4.89543 19.9971 6 19.9971ZM15.5346 13.4104L21.9099 19.7857L21.2028 20.4928C20.4217 21.2738 19.1554 21.2738 18.3744 20.4928L13.4133 15.5317L15.5346 13.4104ZM16 10.9971H18V12.9971H16V10.9971ZM20 10.9971H22V12.9971H20V10.9971ZM6 10.9971H8V12.9971H6V10.9971ZM2 10.9971H4V12.9971H2V10.9971Z" fill="#777777" />
        </svg>
    );
}

export default CutSvg;