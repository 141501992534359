import React from "react";
import { useState, useEffect } from "react";
import Input from "../../../../../Components/Profile/Input/Input";
import Button from "../../../../../Components/Profile/Button/Button";
import { aggregateType } from "../../Data/SelectData";
import Popup from "../../../../../Components/Profile/Popup/PopUp";
import Loading from "../../../../../Components/Loading";

const Matriculation = ({
  type,
  title,
  HandleSubmitFunction,
  onClose,
  show,
  isEdit = false,
  editEducationData,
}) => {
  const [matriculationDetails, setMatriculationDetails] = useState({
    institution: "",
    board: "",
    year_of_completion: "",
    marks_type: "",
    marks_obtained: "",
    max_marks: "",
  });

  const [errors, setErrors] = useState({
    institution: false,
    board: false,
    year_of_completion: false,
    marks_type: false,
    marks_obtained: false,
    max_marks: false,
  });

  const [isFormValid, setIsFormValid] = useState(false);
  const [isFormFieldsEmpty, setIsFormFieldsEmpty] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isEdit) {
      setMatriculationDetails({ ...editEducationData });
    }
  }, [isEdit, editEducationData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setMatriculationDetails({ ...matriculationDetails, [name]: value });
    setErrors({ ...errors, [name]: false });
  };

  const handleSelectChange = (selectedOption) => {
    setMatriculationDetails({
      ...matriculationDetails,
      marks_type: selectedOption.value,
    });
    setErrors({ ...errors, marks_type: false });
  };

  const validateForm = () => {
    const isFormValid = Object.values(errors).every((x) => x === false);
    setIsFormValid(isFormValid);
  };

  const validateFormField = () => {
    const isFormFieldEmpty = Object.values(matriculationDetails).some(
      (x) => x === ""
    );
    const hasErrors = Object.values(errors).some((x) => x === true);
    setIsFormFieldsEmpty(isFormFieldEmpty || hasErrors);
  };

  useEffect(() => {
    validateFormField();
  }, [matriculationDetails, errors]);

  const submitForm = async (e) => {
    setLoading(true);
    validateForm();
    e.preventDefault();
    try {
      matriculationDetails.type = type;
      matriculationDetails.degree = "Class X";
      await HandleSubmitFunction(matriculationDetails);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Popup title={title} onClose={onClose} show={show}>
      <div className="profile-education-form">
        <div className="profile-education-form-section">
          <Input
            width="100%"
            name="institution"
            label="School Name"
            inputPlaceholderValue="Enter your school name"
            value={
              matriculationDetails.institution !== ""
                ? matriculationDetails.institution
                : ""
            }
            onChangeFunction={handleInputChange}
          />
        </div>
        <div className="profile-education-form-section">
          <Input
            width="90%"
            name="board"
            label="Board"
            inputPlaceholderValue="Enter your board name"
            value={
              matriculationDetails.board !== ""
                ? matriculationDetails.board
                : ""
            }
            onChangeFunction={handleInputChange}
          />
          <Input
            width="90%"
            name="year_of_completion"
            label="Completion Year"
            inputPlaceholderValue="Enter your completion year"
            value={
              matriculationDetails.year_of_completion !== ""
                ? matriculationDetails.year_of_completion
                : ""
            }
            onChangeFunction={handleInputChange}
          />
        </div>
        <div className="profile-education-form-section">
          <Input
            width="60%"
            name="marks_type"
            label="Marks Type"
            isSelect={true}
            selectData={aggregateType}
            selectLabel="Marks Type"
            onChangeFunction={handleSelectChange}
            isNoInputinSelect={true}
            selectvalue={
              matriculationDetails.marks_type? matriculationDetails.marks_type : ""
            }
          />
          <Input
            width="82%"
            name="marks_obtained"
            label="Aggregate"
            inputPlaceholderValue="Enter your marks"
            value={
              matriculationDetails.marks_obtained !== ""
                ? matriculationDetails.marks_obtained
                : ""
            }
            onChangeFunction={handleInputChange}
          />
          <Input
            width="82%"
            name="max_marks"
            label="Max Marks"
            inputPlaceholderValue="Enter your max marks"
            value={
              matriculationDetails.max_marks !== ""
                ? matriculationDetails.max_marks
                : ""
            }
            onChangeFunction={handleInputChange}
          />
        </div>
        <div className="profile-education-form-section-button">
          {loading ? (
            <div className="profile-delete-loading-container">
              <Loading color="#14144e" />
            </div>
          ) : (
            <Button
              text="SAVE"
              onClick={isFormFieldsEmpty ? null : submitForm}
              disabled={isFormFieldsEmpty}
            />
          )}
        </div>
      </div>
    </Popup>
  );
};

export default Matriculation;
