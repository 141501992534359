import React from "react";
import PopUp from "../UiComponents/Popup/PopUp";
import "./ViewModule.css";

const ViewModule = ({ module, showPopup, setShowPopup }) => (
  <PopUp
    title={module.module_name}
    onClose={() => setShowPopup(false)}
    show={showPopup}
  >
    <div className="module_popup__content">
      <div className="module_popup__content-row">
        <div className="module_popup__content-row-title">
          <p>Module ID</p>
        </div>
        <div className="module_popup__content-row-value">
          <p>{module.module_id}</p>
        </div>
      </div>
      <div className="module_popup__content-row">
        <div className="module_popup__content-row-title">
          <p>Module Name</p>
        </div>
        <div className="module_popup__content-row-value">
          <p>{module.module_name}</p>
        </div>
      </div>
      <div className="module_popup__content-row">
        <div className="module_popup__content-row-title">
          <p>Module Description</p>
        </div>
        <div className="module_popup__content-row-value">
          <p>{module.module_description}</p>
        </div>
      </div>
      <div className="module_popup__content-row">
        <div className="module_popup__content-row-title">
          <p>Is Active</p>
        </div>
        <div className="module_popup__content-row-value">
          <p>{module.module_lock ? "No" : "Yes"}</p>
        </div>
      </div>
      <div className="module_popup__content-row">
        <div className="module_popup__content-row-title">
          <p>Module Order</p>
        </div>
        <div className="module_popup__content-row-value">
          <p>{module.module_order || "N/A"}</p>
        </div>
      </div>
      <div className="module_popup__content-row">
        <div className="module_popup__content-row-title">
          <p>Created On</p>
        </div>
        <div className="module_popup__content-row-value">
          <p>{module.module_create_timestamp}</p>
        </div>
      </div>
      <div className="module_popup__content-row">
        <div className="module_popup__content-row-title">
          <p>Last Updated</p>
        </div>
        <div className="module_popup__content-row-value">
          <p>{module.module_update_timestamp}</p>
        </div>
      </div>
    </div>
  </PopUp>
);

export default ViewModule;
