import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useNavigate } from "react-router-dom";
import ProgramCard from './Components/Cards/ProgramCard';
import './Courses.css';
import { useHttpClient } from '../../../../../Components/Hooks/http-hook';
import { AuthContext } from '../../../../../context/auth-context';
import Button from '../../../../../Components/Profile/Button/Button';
import Loading from '../../../../../Components/Loading';

const Courses = () => {
    const [courseInfoList, setCourseInfoList] = useState([]);
    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const auth = useContext(AuthContext);
    const enroll_info = auth.course_ids_info;
    const course_ids = useMemo(() => enroll_info.map((item) => item.program_id), [enroll_info]);

    const programTitles = async () => {
        try {
            const responseData = await sendRequest(
                `${process.env.REACT_APP_BACKEND_URL}/db/prog-titles`,
                "POST",
                JSON.stringify({ course_ids }),
                {
                    "Content-Type": "application/json",
                    "Refresh-Token": auth.refresh_token,
                    Authorization: auth.token,
                }
            );
            if (responseData.ok) {
                return responseData.data;
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        const fetchCourseInfoList = async () => {
            try {
                const responseData = await programTitles();
                setCourseInfoList(responseData);
            } catch (err) { }
        };
        fetchCourseInfoList();
    }, [sendRequest, auth.refresh_token, auth.token]);

    const history = useNavigate();

    const courseHandler = (item1, item2) => {
        history(`/skillbox-v2/${item1}/${item2}`);
    };

    return (
        <>
            {(isLoading || error) && (
                <div className="st-skillbox-programs-mn-ct-section">
                    {isLoading && (
                        <Loading color="#004c73" customClass="st-cls-interview-loading" />
                    )}
                    {error && <div className="interview-questions-not-found-section">
                        {error}
                    </div>}
                </div>
            )}
            <div className="st-skillbox-programs-mn-ct-section">
                <div className="st-skillbox-programs-mn-ct">
                    {courseInfoList && courseInfoList.length > 0 && courseInfoList.map((p, i) => (
                            <div key={i}>
                                <ProgramCard program={p} handleClick={courseHandler} />
                            </div>
                        ))}
                </div>
            </div>
        </>
    );
};

export default Courses;
