import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { FaWhatsapp } from "react-icons/fa";
import { MdDoubleArrow, MdEmail, MdCall } from "react-icons/md";
import { MdContentCopy } from "react-icons/md";
import { BsDownload } from "react-icons/bs";
import "./CourseManagement/Courses.css";
import { useHttpClient } from "../../Components/Hooks/http-hook";
import { AdminContext } from "../../context/admin-context";
import DataTable from "react-data-table-component";
import PopUp from "./CourseManagement/UiComponents/Popup/PopUp";
import { data } from "jquery";

const columns = [
  {
    name: "Name",
    selector: (row) => row.name,
    sortable: true,
  },
  {
    name: "Email",
    selector: (row) => row.email,
    sortable: true,
  },
  {
    name: "Mobile",
    selector: (row) => row.mobile,
    sortable: true,
  },
  {
    name: "Branch",
    selector: (row) => row.branch,
    sortable: true,
  },
  {
    name: "Year",
    selector: (row) => row.year,
    sortable: true,
  },
  {
    name: "College",
    selector: (row) => row.college_name,
    sortable: true,
  },
  {
    name: "City",
    selector: (row) => row.college_city,
    sortable: true,
  },
  {
    name: "Created At",
    selector: (row) => row.create_timestamp,
    sortable: true,
  },
];

const FreeCourseEnquiry = () => {
  const [freeCourseEnquiryLeads, setFreeCourseEnquiryLeads] = useState([]);
  const [showJson, setShowJson] = useState(false);
  const [jsonData, setJsonData] = useState([]);
  const [fileDetails, setFileDetails] = useState({});
  const admin = useContext(AdminContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const {isLoading: isLoading1, error: error1, sendRequest: sendRequest1, clearError: clearError1} = useHttpClient();

  const getFreeCourseEnquiryLeads = async () => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/db/free-course-enquiry`,
        "GET",
        null,
        {
          "Content-Type": "application/json",
          Authorization: admin.token,
          "Refresh-Token": admin.refresh_token,
        }
      );
      if (responseData.ok) {
        return responseData.data;
      }
    } catch (err) {
      console.log("Got error while getting Free Course Enquiry Leads", err);
    }
  };

  const viewJson = (data) => {
    setShowJson(true);
    setJsonData(data);
  };

  const handleCopy = (data) => {
    const jsonString = JSON.stringify(data, null, 2);
    navigator.clipboard
      .writeText(jsonString)
      .then(() => {
        alert("JSON data copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy JSON data: ", err);
      });
  };

  const downloadCSV = async () => {
    try {
      const responseData = await sendRequest1(
        `${process.env.REACT_APP_BACKEND_URL}/db/free-course-enquiry-info`,
        "GET",
        null,
        {
          "Content-Type": "application/json",
          Authorization: admin.token,
          "Refresh-Token": admin.refresh_token,
        }
      );
      if (responseData.ok) {
        return responseData.data;
      }
    } catch (err) {
      console.log("got error while downloading registration info", err);
    }
  };

  useEffect(() => {
    const fetchFreeCourseEnquiryLeads = async () => {
      const leads = await getFreeCourseEnquiryLeads();
      if (leads) {
        setFreeCourseEnquiryLeads(leads);
      }
    };
    fetchFreeCourseEnquiryLeads();

    downloadCSV().then((response1) => setFileDetails(response1));
  }, []);

  const ExpandedComponent = ({ data }) => (
    <div className="course-management-admin-listing-expanded">
      <a
        href={`tel:${data.mobile}`}
        className="course-management-admin-listing-expanded-button"
        target="_blank"
      >
        <MdCall className="course-management-admin-listing-expanded-icon" />
        Call
      </a>
      <a
        href={`https://wa.me/${data.mobile}`}
        className="course-management-admin-listing-expanded-button"
        target="_blank"
      >
        <FaWhatsapp className="course-management-admin-listing-expanded-icon" />
        Whatsapp
      </a>
      <a
        href={`mailto:${data.email}`}
        className="course-management-admin-listing-expanded-button"
        target="_blank"
      >
        <MdEmail className="course-management-admin-listing-expanded-icon" />
        Email
      </a>
      <a
        className="course-management-admin-listing-expanded-button"
        onClick={() => viewJson(data)}
      >
        View Data in JSON
      </a>
    </div>
  );

  const ActionsComponent = ({ fileDetails }) => {
    return (
      <div className="course-management-admin-listing-title-button-container">
        <a download={fileDetails.filename} href={fileDetails.base64}>
          <button className="course-management-admin-listing-title-button">
            Download CSV
            <BsDownload />
          </button>
        </a>
      </div>
    );
  };

  return (
    <div className="course-management-admin">
      {showJson && (
        <PopUp
          title="JSON Data"
          onClose={() => setShowJson(false)}
          show={showJson}
        >
          <div className="course-management-admin-listing-title-button-container">
            <pre>
              <code>{JSON.stringify(jsonData, null, 2)}</code>
            </pre>
            <button onClick={() => handleCopy(jsonData)}>
              Copy Data <MdContentCopy />
            </button>
          </div>
        </PopUp>
      )}
      <div className="course-management-admin-listing-table">
        <DataTable
          title={
            <div className="course-management-admin-listing-title">
              <div className="course-management-admin-listing-title-text">
                <MdDoubleArrow className="course-management-table-title-icon" />
                <h3>Free Course Enquiry</h3>
              </div>
            </div>
          }
          width="80%"
          columns={columns}
          data={freeCourseEnquiryLeads}
          showGridlines
          filterDisplay="row"
          pagination
          highlightOnHover
          pointerOnHover
          defaultSortFieldId={1}
          actions={<ActionsComponent fileDetails={fileDetails} />}
          expandableRows
          expandableRowsHideExpander
          expandableRowsComponent={ExpandedComponent}
          expandOnRowClicked
          progressPending={isLoading}
          noDataComponent={
            <div className="course-management-admin-listing-no-data">
              No Data Found
            </div>
          }
        />
      </div>
    </div>
  );
};

export default FreeCourseEnquiry;
