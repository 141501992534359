import React, { useState } from 'react'
import { useNavigate, useParams } from "react-router-dom";

import './InstructorCourse.css'
import ScheduleManagement from '../Home/ScheduleManagement'
import UnderProgress from '../miscellaneous/UnderProgress';
import { GrUserSettings, GrDatabase } from "react-icons/gr";

const InstructorCourse = () => {
    let { cid, title } = useParams()

    const [instructorSec, setInstructorSec] = useState("Meetings Management");

    const handleInstructorSection = (item) => {
        setInstructorSec(item);
    };
      
  return (
    <div className='instructor-course'>
      <div className="admin-left-div">
        <hr />
        <div
          onClick={() => handleInstructorSection("Meetings Management")}
          className="admin-left-sec"
        >
          <GrUserSettings style={{ marginRight: "1rem", fontSize: "1.3rem" }} />
          Meetings
        </div>
        <hr />
        <div
          onClick={() => handleInstructorSection("Assignments Management")}
          className="admin-left-sec"
        >
          <GrDatabase style={{ marginRight: "1rem" }} />
          Assignments
        </div>
        <hr />
      </div>
      <hr />
      <div className="admin-right-div">
        {instructorSec == "Meetings Management" && <ScheduleManagement  courseId = {title}/>}
        {instructorSec=="Assignments Management" && <UnderProgress/>}
      </div>
    </div>
  )
}

export default InstructorCourse