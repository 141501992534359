import React from "react";

const SendMessageSvg = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.9468 9.31555C1.42426 9.14137 1.42013 8.86034 1.95755 8.6812L21.0437 2.31913C21.5722 2.14297 21.8753 2.43878 21.7272 2.95706L16.274 22.0433C16.123 22.5717 15.8184 22.5901 15.5951 22.0877L12.0006 14.0001L18.0006 6.00017L10.0007 12.0001L1.9468 9.31555Z" fill="#004C73" />
        </svg>
    )
}

export default SendMessageSvg;
