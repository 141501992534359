import React from "react";
import "./Loading.css"

const Loading = ({color  = "#fff", customClass = "loading"}) => {
    return (
        <div className={customClass}>
            <div className="loading-ring" style={
                color ? {
                    borderColor: color,
                    borderLeftColor: "transparent"
                } : null
            }></div>
        </div>
    );
    }

export default Loading;