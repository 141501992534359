import React from "react";

const FaqDownArrowSvg = () => {
  return (
    <svg
      width="15"
      height="9"
      viewBox="0 0 15 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.7032 1.78341L8.05409 8.77188C7.97493 8.85483 7.88918 8.91372 7.79683 8.94856C7.70449 8.9834 7.60554 9.00054 7.5 8.99999C7.39446 8.99999 7.29551 8.98257 7.20317 8.94773C7.11082 8.91289 7.02507 8.85427 6.94591 8.77188L0.277045 1.78341C0.0923483 1.58986 9.12501e-08 1.34792 9.47121e-08 1.0576C9.81742e-08 0.767281 0.0989447 0.518434 0.296834 0.311061C0.494723 0.103687 0.725594 9.62327e-07 0.989446 9.65473e-07C1.2533 9.6862e-07 1.48417 0.103687 1.68206 0.311061L7.5 6.40783L13.3179 0.311061C13.5026 0.117513 13.7303 0.0207378 14.0011 0.0207378C14.2718 0.0207378 14.5058 0.124425 14.7032 0.331798C14.9011 0.53917 15 0.781105 15 1.0576C15 1.3341 14.9011 1.57604 14.7032 1.78341Z"
        fill="#464646"
      />
    </svg>
  );
};

export default FaqDownArrowSvg;
