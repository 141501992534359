import React from "react";

const HistorySvg = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 1.5C13.1421 1.5 16.5 4.85786 16.5 9C16.5 13.1421 13.1421 16.5 9 16.5C7.7235 16.5 6.52144 16.1811 5.46922 15.6186L1.5 16.5L2.38222 12.5323C1.81921 11.4797 1.5 10.2771 1.5 9C1.5 4.85786 4.85786 1.5 9 1.5ZM9 3C5.68629 3 3 5.68629 3 9C3 10.001 3.24422 10.9636 3.7049 11.8248L3.96714 12.3151L3.47584 14.5247L5.68642 14.0338L6.17638 14.2958C7.03736 14.756 7.99942 15 9 15C12.3137 15 15 12.3137 15 9C15 5.68629 12.3137 3 9 3ZM9.75 5.25V9H12.75V10.5H8.25V5.25H9.75Z"
        fill="white"
      />
    </svg>
  );
};

export default HistorySvg;
