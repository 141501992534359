import React from "react";
import UnderDevelopment from "../Construction/UnderDevelopment";
import InterviewPreperation from "../Layout/InterviewPreperation/InterviewPreperation";

export const SkillboxSideBarData = [
  {
    title: "Mock Interview",
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.5 15.85C7.32761 15.85 7.16228 15.9185 7.04038 16.0404C6.91848 16.1623 6.85 16.3276 6.85 16.5C6.85 16.6724 6.91848 16.8377 7.04038 16.9596C7.16228 17.0815 7.32761 17.15 7.5 17.15H16.5C16.6724 17.15 16.8377 17.0815 16.9596 16.9596C17.0815 16.8377 17.15 16.6724 17.15 16.5C17.15 16.3276 17.0815 16.1623 16.9596 16.0404C16.8377 15.9185 16.6724 15.85 16.5 15.85H7.5ZM7.04038 18.0404C6.91848 18.1623 6.85 18.3276 6.85 18.5C6.85 18.6724 6.91848 18.8377 7.04038 18.9596C7.16228 19.0815 7.32761 19.15 7.5 19.15H13.5C13.6724 19.15 13.8377 19.0815 13.9596 18.9596C14.0815 18.8377 14.15 18.6724 14.15 18.5C14.15 18.3276 14.0815 18.1623 13.9596 18.0404C13.8377 17.9185 13.6724 17.85 13.5 17.85H7.5C7.32761 17.85 7.16228 17.9185 7.04038 18.0404Z"
          fill="#004C73"
          stroke="#434343"
          strokeWidth="0.3"
        />
        <path
          d="M10.6 7.74988L10.6 7.7499L12.4707 12.2392C12.4741 12.247 12.4773 12.255 12.4804 12.263L13.1 13.7499C13.1663 13.909 13.1668 14.0879 13.1012 14.2474C13.0356 14.4068 12.9094 14.5337 12.7503 14.6C12.5912 14.6664 12.4122 14.6669 12.2528 14.6013C12.0933 14.5357 11.9665 14.4095 11.9001 14.2503L11.9001 14.2503L11.4415 13.1501H8.55853L8.1 14.2503L8.09998 14.2503C8.03361 14.4095 7.90675 14.5357 7.74731 14.6013C7.58787 14.6669 7.40891 14.6664 7.24979 14.6C7.09068 14.5337 6.96445 14.4068 6.89887 14.2474C6.83329 14.0879 6.83373 13.909 6.9001 13.7499M10.6 7.74988L7.03854 13.8076M10.6 7.74988C10.5506 7.63146 10.4673 7.53029 10.3605 7.45913C10.2538 7.38797 10.1283 7.35 10 7.35C9.87173 7.35 9.7463 7.38797 9.63954 7.45913C9.53278 7.53029 9.44946 7.63146 9.40009 7.74988L9.40008 7.7499L7.52939 12.2392M10.6 7.74988L7.52939 12.2392M6.9001 13.7499L7.03854 13.8076M6.9001 13.7499L6.90008 13.7499L7.03854 13.8076M6.9001 13.7499L7.51971 12.263M7.03854 13.8076L7.65904 12.3186M7.51971 12.263C7.51941 12.2638 7.5191 12.2646 7.5188 12.2654L7.65904 12.3186M7.51971 12.263C7.52278 12.255 7.52601 12.247 7.52939 12.2392M7.51971 12.263L7.52058 12.2609L7.65904 12.3186M7.65904 12.3186L7.52939 12.2392M9.10004 11.8501L10 9.6901L10.9 11.8501H9.10004Z"
          fill="#004C73"
          stroke="#434343"
          strokeWidth="0.3"
        />
        <path
          d="M15.4596 10.5404C15.3377 10.4185 15.1724 10.35 15 10.35C14.8276 10.35 14.6623 10.4185 14.5404 10.5404C14.4185 10.6623 14.35 10.8276 14.35 11V11.85H13.5C13.3276 11.85 13.1623 11.9185 13.0404 12.0404C12.9185 12.1623 12.85 12.3276 12.85 12.5C12.85 12.6724 12.9185 12.8377 13.0404 12.9596C13.1623 13.0815 13.3276 13.15 13.5 13.15H14.35V14C14.35 14.1724 14.4185 14.3377 14.5404 14.4596C14.6623 14.5815 14.8276 14.65 15 14.65C15.1724 14.65 15.3377 14.5815 15.4596 14.4596C15.5815 14.3377 15.65 14.1724 15.65 14V13.15H16.5C16.6724 13.15 16.8377 13.0815 16.9596 12.9596C17.0815 12.8377 17.15 12.6724 17.15 12.5C17.15 12.3276 17.0815 12.1623 16.9596 12.0404C16.8377 11.9185 16.6724 11.85 16.5 11.85H15.65V11C15.65 10.8276 15.5815 10.6623 15.4596 10.5404Z"
          fill="#004C73"
          stroke="#434343"
          strokeWidth="0.3"
        />
        <path
          d="M19.15 7.5V7.44201L19.111 7.3991L14.111 1.8991L14.0664 1.85H14H7C6.42978 1.85 5.88292 2.07652 5.47972 2.47972C5.07652 2.88292 4.85 3.42978 4.85 4V20C4.85 20.5702 5.07652 21.1171 5.47972 21.5203C5.88292 21.9235 6.42978 22.15 7 22.15H17C17.5702 22.15 18.1171 21.9235 18.5203 21.5203C18.9235 21.1171 19.15 20.5702 19.15 20V7.5ZM13.35 7.5C13.35 7.67239 13.4185 7.83772 13.5404 7.95962C13.6623 8.08152 13.8276 8.15 14 8.15H17.85V20C17.85 20.2254 17.7604 20.4416 17.601 20.601C17.4416 20.7604 17.2254 20.85 17 20.85H7C6.77457 20.85 6.55837 20.7604 6.39896 20.601C6.23955 20.4416 6.15 20.2254 6.15 20V4C6.15 3.77457 6.23955 3.55837 6.39896 3.39896C6.55837 3.23955 6.77457 3.15 7 3.15H13.35V7.5ZM14.65 6.85V4.4245L16.8549 6.85H14.65Z"
          fill="#004C73"
          stroke="#434343"
          strokeWidth="0.3"
        />
      </svg>
    ),
    component: InterviewPreperation
  }
];
