import React from "react";
import Matriculation from "../forms/education/Matriculation";
import Intermediate from "../forms/education/Intermediate";
import Bachelors from "../forms/education/Bachelors";
import Masters from "../forms/education/Masters";
import Diploma from "../forms/education/Diploma";
import Seminar from "../forms/achievement/Seminar";
import Workshop from "../forms/achievement/Workshop";
import Publication from "../forms/achievement/Publication";
import POS from "../forms/achievement/POS";
import Achievements from "../forms/achievement/Achievements";


export const phoneExtension = [
    { value: "+91", label: "+91 - India" },
    { value: "+1", label: "+1 - USA" },
    { value: "+44", label: "+44 - UK" },
];

export const SocialMediaLinks = [
    { value: "facebook", label: "Facebook" },
    { value: "twitter", label: "Twitter" },
    { value: "instagram", label: "Instagram" },
    { value: "linkedin", label: "Linkedin" },
    { value: "github", label: "Github" },
    { value: "medium", label: "Medium" },
    { value: "youtube", label: "Youtube" },
    { value: "website", label: "Website" },
    { value: "blog", label: "Blog" },
    { value: "other", label: "Other" },
];

export const EducationType = [
    { value: "10th", label: "10th Board Exam" , component: Matriculation },
    { value: "12th", label: "12th Board Exam" , component: Intermediate },
    { value: "bachelors", label: "Bachelor's Degree" , component: Bachelors },
    { value: "masters", label: "Master's Degree" , component: Masters},
    { value: "diploma", label: "Diploma" , component: Diploma},
];

export const aggregateType = [
    { value: "percentage", label: "Percentage" },
    { value: "cgpa", label: "CGPA" },
];

export const AchievementType = [
    { value: "publication", label: "Publication" , component: Publication},
    { value: "workshop", label: "Workshop" , component: Workshop},
    { value: "seminar", label: "Seminar", component: Seminar},
    { value: "positionOfResponsibility", label: "Position of Responsibility", component: POS},
    { value: "achievements", label: "Achievements", component: Achievements}
];

export const WorkType = [
    { value: "full_time", label: "Full Time" },
    { value: "part_time", label: "Part Time" },
    { value: "internship", label: "Internship" },
];

export const ProjectType = [
    { value: "btech", label: "B.Tech Final Year Project" },
    { value: "surge", label: "Surge Program Project" },
    { value: "internship", label: "Internship Based Project" },
    { value: "academic", label: "Academic Project" },
    { value: "others", label: "Others" },
];