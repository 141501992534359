import React, { useEffect, useContext, useState, useRef } from "react";
import AddIcon from '@material-ui/icons/Add';

import "./MessagePopup.css";
import { AuthContext } from "../../context/auth-context";
import { AdminContext } from "../../context/admin-context";
import { useHttpClient } from "../../Components/Hooks/http-hook";

const MessagePopup = ({ showMessagePopup, queryId, setShowMessagePopup,resolveQuery }) => {
  const [query, setQuery] = useState({});
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [newMessage, setNewMessage] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const messagesEndRef = useRef(null);
  const [sendingMessage, setSendingMessage] = useState(false);
  const [loadingMessages, setLoadingMessages] = useState(false);
  const [isResolved, setIsResolved] = useState(false);

  const scrollToBottom = () => {
    messagesEndRef &&
      messagesEndRef.current &&
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(scrollToBottom, [query]);

  const auth = useContext(AuthContext);
  const admin = useContext(AdminContext);

  useEffect(() => {
    if (admin.isLoggedIn) setIsAdmin(true);
    else setIsAdmin(false);
  }, [admin.isLoggedIn]);

  useEffect(() => {
    const fetchQuery = async () => {
      try {
        setLoadingMessages(true);
        if (!queryId) return;
        let url = `${process.env.REACT_APP_BACKEND_URL}/auth/${
          isAdmin ? "admin" : "user"
        }/queries/${queryId}`;
        const responseData = await sendRequest(url, "GET", null, {
          "Content-Type": "application/json",
          "Refresh-Token": isAdmin ? admin.refresh_token : auth.refresh_token,
          Authorization: isAdmin ? admin.token : auth.token,
        });
        setLoadingMessages(false);
        setQuery(responseData.query);
        setIsResolved(responseData.query.status == "resolved" ? true : false);
      } catch (err) {}
    };
    fetchQuery();
  }, [queryId]);

  const handleSubmitMessage = async (event) => {
    event.preventDefault();
    setSendingMessage(true);
    let messageData = {
      message: newMessage,
    };
    messageData = JSON.stringify(messageData);
    try {
      let url = `${process.env.REACT_APP_BACKEND_URL}/auth/${
        isAdmin ? "admin" : "user"
      }/queries/chat/${queryId}`;
      const responseData = await sendRequest(url, "POST", messageData, {
        "Content-Type": "application/json",
        "Refresh-Token": isAdmin ? admin.refresh_token : auth.refresh_token,
        Authorization: isAdmin ? admin.token : auth.token,
      });
      setSendingMessage(false);
      let newQuery = query;
      newQuery.query_messages.push({
        message: newMessage,
        sender: isAdmin ? "admin" : "student",
        sendtime: new Date().toDateString(),
      });
      setQuery(newQuery);
      setNewMessage("");
      scrollToBottom();
    } catch (err) {}
  };

  const handleResolve = async (event) => {
    event.preventDefault();
    try {
      let url = `${process.env.REACT_APP_BACKEND_URL}/auth/user/queries/resolve/${queryId}`;
      const responseData = await sendRequest(url, "PATCH", null, {
        "Content-Type": "application/json",
        "Refresh-Token": auth.refresh_token,
        Authorization: auth.token,
      });
      setIsResolved(true);
      resolveQuery(queryId);
    } catch (err) {}
  };

  const handleDownload = () => {
    const bytes = atob(query.query_zip_path.base64);
    let length = bytes.length;
    let out = new Uint8Array(length);

    while (length--) {
      out[length] = bytes.charCodeAt(length);
    }

    let blob = new Blob([out], { type: "application/pdf" });
    // const blob = base64toBlob(fileDetails.base64)
    console.log(blob, "blob");
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    const filename = query.query_zip_path.filename;
    console.log(filename);
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    console.log(url);
    link.click();
  };

  const [zoomLevel, setZoomLevel] = React.useState(1);

  const handleZoomIn = () => {
    if (zoomLevel < 3) {
      setZoomLevel(zoomLevel + 0.1);
    }
  }

  return (
    <>
      {/* Query Form Popup */}
      {showMessagePopup && (
        <div className="popup">
          <div className="popup__content">
            <div className="ticker-header">
              <h2>
                Ticket #{queryId}
              </h2>
                {/* resolve button */}
              {!isResolved && !isAdmin && (
                <button className="resolve-btn" onClick={handleResolve}>
                  Resolve
                </button>
              )}
              {isResolved && <p>Ticket is resolved</p>}
            </div>
            <div
              className="close-btn"
              onClick={() => setShowMessagePopup(false)}
            >
              X
            </div>

            {/* message list */}
            <div className="message-list">
              <div className="attachments">
                {
                  query &&
                    query.query_image_path &&
                    query.query_image_path.map((file, i) => {
                      return (
                        <div >
                          <img
                            style={{ width: "100%", height: "400px", transform: `scale(${zoomLevel})` }}
                            key={file.filename}
                            src={`data:image/jpeg;base64,${file.base64}`}
                          />
                          {/* <div className="close-btn-zoom" onClick={handleZoomIn}>
                            Zoom in
                          </div> */}
                        </div>
                      );
                    })
                  // <a href={query.query_image_path} target="_blank">
                  //   <img src={query.query_image_path} className="queryImg" />
                  // </a>
                }
                <br />
                {query && query.query_zip_path && (
                  <button onClick={handleDownload}>Download files</button>
                )}
              </div>
              {loadingMessages && <p>Loading...</p>}
              {!loadingMessages &&
                query &&
                query.query_messages &&
                query.query_messages.map((message, index) => {
                  return (
                    <div
                      className={`message ${
                        message.sender == "admin" && isAdmin
                          ? "message__sender"
                          : message.sender == "student" && !isAdmin
                          ? "message__sender"
                          : "message__receiver"
                      }`}
                      key={index}
                    >
                      <div
                        className={`message__content ${
                          message.sender == "admin" && isAdmin
                            ? "message__sender__text"
                            : message.sender == "student" && !isAdmin
                            ? "message__sender__text"
                            : "message__receiver__text"
                        }`}
                      >
                        <div className="message__content__text">
                          {message.message}
                        </div>
                        <div className="message__content__time">
                          {message.sendtime}
                        </div>
                      </div>
                    </div>
                  );
                })}
              <div ref={messagesEndRef} />
            </div>
            {/* message form */}
            
            {!isResolved && (
              <form
                style={{ backgroundColor: "#eef6fc", height: "130px" }}
                onSubmit={handleSubmitMessage}
              >
                <label htmlFor="message">Message:</label>
                <textarea
                  type="text"
                  id="message"
                  rows={5}
                  cols={10}
                  value={newMessage}
                  readOnly={sendingMessage || loadingMessages}
                  required
                  onChange={(event) => setNewMessage(event.target.value)}
                />
                <br />
                {sendingMessage && <p>Sending...</p>}
                <button
                  type="submit"
                  disabled={sendingMessage || loadingMessages}
                >
                  Send
                </button>
              </form>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default MessagePopup;
