import React from 'react';
import "./Common.css";
import Badge from "../../../../../../Components/UiComponents/Badge/Badge";

const Common = ({
    userDetails,
    courseName,
    sectionName,
    moduleName,
    assignmentTitle,
}) => {
    return (
        <div className="ad-sk-asgnmt_sbms_usr-dtls-section">
            <div className="ad-sk-asgnmt_sbms_usr-dtls-sc-1">
                <div className="ad-sk-asgnmt_sbms_usr-dtls-sc-1-row">
                    Student Name: {userDetails.name ? userDetails.name : "N/A"}
                </div>
                <div className="ad-sk-asgnmt_sbms_usr-dtls-sc-1-row">
                    Email: {userDetails.email ? userDetails.email : "N/A"}
                </div>
                <div className="ad-sk-asgnmt_sbms_usr-dtls-sc-1-row">
                    Surge Id: {userDetails.surge_id ? userDetails.surge_id : "N/A"}
                </div>
                <div className="ad-sk-asgnmt_sbms_usr-dtls-sc-1-row">
                    Course Name: {courseName ? courseName : "N/A"}
                </div>
                <div className="ad-sk-asgnmt_sbms_usr-dtls-sc-1-row">
                    Pathway: {sectionName ? sectionName : "N/A"}
                </div>
            </div>
            <hr className="ad-sk-asgnmt_sbms_usr-dtls-hr" />
            <div className="ad-sk-asgnmt_sbms_usr-dtls-sc-2">
                <div className="ad-sk-asgnmt_sbms_usr-dtls-sc-2-row">
                    Module: {moduleName ? moduleName : "N/A"}
                </div>
                <div className="ad-sk-asgnmt_sbms_usr-dtls-sc-2-row">
                    Assignment: {assignmentTitle ? assignmentTitle : "N/A"}
                </div>
                <div className={`ad-sk-asgnmt_sbms_usr-dtls-sc-2-row`}>
                    Deadline: 
                    <span className={`${userDetails.grading_status === "due_date_over" || userDetails.grading_status === "late_submission" ? "ad-sk-asgnmt_sbms_usr-dtls-sc-row-red" : ""}`}>
                    {userDetails.deadline ? userDetails.deadline : "N/A"}
                    </span>
                </div>
                <div className="ad-sk-asgnmt_sbms_usr-dtls-sc-2-row">
                    <Badge {...{ [userDetails.grading_status]: true }} />
                </div>
                <div className="ad-sk-asgnmt_sbms_usr-dtls-sc-2-row">
                    Submitted At: {userDetails.submitted_at ? userDetails.submitted_at : "N/A"}
                </div>
            </div>
        </div>
    );
}

export default Common;