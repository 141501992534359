import React, { useState, useEffect, useContext } from 'react';
import Info from '../../Components/Info';
import Roles from './pages/Roles';
import './RoleBasedInterview.css';
import { AuthContext } from "../../../../../../../../context/auth-context";
import { useHttpClient } from "../../../../../../../../Components/Hooks/http-hook";
import SelectLevel from './pages/SelectLevel';
const swal = require("sweetalert2");

const RoleBasedInterview = ({ selectedInterviewType, startInterviewHandler, handleGoToHome, handleGoToAllInterview }) => {
  const [currentPage, setCurrentPage] = useState('info');
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedLevel, setSelectedLevel] = useState("");
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const level = [
    { value: "fresher", label: "Fresher" },
    { value: "mid", label: "Mid-Level" },
    { value: "senior", label: "Senior" },
  ];

  const getRoles = async () => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/api/interview_prep/get_all_interview_preparation_roles`,
        "GET",
        null,
        {
          "Content-Type": "application/json",
          "Refresh-Token": auth.refresh_token,
          Authorization: auth.token,
        }
      );
      if (responseData.data.length === 0) {
        swal.fire({
          title: "No roles found",
          icon: "error",
          confirmButtonText: "OK",
        });
        return;
      } else {
        const roles = responseData.data.map((role) => {
          return { value: role.id, label: role.role_name };
        });
        setRoles(roles);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getRoles();
  }, []);

  const handleShowRoles = () => {
    setCurrentPage('roles');
  };

  const handleShowSelectLevel = () => {
    setCurrentPage('selectLevel');
  };

  return (
    <>
      {currentPage === 'info' && (
        <Info
          selectedInterviewType={selectedInterviewType}
          handleGoToHome={handleGoToHome}
          handleGoToAllInterview={handleGoToAllInterview}
          onNext={handleShowRoles}
        />
      )}
      {currentPage === 'roles' && (
        <Roles
          selectedInterviewType={selectedInterviewType}
          isLoading={isLoading}
          error={error}
          roles={roles}
          setSelectedRole={setSelectedRole}
          selectedRole={selectedRole}
          handleGoToHome={handleGoToHome}
          handleGoToAllInterview={handleGoToAllInterview}
          handleGoToRoleBased={() => setCurrentPage('info')}
          onNext={handleShowSelectLevel}
        />
      )}
      {currentPage === 'selectLevel' && (
        <SelectLevel
          selectedInterviewType={selectedInterviewType}
          startInterviewHandler={startInterviewHandler}
          level={level}
          setSelectedLevel={setSelectedLevel}
          selectedLevel={selectedLevel}
          selectedRole={selectedRole}
          handleGoToHome={handleGoToHome}
          handleGoToAllInterview={handleGoToAllInterview}
          handleGoToRoles={() => setCurrentPage('info')}
          handleGoToSelectRole={() => setCurrentPage('roles')}
        />
      )}
    </>
  );
};

export default RoleBasedInterview;
