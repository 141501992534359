import React from "react";
import "./Button.css";

const Button = ({ 
  text, onClick,
  width="130px", 
  disabled=false,
  }) => {
  return (
    <div className={disabled ? "profile-custom-button-disabled profile-custom-button" : "profile-custom-button"}
      style={{ width: width , pointerEvents: disabled ? 'none' : 'auto' }} 
      onClick={onClick}
      disabled={disabled}
    >
      {text}
    </div>
  );
}

export default Button;