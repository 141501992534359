import React from "react";
import "../Common.css";
import { useState, useEffect, useContext } from "react";
import CustomPopup from "../../../../../../Components/Popup/CustomPopup";
import Input from "../../../../../../../../../Components/Profile/Input/Input";
import Button from "../../../../../../../../../Components/Profile/Button/Button";
import { AdminContext } from "../../../../../../../../../context/admin-context";
import { useHttpClient } from "../../../../../../../../../Components/Hooks/http-hook";
import { toast } from "react-toastify";

const Add = ({
    show,
    setShow,
    moduleId,
    courseName,
    sectionName,
    moduleName,
    onAdd
}) => {
    const admin = useContext(AdminContext);
    const { sendRequest, isLoading, error, clearError } = useHttpClient();
    const [formData, setFormData] = useState({
        title: "",
        skills: "",
        file: null,
        due_days: "",
        order: "",
    });

    const onSubmit = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append("title", formData.title);
        formDataToSend.append("skills", formData.skills);
        formDataToSend.append("file", formData.file);
        formDataToSend.append("due_days", formData.due_days);
        formDataToSend.append("order", formData.order);
        formDataToSend.append("module_id", moduleId);

        try {
            const responseData = await sendRequest(
                `${process.env.REACT_APP_BACKEND_URL}/assignments/create`,
                "POST",
                formDataToSend,
                {
                   "Refresh-Token": admin.refresh_token,
                    Authorization: admin.token,
                }
            );
            if (responseData) {
                toast.success("Assignment Created Successfully");
                onAdd();
                handleClose();
            }
        } catch (err) {
            toast.error("Assignment Creation Failed");
            console.log(err);
        }
    }
        

    const handleClose = () => {
        setShow(false);
        setFormData({
            title: "",
            skills: "",
            file: "",
            due_days: "",
            order: "",
        });
    }

    return (
        <CustomPopup
            title="Create Assignment"
            subtitle={`<b>Course:</b> ${courseName} | <b>Pathway:</b> ${sectionName} <br/> <b>Module:</b> ${moduleName}`}
            show={show}
            onClose={handleClose}
        >
            <div className="sk-ad-asgnmt_popup-content">
                <div className="sk-ad-asgnmt_popup-content-row">
                    <label htmlFor="title" className="sk-ad-asgnmt_popup-content-row-label">
                        Title
                    </label>
                    <Input
                        width="100%"
                        name="title"
                        inputPlaceholderValue="Enter Assignment Name"
                        value={formData.title}
                        onChangeFunction={(e) => setFormData({
                            ...formData,
                            title: e.target.value
                        })}
                        inperror={false}
                    />
                </div>
                <div className="sk-ad-asgnmt_popup-content-row">
                    <label htmlFor="skills" className="sk-ad-asgnmt_popup-content-row-label">
                        Skills
                    </label>
                    <Input
                        width="100%"
                        name="skills"
                        inputPlaceholderValue="Enter Skills"
                        value={formData.skills}
                        onChangeFunction={(e) => setFormData({
                            ...formData,
                            skills: e.target.value
                        })}
                        inperror={false}
                    />
                </div>
                <div className="sk-ad-asgnmt_popup-content-row">
                    <label htmlFor="file" className="sk-ad-asgnmt_popup-content-row-label">
                        File
                    </label>
                    <input
                        type="file"
                        accept=".zip"
                        onChange={(e) => setFormData({
                            ...formData,
                            file: e.target.files[0]
                        })}
                        className="sk-ad-asgnmt_popup-content-row-sp-fl-inp"
                    />
                </div>
                <div className="sk-ad-asgnmt_popup-content-row-sp">
                    <div className="sk-ad-asgnmt_popup-content-row">
                        <label htmlFor="due_days" className="sk-ad-asgnmt_popup-content-row-label">
                            Due Days (No. of Days)
                        </label>
                        <Input
                            inpType="number"
                            width="95%"
                            name="due_days"
                            inputPlaceholderValue="Enter Due Days"
                            value={formData.due_days}
                            onChangeFunction={(e) => setFormData({
                                ...formData,
                                due_days: e.target.value
                            })}
                            inperror={false}
                        />
                    </div>
                    <div className="sk-ad-asgnmt_popup-content-row">
                        <label htmlFor="order" className="sk-ad-asgnmt_popup-content-row-label">
                            Order
                        </label>
                        <Input
                            inpType="number"
                            width="95%"
                            name="order"
                            inputPlaceholderValue="Enter Order"
                            value={formData.order}
                            onChangeFunction={(e) => setFormData({
                                ...formData,
                                order: e.target.value
                            })}
                            inperror={false}
                        />
                    </div>
                </div>
                <div className="sk-ad-asgnmt_popup-content-row-btn">
                    {isLoading ? (
                        <Button
                            text="Creating Assignment..."
                            disabled={true}
                            width="60%"
                        />
                    ) : (
                        <Button
                            text="Create Assignment"
                            onClick={onSubmit}
                            disabled={formData.title === "" || formData.skills === "" || formData.file === null || formData.due_days === "" || formData.order === ""}
                            width="60%"
                        />
                    )}
                </div>
            </div>
        </CustomPopup>
    );
}

export default Add;