import React, { useState, useEffect, useContext } from 'react'
import { useNavigate, useParams } from "react-router-dom";

import './Skillbox.css'
import Course1 from "../../assets/Continuing-professional-development-1170x630.jpg";
import Course2 from "../../assets/react-js-projects.jpg"
import { useHttpClient } from '../../Components/Hooks/http-hook';
import { AuthContext } from '../../context/auth-context';

const Skillbox = () => {
    const [courseInfoList, setCourseInfoList] = useState([])
    const { isLoading, error, sendRequest, clearError } = useHttpClient();

    const auth = useContext(AuthContext);
    const enroll_info = auth.course_ids_info

    const course_ids = enroll_info.map((item) => {
        return item.program_id
    }
    )

    const programTitles = async() => {
        try{
            const responseData = await sendRequest(
                `${process.env.REACT_APP_BACKEND_URL}/db/prog-titles`,
                "POST",
                JSON.stringify({
                    course_ids: course_ids
                }),
                {
                    "Content-Type": "application/json",
                    "Refresh-Token": auth.refresh_token,
                    Authorization: auth.token,
                }
            );
            if (responseData.ok) {
                return responseData.data
            }
        }
        catch(err){
            console.log(err)
        }
    }

    useEffect(() => {
        const fetchCourseInfoList = async () => {
            try {
                const responseData = await programTitles();
                setCourseInfoList(responseData);
            } catch (err) {}
        };
        fetchCourseInfoList();
    }
    , [sendRequest, auth.refresh_token, auth.token]);


    const history = useNavigate();

    const courseHandler = (item1,item2) => {
        history(`/skillbox/${item1}/${item2}`)
    };    
 
  return (
    <div className='skillbox'>
        <div className="main-heading"><span>Enrolled Surge Programs</span></div>
        <div className="courses-grid">
            {courseInfoList && courseInfoList.map((p) => {
                return (
                    <div key={p.course_id} className='grid-div' onClick={()=>courseHandler(p.course_id, p.course_name)}>
                        <div className="grid-card-img">
                            <img src={Course1}></img>
                        </div>
                        <h3>{p.course_name}</h3>
                        <p>Click to Explore</p>
                    </div>
                );
            })}            
        </div>
    </div>
  )
}

export default Skillbox