import React, { useState, useEffect, useContext } from "react";
import "./Navbar.css";
import ProfileDropdown from "../Profile/ProfileDropdown/ProfileDropdown";
import { AuthContext } from "../../context/auth-context";
import { AdminContext } from "../../context/admin-context";
import { useNavigate } from "react-router";
import swal from "sweetalert2";

const Navbar = ({
  setIsNoHeaderFooter,
  setSelectedSidebarIndex,
  currentOptionIndex,
  dropdownValues,
}) => {
  const auth = useContext(AuthContext);
  const admin = useContext(AdminContext);
  const history = useNavigate();

  const [dropdownState, setDropdownState] = useState({
    isOpen: false,
    selectedOption: dropdownValues[currentOptionIndex],
  });

  useEffect(() => {
    setIsNoHeaderFooter(true);
  }, []);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        dropdownState.isOpen &&
        !event.target.closest(".skillbox-nav-content-header-dropdown ")
      ) {
        setDropdownState((prevState) => ({ ...prevState, isOpen: false }));
      }
    };
    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [dropdownState.isOpen]);

  const handleOptionSelection = (selectedOption) => {
    setDropdownState((prevState) => ({ ...prevState, selectedOption }));
  };

  
  useEffect(() => {
    if (dropdownState.selectedOption) {
      if (dropdownState.selectedOption.value === "profile") {
        history("/profile");
      } else if (dropdownState.selectedOption.value === "logout") {
        swal
          .fire({
            title: "Logout",
            text: "Are you sure you want to logout?",
            showCancelButton: true,
            confirmButtonText: "Logout",
            confirmButtonColor: "#ff0000",
            cancelButtonText: "Cancel",
            cancelButtonColor: "#2874f0",
            allowOutsideClick: false,
          })
          .then((result) => {
            if (result.isConfirmed) {
              if (admin.isLoggedIn) {
                admin.logout();
                setIsNoHeaderFooter(false);
                return;
              }
              auth.logout();
              setIsNoHeaderFooter(false);
            } else {
              return;
              setDropdownState((prevState) => ({
                ...prevState,
                selectedOption: "",
              }));
            }
          });
      } else if (dropdownState.selectedOption.value === "resetPassword") {
        setIsNoHeaderFooter(false);
        history("/password-reset");
      } else if (dropdownState.selectedOption.value === "generate-resume") {
        history("/generate-resume");
      } else if (dropdownState.selectedOption.value === "referralCode") {
        history("/referral-code");
      } else if (dropdownState.selectedOption.value === "resume") {
        history("/resume");
      }else {
        if (admin.isLoggedIn) {
          history("/admin");
          return;
        }
        history("/skillbox-v2");
      }
      setSelectedSidebarIndex(0);
    }
  }, [dropdownState.selectedOption]);

  return (
    <div className="skillbox-nav-content-header-dropdown ">
      <ProfileDropdown
        ProfileDropdownValues={dropdownValues}
        selectedOption={dropdownState.selectedOption}
        setSelectedOption={handleOptionSelection}
        isOpen={dropdownState.isOpen}
        setIsOpen={(isOpen) =>
          setDropdownState((prevState) => ({ ...prevState, isOpen }))
        }
      />
    </div>
  );
};

export default Navbar;
