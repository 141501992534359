import React from "react";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import "./CustomPopup.css";

const CustomPopup = ({ 
    title, 
    subtitle, 
    children, 
    onClose, 
    show = false 
  }) => (
  <Popup
    position="right center"
    closeOnDocumentClick
    open={show}
    onClose={onClose}
  >
    <div className="sk-adm_popup">
      <div className="sk-adm_popup-header">
        <div className="sk-adm_popup-title">
          <h3>
            {title}
          </h3>
          {subtitle &&
            <p dangerouslySetInnerHTML={{ __html: subtitle }} />}
        </div>
        <div className="sk-adm_popup-close-button" onClick={onClose}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.82446 3.6322L12.0005 9.8082L18.1445 3.6642C18.2802 3.51974 18.4437 3.40419 18.6251 3.32445C18.8066 3.24472 19.0023 3.20246 19.2005 3.2002C19.6248 3.2002 20.0318 3.36877 20.3318 3.66882C20.6319 3.96888 20.8005 4.37585 20.8005 4.8002C20.8042 4.99636 20.7678 5.19121 20.6935 5.37281C20.6192 5.5544 20.5086 5.71889 20.3685 5.8562L14.1445 12.0002L20.3685 18.2242C20.6322 18.4822 20.7868 18.8315 20.8005 19.2002C20.8005 19.6245 20.6319 20.0315 20.3318 20.3316C20.0318 20.6316 19.6248 20.8002 19.2005 20.8002C18.9965 20.8087 18.7931 20.7746 18.603 20.7003C18.413 20.6259 18.2405 20.5128 18.0965 20.3682L12.0005 14.1922L5.84046 20.3522C5.70526 20.4918 5.54376 20.6033 5.36526 20.6802C5.18676 20.7571 4.9948 20.7979 4.80046 20.8002C4.37611 20.8002 3.96914 20.6316 3.66908 20.3316C3.36903 20.0315 3.20046 19.6245 3.20046 19.2002C3.19673 19.004 3.23312 18.8092 3.30741 18.6276C3.38169 18.446 3.49231 18.2815 3.63246 18.1442L9.85646 12.0002L3.63246 5.7762C3.36875 5.51821 3.21412 5.16885 3.20046 4.8002C3.20046 4.37585 3.36903 3.96888 3.66908 3.66882C3.96914 3.36877 4.37611 3.2002 4.80046 3.2002C5.18446 3.205 5.55246 3.3602 5.82446 3.6322Z" fill="#434343" />
          </svg>

        </div>
      </div>
      <div className="sk-adm_popup-content">{children}</div>
    </div>
  </Popup>
);

export default CustomPopup;