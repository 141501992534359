import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import './InterviewType.css';
import rolebased from './Assets/rolebased.png';
import skillbased from './Assets/skillbased.png';
import profilebased from './Assets/profilebased.png';
import communicationbased from './Assets/communicationbased.png';
import placementbased from './Assets/placementprep.png';
import courseStreambased from './Assets/courseStreambased.png';
import commcover from "../../../assets/commcover.png";
import placementcov from "../../../assets/placementcov.png";
import profilecover from "../../../assets/profilecover.png";
import courseStreamcover from "../../../assets/courseStreamcover.png";
import rolecover from "../../../assets/rolecover.png";
import skillcover from "../../../assets/skillcover.png";
import Button from '../../../../../../Components/Profile/Button/Button';
import InterviewStart from '../PersonalInterview/Pages/InterviewStart';

const InterviewTypeCard = ({ interview, handleClick }) => {
    return (
        <div className="st-skillbox-interview-type-card" key={interview.type_slug} onClick={handleClick}>
            <div className='st-skillbox-interview-type-card__img'>
                <img src={interview.image} alt={interview.title} />
            </div>
            <div className="st-skillbox-interview-type-card__title">
                {interview.title}
            </div>
        </div>
    );
}

const interViewTypeList = [
    {
        type_slug: "roleBasedInterview",
        title: "Role Based Interview",
        cover: rolecover,
        image: rolebased,
        keyPoints: [
            "AI-powered feedback on communications",
            "Analyze your performance and identify areas for growth",
            "Comprehensive analytics panel",
            "Improved student-teacher engagement",
            "Identify areas for growth",
            "Prepare yourself for interview",
            "Relevant role based questions and comprehensive feedback",
        ],
        InterviewStartTitle: "Choose a role you want to interview for",
    },
    {
        type_slug: "skillBasedInterview",
        title: "Skill Based Interview",
        cover: skillcover,
        image: skillbased,
        keyPoints: [
            "Analyze your responses and pinpoint areas for improvement",
            "Evaluation of speech and content",
            "Receive a detailed performance report to track your progress",
            "Get customized tips to enhance your body language and tone",
            "Relevant skill based questions and comprehensive feedback",
            "Prepare yourself for interview",
            "Relevant skill based questions and comprehensive feedback",
        ],
        InterviewStartTitle: "Choose a skill you want to interview for",
    },
    {
        type_slug: "profileBasedInterview",
        title: "Profile Based Interview",
        cover: profilecover,  
        image: profilebased,
        keyPoints: [
          "Practice answering a range of common and behavioral interview questions",
          "Real-time feedback on your responses, body language, and tone",
          "Customized tips to enhance your communication and presentation skills",
          "Detailed performance reports to track your progress and identify areas for growth",
          "Upload your resume to get started",
          "Relevant profile based questions and comprehensive feedback",
        ],  
        InterviewStartTitle: "Upload your resume to get started",
    },
    {
        type_slug: "communicationBasedInterview",
        title: "Communication Interview",
        cover: commcover,
        image: communicationbased,
        keyPoints: [
            "AI-powered feedback on communications and speech",
            "Receive tips to improve your body language and tone",
            "Practice answering tough behavioral questions",
            "Improved student-teacher engagement",
            "Prepare yourself for interview",
            "Relevant communication based questions and comprehensive feedback",
            "Help you to improve your communication skills",
        ],
        InterviewStartTitle: "Choose a communication level you want to improve",
    },
    {
        type_slug: "placementBasedInterview",
        title: "Placement Preparation",
        cover: placementcov,
        image: placementbased,
        keyPoints: [
          "AI-powered feedback on communications, speech and content",
          "Get personalized feedback on your responses",
          "Comprehensive analytics panel",
          "Improved student-teacher engagement",
          "Prepare yourself for interview",
          "Relevant placement based questions and comprehensive feedback",
          "Placement preparation for students",
        ],
        InterviewStartTitle: "Choose a salary package you want to prepare for"
    },
    {
        type_slug: "courseStreamBasedInterview",
        title: "Course Based Interview",
        cover: courseStreamcover,
        image: courseStreambased,
        keyPoints: [
          "AI-powered course based feedback on communications",
          "Examine your performance and pinpoint key areas for improvement.",
          "An in depth analytics panel",
          "Improved learners and educators engagement",
          "A thorough insights panel with speech fluency",
          "Examine your content using AI",
          "Relevant course based questions and comprehensive feedback",
        ],
        InterviewStartTitle: "Choose a course stream you want to prepare for"
    }
  ];
  

const InterviewType = React.memo(({ setSelectedTab, setSelectedOption, setSelectedInterviewType }) => {
    const handleInterviewData = (data) => {
        setSelectedInterviewType(data);
        setSelectedTab("personalInterview");
    }

    return (
        <>
            <div className="personal-interview-page-nav-menu">
                <div
                    onClick={() => setSelectedOption({
                        value: "skillbox",
                        label: "Skillbox",
                    })}
                    className="personal-interview-page-nav-menu-home"
                >
                    Home
                </div>
            </div>
            <div className="profile-user-resume-header">
                <div className="profile-user-resume-header-title">
                    Select Type of Interview
                </div>
                <Button
                    text="Previous Interview Feedback"
                    width="230px"
                    onClick={() => setSelectedTab("interviewFeedback")}
                />
            </div>
            <div className="st-skillbox-programs-mn-ct">
                {interViewTypeList && interViewTypeList.length > 0 && interViewTypeList.map((p, i) => (
                    <div key={i}>
                        <InterviewTypeCard interview={p} handleClick={() => handleInterviewData(p)} />
                    </div>
                ))}
            </div>
        </>
    );
});

InterviewType.propTypes = {
    setSelectedTab: PropTypes.func.isRequired,
    setSelectedOption: PropTypes.func.isRequired,
    setSelectedInterviewType: PropTypes.func.isRequired,
};

export default InterviewType;
