import React, { useState } from "react";
import "./CourseContent.css";
import { useNavigate, useParams } from "react-router-dom";
import ProgressBar from "@ramonak/react-progress-bar";
import {
  IoIosArrowForward,
  IoIosArrowDown,
  IoIosArrowUp,
} from "react-icons/io";
import { Scrollbars } from "react-custom-scrollbars-2";
import { BsArrowRight, BsBook } from "react-icons/bs";
import { TiTickOutline } from "react-icons/ti";
import { FiPlayCircle } from "react-icons/fi";

const CourseContent = () => {
  const history = useNavigate();
  let { cid } = useParams();
  const [mainCourseIndex, setMainCourseIndex]=useState(0);

  const [moduleHandle, setModuleHandle] = useState({
    isView: false,
    title: "Python for Data Science",
  });

  const [moduleReactHandle, setModuleReactHandle] = useState({
    isView: true,
    title: "React Basics for web Development",
  });

  const [userReactModule, setUserReactModule] = useState({
    title: "React",
    isStarted: false,
    percentageComplete: 0,
    modules: [
      {
        title: "React Basics for web Development",
        isStarted: false,
        description:
          "This course will helps us to learn web development. We start with React basics learn keywords,syntax and writing usable code of this language.",
        percentageComplete: 0,
        lectures: [
          {
            url:
              "https://react-crash-course.s3.ap-south-1.amazonaws.com/Class1.mp4",
          },
          {
            url:
              "https://react-crash-course.s3.ap-south-1.amazonaws.com/Class2.mp4",
          },
          {
            url:
              "https://react-crash-course.s3.ap-south-1.amazonaws.com/Class3.mp4",
          },
        ],
      },
    ],
  });

  const [userModule, setUserModule] = useState({
    title: "Data Science Foundations",
    isStarted: true,
    percentageComplete: 40,
    modules: [
      {
        title: "Python for Data Science",
        isStarted: true,
        description:
          "This course will helps us to learn the Python Programming language used for Data Science. We start with python basics learn keywords,syntax and writing usable code of this language.",
        percentageComplete: 40,
        lectures: [
          {
            url: "https://youtu.be/AgyTHzjBS-c",
          },
          {
            url: "https://youtu.be/qcSz4ef9UHA",
          },
          {
            url:
              "https://youtu.be/pCj0Jr-ktp4?list=RDCMUCBwmMxybNva6P_5VmxjzwqA",
          },
          {
            url: "https://youtu.be/jtn-hRJjl68",
          },
          {
            url: "https://youtu.be/AgyTHzjBS-c",
          },
          {
            url: "https://youtu.be/jtn-hRJjl68",
          },
        ],
      },
      {
        title: "Statistics",
        isStarted: false,
        description:
          "This course will helps us to learn the Python Programming language used for Data Science. We start with python basics learn keywords,syntax and writing usable code of this language.",
        percentageComplete: 0,
        lectures: [
          {
            url: "https://youtu.be/jtn-hRJjl68",
          },
          {
            url: "https://youtu.be/qcSz4ef9UHA",
          },
          {
            url:
              "https://youtu.be/uULy2rc6YDc?list=RDCMUCBwmMxybNva6P_5VmxjzwqA",
          },
          {
            url: "https://youtu.be/AgyTHzjBS-c",
          },

          {
            url: "https://youtu.be/qcSz4ef9UHA",
          },

          {
            url: "https://youtu.be/AgyTHzjBS-c",
          },
        ],
      },
      {
        title: "SQL Programming",
        isStarted: true,
        description:
          "This course will helps us to learn the Python Programming language used for Data Science. We start with python basics learn keywords,syntax and writing usable code of this language.",
        percentageComplete: 38,
        lectures: [
          {
            url: "https://youtu.be/AgyTHzjBS-c",
          },
          {
            url: "https://youtu.be/qcSz4ef9UHA",
          },
          {
            url: "https://youtu.be/AgyTHzjBS-c",
          },
          {
            url: "https://youtu.be/qcSz4ef9UHA",
          },
          {
            url:
              "https://youtu.be/uULy2rc6YDc?list=RDCMUCBwmMxybNva6P_5VmxjzwqA",
          },
          {
            url: "https://youtu.be/AgyTHzjBS-c",
          },
        ],
      },
      {
        title: "Machine Learning Fundamentals",
        isStarted: true,
        description:
          "This course will helps us to learn the Python Programming language used for Data Science. We start with python basics learn keywords,syntax and writing usable code of this language.",
        percentageComplete: 76,
        lectures: [
          {
            url: "https://youtu.be/jtn-hRJjl68",
          },
          {
            url: "https://youtu.be/AgyTHzjBS-c",
          },
          {
            url: "https://youtu.be/qcSz4ef9UHA",
          },
          {
            url:
              "https://youtu.be/ZB-7eq4txjA?list=RDCMUCBwmMxybNva6P_5VmxjzwqA",
          },
          {
            url: "https://youtu.be/qcSz4ef9UHA",
          },
          {
            url:
              "https://youtu.be/pCj0Jr-ktp4?list=RDCMUCBwmMxybNva6P_5VmxjzwqA",
          },
        ],
      },
      {
        title: "Deep Learning Fundamentals ",
        isStarted: false,
        description:
          "This course will helps us to learn the Python Programming language used for Data Science. We start with python basics learn keywords,syntax and writing usable code of this language.",
        percentageComplete: 0,
        lectures: [
          {
            url: "https://youtu.be/jtn-hRJjl68",
          },
          {
            url: "https://youtu.be/qcSz4ef9UHA",
          },
          {
            url:
              "https://youtu.be/pCj0Jr-ktp4?list=RDCMUCBwmMxybNva6P_5VmxjzwqA",
          },
          {
            url: "https://youtu.be/qcSz4ef9UHA",
          },
          {
            url:
              "https://youtu.be/uULy2rc6YDc?list=RDCMUCBwmMxybNva6P_5VmxjzwqA",
          },
          {
            url: "https://youtu.be/qcSz4ef9UHA",
          },
        ],
      },
      {
        title: "Exploratory Data Analysis",
        isStarted: false,
        description:
          "This course will helps us to learn the Python Programming language used for Data Science. We start with python basics learn keywords,syntax and writing usable code of this language.",
        percentageComplete: 10,
        lectures: [
          {
            url: "https://youtu.be/jtn-hRJjl68",
          },
          {
            url: "https://youtu.be/qcSz4ef9UHA",
          },
          {
            url:
              "https://youtu.be/pCj0Jr-ktp4?list=RDCMUCBwmMxybNva6P_5VmxjzwqA",
          },
          {
            url: "https://youtu.be/qcSz4ef9UHA",
          },
          {
            url:
              "https://youtu.be/uULy2rc6YDc?list=RDCMUCBwmMxybNva6P_5VmxjzwqA",
          },
          {
            url: "https://youtu.be/qcSz4ef9UHA",
          },
        ],
      },
    ],
  });

  const DUMMY_COURSES = [
    {
      title: "Data Science Foundations",
      isStarted: true,
      percentageComplete: 40,
      modules: [
        {
          title: "Python for Data Science",
          isStarted: true,
          description:
            "This course will helps us to learn the Python Programming language used for Data Science. We start with python basics learn keywords,syntax and writing usable code of this language.",
          percentageComplete: 40,
          lectures: [
            {
              url: "https://youtu.be/AgyTHzjBS-c",
            },
            {
              url: "https://youtu.be/qcSz4ef9UHA",
            },
            {
              url:
                "https://youtu.be/pCj0Jr-ktp4?list=RDCMUCBwmMxybNva6P_5VmxjzwqA",
            },
            {
              url: "https://youtu.be/jtn-hRJjl68",
            },
            {
              url: "https://youtu.be/AgyTHzjBS-c",
            },
            {
              url: "https://youtu.be/jtn-hRJjl68",
            },
          ],
        },
        {
          title: "Statistics",
          isStarted: false,
          description:
            "This course will helps us to learn the Python Programming language used for Data Science. We start with python basics learn keywords,syntax and writing usable code of this language.",
          percentageComplete: 0,
          lectures: [
            {
              url: "https://youtu.be/jtn-hRJjl68",
            },
            {
              url: "https://youtu.be/qcSz4ef9UHA",
            },
            {
              url:
                "https://youtu.be/uULy2rc6YDc?list=RDCMUCBwmMxybNva6P_5VmxjzwqA",
            },
            {
              url: "https://youtu.be/AgyTHzjBS-c",
            },

            {
              url: "https://youtu.be/qcSz4ef9UHA",
            },

            {
              url: "https://youtu.be/AgyTHzjBS-c",
            },
          ],
        },
        {
          title: "SQL Programming",
          isStarted: true,
          description:
            "This course will helps us to learn the Python Programming language used for Data Science. We start with python basics learn keywords,syntax and writing usable code of this language.",
          percentageComplete: 38,
          lectures: [
            {
              url: "https://youtu.be/AgyTHzjBS-c",
            },
            {
              url: "https://youtu.be/qcSz4ef9UHA",
            },
            {
              url: "https://youtu.be/AgyTHzjBS-c",
            },
            {
              url: "https://youtu.be/qcSz4ef9UHA",
            },
            {
              url:
                "https://youtu.be/uULy2rc6YDc?list=RDCMUCBwmMxybNva6P_5VmxjzwqA",
            },
            {
              url: "https://youtu.be/AgyTHzjBS-c",
            },
          ],
        },
        {
          title: "Machine Learning Fundamentals",
          isStarted: true,
          description:
            "This course will helps us to learn the Python Programming language used for Data Science. We start with python basics learn keywords,syntax and writing usable code of this language.",
          percentageComplete: 76,
          lectures: [
            {
              url: "https://youtu.be/jtn-hRJjl68",
            },
            {
              url: "https://youtu.be/AgyTHzjBS-c",
            },
            {
              url: "https://youtu.be/qcSz4ef9UHA",
            },
            {
              url:
                "https://youtu.be/ZB-7eq4txjA?list=RDCMUCBwmMxybNva6P_5VmxjzwqA",
            },
            {
              url: "https://youtu.be/qcSz4ef9UHA",
            },
            {
              url:
                "https://youtu.be/pCj0Jr-ktp4?list=RDCMUCBwmMxybNva6P_5VmxjzwqA",
            },
          ],
        },
        {
          title: "Deep Learning Fundamentals ",
          isStarted: false,
          description:
            "This course will helps us to learn the Python Programming language used for Data Science. We start with python basics learn keywords,syntax and writing usable code of this language.",
          percentageComplete: 0,
          lectures: [
            {
              url: "https://youtu.be/jtn-hRJjl68",
            },
            {
              url: "https://youtu.be/qcSz4ef9UHA",
            },
            {
              url:
                "https://youtu.be/pCj0Jr-ktp4?list=RDCMUCBwmMxybNva6P_5VmxjzwqA",
            },
            {
              url: "https://youtu.be/qcSz4ef9UHA",
            },
            {
              url:
                "https://youtu.be/uULy2rc6YDc?list=RDCMUCBwmMxybNva6P_5VmxjzwqA",
            },
            {
              url: "https://youtu.be/qcSz4ef9UHA",
            },
          ],
        },
        {
          title: "Exploratory Data Analysis",
          isStarted: false,
          description:
            "This course will helps us to learn the Python Programming language used for Data Science. We start with python basics learn keywords,syntax and writing usable code of this language.",
          percentageComplete: 10,
          lectures: [
            {
              url: "https://youtu.be/jtn-hRJjl68",
            },
            {
              url: "https://youtu.be/qcSz4ef9UHA",
            },
            {
              url:
                "https://youtu.be/pCj0Jr-ktp4?list=RDCMUCBwmMxybNva6P_5VmxjzwqA",
            },
            {
              url: "https://youtu.be/qcSz4ef9UHA",
            },
            {
              url:
                "https://youtu.be/uULy2rc6YDc?list=RDCMUCBwmMxybNva6P_5VmxjzwqA",
            },
            {
              url: "https://youtu.be/qcSz4ef9UHA",
            },
          ],
        },
      ],
    },
    {
      title: "Machine Learning",
      isStarted: false,
      percentageComplete: 0,
      modules: [
        {
          title: "Supervised Learning",
          isStarted: false,
          description:
            "This course will helps us to learn the Python Programming language used for Data Science. We start with python basics learn keywords,syntax and writing usable code of this language.",
          percentageComplete: 10,
          lectures: [
            {
              url: "https://youtu.be/jtn-hRJjl68",
            },
            {
              url: "https://youtu.be/qcSz4ef9UHA",
            },
            {
              url:
                "https://youtu.be/pCj0Jr-ktp4?list=RDCMUCBwmMxybNva6P_5VmxjzwqA",
            },
            {
              url: "https://youtu.be/qcSz4ef9UHA",
            },
            {
              url:
                "https://youtu.be/uULy2rc6YDc?list=RDCMUCBwmMxybNva6P_5VmxjzwqA",
            },
            {
              url: "https://youtu.be/qcSz4ef9UHA",
            },
          ],
        },
        {
          title: "Unsupervised Learning",
          isStarted: false,
          description:
            "This course will helps us to learn the Python Programming language used for Data Science. We start with python basics learn keywords,syntax and writing usable code of this language.",
          percentageComplete: 10,
          lectures: [
            {
              url: "https://youtu.be/jtn-hRJjl68",
            },
            {
              url: "https://youtu.be/qcSz4ef9UHA",
            },
            {
              url:
                "https://youtu.be/pCj0Jr-ktp4?list=RDCMUCBwmMxybNva6P_5VmxjzwqA",
            },
            {
              url: "https://youtu.be/qcSz4ef9UHA",
            },
            {
              url:
                "https://youtu.be/uULy2rc6YDc?list=RDCMUCBwmMxybNva6P_5VmxjzwqA",
            },
            {
              url: "https://youtu.be/qcSz4ef9UHA",
            },
          ],
        },
        {
          title: "Time Series Forecasting",
          isStarted: false,
          description:
            "This course will helps us to learn the Python Programming language used for Data Science. We start with python basics learn keywords,syntax and writing usable code of this language.",
          percentageComplete: 10,
          lectures: [
            {
              url: "https://youtu.be/jtn-hRJjl68",
            },
            {
              url: "https://youtu.be/qcSz4ef9UHA",
            },
            {
              url:
                "https://youtu.be/pCj0Jr-ktp4?list=RDCMUCBwmMxybNva6P_5VmxjzwqA",
            },
            {
              url: "https://youtu.be/qcSz4ef9UHA",
            },
            {
              url:
                "https://youtu.be/uULy2rc6YDc?list=RDCMUCBwmMxybNva6P_5VmxjzwqA",
            },
            {
              url: "https://youtu.be/qcSz4ef9UHA",
            },
          ],
        },
        {
          title: "Model Selection and Tuning",
          isStarted: false,
          description:
            "This course will helps us to learn the Python Programming language used for Data Science. We start with python basics learn keywords,syntax and writing usable code of this language.",
          percentageComplete: 10,
          lectures: [
            {
              url: "https://youtu.be/jtn-hRJjl68",
            },
            {
              url: "https://youtu.be/qcSz4ef9UHA",
            },
            {
              url:
                "https://youtu.be/pCj0Jr-ktp4?list=RDCMUCBwmMxybNva6P_5VmxjzwqA",
            },
            {
              url: "https://youtu.be/qcSz4ef9UHA",
            },
            {
              url:
                "https://youtu.be/uULy2rc6YDc?list=RDCMUCBwmMxybNva6P_5VmxjzwqA",
            },
            {
              url: "https://youtu.be/qcSz4ef9UHA",
            },
          ],
        },
        {
          title: "Model Deployment",
          isStarted: false,
          description:
            "This course will helps us to learn the Python Programming language used for Data Science. We start with python basics learn keywords,syntax and writing usable code of this language.",
          percentageComplete: 10,
          lectures: [
            {
              url: "https://youtu.be/jtn-hRJjl68",
            },
            {
              url: "https://youtu.be/qcSz4ef9UHA",
            },
            {
              url:
                "https://youtu.be/pCj0Jr-ktp4?list=RDCMUCBwmMxybNva6P_5VmxjzwqA",
            },
            {
              url: "https://youtu.be/qcSz4ef9UHA",
            },
            {
              url:
                "https://youtu.be/uULy2rc6YDc?list=RDCMUCBwmMxybNva6P_5VmxjzwqA",
            },
            {
              url: "https://youtu.be/qcSz4ef9UHA",
            },
          ],
        },
      ],
    },
    {
      title: "Bussiness Intelligence",
      isStarted: true,
      percentageComplete: 10,
      modules: [
        {
          title: "Business Intelligence Tool Tableau",
          isStarted: false,
          description:
            "This course will helps us to learn the Python Programming language used for Data Science. We start with python basics learn keywords,syntax and writing usable code of this language.",
          percentageComplete: 10,
          lectures: [
            {
              url: "https://youtu.be/jtn-hRJjl68",
            },
            {
              url: "https://youtu.be/qcSz4ef9UHA",
            },
            {
              url:
                "https://youtu.be/pCj0Jr-ktp4?list=RDCMUCBwmMxybNva6P_5VmxjzwqA",
            },
            {
              url: "https://youtu.be/qcSz4ef9UHA",
            },
            {
              url:
                "https://youtu.be/uULy2rc6YDc?list=RDCMUCBwmMxybNva6P_5VmxjzwqA",
            },
            {
              url: "https://youtu.be/qcSz4ef9UHA",
            },
          ],
        },
        {
          title: "Data Analysis in Excel",
          isStarted: false,
          description:
            "This course will helps us to learn the Python Programming language used for Data Science. We start with python basics learn keywords,syntax and writing usable code of this language.",
          percentageComplete: 10,
          lectures: [
            {
              url: "https://youtu.be/jtn-hRJjl68",
            },
            {
              url: "https://youtu.be/qcSz4ef9UHA",
            },
            {
              url:
                "https://youtu.be/pCj0Jr-ktp4?list=RDCMUCBwmMxybNva6P_5VmxjzwqA",
            },
            {
              url: "https://youtu.be/qcSz4ef9UHA",
            },
            {
              url:
                "https://youtu.be/uULy2rc6YDc?list=RDCMUCBwmMxybNva6P_5VmxjzwqA",
            },
            {
              url: "https://youtu.be/qcSz4ef9UHA",
            },
          ],
        },
        {
          title: "Bussiness Case Studies",
          isStarted: false,
          description:
            "This course will helps us to learn the Python Programming language used for Data Science. We start with python basics learn keywords,syntax and writing usable code of this language.",
          percentageComplete: 10,
          lectures: [
            {
              url: "https://youtu.be/jtn-hRJjl68",
            },
            {
              url: "https://youtu.be/qcSz4ef9UHA",
            },
            {
              url:
                "https://youtu.be/pCj0Jr-ktp4?list=RDCMUCBwmMxybNva6P_5VmxjzwqA",
            },
            {
              url: "https://youtu.be/qcSz4ef9UHA",
            },
            {
              url:
                "https://youtu.be/uULy2rc6YDc?list=RDCMUCBwmMxybNva6P_5VmxjzwqA",
            },
            {
              url: "https://youtu.be/qcSz4ef9UHA",
            },
          ],
        },

        {
          title: "BFSI ML Applications",
          isStarted: false,
          description:
            "This course will helps us to learn the Python Programming language used for Data Science. We start with python basics learn keywords,syntax and writing usable code of this language.",
          percentageComplete: 10,
          lectures: [
            {
              url: "https://youtu.be/jtn-hRJjl68",
            },
            {
              url: "https://youtu.be/qcSz4ef9UHA",
            },
            {
              url:
                "https://youtu.be/pCj0Jr-ktp4?list=RDCMUCBwmMxybNva6P_5VmxjzwqA",
            },
            {
              url: "https://youtu.be/qcSz4ef9UHA",
            },
            {
              url:
                "https://youtu.be/uULy2rc6YDc?list=RDCMUCBwmMxybNva6P_5VmxjzwqA",
            },
            {
              url: "https://youtu.be/qcSz4ef9UHA",
            },
          ],
        },
      ],
    },
    {
      title: "Artificial Intelligence",
      isStarted: false,
      percentageComplete: 0,
      modules: [
        {
          title: "Neutral Networks and Deep Learning",
          isStarted: false,
          description:
            "This course will helps us to learn the Python Programming language used for Data Science. We start with python basics learn keywords,syntax and writing usable code of this language.",
          percentageComplete: 10,
          lectures: [
            {
              url:
                "https://d2hkendb91rgug.cloudfront.net/artificial-intelligence/neutral-networks-and-deep-learning/NN_1.mp4",
            },
            {
              url:
                "https://d2hkendb91rgug.cloudfront.net/artificial-intelligence/neutral-networks-and-deep-learning/NN_2.mp4",
            },
            {
              url:
                "https://d2hkendb91rgug.cloudfront.net/artificial-intelligence/neutral-networks-and-deep-learning/NN_3.mp4",
            },
            {
              url:
                "https://d2hkendb91rgug.cloudfront.net/artificial-intelligence/neutral-networks-and-deep-learning/NN_4.mp4",
            },
            {
              url:
                "https://d2hkendb91rgug.cloudfront.net/artificial-intelligence/neutral-networks-and-deep-learning/NN_5.mp4",
            },
            {
              url:
                "https://d2hkendb91rgug.cloudfront.net/artificial-intelligence/neutral-networks-and-deep-learning/NN_6.mp4",
            },
            {
              url:
                "https://d2hkendb91rgug.cloudfront.net/artificial-intelligence/neutral-networks-and-deep-learning/NN_6_1.mp4",
            },
            {
              url:
                "https://d2hkendb91rgug.cloudfront.net/artificial-intelligence/neutral-networks-and-deep-learning/NN_7.mp4",
            },
            {
              url:
                "https://d2hkendb91rgug.cloudfront.net/artificial-intelligence/neutral-networks-and-deep-learning/NN_8.mp4",
            },
            {
              url:
                "https://d2hkendb91rgug.cloudfront.net/artificial-intelligence/neutral-networks-and-deep-learning/NN_9.mp4",
            },
            {
              url:
                "https://d2hkendb91rgug.cloudfront.net/artificial-intelligence/neutral-networks-and-deep-learning/NN_10.mp4",
            },
            {
              url:
                "https://d2hkendb91rgug.cloudfront.net/artificial-intelligence/neutral-networks-and-deep-learning/NN_11.mp4",
            },
            {
              url:
                "https://d2hkendb91rgug.cloudfront.net/artificial-intelligence/neutral-networks-and-deep-learning/NN_12.mp4",
            },
            {
              url:
                "https://d2hkendb91rgug.cloudfront.net/artificial-intelligence/neutral-networks-and-deep-learning/NN_13.mp4",
            },
            {
              url:
                "https://d2hkendb91rgug.cloudfront.net/artificial-intelligence/neutral-networks-and-deep-learning/NN_14.mp4",
            },
            {
              url:
                "https://d2hkendb91rgug.cloudfront.net/artificial-intelligence/neutral-networks-and-deep-learning/NN_15.mp4",
            },
          ],
        },
        {
          title: "Natural Language Processing",
          isStarted: false,
          description:
            "This course will helps us to learn the Python Programming language used for Data Science. We start with python basics learn keywords,syntax and writing usable code of this language.",
          percentageComplete: 10,
          lectures: [
            {
              url:
                "https://d2hkendb91rgug.cloudfront.net/artificial-intelligence/natural-language-processing/Pradip_1_Spam+Detector_TFIDF.mp4",
            },
            {
              url:
                "https://d2hkendb91rgug.cloudfront.net/artificial-intelligence/natural-language-processing/Pradip_2_WordVector_Gensim.mp4",
            },
            {
              url:
                "https://d2hkendb91rgug.cloudfront.net/artificial-intelligence/natural-language-processing/Pradip_3_NER_InformationExtraction_Part_1.mp4",
            },
            {
              url:
                "https://d2hkendb91rgug.cloudfront.net/artificial-intelligence/natural-language-processing/Pradip_4_Topic_Modeling.mp4",
            },
            {
              url:
                "https://d2hkendb91rgug.cloudfront.net/artificial-intelligence/natural-language-processing/Pradip_5_NER_InformationExtraction_Part_2.mp4",
            },
            {
              url:
                "https://d2hkendb91rgug.cloudfront.net/artificial-intelligence/natural-language-processing/Pradip_6_Transfer_Learning_Transformers.mp4",
            },
            {
              url:
                "https://d2hkendb91rgug.cloudfront.net/artificial-intelligence/natural-language-processing/Pradip_7_Sentence_Embeddings.mp4",
            },
            {
              url:
                "https://d2hkendb91rgug.cloudfront.net/artificial-intelligence/natural-language-processing/Pradip_8_Pretrained_Model.mp4",
            },
          ],
        },
      ],
    },
  ];

  const DUMMY_REACT = [
    {
      title: "React",
      isStarted: false,
      percentageComplete: 0,
      modules: [
        {
          title: "React Basics for web Development",
          isStarted: false,
          description:
            "This course will helps us to learn web development. We start with React basics learn keywords,syntax and writing usable code of this language.",
          percentageComplete: 0,
          lectures: [
            {
              url:
                "https://react-crash-course.s3.ap-south-1.amazonaws.com/Class1.mp4",
            },
            {
              url:
                "https://react-crash-course.s3.ap-south-1.amazonaws.com/Class2.mp4",
            },
            {
              url:
                "https://react-crash-course.s3.ap-south-1.amazonaws.com/Class3.mp4",
            },
          ],
        },
      ],
    },
  ];

  const courseHandler = (item, item1,index) => {
    history(`/mycourses/${item1}/${item}/${mainCourseIndex}/${index+1}`);
  };

  const moduleClickHandler = (value) => {
    if (cid != "React Crash Course") {
      setModuleHandle({
        isView: true,
        title: value,
      });
    } else {
      setModuleReactHandle({
        isView: true,
        title: value,
      });
    }
  };

  const moduleClickBackHandler = (value) => {
    if (cid != "React Crash Course") {
      setModuleHandle({
        isView: false,
        title: value,
      });
    } else {
      setModuleReactHandle({ isView: false, title: value });
    }
  };

  const selectModuleHandler = (value,index) => {
    let myModule;
    setMainCourseIndex(index);
    if (cid != "React Crash Course") {
      myModule = DUMMY_COURSES.filter((p) => p.title == value);
      setUserModule(myModule[0]);
    } else {
      myModule = DUMMY_REACT.filter((p) => p.title == value);
      setUserReactModule(myModule[0]);
    }
  };

  return (
    <div className="coursecontentpage">
      <h2>{cid}</h2>
      <h3>Course Overview</h3>
      <div className="coursescontent">
        <div className="coursescontent-flex1">
          {cid != "React Crash Course"
            ? DUMMY_COURSES.map((item, index) => {
                return (
                  <div className="course-grid">
                    <div
                      className="course-grid-div"
                      onClick={() => selectModuleHandler(item.title,index)}
                    >
                      <h3>Module {index + 1} </h3>

                      <h2>{item.title}</h2>

                      <div className="progress-bar">
                        {item.isStarted ? (
                          <React.Fragment>
                            <ProgressBar
                              completed={item.percentageComplete}
                              height={7}
                              bgColor="#1d3557"
                              isLabelVisible={false}
                            />

                            <span
                              style={{
                                color: "#rgb(31, 31, 71)",
                                marginTop: "0.2rem",
                              }}
                            >
                              {item.percentageComplete}% Complete
                            </span>
                          </React.Fragment>
                        ) : (
                          <spam>NOT STARTED</spam>
                        )}
                      </div>
                    </div>
                    <div className="icon-box">
                      <IoIosArrowForward />
                    </div>
                  </div>
                );
              })
            : DUMMY_REACT.map((item, index) => {
                return (
                  <div className="course-grid">
                    <div
                      className="course-grid-div"
                      onClick={() => selectModuleHandler(item.title,index)}
                    >
                      <h3>Module {index + 1} </h3>

                      <h2>{item.title}</h2>

                      <div className="progress-bar">
                        {item.isStarted ? (
                          <React.Fragment>
                            <ProgressBar
                              completed={item.percentageComplete}
                              height={7}
                              bgColor="#1d3557"
                              isLabelVisible={false}
                            />

                            <span
                              style={{
                                color: "#rgb(31, 31, 71)",
                                marginTop: "0.2rem",
                              }}
                            >
                              {item.percentageComplete}% Complete
                            </span>
                          </React.Fragment>
                        ) : (
                          <spam>NOT STARTED</spam>
                        )}
                      </div>
                    </div>
                    <div className="icon-box">
                      <IoIosArrowForward />
                    </div>
                  </div>
                );
              })}
        </div>
        <div className="coursescontent-flex2">
          <Scrollbars
            autoHide
            autoHideTimeout={1000}
            autoHideDuration={310}
            autoHeightMin={300}
            autoHeightMax={800}
            thumbMinSize={30}
            universal={true}
            style={{
              width: `100%`,
              height: cid=="React Crash Course" ? "13rem": "30rem",
              background: "white",
              borderRadius: "6px",
            }}
          >
            {cid != "React Crash Course"
              ? userModule.modules.map((sub_item, i) => {
                  if (
                    sub_item.title == moduleHandle.title &&
                    moduleHandle.isView
                  ) {
                    return (
                      <div className="module-flex1-div">
                        <div className="flex1-div-content1">
                          {sub_item.percentageComplete == 100 ? (
                            <TiTickOutline
                              style={{
                                color: "green",
                                fontSize: "20px",
                                marginRight: "0.7rem",
                                marginLeft: "0.8rem",
                              }}
                            />
                          ) : (
                            <BsBook
                              style={{
                                color: "green",
                                fontSize: "20px",
                                marginRight: "0.7rem",
                                marginLeft: "0.8rem",
                              }}
                            />
                          )}
                        </div>
                        <div className="flex2-div-content">
                          {" "}
                          <h2
                            onClick={() =>
                              moduleClickBackHandler(sub_item.title)
                            }
                          >
                            {sub_item.title}{" "}
                          </h2>
                          <div className="progress-bar-module">
                            {sub_item.isStarted ? (
                              <React.Fragment>
                                <ProgressBar
                                  completed={sub_item.percentageComplete}
                                  height={7}
                                  bgColor="#1d3557"
                                  isLabelVisible={false}
                                />

                                <span
                                  style={{
                                    color: "#rgb(31, 31, 71)",
                                    marginTop: "0.2rem",
                                  }}
                                >
                                  {sub_item.percentageComplete}% Complete
                                </span>
                              </React.Fragment>
                            ) : (
                              <span>NOT STARTED</span>
                            )}
                          </div>
                          <div className="flex1-video-grid">
                            <ul>
                              {sub_item.lectures.map((p, index) => {
                                return (
                                  <li
                                    onClick={() =>
                                      courseHandler(
                                        userModule.title,
                                        sub_item.title,
                                        index
                                      )
                                    }
                                  >
                                    <FiPlayCircle
                                      style={{
                                        fontSize: "18px",
                                        marginRight: "0.4rem",
                                      }}
                                    />
                                    {sub_item.title} Lecture {index + 1}
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        </div>
                        <div className="flex3-div-content">
                          <IoIosArrowUp
                            onClick={() =>
                              moduleClickBackHandler(sub_item.title)
                            }
                          />
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div
                        onClick={() => moduleClickHandler(sub_item.title)}
                        className="module-flex1-div"
                      >
                        <div className="flex1-div-content">
                          {sub_item.percentageComplete == 100 ? (
                            <TiTickOutline
                              style={{
                                color: "green",
                                fontSize: "20px",
                                marginRight: "0.7rem",
                                marginLeft: "0.8rem",
                              }}
                            />
                          ) : (
                            <BsBook
                              style={{
                                color: "green",
                                fontSize: "20px",
                                marginRight: "0.7rem",
                                marginLeft: "0.8rem",
                              }}
                            />
                          )}
                        </div>
                        <div className="flex2-div-content">
                          {" "}
                          <h2>{sub_item.title} </h2>
                          <div className="progress-bar-module">
                            {sub_item.isStarted ? (
                              <React.Fragment>
                                <ProgressBar
                                  completed={sub_item.percentageComplete}
                                  height={7}
                                  bgColor="#1d3557"
                                  isLabelVisible={false}
                                />

                                <span
                                  style={{
                                    color: "#rgb(31, 31, 71)",
                                    marginTop: "0.2rem",
                                  }}
                                >
                                  {sub_item.percentageComplete}% Complete
                                </span>
                              </React.Fragment>
                            ) : (
                              <span>NOT STARTED</span>
                            )}
                          </div>
                        </div>
                        <div className="flex3-div-content">
                          <IoIosArrowDown style={{ fontSize: "20px" }} />
                        </div>
                      </div>
                    );
                  }
                })
              : userReactModule.modules.map((sub_item, i) => {
                  if (
                    sub_item.title == moduleReactHandle.title &&
                    moduleReactHandle.isView
                  ) {
                    return (
                      <div className="module-flex1-div">
                        <div className="flex1-div-content1">
                          {sub_item.percentageComplete == 100 ? (
                            <TiTickOutline
                              style={{
                                color: "green",
                                fontSize: "20px",
                                marginRight: "0.7rem",
                                marginLeft: "0.8rem",
                              }}
                            />
                          ) : (
                            <BsBook
                              style={{
                                color: "green",
                                fontSize: "20px",
                                marginRight: "0.7rem",
                                marginLeft: "0.8rem",
                              }}
                            />
                          )}
                        </div>
                        <div className="flex2-div-content">
                          {" "}
                          <h2
                            onClick={() =>
                              moduleClickBackHandler(sub_item.title)
                            }
                          >
                            {sub_item.title}{" "}
                          </h2>
                          <div className="progress-bar-module">
                            {sub_item.isStarted ? (
                              <React.Fragment>
                                <ProgressBar
                                  completed={sub_item.percentageComplete}
                                  height={7}
                                  bgColor="#1d3557"
                                  isLabelVisible={false}
                                />

                                <span
                                  style={{
                                    color: "#rgb(31, 31, 71)",
                                    marginTop: "0.2rem",
                                  }}
                                >
                                  {sub_item.percentageComplete}% Complete
                                </span>
                              </React.Fragment>
                            ) : (
                              <span>NOT STARTED</span>
                            )}
                          </div>
                          <div className="flex1-video-grid">
                            <ul>
                              {sub_item.lectures.map((p, index) => {
                                return (
                                  <li
                                    onClick={() =>
                                      courseHandler(
                                        userReactModule.title,
                                        sub_item.title,
                                        index
                                      )
                                    }
                                  >
                                    <FiPlayCircle
                                      style={{
                                        fontSize: "18px",
                                        marginRight: "0.4rem",
                                      }}
                                    />
                                    {sub_item.title} Lecture {index + 1}
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        </div>
                        <div className="flex3-div-content">
                          <IoIosArrowUp
                            onClick={() =>
                              moduleClickBackHandler(sub_item.title)
                            }
                          />
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div
                        onClick={() => moduleClickHandler(sub_item.title)}
                        className="module-flex1-div"
                      >
                        <div className="flex1-div-content">
                          {sub_item.percentageComplete == 100 ? (
                            <TiTickOutline
                              style={{
                                color: "green",
                                fontSize: "20px",
                                marginRight: "0.7rem",
                                marginLeft: "0.8rem",
                              }}
                            />
                          ) : (
                            <BsBook
                              style={{
                                color: "green",
                                fontSize: "20px",
                                marginRight: "0.7rem",
                                marginLeft: "0.8rem",
                              }}
                            />
                          )}
                        </div>
                        <div className="flex2-div-content">
                          {" "}
                          <h2>{sub_item.title} </h2>
                          <div className="progress-bar-module">
                            {sub_item.isStarted ? (
                              <React.Fragment>
                                <ProgressBar
                                  completed={sub_item.percentageComplete}
                                  height={7}
                                  bgColor="#1d3557"
                                  isLabelVisible={false}
                                />

                                <span
                                  style={{
                                    color: "#rgb(31, 31, 71)",
                                    marginTop: "0.2rem",
                                  }}
                                >
                                  {sub_item.percentageComplete}% Complete
                                </span>
                              </React.Fragment>
                            ) : (
                              <span>NOT STARTED</span>
                            )}
                          </div>
                        </div>
                        <div className="flex3-div-content">
                          <IoIosArrowDown style={{ fontSize: "20px" }} />
                        </div>
                      </div>
                    );
                  }
                })}
          </Scrollbars>
        </div>
      </div>
    </div>
  );
};

export default CourseContent;
