import React, { useState, useContext, useEffect, useMemo } from "react";
import { useHttpClient } from "../../../../../../../Components/Hooks/http-hook";
import { AuthContext } from "../../../../../../../context/auth-context";
import Button from "../../../../../../../Components/Profile/Button/Button";
import "./AddDoubt.css";
import swal from "sweetalert2";

const URGENCY_OPTIONS = [
    { value: "low", label: "Low" },
    { value: "medium", label: "Medium" },
    { value: "high", label: "High" }
];

const DOUBT_TYPES = [
    { value: "lecture", label: "Lecture Related" },
    { value: "assignment", label: "Assignment Related" },
    { value: "certificate", label: "Certificate Related" },
    { value: "other", label: "Other" }
];

const AddDoubt = ({ onClose, onDoubtAdded }) => {
    const auth = useContext(AuthContext);
    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const [formData, setFormData] = useState({
        title: "",
        description: "",
        course: "",
        pathway: "",
        module: "",
        class: "",
        urgency: "",
        type: "",
        attachments: []
    });
    const [formErrors, setFormErrors] = useState({});
    const [courses, setCourses] = useState([]);
    const [pathways, setPathways] = useState([]);
    const [modules, setModules] = useState([]);
    const [classes, setClasses] = useState([]);
    const [loadingPathways, setLoadingPathways] = useState(false);
    const [loadingModules, setLoadingModules] = useState(false);
    const [loadingClasses, setLoadingClasses] = useState(false);

    const enroll_info = auth.course_ids_info;
    const course_ids = useMemo(() => enroll_info.map((item) => item.program_id), [enroll_info]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => {
            const newData = {
                ...prev,
                [name]: value,
            };
            
            if (name === 'course') {
                newData.pathway = '';
                newData.module = '';
                newData.class = '';
                setPathways([]);
                setModules([]);
                setClasses([]);
            } else if (name === 'pathway') {
                newData.module = '';
                newData.class = '';
                setModules([]);
                setClasses([]);
            } else if (name === 'module') {
                newData.class = '';
                setClasses([]);
            }
            
            return newData;
        });

        if (formErrors[name]) {
            setFormErrors(prev => ({
                ...prev,
                [name]: '',
            }));
        }
    };

    const handleFileChange = (e) => {
        const files = Array.from(e.target.files);
        setFormData(prev => ({
            ...prev,
            attachments: [...prev.attachments, ...files]
        }));
    };

    const removeAllFiles = () => {
        setFormData(prev => ({
            ...prev,
            attachments: []
        }));
    };

    const validateForm = () => {
        const errors = {};
        if (!formData.title.trim()) {
            errors.title = "Title is required";
        }
        if (!formData.description.trim()) {
            errors.description = "Description is required";
        }
        if (!formData.course) {
            errors.course = "Course is required";
        }
        if (!formData.pathway) {
            errors.pathway = "Pathway is required";
        }
        if (!formData.module) {
            errors.module = "Module is required";
        }
        if (!formData.class) {
            errors.class = "Class is required";
        }
        if (!formData.urgency) {
            errors.urgency = "Urgency is required";
        }
        if (!formData.type) {
            errors.type = "Type is required";
        }
        return errors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const errors = validateForm();
        if (Object.keys(errors).length > 0) {
            setFormErrors(errors);
            return;
        }

        const hasEmptyField = Object.entries(formData).some(([key, value]) => 
            key !== 'attachments' && (!value || (typeof value === 'string' && !value.trim()))
        );

        if (hasEmptyField) {
            swal.fire({
                title: "Error",
                text: "Please fill in all required fields",
                icon: "error",
            });
            return;
        }

        const formDataToSend = new FormData();
        Object.keys(formData).forEach(key => {
            if (key !== 'attachments') {
                formDataToSend.append(key, formData[key]);
            }
        });

        formData.attachments.forEach(file => {
            formDataToSend.append('attachments', file);
        });

        try {
            const responseData = await sendRequest(
                `${process.env.REACT_APP_BACKEND_URL}/auth/user/queries`,
                "POST",
                formDataToSend,
                {
                    "Refresh-Token": auth.refresh_token,
                    Authorization: auth.token,
                }
            );

            if (responseData.success) {
                onDoubtAdded && onDoubtAdded();
                onClose();
            }
        } catch (err) {
            console.log(err);
        }
    };

    const isFormValid = () => {
        const hasEmptyField = Object.entries(formData).some(([key, value]) => 
            key !== 'attachments' && (!value || (typeof value === 'string' && !value.trim()))
        );

        const errors = validateForm();
        const hasErrors = Object.keys(errors).length > 0;

        return !hasEmptyField && !hasErrors && !isLoading;
    };

    useEffect(() => {
        const fetchCourses = async () => {
            try {
                const responseData = await sendRequest(
                    `${process.env.REACT_APP_BACKEND_URL}/db/prog-titles`,
                    "POST",
                    JSON.stringify({ course_ids }),
                    {
                        "Content-Type": "application/json",
                        "Refresh-Token": auth.refresh_token,
                        Authorization: auth.token,
                    }
                );
                if (responseData.ok) {
                    const formattedCourses = responseData.data.map(course => ({
                        value: course.course_id,
                        label: course.course_name
                    }));
                    setCourses(formattedCourses);
                }
            } catch (err) {
                console.log(err);
            }
        };
        fetchCourses();
    }, [sendRequest, auth.refresh_token, auth.token, course_ids]);

    useEffect(() => {
        const fetchPathways = async () => {
            if (!formData.course) return;
            
            setLoadingPathways(true);
            try {
                const responseData = await sendRequest(
                    `${process.env.REACT_APP_BACKEND_URL}/db/sections/${formData.course}`,
                    "GET",
                    null,
                    {
                        "Refresh-Token": auth.refresh_token,
                        Authorization: auth.token,
                    }
                );
                if (responseData.ok) {
                    setPathways(responseData.data.map(pathway => ({
                        value: pathway.section_id,
                        label: pathway.section_name
                    })));
                }
            } catch (err) {
                console.log(err);
            }
            setLoadingPathways(false);
        };
        fetchPathways();
    }, [formData.course]);

    useEffect(() => {
        const fetchModules = async () => {
            if (!formData.pathway || !formData.course) return;
            
            setLoadingModules(true);
            try {
                const responseData = await sendRequest(
                    `${process.env.REACT_APP_BACKEND_URL}/db/modules/${formData.pathway}`,
                    "GET",
                    null,
                    {
                        "Refresh-Token": auth.refresh_token,
                        Authorization: auth.token,
                    }
                );
                if (responseData.ok) {
                    setModules(responseData.data.map(module => ({
                        value: module.module_id,
                        label: module.module_name
                    })));
                }
            } catch (err) {
                console.log(err);
            }
            setLoadingModules(false);
        };
        fetchModules();
    }, [formData.pathway, formData.course]);

    useEffect(() => {
        const fetchClasses = async () => {
            if (!formData.module || !formData.pathway || !formData.course) return;
            
            setLoadingClasses(true);
            try {
                const responseData = await sendRequest(
                    `${process.env.REACT_APP_BACKEND_URL}/db/classes/${formData.module}`,
                    "GET",
                    null,
                    {
                        "Refresh-Token": auth.refresh_token,
                        Authorization: auth.token,
                    }
                );
                if (responseData.ok) {
                    setClasses(responseData.data.map(cls => ({
                        value: cls.class_id,
                        label: cls.class_name
                    })));
                }
            } catch (err) {
                console.log(err);
            }
            setLoadingClasses(false);
        };
        fetchClasses();
    }, [formData.module, formData.pathway, formData.course]);

    return (
        <div className="sk-add-doubt-container">
            <form onSubmit={handleSubmit} className="sk-add-doubt-form">
                <div className="sk-add-doubt-form-row">
                    <div className="sk-add-doubt-form-group">
                        <select
                            id="course"
                            name="course"
                            value={formData.course}
                            onChange={handleInputChange}
                            className={formErrors.course ? "error" : ""}
                            disabled={isLoading}
                        >
                            <option value="">Select Course*</option>
                            {courses.map(option => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </select>
                        {formErrors.course && (
                            <span className="sk-add-doubt-error">{formErrors.course}</span>
                        )}
                    </div>

                    <div className="sk-add-doubt-form-group">
                        <select
                            id="pathway"
                            name="pathway"
                            value={formData.pathway}
                            onChange={handleInputChange}
                            className={formErrors.pathway ? "error" : ""}
                            disabled={!formData.course || loadingPathways}
                        >
                            <option value="">Select Pathway*</option>
                            {pathways.map(option => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </select>
                        {formErrors.pathway && (
                            <span className="sk-add-doubt-error">{formErrors.pathway}</span>
                        )}
                    </div>

                    <div className="sk-add-doubt-form-group">
                        <select
                            id="module"
                            name="module"
                            value={formData.module}
                            onChange={handleInputChange}
                            className={formErrors.module ? "error" : ""}
                            disabled={!formData.pathway || loadingModules}
                        >
                            <option value="">Select Module*</option>
                            {modules.map(option => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </select>
                        {formErrors.module && (
                            <span className="sk-add-doubt-error">{formErrors.module}</span>
                        )}
                    </div>

                    <div className="sk-add-doubt-form-group">
                        <select
                            id="class"
                            name="class"
                            value={formData.class}
                            onChange={handleInputChange}
                            className={formErrors.class ? "error" : ""}
                            disabled={!formData.module || loadingClasses}
                        >
                            <option value="">Select Class*</option>
                            {classes.map(option => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </select>
                        {formErrors.class && (
                            <span className="sk-add-doubt-error">{formErrors.class}</span>
                        )}
                    </div>

                    <div className="sk-add-doubt-form-group">
                        <select
                            id="urgency"
                            name="urgency"
                            value={formData.urgency}
                            onChange={handleInputChange}
                            className={formErrors.urgency ? "error" : ""}
                        >
                            <option value="">Select Urgency*</option>
                            {URGENCY_OPTIONS.map(option => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </select>
                        {formErrors.urgency && (
                            <span className="sk-add-doubt-error">{formErrors.urgency}</span>
                        )}
                    </div>

                    <div className="sk-add-doubt-form-group">
                        <select
                            id="type"
                            name="type"
                            value={formData.type}
                            onChange={handleInputChange}
                            className={formErrors.type ? "error" : ""}
                        >
                            <option value="">Select Type*</option>
                            {DOUBT_TYPES.map(option => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </select>
                        {formErrors.type && (
                            <span className="sk-add-doubt-error">{formErrors.type}</span>
                        )}
                    </div>
                </div>

                <div className="sk-add-doubt-form-group">
                    <label htmlFor="title">Doubt Title*</label>
                    <input
                        type="text"
                        id="title"
                        name="title"
                        value={formData.title}
                        onChange={handleInputChange}
                        placeholder="Enter the title of your doubt"
                        className={formErrors.title ? "error" : ""}
                    />
                    {formErrors.title && (
                        <span className="sk-add-doubt-error">{formErrors.title}</span>
                    )}
                </div>

                <div className="sk-add-doubt-form-group">
                    <label htmlFor="description">Doubt Description*</label>
                    <textarea
                        id="description"
                        name="description"
                        value={formData.description}
                        onChange={handleInputChange}
                        placeholder="Describe your doubt in detail"
                        rows="6"
                        className={formErrors.description ? "error" : ""}
                    />
                    {formErrors.description && (
                        <span className="sk-add-doubt-error">{formErrors.description}</span>
                    )}
                </div>

                <div className="sk-add-doubt-form-group">
                    <label>Upload Files (Optional)</label>
                    <div className="sk-add-doubt-file-upload">
                        <input
                            type="file"
                            multiple
                            placeholder="Upload files (PDF, DOC, DOCX, TXT, PNG, JPG, JPEG, ZIP)"
                            onChange={handleFileChange}
                            accept=".pdf,.doc,.docx,.txt,.png,.jpg,.jpeg,.zip"
                        />
                    </div>  
                    <div>
                    {formData.attachments.length > 0 && (
                        <div className="sk-add-doubt-file-list">
                            <div className="sk-add-doubt-file-header">
                                <span>{formData.attachments.length} file(s) selected</span>
                                <button
                                    type="button"
                                    onClick={removeAllFiles}
                                    className="sk-add-doubt-remove-all"
                                >
                                    Remove All ×
                                </button>
                            </div>
                            </div>
                                    )}
                    </div>
                </div>

                <div className="sk-add-doubt-actions">
                    <Button
                        text={isLoading ? "Submitting..." : "Submit"}
                        type="submit"
                        width="80%"
                        disabled={!isFormValid()}
                        onClick={handleSubmit}
                    />
                </div>
            </form>
        </div>
    );
};

export default AddDoubt;
