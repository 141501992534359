import React, { useState } from "react";
import "./PracticeContent.css";
import { useNavigate, useParams } from "react-router-dom";
import MathJax from "react-mathjax";

const PracticeContent = () => {
  const history = useNavigate();

  let { mid } = useParams();

  const TopicList = [
    {
      name: "Problems on Trains",
      topic_text:
        "Similar to the concept of speed, distance and time, train problems are specifically based on evaluating the speed, distance covered and time is taken by a train under different conditions. Train problems form an integral part of the time and speed questions which are frequently asked in the quantitative aptitude section of various Government exams. These questions are different from the basic speed, distance and time questions and require a different approach to be answered.",
      theory_array: [
        {
          th_heading: "Time, Speed and Distance: Problems on Trains",
          isPoint: false,
          th_text: [
            {
              text:
                "In this article, you will learn the concepts related to an important topic in Time, Speed and Distance i.e. Trains.",
              math_formula: "",
            },
            {
              text:
                "Problems on trains are very common in competitive exams. Various types of questions are asked on trains. ",
              math_formula:
                "f(x) = \\int_{-\\infty}^\\infty\\hat f(\\xi)\\,e^{2 \\pi i \\xi x}\\,d\\xi",
            },
            {
              text:
                "Questions on trains are solved using the concept of time, speed and distance i.e. we use the formulas of time, speed and distance to solve questions on trains.",
              math_formula: "",
            },
            {
              text:
                "Given below is a list of some important points that need to be kept in mind while solving questions on trains.",
              math_formula: "",
            },
          ],
        },
        {
          th_heading: "Important Points",
          isPoint: true,
          th_text: [
            {
              text:
                "When two trains are going in the same direction, then their relative speed is the difference between the two speeds.",
              math_formula: "k_{n+1} = n^2 + k_n^2 - k_{n-1}",
            },
            {
              text:
                "When two trains are moving in the opposite direction, then their relative speed is the sum of the two speeds.",
              math_formula: "",
            },
            {
              text:
                "When a train crosses a stationary man/ pole/ lamp post/ sign post- in all these cases, the object which the train crosses is stationary and the distance travelled is the length of the train.",
              math_formula: "",
            },
            {
              text:
                "When it crosses a platform/ bridge- in these cases, the object which the train crosses is stationary and the distance travelled is the length of the train and the length of the object.",
              math_formula: "",
            },
            {
              text:
                "When two trains are moving in same direction, then their speed will be subtracted.",
              math_formula: "",
            },
            {
              text:
                "When two trains are moving in opposite directions, then their speed will be added.",
              math_formula: "",
            },
            {
              text:
                "In both the above cases, the total distance is the sum of the length of both the trains.",
              math_formula: "distance, \\ x= \\ \\frac{1}{\\sqrt{x^2 + 1}}\\",
            },
            {
              text:
                "When a train crosses a car/ bicycle/ a mobile man- in these cases, the relative speed between the train and the object is taken depending upon the direction of the movement of the other object relative to the train- and the distance travelled is the length of the train.",
              math_formula: "",
            },
            {
              text:
                "Now, let us try doing some questions and understand the concept of solving train related problems",
              math_formula: "",
            },
          ],
        },
        {
          th_heading: "Solved Questions",
          isPoint: false,
          th_text: [
            {
              text:
                "Question 1: A train travelling at 60 kmph crosses a man in 6 seconds. What is the length of the train?",
              math_formula: "",
            },
            {
              text: "Solution: Speed in m/sec = 60 *(5/18) = 50/3 m/sec ",
              math_formula: "",
            },
            {
              text: "Time taken to cross the man = 6 secs",
              math_formula: "",
            },
            {
              text:
                "Therefore, Distance = (50/3)* 6 = 100 metres (i.e. the length of the train)",
              math_formula: "",
            },
            {
              text:
                "Question 2: A train travelling at 60 kmph crosses another train travelling in the same direction at 50 kmph in 30 seconds. What is the combined length of both the trains?",
              math_formula: "",
            },
            {
              text:
                "Solution: Speed of train A = 60 kmph = 60* (5/18) = 50/3 m/sec",
              math_formula: "",
            },
            {
              text: "Speed of train B = = 50 kmph = 50 *(5/18) = 125/9 m/sec",
              math_formula: "",
            },
            {
              text:
                "The relative speed =(50/3)-(125/9)=25/9 m/s (we have subtracted the two values because both the trains are going in the same direction)",
              math_formula: "",
            },
            {
              text: "Time taken by train A to cross train B = 30 secs",
              math_formula: "",
            },
            {
              text: "Distance = Speed * Time",
              math_formula: "",
            },
            {
              text:
                "Distance =25/9 * 30 = 250/3 metres (i.e. the combined length of both trains)",
              math_formula: "",
            },
            {
              text:
                "Question 3: The average speed of a train without stoppages is 48 kmph and average speed with stoppages is 40 kmph. How many minutes in an hour the train stops on an average?",
              math_formula: "",
            },
            {
              text:
                "Solution: If the train were to travel without stoppages, it would cover 48 km.",
              math_formula: "",
            },
            {
              text:
                "With stoppages, the average speed reduces by (48-40) = 8 km",
              math_formula: "",
            },
            {
              text: "Therefore, (8/48)* 60 minutes = 10 minutes",
              math_formula: "",
            },
            {
              text:
                "Hence, 10 minutes would be the time per hour the train stops on an average.",
              math_formula: "",
            },
            {
              text:
                "Question 4: A train travelling at 60 kmph crosses a man in 6 seconds. What is the length of the train?",
              math_formula: "",
            },
            {
              text: "Solution: Speed in m/sec = 60 *(5/18) = 50/3 m/sec ",
              math_formula: "",
            },
            {
              text: "Time taken to cross the man = 6 secs",
              math_formula: "",
            },
            {
              text:
                "Therefore, Distance = (50/3)* 6 = 100 metres (i.e. the length of the train)",
              math_formula: "",
            },
            {
              text:
                "Question 5: A train travelling at 60 kmph crosses another train travelling in the same direction at 50 kmph in 30 seconds. What is the combined length of both the trains?",
              math_formula: "",
            },
            {
              text:
                "Solution: Speed of train A = 60 kmph = 60* (5/18) = 50/3 m/sec",
              math_formula: "",
            },
            {
              text: "Speed of train B = = 50 kmph = 50 *(5/18) = 125/9 m/sec",
              math_formula: "",
            },
            {
              text:
                "The relative speed =(50/3)-(125/9)=25/9 m/s (we have subtracted the two values because both the trains are going in the same direction)",
              math_formula: "",
            },
            {
              text: "Time taken by train A to cross train B = 30 secs",
              math_formula: "",
            },
            {
              text: "Distance = Speed * Time",
              math_formula: "",
            },
            {
              text:
                "Distance =25/9 * 30 = 250/3 metres (i.e. the combined length of both trains)",
              math_formula: "",
            },
            {
              text:
                "Question 6: The average speed of a train without stoppages is 48 kmph and average speed with stoppages is 40 kmph. How many minutes in an hour the train stops on an average?",
              math_formula: "",
            },
            {
              text:
                "Solution: If the train were to travel without stoppages, it would cover 48 km.",
              math_formula: "",
            },
            {
              text:
                "With stoppages, the average speed reduces by (48-40) = 8 km",
              math_formula: "",
            },
            {
              text: "Therefore, (8/48)* 60 minutes = 10 minutes",
              math_formula: "",
            },
            {
              text:
                "Hence, 10 minutes would be the time per hour the train stops on an average.",
              math_formula: "",
            },
            {
              text:
                "Question 8: A train travelling at 60 kmph crosses another train travelling in the same direction at 50 kmph in 30 seconds. What is the combined length of both the trains?",
              math_formula: "",
            },
            {
              text:
                "Solution: Speed of train A = 60 kmph = 60* (5/18) = 50/3 m/sec",
              math_formula: "",
            },
            {
              text: "Speed of train B = = 50 kmph = 50 *(5/18) = 125/9 m/sec",
              math_formula: "",
            },
            {
              text:
                "The relative speed =(50/3)-(125/9)=25/9 m/s (we have subtracted the two values because both the trains are going in the same direction)",
              math_formula: "",
            },
            {
              text: "Time taken by train A to cross train B = 30 secs",
              math_formula: "",
            },
            {
              text: "Distance = Speed * Time",
              math_formula: "",
            },
            {
              text:
                "Distance =25/9 * 30 = 250/3 metres (i.e. the combined length of both trains)",
              math_formula: "",
            },
            {
              text:
                "Question 8: The average speed of a train without stoppages is 48 kmph and average speed with stoppages is 40 kmph. How many minutes in an hour the train stops on an average?",
              math_formula: "",
            },
            {
              text:
                "Solution: If the train were to travel without stoppages, it would cover 48 km.",
              math_formula: "",
            },
            {
              text:
                "With stoppages, the average speed reduces by (48-40) = 8 km",
              math_formula: "",
            },
            {
              text: "Therefore, (8/48)* 60 minutes = 10 minutes",
              math_formula: "",
            },
            {
              text:
                "Hence, 10 minutes would be the time per hour the train stops on an average.",
              math_formula: "",
            },
          ],
        },
      ],
      questions: [
        {
          ques_no: 1,
          is_ques_text_mathematical: false,
          quesText:
            "A can do a work in 15 days and B in 20 days. If they work on it together for 4 days, then the fraction of the work that is left is :",
          is_options_mathematical: true,
          options: [
            {
              option_No: "A",
              val: "\\frac{1}{4}",
            },
            {
              option_No: "B",
              val: "\\frac{1}{10}",
            },
            {
              option_No: "C",
              val: "\\frac{7}{15}",
            },
            {
              option_No: "D",
              val: "\\frac{8}{15}",
            },
          ],
          correct_option: "D",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 2,
          is_ques_text_mathematical: false,
          quesText:
            "A can lay railway track between two given stations in 16 days and B can do the same job in 12 days. With help of C, they did the job in 4 days only. Then, C alone can do the job in:",
          is_options_mathematical: true,
          options: [
            {
              option_No: "A",
              val: "{9}\\frac{1}{5}",
            },
            {
              option_No: "B",
              val: "{9}\\frac{2}{5}",
            },
            {
              option_No: "C",
              val: "{9}\\frac{3}{5}",
            },
            {
              option_No: "D",
              val: "10",
            },
          ],
          correct_option: "C",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
      ],
    },
    {
      name: "Height and Distance",
      topic_text:
        "Similar to the concept of speed, distance and time, train problems are specifically based on evaluating the speed, distance covered and time is taken by a train under different conditions. Train problems form an integral part of the time and speed questions which are frequently asked in the quantitative aptitude section of various Government exams. These questions are different from the basic speed, distance and time questions and require a different approach to be answered.",
      theory_array: [
        {
          th_heading: "Heights and Distances: Theory & Concepts",
          isPoint: false,
          th_text: [
            {
              text:
                "Learn the important concepts and tricks to solve questions based on heights and distances",
              math_formula: "",
            },
            {
              text:
                "The topic of height and distance in trigonometry is an important topic from competitive examination point of view. ",
              math_formula: "",
            },
            {
              text:
                "Generally you must have seen the problems where the height of a building is given and then from the top of this building the angles of elevation or depression are given for another ",
              math_formula: "",
            },
            {
              text:
                "building and you have to find the height of the second building. In this article we will cover these problems.",
              math_formula: "",
            },
            {
              text:
                "There are certain terms associated with the heights and distances which are described as follows:",
              math_formula: "",
            },
          ],
        },
        {
          th_heading: "Angle of Elevation:",
          isPoint: false,
          th_text: [
            {
              text:
                "Let us consider a situation where a person is standing on the ground and he is looking at an object which is at some height say the top of the building.",
              math_formula: "",
            },
            {
              text:
                "The line joining the eye of the man with the top of the building is called the line of sight. ",
              math_formula: "",
            },
            {
              text:
                "The angle made by the line of sight with the horizontal line is called angle of elevation.",
              math_formula: "",
            },
            {
              text: "",
              math_formula: "",
            },
            {
              text: "",
              math_formula: "",
            },
            {
              text: "",
              math_formula: "",
            },
            {
              text: "",
              math_formula: "",
            },
            {
              text: "",
              math_formula: "",
            },
            {
              text: "",
              math_formula: "",
            },
          ],
        },
        {
          th_heading: "Angle of Depression: ",
          isPoint: false,
          th_text: [
            {
              text:
                "Now let us take another situation where the person is standing at some height with respect to the object he is seeing.",
              math_formula: "",
            },
            {
              text: "",
              math_formula:
                " In this case again the line joining the eye of the man with the bottom of the building is called the line of sight.",
            },
            {
              text:
                "The angle made by the line of sight with the horizontal line is called angle of depression, where ‘θ’ is the angle of depression.",
              math_formula: "",
            },
            {
              text:
                "Note: The angle of elevation is equal to the angle of depression.",
              math_formula: "",
            },
            {
              text:
                "The questions on this topic require basic knowledge of Trigonometry. You should be aware of the basic trigonometric ratios and their values.",
              math_formula: "",
            },
            {
              text:
                "Let us recall that the ratios of the sides of a right angled triangle are called trigonometric ratios. These are sine, cosine, tangent, cosecant, secant and cotangent.",
              math_formula: "",
            },
            {
              text: "Let the ΔABC is a right angled triangle.",
              math_formula: "",
            },
            {
              text: "",
              math_formula: "Then Sin θ = AB/AC",
            },
            {
              text: "",
              math_formula: "Cos θ = BC/AC",
            },
            {
              text: "",
              math_formula: "Tan θ = AB/BC",
            },
            {
              text: "",
              math_formula: "Cosec θ = AC/AB",
            },
            {
              text: "",
              math_formula: "Sec θ = AC/BC",
            },
            {
              text: "",
              math_formula: "Cot θ = BC/AB",
            },
          ],
        },
        {
          th_heading: "",
          isPoint: false,
          th_text: [
            {
              text:
                "The values in the table will be used while solving the questions on height and distances. Let us now discuss some basic problems on height and distances.",
              math_formula: "",
            },
            {
              text: "",
              math_formula:
                " After going through these examples you will learn how to measure the height and how to find the distance.",
            },
            {
              text: "",
              math_formula: "",
            },
            {
              text: "",
              math_formula: "",
            },
          ],
        },
      ],
    },
    {
      name: "Simple Interest",
      topic_text: "Simple Interest",
      theory_array: [
        {
          th_heading: "What Is Simple Interest?",
          isPoint: false,
          th_text: [
            {
              text:
                "Simple interest is a quick and easy method of calculating the interest charge on a loan.",
              math_formula: "",
            },
            {
              text:
                " Simple interest is determined by multiplying the daily interest rate by the principal by the number of days that elapse between payments.",
              math_formula: "",
            },
            {
              text:
                "This type of interest usually applies to automobile loans or short-term loans, although some mortgages use this calculation method.",
              math_formula: "",
            },
            {
              text: "",
              math_formula: "",
            },
            {
              text: "",
              math_formula: "",
            },
            {
              text: "",
              math_formula: "",
            },
          ],
        },
        {
          th_heading: "KEY TAKEAWAYS",
          isPoint: true,
          th_text: [
            {
              text:
                "Simple interest is calculated by multiplying the daily interest rate by the principal, by the number of days that elapse between payments.",
              math_formula: "",
            },
            {
              text:
                "Simple interest benefits consumers who pay their loans on time or early each month. ",
              math_formula: "",
            },
            {
              text:
                "Auto loans and short-term personal loans are usually simple interest loans. ",
              math_formula: "",
            },
            {
              text: "",
              math_formula: "",
            },
            {
              text: "",
              math_formula: "",
            },
            {
              text: "",
              math_formula: "",
            },
          ],
        },
        {
          th_heading: "Understanding Simple Interest ",
          isPoint: false,
          th_text: [
            {
              text:
                "Interest is the cost of borrowing money. Typically expressed as a percentage, it amounts to a fee or extra charge the borrower pays the lender for the financed sum. ",
              math_formula: "",
            },
            {
              text:
                "When you make a payment on a simple interest loan, the payment first goes toward that month’s interest, and the remainder goes toward the principal. Each month’s interest is paid in full so it never accrues.",
              math_formula: "",
            },
            {
              text:
                "In contrast, compound interest adds some of the monthly interest back onto the loan; in each succeeding month, you pay new interest on old interest.",
              math_formula: "",
            },
            {
              text:
                "To understand how simple interest works, consider an automobile loan that has a $15,000 principal balance and an annual 5% simple interest rate.",
              math_formula: "",
            },
            {
              text:
                " If your payment is due on May 1 and you pay it precisely on the due date, the finance company calculates your interest on the 30 days in April.",
              math_formula: "",
            },
            {
              text: "Your interest for 30 days is $61.64 under this scenario.",
              math_formula: "",
            },
            {
              text:
                " However, if you make the payment on April 21, the finance company charges you interest for only 20 days in April, dropping your interest payment to $41.09, a $20 savings.",
              math_formula: "",
            },
            {
              text: "",
              math_formula: "",
            },
          ],
        },
        {
          th_heading: "Simple Interest Formula and Example",
          isPoint: true,
          th_text: [
            {
              text:
                "The formula for simple interest is pretty, well, simple. It looks like this:",
              math_formula: "Simple Interest=P×I×N",
            },
            {
              text: "where:",
              math_formula: "P=principle",
            },
            {
              text: "",
              math_formula: "I = daily interest rate",
            },
            {
              text: "",
              math_formula: "N = Number of days between payment",
            },
            {
              text:
                "Generally, simple interest paid or received over a certain period is a fixed percentage of the principal amount that was borrowed or lent. ",
              math_formula: "",
            },
            {
              text:
                "For example, say a student obtains a simple-interest loan to pay one year of college tuition, which costs $18,000, and the annual interest rate on the loan is 6%. ",
              math_formula: "",
            },
            {
              text:
                "The student repays the loan over three years. The amount of simple interest paid is:",
              math_formula: "$3,240=$18,000×0.06×3",
            },
            {
              text: "and the total amount paid is:",
              math_formula: "$21,240=$18,000+$3,240",
            },
          ],
        },
        {
          th_heading: "Who Benefits From a Simple Interest Loan?",
          isPoint: false,
          th_text: [
            {
              text:
                "Under the student-loan scenario above, if you sent a $300 payment on May 1, then $238.36 goes toward the principal. If you sent the same payment on April 20, then $258.91 goes toward the principal. ",
              math_formula: "",
            },
            {
              text:
                "If you can pay early every month, your principal balance shrinks faster, and you pay the loan off sooner than the original estimate.",
              math_formula: "",
            },
            {
              text:
                "Conversely, if you pay the loan late, more of your payment goes toward interest than if you pay on time.",
              math_formula: "",
            },
            {
              text:
                "Using the same automobile loan example, if your payment is due on May 1 and you make it on May 16, you get charged for 45 days of interest at a cost of $92.46. ",
              math_formula: "",
            },
            {
              text:
                "This means only $207.54 of your $300 payment goes toward the principal. ",
              math_formula: "",
            },
            {
              text:
                "If you consistently pay late over the life of a loan, your final payment will be larger than the original estimate because you did not pay down the principal at the expected rate.",
              math_formula: "",
            },
          ],
        },
        {
          th_heading: "What Types of Loans Use Simple Interest?",
          isPoint: false,
          th_text: [
            {
              text:
                "Simple interest usually applies to automobile loans or short-term personal loans. In the U.S., most mortgages on an amortization schedule are also simple interest loans, although they can certainly feel like compound interest ones.",
              math_formula: "",
            },
            {
              text:
                "The compounding feel comes from varying principal payments—that is, the percentage of your mortgage payment that's actually going towards the loan itself, not the interest. ",
              math_formula: "",
            },
            {
              text: "The interest doesn’t compound; the principal payments do.",
              math_formula: "",
            },
            {
              text:
                "A $1,000 principal payment saves interest on that $1,000 and results in higher principal payments the next year, and higher the following year, and so on.",
              math_formula: "",
            },
            {
              text:
                "If you don’t let the principal payments vary, as in an interest-only loan (zero principal payment), or by equalizing the principal payments, the loan interest itself doesn’t compound.",
              math_formula: "",
            },
            {
              text:
                "Lowering the interest rate, shortening the loan term, or pre-paying principal also has a compounding effect.",
              math_formula: "",
            },
            {
              text: "For example, take bi-weekly mortgage payment plans. ",
              math_formula: "",
            },
            {
              text:
                "Bi-weekly plans generally help consumers pay off their mortgages early because the borrowers make two extra payments a year, saving interest over the life of the loan by paying off the principal faster.",
              math_formula: "",
            },
          ],
        },
        {
          th_heading: "Simple Interest vs. Compound Interest",
          isPoint: false,
          th_text: [
            {
              text:
                "Interest can be either simple or compounded. Simple interest is based on the original principal amount of a loan or deposit.",
              math_formula: "",
            },
            {
              text:
                "Compound interest, on the other hand, is based on the principal amount and the interest that accumulates on it in every period.",
              math_formula: "",
            },
            {
              text:
                "Simple interest is calculated only on the principal, so it is easier to determine than compound interest.",
              math_formula: "",
            },
            {
              text:
                "In real-life situations, compound interest is often a factor in business transactions, investments, and financial products intended to extend for multiple periods or years.",
              math_formula: "",
            },
            {
              text:
                "Simple interest is mainly used for easy calculations: those generally for a single period or less than a year. Simple interest also applies to open-ended situations, such as credit card balances.",
              math_formula: "",
            },
          ],
        },
        {
          th_heading: "Why is Simple Interest 'Simple'?",
          isPoint: false,
          th_text: [
            {
              text:
                "'Simple' interest refers to the straightforward crediting of cash flows associated with some investment or deposit. ",
              math_formula: "",
            },
            {
              text:
                "For instance, 1% annual simple interest would credit $1 for every $100 invested, year after year. ",
              math_formula: "",
            },
            {
              text:
                " Simple interest does not, however, take into account the power of compounding, or interest-on-interest, where after the first year the 1% would actually be earned on the $101 balance—adding up to $1.01. ",
              math_formula: "",
            },
            {
              text:
                "The next year, the 1% would be earned on $102.01, amounting to $1.02. And so one.",
              math_formula: "",
            },
          ],
        },
        {
          th_heading:
            "Which Will Pay Out More Over Time, Simple or Compound Interest?",
          isPoint: false,
          th_text: [
            {
              text:
                "Compound interest will always pay more after the first payment period. ",
              math_formula: "",
            },
            {
              text:
                "Suppose you borrow $10,000 at a 10% annual interest rate with the principal and interest due as a lump sum in three years. ",
              math_formula: "",
            },
            {
              text:
                "Using a simple interest calculation, 10% of the principal balance gets added to your repayment amount during each of the three years. ",
              math_formula: "",
            },
            {
              text:
                "That comes out to $1,000 per year, which totals $3,000 in interest over the life of the loan. At repayment, then, the amount due is $13,000. ",
              math_formula: "",
            },
            {
              text:
                "Now suppose you take out the same loan, with the same terms, but the interest is compounded annually. When the loan is due, instead of owing $13,000, you end up owing $13,310.",
              math_formula: "",
            },
            {
              text:
                " While you may not consider $310 a huge difference, this example is only a three-year loan; compound interest piles up and becomes oppressive with longer loan terms.",
              math_formula: "",
            },
          ],
        },
      ],
    },
    {
      name: "Profit and Loss",
      topic_text:
        "Similar to the concept of speed, distance and time, train problems are specifically based on evaluating the speed, distance covered and time is taken by a train under different conditions. Train problems form an integral part of the time and speed questions which are frequently asked in the quantitative aptitude section of various Government exams. These questions are different from the basic speed, distance and time questions and require a different approach to be answered.",
      theory_array: [
        {
          th_heading: "Time, Speed and Distance: Problems on Trains",
          isPoint: false,
          th_text: [
            {
              text:
                "In this article, you will learn the concepts related to an important topic in Time, Speed and Distance i.e. Trains.",
              math_formula: "",
            },
            {
              text:
                "Problems on trains are very common in competitive exams. Various types of questions are asked on trains. ",
              math_formula: "",
            },
            {
              text:
                "Questions on trains are solved using the concept of time, speed and distance i.e. we use the formulas of time, speed and distance to solve questions on trains.",
              math_formula: "",
            },
            {
              text:
                "Given below is a list of some important points that need to be kept in mind while solving questions on trains.",
              math_formula: "",
            },
          ],
        },
        {
          th_heading: "Important Points",
          isPoint: true,
          th_text: [
            {
              text:
                "When two trains are going in the same direction, then their relative speed is the difference between the two speeds.",
              math_formula: "",
            },
            {
              text:
                "When two trains are moving in the opposite direction, then their relative speed is the sum of the two speeds.",
              math_formula: "",
            },
            {
              text:
                "When a train crosses a stationary man/ pole/ lamp post/ sign post- in all these cases, the object which the train crosses is stationary and the distance travelled is the length of the train.",
              math_formula: "",
            },
            {
              text:
                "When it crosses a platform/ bridge- in these cases, the object which the train crosses is stationary and the distance travelled is the length of the train and the length of the object.",
              math_formula: "",
            },
            {
              text:
                "When two trains are moving in same direction, then their speed will be subtracted.",
              math_formula: "",
            },
            {
              text:
                "When two trains are moving in opposite directions, then their speed will be added.",
              math_formula: "",
            },
            {
              text:
                "In both the above cases, the total distance is the sum of the length of both the trains.",
              math_formula: "",
            },
            {
              text:
                "When a train crosses a car/ bicycle/ a mobile man- in these cases, the relative speed between the train and the object is taken depending upon the direction of the movement of the other object relative to the train- and the distance travelled is the length of the train.",
              math_formula: "",
            },
            {
              text:
                "Now, let us try doing some questions and understand the concept of solving train related problems",
              math_formula: "",
            },
          ],
        },
        {
          th_heading: "Solved Questions",
          isPoint: false,
          th_text: [
            {
              text:
                "Question 1: A train travelling at 60 kmph crosses a man in 6 seconds. What is the length of the train?",
              math_formula: "",
            },
            {
              text: "Solution: Speed in m/sec = 60 *(5/18) = 50/3 m/sec ",
              math_formula: "",
            },
            {
              text: "Time taken to cross the man = 6 secs",
              math_formula: "",
            },
            {
              text:
                "Therefore, Distance = (50/3)* 6 = 100 metres (i.e. the length of the train)",
              math_formula: "",
            },
            {
              text:
                "Question 2: A train travelling at 60 kmph crosses another train travelling in the same direction at 50 kmph in 30 seconds. What is the combined length of both the trains?",
              math_formula: "",
            },
            {
              text:
                "Solution: Speed of train A = 60 kmph = 60* (5/18) = 50/3 m/sec",
              math_formula: "",
            },
            {
              text: "Speed of train B = = 50 kmph = 50 *(5/18) = 125/9 m/sec",
              math_formula: "",
            },
            {
              text:
                "The relative speed =(50/3)-(125/9)=25/9 m/s (we have subtracted the two values because both the trains are going in the same direction)",
              math_formula: "",
            },
            {
              text: "Time taken by train A to cross train B = 30 secs",
              math_formula: "",
            },
            {
              text: "Distance = Speed * Time",
              math_formula: "",
            },
            {
              text:
                "Distance =25/9 * 30 = 250/3 metres (i.e. the combined length of both trains)",
              math_formula: "",
            },
            {
              text:
                "Question 3: The average speed of a train without stoppages is 48 kmph and average speed with stoppages is 40 kmph. How many minutes in an hour the train stops on an average?",
              math_formula: "",
            },
            {
              text:
                "Solution: If the train were to travel without stoppages, it would cover 48 km.",
              math_formula: "",
            },
            {
              text:
                "With stoppages, the average speed reduces by (48-40) = 8 km",
              math_formula: "",
            },
            {
              text: "Therefore, (8/48)* 60 minutes = 10 minutes",
              math_formula: "",
            },
            {
              text:
                "Hence, 10 minutes would be the time per hour the train stops on an average.",
              math_formula: "",
            },
            {
              text:
                "Question 4: A train travelling at 60 kmph crosses a man in 6 seconds. What is the length of the train?",
              math_formula: "",
            },
            {
              text: "Solution: Speed in m/sec = 60 *(5/18) = 50/3 m/sec ",
              math_formula: "",
            },
            {
              text: "Time taken to cross the man = 6 secs",
              math_formula: "",
            },
            {
              text:
                "Therefore, Distance = (50/3)* 6 = 100 metres (i.e. the length of the train)",
              math_formula: "",
            },
            {
              text:
                "Question 5: A train travelling at 60 kmph crosses another train travelling in the same direction at 50 kmph in 30 seconds. What is the combined length of both the trains?",
              math_formula: "",
            },
            {
              text:
                "Solution: Speed of train A = 60 kmph = 60* (5/18) = 50/3 m/sec",
              math_formula: "",
            },
            {
              text: "Speed of train B = = 50 kmph = 50 *(5/18) = 125/9 m/sec",
              math_formula: "",
            },
            {
              text:
                "The relative speed =(50/3)-(125/9)=25/9 m/s (we have subtracted the two values because both the trains are going in the same direction)",
              math_formula: "",
            },
            {
              text: "Time taken by train A to cross train B = 30 secs",
              math_formula: "",
            },
            {
              text: "Distance = Speed * Time",
              math_formula: "",
            },
            {
              text:
                "Distance =25/9 * 30 = 250/3 metres (i.e. the combined length of both trains)",
              math_formula: "",
            },
            {
              text:
                "Question 6: The average speed of a train without stoppages is 48 kmph and average speed with stoppages is 40 kmph. How many minutes in an hour the train stops on an average?",
              math_formula: "",
            },
            {
              text:
                "Solution: If the train were to travel without stoppages, it would cover 48 km.",
              math_formula: "",
            },
            {
              text:
                "With stoppages, the average speed reduces by (48-40) = 8 km",
              math_formula: "",
            },
            {
              text: "Therefore, (8/48)* 60 minutes = 10 minutes",
              math_formula: "",
            },
            {
              text:
                "Hence, 10 minutes would be the time per hour the train stops on an average.",
              math_formula: "",
            },
            {
              text:
                "Question 8: A train travelling at 60 kmph crosses another train travelling in the same direction at 50 kmph in 30 seconds. What is the combined length of both the trains?",
              math_formula: "",
            },
            {
              text:
                "Solution: Speed of train A = 60 kmph = 60* (5/18) = 50/3 m/sec",
              math_formula: "",
            },
            {
              text: "Speed of train B = = 50 kmph = 50 *(5/18) = 125/9 m/sec",
              math_formula: "",
            },
            {
              text:
                "The relative speed =(50/3)-(125/9)=25/9 m/s (we have subtracted the two values because both the trains are going in the same direction)",
              math_formula: "",
            },
            {
              text: "Time taken by train A to cross train B = 30 secs",
              math_formula: "",
            },
            {
              text: "Distance = Speed * Time",
              math_formula: "",
            },
            {
              text:
                "Distance =25/9 * 30 = 250/3 metres (i.e. the combined length of both trains)",
              math_formula: "",
            },
            {
              text:
                "Question 8: The average speed of a train without stoppages is 48 kmph and average speed with stoppages is 40 kmph. How many minutes in an hour the train stops on an average?",
              math_formula: "",
            },
            {
              text:
                "Solution: If the train were to travel without stoppages, it would cover 48 km.",
              math_formula: "",
            },
            {
              text:
                "With stoppages, the average speed reduces by (48-40) = 8 km",
              math_formula: "",
            },
            {
              text: "Therefore, (8/48)* 60 minutes = 10 minutes",
              math_formula: "",
            },
            {
              text:
                "Hence, 10 minutes would be the time per hour the train stops on an average.",
              math_formula: "",
            },
          ],
        },
      ],
    },
    {
      name: "Percentage",
      topic_text:
        "Similar to the concept of speed, distance and time, train problems are specifically based on evaluating the speed, distance covered and time is taken by a train under different conditions. Train problems form an integral part of the time and speed questions which are frequently asked in the quantitative aptitude section of various Government exams. These questions are different from the basic speed, distance and time questions and require a different approach to be answered.",
      theory_array: [
        {
          th_heading: "Heights and Distances: Theory & Concepts",
          isPoint: false,
          th_text: [
            {
              text:
                "Learn the important concepts and tricks to solve questions based on heights and distances",
              math_formula: "",
            },
            {
              text:
                "The topic of height and distance in trigonometry is an important topic from competitive examination point of view. ",
              math_formula: "",
            },
            {
              text:
                "Generally you must have seen the problems where the height of a building is given and then from the top of this building the angles of elevation or depression are given for another ",
              math_formula: "",
            },
            {
              text:
                "building and you have to find the height of the second building. In this article we will cover these problems.",
              math_formula: "",
            },
            {
              text:
                "There are certain terms associated with the heights and distances which are described as follows:",
              math_formula: "",
            },
          ],
        },
        {
          th_heading: "Angle of Elevation:",
          isPoint: false,
          th_text: [
            {
              text:
                "Let us consider a situation where a person is standing on the ground and he is looking at an object which is at some height say the top of the building.",
              math_formula: "",
            },
            {
              text:
                "The line joining the eye of the man with the top of the building is called the line of sight. ",
              math_formula: "",
            },
            {
              text:
                "The angle made by the line of sight with the horizontal line is called angle of elevation.",
              math_formula: "",
            },
            {
              text: "",
              math_formula: "",
            },
            {
              text: "",
              math_formula: "",
            },
            {
              text: "",
              math_formula: "",
            },
            {
              text: "",
              math_formula: "",
            },
            {
              text: "",
              math_formula: "",
            },
            {
              text: "",
              math_formula: "",
            },
          ],
        },
        {
          th_heading: "Angle of Depression: ",
          isPoint: false,
          th_text: [
            {
              text:
                "Now let us take another situation where the person is standing at some height with respect to the object he is seeing.",
              math_formula: "",
            },
            {
              text: "",
              math_formula:
                " In this case again the line joining the eye of the man with the bottom of the building is called the line of sight.",
            },
            {
              text:
                "The angle made by the line of sight with the horizontal line is called angle of depression, where ‘θ’ is the angle of depression.",
              math_formula: "",
            },
            {
              text:
                "Note: The angle of elevation is equal to the angle of depression.",
              math_formula: "",
            },
            {
              text:
                "The questions on this topic require basic knowledge of Trigonometry. You should be aware of the basic trigonometric ratios and their values.",
              math_formula: "",
            },
            {
              text:
                "Let us recall that the ratios of the sides of a right angled triangle are called trigonometric ratios. These are sine, cosine, tangent, cosecant, secant and cotangent.",
              math_formula: "",
            },
            {
              text: "Let the ΔABC is a right angled triangle.",
              math_formula: "",
            },
            {
              text: "",
              math_formula: "Then Sin θ = AB/AC",
            },
            {
              text: "",
              math_formula: "Cos θ = BC/AC",
            },
            {
              text: "",
              math_formula: "Tan θ = AB/BC",
            },
            {
              text: "",
              math_formula: "Cosec θ = AC/AB",
            },
            {
              text: "",
              math_formula: "Sec θ = AC/BC",
            },
            {
              text: "",
              math_formula: "Cot θ = BC/AB",
            },
          ],
        },
        {
          th_heading: "",
          isPoint: false,
          th_text: [
            {
              text:
                "The values in the table will be used while solving the questions on height and distances. Let us now discuss some basic problems on height and distances.",
              math_formula: "",
            },
            {
              text: "",
              math_formula:
                " After going through these examples you will learn how to measure the height and how to find the distance.",
            },
            {
              text: "",
              math_formula: "",
            },
            {
              text: "",
              math_formula: "",
            },
          ],
        },
      ],
    },
    {
      name: "Calendar",
      topic_text: "",
      theory_array: [
        {
          th_heading: "What Is Simple Interest?",
          isPoint: false,
          th_text: [
            {
              text:
                "Simple interest is a quick and easy method of calculating the interest charge on a loan.",
              math_formula: "",
            },
            {
              text:
                " Simple interest is determined by multiplying the daily interest rate by the principal by the number of days that elapse between payments.",
              math_formula: "",
            },
            {
              text:
                "This type of interest usually applies to automobile loans or short-term loans, although some mortgages use this calculation method.",
              math_formula: "",
            },
            {
              text: "",
              math_formula: "",
            },
            {
              text: "",
              math_formula: "",
            },
            {
              text: "",
              math_formula: "",
            },
          ],
        },
        {
          th_heading: "KEY TAKEAWAYS",
          isPoint: true,
          th_text: [
            {
              text:
                "Simple interest is calculated by multiplying the daily interest rate by the principal, by the number of days that elapse between payments.",
              math_formula: "",
            },
            {
              text:
                "Simple interest benefits consumers who pay their loans on time or early each month. ",
              math_formula: "",
            },
            {
              text:
                "Auto loans and short-term personal loans are usually simple interest loans. ",
              math_formula: "",
            },
            {
              text: "",
              math_formula: "",
            },
            {
              text: "",
              math_formula: "",
            },
            {
              text: "",
              math_formula: "",
            },
          ],
        },
        {
          th_heading: "Understanding Simple Interest ",
          isPoint: false,
          th_text: [
            {
              text:
                "Interest is the cost of borrowing money. Typically expressed as a percentage, it amounts to a fee or extra charge the borrower pays the lender for the financed sum. ",
              math_formula: "",
            },
            {
              text:
                "When you make a payment on a simple interest loan, the payment first goes toward that month’s interest, and the remainder goes toward the principal. Each month’s interest is paid in full so it never accrues.",
              math_formula: "",
            },
            {
              text:
                "In contrast, compound interest adds some of the monthly interest back onto the loan; in each succeeding month, you pay new interest on old interest.",
              math_formula: "",
            },
            {
              text:
                "To understand how simple interest works, consider an automobile loan that has a $15,000 principal balance and an annual 5% simple interest rate.",
              math_formula: "",
            },
            {
              text:
                " If your payment is due on May 1 and you pay it precisely on the due date, the finance company calculates your interest on the 30 days in April.",
              math_formula: "",
            },
            {
              text: "Your interest for 30 days is $61.64 under this scenario.",
              math_formula: "",
            },
            {
              text:
                " However, if you make the payment on April 21, the finance company charges you interest for only 20 days in April, dropping your interest payment to $41.09, a $20 savings.",
              math_formula: "",
            },
            {
              text: "",
              math_formula: "",
            },
          ],
        },
        {
          th_heading: "Simple Interest Formula and Example",
          isPoint: true,
          th_text: [
            {
              text:
                "The formula for simple interest is pretty, well, simple. It looks like this:",
              math_formula: "Simple Interest=P×I×N",
            },
            {
              text: "where:",
              math_formula: "P=principle",
            },
            {
              text: "",
              math_formula: "I = daily interest rate",
            },
            {
              text: "",
              math_formula: "N = Number of days between payment",
            },
            {
              text:
                "Generally, simple interest paid or received over a certain period is a fixed percentage of the principal amount that was borrowed or lent. ",
              math_formula: "",
            },
            {
              text:
                "For example, say a student obtains a simple-interest loan to pay one year of college tuition, which costs $18,000, and the annual interest rate on the loan is 6%. ",
              math_formula: "",
            },
            {
              text:
                "The student repays the loan over three years. The amount of simple interest paid is:",
              math_formula: "$3,240=$18,000×0.06×3",
            },
            {
              text: "and the total amount paid is:",
              math_formula: "$21,240=$18,000+$3,240",
            },
          ],
        },
        {
          th_heading: "Who Benefits From a Simple Interest Loan?",
          isPoint: false,
          th_text: [
            {
              text:
                "Under the student-loan scenario above, if you sent a $300 payment on May 1, then $238.36 goes toward the principal. If you sent the same payment on April 20, then $258.91 goes toward the principal. ",
              math_formula: "",
            },
            {
              text:
                "If you can pay early every month, your principal balance shrinks faster, and you pay the loan off sooner than the original estimate.",
              math_formula: "",
            },
            {
              text:
                "Conversely, if you pay the loan late, more of your payment goes toward interest than if you pay on time.",
              math_formula: "",
            },
            {
              text:
                "Using the same automobile loan example, if your payment is due on May 1 and you make it on May 16, you get charged for 45 days of interest at a cost of $92.46. ",
              math_formula: "",
            },
            {
              text:
                "This means only $207.54 of your $300 payment goes toward the principal. ",
              math_formula: "",
            },
            {
              text:
                "If you consistently pay late over the life of a loan, your final payment will be larger than the original estimate because you did not pay down the principal at the expected rate.",
              math_formula: "",
            },
          ],
        },
        {
          th_heading: "What Types of Loans Use Simple Interest?",
          isPoint: false,
          th_text: [
            {
              text:
                "Simple interest usually applies to automobile loans or short-term personal loans. In the U.S., most mortgages on an amortization schedule are also simple interest loans, although they can certainly feel like compound interest ones.",
              math_formula: "",
            },
            {
              text:
                "The compounding feel comes from varying principal payments—that is, the percentage of your mortgage payment that's actually going towards the loan itself, not the interest. ",
              math_formula: "",
            },
            {
              text: "The interest doesn’t compound; the principal payments do.",
              math_formula: "",
            },
            {
              text:
                "A $1,000 principal payment saves interest on that $1,000 and results in higher principal payments the next year, and higher the following year, and so on.",
              math_formula: "",
            },
            {
              text:
                "If you don’t let the principal payments vary, as in an interest-only loan (zero principal payment), or by equalizing the principal payments, the loan interest itself doesn’t compound.",
              math_formula: "",
            },
            {
              text:
                "Lowering the interest rate, shortening the loan term, or pre-paying principal also has a compounding effect.",
              math_formula: "",
            },
            {
              text: "For example, take bi-weekly mortgage payment plans. ",
              math_formula: "",
            },
            {
              text:
                "Bi-weekly plans generally help consumers pay off their mortgages early because the borrowers make two extra payments a year, saving interest over the life of the loan by paying off the principal faster.",
              math_formula: "",
            },
          ],
        },
        {
          th_heading: "Simple Interest vs. Compound Interest",
          isPoint: false,
          th_text: [
            {
              text:
                "Interest can be either simple or compounded. Simple interest is based on the original principal amount of a loan or deposit.",
              math_formula: "",
            },
            {
              text:
                "Compound interest, on the other hand, is based on the principal amount and the interest that accumulates on it in every period.",
              math_formula: "",
            },
            {
              text:
                "Simple interest is calculated only on the principal, so it is easier to determine than compound interest.",
              math_formula: "",
            },
            {
              text:
                "In real-life situations, compound interest is often a factor in business transactions, investments, and financial products intended to extend for multiple periods or years.",
              math_formula: "",
            },
            {
              text:
                "Simple interest is mainly used for easy calculations: those generally for a single period or less than a year. Simple interest also applies to open-ended situations, such as credit card balances.",
              math_formula: "",
            },
          ],
        },
        {
          th_heading: "Why is Simple Interest 'Simple'?",
          isPoint: false,
          th_text: [
            {
              text:
                "'Simple' interest refers to the straightforward crediting of cash flows associated with some investment or deposit. ",
              math_formula: "",
            },
            {
              text:
                "For instance, 1% annual simple interest would credit $1 for every $100 invested, year after year. ",
              math_formula: "",
            },
            {
              text:
                " Simple interest does not, however, take into account the power of compounding, or interest-on-interest, where after the first year the 1% would actually be earned on the $101 balance—adding up to $1.01. ",
              math_formula: "",
            },
            {
              text:
                "The next year, the 1% would be earned on $102.01, amounting to $1.02. And so one.",
              math_formula: "",
            },
          ],
        },
        {
          th_heading:
            "Which Will Pay Out More Over Time, Simple or Compound Interest?",
          isPoint: false,
          th_text: [
            {
              text:
                "Compound interest will always pay more after the first payment period. ",
              math_formula: "",
            },
            {
              text:
                "Suppose you borrow $10,000 at a 10% annual interest rate with the principal and interest due as a lump sum in three years. ",
              math_formula: "",
            },
            {
              text:
                "Using a simple interest calculation, 10% of the principal balance gets added to your repayment amount during each of the three years. ",
              math_formula: "",
            },
            {
              text:
                "That comes out to $1,000 per year, which totals $3,000 in interest over the life of the loan. At repayment, then, the amount due is $13,000. ",
              math_formula: "",
            },
            {
              text:
                "Now suppose you take out the same loan, with the same terms, but the interest is compounded annually. When the loan is due, instead of owing $13,000, you end up owing $13,310.",
              math_formula: "",
            },
            {
              text:
                " While you may not consider $310 a huge difference, this example is only a three-year loan; compound interest piles up and becomes oppressive with longer loan terms.",
              math_formula: "",
            },
          ],
        },
      ],
    },
    {
      name: "Average",
      topic_text:
        "Similar to the concept of speed, distance and time, train problems are specifically based on evaluating the speed, distance covered and time is taken by a train under different conditions. Train problems form an integral part of the time and speed questions which are frequently asked in the quantitative aptitude section of various Government exams. These questions are different from the basic speed, distance and time questions and require a different approach to be answered.",
      theory_array: [
        {
          th_heading: "Time, Speed and Distance: Problems on Trains",
          isPoint: false,
          th_text: [
            {
              text:
                "In this article, you will learn the concepts related to an important topic in Time, Speed and Distance i.e. Trains.",
              math_formula: "",
            },
            {
              text:
                "Problems on trains are very common in competitive exams. Various types of questions are asked on trains. ",
              math_formula: "",
            },
            {
              text:
                "Questions on trains are solved using the concept of time, speed and distance i.e. we use the formulas of time, speed and distance to solve questions on trains.",
              math_formula: "",
            },
            {
              text:
                "Given below is a list of some important points that need to be kept in mind while solving questions on trains.",
              math_formula: "",
            },
          ],
        },
        {
          th_heading: "Important Points",
          isPoint: true,
          th_text: [
            {
              text:
                "When two trains are going in the same direction, then their relative speed is the difference between the two speeds.",
              math_formula: "",
            },
            {
              text:
                "When two trains are moving in the opposite direction, then their relative speed is the sum of the two speeds.",
              math_formula: "",
            },
            {
              text:
                "When a train crosses a stationary man/ pole/ lamp post/ sign post- in all these cases, the object which the train crosses is stationary and the distance travelled is the length of the train.",
              math_formula: "",
            },
            {
              text:
                "When it crosses a platform/ bridge- in these cases, the object which the train crosses is stationary and the distance travelled is the length of the train and the length of the object.",
              math_formula: "",
            },
            {
              text:
                "When two trains are moving in same direction, then their speed will be subtracted.",
              math_formula: "",
            },
            {
              text:
                "When two trains are moving in opposite directions, then their speed will be added.",
              math_formula: "",
            },
            {
              text:
                "In both the above cases, the total distance is the sum of the length of both the trains.",
              math_formula: "",
            },
            {
              text:
                "When a train crosses a car/ bicycle/ a mobile man- in these cases, the relative speed between the train and the object is taken depending upon the direction of the movement of the other object relative to the train- and the distance travelled is the length of the train.",
              math_formula: "",
            },
            {
              text:
                "Now, let us try doing some questions and understand the concept of solving train related problems",
              math_formula: "",
            },
          ],
        },
        {
          th_heading: "Solved Questions",
          isPoint: false,
          th_text: [
            {
              text:
                "Question 1: A train travelling at 60 kmph crosses a man in 6 seconds. What is the length of the train?",
              math_formula: "",
            },
            {
              text: "Solution: Speed in m/sec = 60 *(5/18) = 50/3 m/sec ",
              math_formula: "",
            },
            {
              text: "Time taken to cross the man = 6 secs",
              math_formula: "",
            },
            {
              text:
                "Therefore, Distance = (50/3)* 6 = 100 metres (i.e. the length of the train)",
              math_formula: "",
            },
            {
              text:
                "Question 2: A train travelling at 60 kmph crosses another train travelling in the same direction at 50 kmph in 30 seconds. What is the combined length of both the trains?",
              math_formula: "",
            },
            {
              text:
                "Solution: Speed of train A = 60 kmph = 60* (5/18) = 50/3 m/sec",
              math_formula: "",
            },
            {
              text: "Speed of train B = = 50 kmph = 50 *(5/18) = 125/9 m/sec",
              math_formula: "",
            },
            {
              text:
                "The relative speed =(50/3)-(125/9)=25/9 m/s (we have subtracted the two values because both the trains are going in the same direction)",
              math_formula: "",
            },
            {
              text: "Time taken by train A to cross train B = 30 secs",
              math_formula: "",
            },
            {
              text: "Distance = Speed * Time",
              math_formula: "",
            },
            {
              text:
                "Distance =25/9 * 30 = 250/3 metres (i.e. the combined length of both trains)",
              math_formula: "",
            },
            {
              text:
                "Question 3: The average speed of a train without stoppages is 48 kmph and average speed with stoppages is 40 kmph. How many minutes in an hour the train stops on an average?",
              math_formula: "",
            },
            {
              text:
                "Solution: If the train were to travel without stoppages, it would cover 48 km.",
              math_formula: "",
            },
            {
              text:
                "With stoppages, the average speed reduces by (48-40) = 8 km",
              math_formula: "",
            },
            {
              text: "Therefore, (8/48)* 60 minutes = 10 minutes",
              math_formula: "",
            },
            {
              text:
                "Hence, 10 minutes would be the time per hour the train stops on an average.",
              math_formula: "",
            },
            {
              text:
                "Question 4: A train travelling at 60 kmph crosses a man in 6 seconds. What is the length of the train?",
              math_formula: "",
            },
            {
              text: "Solution: Speed in m/sec = 60 *(5/18) = 50/3 m/sec ",
              math_formula: "",
            },
            {
              text: "Time taken to cross the man = 6 secs",
              math_formula: "",
            },
            {
              text:
                "Therefore, Distance = (50/3)* 6 = 100 metres (i.e. the length of the train)",
              math_formula: "",
            },
            {
              text:
                "Question 5: A train travelling at 60 kmph crosses another train travelling in the same direction at 50 kmph in 30 seconds. What is the combined length of both the trains?",
              math_formula: "",
            },
            {
              text:
                "Solution: Speed of train A = 60 kmph = 60* (5/18) = 50/3 m/sec",
              math_formula: "",
            },
            {
              text: "Speed of train B = = 50 kmph = 50 *(5/18) = 125/9 m/sec",
              math_formula: "",
            },
            {
              text:
                "The relative speed =(50/3)-(125/9)=25/9 m/s (we have subtracted the two values because both the trains are going in the same direction)",
              math_formula: "",
            },
            {
              text: "Time taken by train A to cross train B = 30 secs",
              math_formula: "",
            },
            {
              text: "Distance = Speed * Time",
              math_formula: "",
            },
            {
              text:
                "Distance =25/9 * 30 = 250/3 metres (i.e. the combined length of both trains)",
              math_formula: "",
            },
            {
              text:
                "Question 6: The average speed of a train without stoppages is 48 kmph and average speed with stoppages is 40 kmph. How many minutes in an hour the train stops on an average?",
              math_formula: "",
            },
            {
              text:
                "Solution: If the train were to travel without stoppages, it would cover 48 km.",
              math_formula: "",
            },
            {
              text:
                "With stoppages, the average speed reduces by (48-40) = 8 km",
              math_formula: "",
            },
            {
              text: "Therefore, (8/48)* 60 minutes = 10 minutes",
              math_formula: "",
            },
            {
              text:
                "Hence, 10 minutes would be the time per hour the train stops on an average.",
              math_formula: "",
            },
            {
              text:
                "Question 8: A train travelling at 60 kmph crosses another train travelling in the same direction at 50 kmph in 30 seconds. What is the combined length of both the trains?",
              math_formula: "",
            },
            {
              text:
                "Solution: Speed of train A = 60 kmph = 60* (5/18) = 50/3 m/sec",
              math_formula: "",
            },
            {
              text: "Speed of train B = = 50 kmph = 50 *(5/18) = 125/9 m/sec",
              math_formula: "",
            },
            {
              text:
                "The relative speed =(50/3)-(125/9)=25/9 m/s (we have subtracted the two values because both the trains are going in the same direction)",
              math_formula: "",
            },
            {
              text: "Time taken by train A to cross train B = 30 secs",
              math_formula: "",
            },
            {
              text: "Distance = Speed * Time",
              math_formula: "",
            },
            {
              text:
                "Distance =25/9 * 30 = 250/3 metres (i.e. the combined length of both trains)",
              math_formula: "",
            },
            {
              text:
                "Question 8: The average speed of a train without stoppages is 48 kmph and average speed with stoppages is 40 kmph. How many minutes in an hour the train stops on an average?",
              math_formula: "",
            },
            {
              text:
                "Solution: If the train were to travel without stoppages, it would cover 48 km.",
              math_formula: "",
            },
            {
              text:
                "With stoppages, the average speed reduces by (48-40) = 8 km",
              math_formula: "",
            },
            {
              text: "Therefore, (8/48)* 60 minutes = 10 minutes",
              math_formula: "",
            },
            {
              text:
                "Hence, 10 minutes would be the time per hour the train stops on an average.",
              math_formula: "",
            },
          ],
        },
      ],
    },
    {
      name: "Volume and Surface Area",
      topic_text: "Simple Interest",
      theory_array: [
        {
          th_heading: "What Is Simple Interest?",
          isPoint: false,
          th_text: [
            {
              text:
                "Simple interest is a quick and easy method of calculating the interest charge on a loan.",
              math_formula: "",
            },
            {
              text:
                " Simple interest is determined by multiplying the daily interest rate by the principal by the number of days that elapse between payments.",
              math_formula: "",
            },
            {
              text:
                "This type of interest usually applies to automobile loans or short-term loans, although some mortgages use this calculation method.",
              math_formula: "",
            },
            {
              text: "",
              math_formula: "",
            },
            {
              text: "",
              math_formula: "",
            },
            {
              text: "",
              math_formula: "",
            },
          ],
        },
        {
          th_heading: "KEY TAKEAWAYS",
          isPoint: true,
          th_text: [
            {
              text:
                "Simple interest is calculated by multiplying the daily interest rate by the principal, by the number of days that elapse between payments.",
              math_formula: "",
            },
            {
              text:
                "Simple interest benefits consumers who pay their loans on time or early each month. ",
              math_formula: "",
            },
            {
              text:
                "Auto loans and short-term personal loans are usually simple interest loans. ",
              math_formula: "",
            },
            {
              text: "",
              math_formula: "",
            },
            {
              text: "",
              math_formula: "",
            },
            {
              text: "",
              math_formula: "",
            },
          ],
        },
        {
          th_heading: "Understanding Simple Interest ",
          isPoint: false,
          th_text: [
            {
              text:
                "Interest is the cost of borrowing money. Typically expressed as a percentage, it amounts to a fee or extra charge the borrower pays the lender for the financed sum. ",
              math_formula: "",
            },
            {
              text:
                "When you make a payment on a simple interest loan, the payment first goes toward that month’s interest, and the remainder goes toward the principal. Each month’s interest is paid in full so it never accrues.",
              math_formula: "",
            },
            {
              text:
                "In contrast, compound interest adds some of the monthly interest back onto the loan; in each succeeding month, you pay new interest on old interest.",
              math_formula: "",
            },
            {
              text:
                "To understand how simple interest works, consider an automobile loan that has a $15,000 principal balance and an annual 5% simple interest rate.",
              math_formula: "",
            },
            {
              text:
                " If your payment is due on May 1 and you pay it precisely on the due date, the finance company calculates your interest on the 30 days in April.",
              math_formula: "",
            },
            {
              text: "Your interest for 30 days is $61.64 under this scenario.",
              math_formula: "",
            },
            {
              text:
                " However, if you make the payment on April 21, the finance company charges you interest for only 20 days in April, dropping your interest payment to $41.09, a $20 savings.",
              math_formula: "",
            },
            {
              text: "",
              math_formula: "",
            },
          ],
        },
        {
          th_heading: "Simple Interest Formula and Example",
          isPoint: true,
          th_text: [
            {
              text:
                "The formula for simple interest is pretty, well, simple. It looks like this:",
              math_formula: "Simple Interest=P×I×N",
            },
            {
              text: "where:",
              math_formula: "P=principle",
            },
            {
              text: "",
              math_formula: "I = daily interest rate",
            },
            {
              text: "",
              math_formula: "N = Number of days between payment",
            },
            {
              text:
                "Generally, simple interest paid or received over a certain period is a fixed percentage of the principal amount that was borrowed or lent. ",
              math_formula: "",
            },
            {
              text:
                "For example, say a student obtains a simple-interest loan to pay one year of college tuition, which costs $18,000, and the annual interest rate on the loan is 6%. ",
              math_formula: "",
            },
            {
              text:
                "The student repays the loan over three years. The amount of simple interest paid is:",
              math_formula: "$3,240=$18,000×0.06×3",
            },
            {
              text: "and the total amount paid is:",
              math_formula: "$21,240=$18,000+$3,240",
            },
          ],
        },
        {
          th_heading: "Who Benefits From a Simple Interest Loan?",
          isPoint: false,
          th_text: [
            {
              text:
                "Under the student-loan scenario above, if you sent a $300 payment on May 1, then $238.36 goes toward the principal. If you sent the same payment on April 20, then $258.91 goes toward the principal. ",
              math_formula: "",
            },
            {
              text:
                "If you can pay early every month, your principal balance shrinks faster, and you pay the loan off sooner than the original estimate.",
              math_formula: "",
            },
            {
              text:
                "Conversely, if you pay the loan late, more of your payment goes toward interest than if you pay on time.",
              math_formula: "",
            },
            {
              text:
                "Using the same automobile loan example, if your payment is due on May 1 and you make it on May 16, you get charged for 45 days of interest at a cost of $92.46. ",
              math_formula: "",
            },
            {
              text:
                "This means only $207.54 of your $300 payment goes toward the principal. ",
              math_formula: "",
            },
            {
              text:
                "If you consistently pay late over the life of a loan, your final payment will be larger than the original estimate because you did not pay down the principal at the expected rate.",
              math_formula: "",
            },
          ],
        },
        {
          th_heading: "What Types of Loans Use Simple Interest?",
          isPoint: false,
          th_text: [
            {
              text:
                "Simple interest usually applies to automobile loans or short-term personal loans. In the U.S., most mortgages on an amortization schedule are also simple interest loans, although they can certainly feel like compound interest ones.",
              math_formula: "",
            },
            {
              text:
                "The compounding feel comes from varying principal payments—that is, the percentage of your mortgage payment that's actually going towards the loan itself, not the interest. ",
              math_formula: "",
            },
            {
              text: "The interest doesn’t compound; the principal payments do.",
              math_formula: "",
            },
            {
              text:
                "A $1,000 principal payment saves interest on that $1,000 and results in higher principal payments the next year, and higher the following year, and so on.",
              math_formula: "",
            },
            {
              text:
                "If you don’t let the principal payments vary, as in an interest-only loan (zero principal payment), or by equalizing the principal payments, the loan interest itself doesn’t compound.",
              math_formula: "",
            },
            {
              text:
                "Lowering the interest rate, shortening the loan term, or pre-paying principal also has a compounding effect.",
              math_formula: "",
            },
            {
              text: "For example, take bi-weekly mortgage payment plans. ",
              math_formula: "",
            },
            {
              text:
                "Bi-weekly plans generally help consumers pay off their mortgages early because the borrowers make two extra payments a year, saving interest over the life of the loan by paying off the principal faster.",
              math_formula: "",
            },
          ],
        },
        {
          th_heading: "Simple Interest vs. Compound Interest",
          isPoint: false,
          th_text: [
            {
              text:
                "Interest can be either simple or compounded. Simple interest is based on the original principal amount of a loan or deposit.",
              math_formula: "",
            },
            {
              text:
                "Compound interest, on the other hand, is based on the principal amount and the interest that accumulates on it in every period.",
              math_formula: "",
            },
            {
              text:
                "Simple interest is calculated only on the principal, so it is easier to determine than compound interest.",
              math_formula: "",
            },
            {
              text:
                "In real-life situations, compound interest is often a factor in business transactions, investments, and financial products intended to extend for multiple periods or years.",
              math_formula: "",
            },
            {
              text:
                "Simple interest is mainly used for easy calculations: those generally for a single period or less than a year. Simple interest also applies to open-ended situations, such as credit card balances.",
              math_formula: "",
            },
          ],
        },
        {
          th_heading: "Why is Simple Interest 'Simple'?",
          isPoint: false,
          th_text: [
            {
              text:
                "'Simple' interest refers to the straightforward crediting of cash flows associated with some investment or deposit. ",
              math_formula: "",
            },
            {
              text:
                "For instance, 1% annual simple interest would credit $1 for every $100 invested, year after year. ",
              math_formula: "",
            },
            {
              text:
                " Simple interest does not, however, take into account the power of compounding, or interest-on-interest, where after the first year the 1% would actually be earned on the $101 balance—adding up to $1.01. ",
              math_formula: "",
            },
            {
              text:
                "The next year, the 1% would be earned on $102.01, amounting to $1.02. And so one.",
              math_formula: "",
            },
          ],
        },
        {
          th_heading:
            "Which Will Pay Out More Over Time, Simple or Compound Interest?",
          isPoint: false,
          th_text: [
            {
              text:
                "Compound interest will always pay more after the first payment period. ",
              math_formula: "",
            },
            {
              text:
                "Suppose you borrow $10,000 at a 10% annual interest rate with the principal and interest due as a lump sum in three years. ",
              math_formula: "",
            },
            {
              text:
                "Using a simple interest calculation, 10% of the principal balance gets added to your repayment amount during each of the three years. ",
              math_formula: "",
            },
            {
              text:
                "That comes out to $1,000 per year, which totals $3,000 in interest over the life of the loan. At repayment, then, the amount due is $13,000. ",
              math_formula: "",
            },
            {
              text:
                "Now suppose you take out the same loan, with the same terms, but the interest is compounded annually. When the loan is due, instead of owing $13,000, you end up owing $13,310.",
              math_formula: "",
            },
            {
              text:
                " While you may not consider $310 a huge difference, this example is only a three-year loan; compound interest piles up and becomes oppressive with longer loan terms.",
              math_formula: "",
            },
          ],
        },
      ],
    },
    {
      name: "Numbers",
      topic_text:
        "Similar to the concept of speed, distance and time, train problems are specifically based on evaluating the speed, distance covered and time is taken by a train under different conditions. Train problems form an integral part of the time and speed questions which are frequently asked in the quantitative aptitude section of various Government exams. These questions are different from the basic speed, distance and time questions and require a different approach to be answered.",
      theory_array: [
        {
          th_heading: "Time, Speed and Distance: Problems on Trains",
          isPoint: false,
          th_text: [
            {
              text:
                "In this article, you will learn the concepts related to an important topic in Time, Speed and Distance i.e. Trains.",
              math_formula: "",
            },
            {
              text:
                "Problems on trains are very common in competitive exams. Various types of questions are asked on trains. ",
              math_formula: "",
            },
            {
              text:
                "Questions on trains are solved using the concept of time, speed and distance i.e. we use the formulas of time, speed and distance to solve questions on trains.",
              math_formula: "",
            },
            {
              text:
                "Given below is a list of some important points that need to be kept in mind while solving questions on trains.",
              math_formula: "",
            },
          ],
        },
        {
          th_heading: "Important Points",
          isPoint: true,
          th_text: [
            {
              text:
                "When two trains are going in the same direction, then their relative speed is the difference between the two speeds.",
              math_formula: "",
            },
            {
              text:
                "When two trains are moving in the opposite direction, then their relative speed is the sum of the two speeds.",
              math_formula: "",
            },
            {
              text:
                "When a train crosses a stationary man/ pole/ lamp post/ sign post- in all these cases, the object which the train crosses is stationary and the distance travelled is the length of the train.",
              math_formula: "",
            },
            {
              text:
                "When it crosses a platform/ bridge- in these cases, the object which the train crosses is stationary and the distance travelled is the length of the train and the length of the object.",
              math_formula: "",
            },
            {
              text:
                "When two trains are moving in same direction, then their speed will be subtracted.",
              math_formula: "",
            },
            {
              text:
                "When two trains are moving in opposite directions, then their speed will be added.",
              math_formula: "",
            },
            {
              text:
                "In both the above cases, the total distance is the sum of the length of both the trains.",
              math_formula: "",
            },
            {
              text:
                "When a train crosses a car/ bicycle/ a mobile man- in these cases, the relative speed between the train and the object is taken depending upon the direction of the movement of the other object relative to the train- and the distance travelled is the length of the train.",
              math_formula: "",
            },
            {
              text:
                "Now, let us try doing some questions and understand the concept of solving train related problems",
              math_formula: "",
            },
          ],
        },
        {
          th_heading: "Solved Questions",
          isPoint: false,
          th_text: [
            {
              text:
                "Question 1: A train travelling at 60 kmph crosses a man in 6 seconds. What is the length of the train?",
              math_formula: "",
            },
            {
              text: "Solution: Speed in m/sec = 60 *(5/18) = 50/3 m/sec ",
              math_formula: "",
            },
            {
              text: "Time taken to cross the man = 6 secs",
              math_formula: "",
            },
            {
              text:
                "Therefore, Distance = (50/3)* 6 = 100 metres (i.e. the length of the train)",
              math_formula: "",
            },
            {
              text:
                "Question 2: A train travelling at 60 kmph crosses another train travelling in the same direction at 50 kmph in 30 seconds. What is the combined length of both the trains?",
              math_formula: "",
            },
            {
              text:
                "Solution: Speed of train A = 60 kmph = 60* (5/18) = 50/3 m/sec",
              math_formula: "",
            },
            {
              text: "Speed of train B = = 50 kmph = 50 *(5/18) = 125/9 m/sec",
              math_formula: "",
            },
            {
              text:
                "The relative speed =(50/3)-(125/9)=25/9 m/s (we have subtracted the two values because both the trains are going in the same direction)",
              math_formula: "",
            },
            {
              text: "Time taken by train A to cross train B = 30 secs",
              math_formula: "",
            },
            {
              text: "Distance = Speed * Time",
              math_formula: "",
            },
            {
              text:
                "Distance =25/9 * 30 = 250/3 metres (i.e. the combined length of both trains)",
              math_formula: "",
            },
            {
              text:
                "Question 3: The average speed of a train without stoppages is 48 kmph and average speed with stoppages is 40 kmph. How many minutes in an hour the train stops on an average?",
              math_formula: "",
            },
            {
              text:
                "Solution: If the train were to travel without stoppages, it would cover 48 km.",
              math_formula: "",
            },
            {
              text:
                "With stoppages, the average speed reduces by (48-40) = 8 km",
              math_formula: "",
            },
            {
              text: "Therefore, (8/48)* 60 minutes = 10 minutes",
              math_formula: "",
            },
            {
              text:
                "Hence, 10 minutes would be the time per hour the train stops on an average.",
              math_formula: "",
            },
            {
              text:
                "Question 4: A train travelling at 60 kmph crosses a man in 6 seconds. What is the length of the train?",
              math_formula: "",
            },
            {
              text: "Solution: Speed in m/sec = 60 *(5/18) = 50/3 m/sec ",
              math_formula: "",
            },
            {
              text: "Time taken to cross the man = 6 secs",
              math_formula: "",
            },
            {
              text:
                "Therefore, Distance = (50/3)* 6 = 100 metres (i.e. the length of the train)",
              math_formula: "",
            },
            {
              text:
                "Question 5: A train travelling at 60 kmph crosses another train travelling in the same direction at 50 kmph in 30 seconds. What is the combined length of both the trains?",
              math_formula: "",
            },
            {
              text:
                "Solution: Speed of train A = 60 kmph = 60* (5/18) = 50/3 m/sec",
              math_formula: "",
            },
            {
              text: "Speed of train B = = 50 kmph = 50 *(5/18) = 125/9 m/sec",
              math_formula: "",
            },
            {
              text:
                "The relative speed =(50/3)-(125/9)=25/9 m/s (we have subtracted the two values because both the trains are going in the same direction)",
              math_formula: "",
            },
            {
              text: "Time taken by train A to cross train B = 30 secs",
              math_formula: "",
            },
            {
              text: "Distance = Speed * Time",
              math_formula: "",
            },
            {
              text:
                "Distance =25/9 * 30 = 250/3 metres (i.e. the combined length of both trains)",
              math_formula: "",
            },
            {
              text:
                "Question 6: The average speed of a train without stoppages is 48 kmph and average speed with stoppages is 40 kmph. How many minutes in an hour the train stops on an average?",
              math_formula: "",
            },
            {
              text:
                "Solution: If the train were to travel without stoppages, it would cover 48 km.",
              math_formula: "",
            },
            {
              text:
                "With stoppages, the average speed reduces by (48-40) = 8 km",
              math_formula: "",
            },
            {
              text: "Therefore, (8/48)* 60 minutes = 10 minutes",
              math_formula: "",
            },
            {
              text:
                "Hence, 10 minutes would be the time per hour the train stops on an average.",
              math_formula: "",
            },
            {
              text:
                "Question 8: A train travelling at 60 kmph crosses another train travelling in the same direction at 50 kmph in 30 seconds. What is the combined length of both the trains?",
              math_formula: "",
            },
            {
              text:
                "Solution: Speed of train A = 60 kmph = 60* (5/18) = 50/3 m/sec",
              math_formula: "",
            },
            {
              text: "Speed of train B = = 50 kmph = 50 *(5/18) = 125/9 m/sec",
              math_formula: "",
            },
            {
              text:
                "The relative speed =(50/3)-(125/9)=25/9 m/s (we have subtracted the two values because both the trains are going in the same direction)",
              math_formula: "",
            },
            {
              text: "Time taken by train A to cross train B = 30 secs",
              math_formula: "",
            },
            {
              text: "Distance = Speed * Time",
              math_formula: "",
            },
            {
              text:
                "Distance =25/9 * 30 = 250/3 metres (i.e. the combined length of both trains)",
              math_formula: "",
            },
            {
              text:
                "Question 8: The average speed of a train without stoppages is 48 kmph and average speed with stoppages is 40 kmph. How many minutes in an hour the train stops on an average?",
              math_formula: "",
            },
            {
              text:
                "Solution: If the train were to travel without stoppages, it would cover 48 km.",
              math_formula: "",
            },
            {
              text:
                "With stoppages, the average speed reduces by (48-40) = 8 km",
              math_formula: "",
            },
            {
              text: "Therefore, (8/48)* 60 minutes = 10 minutes",
              math_formula: "",
            },
            {
              text:
                "Hence, 10 minutes would be the time per hour the train stops on an average.",
              math_formula: "",
            },
          ],
        },
      ],
    },
    {
      name: "Problems on H.C.F and L.C.M",
      topic_text:
        "Similar to the concept of speed, distance and time, train problems are specifically based on evaluating the speed, distance covered and time is taken by a train under different conditions. Train problems form an integral part of the time and speed questions which are frequently asked in the quantitative aptitude section of various Government exams. These questions are different from the basic speed, distance and time questions and require a different approach to be answered.",
      theory_array: [
        {
          th_heading: "Heights and Distances: Theory & Concepts",
          isPoint: false,
          th_text: [
            {
              text:
                "Learn the important concepts and tricks to solve questions based on heights and distances",
              math_formula: "",
            },
            {
              text:
                "The topic of height and distance in trigonometry is an important topic from competitive examination point of view. ",
              math_formula: "",
            },
            {
              text:
                "Generally you must have seen the problems where the height of a building is given and then from the top of this building the angles of elevation or depression are given for another ",
              math_formula: "",
            },
            {
              text:
                "building and you have to find the height of the second building. In this article we will cover these problems.",
              math_formula: "",
            },
            {
              text:
                "There are certain terms associated with the heights and distances which are described as follows:",
              math_formula: "",
            },
          ],
        },
        {
          th_heading: "Angle of Elevation:",
          isPoint: false,
          th_text: [
            {
              text:
                "Let us consider a situation where a person is standing on the ground and he is looking at an object which is at some height say the top of the building.",
              math_formula: "",
            },
            {
              text:
                "The line joining the eye of the man with the top of the building is called the line of sight. ",
              math_formula: "",
            },
            {
              text:
                "The angle made by the line of sight with the horizontal line is called angle of elevation.",
              math_formula: "",
            },
            {
              text: "",
              math_formula: "",
            },
            {
              text: "",
              math_formula: "",
            },
            {
              text: "",
              math_formula: "",
            },
            {
              text: "",
              math_formula: "",
            },
            {
              text: "",
              math_formula: "",
            },
            {
              text: "",
              math_formula: "",
            },
          ],
        },
        {
          th_heading: "Angle of Depression: ",
          isPoint: false,
          th_text: [
            {
              text:
                "Now let us take another situation where the person is standing at some height with respect to the object he is seeing.",
              math_formula: "",
            },
            {
              text: "",
              math_formula:
                " In this case again the line joining the eye of the man with the bottom of the building is called the line of sight.",
            },
            {
              text:
                "The angle made by the line of sight with the horizontal line is called angle of depression, where ‘θ’ is the angle of depression.",
              math_formula: "",
            },
            {
              text:
                "Note: The angle of elevation is equal to the angle of depression.",
              math_formula: "",
            },
            {
              text:
                "The questions on this topic require basic knowledge of Trigonometry. You should be aware of the basic trigonometric ratios and their values.",
              math_formula: "",
            },
            {
              text:
                "Let us recall that the ratios of the sides of a right angled triangle are called trigonometric ratios. These are sine, cosine, tangent, cosecant, secant and cotangent.",
              math_formula: "",
            },
            {
              text: "Let the ΔABC is a right angled triangle.",
              math_formula: "",
            },
            {
              text: "",
              math_formula: "Then Sin θ = AB/AC",
            },
            {
              text: "",
              math_formula: "Cos θ = BC/AC",
            },
            {
              text: "",
              math_formula: "Tan θ = AB/BC",
            },
            {
              text: "",
              math_formula: "Cosec θ = AC/AB",
            },
            {
              text: "",
              math_formula: "Sec θ = AC/BC",
            },
            {
              text: "",
              math_formula: "Cot θ = BC/AB",
            },
          ],
        },
        {
          th_heading: "",
          isPoint: false,
          th_text: [
            {
              text:
                "The values in the table will be used while solving the questions on height and distances. Let us now discuss some basic problems on height and distances.",
              math_formula: "",
            },
            {
              text: "",
              math_formula:
                " After going through these examples you will learn how to measure the height and how to find the distance.",
            },
            {
              text: "",
              math_formula: "",
            },
            {
              text: "",
              math_formula: "",
            },
          ],
        },
      ],
    },
    {
      name: "Simplification",
      topic_text:
        "Similar to the concept of speed, distance and time, train problems are specifically based on evaluating the speed, distance covered and time is taken by a train under different conditions. Train problems form an integral part of the time and speed questions which are frequently asked in the quantitative aptitude section of various Government exams. These questions are different from the basic speed, distance and time questions and require a different approach to be answered.",
      theory_array: [
        {
          th_heading: "Time, Speed and Distance: Problems on Trains",
          isPoint: false,
          th_text: [
            {
              text:
                "In this article, you will learn the concepts related to an important topic in Time, Speed and Distance i.e. Trains.",
              math_formula: "",
            },
            {
              text:
                "Problems on trains are very common in competitive exams. Various types of questions are asked on trains. ",
              math_formula: "",
            },
            {
              text:
                "Questions on trains are solved using the concept of time, speed and distance i.e. we use the formulas of time, speed and distance to solve questions on trains.",
              math_formula: "",
            },
            {
              text:
                "Given below is a list of some important points that need to be kept in mind while solving questions on trains.",
              math_formula: "",
            },
          ],
        },
        {
          th_heading: "Important Points",
          isPoint: true,
          th_text: [
            {
              text:
                "When two trains are going in the same direction, then their relative speed is the difference between the two speeds.",
              math_formula: "",
            },
            {
              text:
                "When two trains are moving in the opposite direction, then their relative speed is the sum of the two speeds.",
              math_formula: "",
            },
            {
              text:
                "When a train crosses a stationary man/ pole/ lamp post/ sign post- in all these cases, the object which the train crosses is stationary and the distance travelled is the length of the train.",
              math_formula: "",
            },
            {
              text:
                "When it crosses a platform/ bridge- in these cases, the object which the train crosses is stationary and the distance travelled is the length of the train and the length of the object.",
              math_formula: "",
            },
            {
              text:
                "When two trains are moving in same direction, then their speed will be subtracted.",
              math_formula: "",
            },
            {
              text:
                "When two trains are moving in opposite directions, then their speed will be added.",
              math_formula: "",
            },
            {
              text:
                "In both the above cases, the total distance is the sum of the length of both the trains.",
              math_formula: "",
            },
            {
              text:
                "When a train crosses a car/ bicycle/ a mobile man- in these cases, the relative speed between the train and the object is taken depending upon the direction of the movement of the other object relative to the train- and the distance travelled is the length of the train.",
              math_formula: "",
            },
            {
              text:
                "Now, let us try doing some questions and understand the concept of solving train related problems",
              math_formula: "",
            },
          ],
        },
        {
          th_heading: "Solved Questions",
          isPoint: false,
          th_text: [
            {
              text:
                "Question 1: A train travelling at 60 kmph crosses a man in 6 seconds. What is the length of the train?",
              math_formula: "",
            },
            {
              text: "Solution: Speed in m/sec = 60 *(5/18) = 50/3 m/sec ",
              math_formula: "",
            },
            {
              text: "Time taken to cross the man = 6 secs",
              math_formula: "",
            },
            {
              text:
                "Therefore, Distance = (50/3)* 6 = 100 metres (i.e. the length of the train)",
              math_formula: "",
            },
            {
              text:
                "Question 2: A train travelling at 60 kmph crosses another train travelling in the same direction at 50 kmph in 30 seconds. What is the combined length of both the trains?",
              math_formula: "",
            },
            {
              text:
                "Solution: Speed of train A = 60 kmph = 60* (5/18) = 50/3 m/sec",
              math_formula: "",
            },
            {
              text: "Speed of train B = = 50 kmph = 50 *(5/18) = 125/9 m/sec",
              math_formula: "",
            },
            {
              text:
                "The relative speed =(50/3)-(125/9)=25/9 m/s (we have subtracted the two values because both the trains are going in the same direction)",
              math_formula: "",
            },
            {
              text: "Time taken by train A to cross train B = 30 secs",
              math_formula: "",
            },
            {
              text: "Distance = Speed * Time",
              math_formula: "",
            },
            {
              text:
                "Distance =25/9 * 30 = 250/3 metres (i.e. the combined length of both trains)",
              math_formula: "",
            },
            {
              text:
                "Question 3: The average speed of a train without stoppages is 48 kmph and average speed with stoppages is 40 kmph. How many minutes in an hour the train stops on an average?",
              math_formula: "",
            },
            {
              text:
                "Solution: If the train were to travel without stoppages, it would cover 48 km.",
              math_formula: "",
            },
            {
              text:
                "With stoppages, the average speed reduces by (48-40) = 8 km",
              math_formula: "",
            },
            {
              text: "Therefore, (8/48)* 60 minutes = 10 minutes",
              math_formula: "",
            },
            {
              text:
                "Hence, 10 minutes would be the time per hour the train stops on an average.",
              math_formula: "",
            },
            {
              text:
                "Question 4: A train travelling at 60 kmph crosses a man in 6 seconds. What is the length of the train?",
              math_formula: "",
            },
            {
              text: "Solution: Speed in m/sec = 60 *(5/18) = 50/3 m/sec ",
              math_formula: "",
            },
            {
              text: "Time taken to cross the man = 6 secs",
              math_formula: "",
            },
            {
              text:
                "Therefore, Distance = (50/3)* 6 = 100 metres (i.e. the length of the train)",
              math_formula: "",
            },
            {
              text:
                "Question 5: A train travelling at 60 kmph crosses another train travelling in the same direction at 50 kmph in 30 seconds. What is the combined length of both the trains?",
              math_formula: "",
            },
            {
              text:
                "Solution: Speed of train A = 60 kmph = 60* (5/18) = 50/3 m/sec",
              math_formula: "",
            },
            {
              text: "Speed of train B = = 50 kmph = 50 *(5/18) = 125/9 m/sec",
              math_formula: "",
            },
            {
              text:
                "The relative speed =(50/3)-(125/9)=25/9 m/s (we have subtracted the two values because both the trains are going in the same direction)",
              math_formula: "",
            },
            {
              text: "Time taken by train A to cross train B = 30 secs",
              math_formula: "",
            },
            {
              text: "Distance = Speed * Time",
              math_formula: "",
            },
            {
              text:
                "Distance =25/9 * 30 = 250/3 metres (i.e. the combined length of both trains)",
              math_formula: "",
            },
            {
              text:
                "Question 6: The average speed of a train without stoppages is 48 kmph and average speed with stoppages is 40 kmph. How many minutes in an hour the train stops on an average?",
              math_formula: "",
            },
            {
              text:
                "Solution: If the train were to travel without stoppages, it would cover 48 km.",
              math_formula: "",
            },
            {
              text:
                "With stoppages, the average speed reduces by (48-40) = 8 km",
              math_formula: "",
            },
            {
              text: "Therefore, (8/48)* 60 minutes = 10 minutes",
              math_formula: "",
            },
            {
              text:
                "Hence, 10 minutes would be the time per hour the train stops on an average.",
              math_formula: "",
            },
            {
              text:
                "Question 8: A train travelling at 60 kmph crosses another train travelling in the same direction at 50 kmph in 30 seconds. What is the combined length of both the trains?",
              math_formula: "",
            },
            {
              text:
                "Solution: Speed of train A = 60 kmph = 60* (5/18) = 50/3 m/sec",
              math_formula: "",
            },
            {
              text: "Speed of train B = = 50 kmph = 50 *(5/18) = 125/9 m/sec",
              math_formula: "",
            },
            {
              text:
                "The relative speed =(50/3)-(125/9)=25/9 m/s (we have subtracted the two values because both the trains are going in the same direction)",
              math_formula: "",
            },
            {
              text: "Time taken by train A to cross train B = 30 secs",
              math_formula: "",
            },
            {
              text: "Distance = Speed * Time",
              math_formula: "",
            },
            {
              text:
                "Distance =25/9 * 30 = 250/3 metres (i.e. the combined length of both trains)",
              math_formula: "",
            },
            {
              text:
                "Question 8: The average speed of a train without stoppages is 48 kmph and average speed with stoppages is 40 kmph. How many minutes in an hour the train stops on an average?",
              math_formula: "",
            },
            {
              text:
                "Solution: If the train were to travel without stoppages, it would cover 48 km.",
              math_formula: "",
            },
            {
              text:
                "With stoppages, the average speed reduces by (48-40) = 8 km",
              math_formula: "",
            },
            {
              text: "Therefore, (8/48)* 60 minutes = 10 minutes",
              math_formula: "",
            },
            {
              text:
                "Hence, 10 minutes would be the time per hour the train stops on an average.",
              math_formula: "",
            },
          ],
        },
      ],
    },
    {
      name: "Surds and Indices",
      topic_text:
        "Similar to the concept of speed, distance and time, train problems are specifically based on evaluating the speed, distance covered and time is taken by a train under different conditions. Train problems form an integral part of the time and speed questions which are frequently asked in the quantitative aptitude section of various Government exams. These questions are different from the basic speed, distance and time questions and require a different approach to be answered.",
      theory_array: [
        {
          th_heading: "Heights and Distances: Theory & Concepts",
          isPoint: false,
          th_text: [
            {
              text:
                "Learn the important concepts and tricks to solve questions based on heights and distances",
              math_formula: "",
            },
            {
              text:
                "The topic of height and distance in trigonometry is an important topic from competitive examination point of view. ",
              math_formula: "",
            },
            {
              text:
                "Generally you must have seen the problems where the height of a building is given and then from the top of this building the angles of elevation or depression are given for another ",
              math_formula: "",
            },
            {
              text:
                "building and you have to find the height of the second building. In this article we will cover these problems.",
              math_formula: "",
            },
            {
              text:
                "There are certain terms associated with the heights and distances which are described as follows:",
              math_formula: "",
            },
          ],
        },
        {
          th_heading: "Angle of Elevation:",
          isPoint: false,
          th_text: [
            {
              text:
                "Let us consider a situation where a person is standing on the ground and he is looking at an object which is at some height say the top of the building.",
              math_formula: "",
            },
            {
              text:
                "The line joining the eye of the man with the top of the building is called the line of sight. ",
              math_formula: "",
            },
            {
              text:
                "The angle made by the line of sight with the horizontal line is called angle of elevation.",
              math_formula: "",
            },
            {
              text: "",
              math_formula: "",
            },
            {
              text: "",
              math_formula: "",
            },
            {
              text: "",
              math_formula: "",
            },
            {
              text: "",
              math_formula: "",
            },
            {
              text: "",
              math_formula: "",
            },
            {
              text: "",
              math_formula: "",
            },
          ],
        },
        {
          th_heading: "Angle of Depression: ",
          isPoint: false,
          th_text: [
            {
              text:
                "Now let us take another situation where the person is standing at some height with respect to the object he is seeing.",
              math_formula: "",
            },
            {
              text: "",
              math_formula:
                " In this case again the line joining the eye of the man with the bottom of the building is called the line of sight.",
            },
            {
              text:
                "The angle made by the line of sight with the horizontal line is called angle of depression, where ‘θ’ is the angle of depression.",
              math_formula: "",
            },
            {
              text:
                "Note: The angle of elevation is equal to the angle of depression.",
              math_formula: "",
            },
            {
              text:
                "The questions on this topic require basic knowledge of Trigonometry. You should be aware of the basic trigonometric ratios and their values.",
              math_formula: "",
            },
            {
              text:
                "Let us recall that the ratios of the sides of a right angled triangle are called trigonometric ratios. These are sine, cosine, tangent, cosecant, secant and cotangent.",
              math_formula: "",
            },
            {
              text: "Let the ΔABC is a right angled triangle.",
              math_formula: "",
            },
            {
              text: "",
              math_formula: "Then Sin θ = AB/AC",
            },
            {
              text: "",
              math_formula: "Cos θ = BC/AC",
            },
            {
              text: "",
              math_formula: "Tan θ = AB/BC",
            },
            {
              text: "",
              math_formula: "Cosec θ = AC/AB",
            },
            {
              text: "",
              math_formula: "Sec θ = AC/BC",
            },
            {
              text: "",
              math_formula: "Cot θ = BC/AB",
            },
          ],
        },
        {
          th_heading: "",
          isPoint: false,
          th_text: [
            {
              text:
                "The values in the table will be used while solving the questions on height and distances. Let us now discuss some basic problems on height and distances.",
              math_formula: "",
            },
            {
              text: "",
              math_formula:
                " After going through these examples you will learn how to measure the height and how to find the distance.",
            },
            {
              text: "",
              math_formula: "",
            },
            {
              text: "",
              math_formula: "",
            },
          ],
        },
      ],
    },
    {
      name: "Chain Rule",
      topic_text: "",
      theory_array: [
        {
          th_heading: "What Is Simple Interest?",
          isPoint: false,
          th_text: [
            {
              text:
                "Simple interest is a quick and easy method of calculating the interest charge on a loan.",
              math_formula: "",
            },
            {
              text:
                " Simple interest is determined by multiplying the daily interest rate by the principal by the number of days that elapse between payments.",
              math_formula: "",
            },
            {
              text:
                "This type of interest usually applies to automobile loans or short-term loans, although some mortgages use this calculation method.",
              math_formula: "",
            },
            {
              text: "",
              math_formula: "",
            },
            {
              text: "",
              math_formula: "",
            },
            {
              text: "",
              math_formula: "",
            },
          ],
        },
        {
          th_heading: "KEY TAKEAWAYS",
          isPoint: true,
          th_text: [
            {
              text:
                "Simple interest is calculated by multiplying the daily interest rate by the principal, by the number of days that elapse between payments.",
              math_formula: "",
            },
            {
              text:
                "Simple interest benefits consumers who pay their loans on time or early each month. ",
              math_formula: "",
            },
            {
              text:
                "Auto loans and short-term personal loans are usually simple interest loans. ",
              math_formula: "",
            },
            {
              text: "",
              math_formula: "",
            },
            {
              text: "",
              math_formula: "",
            },
            {
              text: "",
              math_formula: "",
            },
          ],
        },
        {
          th_heading: "Understanding Simple Interest ",
          isPoint: false,
          th_text: [
            {
              text:
                "Interest is the cost of borrowing money. Typically expressed as a percentage, it amounts to a fee or extra charge the borrower pays the lender for the financed sum. ",
              math_formula: "",
            },
            {
              text:
                "When you make a payment on a simple interest loan, the payment first goes toward that month’s interest, and the remainder goes toward the principal. Each month’s interest is paid in full so it never accrues.",
              math_formula: "",
            },
            {
              text:
                "In contrast, compound interest adds some of the monthly interest back onto the loan; in each succeeding month, you pay new interest on old interest.",
              math_formula: "",
            },
            {
              text:
                "To understand how simple interest works, consider an automobile loan that has a $15,000 principal balance and an annual 5% simple interest rate.",
              math_formula: "",
            },
            {
              text:
                " If your payment is due on May 1 and you pay it precisely on the due date, the finance company calculates your interest on the 30 days in April.",
              math_formula: "",
            },
            {
              text: "Your interest for 30 days is $61.64 under this scenario.",
              math_formula: "",
            },
            {
              text:
                " However, if you make the payment on April 21, the finance company charges you interest for only 20 days in April, dropping your interest payment to $41.09, a $20 savings.",
              math_formula: "",
            },
            {
              text: "",
              math_formula: "",
            },
          ],
        },
        {
          th_heading: "Simple Interest Formula and Example",
          isPoint: true,
          th_text: [
            {
              text:
                "The formula for simple interest is pretty, well, simple. It looks like this:",
              math_formula: "Simple Interest=P×I×N",
            },
            {
              text: "where:",
              math_formula: "P=principle",
            },
            {
              text: "",
              math_formula: "I = daily interest rate",
            },
            {
              text: "",
              math_formula: "N = Number of days between payment",
            },
            {
              text:
                "Generally, simple interest paid or received over a certain period is a fixed percentage of the principal amount that was borrowed or lent. ",
              math_formula: "",
            },
            {
              text:
                "For example, say a student obtains a simple-interest loan to pay one year of college tuition, which costs $18,000, and the annual interest rate on the loan is 6%. ",
              math_formula: "",
            },
            {
              text:
                "The student repays the loan over three years. The amount of simple interest paid is:",
              math_formula: "$3,240=$18,000×0.06×3",
            },
            {
              text: "and the total amount paid is:",
              math_formula: "$21,240=$18,000+$3,240",
            },
          ],
        },
        {
          th_heading: "Who Benefits From a Simple Interest Loan?",
          isPoint: false,
          th_text: [
            {
              text:
                "Under the student-loan scenario above, if you sent a $300 payment on May 1, then $238.36 goes toward the principal. If you sent the same payment on April 20, then $258.91 goes toward the principal. ",
              math_formula: "",
            },
            {
              text:
                "If you can pay early every month, your principal balance shrinks faster, and you pay the loan off sooner than the original estimate.",
              math_formula: "",
            },
            {
              text:
                "Conversely, if you pay the loan late, more of your payment goes toward interest than if you pay on time.",
              math_formula: "",
            },
            {
              text:
                "Using the same automobile loan example, if your payment is due on May 1 and you make it on May 16, you get charged for 45 days of interest at a cost of $92.46. ",
              math_formula: "",
            },
            {
              text:
                "This means only $207.54 of your $300 payment goes toward the principal. ",
              math_formula: "",
            },
            {
              text:
                "If you consistently pay late over the life of a loan, your final payment will be larger than the original estimate because you did not pay down the principal at the expected rate.",
              math_formula: "",
            },
          ],
        },
        {
          th_heading: "What Types of Loans Use Simple Interest?",
          isPoint: false,
          th_text: [
            {
              text:
                "Simple interest usually applies to automobile loans or short-term personal loans. In the U.S., most mortgages on an amortization schedule are also simple interest loans, although they can certainly feel like compound interest ones.",
              math_formula: "",
            },
            {
              text:
                "The compounding feel comes from varying principal payments—that is, the percentage of your mortgage payment that's actually going towards the loan itself, not the interest. ",
              math_formula: "",
            },
            {
              text: "The interest doesn’t compound; the principal payments do.",
              math_formula: "",
            },
            {
              text:
                "A $1,000 principal payment saves interest on that $1,000 and results in higher principal payments the next year, and higher the following year, and so on.",
              math_formula: "",
            },
            {
              text:
                "If you don’t let the principal payments vary, as in an interest-only loan (zero principal payment), or by equalizing the principal payments, the loan interest itself doesn’t compound.",
              math_formula: "",
            },
            {
              text:
                "Lowering the interest rate, shortening the loan term, or pre-paying principal also has a compounding effect.",
              math_formula: "",
            },
            {
              text: "For example, take bi-weekly mortgage payment plans. ",
              math_formula: "",
            },
            {
              text:
                "Bi-weekly plans generally help consumers pay off their mortgages early because the borrowers make two extra payments a year, saving interest over the life of the loan by paying off the principal faster.",
              math_formula: "",
            },
          ],
        },
        {
          th_heading: "Simple Interest vs. Compound Interest",
          isPoint: false,
          th_text: [
            {
              text:
                "Interest can be either simple or compounded. Simple interest is based on the original principal amount of a loan or deposit.",
              math_formula: "",
            },
            {
              text:
                "Compound interest, on the other hand, is based on the principal amount and the interest that accumulates on it in every period.",
              math_formula: "",
            },
            {
              text:
                "Simple interest is calculated only on the principal, so it is easier to determine than compound interest.",
              math_formula: "",
            },
            {
              text:
                "In real-life situations, compound interest is often a factor in business transactions, investments, and financial products intended to extend for multiple periods or years.",
              math_formula: "",
            },
            {
              text:
                "Simple interest is mainly used for easy calculations: those generally for a single period or less than a year. Simple interest also applies to open-ended situations, such as credit card balances.",
              math_formula: "",
            },
          ],
        },
        {
          th_heading: "Why is Simple Interest 'Simple'?",
          isPoint: false,
          th_text: [
            {
              text:
                "'Simple' interest refers to the straightforward crediting of cash flows associated with some investment or deposit. ",
              math_formula: "",
            },
            {
              text:
                "For instance, 1% annual simple interest would credit $1 for every $100 invested, year after year. ",
              math_formula: "",
            },
            {
              text:
                " Simple interest does not, however, take into account the power of compounding, or interest-on-interest, where after the first year the 1% would actually be earned on the $101 balance—adding up to $1.01. ",
              math_formula: "",
            },
            {
              text:
                "The next year, the 1% would be earned on $102.01, amounting to $1.02. And so one.",
              math_formula: "",
            },
          ],
        },
        {
          th_heading:
            "Which Will Pay Out More Over Time, Simple or Compound Interest?",
          isPoint: false,
          th_text: [
            {
              text:
                "Compound interest will always pay more after the first payment period. ",
              math_formula: "",
            },
            {
              text:
                "Suppose you borrow $10,000 at a 10% annual interest rate with the principal and interest due as a lump sum in three years. ",
              math_formula: "",
            },
            {
              text:
                "Using a simple interest calculation, 10% of the principal balance gets added to your repayment amount during each of the three years. ",
              math_formula: "",
            },
            {
              text:
                "That comes out to $1,000 per year, which totals $3,000 in interest over the life of the loan. At repayment, then, the amount due is $13,000. ",
              math_formula: "",
            },
            {
              text:
                "Now suppose you take out the same loan, with the same terms, but the interest is compounded annually. When the loan is due, instead of owing $13,000, you end up owing $13,310.",
              math_formula: "",
            },
            {
              text:
                " While you may not consider $310 a huge difference, this example is only a three-year loan; compound interest piles up and becomes oppressive with longer loan terms.",
              math_formula: "",
            },
          ],
        },
      ],
    },
    {
      name: "Boats and Streams",
      topic_text:
        "Similar to the concept of speed, distance and time, train problems are specifically based on evaluating the speed, distance covered and time is taken by a train under different conditions. Train problems form an integral part of the time and speed questions which are frequently asked in the quantitative aptitude section of various Government exams. These questions are different from the basic speed, distance and time questions and require a different approach to be answered.",
      theory_array: [
        {
          th_heading: "Time, Speed and Distance: Problems on Trains",
          isPoint: false,
          th_text: [
            {
              text:
                "In this article, you will learn the concepts related to an important topic in Time, Speed and Distance i.e. Trains.",
              math_formula: "",
            },
            {
              text:
                "Problems on trains are very common in competitive exams. Various types of questions are asked on trains. ",
              math_formula: "",
            },
            {
              text:
                "Questions on trains are solved using the concept of time, speed and distance i.e. we use the formulas of time, speed and distance to solve questions on trains.",
              math_formula: "",
            },
            {
              text:
                "Given below is a list of some important points that need to be kept in mind while solving questions on trains.",
              math_formula: "",
            },
          ],
        },
        {
          th_heading: "Important Points",
          isPoint: true,
          th_text: [
            {
              text:
                "When two trains are going in the same direction, then their relative speed is the difference between the two speeds.",
              math_formula: "",
            },
            {
              text:
                "When two trains are moving in the opposite direction, then their relative speed is the sum of the two speeds.",
              math_formula: "",
            },
            {
              text:
                "When a train crosses a stationary man/ pole/ lamp post/ sign post- in all these cases, the object which the train crosses is stationary and the distance travelled is the length of the train.",
              math_formula: "",
            },
            {
              text:
                "When it crosses a platform/ bridge- in these cases, the object which the train crosses is stationary and the distance travelled is the length of the train and the length of the object.",
              math_formula: "",
            },
            {
              text:
                "When two trains are moving in same direction, then their speed will be subtracted.",
              math_formula: "",
            },
            {
              text:
                "When two trains are moving in opposite directions, then their speed will be added.",
              math_formula: "",
            },
            {
              text:
                "In both the above cases, the total distance is the sum of the length of both the trains.",
              math_formula: "",
            },
            {
              text:
                "When a train crosses a car/ bicycle/ a mobile man- in these cases, the relative speed between the train and the object is taken depending upon the direction of the movement of the other object relative to the train- and the distance travelled is the length of the train.",
              math_formula: "",
            },
            {
              text:
                "Now, let us try doing some questions and understand the concept of solving train related problems",
              math_formula: "",
            },
          ],
        },
        {
          th_heading: "Solved Questions",
          isPoint: false,
          th_text: [
            {
              text:
                "Question 1: A train travelling at 60 kmph crosses a man in 6 seconds. What is the length of the train?",
              math_formula: "",
            },
            {
              text: "Solution: Speed in m/sec = 60 *(5/18) = 50/3 m/sec ",
              math_formula: "",
            },
            {
              text: "Time taken to cross the man = 6 secs",
              math_formula: "",
            },
            {
              text:
                "Therefore, Distance = (50/3)* 6 = 100 metres (i.e. the length of the train)",
              math_formula: "",
            },
            {
              text:
                "Question 2: A train travelling at 60 kmph crosses another train travelling in the same direction at 50 kmph in 30 seconds. What is the combined length of both the trains?",
              math_formula: "",
            },
            {
              text:
                "Solution: Speed of train A = 60 kmph = 60* (5/18) = 50/3 m/sec",
              math_formula: "",
            },
            {
              text: "Speed of train B = = 50 kmph = 50 *(5/18) = 125/9 m/sec",
              math_formula: "",
            },
            {
              text:
                "The relative speed =(50/3)-(125/9)=25/9 m/s (we have subtracted the two values because both the trains are going in the same direction)",
              math_formula: "",
            },
            {
              text: "Time taken by train A to cross train B = 30 secs",
              math_formula: "",
            },
            {
              text: "Distance = Speed * Time",
              math_formula: "",
            },
            {
              text:
                "Distance =25/9 * 30 = 250/3 metres (i.e. the combined length of both trains)",
              math_formula: "",
            },
            {
              text:
                "Question 3: The average speed of a train without stoppages is 48 kmph and average speed with stoppages is 40 kmph. How many minutes in an hour the train stops on an average?",
              math_formula: "",
            },
            {
              text:
                "Solution: If the train were to travel without stoppages, it would cover 48 km.",
              math_formula: "",
            },
            {
              text:
                "With stoppages, the average speed reduces by (48-40) = 8 km",
              math_formula: "",
            },
            {
              text: "Therefore, (8/48)* 60 minutes = 10 minutes",
              math_formula: "",
            },
            {
              text:
                "Hence, 10 minutes would be the time per hour the train stops on an average.",
              math_formula: "",
            },
            {
              text:
                "Question 4: A train travelling at 60 kmph crosses a man in 6 seconds. What is the length of the train?",
              math_formula: "",
            },
            {
              text: "Solution: Speed in m/sec = 60 *(5/18) = 50/3 m/sec ",
              math_formula: "",
            },
            {
              text: "Time taken to cross the man = 6 secs",
              math_formula: "",
            },
            {
              text:
                "Therefore, Distance = (50/3)* 6 = 100 metres (i.e. the length of the train)",
              math_formula: "",
            },
            {
              text:
                "Question 5: A train travelling at 60 kmph crosses another train travelling in the same direction at 50 kmph in 30 seconds. What is the combined length of both the trains?",
              math_formula: "",
            },
            {
              text:
                "Solution: Speed of train A = 60 kmph = 60* (5/18) = 50/3 m/sec",
              math_formula: "",
            },
            {
              text: "Speed of train B = = 50 kmph = 50 *(5/18) = 125/9 m/sec",
              math_formula: "",
            },
            {
              text:
                "The relative speed =(50/3)-(125/9)=25/9 m/s (we have subtracted the two values because both the trains are going in the same direction)",
              math_formula: "",
            },
            {
              text: "Time taken by train A to cross train B = 30 secs",
              math_formula: "",
            },
            {
              text: "Distance = Speed * Time",
              math_formula: "",
            },
            {
              text:
                "Distance =25/9 * 30 = 250/3 metres (i.e. the combined length of both trains)",
              math_formula: "",
            },
            {
              text:
                "Question 6: The average speed of a train without stoppages is 48 kmph and average speed with stoppages is 40 kmph. How many minutes in an hour the train stops on an average?",
              math_formula: "",
            },
            {
              text:
                "Solution: If the train were to travel without stoppages, it would cover 48 km.",
              math_formula: "",
            },
            {
              text:
                "With stoppages, the average speed reduces by (48-40) = 8 km",
              math_formula: "",
            },
            {
              text: "Therefore, (8/48)* 60 minutes = 10 minutes",
              math_formula: "",
            },
            {
              text:
                "Hence, 10 minutes would be the time per hour the train stops on an average.",
              math_formula: "",
            },
            {
              text:
                "Question 8: A train travelling at 60 kmph crosses another train travelling in the same direction at 50 kmph in 30 seconds. What is the combined length of both the trains?",
              math_formula: "",
            },
            {
              text:
                "Solution: Speed of train A = 60 kmph = 60* (5/18) = 50/3 m/sec",
              math_formula: "",
            },
            {
              text: "Speed of train B = = 50 kmph = 50 *(5/18) = 125/9 m/sec",
              math_formula: "",
            },
            {
              text:
                "The relative speed =(50/3)-(125/9)=25/9 m/s (we have subtracted the two values because both the trains are going in the same direction)",
              math_formula: "",
            },
            {
              text: "Time taken by train A to cross train B = 30 secs",
              math_formula: "",
            },
            {
              text: "Distance = Speed * Time",
              math_formula: "",
            },
            {
              text:
                "Distance =25/9 * 30 = 250/3 metres (i.e. the combined length of both trains)",
              math_formula: "",
            },
            {
              text:
                "Question 8: The average speed of a train without stoppages is 48 kmph and average speed with stoppages is 40 kmph. How many minutes in an hour the train stops on an average?",
              math_formula: "",
            },
            {
              text:
                "Solution: If the train were to travel without stoppages, it would cover 48 km.",
              math_formula: "",
            },
            {
              text:
                "With stoppages, the average speed reduces by (48-40) = 8 km",
              math_formula: "",
            },
            {
              text: "Therefore, (8/48)* 60 minutes = 10 minutes",
              math_formula: "",
            },
            {
              text:
                "Hence, 10 minutes would be the time per hour the train stops on an average.",
              math_formula: "",
            },
          ],
        },
      ],
    },
    {
      name: "Logarithm",
      topic_text:
        "Similar to the concept of speed, distance and time, train problems are specifically based on evaluating the speed, distance covered and time is taken by a train under different conditions. Train problems form an integral part of the time and speed questions which are frequently asked in the quantitative aptitude section of various Government exams. These questions are different from the basic speed, distance and time questions and require a different approach to be answered.",
      theory_array: [
        {
          th_heading: "What Is Simple Interest?",
          isPoint: false,
          th_text: [
            {
              text:
                "Simple interest is a quick and easy method of calculating the interest charge on a loan.",
              math_formula: "",
            },
            {
              text:
                " Simple interest is determined by multiplying the daily interest rate by the principal by the number of days that elapse between payments.",
              math_formula: "",
            },
            {
              text:
                "This type of interest usually applies to automobile loans or short-term loans, although some mortgages use this calculation method.",
              math_formula: "",
            },
            {
              text: "",
              math_formula: "",
            },
            {
              text: "",
              math_formula: "",
            },
            {
              text: "",
              math_formula: "",
            },
          ],
        },
        {
          th_heading: "KEY TAKEAWAYS",
          isPoint: true,
          th_text: [
            {
              text:
                "Simple interest is calculated by multiplying the daily interest rate by the principal, by the number of days that elapse between payments.",
              math_formula: "",
            },
            {
              text:
                "Simple interest benefits consumers who pay their loans on time or early each month. ",
              math_formula: "",
            },
            {
              text:
                "Auto loans and short-term personal loans are usually simple interest loans. ",
              math_formula: "",
            },
            {
              text: "",
              math_formula: "",
            },
            {
              text: "",
              math_formula: "",
            },
            {
              text: "",
              math_formula: "",
            },
          ],
        },
        {
          th_heading: "Understanding Simple Interest ",
          isPoint: false,
          th_text: [
            {
              text:
                "Interest is the cost of borrowing money. Typically expressed as a percentage, it amounts to a fee or extra charge the borrower pays the lender for the financed sum. ",
              math_formula: "",
            },
            {
              text:
                "When you make a payment on a simple interest loan, the payment first goes toward that month’s interest, and the remainder goes toward the principal. Each month’s interest is paid in full so it never accrues.",
              math_formula: "",
            },
            {
              text:
                "In contrast, compound interest adds some of the monthly interest back onto the loan; in each succeeding month, you pay new interest on old interest.",
              math_formula: "",
            },
            {
              text:
                "To understand how simple interest works, consider an automobile loan that has a $15,000 principal balance and an annual 5% simple interest rate.",
              math_formula: "",
            },
            {
              text:
                " If your payment is due on May 1 and you pay it precisely on the due date, the finance company calculates your interest on the 30 days in April.",
              math_formula: "",
            },
            {
              text: "Your interest for 30 days is $61.64 under this scenario.",
              math_formula: "",
            },
            {
              text:
                " However, if you make the payment on April 21, the finance company charges you interest for only 20 days in April, dropping your interest payment to $41.09, a $20 savings.",
              math_formula: "",
            },
            {
              text: "",
              math_formula: "",
            },
          ],
        },
        {
          th_heading: "Simple Interest Formula and Example",
          isPoint: true,
          th_text: [
            {
              text:
                "The formula for simple interest is pretty, well, simple. It looks like this:",
              math_formula: "Simple Interest=P×I×N",
            },
            {
              text: "where:",
              math_formula: "P=principle",
            },
            {
              text: "",
              math_formula: "I = daily interest rate",
            },
            {
              text: "",
              math_formula: "N = Number of days between payment",
            },
            {
              text:
                "Generally, simple interest paid or received over a certain period is a fixed percentage of the principal amount that was borrowed or lent. ",
              math_formula: "",
            },
            {
              text:
                "For example, say a student obtains a simple-interest loan to pay one year of college tuition, which costs $18,000, and the annual interest rate on the loan is 6%. ",
              math_formula: "",
            },
            {
              text:
                "The student repays the loan over three years. The amount of simple interest paid is:",
              math_formula: "$3,240=$18,000×0.06×3",
            },
            {
              text: "and the total amount paid is:",
              math_formula: "$21,240=$18,000+$3,240",
            },
          ],
        },
        {
          th_heading: "Who Benefits From a Simple Interest Loan?",
          isPoint: false,
          th_text: [
            {
              text:
                "Under the student-loan scenario above, if you sent a $300 payment on May 1, then $238.36 goes toward the principal. If you sent the same payment on April 20, then $258.91 goes toward the principal. ",
              math_formula: "",
            },
            {
              text:
                "If you can pay early every month, your principal balance shrinks faster, and you pay the loan off sooner than the original estimate.",
              math_formula: "",
            },
            {
              text:
                "Conversely, if you pay the loan late, more of your payment goes toward interest than if you pay on time.",
              math_formula: "",
            },
            {
              text:
                "Using the same automobile loan example, if your payment is due on May 1 and you make it on May 16, you get charged for 45 days of interest at a cost of $92.46. ",
              math_formula: "",
            },
            {
              text:
                "This means only $207.54 of your $300 payment goes toward the principal. ",
              math_formula: "",
            },
            {
              text:
                "If you consistently pay late over the life of a loan, your final payment will be larger than the original estimate because you did not pay down the principal at the expected rate.",
              math_formula: "",
            },
          ],
        },
        {
          th_heading: "What Types of Loans Use Simple Interest?",
          isPoint: false,
          th_text: [
            {
              text:
                "Simple interest usually applies to automobile loans or short-term personal loans. In the U.S., most mortgages on an amortization schedule are also simple interest loans, although they can certainly feel like compound interest ones.",
              math_formula: "",
            },
            {
              text:
                "The compounding feel comes from varying principal payments—that is, the percentage of your mortgage payment that's actually going towards the loan itself, not the interest. ",
              math_formula: "",
            },
            {
              text: "The interest doesn’t compound; the principal payments do.",
              math_formula: "",
            },
            {
              text:
                "A $1,000 principal payment saves interest on that $1,000 and results in higher principal payments the next year, and higher the following year, and so on.",
              math_formula: "",
            },
            {
              text:
                "If you don’t let the principal payments vary, as in an interest-only loan (zero principal payment), or by equalizing the principal payments, the loan interest itself doesn’t compound.",
              math_formula: "",
            },
            {
              text:
                "Lowering the interest rate, shortening the loan term, or pre-paying principal also has a compounding effect.",
              math_formula: "",
            },
            {
              text: "For example, take bi-weekly mortgage payment plans. ",
              math_formula: "",
            },
            {
              text:
                "Bi-weekly plans generally help consumers pay off their mortgages early because the borrowers make two extra payments a year, saving interest over the life of the loan by paying off the principal faster.",
              math_formula: "",
            },
          ],
        },
        {
          th_heading: "Simple Interest vs. Compound Interest",
          isPoint: false,
          th_text: [
            {
              text:
                "Interest can be either simple or compounded. Simple interest is based on the original principal amount of a loan or deposit.",
              math_formula: "",
            },
            {
              text:
                "Compound interest, on the other hand, is based on the principal amount and the interest that accumulates on it in every period.",
              math_formula: "",
            },
            {
              text:
                "Simple interest is calculated only on the principal, so it is easier to determine than compound interest.",
              math_formula: "",
            },
            {
              text:
                "In real-life situations, compound interest is often a factor in business transactions, investments, and financial products intended to extend for multiple periods or years.",
              math_formula: "",
            },
            {
              text:
                "Simple interest is mainly used for easy calculations: those generally for a single period or less than a year. Simple interest also applies to open-ended situations, such as credit card balances.",
              math_formula: "",
            },
          ],
        },
        {
          th_heading: "Why is Simple Interest 'Simple'?",
          isPoint: false,
          th_text: [
            {
              text:
                "'Simple' interest refers to the straightforward crediting of cash flows associated with some investment or deposit. ",
              math_formula: "",
            },
            {
              text:
                "For instance, 1% annual simple interest would credit $1 for every $100 invested, year after year. ",
              math_formula: "",
            },
            {
              text:
                " Simple interest does not, however, take into account the power of compounding, or interest-on-interest, where after the first year the 1% would actually be earned on the $101 balance—adding up to $1.01. ",
              math_formula: "",
            },
            {
              text:
                "The next year, the 1% would be earned on $102.01, amounting to $1.02. And so one.",
              math_formula: "",
            },
          ],
        },
        {
          th_heading:
            "Which Will Pay Out More Over Time, Simple or Compound Interest?",
          isPoint: false,
          th_text: [
            {
              text:
                "Compound interest will always pay more after the first payment period. ",
              math_formula: "",
            },
            {
              text:
                "Suppose you borrow $10,000 at a 10% annual interest rate with the principal and interest due as a lump sum in three years. ",
              math_formula: "",
            },
            {
              text:
                "Using a simple interest calculation, 10% of the principal balance gets added to your repayment amount during each of the three years. ",
              math_formula: "",
            },
            {
              text:
                "That comes out to $1,000 per year, which totals $3,000 in interest over the life of the loan. At repayment, then, the amount due is $13,000. ",
              math_formula: "",
            },
            {
              text:
                "Now suppose you take out the same loan, with the same terms, but the interest is compounded annually. When the loan is due, instead of owing $13,000, you end up owing $13,310.",
              math_formula: "",
            },
            {
              text:
                " While you may not consider $310 a huge difference, this example is only a three-year loan; compound interest piles up and becomes oppressive with longer loan terms.",
              math_formula: "",
            },
          ],
        },
      ],
    },
    {
      name: "Stocks and Shares",
      topic_text:
        "Similar to the concept of speed, distance and time, train problems are specifically based on evaluating the speed, distance covered and time is taken by a train under different conditions. Train problems form an integral part of the time and speed questions which are frequently asked in the quantitative aptitude section of various Government exams. These questions are different from the basic speed, distance and time questions and require a different approach to be answered.",
      theory_array: [
        {
          th_heading: "Heights and Distances: Theory & Concepts",
          isPoint: false,
          th_text: [
            {
              text:
                "Learn the important concepts and tricks to solve questions based on heights and distances",
              math_formula: "",
            },
            {
              text:
                "The topic of height and distance in trigonometry is an important topic from competitive examination point of view. ",
              math_formula: "",
            },
            {
              text:
                "Generally you must have seen the problems where the height of a building is given and then from the top of this building the angles of elevation or depression are given for another ",
              math_formula: "",
            },
            {
              text:
                "building and you have to find the height of the second building. In this article we will cover these problems.",
              math_formula: "",
            },
            {
              text:
                "There are certain terms associated with the heights and distances which are described as follows:",
              math_formula: "",
            },
          ],
        },
        {
          th_heading: "Angle of Elevation:",
          isPoint: false,
          th_text: [
            {
              text:
                "Let us consider a situation where a person is standing on the ground and he is looking at an object which is at some height say the top of the building.",
              math_formula: "",
            },
            {
              text:
                "The line joining the eye of the man with the top of the building is called the line of sight. ",
              math_formula: "",
            },
            {
              text:
                "The angle made by the line of sight with the horizontal line is called angle of elevation.",
              math_formula: "",
            },
            {
              text: "",
              math_formula: "",
            },
            {
              text: "",
              math_formula: "",
            },
            {
              text: "",
              math_formula: "",
            },
            {
              text: "",
              math_formula: "",
            },
            {
              text: "",
              math_formula: "",
            },
            {
              text: "",
              math_formula: "",
            },
          ],
        },
        {
          th_heading: "Angle of Depression: ",
          isPoint: false,
          th_text: [
            {
              text:
                "Now let us take another situation where the person is standing at some height with respect to the object he is seeing.",
              math_formula: "",
            },
            {
              text: "",
              math_formula:
                " In this case again the line joining the eye of the man with the bottom of the building is called the line of sight.",
            },
            {
              text:
                "The angle made by the line of sight with the horizontal line is called angle of depression, where ‘θ’ is the angle of depression.",
              math_formula: "",
            },
            {
              text:
                "Note: The angle of elevation is equal to the angle of depression.",
              math_formula: "",
            },
            {
              text:
                "The questions on this topic require basic knowledge of Trigonometry. You should be aware of the basic trigonometric ratios and their values.",
              math_formula: "",
            },
            {
              text:
                "Let us recall that the ratios of the sides of a right angled triangle are called trigonometric ratios. These are sine, cosine, tangent, cosecant, secant and cotangent.",
              math_formula: "",
            },
            {
              text: "Let the ΔABC is a right angled triangle.",
              math_formula: "",
            },
            {
              text: "",
              math_formula: "Then Sin θ = AB/AC",
            },
            {
              text: "",
              math_formula: "Cos θ = BC/AC",
            },
            {
              text: "",
              math_formula: "Tan θ = AB/BC",
            },
            {
              text: "",
              math_formula: "Cosec θ = AC/AB",
            },
            {
              text: "",
              math_formula: "Sec θ = AC/BC",
            },
            {
              text: "",
              math_formula: "Cot θ = BC/AB",
            },
          ],
        },
        {
          th_heading: "",
          isPoint: false,
          th_text: [
            {
              text:
                "The values in the table will be used while solving the questions on height and distances. Let us now discuss some basic problems on height and distances.",
              math_formula: "",
            },
            {
              text: "",
              math_formula:
                " After going through these examples you will learn how to measure the height and how to find the distance.",
            },
            {
              text: "",
              math_formula: "",
            },
            {
              text: "",
              math_formula: "",
            },
          ],
        },
      ],
    },
    {
      name: "True Discount",
      topic_text: "",
      theory_array: [
        {
          th_heading: "What Is Simple Interest?",
          isPoint: false,
          th_text: [
            {
              text:
                "Simple interest is a quick and easy method of calculating the interest charge on a loan.",
              math_formula: "",
            },
            {
              text:
                " Simple interest is determined by multiplying the daily interest rate by the principal by the number of days that elapse between payments.",
              math_formula: "",
            },
            {
              text:
                "This type of interest usually applies to automobile loans or short-term loans, although some mortgages use this calculation method.",
              math_formula: "",
            },
            {
              text: "",
              math_formula: "",
            },
            {
              text: "",
              math_formula: "",
            },
            {
              text: "",
              math_formula: "",
            },
          ],
        },
        {
          th_heading: "KEY TAKEAWAYS",
          isPoint: true,
          th_text: [
            {
              text:
                "Simple interest is calculated by multiplying the daily interest rate by the principal, by the number of days that elapse between payments.",
              math_formula: "",
            },
            {
              text:
                "Simple interest benefits consumers who pay their loans on time or early each month. ",
              math_formula: "",
            },
            {
              text:
                "Auto loans and short-term personal loans are usually simple interest loans. ",
              math_formula: "",
            },
            {
              text: "",
              math_formula: "",
            },
            {
              text: "",
              math_formula: "",
            },
            {
              text: "",
              math_formula: "",
            },
          ],
        },
        {
          th_heading: "Understanding Simple Interest ",
          isPoint: false,
          th_text: [
            {
              text:
                "Interest is the cost of borrowing money. Typically expressed as a percentage, it amounts to a fee or extra charge the borrower pays the lender for the financed sum. ",
              math_formula: "",
            },
            {
              text:
                "When you make a payment on a simple interest loan, the payment first goes toward that month’s interest, and the remainder goes toward the principal. Each month’s interest is paid in full so it never accrues.",
              math_formula: "",
            },
            {
              text:
                "In contrast, compound interest adds some of the monthly interest back onto the loan; in each succeeding month, you pay new interest on old interest.",
              math_formula: "",
            },
            {
              text:
                "To understand how simple interest works, consider an automobile loan that has a $15,000 principal balance and an annual 5% simple interest rate.",
              math_formula: "",
            },
            {
              text:
                " If your payment is due on May 1 and you pay it precisely on the due date, the finance company calculates your interest on the 30 days in April.",
              math_formula: "",
            },
            {
              text: "Your interest for 30 days is $61.64 under this scenario.",
              math_formula: "",
            },
            {
              text:
                " However, if you make the payment on April 21, the finance company charges you interest for only 20 days in April, dropping your interest payment to $41.09, a $20 savings.",
              math_formula: "",
            },
            {
              text: "",
              math_formula: "",
            },
          ],
        },
        {
          th_heading: "Simple Interest Formula and Example",
          isPoint: true,
          th_text: [
            {
              text:
                "The formula for simple interest is pretty, well, simple. It looks like this:",
              math_formula: "Simple Interest=P×I×N",
            },
            {
              text: "where:",
              math_formula: "P=principle",
            },
            {
              text: "",
              math_formula: "I = daily interest rate",
            },
            {
              text: "",
              math_formula: "N = Number of days between payment",
            },
            {
              text:
                "Generally, simple interest paid or received over a certain period is a fixed percentage of the principal amount that was borrowed or lent. ",
              math_formula: "",
            },
            {
              text:
                "For example, say a student obtains a simple-interest loan to pay one year of college tuition, which costs $18,000, and the annual interest rate on the loan is 6%. ",
              math_formula: "",
            },
            {
              text:
                "The student repays the loan over three years. The amount of simple interest paid is:",
              math_formula: "$3,240=$18,000×0.06×3",
            },
            {
              text: "and the total amount paid is:",
              math_formula: "$21,240=$18,000+$3,240",
            },
          ],
        },
        {
          th_heading: "Who Benefits From a Simple Interest Loan?",
          isPoint: false,
          th_text: [
            {
              text:
                "Under the student-loan scenario above, if you sent a $300 payment on May 1, then $238.36 goes toward the principal. If you sent the same payment on April 20, then $258.91 goes toward the principal. ",
              math_formula: "",
            },
            {
              text:
                "If you can pay early every month, your principal balance shrinks faster, and you pay the loan off sooner than the original estimate.",
              math_formula: "",
            },
            {
              text:
                "Conversely, if you pay the loan late, more of your payment goes toward interest than if you pay on time.",
              math_formula: "",
            },
            {
              text:
                "Using the same automobile loan example, if your payment is due on May 1 and you make it on May 16, you get charged for 45 days of interest at a cost of $92.46. ",
              math_formula: "",
            },
            {
              text:
                "This means only $207.54 of your $300 payment goes toward the principal. ",
              math_formula: "",
            },
            {
              text:
                "If you consistently pay late over the life of a loan, your final payment will be larger than the original estimate because you did not pay down the principal at the expected rate.",
              math_formula: "",
            },
          ],
        },
        {
          th_heading: "What Types of Loans Use Simple Interest?",
          isPoint: false,
          th_text: [
            {
              text:
                "Simple interest usually applies to automobile loans or short-term personal loans. In the U.S., most mortgages on an amortization schedule are also simple interest loans, although they can certainly feel like compound interest ones.",
              math_formula: "",
            },
            {
              text:
                "The compounding feel comes from varying principal payments—that is, the percentage of your mortgage payment that's actually going towards the loan itself, not the interest. ",
              math_formula: "",
            },
            {
              text: "The interest doesn’t compound; the principal payments do.",
              math_formula: "",
            },
            {
              text:
                "A $1,000 principal payment saves interest on that $1,000 and results in higher principal payments the next year, and higher the following year, and so on.",
              math_formula: "",
            },
            {
              text:
                "If you don’t let the principal payments vary, as in an interest-only loan (zero principal payment), or by equalizing the principal payments, the loan interest itself doesn’t compound.",
              math_formula: "",
            },
            {
              text:
                "Lowering the interest rate, shortening the loan term, or pre-paying principal also has a compounding effect.",
              math_formula: "",
            },
            {
              text: "For example, take bi-weekly mortgage payment plans. ",
              math_formula: "",
            },
            {
              text:
                "Bi-weekly plans generally help consumers pay off their mortgages early because the borrowers make two extra payments a year, saving interest over the life of the loan by paying off the principal faster.",
              math_formula: "",
            },
          ],
        },
        {
          th_heading: "Simple Interest vs. Compound Interest",
          isPoint: false,
          th_text: [
            {
              text:
                "Interest can be either simple or compounded. Simple interest is based on the original principal amount of a loan or deposit.",
              math_formula: "",
            },
            {
              text:
                "Compound interest, on the other hand, is based on the principal amount and the interest that accumulates on it in every period.",
              math_formula: "",
            },
            {
              text:
                "Simple interest is calculated only on the principal, so it is easier to determine than compound interest.",
              math_formula: "",
            },
            {
              text:
                "In real-life situations, compound interest is often a factor in business transactions, investments, and financial products intended to extend for multiple periods or years.",
              math_formula: "",
            },
            {
              text:
                "Simple interest is mainly used for easy calculations: those generally for a single period or less than a year. Simple interest also applies to open-ended situations, such as credit card balances.",
              math_formula: "",
            },
          ],
        },
        {
          th_heading: "Why is Simple Interest 'Simple'?",
          isPoint: false,
          th_text: [
            {
              text:
                "'Simple' interest refers to the straightforward crediting of cash flows associated with some investment or deposit. ",
              math_formula: "",
            },
            {
              text:
                "For instance, 1% annual simple interest would credit $1 for every $100 invested, year after year. ",
              math_formula: "",
            },
            {
              text:
                " Simple interest does not, however, take into account the power of compounding, or interest-on-interest, where after the first year the 1% would actually be earned on the $101 balance—adding up to $1.01. ",
              math_formula: "",
            },
            {
              text:
                "The next year, the 1% would be earned on $102.01, amounting to $1.02. And so one.",
              math_formula: "",
            },
          ],
        },
        {
          th_heading:
            "Which Will Pay Out More Over Time, Simple or Compound Interest?",
          isPoint: false,
          th_text: [
            {
              text:
                "Compound interest will always pay more after the first payment period. ",
              math_formula: "",
            },
            {
              text:
                "Suppose you borrow $10,000 at a 10% annual interest rate with the principal and interest due as a lump sum in three years. ",
              math_formula: "",
            },
            {
              text:
                "Using a simple interest calculation, 10% of the principal balance gets added to your repayment amount during each of the three years. ",
              math_formula: "",
            },
            {
              text:
                "That comes out to $1,000 per year, which totals $3,000 in interest over the life of the loan. At repayment, then, the amount due is $13,000. ",
              math_formula: "",
            },
            {
              text:
                "Now suppose you take out the same loan, with the same terms, but the interest is compounded annually. When the loan is due, instead of owing $13,000, you end up owing $13,310.",
              math_formula: "",
            },
            {
              text:
                " While you may not consider $310 a huge difference, this example is only a three-year loan; compound interest piles up and becomes oppressive with longer loan terms.",
              math_formula: "",
            },
          ],
        },
      ],
    },

    {
      name: "Odd Man Out and Series",
      topic_text:
        "Similar to the concept of speed, distance and time, train problems are specifically based on evaluating the speed, distance covered and time is taken by a train under different conditions. Train problems form an integral part of the time and speed questions which are frequently asked in the quantitative aptitude section of various Government exams. These questions are different from the basic speed, distance and time questions and require a different approach to be answered.",
      theory_array: [
        {
          th_heading: "Time, Speed and Distance: Problems on Trains",
          isPoint: false,
          th_text: [
            {
              text:
                "In this article, you will learn the concepts related to an important topic in Time, Speed and Distance i.e. Trains.",
              math_formula: "",
            },
            {
              text:
                "Problems on trains are very common in competitive exams. Various types of questions are asked on trains. ",
              math_formula: "",
            },
            {
              text:
                "Questions on trains are solved using the concept of time, speed and distance i.e. we use the formulas of time, speed and distance to solve questions on trains.",
              math_formula: "",
            },
            {
              text:
                "Given below is a list of some important points that need to be kept in mind while solving questions on trains.",
              math_formula: "",
            },
          ],
        },
        {
          th_heading: "Important Points",
          isPoint: true,
          th_text: [
            {
              text:
                "When two trains are going in the same direction, then their relative speed is the difference between the two speeds.",
              math_formula: "",
            },
            {
              text:
                "When two trains are moving in the opposite direction, then their relative speed is the sum of the two speeds.",
              math_formula: "",
            },
            {
              text:
                "When a train crosses a stationary man/ pole/ lamp post/ sign post- in all these cases, the object which the train crosses is stationary and the distance travelled is the length of the train.",
              math_formula: "",
            },
            {
              text:
                "When it crosses a platform/ bridge- in these cases, the object which the train crosses is stationary and the distance travelled is the length of the train and the length of the object.",
              math_formula: "",
            },
            {
              text:
                "When two trains are moving in same direction, then their speed will be subtracted.",
              math_formula: "",
            },
            {
              text:
                "When two trains are moving in opposite directions, then their speed will be added.",
              math_formula: "",
            },
            {
              text:
                "In both the above cases, the total distance is the sum of the length of both the trains.",
              math_formula: "",
            },
            {
              text:
                "When a train crosses a car/ bicycle/ a mobile man- in these cases, the relative speed between the train and the object is taken depending upon the direction of the movement of the other object relative to the train- and the distance travelled is the length of the train.",
              math_formula: "",
            },
            {
              text:
                "Now, let us try doing some questions and understand the concept of solving train related problems",
              math_formula: "",
            },
          ],
        },
        {
          th_heading: "Solved Questions",
          isPoint: false,
          th_text: [
            {
              text:
                "Question 1: A train travelling at 60 kmph crosses a man in 6 seconds. What is the length of the train?",
              math_formula: "",
            },
            {
              text: "Solution: Speed in m/sec = 60 *(5/18) = 50/3 m/sec ",
              math_formula: "",
            },
            {
              text: "Time taken to cross the man = 6 secs",
              math_formula: "",
            },
            {
              text:
                "Therefore, Distance = (50/3)* 6 = 100 metres (i.e. the length of the train)",
              math_formula: "",
            },
            {
              text:
                "Question 2: A train travelling at 60 kmph crosses another train travelling in the same direction at 50 kmph in 30 seconds. What is the combined length of both the trains?",
              math_formula: "",
            },
            {
              text:
                "Solution: Speed of train A = 60 kmph = 60* (5/18) = 50/3 m/sec",
              math_formula: "",
            },
            {
              text: "Speed of train B = = 50 kmph = 50 *(5/18) = 125/9 m/sec",
              math_formula: "",
            },
            {
              text:
                "The relative speed =(50/3)-(125/9)=25/9 m/s (we have subtracted the two values because both the trains are going in the same direction)",
              math_formula: "",
            },
            {
              text: "Time taken by train A to cross train B = 30 secs",
              math_formula: "",
            },
            {
              text: "Distance = Speed * Time",
              math_formula: "",
            },
            {
              text:
                "Distance =25/9 * 30 = 250/3 metres (i.e. the combined length of both trains)",
              math_formula: "",
            },
            {
              text:
                "Question 3: The average speed of a train without stoppages is 48 kmph and average speed with stoppages is 40 kmph. How many minutes in an hour the train stops on an average?",
              math_formula: "",
            },
            {
              text:
                "Solution: If the train were to travel without stoppages, it would cover 48 km.",
              math_formula: "",
            },
            {
              text:
                "With stoppages, the average speed reduces by (48-40) = 8 km",
              math_formula: "",
            },
            {
              text: "Therefore, (8/48)* 60 minutes = 10 minutes",
              math_formula: "",
            },
            {
              text:
                "Hence, 10 minutes would be the time per hour the train stops on an average.",
              math_formula: "",
            },
            {
              text:
                "Question 4: A train travelling at 60 kmph crosses a man in 6 seconds. What is the length of the train?",
              math_formula: "",
            },
            {
              text: "Solution: Speed in m/sec = 60 *(5/18) = 50/3 m/sec ",
              math_formula: "",
            },
            {
              text: "Time taken to cross the man = 6 secs",
              math_formula: "",
            },
            {
              text:
                "Therefore, Distance = (50/3)* 6 = 100 metres (i.e. the length of the train)",
              math_formula: "",
            },
            {
              text:
                "Question 5: A train travelling at 60 kmph crosses another train travelling in the same direction at 50 kmph in 30 seconds. What is the combined length of both the trains?",
              math_formula: "",
            },
            {
              text:
                "Solution: Speed of train A = 60 kmph = 60* (5/18) = 50/3 m/sec",
              math_formula: "",
            },
            {
              text: "Speed of train B = = 50 kmph = 50 *(5/18) = 125/9 m/sec",
              math_formula: "",
            },
            {
              text:
                "The relative speed =(50/3)-(125/9)=25/9 m/s (we have subtracted the two values because both the trains are going in the same direction)",
              math_formula: "",
            },
            {
              text: "Time taken by train A to cross train B = 30 secs",
              math_formula: "",
            },
            {
              text: "Distance = Speed * Time",
              math_formula: "",
            },
            {
              text:
                "Distance =25/9 * 30 = 250/3 metres (i.e. the combined length of both trains)",
              math_formula: "",
            },
            {
              text:
                "Question 6: The average speed of a train without stoppages is 48 kmph and average speed with stoppages is 40 kmph. How many minutes in an hour the train stops on an average?",
              math_formula: "",
            },
            {
              text:
                "Solution: If the train were to travel without stoppages, it would cover 48 km.",
              math_formula: "",
            },
            {
              text:
                "With stoppages, the average speed reduces by (48-40) = 8 km",
              math_formula: "",
            },
            {
              text: "Therefore, (8/48)* 60 minutes = 10 minutes",
              math_formula: "",
            },
            {
              text:
                "Hence, 10 minutes would be the time per hour the train stops on an average.",
              math_formula: "",
            },
            {
              text:
                "Question 8: A train travelling at 60 kmph crosses another train travelling in the same direction at 50 kmph in 30 seconds. What is the combined length of both the trains?",
              math_formula: "",
            },
            {
              text:
                "Solution: Speed of train A = 60 kmph = 60* (5/18) = 50/3 m/sec",
              math_formula: "",
            },
            {
              text: "Speed of train B = = 50 kmph = 50 *(5/18) = 125/9 m/sec",
              math_formula: "",
            },
            {
              text:
                "The relative speed =(50/3)-(125/9)=25/9 m/s (we have subtracted the two values because both the trains are going in the same direction)",
              math_formula: "",
            },
            {
              text: "Time taken by train A to cross train B = 30 secs",
              math_formula: "",
            },
            {
              text: "Distance = Speed * Time",
              math_formula: "",
            },
            {
              text:
                "Distance =25/9 * 30 = 250/3 metres (i.e. the combined length of both trains)",
              math_formula: "",
            },
            {
              text:
                "Question 8: The average speed of a train without stoppages is 48 kmph and average speed with stoppages is 40 kmph. How many minutes in an hour the train stops on an average?",
              math_formula: "",
            },
            {
              text:
                "Solution: If the train were to travel without stoppages, it would cover 48 km.",
              math_formula: "",
            },
            {
              text:
                "With stoppages, the average speed reduces by (48-40) = 8 km",
              math_formula: "",
            },
            {
              text: "Therefore, (8/48)* 60 minutes = 10 minutes",
              math_formula: "",
            },
            {
              text:
                "Hence, 10 minutes would be the time per hour the train stops on an average.",
              math_formula: "",
            },
          ],
        },
      ],
    },
  ];

  const [showAnswer, setShowAnswer] = useState(null);
  const [stateBool, setStateBool] = useState(false);

  const handleShowAnswer = (v) => {
    setShowAnswer(v.value);
  };

  const handleHideAnswer = () => {
    setShowAnswer(null);
  };

  return (
    <div className="practicecontent-page">
      <div className="theory-outer">
        {TopicList &&
          TopicList.map((item) => {
            if (item.name === mid) {
              return (
                <React.Fragment>
                  <div className="theory-inner">
                    <div className="theory-topic-heading"> {mid} </div>
                    <div className="theory-topic-para"> {item.topic_text} </div>
                    <div className="theory-content">
                      {item.theory_array.map((subTopics) => {
                        return (
                          <div className="subTopics-content">
                            <div className="subTopics-heading">
                              {subTopics.th_heading}
                            </div>
                            <div className="subTopics-para">
                              {subTopics.th_text.map((data) => {
                                return (
                                  <React.Fragment>
                                    <div className="th-text-data">
                                      <div
                                        className={
                                          subTopics.isPoint == true
                                            ? "th-text-points"
                                            : "th-text-normal"
                                        }
                                      >
                                        <ul className="th-text-para">
                                          <li>{data.text}</li>
                                        </ul>
                                        {data.math_formula && (
                                          <MathJax.Provider className="th-text-formula">
                                            <MathJax.Node
                                              formula={data.math_formula}
                                            />
                                          </MathJax.Provider>
                                        )}
                                      </div>
                                    </div>
                                  </React.Fragment>
                                );
                              })}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <h3>Practice Problems</h3>
                  {item.questions.map((ques) => {
                    return (
                      <div className="question-box">
                        <div className="ques-no">
                          <span className="ques-text">
                           {ques.ques_no }{". "} {ques.is_ques_text_mathematical && (
                              <span className="ques-math-text">
                                <MathJax.Provider>
                                  <MathJax.Node
                                    inline
                                    formula={ques.quesText}
                                  />
                                </MathJax.Provider>
                              </span>
                            )}
                            {!ques.is_ques_text_mathematical && (
                              <span className="ques-normal-text">
                                {ques.quesText}
                              </span>
                            )}
                          </span>
                        </div>
                        <div className="ques-options">
                          {ques.is_options_mathematical &&
                            ques.options.map((option) => {
                              return (
                                <div className="optionValue">
                                  {" "}
                                  {option.option_No})
                                  <span className="option-text">
                                    <MathJax.Provider>
                                      <MathJax.Node
                                        inline
                                        formula={option.val}
                                      />
                                    </MathJax.Provider>
                                  </span>
                                </div>
                              );
                            })}{" "}
                          {!ques.is_options_mathematical &&
                            ques.options.map((option) => {
                              return (
                                <div className="optionValue">
                                  {" "}
                                  {option.option_No})
                                  <span className="option-text">
                                    {option.val}
                                  </span>
                                </div>
                              );
                            })}
                        </div>
                        <div className="answer-apti">
                          {showAnswer != ques.ques_no ? (
                            <div>
                              {/* {setQues_number(ques.ques_no)} */}
                              <button
                                className="button-style-css"
                                onClick={() =>
                                  handleShowAnswer({ value: `${ques.ques_no}` })
                                }
                              >
                                Show Answer
                              </button>
                            </div>
                          ) : (
                            <div>
                              <button
                                className="button-style-css"
                                onClick={() => handleHideAnswer()}
                              >
                                Hide Answer
                              </button>
                              <div data-aos="fade-in-down">
                                <div className="options-answer-lines">
                                  <div className="correct-option">
                                    {" "}
                                    Correct Option is  ({
                                      ques.correct_option
                                    }){" "}
                                  </div>
                                  {ques.is_answer_mathematical &&
                                    ques.answer_lines.map((ans) => {
                                      return (
                                        <div className="answer-line">
                                          <span className="answer-text">
                                            <MathJax.Provider>
                                              <MathJax.Node
                                                inline
                                                formula={ans.ans_Line}
                                              />
                                            </MathJax.Provider>
                                          </span>
                                        </div>
                                      );
                                    })}
                                  {!ques.is_answer_mathematical &&
                                    ques.answer_lines.map((ans) => {
                                      return (
                                        <div className="answer-line">
                                          <span className="answer-text">
                                            {ans.ans_Line}
                                          </span>
                                        </div>
                                      );
                                    })}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </React.Fragment>
              );
            }
          })}
      </div>
    </div>
  );
};

export default PracticeContent;
