import React, { useState, useEffect, useContext } from "react";
import "./RewardHistory.css";
import { useHttpClient } from "../../../../Components/Hooks/http-hook";
import { AuthContext } from "../../../../context/auth-context";
import Loading from "../../../../Components/Loading";
import Button from "../../../../Components/Profile/Button/Button";
import moment from "moment/moment";
import { ToastContainer, toast } from "react-toastify";

const RewardHistory = () => {
  const [referralHistory, setRewardHistory] = useState([]);
  const [totalRewardAmount, setTotalRewardAmount] = useState(0);
  const [pageLoading, setPageLoading] = useState(true);
  const { sendRequest, isLoading, error, clearError } = useHttpClient();
  const auth = useContext(AuthContext);

  const formatDate = (date) => {
    return moment(date).format("DD MMM YYYY");
  };

  useEffect(() => {
    const fetchReferralCode = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/referral/get-referral-history`,
          "GET",
          null,
          {
            "Content-Type": "application/json",
            "Refresh-Token": auth.refresh_token,
            Authorization: auth.token,
          }
        );
        if (responseData.data) {
          setRewardHistory(responseData.data.referral_history);
          setTotalRewardAmount(responseData.data.total_reward_amount);
        }
        setPageLoading(false);
      } catch (err) {}
    };
    fetchReferralCode();
  }, []);

  useEffect(() => {
    if (error) {
      toast.error(error);
      setPageLoading(false);
      clearError();
    }
  }, [error, clearError]);

  return (
    <div>
      <ToastContainer closeButton={false} />
      <div className="referral-header">
        <div className="referral-header-title">Rewards History</div>
        <div className="referral-header-right">
          <Button
            text={`Total Reward Amount: Rs. ${totalRewardAmount}`}
            width="auto"
          />
        </div>
      </div>
      {pageLoading ? (
        <div className="referral-loader-container">
          <div className="referral-loader-sub-container">
            <Loading
              color="#14144e"
              customClass="referral-loader-container-for-loader"
            />
          </div>
        </div>
      ) : (
        <div className="referral-main-section">
          {referralHistory.length > 0 ? (
            <div className="reward-history-table">
              <div className="reward-history-table-header">
                <div className="reward-history-table-header-name">Name</div>
                <div className="reward-history-table-header-date">Date</div>
                <div className="reward-history-table-header-type">Type</div>
                <div className="reward-history-table-header-status">Status</div>
              </div>
              <div className="reward-history-hr-line"></div>
              <div className="reward-history-table-body">
                {referralHistory.map((data) => (
                  <div key={data.id} className="reward-history-table-row">
                    <div className="reward-history-table-name">{data.user_details && data.user_details.name}</div>
                    <div className="reward-history-table-date">{data.date && formatDate(data.date)}</div>
                    <div className="reward-history-table-type">{data.user_details && data.user_details.type}</div>
                    <div
                      className={`reward-history-table-status ${
                        data.status === "pending"
                          ? "reward-history-status-in-progress"
                          : "reward-history-status-completed"
                      }`}
                    >
                      {data.status === "pending" ? "In Progress" : "Completed"}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className="referral-history-error-container">
              No rewards history found for you
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default RewardHistory;
