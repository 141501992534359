import React from 'react';
import { PieChart, Pie, Tooltip, Cell } from 'recharts';

const dummydata = [
  { name: 'Red', value: 20 },
  { name: 'Blue', value: 80 },
];

const COLORS = ['#FA1B00', '#00AD50'];

const CustomPieChart = ({
  width = 200,
  height = 200,
  data = dummydata,
  dataKey = 'value',
  nameKey = 'name',
  cx = '50%',
  cy = '50%',
  outerRadius = 80,
  fill = '#8884d8',
  colors = COLORS,
}) => (
  <PieChart width={width} height={height}>
    <Pie data={data} dataKey={dataKey} nameKey={nameKey} cx={cx} cy={cy} outerRadius={outerRadius} fill={fill}>
      {data && data.map((entry, index) => (
        <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
      ))}
    </Pie>
    <Tooltip />
  </PieChart>
);

export default CustomPieChart;
