import React from 'react';
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';

const data = [
  { name: 'Completed', value: 400 },
  { name: 'InComplete', value: 300 },
];

const COLORS = ['#00AD50', '#FA1B00'];

const ProfileProgressChart = () => (
  <PieChart width={400} height={400}>
    <Pie
      data={data}
      cx={200}
      cy={200}
      startAngle={180}
      endAngle={0}
      innerRadius={80}
      outerRadius={120}
      fill="#8884d8"
      paddingAngle={2}
      dataKey="value"
    >
      {data.map((entry, index) => (
        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
      ))}
    </Pie>
    <Tooltip />
  </PieChart>
);

export default ProfileProgressChart;
