import React, { useState , useContext } from 'react';
import './FileUpload.css';
import UploadIcon from "../../../Svgs/UploadIcon";
import Button from "../../../../../../../../../../../../Components/Profile/Button/Button";
import { useHttpClient } from '../../../../../../../../../../../../Components/Hooks/http-hook';
import { AuthContext } from '../../../../../../../../../../../../context/auth-context';
import { toast } from 'react-toastify';

const FileUpload = ({asgnId, onClose, setReload}) => {
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const [fileUrl, setFileUrl] = useState('');
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [fileError, setFileError] = useState('');

  const handleFileDrop = (e) => {
    e.preventDefault();
    const droppedFile = e.dataTransfer.files[0];
    if (droppedFile.type !== 'application/zip') {
        setFileError('Invalid Format! Please upload a zip file');
        return;
    }
    setFile(droppedFile);
    setFileName(droppedFile.name);
    setFileUrl(URL.createObjectURL(droppedFile));
    setIsSubmitDisabled(false);
  };

  const handleFileSelect = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    setFileName(selectedFile.name);
    setFileUrl(URL.createObjectURL(selectedFile));
    setIsSubmitDisabled(false);
  };

  const handleSubmit = async () => {    
    const formData = new FormData();
    formData.append('file', file);
    try {
        const responseData = await sendRequest(
            `${process.env.REACT_APP_BACKEND_URL}/assignments/submit/${asgnId}`,
            'POST',
            formData,
            {
                "Refresh-Token": auth.refresh_token,
                Authorization: auth.token,
            }
        );
        if (responseData) {
            toast.success('Assignment uploaded successfully');
            setFile(null);
            setFileName('');
            setFileUrl('');
            setIsSubmitDisabled(true);
            setReload(true);
            onClose();
        }
    } catch (err) {
        console.log(err);
    }};

  return (
    <div className='sk-st-fl-upload-container'>
        <div className='sk-st-fl-upload-title'>Upload</div>
        <div
            className="sk-st-fl-drop-area"
            onDrop={handleFileDrop}
            onDragOver={(e) => e.preventDefault()}
            >
            <UploadIcon />
            <p className='sk-st-fl-upload-text'>
                Drag & drop files or <label htmlFor="fileInput">Browse</label>
            </p>
            <input
                type="file"
                id="fileInput"
                multiple={false}
                accept=".zip"
                onChange={handleFileSelect}
                style={{ display: 'none' }}
            />
            {fileError && <p className='sk-st-fl-upload-error'>
                {fileError}
            </p>}
            <p className='sk-st-fl-upload-format-info'>
                Supported Format: Zip file
            </p>
            <p className='sk-st-fl-upload-instructions'>
                Create a zip folder of all your files and upload
            </p>
            {fileName && (
            <div>
                <p className='sk-st-fl-uploaded-file'>Uploaded file: {fileName}</p>
                <a href={fileUrl} download={fileName} className='sk-st-fl-download-link'>
                    Download
                </a>
            </div>
        )}
        </div>
        
        {isLoading ? (
            <Button
                text="Uploading..."
                disabled={true}
                width='70%'
            />
        ) : (
            <Button
                text="Submit"
                onClick={handleSubmit}
                disabled={isSubmitDisabled}
                width='70%'
            />
        )}
    </div>
  );
};

export default FileUpload;
