import React, { useEffect, useState } from "react";
import PopUpModal from "./PopUpModal";
import ScanResult from "../PA_Components/ScanResult";

const ScanResume = ({
  setIsScanBtnClicked,
  isLoading,
  setIsLoading,
  keywordsData,
}) => {
  const [close, setClose] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const timer = setTimeout(() => {
      setIsLoading(false);
      setModalOpen(true);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  const handleClose = () => {
    setClose(true);
    setIsScanBtnClicked(false);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "rgba(0, 0, 0, 0.6)",
        }}
      >
        {isLoading && (
          <img
            src={require("../PA_Components/scanDoc.gif")}
            alt="Loading"
            style={{
              width: "300px",
              height: "300px",
              display: isLoading ? "block" : "none",
            }}
          />
        )}
      </div>
      {!isLoading && (
        <PopUpModal
          title="Report"
          onClose={handleClose}
          show={!close}
          children={<ScanResult keywordsData={keywordsData} />}
        />
      )}
    </div>
  );
};

export default ScanResume;
