import React from "react";
import "./Leads.css";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router";

const Leads = () => {
  const history = useNavigate();
  return (
    <div>
      <ToastContainer closeButton={false} />
      <div className="admin-skillbox-header">
        <div className="admin-skillbox-header-title">
          Leads
        </div>
      </div>
    </div>
  );
};

export default Leads;
