import React, { useState } from "react";
import ActionVerbsSelect from "../components/ActionVerbsSelect";

const ActionVerbsContent = () => {
  const categories = [
    {
      id: "accomplishments",
      name: "Accomplishments",
    },
    {
      id: "achievements",
      name: "Achievements",
    },
    {
      id: "achiever",
      name: "Achiever",
    },
    {
      id: "administrative",
      name: "Administrative",
    },
    {
      id: "analyticalthinking",
      name: "Analytical Thinking",
    },
    {
      id: "communication",
      name: "Communication",
    },
  ];

  const actionVerbs = {
    Accomplishments: [
      "Achieved",
      "Amplified",
      "Attained",
      "Capitalised",
      "Chaired",
      "Consolidated",
      "Deciphered",
      "Decreased",
      "Discerned",
      "Drove",
      "Enacted",
      "Endeavoured",
    ],
    Achievements: [
      "Achieved",
      "Amplified",
      "Attained",
      "Capitalised",
      "Chaired",
      "Consolidated",
      "Deciphered",
      "Decreased",
      "Discerned",
      "Drove",
      "Enacted",
      "Endeavoured",
    ],
  };

  const [selectedCategory, setSelectedCategory] = useState([]);

  return (
    <div>
      <div>Home</div>
      <div className="section-title">Action Verbs Assistance</div>
      <div className="section-content">
        <div className="content-title">Select a category</div>
        <div className="content-title-desc">
          Select a category to generate action words to add in your resume.
        </div>

        {/* New Dropdown section container */}
        <div className="dropdown-sections-container-action">
          {/* Select Category */}
          <div className="select-category">
            <div className="content-title">Select Category</div>
            <div className="content-title-desc">
              <ActionVerbsSelect
                options={categories}
                onSelect={(option) => setSelectedCategory(option)}
              />
            </div>
          </div>

          {/* Action Verbs */}
          <div className="action-verbs-data">
            <div className="content-title">Action Verbs</div>
            <div className="content-title-desc">
              <div className="action-verbs-display-container">
                {/* {console.log(selectedCategory)} */}
                {selectedCategory.length > 0 ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      flexWrap: "wrap",
                      maxWidth: "90%",
                      gap: "10px",
                    }}
                  >
                    {selectedCategory}
                  </div>
                ) : (
                  <div>
                    Select one or more categories to show the action verbs
                  </div>
                )}
                {/* Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActionVerbsContent;
