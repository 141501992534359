import React, { useContext, useState } from "react";
import LocalLibraryIcon from "@material-ui/icons/LocalLibrary";
import ComputerIcon from "@material-ui/icons/Computer";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import PersonIcon from "@material-ui/icons/Person";
import SurgeLogo from "../../assets/logo4.png";
import "./Navbar.css";
import SideDrawer from "../SideDrawer";
import Backdrop from "../BackDrop";
import { Link } from "react-router-dom";
import BookDemo from "../BookDemo";
import BookingSlot from "../BookingSlot";
import MessageModel from "../MessageModel";
import Button from "../Input/Button";
import { AuthContext } from "../../context/auth-context";
import { AdminContext } from "../../context/admin-context";

function Navbar() {
  const auth = useContext(AuthContext);
  const admin = useContext(AdminContext);
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const openDrawer = () => {
    setDrawerIsOpen(true);
  };
  const closeDrawer = () => {
    setDrawerIsOpen(false);
  };

  const [liveDemo, setLiveDemo] = useState({
    isView: false,
    isSlotBooking: false,
    isResponse: false,
    name: "",
    email: "",
    mobile: "",
    experience: "",
  });

  const toggleResponseMessage = () => {
    setLiveDemo({
      ...liveDemo,
      isView: false,
      isResponse: !liveDemo.isResponse,
    });
  };

  const toggleLiveDemo = () => {
    setLiveDemo({
      ...liveDemo,
      isView: !liveDemo.isView,
    });
  };

  const toggleMessage = () => {
    setLiveDemo({
      ...liveDemo,
      isView: false,
      isSlotBooking: !liveDemo.isSlotBooking,
    });
  };

  const toggleCancelHandler = () => {
    setLiveDemo({
      ...liveDemo,
      isSlotBooking: !liveDemo.isSlotBooking,
    });
  };

  return (
    <div>
      {drawerIsOpen && <Backdrop onClick={closeDrawer} />}
      {liveDemo.isView && (
        <BookDemo
          toggleMessage={toggleMessage}
          toggleLiveDemo={toggleLiveDemo}
          toggleResponseMessage={toggleResponseMessage}
          liveDemo={liveDemo}
        />
      )}
      {liveDemo.isSlotBooking && (
        <BookingSlot toggleCancelHandler={toggleCancelHandler} />
      )}
      {liveDemo.isResponse && (
        <MessageModel toggleResponseMessage={toggleResponseMessage} />
      )}

      <SideDrawer show={drawerIsOpen} onClick={closeDrawer}>
        <nav>
          <div className="sidedrawer-nav">
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <div className="side-drawer-div">
              <Link className="side-drawer-p" to="/mycourses">
                <p>My Courses</p>
              </Link>
            </div>
            <div className="side-drawer-div">
              <Link className="side-drawer-p" to="/aptitude">
                <p>Aptitude</p>
              </Link>
            </div>
            <div className="side-drawer-div">
              <Link
                onClick={() => auth.logout()}
                className="side-drawer-p"
                to="/"
              >
                <p>Log out</p>
              </Link>
            </div>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
          </div>
        </nav>
      </SideDrawer>

      <div className="navheader">
        {!auth.isLoggedIn && !admin.isLoggedIn && (
          <div className="img-navheader1">
            <a href="/">
              <img src={SurgeLogo} alt="Surge Logo"></img>
            </a>
          </div>
        )}

        {auth.isLoggedIn && (
          <div className="img-navheader">
            <a href="/skillbox">
              <img src={SurgeLogo} alt="Surge Logo"></img>
            </a>
          </div>
        )}

        {admin.isLoggedIn && (
          <div className="img-navheader3">
            <a href="/home">
              <img src={SurgeLogo} alt="Surge Logo"></img>
            </a>
          </div>
        )}

        {auth.isLoggedIn && (
          <button onClick={openDrawer} className="navicon-div-span">
            <span></span>
            <span></span>
            <span></span>
          </button>
        )}

        {admin.isLoggedIn && (
          <button onClick={openDrawer} className="navicon-div-span">
            <span></span>
            <span></span>
            <span></span>
          </button>
        )}

        <nav>
          {auth.isLoggedIn && auth.role != "moderator" && (
            <div className="navheader__icons">
              {/* <Link to="/coding-platform" className="navheader__icon"> */}
              {/* <Link to="/wip" className="navheader__icon"> */}
              {/* <ComputerIcon style={{ color: "#dc2f02" }} />
                <p>Coding Exam</p> */}
              <Link to="/assessment" className="navheader__icon">
                <AssignmentIndIcon style={{ color: "#dc2f02" }} />
                <p>Assessment</p>
              </Link>
              {/* <Link to="/aptitude-platform" className="navheader__icon"> */}
              {/* <Link to="/wip" className="navheader__icon"> */}
              <Link to="/practice" className="navheader__icon">
                <LocalLibraryIcon style={{ color: "#dc2f02" }} />
                <p>Practice</p>
              </Link>
              <div className="dropdown">
                <Link className="navheader__icon">
                  <PersonIcon style={{ color: "#dc2f02" }} />
                  <p>Profile</p>
                </Link>
                <div className="dropdown-content">
                  <Link to="/skillbox">My Courses</Link>
                  <Link to="/profile">My Profile</Link>
                  <Link to="/profile-analyzer">Profile Analyzer</Link>
                  {/* <Link to="/profile">My Profile</Link> */}
                  <Link to="/skillbox-v2">My Skillbox</Link>
                  <Link to="/attendance">My Attendance</Link>
                  <Link to="/referral-code">Referral Code</Link>
                  <Link to="/queries">My Doubts</Link>
                  <Link to="/password-reset">Reset Password</Link>
                  <Link to="/" onClick={() => auth.logout()}>
                    Logout
                  </Link>
                </div>
              </div>
            </div>
          )}
          {auth.isLoggedIn && auth.role == "moderator" && (
            <div className="navheader__icons">
              <div className="dropdown">
                <Link
                  style={{ alignItems: "flex-end", marginRight: "1rem" }}
                  className="navheader__icon"
                >
                  <PersonIcon style={{ color: "#dc2f02" }} />
                  <p>Profile</p>
                </Link>
                <div className="dropdown-content">
                  <Link to="/password-reset">Reset Password</Link>
                  <Link to="/" onClick={() => auth.logout()}>
                    Logout
                  </Link>
                </div>
              </div>
            </div>
          )}

          {admin.isLoggedIn && (
            <div className="navheader__icons_admin">
              <div className="dropdown">
                <Link className="navheader__icon">
                  <PersonIcon style={{ color: "#dc2f02" }} />
                  <p>Profile</p>
                </Link>
                <div className="dropdown-content">
                  <Link to="/admin">Skillbox Admin</Link>
                  <Link to="/admin/home">Dashboard</Link>
                  <Link to="/home">My Courses</Link>
                  {/* <Link to="/admin/referral">Referrals</Link> */}
                  <Link to="/" onClick={() => admin.logout()}>
                    Logout
                  </Link>
                </div>
              </div>
            </div>
          )}
        </nav>
      </div>
    </div>
  );
}

export default Navbar;
