import React, { useState, useEffect } from "react";
import "./AssessmentsDash.css";
import CodingAssessment from "./AssessmentType/CodingAssessment";
import AptitudeAssessment from "./AssessmentType/AptitudeAssessment";
import CompanyAssessment from "./AssessmentType/CompanyAssessment";

const AssessmentsDash = ({
  setSelectedTab,
  setSelectedOption,
  selectedInterviewType,
}) => {
  const [activeTab, setActiveTab] = useState(selectedInterviewType.type_slug);

  useEffect(() => {
    setActiveTab(selectedInterviewType.type_slug);
  }, [selectedInterviewType]);

  const goToAllInterview = () => {
    setSelectedTab("interviewHome");
  };

  const goToHome = () => {
    setSelectedOption({
      value: "skillbox",
      label: "Skillbox",
    });
  };

  console.log(selectedInterviewType);

  const renderTabContent = () => {
    switch (activeTab) {
      case "coding":
        return <CodingAssessment />;
      case "aptitude":
        return <AptitudeAssessment />;
      case "company":
        return <CompanyAssessment />;
      default:
        return null;
    }
  };

  return (
    <div className="personal-interview-page">
      <div className="personal-interview-page-nav-menu">
        <div
          onClick={goToHome}
          className="personal-interview-page-nav-menu-home"
        >
          Home
        </div>
        &nbsp; &gt; &nbsp;
        <div
          onClick={goToAllInterview}
          className="interview-feedback-page-nav-menu-interview-preperation"
        >
          Assessment
        </div>
      </div>
      <div className="personal-interview-container">
        <div className="personal-interview-header">
          <div className="personal-interview-header-title">
            {selectedInterviewType && selectedInterviewType.title}
          </div>
        </div>
      </div>
      {renderTabContent()}
    </div>
  );
};

export default AssessmentsDash;
