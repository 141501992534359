import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Cell,
} from "recharts";

const dummydata = [
  { name: "Red", value: 70 },
  { name: "Blue", value: 80 },
];

// generate random colors
const COLORS = ["#004C73", "#CD7B00", "#FA1B00", "#1F7A08", "#004C73"];

const BAR_WIDTH = 70;

const CustomBarChart = ({
  width = 310,
  height = 200,
  data = dummydata,
  barWidth = BAR_WIDTH,
  dataKey = "value",
  nameKey = "name",
  colors = COLORS,
}) => (
  // no label on x axis
  <BarChart width={width} height={height} data={data}>
    <CartesianGrid strokeDasharray="3 3" />
    <XAxis dataKey={nameKey} tickMargin={1} tickFormatter={(value) => value} tick={{ fontSize: 10 }} />
    <YAxis domain={[0, 90]} tickCount={10} />
    <Tooltip />
    <Bar dataKey={dataKey} barSize={barWidth}>
      {data.map((entry, index) => (
        <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
      ))}
    </Bar>
  </BarChart>
);

export default CustomBarChart;
