import React from "react";
import "./ProgramCard.css";

const ProgramCard = ({ program, handleClick }) => {
    return (
        <div className="st-skillbox-program-card" key={program.course_id}>
            <div className="st-skillbox-program-card__title">
                {program.course_name}
            </div>
            {/* <div className="st-skillbox-program-card__tag">
                <Badge
                    {...{[program.tag]: true }}
                />
            </div> */}
            <div className="st-skillbox-program-card_smp-text">
                <span>{program.section_count}{" "}</span>Pathways
            </div>
            <button className="st-skillbox-program-card__button" onClick={() => handleClick(program.course_id, program.course_name)}>
                View Course
            </button>
        </div>
    );
}

export default ProgramCard;