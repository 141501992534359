import React from "react";

const FontSizeSvg = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.2475 15H4.75562L2.75562 20H0.601562L7.00156 4H9.00156L15.4016 20H13.2475L11.2475 15ZM10.4475 13L8.00156 6.88516L5.55562 13H10.4475ZM21.0016 12.5351V12H23.0016V20H21.0016V19.4649C20.4133 19.8052 19.7302 20 19.0016 20C16.7925 20 15.0016 18.2091 15.0016 16C15.0016 13.7909 16.7925 12 19.0016 12C19.7302 12 20.4133 12.1948 21.0016 12.5351ZM19.0016 18C20.1062 18 21.0016 17.1046 21.0016 16C21.0016 14.8954 20.1062 14 19.0016 14C17.897 14 17.0016 14.8954 17.0016 16C17.0016 17.1046 17.897 18 19.0016 18Z" fill="#444444"/>
</svg>
    );
}

export default FontSizeSvg;