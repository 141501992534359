import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "./ClassPage.css";
import { classSideBarData } from "./Data/SidebarData";
import Sidebar from "../../../../../../../Components/Profile/Sidebar/Sidebar";
import ProfileDropdown from "../../../../../../../Components/Profile/ProfileDropdown/ProfileDropdown";
import { ProfileDropdownValues } from "../../../../../StudentProfile/Data/ProfileDropdownValues";
import { AuthContext } from "../../../../../../../context/auth-context";
import { useHttpClient } from "../../../../../../../Components/Hooks/http-hook";
import swal from "sweetalert2";

const ClassPage = ({ isNoHeaderFooter, setIsNoHeaderFooter }) => {
  const auth = useContext(AuthContext);
  const { setUserProfilePic, setUserName } = useContext(AuthContext);
  const { sendRequest } = useHttpClient();
  const [SidebarComponent, setSidebarComponent] = useState(
    classSideBarData[0]
  );
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState({
    value: "skillbox",
    label: "Skillbox",
  });
  const [selectedSidebarIndex, setSelectedSidebarIndex] = useState(0);
  const { pid, cid, ct, mid } = useParams();
  const navigate = useNavigate();

  const history = useNavigate();

  const renderSidebar = (data) => {
    setSidebarComponent(data);
  };

  const isValidUrl = (string) => {
    try {
      new URL(string);
    } catch (_) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/profile/user`,
          "GET",
          null,
          {
            "Content-Type": "application/json",
            "Refresh-Token": auth.refresh_token,
            Authorization: auth.token,
          }
        );
        if (responseData.data.name) {
          setUserName(responseData.data.name);
        }
        if (responseData.data.profile_pic_url) {
          if (isValidUrl(responseData.data.profile_pic_url)) {
            setUserProfilePic(responseData.data.profile_pic_url);
          } else {
            setUserProfilePic(
              `${process.env.REACT_APP_BACKEND_URL}/${responseData.data.profile_pic_url}`
            );
          }
        }
      } catch (err) {
        console.log(err);
      }
    };
    fetchProfile();
  }, []);

  useEffect(() => {
    if (selectedOption.value === "resume") {
      history("/resume");
    } else if (selectedOption.value === "profile") {
      history("/profile");
    } else if (selectedOption.value === "referralCode") {
      history("/referral-code");
    } else if (selectedOption.value === "logout") {
      swal
        .fire({
          title: "Logout",
          text: "Are you sure you want to logout?",
          showCancelButton: true,
          confirmButtonText: "Logout",
          confirmButtonColor: "#ff0000",
          cancelButtonText: "Cancel",
          cancelButtonColor: "#2874f0",
          allowOutsideClick: false,
        })
        .then((result) => {
          if (result.isConfirmed) {
            auth.logout();
            setIsNoHeaderFooter(false);
          } else {
            setSelectedOption({
              value: "skillbox",
              label: "Skillbox",
            });
          }
        });
    } else if (selectedOption.value === "resetPassword") {
      setIsNoHeaderFooter(false);
      history("/password-reset");
    } else {
      renderSidebar(classSideBarData[0]);
    }

    setSelectedSidebarIndex(0);
  }, [selectedOption]);

  useEffect(() => {
    setIsNoHeaderFooter(true);
  }, []);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        isDropdownOpen &&
        !event.target.closest(".profile-content-header-dropdown")
      ) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [isDropdownOpen, setIsDropdownOpen]);

  useEffect(() => {
    renderSidebar(classSideBarData[0]);
  }, []);

  const handleBackToModules = () => {
    navigate(`/skillbox-v2/${cid}/${ct}/pathway/${pid}/module-${mid}`);
  };

  return (
    <>
      <div className="profile">
        <Sidebar
          SidebarData={classSideBarData}
          renderSidebar={renderSidebar}
          setIsNoHeaderFooter={setIsNoHeaderFooter}
          selectedSidebarIndex={selectedSidebarIndex}
          setSelectedSidebarIndex={setSelectedSidebarIndex}
        />

        <div className="profile-content">
          <div className="profile-content-header">
            <div className="profile-content-header-dropdown">
              <ProfileDropdown
                ProfileDropdownValues={ProfileDropdownValues}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                isOpen={isDropdownOpen}
                setIsOpen={setIsDropdownOpen}
              />
            </div>
          </div>
          <div className="profile-content-body">
            <SidebarComponent.component setSelectedOption={setSelectedOption} handleBackToModules={handleBackToModules} />
          </div>
        </div>
      </div>
    </>
  );
};

export default ClassPage;
