import React from "react";
import LectureRecordings from "../Layout/LectureRecordings/LectureRecordings";
import StudyMaterial from "../Layout/StudyMaterial/StudyMaterial";

export const classSideBarData = [
  {
    title: "Lecture Recordings",
    icon: (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.875 4.125C3.97989 4.125 3.12145 4.48058 2.48851 5.11351C1.85558 5.74645 1.5 6.60489 1.5 7.5V16.5C1.5 17.3951 1.85558 18.2535 2.48851 18.8865C3.12145 19.5194 3.97989 19.875 4.875 19.875H13.875C14.7701 19.875 15.6285 19.5194 16.2615 18.8865C16.8944 18.2535 17.25 17.3951 17.25 16.5V15.375L20.4 17.7375C21.2648 18.3862 22.5 17.769 22.5 16.6875V7.3125C22.5 6.231 21.2648 5.61375 20.4 6.2625L17.25 8.625V7.5C17.25 6.60489 16.8944 5.74645 16.2615 5.11351C15.6285 4.48058 14.7701 4.125 13.875 4.125H4.875ZM17.25 10.5L21 7.6875V16.3125L17.25 13.5V10.5ZM15.75 7.5V16.5C15.75 16.9973 15.5525 17.4742 15.2008 17.8258C14.8492 18.1775 14.3723 18.375 13.875 18.375H4.875C4.37772 18.375 3.90081 18.1775 3.54917 17.8258C3.19754 17.4742 3 16.9973 3 16.5V7.5C3 7.00272 3.19754 6.52581 3.54917 6.17417C3.90081 5.82254 4.37772 5.625 4.875 5.625H13.875C14.3723 5.625 14.8492 5.82254 15.2008 6.17417C15.5525 6.52581 15.75 7.00272 15.75 7.5Z" fill="#004C73" />
      </svg>
    ),
    component: LectureRecordings
  },
  {
    title: "Study Material",
    icon: (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6 6C6 5.73478 6.10536 5.48043 6.29289 5.29289C6.48043 5.10536 6.73478 5 7 5H17C17.2652 5 17.5196 5.10536 17.7071 5.29289C17.8946 5.48043 18 5.73478 18 6C18 6.26522 17.8946 6.51957 17.7071 6.70711C17.5196 6.89464 17.2652 7 17 7H7C6.73478 7 6.48043 6.89464 6.29289 6.70711C6.10536 6.51957 6 6.26522 6 6ZM6 10C6 9.73478 6.10536 9.48043 6.29289 9.29289C6.48043 9.10536 6.73478 9 7 9H17C17.2652 9 17.5196 9.10536 17.7071 9.29289C17.8946 9.48043 18 9.73478 18 10C18 10.2652 17.8946 10.5196 17.7071 10.7071C17.5196 10.8946 17.2652 11 17 11H7C6.73478 11 6.48043 10.8946 6.29289 10.7071C6.10536 10.5196 6 10.2652 6 10ZM7 13C6.73478 13 6.48043 13.1054 6.29289 13.2929C6.10536 13.4804 6 13.7348 6 14C6 14.2652 6.10536 14.5196 6.29289 14.7071C6.48043 14.8946 6.73478 15 7 15H17C17.2652 15 17.5196 14.8946 17.7071 14.7071C17.8946 14.5196 18 14.2652 18 14C18 13.7348 17.8946 13.4804 17.7071 13.2929C17.5196 13.1054 17.2652 13 17 13H7ZM6 18C6 17.7348 6.10536 17.4804 6.29289 17.2929C6.48043 17.1054 6.73478 17 7 17H11C11.2652 17 11.5196 17.1054 11.7071 17.2929C11.8946 17.4804 12 17.7348 12 18C12 18.2652 11.8946 18.5196 11.7071 18.7071C11.5196 18.8946 11.2652 19 11 19H7C6.73478 19 6.48043 18.8946 6.29289 18.7071C6.10536 18.5196 6 18.2652 6 18Z" fill="#434343" />
        <path fillRule="evenodd" clipRule="evenodd" d="M2 4C2 3.20435 2.31607 2.44129 2.87868 1.87868C3.44129 1.31607 4.20435 1 5 1H19C19.7956 1 20.5587 1.31607 21.1213 1.87868C21.6839 2.44129 22 3.20435 22 4V20C22 20.7956 21.6839 21.5587 21.1213 22.1213C20.5587 22.6839 19.7956 23 19 23H5C4.20435 23 3.44129 22.6839 2.87868 22.1213C2.31607 21.5587 2 20.7956 2 20V4ZM5 3H19C19.2652 3 19.5196 3.10536 19.7071 3.29289C19.8946 3.48043 20 3.73478 20 4V20C20 20.2652 19.8946 20.5196 19.7071 20.7071C19.5196 20.8946 19.2652 21 19 21H5C4.73478 21 4.48043 20.8946 4.29289 20.7071C4.10536 20.5196 4 20.2652 4 20V4C4 3.73478 4.10536 3.48043 4.29289 3.29289C4.48043 3.10536 4.73478 3 5 3Z" fill="#434343" />
      </svg>
    ),
    component: StudyMaterial
  }
];
