import React from "react";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import "./Popup.css";
import { MdDoubleArrow } from "react-icons/md";

const PopUp = ({ children, title, onClose, show = false}) => (
  <Popup
    position="right center"
    closeOnDocumentClick
    open={show}
    onClose={onClose}
  >
    <>
      <div className="custom_course_popup-header">
        {title && (
          <div className="custom-course-popup-title">
            <MdDoubleArrow className="custom-course-popup-title-icon" />
            <h3>{title}</h3>
          </div>
        )}
        <div className="custom_course_popup-close-button" onClick={onClose}>
          X
        </div>
      </div>
      {title && <hr />}
    </>
    <div className="custom_course_popup-content">{children}</div>
  </Popup>
);

export default PopUp;
