import React, { useContext, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import websiteMaintenance from "../../../../../../../../../assets/website-maintenance.svg";
import "./Attendance.css";
import { useHttpClient } from '../../../../../../../../../Components/Hooks/http-hook';
import { AuthContext } from '../../../../../../../../../context/auth-context';
import Button from '../../../../../../../../../Components/Profile/Button/Button';

const Attendance = () => {

  const navigate = useNavigate();
  const { cid, ct } = useParams();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);

  const goToHome = () => {
    navigate("/skillbox-v2");
  };

  return (
    <>
      <div className="stu-skillbox-nav-header">
        <span onClick={goToHome}>Home</span>
      </div>
      {isLoading && <div>Loading...</div>}
      {error && <div>{error}</div>}
      <div className="stu-ts-skillbox-header-atn-pg">
        <div className="stu-ts-skillbox-header-title">
          Attendance - {ct}
        </div>
      </div>
      
      <div className="under-development-page">
        <div className="under-development-container">
          <div className="construction-svg-container">
            <img
              src={websiteMaintenance}
              alt="website-maintenance"
            />
          </div>
          <p className="under-development-text">
            This feature is under development. Please check back after a while. We
            are working on lots of updates right now.
          </p>
          <button
            className="previous-version-button"
            onClick={() => (window.location.href = "/")}
          >
            Go to Previous Version
          </button>
        </div>
      </div>
    </>
  );
};

export default Attendance;
