import React, {useContext, useRef} from 'react'
import { useState } from 'react'
import { Scrollbars } from "react-custom-scrollbars-2";
import { Editor, EditorState } from 'draft-js';
import FileBase64 from 'react-file-base64';
import Viewer from 'react-viewer';
import { event } from 'jquery';

import './AssignmentPop.css'
import { useHttpClient } from '../../Components/Hooks/http-hook';
import { AdminContext } from '../../context/admin-context';
import ErrorModal from "../../Components/Input/ErrorModel";
import LoadingSpinner from "../../Components/Input/LoadingSpinner";

const AssignmentPop = (props) => {

    // const history = useNavigate();
    const [isPop, setIsPop] = useState(true)
    const popUpCloseHandler = () => {
      setIsPop(false)
    //   history(`/`)
    };

    const [showEditor, setShowEditor] = useState(true)
    const [editorText, setEditorText] = useState("")
    const [addPdf, setAddPdf] = useState(false)
    const [fileDetails, setFileDetails] = useState()
    const [addSkills, setAddSkills] = useState(false)
    const [skillItem, setSkillItem] = useState("")
    const [skills, setSkills] = useState([])
    const [deadline, setDeadline] = useState(false)
    const [deadlineValue, setDeadlineValue] = useState()
    const [uploadBlobDetails, setUploadBlobDetails] = useState({})

    const skillRef = useRef()
    const addTextHandler = () => {
        setShowEditor(true)
        setAddPdf(false)
        setAddSkills(false)
        setDeadline(false)
    }

    const handleSave = (event) => {
        event.preventDefault()
        // console.log(editorText)
    }

    const addPDFHandler = () => {
        setShowEditor(false)
        setAddPdf(true)
        setAddSkills(false)
        setDeadline(false)
    }

    const addSkillsHandler = () => {
        setShowEditor(false)
        setAddPdf(false)
        setAddSkills(true)
        setDeadline(false)
    }

    const addDeadlineHandler = () => {
        setShowEditor(false)
        setAddPdf(false)
        setAddSkills(false)
        setDeadline(true)
    }

    function handleInputChange (event) {
        console.log(event.target.value)
        setSkillItem(event.target.value)
    }

    const handleSKillAddButton = (event) => {
        event.preventDefault();
        if (skillItem.trim() === "") {
            return;
          }
      
          const newItem = skillItem.trim()
      
          setSkills([...skills, newItem]);
          console.log(skills)
          setSkillItem("");
    }

    const handlePDFUploadOld = () => {
        // setFileDetails({"name": name, "base64":base64})
        // Cut the prefix `data:application/pdf;base64` from the raw base 64
        console.log(fileDetails, 'base64')
        const base64WithoutPrefix = fileDetails.base64.substr('data:application/pdf;base64,'.length);
    
        const bytes = atob(base64WithoutPrefix);
        let length = bytes.length;
        let out = new Uint8Array(length);
    
        while (length--) {
            out[length] = bytes.charCodeAt(length);
        }
    
        let blob = new Blob([out], { type: 'application/pdf' });
        // const blob = base64toBlob(fileDetails.base64)
        console.log(blob, 'blob')
        const url = URL.createObjectURL(blob)
        // console.log(url.toString(), 'url')
        var bloburl = { "url": url }
        var curUploadDetails = uploadBlobDetails
        uploadBlobDetails['pdf1'] = bloburl
        setUploadBlobDetails({
            ...uploadBlobDetails
        })
        console.log(uploadBlobDetails, "uploadBlobDetails")
    }

    const handleFIleUpload = (file_details) => {
        console.log(file_details.length)
        console.log(file_details)
        setFileDetails(file_details)
    }

    const [editorState, setEditorState] = useState(
        () => EditorState.createEmpty(),
    );
    
    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const admin = useContext(AdminContext);

    const handleCreateAssignment = async(event) => {
        event.preventDefault();

        try {
          const responseData = await sendRequest(
            `${process.env.REACT_APP_BACKEND_URL}/s3/assignment`,
            "POST",
            JSON.stringify({
              title: editorText,
              file_details: fileDetails,
              program_id: `${props.programDetails}`,
              skills: {'skills': skills},
              deadline: deadlineValue ? deadlineValue : 5
            }),
            {
              "Content-Type": "application/json",
              "Authorization": admin.token,
              "Refresh-Token": admin.refresh_token,
            }
          );
    
          if (responseData.ok == true) {
                alert(responseData.msg)
            } else {
                alert(responseData.msg)
            }
        } 
        catch (err) {console.log(err)}
    
    }

    return (
        <React.Fragment>
          <ErrorModal error={error} onClear={clearError} />
          {isLoading && <LoadingSpinner asOverlay />}
          {
            !isLoading && (
                <div className='popup-box'>
                {isPop &&        
                    <div className='box'>
                        <p style={{fontWeight:"bold", fontSize:"20px", textAlign:"center"}}>Create Assignment</p>
                        <span className="close-icon" onClick={props.handleClose}>x</span>
                        <div className='add-heading-assign'>
                        <Scrollbars
                            autoHide
                            autoHideTimeout={2000}
                            autoHideDuration={310}
                            autoHeightMin={300}
                            autoHeightMax={800}
                            thumbMinSize={30}
                            universal={true}
                            style={{
                            width: `100%`,
                            height: "25rem",
                            background: "white",
                            borderRadius: "6px",
                            }}
                        >
                            {<button onClick={() => addTextHandler()}>Title</button>}                    
                            {<button onClick={() => addPDFHandler()}>Upload Files</button>}
                            {<button onClick={() => addSkillsHandler()}>Add Skills</button>}
                            {<button onClick={() => addDeadlineHandler()}>Deadline</button>}
                        <div>
                            {showEditor && 
                                <form style={{"width": "70%", "marginLeft":"10%", "marginRight":"10%"}}>
                                    <label>Title
                                        <input
                                            style={{"width": "75%"}}
                                            type="text"
                                            value={editorText}
                                            onChange={(e) => setEditorText(e.target.value)}
                                        />                                
                                    </label>
                                </form>
                            }
                            {addPdf && 
                                <div>
                                    <FileBase64
                                        type="file" 
                                        multiple={true} 
                                        onDone={
                                            (files) => {
                                                const fileDetails = files.map(file => ({ "name": file.name, "base64": file.base64 }));
                                                setFileDetails(fileDetails);
                                            }
                                        } 
                                        onChange={
                                            (e) => {
                                                const files = e.target.files;
                                                const fileDetails = [];
                                                for (let i = 0; i < files.length; i++) {
                                                    const file = files[i];
                                                    const reader = new FileReader();
                                                    reader.onload = (event) => {
                                                        fileDetails.push({ "name": file.name, "base64": event.target.result });
                                                        if (fileDetails.length === files.length) {
                                                            // all files have been processed
                                                            setFileDetails(fileDetails);
                                                        }
                                                    };
                                                    reader.readAsDataURL(file);
                                                }
                                            }
                                        }
                                    >                     
                                    </FileBase64>
                                    {/* <button onClick={()=>handleFIleUpload(fileDetails)}>Save Files</button> */}
                                </div>
                            }
                            {addSkills &&
                                <form style={{"width": "70%", "marginLeft":"10%", "marginRight":"10%"}}>
                                    <input style={{"width": "75%"}} type="text" value={skillItem} onChange={handleInputChange}/>
                                    <button onClick={(event) => handleSKillAddButton(event)}>Add</button>
                                </form>
                            }
                            {deadline && 
                                <form style={{"width": "70%", "marginLeft":"10%", "marginRight":"10%"}}>
                                    <label>Deadline
                                        <input
                                            style={{"width": "75%"}}
                                            type="int"
                                            placeholder='In Days'                                            
                                            onChange={(e) => setDeadlineValue(e.target.value)}
                                        />                                
                                    </label>
                                </form>
                            }
                            {editorText &&
                                <p style={{"marginLeft":"5%"}}><span style={{"fontSize":"20px","fontWeight":"bold"}}>Title is:</span> {editorText}</p>
                            }
                            {fileDetails && <p style={{"marginLeft":"5%", "fontSize":"20px","fontWeight":"bold"}}>You Have Uploaded Following Files</p>}
                            {fileDetails && fileDetails.map((file, i) => {
                                return (
                                    <p style={{"marginLeft":"7.5%"}}>{file.name}</p>
                                )
                            })
                            }
                            {skills && skills.length != 0 &&
                                    <p style={{"marginLeft":"5%"}}><span style={{"fontSize":"20px","fontWeight":"bold"}}>Skills are:</span></p>
                            }
                            {skills && skills.length != 0 &&
                                skills.map((skill, i) => {
                                    return (
                                        <ul style={{"marginLeft":"7.5%"}}>{skill}</ul>
                                    )
                                })
                            }
                            {deadlineValue &&
                                <p style={{"marginLeft":"5%"}}><span style={{"fontSize":"20px","fontWeight":"bold"}}>Deadline is:</span> {deadlineValue} <span style={{"fontSize":"20px","fontWeight":"bold"}}>Days</span></p>
                            }
                            {/* {uploadBlobDetails.pdf1 && uploadBlobDetails.pdf1.url &&
                                <div>
                                    <p>something here {uploadBlobDetails.pdf1.url}</p>
                                    <img alt="" src={uploadBlobDetails.pdf1.url} />
                                    <Viewer fileUrl={uploadBlobDetails.pdf1.url} />
                                </div>
                            } */}
                            <button style={{"marginTop": "2%"}} onClick={handleCreateAssignment}>Create Assignment</button>
                        </div>
                        </Scrollbars>
                        </div>
                    </div>
                }
            </div>        
            )
          }
        </React.Fragment>
  )
}

export default AssignmentPop