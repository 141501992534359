import React from "react";
import { useState, useEffect, useContext } from "react";
import "./Assessment.css";
import AssessmentsDash from "./AssessmentsDash/AssessmentsDash";
import AssessmentFeedback from "./AssessmentFeedback/AssessmentFeedback";
import AssessmentType from "./AssessmentType/AssessmentType";

const Assessment = ({ setSelectedOption }) => {
  const [selectedTab, setSelectedTab] = useState("interviewHome");
  const [selectedInterviewType, setSelectedInterviewType] = useState({
    type_slug: "",
    title: "",
    cover: null,
    image: null,
    keyPoints: [],
  });

  const renderSelectedTab = () => {
    if (selectedTab === "interviewHome") {
      return (
        <AssessmentType
          setSelectedTab={setSelectedTab}
          setSelectedOption={setSelectedOption}
          setSelectedInterviewType={setSelectedInterviewType}
        />
      );
    }
    else if (selectedTab === "personalInterview") {
      return (
        <AssessmentsDash
          setSelectedTab={setSelectedTab}
          setSelectedOption={setSelectedOption}
          selectedInterviewType={selectedInterviewType}
        />
      );
    } else if (selectedTab === "interviewFeedback") {
      return (
        <AssessmentFeedback
          setSelectedTab={setSelectedTab}
          setSelectedOption={setSelectedOption}
        />
      );
    }
  };

  return <div>{renderSelectedTab()}</div>;
};
export default Assessment;
