import React from "react";
import Pathways from "../Layout/Pathways/Pathways";
import Attendance from "../Layout/Attendance/Attendance";

export const SectionSideBarData = [
  {
    title: "Pathways",
    icon: (
        <svg width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 5.50001C12.75 2.53938 15.5827 1.02766 21.75 1.00001C21.8486 0.999633 21.9463 1.01878 22.0375 1.05634C22.1286 1.0939 22.2114 1.14913 22.2812 1.21885C22.3509 1.28856 22.4061 1.37139 22.4437 1.46255C22.4812 1.55372 22.5004 1.65141 22.5 1.75001V15.25C22.5 15.4489 22.421 15.6397 22.2803 15.7803C22.1397 15.921 21.9489 16 21.75 16C15.75 16 13.432 17.2098 12 19M12 5.50001C11.25 2.53938 8.41735 1.02766 2.25001 1.00001C2.15141 0.999633 2.05372 1.01878 1.96255 1.05634C1.87139 1.0939 1.78856 1.14913 1.71885 1.21885C1.64913 1.28856 1.5939 1.37139 1.55634 1.46255C1.51878 1.55372 1.49963 1.65141 1.50001 1.75001V15.1595C1.50001 15.6227 1.78688 16 2.25001 16C8.25001 16 10.5764 17.2188 12 19M12 5.50001V19" stroke="#004C73" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>        
    ),
    component: Pathways
  },
  {
    title: "View Attendance",
    icon: (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M22 21H2V3H4V19H6V10H10V19H12V6H16V19H18V14H22V21Z" fill="#434343"/>
      </svg>
    ),
    component: Attendance
  }
];
