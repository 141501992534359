import React, { useState } from "react";
import "./Input.css";
import Select from "./Select";

const Input = ({
  inpType = "text",
  width = "300px",
  selectWidth = "200px",
  selectInpWidth = "99%",
  inputPlaceholderValue,
  onChangeFunction,
  onChangeSelectFunction,
  isDisabled = false,
  isSelectDisabled = false,
  value,
  selectvalue,
  name,
  label = false,
  isSelect = false,
  selectData = [],
  selectLabel = false,
  isNoInputinSelect = false,
  inperror = false,
  inpMaxLen = 100,
}) => {
  const [charCountErr, setCharCountErr] = useState(false);
  const [errDesc, setErrDesc] = useState("");

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    if (inputValue.length > inpMaxLen) {
      setCharCountErr(true);
      setErrDesc("Character limit exceeded");
    }
    if (inputValue.length <= inpMaxLen) {
      setCharCountErr(false);
      setErrDesc("");
    }
    onChangeFunction(e);
  };

  const handleSelectChange = (selectedOption) => {
    onChangeSelectFunction(selectedOption);
  };


  return (
    <div
      className="profile-input-component-container"
      style={{ width: isSelect ? selectInpWidth : width }}
    >
      {label && <div className="profile-input-field-label">{label}</div>}
      <div className="profile-input-field-container-outer">
        {isSelect && (
          <Select
            width={selectWidth}
            onChange={!isNoInputinSelect ? handleSelectChange : onChangeFunction}
            value={selectvalue}
            selectData={selectData}
            selectLabel={selectLabel}
            isDisabled={isSelectDisabled}
          />
        )}
        {!isNoInputinSelect && (
          <div
            className="profile-input-field-container"
            style={{ width: width }}
          >
            <input
              type={inpType}
              placeholder={inputPlaceholderValue}
              onChange={handleInputChange}
              defaultValue={value}
              name={name}
              className={
                `profile-input-field
                ${inperror
                  ? "profile-input-field-err"
                  : ""}
                ${isDisabled
                 ? "profile-input-component-container-disabled"
                 : ""
                }`
              }
              disabled={isDisabled}
            />
            {charCountErr && (
              <div className="profile-input-err-desc">{errDesc}</div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Input;
