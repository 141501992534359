import React, { useState, useEffect, useContext } from 'react'
import { useNavigate, useParams } from "react-router-dom";

import './MentorHome.css'
import Course1 from "../../assets/Continuing-professional-development-1170x630.jpg";
import { useHttpClient } from '../../Components/Hooks/http-hook';
import { AuthContext } from '../../context/auth-context';

const MentorHome = () => {
    const [courseInfoList, setCourseInfoList] = useState([])
    const auth = useContext(AuthContext);

    const { isLoading, error, sendRequest, clearError } = useHttpClient();    
    const getProgramTitles = async() => {
        try {   
            const responseData = await sendRequest(
                `${process.env.REACT_APP_BACKEND_URL}/db/mentor-course-details/${auth.surge_id}`,
                "get",              
                );
                if (responseData.ok) {
                    return responseData.data
                }
            } catch (err) {
                console.log(err)
            }
        }
        
    useEffect(() => {
        getProgramTitles().then(
            response => setCourseInfoList(response)
            )
        }, [])
    const history = useNavigate();

    const courseHandler = (item, title) => {
        history(`/mentor-home/${item}/${title}`);
    };    
    
  return (
    <div className='mentor-home'>
        <div className="main-heading"><span>Surge Programs</span></div>
        <div className="courses-grid">
            {courseInfoList && courseInfoList.map((p, i) => {
                return (
                    <div className='grid-div' onClick={()=>courseHandler(p.program_id, p.title)}>
                        <div className="grid-card-img">
                            <img src={Course1}></img>
                        </div>
                        <h3>{p.title}</h3>
                        <p>Click to Manage</p>
                    </div>
                );
            })}            
        </div>
    </div>
  )
}

export default MentorHome