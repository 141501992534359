import React, { useEffect, useState } from "react";
import Select from "react-select";
import "./Dropdown.css";

const Dropdown = ({
  title,
  items = [],
  onChange,
  isLoading = false,
  selectedValue,
}) => {
  return (
    <div className="skillbox-admin-custom-dropdown">
      <Select
        className="skillbox-admin-dropdown__list"
        options={items}
        onChange={onChange}
        clearable={false}
        autosize={false}
        searchable={false}
        placeholder={title}
        isLoading={isLoading}
        defaultValue={selectedValue}
        value={selectedValue}
        isDisabled={isLoading}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            borderRadius: "10px",
            color: "#000",
            ".css-1u9des2-indicatorSeparator": {
              display: "none",
            },
          }),
        }}
      />
    </div>
  );
};

export default Dropdown;
