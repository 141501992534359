import React, { useCallback } from 'react';
import Button from "../../../../../../../../../Components/Profile/Button/Button";
import Loading from "../../../../../../../../../Components/Loading";

const Profile = ({
    selectedInterviewType,
    startInterviewHandler,
    isLoading,
    error,
    selectedResume,
    setSelectedResume,
    handleGoToHome,
    handleGoToAllInterview,
    handleGoToProfileBased
}) => {
    const onDrop = useCallback((event) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        if (file && file.type === 'application/pdf') {
            setSelectedResume(file);
        } else {
            alert('Only PDF files are allowed.');
        }
    }, [setSelectedResume]);

    const onFileChange = (event) => {
        const file = event.target.files[0];
        if (file && file.type === 'application/pdf') {
            setSelectedResume(file);
        } else {
            alert('Only PDF files are allowed.');
        }
    };

    const onDragOver = (event) => {
        event.preventDefault();
    };

    return (
        <div className="interview-preperation-personal-interview-page">
            <div className="interview-preperation-personal-interview-page-nav-menu">
                <div
                    onClick={handleGoToHome}
                    className="interview-preperation-personal-interview-page-nav-menu-home"
                >
                    Home
                </div>
                &nbsp; &gt; &nbsp;
                <div
                    onClick={handleGoToAllInterview}
                    className="interview-preperation-personal-interview-page-nav-menu-other"
                >
                    All Interview
                </div>
                &nbsp; &gt; &nbsp;
                <div
                    onClick={() => handleGoToProfileBased(false)}
                    className="interview-preperation-personal-interview-page-nav-menu-other"
                >
                    Profile Based
                </div>
            </div>
            <div className="interview-preperation-personal-interview-container">
                <div className="interview-preperation-personal-interview-header">
                    <div className="interview-preperation-personal-interview-header-title">
                        {selectedInterviewType.title}
                    </div>
                </div>
                <div className="interview-preperation-personal-interview-body">
                    <div className="interview-preperation-personal-interview-body-2">
                        <div className="interview-preperation-personal-interview-body-2-1">
                            <div className="interview-preperation-personal-interview-title">
                                {selectedInterviewType.InterviewStartTitle}
                            </div>
                        </div>

                        {isLoading && (
                            <div className="interview-preperation-personal-interview-body-2-2">
                                <Loading color="#004c73" />
                            </div>
                        )}
                        {error && (
                            <div className="interview-preperation-personal-interview-body-2-2">
                                <div className="interview-preperation-personal-interview-error">
                                    Something went wrong. Please try again later.
                                </div>
                            </div>
                        )}
                        {!isLoading && !error && (
                            <>
                                <div
                                    className="unique-personal-interview-body"
                                    onDrop={onDrop}
                                    onDragOver={onDragOver}
                                >
                                    {selectedResume ? (
                                        <p>{selectedResume.name}</p>
                                    ) : (
                                        <p>Drag and drop a PDF file here, or click to select a PDF file</p>
                                    )}
                                    <input
                                        type="file"
                                        onChange={onFileChange}
                                        style={{ display: 'none' }}
                                        id="fileUpload"
                                        accept="application/pdf"
                                    />
                                    <label htmlFor="fileUpload" className="unique-file-upload-label">
                                        Click to select a PDF file
                                    </label>
                                </div>
                                <div className="interview-preperation-personal-interview-body-2-3">
                                    <Button
                                        text="NEXT"
                                        width="400px"
                                        disabled={!selectedResume}
                                        onClick={() => startInterviewHandler({ resume: selectedResume })}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Profile;
