import React from "react";

import "./ProgressBar.css";

const ProgressBar = ({ progress }) => {
  if (progress < 0 || progress > 100) {
    console.error("Invalid progress value. Must be between 0 and 100.");
    return null;
  }

  return (
    <div className="progress-bar">
      <div
        className="progress-bar-fill"
        style={{ width: `${progress}%` }}
      ></div>
    </div>
  );
};

export default ProgressBar;
