import React, { useState } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import { GrUserSettings, GrDatabase } from "react-icons/gr";

import './MentorCoursePage.css'
import ListAssignments from './ListAssignments'

const MentorCoursePage = () => {
    let { cid, title } = useParams()

    const [instructorSec, setInstructorSec] = useState("Assignments");

    const handleInstructorSection = (item) => {
        setInstructorSec(item);
    };

    return (
    <div className='mentor-course-page'>
      <div className="admin-left-div">
        <div
          onClick={() => handleInstructorSection("Assignments")}
          className="admin-left-sec"
        >
          <GrDatabase style={{ marginRight: "1rem" }} />
          Assignments
        </div>
        <hr />
      </div>
      <hr />
      <div className="admin-right-div">
        {instructorSec == "Assignments" && <ListAssignments/>}
      </div>
    </div>
  )
}

export default MentorCoursePage