import React, { useEffect, useState, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./Auth.css";
import { AdminContext } from "../../context/admin-context";
import Button from "../../Components/Input/Button";
import Input from "../../Components/Input/Input";
import LoadingSpinner from "../../Components/Input/LoadingSpinner";
import {
  VALIDATOR_EMAIL,
  VALIDATOR_MINLENGTH,
  VALIDATOR_REQUIRE,
} from "../../Components/Input/validators";
import useForm from "../../Components/Hooks/formhooks";
import { useHttpClient } from "../../Components/Hooks/http-hook";
import ErrorModal from "../../Components/Input/ErrorModel";

const AdmInsAuth = () => {
  const admin = useContext(AdminContext);
  const history = useNavigate();
  const [isLoginMode, setIsLoginMode] = useState(true);
  const [formState, inputHandler, setFormData] = useForm({
    inputs: {
      email: {
        value: "",
        isValid: false,
      },
      password: {
        value: "",
        isValid: false,
      },
    },
    isValid: false,
  });

  const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

  const myRef = useRef(null);
  const scrollTop = async () => scrollToRef(myRef);

  useEffect(() => {
    scrollTop();
  }, []);

  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const submitHandler = async (event) => {
    event.preventDefault();

    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/auth/login`,
        "POST",
        JSON.stringify({
          email: formState.inputs.email.value,
          password: formState.inputs.password.value,
        }),
        {
          "Content-Type": "application/json",
        }
      );

      if (responseData.data.role == "admin") {
        console.log(responseData)
        admin.login(responseData.data.idToken);
       
        history("/admin/home");
      } else {
        history("/");
      }
    } catch (err) {}
  };

  return (
    <React.Fragment>
      {isLoginMode && (
        <React.Fragment>
          <ErrorModal error={error} onClear={clearError} />
          {isLoading && <LoadingSpinner asOverlay />}
          {!isLoading && (
            <div ref={myRef} className="authentication">
              {/* <hr /> */}
              <form onSubmit={submitHandler}>
                <div className="auth-form">
                  <div className="formnaya"></div>
                  <div className="auth-box">
                    <h2 className="mylogin-title">Admin/Instructor Login</h2>
                    <div className="form-class1">
                      <Input
                        type="email"
                        //label="Email"
                        placeholder="Email"
                        id="email"
                        value={formState.inputs.email.value}
                        isValid={formState.inputs.email.isValid}
                        errorText="Enter a valid Email"
                        validators={[VALIDATOR_EMAIL()]}
                        element="input"
                        onInput={inputHandler}
                      />
                    </div>
                    <div className="form-class1">
                      <Input
                        className="password-input"
                        type="password"
                        placeholder="Password"
                        errorText="Enter a valid password"
                        id="password"
                        validators={[VALIDATOR_MINLENGTH(6)]}
                        element="input"
                        onInput={inputHandler}
                        value={formState.inputs.password.value}
                        isValid={formState.inputs.password.isValid}
                      />
                    </div>

                    <Button type="submit" disabled={!formState.isValid}>
                      Login
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default AdmInsAuth;
