import React , { useState, useEffect, useContext } from "react";
import "./Dashboard.css";
import { useHttpClient } from "../../../../Components/Hooks/http-hook";
import { AuthContext } from "../../../../context/auth-context";
import Loading from "../../../../Components/Loading";
import moment from "moment";
const swal = require("sweetalert2");

const Dashboard = () => {
  const { error, clearError, sendRequest, isLoading } = useHttpClient();
  const auth = useContext(AuthContext);

  const [attemptedTests, setAttemptedTests] = useState([]);

  const totalCount = {
    testsAttempted: 10,
    dailyStreak: 5,
  };

  const handleDateFormatter = (date) => {
    return moment(date).format("YYYY-MM-DD HH:mm:ss");
  };

  useEffect(() => {
    const fetchAttemptedTests = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/prep/get-all-previously-solved-tests`,
          "GET",
          null,
          {
            "Content-Type": "application/json",
            "Refresh-Token": auth.refresh_token,
            Authorization: auth.token,
          }
        );
        if (responseData.data) {
          setAttemptedTests(responseData.data);
        }
      } catch (err) {
        console.log(err);
      }
        try {
          const responseData = await sendRequest(
            `${process.env.REACT_APP_BACKEND_URL}/api/coding/get-all-coding-exams`,
            "GET",
            null,
            {
              "Content-Type": "application/json",
              "Refresh-Token": auth.refresh_token,
              Authorization: auth.token,
            }
          );
          if (responseData.data) {
            setAttemptedTests(prevTests => [...prevTests, ...responseData.data]);
          }
        } catch (err) {
          console.log(err);
      }
    };
    fetchAttemptedTests();
  }, []);

  const upcomingTests = [
    { title: "TCS NINJA Mock Test", date: "2024-02-10", type: "Aptitude" },
    { title: "Python Basics Mock Test", date: "2024-02-12", type: "Coding" },
  ];

  // const attemptedTests = [
  //   { title: "Java Basics", type: "Coding", score: 80, time: "2024-02-02" },
  //   { title: "TCS NQT", type: "Aptitude", score: 90, time: "2024-02-05" },
  //   { title: "Infosys", type: "Coding", score: 85, time: "2024-02-08" },
  // ];

  const testSuggestions = [
    { title: "Amcat Test", type: "Aptitude", date: "2024-02-10" },
    { title: "Accenture Mock Test", type: "Coding", date: "2024-02-12" },
  ];

  const handleUpgrade = () => {
    swal.fire({
      title: "Upgrade to Premium",
      text: "Premium features include unlimited tests, detailed analysis and much more, it's about to come soon!",
      icon: "info",
    });
  }

  return (
    <div className="assesment-dashboard">
      <div className="assesment-dashboard-total-count-details">
        <div className="assesment-dashboard-progress">
          <p className="assesment-dashboard-tests-attempted">
            {attemptedTests.length} / 140 Tests Attempted
          </p>
          <p className="assesment-dashboard-daily-streak">
            {/* Daily Streak: {totalCount.dailyStreak} Days */}
            Welcome to the Assessment Platform
          </p>
        </div>
        {/* <div className="assesment-dashboard-upgrade">
          <button className="upgrade-button" onClick={handleUpgrade}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-star"
            >
              <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
            </svg>
            Upgrade to Premium
          </button>
        </div> */}
      </div>

      {/* <h2 className="assesment-dashboard-upcoming-tests">
        Upcoming Tests - {upcomingTests.length}{" "}
      </h2>
      <div className="assesment-dashboard-info">
        You have {upcomingTests.length} tests scheduled in the next 7 days
      </div>
      <div className="assesment-dashboard-upcoming-tests-list">
        {upcomingTests.map((test, index) => (
          <div className="assesment-dashboard-test-card" key={index}>
            <div className="assesment-dashboard-test-card-title">
              {test.title}
            </div>
            <p className="assesment-dashboard-test-date">Date: {test.date}</p>
            <p className="assesment-dashboard-test-type">Type: {test.type}</p>
            <button className="assesment-dashboard-attend-test-button" onClick={handleStartTest}>
              Attend Test
            </button>
          </div>
        ))}
      </div> */}

      <h2 className="assesment-dashboard-attempted-tests">
        Your Attempted Tests - {attemptedTests.length}
      </h2>
      <div className="assesment-dashboard-info">
        You are making a good progress. Keep it up!
      </div>
      <table className="assesment-dashboard-attempted-tests-table">
        <thead>
          <tr>
            <th className="assesment-dashboard-test-id">S.No.</th>
            <th className="assesment-dashboard-test-title">Exam Id</th>
            <th className="assesment-dashboard-test-type">Type</th>
            <th className="assesment-dashboard-test-status">Status</th>
            <th className="assesment-dashboard-test-score">
              Score
            </th>
            <th className="assesment-dashboard-test-time">Time</th>
          </tr>
        </thead>
        <tbody>
          {attemptedTests.map((test, index) => (
            <tr key={index}>
              <td className="assesment-dashboard-test-id">{index + 1}</td>
              <td className="assesment-dashboard-test-title">{test.exam_id}</td>
              <td className="assesment-dashboard-test-type">{test.type}</td>
              <td className="assesment-dashboard-test-status">{test.status}</td>
              <td className="assesment-dashboard-test-score">{test.score_gained}/{test.total_score}</td>
              <td className="assesment-dashboard-test-time">{handleDateFormatter(test.exam_date)}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* <h2 className="assesment-dashboard-test-suggestions">Test Suggestions</h2>
      <div className="assesment-dashboard-info">
        Here are few curated tests for you. Schedule them now!
      </div>
      <div className="assesment-dashboard-test-suggestions-list">
        {testSuggestions.map((test, index) => (
          <div className="assesment-dashboard-test-suggestion-card" key={index}>
            <div className="assesment-dashboard-test-suggestion-title">
              {test.title}
            </div>
            <p className="assesment-dashboard-test-suggestion-type">
              Type: {test.type}
            </p>
            <p className="assesment-dashboard-test-suggestion-date">
              Date: {test.date}
            </p>
            <button className="assesment-dashboard-attend-test-button" onClick={handleScheduleTest}>
              Schedule Test
            </button>
          </div>
        ))}
      </div> */}
    </div>
  );
};

export default Dashboard;
