import { createContext } from "react";

const authModel = {
  userId: "",
  userName:"",
  email:"",
  profile:"",
  profile_pic:"",
  mobile: "",
  token: "",
  refresh_token:"",
  role: "",
  program_info: [],
  surge_id:"",
  isLoggedIn: false,
  isInstructor: false,
  login: () => {},
  logout: () => {},
  setUserProfilePic: () => {},
  setUserName: () => {},
};

export const AuthContext = createContext(authModel);
