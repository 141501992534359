import React, { useState, useEffect, useContext } from "react";
import "./CodingTest.css";
import { useParams } from "react-router-dom";
import { useHttpClient } from "../../Components/Hooks/http-hook";
import { AuthContext } from "../../context/auth-context";
import SurgeLogo from "../../assets/logo2.png";
import LoadingSpinner from "../../Components/Input/LoadingSpinner";
import Loading from "../../Components/Loading";
import CodeMirror from "@uiw/react-codemirror";
import { StreamLanguage } from '@codemirror/language';
import { codingLanguageDefaultCodes, codingLanguages, codingLanguageExtensions } from "./Data/CodingData";
const swal = require("sweetalert2");

const CodingTest = ({ isNoHeaderFooter, setIsNoHeaderFooter }) => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const {
    isLoading: isLoading2,
    error: error2,
    sendRequest: sendRequest2,
    clearError: clearError2,
  } = useHttpClient();
  const {
    isLoading: isLoading3,
    error: error3,
    sendRequest: sendRequest3,
    clearError: clearError3,
  } = useHttpClient();
  const {
    isLoading: isLoading4,
    error: error4,
    sendRequest: sendRequest4,
    clearError: clearError4,
  } = useHttpClient();
  const auth = useContext(AuthContext);
  const [testData, setTestData] = useState(null);
  const [examTime, setExamTime] = useState(null);
  const [codeOutput, setCodeOutput] = useState({
    input: "",
    expected: "",
    output: "",
    score: 0,
    total: 0,
    is_error: false,
    is_custom_input: false,
  });
  const [totalAttempted, setTotalAttempted] = useState(0);
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(null);
  const [selectedTestcase, setSelectedTestcase] = useState(0);
  const [isCustomInput, setIsCustomInput] = useState(false);
  const [isCodeRan, setIsCodeRan] = useState(false);
  const [resultsCurrentDiv, setResultsCurrentDiv] = useState("customInputDiv");
  const [customInput, setCustomInput] = useState(null);
  const [isQuestionSubmitted, setIsQuestionSubmitted] = useState({});
  const [selectedLanguage, setSelectedLanguage] = useState("Python");

  const [code, setCode] = useState(
    codingLanguageDefaultCodes[selectedLanguage]
  );

  const [windowIsActive, setWindowIsActive] = useState(true);

  // function handleActivity(forcedFlag) {
  //   if (typeof forcedFlag === "boolean") {
  //     return forcedFlag ? setWindowIsActive(true) : setWindowIsActive(false);
  //   }

  //   return document.hidden ? setWindowIsActive(false) : setWindowIsActive(true);
  // }

  //   useEffect(() => {
  //     const handleActivityFalse = () => handleActivity(false);
  //     const handleActivityTrue = () => handleActivity(true);

  //     document.addEventListener("visibilitychange", handleActivity);
  //     document.addEventListener("blur", handleActivityFalse);
  //     window.addEventListener("blur", handleActivityFalse);
  //     window.addEventListener("focus", handleActivityTrue);
  //     document.addEventListener("focus", handleActivityTrue);

  //     return () => {
  //       window.removeEventListener("blur", handleActivity);
  //       document.removeEventListener("blur", handleActivityFalse);
  //       window.removeEventListener("focus", handleActivityFalse);
  //       document.removeEventListener("focus", handleActivityTrue);
  //       document.removeEventListener("visibilitychange", handleActivityTrue);
  //     };
  //   }, []);

  //   useEffect(() => {
  //     if (!windowIsActive) {
  //       swal
  //         .fire({
  //           title: "Are you sure?",
  //           text: "You activity is being monitored! Continue the exam?",
  //           icon: "warning",
  //           showCancelButton: true,
  //           confirmButtonText: "No, exit!",
  //           cancelButtonText: "Yes, continue!",
  //           reverseButtons: true,
  //         })
  //         .then((result) => {
  //           if (result.isConfirmed) {
  //             setIsNoHeaderFooter(false);
  //           }
  //         });
  //     }
  //   }, [windowIsActive]);

  //   useEffect(() => {
  //     const handleBeforeUnload = (event) => {
  //       if (isNoHeaderFooter) {
  //         const message = "Your exam will be lost";
  //         event.returnValue = message;
  //         return message;
  //       } else {
  //         return;
  //       }
  //     };

  //     window.addEventListener("beforeunload", handleBeforeUnload);
  //     window.addEventListener("contextmenu", (event) => event.preventDefault());
  //     window.addEventListener("keydown", (event) => {
  //       event.preventDefault();
  //     });

  //     return () => {
  //       window.removeEventListener("beforeunload", handleBeforeUnload);
  //       window.removeEventListener("contextmenu", (event) =>
  //         event.preventDefault()
  //       );
  //       window.removeEventListener("keydown", (event) => {
  //         event.preventDefault();
  //       });
  //     };
  //   }, [isNoHeaderFooter]);

  useEffect(() => {
    const handleNavigation = () => {
      window.history.pushState(null, null, window.location.href);
      window.history.back();
      window.onpopstate = () => {
        window.history.forward();
      };
    };

    if (isNoHeaderFooter) {
      handleNavigation();
      return () => {
        window.onpopstate = null;
      };
    }
  }, [isNoHeaderFooter]);

  const { examid } = useParams();

  useEffect(() => {
    setIsNoHeaderFooter(true);
    const fetchExamData = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/api/coding/exam/${examid}`,
          "GET",
          null,
          {
            "Content-Type": "application/json",
            "Refresh-Token": auth.refresh_token,
            Authorization: auth.token,
          }
        );
        console.log(responseData.data);
        setTestData(responseData.data);
        setExamTime(responseData.data.total_time);
      } catch (err) {
        setIsNoHeaderFooter(false);
      }
    };
    fetchExamData();
  }, [examid]);

  useEffect(() => {
    if (!isNoHeaderFooter) {
      window.location.reload();
    }
  }, [isNoHeaderFooter]);

  const [minLeft, setMinLeft] = useState(null);
  const [secLeft, setSecLeft] = useState(null);

  useEffect(() => {
    if (examTime) {
      let min = examTime - 1;
      let sec = 59;
      setMinLeft(min);
      setSecLeft(sec);

      const timer = setInterval(() => {
        if (sec === 0) {
          if (min === 0) {
            clearInterval(timer);
            submitTestApi();
          } else {
            min--;
            sec = 59;
          }
        } else {
          sec--;
        }
        setMinLeft(min);
        setSecLeft(sec);
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [examTime]);

  const exitExam = () => {
    swal
      .fire({
        title: "Are you sure?",
        text: "You will not be able to continue the exam!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, exit!",
        cancelButtonText: "No, continue!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          setIsNoHeaderFooter(false);
        }
      });
  };

  const runCode = async () => {
    try {
      const responseData = await sendRequest2(
        `${process.env.REACT_APP_BACKEND_URL}/api/coding/run`,
        "POST",
        JSON.stringify({
          code: code,
          language: selectedLanguage.toLowerCase(),
          question_id: selectedQuestion.id,
          exam_id: testData.exam_id,
          custom_inputs: "",
        }),
        {
          "Content-Type": "application/json",
          "Refresh-Token": auth.refresh_token,
          Authorization: auth.token,
        }
      );
      setCodeOutput(responseData.data);
      if (responseData.data.is_custom_input) {
        setIsCustomInput(true);
      }
      setResultsCurrentDiv("testcaseResultsDiv");
      setIsCodeRan(true);
    } catch (err) {
      console.log(err);
    }
  };

  const submitAnswer = async () => {
    try {
      const responseData = await sendRequest3(
        `${process.env.REACT_APP_BACKEND_URL}/api/coding/submit`,
        "POST",
        JSON.stringify({
          code: code,
          language: selectedLanguage.toLowerCase(),
          question_id: selectedQuestion.id,
          exam_id: testData.exam_id,
          custom_inputs: "",
        }),
        {
          "Content-Type": "application/json",
          "Refresh-Token": auth.refresh_token,
          Authorization: auth.token,
        }
      );
      if (responseData.status=="submitted") {
        setIsQuestionSubmitted((prevStatus) => ({
          ...prevStatus,
          [selectedQuestion.id]: true,
        }));
        setSelectedQuestionIndex(null);
        setSelectedQuestion(null);
        setCodeOutput(null);
        setResultsCurrentDiv("customInputDiv");
        setIsCodeRan(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const submitTestApi = async () => {
    try {
      const responseData = await sendRequest4(
        `${process.env.REACT_APP_BACKEND_URL}/api/coding/submit_exam`,
        "POST",
        JSON.stringify({
          exam_id: testData.exam_id,
        }),
        {
          "Content-Type": "application/json",
          "Refresh-Token": auth.refresh_token,
          Authorization: auth.token,
        }
      );
      console.log(responseData.data);
      if (responseData.data) {
        swal.fire({
          title: "Test Submitted!",
          text: "You have successfully submitted the test.",
          icon: "success",
          confirmButtonText: "Close",
        });
        setIsNoHeaderFooter(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const resetCode = () => {
    swal
      .fire({
        title: "Are you sure?",
        text: "Your code will be reset!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, reset!",
        cancelButtonText: "No, continue!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          setCode(codingLanguageDefaultCodes[selectedLanguage]);
          setCodeOutput(null);
          setResultsCurrentDiv("customInputDiv");
          setCustomInput(null);
        }
      });
  };

  const submitCode = () => {
    swal
      .fire({
        title: "Are you sure?",
        text: "Your code will be submitted!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, submit!",
        cancelButtonText: "No, continue!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          submitAnswer();
        }
      });
  };

  const submitTest = () => {
    swal
      .fire({
        title: "Are you sure?",
        text: "You will not be able to continue the exam!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, submit!",
        cancelButtonText: "No, continue!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.isConfirmed) {
          submitTestApi();
        }
      });
  };

  const startAttempt = (question, i) => {
    if (isQuestionSubmitted[question.id]) {
      swal.fire({
        title: "Info",
        text: "You have already submitted the answer for this question.",
        icon: "info",
        confirmButtonText: "OK",
      });
    } else {
      setSelectedQuestion(question);
      setSelectedQuestionIndex(i + 1);
    }
  };

  useEffect(() => {
    if (error2) {
      swal.fire({
        title: "Error!",
        text:
          error ||
          "Something went wrong while running the code, please try again later.",
        icon: "error",
        confirmButtonText: "Close",
      });
      clearError2();
    }
  }, [error2, clearError2]);

  useEffect(() => {
    if (error3) {
      swal.fire({
        title: "Error!",
        text:
          error ||
          "Something went wrong while submitting the answer, please try again later.",
        icon: "error",
        confirmButtonText: "Close",
      });
      clearError3();
    }
  }, [error3, clearError3]);

  useEffect(() => {
    if (error4) {
      swal.fire({
        title: "Error!",
        text:
          error ||
          "Something went wrong while submitting the test, please try again later.",
        icon: "error",
        confirmButtonText: "Close",
      });
      clearError4();
    }
  }, [error4, clearError4]);

  return (
    <div className="coding-apptest-page">
      {!testData && <LoadingSpinner asOverlay />}
      {testData && (
        <>
          {!selectedQuestion ? (
            <>
              <div className="coding-ques-page">
                <div className="coding-head-fixed">
                  <div className="coding-exam-heading">
                    <div className="coding-exam-surge-head">
                      <div className="coding-exam-surge-head-part-left">
                        <img src={SurgeLogo} alt="Surge Classes" />
                        <div>CODING CHALLENGES</div>
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M16 7L20 12L16 17"
                            stroke="#FFFFFF"
                            strokeWidth="2"
                          />
                          <path
                            d="M8 7L4 12L8 17"
                            stroke="#FFFFFF"
                            strokeWidth="2"
                          />
                        </svg>
                      </div>
                      <div className="coding-exam-surge-head-part-right">
                        <div className="coding-exit-btn">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="none"
                            id="coding-exit-btn"
                            onClick={() => exitExam()}
                          >
                            <path
                              fill="#fff"
                              d="M6 2a4 4 0 0 0-4 4v3h2V6a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-3H2v3a4 4 0 0 0 4 4h12a4 4 0 0 0 4-4V6a4 4 0 0 0-4-4H6Z"
                            ></path>
                            <path
                              fill="#fff"
                              d="M3 11a1 1 0 1 0 0 2h9.582l-2.535 2.536a1 1 0 0 0 1.414 1.414l4.196-4.196a.998.998 0 0 0 0-1.508L11.46 7.05a1 1 0 1 0-1.414 1.414L12.582 11H3Z"
                            ></path>
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div className="coding-exam-surge-heading-bottom">
                      <div className="coding-flex-item">
                        <div className="coding-test-total-data">
                          <p>
                            TOTAL SCORE :{" "}
                            <span id="coding-total-score">
                              {testData.total_score} marks
                            </span>
                          </p>
                          <p>
                            TOTAL TIME :{" "}
                            <span id="coding-total-time">
                              {testData.total_time} minutes
                            </span>
                          </p>
                        </div>
                      </div>

                      <div className="coding-flex-item">
                        <div className="coding-timer" id="coding-timer">
                          <svg
                            width="17"
                            height="21"
                            viewBox="0 0 17 21"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8.29412 10.5L12.5279 14.1482C12.906 14.4741 13.1324 15.0157 13.1324 15.5949V17.9375C13.1324 18.1791 12.9776 18.375 12.7868 18.375H3.80147C3.61061 18.375 3.45588 18.1791 3.45588 17.9375V15.5949C3.45588 15.0157 3.68222 14.4741 4.0604 14.1482L8.29412 10.5ZM8.29412 10.5L12.5279 6.85179C12.906 6.52592 13.1324 5.98427 13.1324 5.40513V3.0625C13.1324 2.82088 12.9776 2.625 12.7868 2.625H3.80147C3.61061 2.625 3.45588 2.82088 3.45588 3.0625V5.40513C3.45588 5.98427 3.68222 6.52592 4.0604 6.85179L8.29412 10.5Z"
                              stroke="#33363F"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M11.0588 16.43V18.2437C11.0588 18.3162 11.0124 18.375 10.9551 18.375H5.63309C5.57583 18.375 5.52941 18.3162 5.52941 18.2437V16.43C5.52941 16.2791 5.59079 16.1389 5.69184 16.059L8.00106 14.2319C8.18035 14.09 8.40788 14.09 8.58718 14.2319L10.8964 16.059C10.9974 16.1389 11.0588 16.2791 11.0588 16.43Z"
                              fill="#33363F"
                            />
                          </svg>

                          {minLeft != null && secLeft != null && (
                            <p>
                              00:
                              {minLeft > 9 ? minLeft : `0${minLeft}`}:
                              {secLeft > 9 ? secLeft : `0${secLeft}`}
                            </p>
                          )}
                        </div>
                        <button
                          className="coding-submit-button"
                          onClick={() => submitTest()}
                        >
                          SUBMIT TEST
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="coding-practice-test">
                  {testData.questions.map((question, i) => (
                    <div className="coding-question-card" key={question.id}>
                      <div className="coding-question-card-left">
                        <div className="coding-question-card-left-top">
                          <h2 className="coding-question-card-left-top-question">
                            Challenge {i + 1}: {question.question_name}
                          </h2>
                        </div>
                        <div className="coding-question-card-left-bottom">
                          <div className="coding-question-card-left-top-level">
                            <svg
                              width="23"
                              height="24"
                              viewBox="0 0 23 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M5.73195 12.8652L2.86597 10.9775L11.4639 6.25842L20.0618 10.9775L17.1959 12.8652"
                                stroke="#ffffff"
                                strokeWidth="1"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M12.4193 13.8089C12.4193 14.3302 12.847 14.7527 13.3746 14.7527C13.9022 14.7527 14.3299 14.3302 14.3299 13.8089H12.4193ZM12.4193 7.20215V13.8089H14.3299V7.20215H12.4193Z"
                                fill="#ffffff"
                              />
                              <path
                                d="M5.73196 12.8652V16.6405L11.4639 19L17.1959 16.6405V12.8652C17.1959 12.8652 16.2406 10.9775 11.4639 10.9775C6.68729 10.9775 5.73196 12.8652 5.73196 12.8652Z"
                                stroke="#ffffff"
                                strokeWidth="1"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                            <p>{question.level}</p>
                          </div>
                          <div className="coding-question-card-left-bottom-time">
                            <svg
                              width="21"
                              height="20"
                              viewBox="0 0 21 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M10.4708 17.7865C13.9882 17.7865 16.8396 14.9695 16.8396 11.4944C16.8396 8.01935 13.9882 5.20227 10.4708 5.20227C6.95337 5.20227 4.10194 8.01935 4.10194 11.4944C4.10194 14.9695 6.95337 17.7865 10.4708 17.7865Z"
                                stroke="#ffffff"
                                strokeWidth="1"
                              />
                              <path
                                d="M10.4708 11.4944V9.13489"
                                stroke="#ffffff"
                                strokeWidth="1"
                                strokeLinecap="round"
                              />
                              <path
                                d="M14.8494 6.38205L16.0435 5.20227"
                                stroke="#ffffff"
                                strokeWidth="1"
                                strokeLinecap="round"
                              />
                              <path
                                d="M8.9328 2.34763C9.02356 2.26401 9.22346 2.19012 9.50154 2.13742C9.77962 2.08472 10.1203 2.05615 10.4708 2.05615C10.8213 2.05615 11.162 2.08472 11.4401 2.13742C11.7181 2.19012 11.918 2.26401 12.0088 2.34763"
                                stroke="#ffffff"
                                strokeWidth="1"
                                strokeLinecap="round"
                              />
                            </svg>
                            <p>{question.time} minutes </p>
                          </div>
                          <div className="coding-question-card-left-bottom-score">
                            <svg
                              width="17"
                              height="18"
                              viewBox="0 0 17 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M13.8156 8.86505V12.4044C13.8156 13.7391 13.8156 14.4065 13.4192 14.8212C13.0228 15.2358 12.3848 15.2358 11.1088 15.2358H4.68026C3.74595 15.2358 2.98854 14.4435 2.98854 13.4662M13.8156 8.86505C13.8156 10.1998 13.8156 10.8672 13.4192 11.2818C13.0228 11.6965 12.3848 11.6965 11.1088 11.6965H4.68026C3.74595 11.6965 2.98854 12.4888 2.98854 13.4662M13.8156 8.86505V5.32572C13.8156 3.99096 13.8156 3.32358 13.4192 2.90892C13.0228 2.49426 12.3848 2.49426 11.1088 2.49426H5.6953C4.41932 2.49426 3.78133 2.49426 3.38494 2.90892C2.98854 3.32358 2.98854 3.99096 2.98854 5.32572V13.4662"
                                stroke="#ffffff"
                                strokeWidth="1"
                              />
                              <path
                                d="M6.37199 7.44942L7.24689 8.36462C7.51113 8.64104 7.93961 8.64104 8.20386 8.36462L10.4321 6.03369"
                                stroke="#ffffff"
                                strokeWidth="1"
                                strokeLinecap="round"
                              />
                            </svg>
                            <p>{question.score} marks </p>
                          </div>
                        </div>
                      </div>
                      <div className="coding-question-card-right">
                        <button
                          className={`coding-question-button ${
                            isQuestionSubmitted[question.id] ? "inactive" : ""
                          }`}
                          onClick={() => startAttempt(question, i)}
                        >
                        {isQuestionSubmitted[question.id] ? "Submitted" : "Start Test"}
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </>
          ) : (
            <div className="coding-test">
              <div className="coding-test-heading-container">
                <div className="coding-test-heading">
                  <div className="coding-test-heading-left">
                    <div className="coding-test-heading-left-logo">
                      <img src={SurgeLogo} alt="Surge Classes" />
                    </div>
                    {/* <div className="coding-hamburger-nav-icon">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5 7H19"
                          stroke="#FFFFFF"
                          strokeWidth="2"
                          strokeLinecap="round"
                        />
                        <path
                          d="M5 12H19"
                          stroke="#FFFFFF"
                          strokeWidth="2"
                          strokeLinecap="round"
                        />
                        <path
                          d="M5 17H19"
                          stroke="#FFFFFF"
                          strokeWidth="2"
                          strokeLinecap="round"
                        />
                      </svg>
                    </div> */}
                    <div className="coding-test-heading-left-title">
                      <h1> {selectedQuestion.question_name}</h1>
                    </div>
                  </div>
                  <div className="coding-test-heading-right">
                    <div className="coding-timer" id="coding-timer">
                      <svg
                        width="17"
                        height="21"
                        viewBox="0 0 17 21"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.29412 10.5L12.5279 14.1482C12.906 14.4741 13.1324 15.0157 13.1324 15.5949V17.9375C13.1324 18.1791 12.9776 18.375 12.7868 18.375H3.80147C3.61061 18.375 3.45588 18.1791 3.45588 17.9375V15.5949C3.45588 15.0157 3.68222 14.4741 4.0604 14.1482L8.29412 10.5ZM8.29412 10.5L12.5279 6.85179C12.906 6.52592 13.1324 5.98427 13.1324 5.40513V3.0625C13.1324 2.82088 12.9776 2.625 12.7868 2.625H3.80147C3.61061 2.625 3.45588 2.82088 3.45588 3.0625V5.40513C3.45588 5.98427 3.68222 6.52592 4.0604 6.85179L8.29412 10.5Z"
                          stroke="#33363F"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M11.0588 16.43V18.2437C11.0588 18.3162 11.0124 18.375 10.9551 18.375H5.63309C5.57583 18.375 5.52941 18.3162 5.52941 18.2437V16.43C5.52941 16.2791 5.59079 16.1389 5.69184 16.059L8.00106 14.2319C8.18035 14.09 8.40788 14.09 8.58718 14.2319L10.8964 16.059C10.9974 16.1389 11.0588 16.2791 11.0588 16.43Z"
                          fill="#33363F"
                        />
                      </svg>

                      {minLeft != null && secLeft != null && (
                        <p>
                          00:
                          {minLeft > 9 ? minLeft : `0${minLeft}`}:
                          {secLeft > 9 ? secLeft : `0${secLeft}`}
                        </p>
                      )}
                    </div>
                    <div className="coding-test-editor-buttons">
                      <button
                        className="coding-test-editor-buttons-run-button"
                        disabled={isLoading2}
                        onClick={() => runCode()}
                      >
                        {isLoading2 ? (
                          <Loading customClass="coding-run-loading" />
                        ) : (
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M16.2111 11.1056L9.73666 7.86833C8.93878 7.46939 8 8.04958 8 8.94164V15.0584C8 15.9504 8.93878 16.5306 9.73666 16.1317L16.2111 12.8944C16.9482 12.5259 16.9482 11.4741 16.2111 11.1056Z"
                              stroke="#FFFFFF"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        )}
                        Run
                      </button>
                      <button
                        className="coding-test-editor-buttons-reset-button"
                        onClick={() => resetCode()}
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M18 20L17.6464 20.3536L18 20.7071L18.3536 20.3536L18 20ZM13 4.5C12.7239 4.5 12.5 4.72386 12.5 5C12.5 5.27614 12.7239 5.5 13 5.5L13 4.5ZM13.6464 16.3536L17.6464 20.3536L18.3536 19.6464L14.3536 15.6464L13.6464 16.3536ZM18.3536 20.3536L22.3536 16.3536L21.6464 15.6464L17.6464 19.6464L18.3536 20.3536ZM18.5 20L18.5 9L17.5 9L17.5 20L18.5 20ZM14 4.5L13 4.5L13 5.5L14 5.5L14 4.5ZM18.5 9C18.5 6.51472 16.4853 4.5 14 4.5L14 5.5C15.933 5.5 17.5 7.067 17.5 9L18.5 9Z"
                            fill="#FFF"
                          />
                          <path
                            d="M6 4L6.35355 3.64645L6 3.29289L5.64645 3.64645L6 4ZM11 19.5C11.2761 19.5 11.5 19.2761 11.5 19C11.5 18.7239 11.2761 18.5 11 18.5L11 19.5ZM10.3536 7.64645L6.35355 3.64645L5.64645 4.35355L9.64645 8.35355L10.3536 7.64645ZM5.64645 3.64645L1.64645 7.64645L2.35355 8.35355L6.35355 4.35355L5.64645 3.64645ZM5.5 4L5.5 15L6.5 15L6.5 4L5.5 4ZM10 19.5L11 19.5L11 18.5L10 18.5L10 19.5ZM5.5 15C5.5 17.4853 7.51472 19.5 10 19.5L10 18.5C8.067 18.5 6.5 16.933 6.5 15L5.5 15Z"
                            fill="#FFF"
                          />
                        </svg>
                        Reset
                      </button>
                      <button
                        className="coding-test-editor-buttons-submit-button"
                        disabled={isLoading3}
                        onClick={() => submitCode()}
                      >
                        {isLoading3 ? <>Submitting...</> : <>Submit Code</>}
                      </button>
                    </div>
                    <div className="coding-test-close">
                      <div
                        className="coding-test-close-icon"
                        onClick={() => {
                          setSelectedQuestion(null);
                          setSelectedQuestionIndex(null);
                          setCodeOutput(null);
                          setIsCodeRan(false);
                          setResultsCurrentDiv("customInputDiv");
                        }}
                      >
                        X
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="coding-test-flex-container">
                <div className="coding-test-flex-container-left">
                  <div className="coding-test-question">
                    <h3>
                      Challenge {selectedQuestionIndex}:{" "}
                      {selectedQuestion.question_name}
                    </h3>
                    <div className="coding-test-question-tags">
                      <div className="coding-question-card-left-top-level">
                        <svg
                          width="23"
                          height="24"
                          viewBox="0 0 23 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5.73195 12.8652L2.86597 10.9775L11.4639 6.25842L20.0618 10.9775L17.1959 12.8652"
                            stroke="#ffffff"
                            strokeWidth="1"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M12.4193 13.8089C12.4193 14.3302 12.847 14.7527 13.3746 14.7527C13.9022 14.7527 14.3299 14.3302 14.3299 13.8089H12.4193ZM12.4193 7.20215V13.8089H14.3299V7.20215H12.4193Z"
                            fill="#ffffff"
                          />
                          <path
                            d="M5.73196 12.8652V16.6405L11.4639 19L17.1959 16.6405V12.8652C17.1959 12.8652 16.2406 10.9775 11.4639 10.9775C6.68729 10.9775 5.73196 12.8652 5.73196 12.8652Z"
                            stroke="#ffffff"
                            strokeWidth="1"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <p>{selectedQuestion.level}</p>
                      </div>
                      <div className="coding-question-card-left-bottom-time">
                        <svg
                          width="21"
                          height="20"
                          viewBox="0 0 21 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.4708 17.7865C13.9882 17.7865 16.8396 14.9695 16.8396 11.4944C16.8396 8.01935 13.9882 5.20227 10.4708 5.20227C6.95337 5.20227 4.10194 8.01935 4.10194 11.4944C4.10194 14.9695 6.95337 17.7865 10.4708 17.7865Z"
                            stroke="#FFFFFF"
                            strokeWidth="1"
                          />
                          <path
                            d="M10.4708 11.4944V9.13489"
                            stroke="#FFFFFF"
                            strokeWidth="1"
                            strokeLinecap="round"
                          />
                          <path
                            d="M14.8494 6.38205L16.0435 5.20227"
                            stroke="#FFFFFF"
                            strokeWidth="1"
                            strokeLinecap="round"
                          />
                          <path
                            d="M8.9328 2.34763C9.02356 2.26401 9.22346 2.19012 9.50154 2.13742C9.77962 2.08472 10.1203 2.05615 10.4708 2.05615C10.8213 2.05615 11.162 2.08472 11.4401 2.13742C11.7181 2.19012 11.918 2.26401 12.0088 2.34763"
                            stroke="#FFFFFF"
                            strokeWidth="1"
                            strokeLinecap="round"
                          />
                        </svg>
                        <p>{selectedQuestion.time} minutes </p>
                      </div>
                      <div className="coding-question-card-left-bottom-score">
                        <svg
                          width="17"
                          height="18"
                          viewBox="0 0 17 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13.8156 8.86505V12.4044C13.8156 13.7391 13.8156 14.4065 13.4192 14.8212C13.0228 15.2358 12.3848 15.2358 11.1088 15.2358H4.68026C3.74595 15.2358 2.98854 14.4435 2.98854 13.4662M13.8156 8.86505C13.8156 10.1998 13.8156 10.8672 13.4192 11.2818C13.0228 11.6965 12.3848 11.6965 11.1088 11.6965H4.68026C3.74595 11.6965 2.98854 12.4888 2.98854 13.4662M13.8156 8.86505V5.32572C13.8156 3.99096 13.8156 3.32358 13.4192 2.90892C13.0228 2.49426 12.3848 2.49426 11.1088 2.49426H5.6953C4.41932 2.49426 3.78133 2.49426 3.38494 2.90892C2.98854 3.32358 2.98854 3.99096 2.98854 5.32572V13.4662"
                            stroke="#FFFFFF"
                            strokeWidth="1"
                          />
                          <path
                            d="M6.37199 7.44942L7.24689 8.36462C7.51113 8.64104 7.93961 8.64104 8.20386 8.36462L10.4321 6.03369"
                            stroke="#FFFFFF"
                            strokeWidth="1"
                            strokeLinecap="round"
                          />
                        </svg>
                        <p>{selectedQuestion.score} marks </p>
                      </div>
                    </div>
                    <h4>Question Description:</h4>
                    {selectedQuestion.question_description && (
                      <div
                        className="coding-test-question-description"
                        dangerouslySetInnerHTML={{
                          __html: selectedQuestion.question_description,
                        }}
                      ></div>
                    )}
                  </div>
                </div>
                <div className="coding-test-flex-container-middle">
                  <div className="vertical-line"></div>
                </div>
                <div className="coding-test-flex-container-right">
                  <div className="coding-test-editor">
                    <div className="coding-test-editor-language">
                      <select
                        className="coding-test-editor-language-select"
                        value={selectedLanguage}
                        onChange={(e) => {
                          setSelectedLanguage(e.target.value);
                          setCode(codingLanguageDefaultCodes[e.target.value]);
                          setCodeOutput(null);
                          setIsCodeRan(false);
                        }}
                      >
                        {codingLanguages.map((language, i) => (
                          <option key={i} value={language}>{language}</option>
                        ))}
                      </select>
                    </div>
                    <div className="coding-test-editor-code">
                      <CodeMirror
                        value={code}
                        onChange={(value) => setCode(value)}
                        options={{
                          theme: "material",
                          lineNumbers: true,
                        }}
                        extensions={[StreamLanguage.define(codingLanguageExtensions[selectedLanguage])]}
                        height="300px"
                      />
                    </div>
                  </div>
                  <div className="coding-test-flex-container-right-middle">
                    <div className="horizontal-line"></div>
                  </div>
                  <div className="coding-test-results">
                    <div className="coding-test-results-heading">
                      <div
                        className="coding-test-custom-input"
                        onClick={() => setResultsCurrentDiv("customInputDiv")}
                      >
                        Custom Input
                      </div>
                      <div
                        className="coding-test-results-testcases-results"
                        onClick={() =>
                          setResultsCurrentDiv("testcaseResultsDiv")
                        }
                      >
                        Testcases Results
                      </div>
                    </div>
                    {resultsCurrentDiv === "customInputDiv" && (
                      <div className="coding-test-custom-input-box">
                        <textarea
                          className="coding-test-custom-input-box-textarea"
                          placeholder="Enter custom input. Custom input is not supported for this question"
                          onChange={(e) => setCustomInput(e.target.value)}
                        ></textarea>
                      </div>
                    )}
                    {resultsCurrentDiv === "testcaseResultsDiv" && (
                      <div className="coding-test-case-results-div">
                        {isCodeRan ? (
                          <div className="coding-test-output-box">
                            {codeOutput && (
                              <>
                                {codeOutput.is_error ? (
                                  <div className="coding-test-output-box-error">
                                    {codeOutput.output}
                                  </div>
                                ) : (
                                  <div className="coding-test-output-box-success">
                                    {!isCustomInput && (
                                      <>
                                        <div className="coding-test-output-box-success-score">
                                          Score: {codeOutput.score} out of{" "}
                                          {codeOutput.total}
                                        </div>

                                        <h4>Testcases:</h4>
                                        <div
                                          className="code-testcase-input"
                                          style={{ lineHeight: "1.5" }}
                                        >
                                          {codeOutput.input.map((_, index) => (
                                            <div
                                              key={index}
                                              className={`coding-test-case ${
                                                selectedTestcase === index
                                                  ? "active"
                                                  : ""
                                              }`}
                                            >
                                              <div
                                                className="coding-test-case-heading-box"
                                                onClick={() =>
                                                  setSelectedTestcase(index)
                                                }
                                              >
                                                Test Case {index + 1}
                                              </div>
                                            </div>
                                          ))}
                                        </div>
                                        {selectedTestcase != null && (
                                          <div className="coding-testcase">
                                            <div className="coding-testcase-input">
                                              <strong>Input:</strong>
                                              <br />
                                              <code>
                                                <pre>
                                                  {
                                                    codeOutput.input[
                                                      selectedTestcase
                                                    ]
                                                  }
                                                </pre>
                                              </code>
                                            </div>
                                            <div className="coding-testcase-expected">
                                              <strong>Expected:</strong>
                                              <br />
                                              <code>
                                                <pre>
                                                  {
                                                    codeOutput.expected[
                                                      selectedTestcase
                                                    ]
                                                  }
                                                </pre>
                                              </code>
                                            </div>
                                            <div className="coding-testcase-output">
                                              <strong>Output:</strong>
                                              <br />
                                              <code>
                                                <pre>
                                                  {
                                                    codeOutput.output[
                                                      selectedTestcase
                                                    ]
                                                  }
                                                </pre>
                                              </code>
                                            </div>
                                          </div>
                                        )}
                                      </>
                                    )}
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                        ) : (
                          <div className="coding-test-output-box-div">
                            <div className="coding-test-output-box-error-div">
                              Run the code to see the results
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default CodingTest;
