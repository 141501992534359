import React, { useState } from 'react';
import Info from '../../Components/Info';
import Placement from './pages/Placement';

const PlacementBasedInterview = ({ 
  isLoading, 
  error, 
  jobpackage, 
  setSelectedPackage, 
  selectedPackage, 
  startInterviewHandler, 
  selectedInterviewType, 
  handleGoToHome, 
  handleGoToAllInterview 
}) => {
  const [showPlacement, setShowPlacement] = useState(false);

  const handleShowPlacement = () => {
    setShowPlacement(true);
  };

  return (
    <>
      {!showPlacement ? (
        <Info
          selectedInterviewType={selectedInterviewType}
          handleGoToHome={handleGoToHome}
          handleGoToAllInterview={handleGoToAllInterview}
          onNext={handleShowPlacement}
        />
      ) : (
        <Placement
          selectedInterviewType={selectedInterviewType}
          startInterviewHandler={startInterviewHandler}
          isLoading={isLoading}
          error={error}
          jobpackage={jobpackage}
          setSelectedPackage={setSelectedPackage}
          selectedPackage={selectedPackage}
          handleGoToHome={handleGoToHome}
          handleGoToAllInterview={handleGoToAllInterview}
          handleGoToPlacementBased={setShowPlacement}
        />
      )}
    </>
  );
};

export default PlacementBasedInterview;

