import React, { useState, useContext } from "react";
import FileBase64 from 'react-file-base64';

import "./Popup.css";
import { AuthContext } from "../../context/auth-context";
import { useHttpClient } from "../../Components/Hooks/http-hook";
import ErrorModal from "../../Components/Input/ErrorModel";
import LoadingSpinner from "../../Components/Input/LoadingSpinner";

const Popup = ({ showPopup, setShowPopup }) => {
  const [queryTitle, setQueryTitle] = useState("");
  const [queryDescription, setQueryDescription] = useState("");
  const [queryLevel, setQueryLevel] = useState(0);
  const [queryFile, setQueryFile] = useState(null);
  const [fileDetails, setFileDetails] = useState();

  const auth = useContext(AuthContext);

  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const handleSubmitQuery = (event) => {
    event.preventDefault();
    const formData1 = new FormData();
    formData1.append("title", queryTitle);
    formData1.append("description", queryDescription);
    formData1.append("level", queryLevel);
    queryFile && formData1.append("file", queryFile);

    let queryData = {};
    formData1.forEach((value, key) => {
      queryData[key] = value;
    });

    queryData["file_details"] = fileDetails;
    queryData = JSON.stringify(queryData);
    console.log(queryData);
    sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/auth/user/queries`,
      "POST",
      queryData,
      {
        "Content-Type": "application/json",
        "Refresh-Token": auth.refresh_token,
        Authorization: auth.token,
      }
    ).then((response) => {
      setShowPopup(false);
      if (response.message) {
        alert(response.message);
      } else {
        if (response.error) {
          alert(response.error);
        }
      }
    });

    setQueryTitle("");
    setQueryDescription("");
    setQueryLevel(0);
    setQueryFile(null);
  };

  return (
    <>
      {/* Query Form Popup */}
      <React.Fragment>
    <ErrorModal error={error} onClear={clearError} />
    {isLoading && <LoadingSpinner asOverlay />}
    {
      !isLoading && (
        <div>
        {showPopup && (
          <div className="popup">
            <div className="popup__content">
              <h2>Raise a Query</h2>
              <div className="close-btn" onClick={() => setShowPopup(false)}>
                X
              </div>
              <form onSubmit={handleSubmitQuery}>
                <label htmlFor="title">Title:</label>
                <input
                  type="text"
                  id="title"
                  value={queryTitle}
                  required
                  onChange={(event) => setQueryTitle(event.target.value)}
                />
  
                <label htmlFor="description">Description:</label>
                <textarea
                  type="text"
                  id="description"
                  rows={5}
                  cols={10}
                  value={queryDescription}
                  required
                  onChange={(event) => setQueryDescription(event.target.value)}
                />
  
                <label htmlFor="level">Level:</label>
  
                <select
                  id="level"
                  onChange={(event) => setQueryLevel(event.target.value)}
                >
                  <option value="0">Non-Urgent</option>
                  <option value="1">Urgent</option>
                </select>
  
                <label htmlFor="file">Upload Files:</label>
                <FileBase64
                  type="file"
                  multiple={true}
                  onDone={(files) => {
                    const fileDetails = files.map((file) => ({
                      name: file.name,
                      base64: file.base64,
                    }));
                    setFileDetails(fileDetails);
                  }}
                  onChange={(e) => {
                    const files = e.target.files;
                    const fileDetails = [];
                    for (let i = 0; i < files.length; i++) {
                      const file = files[i];
                      const reader = new FileReader();
                      reader.onload = (event) => {
                        fileDetails.push({
                          name: file.name,
                          base64: event.target.result,
                        });
                        if (fileDetails.length === files.length) {
                          setFileDetails(fileDetails);
                        }
                      };
                      reader.readAsDataURL(file);
                    }
                  }}
                ></FileBase64>
  
                <button type="submit">Submit</button>
              </form>
            </div>
          </div>
        )}  
        </div>
      )
    }
    </React.Fragment>
    </>
  );
};

export default Popup;
