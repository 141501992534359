import React, { useState, useEffect, useContext } from "react";
import "./Assignments.css";
import View from "./Actions/View/View";
import Upload from "./Actions/Upload/Upload";
import Loading from "../../../../../../../../../../../Components/Loading";
import { useNavigate, useParams } from "react-router-dom";
import { useHttpClient } from '../../../../../../../../../../../Components/Hooks/http-hook';
import { AuthContext } from '../../../../../../../../../../../context/auth-context';
import Badge from "../../../../../../../../../../Admin/SkillboxAdmin/Components/UiComponents/Badge/Badge";
import { ToastContainer } from "react-toastify";

import moment from "moment";

const Assignments = ({ handleBackToModules }) => {
    const auth = useContext(AuthContext);
    const navigate = useNavigate();
    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const { cid, ct, pid, mid } = useParams();
    const [assignments, setAssignments] = useState([]);
    const [showViewPopup, setShowViewPopup] = useState(false);
    const [showUploadPopup, setShowUploadPopup] = useState(false);
    const [selectedAssignment, setSelectedAssignment] = useState({});
    const [reload, setReload] = useState(false);

    const goToHome = () => {
        navigate("/skillbox-v2");
    }

    const goToPathWay = () => {
        navigate(`/skillbox-v2/${cid}/${ct}`);
      }

    const goToModules = () => {
        navigate(`/skillbox-v2/${cid}/${ct}/pathway/${pid}`);
    }

    const viewAssignment = (data) => {
        setSelectedAssignment(data);
        setShowViewPopup(true);
    };

    const uploadAssignment = (data) => {
        setSelectedAssignment(data);
        setShowUploadPopup(true);
    };

    const formatDate = (date) => {
        return moment(date).format("DD-MM-YYYY");
    }

    const fetchAssignments = async () => {
        try {
            const responseData = await sendRequest(
                `${process.env.REACT_APP_BACKEND_URL}/assignments/student/${mid}`,
                "GET",
                null,
                {
                    "Content-Type": "application/json",
                    "Refresh-Token": auth.refresh_token,
                    Authorization: auth.token,
                }
            );
            if (responseData) {
                setAssignments(responseData.assignments);
            }
        }
        catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        fetchAssignments();
    }
    , [reload]);

    return (
        <>
            <ToastContainer closeButton={false} />
            <View
                show={showViewPopup}
                setShow={setShowViewPopup}
                assignmentData={selectedAssignment}
            />
            <Upload
                show={showUploadPopup}
                setShow={setShowUploadPopup}
                assignmentData={selectedAssignment}
                setReload={setReload}
            />
            <div className="stu-skillbox-nav-header">
                <span onClick={goToHome}>Home</span>
                <span>
                    {" "}&gt;{" "}
                </span>
                <span onClick={goToPathWay}>Pathway</span>
                <span>
                    {" "}&gt;{" "}
                </span>
                <span onClick={handleBackToModules}>Modules</span>
            </div>
            <div className="stu-ts-skillbox-header">
                <div className="stu-ts-skillbox-header-title">
                    Assignments
                </div>
                {/* <div className="st-ts-assgn-body-last-updated">
                    Last Updated: N/A
                </div> */}
            </div>
            {(isLoading || error) && (
                <div className="st-assgn-body">
                    <div className="st-assgn-lt-section">
                        {isLoading && (
                            <Loading color="#004c73" customClass="st-assgn-interview-loading" />
                        )}
                        {error && <div className="interview-questions-not-found-section">
                            Something went wrong, Please try again later
                        </div>
                        }
                    </div>
                </div>
            )}
            {!isLoading && assignments && assignments.length === 0 && (
                <div className="st-assgn-body">
                    <div className="st-assgn-lt-section">
                        <div className="interview-questions-not-found-section">
                            No Assignments Found
                        </div>
                    </div>
                </div>
            )}
            {assignments && assignments.length > 0 && (
                <div className="st-assgn-body">
                    <div className="st-assgn-lt-section">
                        <div className="st-assgn-table">
                            <div className="st-assgn-table-header">
                                <div className="st-assgn-lt-table-header-row-1">
                                    Title
                                </div>
                                <div className="st-assgn-lt-table-header-row-2">
                                    Due Date
                                </div>
                                <div className="st-assgn-lt-table-header-row-3">
                                    Assignment
                                </div>
                                <div className="st-assgn-lt-table-header-row-4">
                                    Status
                                </div>
                                <div className="st-assgn-lt-table-header-row-5">
                                    Grade
                                </div>
                            </div>
                            <div className="st-assgn-hr-line"></div>
                            <div className="st-assgn-table-body">
                                {assignments.map((data) => (
                                    <div
                                        key={data.assignment_id}
                                        className="st-assgn-table-row"
                                    >
                                        <div className="st-assgn-lt-table-row-1">
                                            {data.title}
                                        </div>
                                        <div className="st-assgn-lt-table-row-2">
                                            {data.deadline ? formatDate(data.deadline) : "-"}
                                        </div>
                                        <div className="st-assgn-lt-table-row-3">
                                            <span onClick={() => viewAssignment(data)}>
                                                View
                                            </span> 
                                            {!(data.grading_status === "submitted" || data.grading_status === "graded" || data.grading_status === "late_submission") && (
                                            <>
                                            |{" "}
                                            <span onClick={() => uploadAssignment(data)}>
                                                Upload
                                            </span>
                                            </>
                                            )}
                                        </div>
                                        <div className="st-assgn-lt-table-row-4">
                                            <Badge
                                                    {...{ [data.grading_status]: true }}
                                            />
                                        </div>
                                        <div className="st-assgn-lt-table-row-5">
                                            {data.grade_obtained ? data.grade_obtained : "-"}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Assignments;
