import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import CreateResume from "./GenerateResumeSection/CreateResume";
import ProfileIcon from "../assets/images/profile.jpg";
import EditTemplateData from "../Templates/EditTemplateData";
import DocumentEditor from "../editor/DocumentEditor";
import DocumentTemplate2 from "../Templates/DocumentTemplate2";
import Mustache from "mustache";
import html2pdf from "html2pdf.js";
import { saveAs } from "file-saver";
import htmlDocx from "html-docx-js/dist/html-docx";

const templates = [
  {
    id: 1,
    name: "Default",
    image: require("../assets/images/default-temp.png"),
  },
  {
    id: 2,
    name: "Fresher",
    image: require("../assets/images/fresher.png"),
  },
  {
    id: 3,
    name: "Fresher (Dark Mode)",
    image: require("../assets/images/fresher-dark.png"),
  },
  {
    id: 4,
    name: "Simple",
    image: require("../assets/images/simple.png"),
  },
];

const GenerateResumeSection = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [finalTemplate, setFinalTemplate] = useState("");
  const [editMode, setEditMode] = useState(false);
  const navigate = useNavigate();
  const pdfRef = useRef();

  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const [templateData, setTemplateData] = useState(null);

  const goToHome = () => {
    navigate("/skillbox-v2");
  };

  const goToTemplates = () => {
    setSelectedTemplate(null);
  };

  const goToGenerateResume = () => {
    setEditMode(false);
  };

  useEffect(() => {
    if (templateData) {
      let k = DocumentTemplate2;
      k = Mustache.render(k, templateData);
      setFinalTemplate(k);
    }
  }, [templateData, DocumentTemplate2, setFinalTemplate]);

  // const generatePDF = () => {
  //   const element = pdfRef.current;
  //   const options = {
  //     margin: 0.5,
  //     filename: "resume.pdf",
  //     image: { type: "png", quality: 0.98 },
  //     html2canvas: { scale: 2 },
  //     jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
  //     pagebreak: { mode: ["css", "legacy"] },
  //   };
  //   html2pdf().from(element).set(options).save();
  // };

  const generatePDF = () => {
    const element = pdfRef.current;

    const mywindow = window.open("", "PRINT", "height=600,width=800");

    mywindow.document.write(`
        <html>
            <head>
                <title>Resume</title>
                <style>
                    .header {
                        display: none;
                    }
                    .section {
                        margin-bottom: 20px;
                    }
                    .section-title {
                        font-size: 18px;
                        font-weight: bold;
                        margin-bottom: 10px;
                    }
                    .section-content {
                        font-size: 14px;
                    }
                </style>
            </head>
            <body>
                ${element.innerHTML}
            </body>
        </html>
    `);

    mywindow.document.close();
    mywindow.focus();
    mywindow.print();
    mywindow.onafterprint = function() {
        mywindow.close();
    };
};


  const generateDOCX = () => {
    const element = pdfRef.current;
    const htmlString = element.innerHTML;
    const docxBlob = htmlDocx.asBlob(htmlString);
    saveAs(docxBlob, "resume.docx");
  };

  return (
    <div>
      {!selectedTemplate ? (
        <div>
          <div className="stu-skillbox-nav-header">
            <span onClick={goToHome}>Home</span>
          </div>
          <div className="section-title">Generate Resume</div>
          <div className="section-content">
            <div className="content-title">Select a template</div>
            <div className="content-title-desc">
              A resume will be created based on the information provided by you
              on your profile page.
            </div>
            <div className="template-container">
              {templates.map((template) => (
                <div
                  className="template-card"
                  key={template.id}
                  onClick={() => setSelectedTemplate(template.id)}
                >
                  <img className="template-image" src={template.image} />
                  <div className="template-name">{template.name}</div>
                </div>
              ))}
            </div>
            {isLoading && (
              <div
                className="loading-modal"
                onClick={() => setIsLoading(!isLoading)}
              />
            )}
          </div>
        </div>
      ) : (
        <>
          {editMode ? (
            <>
              <div className="stu-skillbox-nav-header">
                <span onClick={goToHome}>Home</span>
                <span> &gt; </span>
                <span onClick={goToTemplates}>Templates</span>
                <span> &gt; </span>
                <span onClick={goToGenerateResume}>Generate Resume</span>
              </div>
              <DocumentEditor initialContent={finalTemplate} generateDOCX={generateDOCX}/>
            </>
          ) : (
            <>
              <div className="stu-skillbox-nav-header">
                <span onClick={goToHome}>Home</span>
                <span> &gt; </span>
                <span onClick={goToTemplates}>Templates</span>
              </div>
              <CreateResume
                templates={templates}
                selectedTemplate={selectedTemplate}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                templateData={templateData}
                setTemplateData={setTemplateData}
                finalTemplate={finalTemplate}
                generatePDF={generatePDF}
                generateDOCX={generateDOCX}
                pdfRef={pdfRef}
                setEditMode={setEditMode}
              />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default GenerateResumeSection;
