import React, { useState, useEffect, useRef, useContext } from 'react';
import SearchSvg from "../../Components/Svgs/SearchSvg";
import AddSvg from "../../Components/Svgs/AddSvg";
import "./Courses.css";
import PopUp from "../../../../../Components/Profile/Popup/PopUp";
import MinusSvg from "../../Components/Svgs/MinusSvg";
import PlusSvg from "../../Components/Svgs/PlusSvg";
import { useHttpClient } from "../../../../../Components/Hooks/http-hook";
import { AuthContext } from "../../../../../context/auth-context";
import Loading from "../../../../../Components/Loading";
import { saveAs } from 'file-saver';

const Courses = ({ handleTabChange, setSelectedCoursesData }) => {
    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const { isLoading: isLoadingAddCourse, error: errorAddCourse, sendRequest: sendRequestAddCourse, clearError: clearErrorAddCourse } = useHttpClient();
    const auth = useContext(AuthContext);
    const [courses, setCourses] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredCourses, setFilteredCourses] = useState(courses);
    const [showAddCourseModal, setShowAddCourseModal] = useState(false);
    const [newCourseName, setNewCourseName] = useState('');
    const [isAddingCourse, setIsAddingCourse] = useState(false);
    const [addCourseError, setAddCourseError] = useState("");
    const newCourseInputRef = useRef(null);

    const [showPopup, setShowPopup] = useState(false);
    const [questionCount, setQuestionCount] = useState(50);
    const [isGenerating, setIsGenerating] = useState(false);
    const [generateProgress, setGenerateProgress] = useState(0);
    const [generateError, setGenerateError] = useState(null);
    const [csvGenerated, setCsvGenerated] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    const [generatedCourseData, setGeneratedCourseData] = useState(null);

    useEffect(() => {
        const fetchCourses = async () => {
            try {
                const responseData = await sendRequest(
                    `${process.env.REACT_APP_BACKEND_URL}/api/qgt/courses`,
                    "GET",
                    null,
                    {
                        "Content-Type": "application/json",
                        Authorization: auth.token,
                    }
                );
                if (responseData.ok) {
                    setCourses(responseData.data);
                    setFilteredCourses(responseData.data);
                }
                else {
                    throw new Error(responseData.message || "Failed to fetch courses");
                }
            } catch (err) {
                console.log(err);
            }
        };

        fetchCourses();
    }, [sendRequest, auth.token]);

    useEffect(() => {
        if (searchTerm && searchTerm.trim() !== "") {
            setFilteredCourses(
                courses.filter(
                    (course) =>
                        course &&
                        course.title &&
                        course.title.toLowerCase().includes(searchTerm.toLowerCase())
                )
            );
        } else {
            setFilteredCourses(courses);
        }
    }, [searchTerm, courses]);

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleAddCourse = async () => {
        if (newCourseName.trim()) {
            if (courses.some(course => course && course.title && course.title.toLowerCase() === newCourseName.trim().toLowerCase())) {
                setAddCourseError("This course already exists");
            } else {
                setShowPopup(true);
            }
        }
    };

    const handleGenerateCSV = async () => {
        setIsGenerating(true);
        setGenerateError(null);
        setCsvGenerated(false);
        setShowSuccessMessage(false);
        clearErrorAddCourse();
        setGenerateProgress(0);

        try {
            const responseData = await sendRequestAddCourse(
                `${process.env.REACT_APP_BACKEND_URL}/api/qgt/courses/generate`,
                "POST",
                JSON.stringify({
                    title: newCourseName.trim(),
                    questionCount: questionCount
                }),
                {
                    "Content-Type": "application/json",
                    Authorization: auth.token,
                }
            );

            if (responseData.ok) {
                const newCourse = {
                    ...responseData.data,
                    createdOn: new Date().toISOString(),
                    type: "Course Based",
                    questionCount: questionCount
                };
                setCourses([...courses, newCourse]);
                setSelectedCoursesData(newCourse);
                setGeneratedCourseData(newCourse);
                setCsvGenerated(true);
                setShowSuccessMessage(true);
                setGenerateProgress(100);
            } else {
                throw new Error(responseData.message || "Failed to add course");
            }
        } catch (err) {
            setGenerateError(err.message);
        } finally {
            setIsGenerating(false);
        }
    };

    const handleDownloadCSV = async () => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_BACKEND_URL}/api/qgt/courses/${generatedCourseData.id}/download`,
                {
                    method: 'GET',
                    headers: {
                        Authorization: auth.token,
                    },
                }
            );

            if (response.ok) {
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = `${generatedCourseData.title}_questions.csv`;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            } else {
                throw new Error('Failed to download CSV');
            }
        } catch (error) {
            console.error('Error downloading CSV:', error);
        }
    };

    const handleRetry = () => {
        setGenerateError(null);
        handleGenerateCSV();
    };

    const handleClosePopup = () => {
        setShowPopup(false);
        setQuestionCount(50);
        setIsGenerating(false);
        setGenerateProgress(0);
        setGenerateError(null);
        setCsvGenerated(false);
        setShowSuccessMessage(false);
    };

    useEffect(() => {
        if (isAddingCourse && newCourseInputRef.current) {
            newCourseInputRef.current.focus();
        }
    }, [isAddingCourse]);

    const handleDecrementCount = () => {
        setQuestionCount(Math.max(10, questionCount - 1));
    };

    const handleIncrementCount = () => {
        setQuestionCount(Math.min(100, questionCount + 1));
    };

    return (
        <div className="qt-courses-container">
            {isLoading || error ? (
                <div className="qt-error-section">
                    {isLoading && (
                        <Loading color="#004c73" customClass="qt-courses-loading" />
                    )}
                    {error && <div className="qt-courses-error-message">{error}</div>}
                </div>
            ) : (
                <>
                    <div className="qt-courses-header">Select course</div>
                    <div className='qt-courses-actions'>
                        <div className="qt-search-box">
                            <input
                                type="text"
                                placeholder="Search for course"
                                value={searchTerm}
                                onChange={handleSearch}
                                className='qt-search-container-input'
                            />
                            <SearchSvg />
                        </div>
                        <div className='orText'>
                            Or
                        </div>
                        {!isAddingCourse ? (
                            <div className="qt-add-course-btn" onClick={() => setIsAddingCourse(true)}>
                                Add New Course <AddSvg />
                            </div>
                        ) : (
                            <div className="qt-add-course-input-container">
                                {addCourseError && (
                                    <div className="qt-add-course-error">{addCourseError}</div>
                                )}
                                <div className="qt-add-course-input-wrapper">
                                    <input
                                        ref={newCourseInputRef}
                                        type="text"
                                        className={`qt-add-course-input ${addCourseError ? "error" : ""}`}
                                        placeholder="Add new course"
                                        value={newCourseName}
                                        onChange={(e) => setNewCourseName(e.target.value)}
                                        onKeyPress={(e) => e.key === "Enter" && handleAddCourse()}
                                        onBlur={() => {
                                            if (!newCourseName.trim()) {
                                                setIsAddingCourse(false);
                                                setAddCourseError("");
                                            }
                                        }}
                                    />
                                    <button
                                        className="qt-add-course-button"
                                        onClick={handleAddCourse}
                                        disabled={!newCourseName.trim() || addCourseError}
                                    >
                                        <AddSvg />
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="qt-courses-header">
                        Existing courses ({filteredCourses.length})
                    </div>
                    <div className="qt-courses-grid">
                        {filteredCourses.map((course, index) => (
                            <div key={index} className="qt-course-box" onClick={() => {
                                setSelectedCoursesData(course);
                                handleTabChange("coursesselected");
                            }}>
                                {course.title}
                            </div>
                        ))}
                    </div>
                    {showAddCourseModal && (
                        <div className="modal">
                            <div className="modal-content">
                                <h2>Add New Course</h2>
                                <input
                                    type="text"
                                    placeholder="New course name"
                                    value={newCourseName}
                                    onChange={(e) => setNewCourseName(e.target.value)}
                                />
                                <div className="modal-buttons">
                                    <button onClick={handleAddCourse}>Add</button>
                                    <button onClick={() => setShowAddCourseModal(false)}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    )}
                    <PopUp
                        title={`${newCourseName}`}
                        onClose={handleClosePopup}
                        show={showPopup}
                    >
                        <div className="qgt-slider-container-wrapper">
                            {!csvGenerated && !showSuccessMessage && (
                                <div className="qgt-slider-container">
                                    <label htmlFor="questionCount" className="qgt-slider-label">
                                        Select no. of questions per subject
                                    </label>
                                    <div className="qgt-slider-wrapper">
                                        <div className="qgt-slider-button" onClick={handleDecrementCount}>
                                            <MinusSvg />
                                        </div>
                                        <div className="qgt-slider-input-wrapper">
                                            <input
                                                type="range"
                                                id="questionCount"
                                                className="qgt-slider"
                                                min="10"
                                                max="100"
                                                value={questionCount}
                                                onChange={(e) => setQuestionCount(parseInt(e.target.value))}
                                            />
                                            <div
                                                className="qgt-slider-value"
                                                style={{
                                                    left: `calc(${((questionCount - 10) / 90) * 100}%)`,
                                                }}
                                            >
                                                {questionCount}
                                            </div>
                                        </div>
                                        <div className="qgt-slider-button" onClick={handleIncrementCount}>
                                            <PlusSvg />
                                        </div>
                                    </div>
                                </div>
                            )}
                            {/* {isGenerating && (
                                <div className="qgt-progress-bar">
                                    <div
                                        className="qgt-progress"
                                        style={{ width: `${generateProgress}%` }}
                                    ></div>
                                </div>
                            )}
                            add loader here */}
                            {isLoadingAddCourse && (
                                <div className="qgt-loading">
                                    <Loading color="#004c73" customClass="qt-roles-loading" />
                                    <p>Generating questions...</p>
                                </div>
                            )}
                            {!csvGenerated && !generateError && !showSuccessMessage && (
                                <div className="qgt-generate-csv-button-wrapper">
                                    <button
                                        onClick={handleGenerateCSV}
                                        className="courseHistoryHeaderButton"
                                        disabled={isGenerating}
                                    >
                                        {isGenerating ? "Generating..." : "Generate CSV"}
                                    </button>
                                    {/* {isGenerating && (
                                        <div className="qgt-progress-value">{generateProgress}%</div>
                                    )} */}
                                </div>
                            )}
                            {showSuccessMessage && (
                                <div className="qgt-success-message">
                                    <div className="qgt-success-message-text">
                                        Questions for {newCourseName} generated successfully, you can
                                        download the CSV or view the details.
                                    </div>
                                    <div className="qgt-success-actions">
                                        <button
                                            onClick={handleDownloadCSV}
                                            style={{ width: "150px" }}
                                            className="courseHistoryHeaderButton"
                                        >
                                            Download CSV
                                        </button>
                                        <button
                                            onClick={() => {
                                                setShowAddCourseModal(false);
                                                setShowSuccessMessage(false);
                                                setIsGenerating(false);
                                                setGenerateProgress(0);
                                                setGenerateError(null);
                                                setCsvGenerated(false);
                                                setNewCourseName("");
                                                setAddCourseError("");
                                                setGeneratedCourseData(generatedCourseData);
                                                handleTabChange("coursesselected");
                                            }}
                                            style={{ width: "150px" }}
                                            className="courseHistoryHeaderButton"
                                        >
                                            View Details
                                        </button>
                                    </div>
                                </div>
                            )}
                            {generateError && (
                                <div className="qgt-error-actions">
                                    <p className="qgt-error-message">{generateError}</p>
                                    <button onClick={handleRetry} className="qgt-action-button">
                                        Retry
                                    </button>
                                    <button onClick={handleClosePopup} className="qgt-action-button">
                                        Go Back
                                    </button>
                                </div>
                            )}
                        </div>
                    </PopUp>
                </>
            )}
        </div>
    );
}

export default Courses;
