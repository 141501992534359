import React from "react";
import "./AdditionalKeywordsandNote.css";

const AdditionalKeywordsandNote = ({ additionalKeywords }) => {
  return (
    <div>
      <div className="addl-keywords-title">Additional Keywords :</div>
      <div className="addl-keywords-container">
        {additionalKeywords.map((keyword) => (
          <div key={keyword.id} className="keyword-bubble">
            {keyword.name}
          </div>
        ))}
      </div>
            <strong style={{ fontSize: "17.6px", marginBottom: "8px" }}>Please Note:</strong> <br />
      <div style={{ fontSize: "16px" }}>
            These suggestions are based on your resume and the role you're
            interested in. Be sure to customize your resume for the specific
            role by ensuring it highlights the most relevant skills and
            experiences.
          </div>
    </div>
  );
};

export default AdditionalKeywordsandNote;
