import React from "react";
// import "../Common.css";
import CustomPopup from "../../../../../../Components/Popup/CustomPopup";
import Input from "../../../../../../../../../Components/Profile/Input/Input";

const View = ({
    show,
    setShow,
    studentData,
}) => {
    const handleClose = () => {
        setShow(false);
    };

    return (
        <CustomPopup
            title="Student Details"
            show={show}
            onClose={handleClose}
        >
            <div className="sk-ad-asgnmt_popup-content">
                <div className="sk-ad-asgnmt_popup-content-row">
                    <label htmlFor="name" className="sk-ad-asgnmt_popup-content-row-label">
                        Name
                    </label>
                    <Input
                        width="100%"
                        name="name"
                        value={studentData.name && studentData.name}
                        isDisabled={true}
                    />
                </div>
                <div className="sk-ad-asgnmt_popup-content-row">
                    <label htmlFor="email" className="sk-ad-asgnmt_popup-content-row-label">
                        Email
                    </label>
                    <Input
                        width="100%"
                        name="email"
                        value={studentData.email && studentData.email}
                        isDisabled={true}
                    />
                </div>
                {/* <div className="sk-ad-asgnmt_popup-content-row">
                    <label htmlFor="file" className="sk-ad-asgnmt_popup-content-row-label">
                        File
                    </label>
                    {studentData.file_location_s3_url && (
                        <>
                            {studentData.file_location_s3_url.startsWith("http://") ||
                                studentData.file_location_s3_url.startsWith("https://") ? (
                                <a href={studentData.file_location_s3_url} className="sk-ad-asgnmt_popup-content-row-file" download>
                                    Download File
                                </a>
                            ) : (
                                <a href={`${process.env.REACT_APP_BACKEND_URL
                                    }${studentData.file_location_s3_url}`} className="sk-ad-asgnmt_popup-content-row-file" download>
                                    Download File
                                </a>
                            )}
                        </>
                    )}
                </div>
                <div className="sk-ad-asgnmt_popup-content-row-sp">
                    <div className="sk-ad-asgnmt_popup-content-row">
                        <label htmlFor="due_days" className="sk-ad-asgnmt_popup-content-row-label">
                            Due Days
                        </label>
                        <Input
                            inpType="number"
                            width="95%"
                            name="due_days"
                            value={studentData.due_days}
                            isDisabled={true}
                        />
                    </div>
                    <div className="sk-ad-asgnmt_popup-content-row">
                        <label htmlFor="order" className="sk-ad-asgnmt_popup-content-row-label">
                            Order
                        </label>
                        <Input
                            inpType="number"
                            width="95%"
                            name="order"
                            value={studentData.order}
                            isDisabled={true}
                        />
                    </div>
                </div> */}
            </div>
        </CustomPopup>
    );
}

export default View;