import React, { useState, useContext, useEffect } from "react";
import Scrollbars from "react-custom-scrollbars-2";
import Base64Downloader from "react-base64-downloader";
import "./AdminLeads.css";
import { useHttpClient } from "../../Components/Hooks/http-hook";
import { AdminContext } from "../../context/admin-context";
import Loading from "../../Components/Loading";

const AdminLeads = () => {
  const [registrationInfo, setRegistrationInfo] = useState([]);
  const [companyInfo, setCompanyInfo] = useState([]);
  const [eventInfo, setEventInfo] = useState([]);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const {
    isLoading: isLoading1,
    error: error1,
    sendRequest: sendRequest1,
    clearError: clearError1,
  } = useHttpClient();
  const {
    isLoading: isLoading2,
    error: error2,
    sendRequest: sendRequest2,
    clearError: clearError2,
  } = useHttpClient();
  const {
    isLoading: isLoading3,
    error: error3,
    sendRequest: sendRequest3,
    clearError: clearError3,
  } = useHttpClient();
  const admin = useContext(AdminContext);

  const getLeadsInfo = async () => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/db/student-register`,
        "GET",
        null,
        {
          "Content-Type": "application/json",
          Authorization: admin.token,
          "Refresh-Token": admin.refresh_token,
        }
      );
      if (responseData.ok) {
        return responseData.data;
      }
    } catch (err) {
      console.log("got error while getting registration info", err);
    }
  };

  const getCompanyInfo = async () => {
    try {
      const responseData = await sendRequest1(
        `${process.env.REACT_APP_BACKEND_URL}/db/company-register`,
        "GET",
        null,
        {
          "Content-Type": "application/json",
          Authorization: admin.token,
          "Refresh-Token": admin.refresh_token,
        }
      );
      if (responseData.ok) {
        return responseData.data;
      }
    } catch (err) {
      console.log("got error while getting company registration info", err);
    }
  };

  const getEventInfo = async () => {
    try {
      const responseData = await sendRequest2(
        `${process.env.REACT_APP_BACKEND_URL}/db/event-register`,
        "GET",
        null,
        {
          "Content-Type": "application/json",
          Authorization: admin.token,
          "Refresh-Token": admin.refresh_token,
        }
      );
      if (responseData.ok) {
        return responseData.data;
      }
    } catch (err) {
      console.log("got error while getting event registration info", err);
    }
  };

  const [fileDetails, setFileDetails] = useState({});
  const downloadRegistrationInfo = async () => {
    try {
      const responseData = await sendRequest3(
        `${process.env.REACT_APP_BACKEND_URL}/db/regsiter-info-download`,
        "GET",
        null,
        {
          "Content-Type": "application/json",
          Authorization: admin.token,
          "Refresh-Token": admin.refresh_token,
        }
      );
      if (responseData.ok) {
        return responseData.data;
      }
    } catch (err) {
      console.log("got error while downloading registration info", err);
    }
  };

  useEffect(() => {
    getLeadsInfo().then((response) => setRegistrationInfo(response));
    downloadRegistrationInfo().then((response1) => setFileDetails(response1));
    getCompanyInfo().then((response) => setCompanyInfo(response));
    getEventInfo().then((response) => setEventInfo(response));
  }, []);

  return (
    <div className="admin-leads">
      <div className="admin-leads-heading">
        <h2>Recent Registration Via Surge Classes Website</h2>
        <a download={fileDetails.filename} href={fileDetails.base64}>
          <button className="admin-leads-download-button">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
              <polyline points="7 10 12 15 17 10"></polyline>
              <line x1="12" y1="15" x2="12" y2="3"></line>
            </svg>
            All Course Enrollment
          </button>
        </a>
      </div>
      <h4>Course Registration Info</h4>
      {isLoading ? (
        <Loading
          color="#004C73"
          customClass="admin-leads-vertical-loading-container"
        />
      ) : (
        <div className="leads-table">
          <Scrollbars
            autoHide
            autoHideTimeout={2000}
            autoHideDuration={310}
            autoHeightMin={300}
            autoHeightMax={800}
            thumbMinSize={30}
            universal={true}
            style={{
              width: `100%`,
              height: "25rem",
              background: "white",
              borderRadius: "6px",
            }}
          >
            <table class="module-table" cellSpacing={"0px"}>
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Mobile</th>
                  <th>Course</th>
                  <th>Date</th>
                </tr>
              </thead>
              {registrationInfo &&
                registrationInfo.map((p, i) => {
                  return (
                    <tr>
                      <td>{i + 1}</td>
                      <td>{p.name}</td>
                      <td>{p.email}</td>
                      <td>{p.mobile}</td>
                      <td>{p.course_title}</td>
                      <td>{p.create_timestamp}</td>
                    </tr>
                  );
                })}
            </table>
          </Scrollbars>
        </div>
      )}
      <h4>Event Registration Info</h4>
      {isLoading1 ? (
        <Loading
          color="#004C73"
          customClass="admin-leads-vertical-loading-container"
        />
      ) : (
        <div className="leads-table">
          <Scrollbars
            autoHide
            autoHideTimeout={2000}
            autoHideDuration={310}
            autoHeightMin={300}
            autoHeightMax={800}
            thumbMinSize={30}
            universal={true}
            style={{
              width: `100%`,
              height: "25rem",
              background: "white",
              borderRadius: "6px",
            }}
          >
            <table class="module-table" cellSpacing={"0px"}>
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>User Name</th>
                  <th>Email</th>
                  <th>Mobile</th>
                  <th>Event</th>
                  <th>Date</th>
                </tr>
              </thead>
              {eventInfo &&
                eventInfo.map((p, i) => {
                  return (
                    <tr>
                      <td>{i + 1}</td>
                      <td>{p.user_name}</td>
                      <td>{p.user_email}</td>
                      <td>{p.user_mobile}</td>
                      <td>{p.event_title}</td>
                      <td>{p.create_timestamp}</td>
                    </tr>
                  );
                })}
            </table>
          </Scrollbars>
        </div>
      )}
      <h4> Company Registration Info</h4>
      {isLoading2 ? (
        <Loading
          color="#004C73"
          customClass="admin-leads-vertical-loading-container"
        />
      ) : (
        <div className="leads-table">
          <Scrollbars
            autoHide
            autoHideTimeout={2000}
            autoHideDuration={310}
            autoHeightMin={300}
            autoHeightMax={800}
            thumbMinSize={30}
            universal={true}
            style={{
              width: `100%`,
              height: "25rem",
              background: "white",
              borderRadius: "6px",
            }}
          >
            <table class="module-table" cellSpacing={"0px"}>
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Name</th>
                  <th>Company Name</th>
                  <th>Work Email</th>
                  <th>Phone</th>
                  <th>Date</th>
                </tr>
              </thead>
              {companyInfo &&
                companyInfo.map((p, i) => {
                  return (
                    <tr>
                      <td>{i + 1}</td>
                      <td>{p.name}</td>
                      <td>{p.company_name}</td>
                      <td>{p.work_email}</td>
                      <td>{p.phone}</td>
                      <td>{p.create_timestamp}</td>
                    </tr>
                  );
                })}
            </table>
          </Scrollbars>
        </div>
      )}
    </div>
  );
};

export default AdminLeads;
