import React from "react";

const WarnSvg = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99935 18.3337C5.39697 18.3337 1.66602 14.6027 1.66602 10.0003C1.66602 5.39795 5.39697 1.66699 9.99935 1.66699C14.6017 1.66699 18.3327 5.39795 18.3327 10.0003C18.3327 14.6027 14.6017 18.3337 9.99935 18.3337ZM9.16602 12.5003V14.167H10.8327V12.5003H9.16602ZM9.16602 5.83366V10.8337H10.8327V5.83366H9.16602Z"
        fill="#FA1B00"
      />
    </svg>
  );
};

export default WarnSvg;
