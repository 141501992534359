import React, { useState, useEffect, useContext } from "react";
import "./Assignments.css";
import AssignmentList from "./layouts/AssignmentList/AssignmentList";
import Assignment from "./layouts/Assignment/Assignment";
import Submissions from "./layouts/Submissions/Submissions";

const Assignments = () => {
  const [selectedTab, setSelectedTab] = useState("assignment");
  const [selectedModuleId, setSelectedModuleId] = useState(null);
  const [selectedModuleData, setSelectedModuleData] = useState(null);
  const [selectedAssignmentData, setSelectedAssignmentData] = useState(null);
  const [selectedAssignmentId, setSelectedAssignmentId] = useState(null);
  const [selectedCourseId, setSelectedCourseId] = useState(null);

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  }

  return (
    <div className="assignments">
      {selectedTab === "assignment" && <Assignment setSelectedTab={handleTabChange} setSelectedModuleId={setSelectedModuleId} setSelectedModuleData={setSelectedModuleData} setSelectedCourseId={setSelectedCourseId} />}
      {selectedTab === "assignments-list" && <AssignmentList setSelectedTab={handleTabChange} selectedModuleData={selectedModuleData} moduleId={selectedModuleId} setSelectedAssignmentId={setSelectedAssignmentId} setSelectedAssignmentData={setSelectedAssignmentData} />}
      {selectedTab === "submissions" && <Submissions setSelectedTab={handleTabChange} assignmentId={selectedAssignmentId} selectedAssignmentData={selectedAssignmentData} courseId={selectedCourseId} />}
    </div>
  );
};

export default Assignments;
