import React, { useState, useContext, useEffect } from "react";
import "./Project.css";
import Button from "../../../../Components/Profile/Button/Button";
import ProjectForm from "../forms/ProjectForm";
import { useHttpClient } from "../../../../Components/Hooks/http-hook";
import { AuthContext } from "../../../../context/auth-context";
import Loading from "../../../../Components/Loading";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import Delete from "../Popups/Delete";
import { ProjectType } from "../Data/SelectData";

const Project = () => {
  const auth = useContext(AuthContext);
  const { sendRequest, error: requestError } = useHttpClient();
  const [projectList, setProjectList] = useState([]);
  const [pageLoading, setPageLoading] = useState(true);
  const [showAddProjectPopup, setShowAddProjectPopup] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [deleteProjectId, setDeleteProjectId] = useState(null);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [editProjectData, setEditProjectData] = useState(null);

  const showDeleteConfirmation = (id) => {
    setDeleteProjectId(id);
    setShowDeletePopup(true);
  };

  const closeDeleteConfirmation = () => {
    setDeleteProjectId(null);
    setShowDeletePopup(false);
  };

  const showEditProjectPopup = (data) => {
    setEditProjectData(data);
    setShowEditPopup(true);
  };

  const changeProjectDateFormat = (date) => {
    const dateArray = date.split("-");
    const year = dateArray[0];
    const month = dateArray[1];
    const day = dateArray[2];
    const newDate = moment(`${year}-${month}-${day}`).format("DD MMM YYYY");
    return newDate;
  };

  const changeTimestampToDaysAgo = (timestamp) => {
    const date = moment(timestamp);
    const currentDate = moment();
    const daysAgo = currentDate.diff(date, "days");
    if (daysAgo === 0) {
      return "Updated today";
    } else if (daysAgo > 30) {
      const monthsAgo = currentDate.diff(date, "months");
      return `${monthsAgo} months ago`;
    } else {
      return `${daysAgo} days ago`;
    }
  };

  const getLabelofProjectType = (value) => {
    let label = "";
    ProjectType.forEach((item) => {
      if (item.value === value) {
        label = item.label;
      }
    });
    return label;
  };

  const deleteProject = async (id) => {
    const responseData = await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/profile/project/${id}`,
      "DELETE",
      null,
      {
        "Content-Type": "application/json",
        "Refresh-Token": auth.refresh_token,
        Authorization: auth.token,
      }
    );
    if (responseData && responseData.message) {
      toast(responseData.message, {
        autoClose: 3000,
        type: toast.TYPE.SUCCESS,
      });
      setShowDeletePopup(false);
      const updatedProjectList = projectList.filter(
        (project) => project.id !== id
      );
      setProjectList(updatedProjectList);
    }
  };

  const addProject = async (projectDetails) => {
    const responseData = await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/profile/project`,
      "POST",
      JSON.stringify(projectDetails),
      {
        "Content-Type": "application/json",
        "Refresh-Token": auth.refresh_token,
        Authorization: auth.token,
      }
    );
    if (responseData && responseData.message) {
      toast(responseData.message, {
        autoClose: 3000,
        type: toast.TYPE.SUCCESS,
      });
      setShowAddProjectPopup(false);
      const updatedProjectList = [...projectList];
      updatedProjectList.unshift({
        ...responseData.data,
        created_at: "Just now",
      });
      setProjectList(updatedProjectList);
    }
  };

  const editProject = async (projectDetails) => {
    const responseData = await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/profile/project/${projectDetails.id}`,
      "PUT",
      JSON.stringify(projectDetails),
      {
        "Content-Type": "application/json",
        "Refresh-Token": auth.refresh_token,
        Authorization: auth.token,
      }
    );
    if (responseData && responseData.message) {
      toast(responseData.message, {
        autoClose: 3000,
        type: toast.TYPE.SUCCESS,
      });
      setShowEditPopup(false);
      const updatedProjectList = projectList.map((project) =>
        project.id === projectDetails.id ? { ...responseData.data, created_at: "Just now" } : project
      );
      setProjectList(updatedProjectList);
    }
  };

  useEffect(() => {
    const getProjectDetails = async () => {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/profile/projects`,
        "GET",
        null,
        {
          "Content-Type": "application/json",
          "Refresh-Token": auth.refresh_token,
          Authorization: auth.token,
        }
      );
      if (responseData && responseData.data) {
        setProjectList(
          responseData.data.map((item) => ({
            ...item,
            created_at: changeTimestampToDaysAgo(item.update_timestamp),
          }))
        );
      }
      setPageLoading(false);
    };
    getProjectDetails();
  }, []);

  useEffect(() => {
    if (requestError) {
      toast(requestError, {
        autoClose: 3000,
        type: toast.TYPE.ERROR,
      });
      setPageLoading(false);
    }
  }, [requestError]);

  return (
    <div className="profile-project-page">
      <div className="profile-project-header">
        <div className="profile-project-header-title">
          My Profile - Project Details
        </div>
        {projectList && projectList.length > 0 && (
          <Button
            text="Add New"
            width="100px"
            onClick={() => setShowAddProjectPopup(true)}
          />
        )}
      </div>
      {pageLoading ? (
        <div className="profile-loader-container">
          <div className="profile-loader-sub-container">
            <Loading
              color="#14144e"
              customClass="profile-loader-container-for-loader"
            />
          </div>
        </div>
      ) : (
        <>
          <ProjectForm
            title="Add Project Details"
            onClose={() => {
              setShowAddProjectPopup(false);
            }}
            show={showAddProjectPopup}
            HandleSubmitFunction={addProject}
            setShowAddProjectPopup={setShowAddProjectPopup}
          />
          <ToastContainer closeButton={false} />
          {projectList && projectList.length > 0 ? (
            <div className="profile-project-section-1">
              {projectList.map((project) => (
                <div className="profile-project-card" key={project.id}>
                  <div className="profile-project-card-header">
                    <div className="profile-project-card-header-section-1">
                      <div className="profile-project-card-header-title">
                        {project.title}
                      </div>
                    </div>
                    <div className="profile-project-card-header-section-2">
                      <div className="profile-project-card-body-title-1">
                        {project.created_at}
                      </div>
                    </div>
                  </div>
                  <div className="profile-project-card-body">
                    <div className="profile-project-card-body-container-1">
                      <div className="profile-project-card-body-container-1-section-1">
                        <div className="profile-project-card-body-title">
                          Start Date
                        </div>
                        <div className="profile-project-card-body-data-value">
                          {project.start_date ? changeProjectDateFormat(project.start_date) : "N/A"}
                        </div>
                      </div>
                      <div className="profile-project-card-body-container-1-section-2">
                        <div className="profile-project-card-body-title">
                          End Date
                        </div>
                        <div className="profile-project-card-body-data-value">
                          {project.end_date ? (project.end_date === "Present" ? "Present" : changeProjectDateFormat(project.end_date)) : "N/A"}
                        </div>
                      </div>
                      <div className="profile-project-card-body-container-1-section-3">
                        <div className="profile-project-card-body-title">
                          Type
                        </div>
                        <div className="profile-project-card-body-data-value">
                          {project.type ? getLabelofProjectType(project.type) : "N/A"}
                        </div>
                      </div>
                    </div>
                    <div className="profile-project-card-body-section-1">
                      <div className="profile-project-card-body-title">
                        Description
                      </div>
                      <div className="profile-project-card-body-data-value">
                        {project.description}
                      </div>
                    </div>
                    <div className="profile-project-card-body-section-2">
                      <div className="profile-project-card-body-title">
                        Project Link
                      </div>
                      <div className="profile-project-card-body-data-value profile-project-card-body-link">
                        <a
                          href={project.project_url}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {project.project_url}
                        </a>
                      </div>
                    </div>
                    <div className="profile-project-card-body-section-3">
                      <div className="profile-project-card-body-title">
                        Skills Used
                      </div>
                      <div className="profile-project-card-body-data-value">
                        {project.skills}
                      </div>
                    </div>
                  </div>
                  <div className="profile-project-card-footer">
                    <div
                      className="profile-project-card-edit"
                      onClick={() => showEditProjectPopup(project)}
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.41421 15.8895L16.5563 5.74738L15.1421 4.33316L5 14.4753V15.8895H6.41421ZM7.24264 17.8895H3V13.6468L14.435 2.21184C14.8256 1.82132 15.4587 1.82132 15.8492 2.21184L18.6777 5.04027C19.0682 5.43079 19.0682 6.06395 18.6777 6.45447L7.24264 17.8895ZM3 19.8895H21V21.8895H3V19.8895Z"
                          fill="#004C73"
                        />
                      </svg>
                      <div className="profile-project-card-edit-title">
                        Edit Details
                      </div>
                    </div>
                    <div
                      className="profile-project-card-delete"
                      onClick={() => showDeleteConfirmation(project.id)}
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7 4V2H17V4H22V6H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V6H2V4H7ZM6 6V20H18V6H6ZM9 9H11V17H9V9ZM13 9H15V17H13V9Z"
                          fill="#004C73"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="profile-project-section-2">
              <div className="profile-project-add-new">
                <div className="profile-project-add-new-title">
                  Add project details
                </div>
                <div className="profile-project-add-new-subtitle">
                  Projects you have worked on before
                </div>
                <Button
                  text="Add New"
                  width="100px"
                  onClick={() => setShowAddProjectPopup(true)}
                />
              </div>
            </div>
          )}
        </>
      )}
      <ProjectForm
        title="Edit Project Details"
        onClose={() => {
          setShowEditPopup(false);
        }}
        show={showEditPopup}
        HandleSubmitFunction={editProject}
        setShowAddProjectPopup={setShowEditPopup}
        editProjectData={editProjectData}
        isEdit={true}
      />
      <Delete
        show={showDeletePopup}
        text="Are you sure you want to delete this project?"
        onClose={closeDeleteConfirmation}
        onDelete={() => deleteProject(deleteProjectId)}
      />
    </div>
  );
};

export default Project;
