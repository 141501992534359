import React from "react";
import { useState, useEffect } from "react";
import Input from "../../../../../Components/Profile/Input/Input";
import TextArea from "../../../../../Components/Profile/Input/TextArea";
import Button from "../../../../../Components/Profile/Button/Button";
import DatePicker from "../../../../../Components/Profile/DatePicker/DatePicker";
import Popup from "../../../../../Components/Profile/Popup/PopUp";
import Loading from "../../../../../Components/Loading";

const POS = ({
  type,
  title,
  HandleSubmitFunction,
  onClose,
  show,
  isEdit = false,
  editAchievementData,
}) => {
  const [POSDetails, setPOSDetails] = useState({
    title: "",
    description: "",
    date: "",
    location: "",
    skills: "",
  });

  const [errors, setErrors] = useState({
    title: false,
    description: false,
    date: false,
    location: false,
    skills: false,
  });

  const [isFormValid, setIsFormValid] = useState(false);
  const [isFormFieldsEmpty, setIsFormFieldsEmpty] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isEdit) {
      setPOSDetails({ ...editAchievementData });
    }
  }, [isEdit, editAchievementData]);

  const validateInput = (name, value) => {
    switch (name) {
      case "description":
        return value.length <= 500;
      case "title":
        return value.length <= 100;
      case "skills":
        return value.length <= 100;
      default:
        return true;
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const isValid = validateInput(name, value);
    setPOSDetails({ ...POSDetails, [name]: value });
    setErrors((prevErrors) => ({ ...prevErrors, [name]: !isValid }));
  };

  const handleDateChange = (date, name) => {
    setPOSDetails({ ...POSDetails, [name]: date });
    const isValid = date !== null;
    setErrors((prevErrors) => ({ ...prevErrors, [name]: !isValid }));
  };

  const validateForm = () => {
    const isFormValid = Object.values(errors).every((x) => x === false);
    setIsFormValid(isFormValid);
  };

  const validateFormField = () => {
    const isFormFieldEmpty = Object.values(POSDetails).some((x) => x === "");
    const hasErrors = Object.values(errors).some((x) => x === true);
    setIsFormFieldsEmpty(isFormFieldEmpty || hasErrors);
  };

  useEffect(() => {
    validateFormField();
  }, [POSDetails, errors]);

  const submitForm = async (e) => {
    setLoading(true);
    validateForm();
    e.preventDefault();
    try {
      POSDetails.type = type;
      await HandleSubmitFunction(POSDetails);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Popup title={title} onClose={onClose} show={show}>
      <div className="profile-exp-form">
        <div className="profile-exp-form-section">
          <Input
            width="100%"
            name="title"
            label="Title"
            inputPlaceholderValue="Enter Title"
            value={POSDetails.title !== "" ? POSDetails.title : ""}
            onChangeFunction={handleInputChange}
            inperror={errors.title}
          />
        </div>
        <TextArea
          width="100%"
          name="description"
          label="Description"
          inputPlaceholderValue="About"
          value={POSDetails.description !== "" ? POSDetails.description : ""}
          onChangeFunction={handleInputChange}
          inperror={errors.description}
        />
        <div className="profile-exp-form-section">
          <div className="profile-form-section-date-container">
            <DatePicker
              width="100%"
              name="date"
              label="Date"
              inputPlaceholderValue="Enter Date"
              defaultValue={
                POSDetails.date ? new Date(POSDetails.date) : ""
              }
              onChangeFunction={(e) => handleDateChange(e, "date")}
              inperror={errors.date}
            />
          </div>
          <Input
            width="90%"
            name="location"
            label="Location"
            inputPlaceholderValue="Enter Location"
            value={POSDetails.location !== "" ? POSDetails.location : ""}
            onChangeFunction={handleInputChange}
            inperror={errors.location}
          />
        </div>
        <div className="profile-exp-form-section">
          <Input
            width="100%"
            name="skills"
            label="Key Skills"
            inputPlaceholderValue="Enter key skills"
            value={POSDetails.skills !== "" ? POSDetails.skills : ""}
            onChangeFunction={handleInputChange}
            inperror={errors.skills}
          />
        </div>
        <div className="profile-exp-form-section-button">
          {loading ? (
            <div className="profile-delete-loading-container">
              <Loading color="#14144e" />
            </div>
          ) : (
            <Button
              text="SAVE"
              onClick={isFormFieldsEmpty ? null : submitForm}
              disabled={isFormFieldsEmpty}
            />
          )}
        </div>
      </div>
    </Popup>
  );
};

export default POS;
