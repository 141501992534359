import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import "./Enroll.css";
import { AuthContext } from "../../context/auth-context";
import Button from "../../Components/Input/Button";
import Input from "../../Components/Input/Input";
import LoadingSpinner from "../../Components/Input/LoadingSpinner";
import {
  VALIDATOR_EMAIL,
  VALIDATOR_MINLENGTH,
  VALIDATOR_MAXLENGTH,
  VALIDATOR_REQUIRE,
  VALIDATOR_MOBILE,
} from "../../Components/Input/validators";
import useForm from "../../Components/Hooks/formhooks";
import { useHttpClient } from "../../Components/Hooks/http-hook";
const swal = require("sweetalert2");

const Enroll = () => {
  const auth = useContext(AuthContext);
  const history = useNavigate();
  const [isLoginMode, setIsLoginMode] = useState(true);
  const [formState, inputHandler, setFormData] = useForm({
    inputs: {
      name: {
        value: "",
        isValid: false,
      },
      email: {
        value: "",
        isValid: false,
      },
      mobile: {
        value: "",
        isValid: false,
      },
      college_name: {
        value: "",
        isValid: false,
      },
      college_city: {
        value: "",
        isValid: false,
      },
      branch: {
        value: "",
        isValid: false,
      },
      year: {
        value: "",
        isValid: false,
      },
      password: {
        value: "",
        isValid: false,
      },
    },
    isValid: false,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const submitHandler = async (event) => {
    event.preventDefault();

    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/auth/signup`,
        "POST",
        JSON.stringify({
          name: formState.inputs.name.value,
          email: formState.inputs.email.value,
          mobile: formState.inputs.mobile.value,
          college_name: formState.inputs.college_name.value,
          college_city: formState.inputs.college_city.value,
          branch: formState.inputs.branch.value,
          year: formState.inputs.year.value,
          password: formState.inputs.password.value,
        }),
        {
          "Content-Type": "application/json",
        }
      );

      auth.login(
        responseData.data.localId,
        responseData.data.displayName,
        responseData.data.email,
        "",
        "1234567890",
        responseData.data.idToken,
        responseData.data.refreshToken,
        responseData.data.role,
        responseData.data.course_ids,
        "",
        responseData.data.surge_id
      );
      history("/skillbox-v2");
    } catch (err) {
      console.log(err);
    }
  };

  const loginHandler = () => {
    history("/");
  };

  const passwordRHandler = () => {
    swal.fire({
      title:
        "Password Reset is not available for now. Please contact admin to reset your password.",
      icon: "warning",
      confirmButtonText: "OK",
    });
  };

  useEffect(() => {
    if (error) {
      swal.fire({
        title: "Error!",
        text: error || "Something went wrong, please try again later.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
    clearError();
  }, [error, clearError]);

  return (
    <React.Fragment>
      {isLoading && <LoadingSpinner asOverlay />}
      <div className="signup-authentication-container">
        {!isLoading && (
          <div className="signup-authentication">
            {/* <hr /> */}
            <form onSubmit={submitHandler}>
              <div className="signup-auth-form">
                <div className="signup-auth-box">
                  <h2 className="signup-title">Machine Learning Fundamentals Enrollment</h2>
                  <p className="signup-subtitle">
                    Enter your details to create your skillbox account.
                  </p>
                  <div className="signup-form-row-container">
                    <div className="signup-form-class1">
                      <Input
                        type="text"
                        label="Name"
                        placeholder="Enter Name (John Doe)"
                        id="name"
                        value={formState.inputs.name.value}
                        isValid={formState.inputs.name.isValid}
                        validators={[VALIDATOR_REQUIRE(), VALIDATOR_MAXLENGTH(250)]}
                        element="input"
                        onInput={inputHandler}
                      />
                    </div>
                    <div className="signup-form-class1">
                      <Input
                        label="Email"
                        type="email"
                        placeholder="Enter Email Address (abc@mail.com)"
                        id="email"
                        value={formState.inputs.email.value}
                        isValid={formState.inputs.email.isValid}
                        validators={[VALIDATOR_EMAIL()]}
                        element="input"
                        onInput={inputHandler}
                      />
                    </div>
                  </div>
                  <div className="signup-form-row-container">
                    <div className="signup-form-class1">
                      <Input
                        label="Mobile"
                        type="text"
                        placeholder="Enter Phone Number (+911234567890)"
                        id="mobile"
                        value={formState.inputs.mobile.value}
                        isValid={formState.inputs.mobile.isValid}
                        validators={[VALIDATOR_MOBILE()]}
                        element="input"
                        onInput={inputHandler}
                      />
                    </div>
                    <div className="signup-form-class1">
                      <Input
                        label="College Name"
                        type="text"
                        placeholder="Enter College Name (ABC College)"
                        id="college_name"
                        value={formState.inputs.college_name.value}
                        isValid={formState.inputs.college_name.isValid}
                        validators={[VALIDATOR_REQUIRE(), VALIDATOR_MAXLENGTH(250)]}
                        element="input"
                        onInput={inputHandler}
                      />
                    </div>
                  </div>
                  <div className="signup-form-row-container">
                    <div className="signup-form-class1">
                      <Input
                        label="College City"
                        type="text"
                        placeholder="Enter College City (Mumbai)"
                        id="college_city"
                        value={formState.inputs.college_city.value}
                        isValid={formState.inputs.college_city.isValid}
                        validators={[VALIDATOR_REQUIRE(), VALIDATOR_MAXLENGTH(250)]}
                        element="input"
                        onInput={inputHandler}
                      />
                    </div>
                    <div className="signup-form-class1">
                      <Input
                        label="Branch"
                        type="text"
                        placeholder="Enter Branch (Computer Science)"
                        id="branch"
                        value={formState.inputs.branch.value}
                        isValid={formState.inputs.branch.isValid}
                        validators={[VALIDATOR_REQUIRE(), VALIDATOR_MAXLENGTH(250)]}
                        element="input"
                        onInput={inputHandler}
                      />
                    </div>
                  </div>
                  <div className="signup-form-row-container">
                    <div className="signup-form-class1">
                      <Input
                        label="Year"
                        type="text"
                        placeholder="Enter Year (First Year)"
                        id="year"
                        value={formState.inputs.year.value}
                        isValid={formState.inputs.year.isValid}
                        validators={[VALIDATOR_REQUIRE(), VALIDATOR_MAXLENGTH(250)]}
                        element="input"
                        onInput={inputHandler}
                      />
                    </div>

                    <div className="signup-form-class1">
                      <Input
                        label="Password"
                        className="password-input"
                        type="password"
                        placeholder="Password (Min. 6 characters)"
                        id="password"
                        validators={[VALIDATOR_MINLENGTH(6)]}
                        element="input"
                        onInput={inputHandler}
                        value={formState.inputs.password.value}
                        isValid={formState.inputs.password.isValid}
                      />
                    </div>
                  </div>
                  <Button type="submit" disabled={!formState.isValid}>
                    Sign Up
                  </Button>
                </div>
              </div>
            </form>
          </div>
        )}
        <div className="signup-Buttons">
          <Button onClick={() => loginHandler()}>Login</Button>
          <Button onClick={passwordRHandler}>Password Reset</Button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Enroll;
