import React from "react";
import { useState, useEffect } from "react";
import Input from "../../../../../Components/Profile/Input/Input";
import Button from "../../../../../Components/Profile/Button/Button";
import { aggregateType } from "../../Data/SelectData";
import Popup from "../../../../../Components/Profile/Popup/PopUp";
import Loading from "../../../../../Components/Loading";

const Masters = ({
  type,
  title,
  HandleSubmitFunction,
  onClose,
  show,
  isEdit = false,
  editEducationData,
}) => {
  const [mastersDetails, setMastersDetails] = useState({
    institution: "",
    degree: "",
    year_of_completion: "",
    stream: "",
    marks_type: "",
    marks_obtained: "",
    max_marks: "",
  });

  const [errors, setErrors] = useState({
    institution: false,
    degree: false,
    year_of_completion: false,
    stream: false,
    marks_type: false,
    marks_obtained: false,
    max_marks: false,
  });

  const [isFormValid, setIsFormValid] = useState(false);
  const [isFormFieldsEmpty, setIsFormFieldsEmpty] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isEdit) {
      setMastersDetails({ ...editEducationData });
    }
  }, [isEdit, editEducationData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setMastersDetails({ ...mastersDetails, [name]: value });
    setErrors({ ...errors, [name]: false });
  };

  const handleSelectChange = (selectedOption) => {
    setMastersDetails({
      ...mastersDetails,
      marks_type: selectedOption.value,
    });
    setErrors({ ...errors, marks_type: false });
  };

  const validateForm = () => {
    const isFormValid = Object.values(errors).every((x) => x === false);
    setIsFormValid(isFormValid);
  };

  const validateFormField = () => {
    const isFormFieldEmpty = Object.values(mastersDetails).some(
      (x) => x === ""
    );
    const hasErrors = Object.values(errors).some((x) => x === true);
    setIsFormFieldsEmpty(isFormFieldEmpty || hasErrors);
  };

  useEffect(() => {
    validateFormField();
  }, [mastersDetails, errors]);

  const submitForm = async (e) => {
    setLoading(true);
    validateForm();
    e.preventDefault();
    try {
      mastersDetails.type = type;
      await HandleSubmitFunction(mastersDetails);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Popup title={title} onClose={onClose} show={show}>
      <div className="profile-education-form">
        <div className="profile-education-form-section">
          <Input
            width="100%"
            name="institution"
            label="College Name"
            inputPlaceholderValue="Enter your institution name"
            value={mastersDetails.institution !== "" ? mastersDetails.institution : ""}
            onChangeFunction={handleInputChange}
          />
        </div>
        <div className="profile-education-form-section">
          <Input
            width="90%"
            name="degree"
            label="Degree"
            inputPlaceholderValue="Enter your degree"
            value={mastersDetails.board !== "" ? mastersDetails.board : ""}
            onChangeFunction={handleInputChange}
          />
          <Input
            width="90%"
            name="year_of_completion"
            label="Completion Year"
            inputPlaceholderValue="Enter your completion year"
            value={mastersDetails.year_of_completion !== "" ? mastersDetails.year_of_completion : ""}
            onChangeFunction={handleInputChange}
          />
        </div>
        <div className="profile-education-form-section">
          <Input
            width="100%"
            name="stream"
            label="Stream"
            inputPlaceholderValue="Enter your stream"
            value={mastersDetails.stream !== "" ? mastersDetails.stream : ""}
            onChangeFunction={handleInputChange}
          />
        </div>
        <div className="profile-education-form-section">
          <Input
            width="60%"
            label="Marks Type"
            isSelect={true}
            selectData={aggregateType}
            selectLabel="Marks Type"
            onChangeFunction={handleSelectChange}
            isNoInputinSelect={true}
            selectvalue={mastersDetails.marks_type !== "" ? mastersDetails.marks_type : ""}
          />
          <Input
            width="82%"
            name="marks_obtained"
            label="Aggregate"
            inputPlaceholderValue="Enter your marks"
            value={mastersDetails.marks_obtained !== "" ? mastersDetails.marks_obtained : ""}
            onChangeFunction={handleInputChange}
          />
          <Input
            width="82%"
            name="max_marks"
            label="Max Marks"
            inputPlaceholderValue="Enter your max marks"
            value={mastersDetails.max_marks !== "" ? mastersDetails.max_marks : ""}
            onChangeFunction={handleInputChange}
          />
        </div>
        <div className="profile-education-form-section-button">
          {loading ? (
            <div className="profile-delete-loading-container">
              <Loading color="#14144e" />
            </div>
          ) : (
            <Button
              text="SAVE"
              onClick={isFormFieldsEmpty ? null : submitForm}
              disabled={isFormFieldsEmpty}
            />
          )}
        </div>
      </div>
    </Popup>
  );
};

export default Masters;
