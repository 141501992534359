import React, { useState } from "react";
import "../PAC.css";

const SavedResume = ({ selectedFiles, onItemClick, handleFileDelete, noDelete }) => {

  const [selectedItem, setSelectedItem] = useState(null);

  const list = selectedFiles;

  const handleItemClick = (index) => {
    setSelectedItem(index);
    onItemClick(list[index]);
    // return list[index];
    // console.log(list[index]);
  };


  return selectedFiles.length > 0 ? (
    <div className="resume-list">
      <ul>
        {list.map((fileName, index) => (
          <div className={`resume-list-container gray-border ${selectedItem === index ? 'selected' : ''}`}
            key={index}
            onClick={() => handleItemClick(index)}
          >
            <li key={fileName}>{fileName}</li>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <p>Uploaded on {`21/01/2022`}</p>

              {/* View icon */}
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path className="resume-list-view-icon"
                  d="M1.18164 12C2.12215 6.87976 6.60812 3 12.0003 3C17.3924 3 21.8784 6.87976 22.8189 12C21.8784 17.1202 17.3924 21 12.0003 21C6.60812 21 2.12215 17.1202 1.18164 12ZM12.0003 17C14.7617 17 17.0003 14.7614 17.0003 12C17.0003 9.23858 14.7617 7 12.0003 7C9.23884 7 7.00026 9.23858 7.00026 12C7.00026 14.7614 9.23884 17 12.0003 17ZM12.0003 15C10.3434 15 9.00026 13.6569 9.00026 12C9.00026 10.3431 10.3434 9 12.0003 9C13.6571 9 15.0003 10.3431 15.0003 12C15.0003 13.6569 13.6571 15 12.0003 15Z"
                  fill="black"
                />
              </svg>

              {/* Delete icon */}
              {!noDelete && (
                <svg
                  onClick={() => handleFileDelete(fileName)}
                  style={{ cursor: "pointer" }}
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  {/* <circle cx="8" cy="8" r="8" fill="#FFF3F3" /> */}
                  <path
                    d="M11.4769 5.14517H10.4774C10.464 5.14054 10.4501 5.13753 10.436 5.13614H9.37174V4.25348C9.3718 4.18714 9.3458 4.12346 9.29945 4.07608C9.25304 4.02871 9.18995 4.00138 9.12369 4H6.60672C6.53948 4 6.47501 4.02667 6.42752 4.07422C6.37997 4.12177 6.35324 4.18624 6.35324 4.25348V5.13614H5.29439C5.2803 5.13753 5.2664 5.14054 5.25303 5.14517H4.25347C4.16288 5.14517 4.07921 5.19345 4.03395 5.27188C3.98868 5.35031 3.98868 5.44691 4.03395 5.52535C4.07921 5.60378 4.16288 5.65211 4.25347 5.65211H5.06783L5.72585 11.7735C5.7329 11.8358 5.76263 11.8933 5.8094 11.9351C5.85611 11.9769 5.91661 11.9999 5.97932 12H9.75462C9.81668 11.9991 9.87627 11.9756 9.92233 11.9339C9.96831 11.8922 9.99751 11.8352 10.0045 11.7735L10.6625 5.66105H11.4769C11.5675 5.66105 11.6511 5.61277 11.6964 5.53434C11.7417 5.45591 11.7417 5.35924 11.6964 5.28087C11.6511 5.20244 11.5675 5.1541 11.4769 5.1541L11.4769 5.14517ZM6.86566 4.50695H8.86472V5.13614H6.86566V4.50695ZM9.52455 11.4894H6.20582L5.57663 5.66111H10.1537L9.52455 11.4894Z"
                    fill="#E41D1D"
                  />
                  <path
                    d="M7.8648 10.8967C7.93203 10.8967 7.9965 10.87 8.04405 10.8225C8.09161 10.7749 8.11827 10.7104 8.11827 10.6432V6.5084C8.11827 6.41781 8.07 6.33413 7.99157 6.28888C7.91314 6.24355 7.81647 6.24355 7.73804 6.28888C7.6596 6.33414 7.61133 6.41781 7.61133 6.5084V10.6432C7.61133 10.7104 7.63799 10.7749 7.68555 10.8225C7.7331 10.87 7.79756 10.8967 7.8648 10.8967H7.8648Z"
                    fill="#E41D1D"
                  />
                  <path
                    d="M6.8674 10.8944H6.87817C6.94511 10.8917 7.00819 10.8625 7.0537 10.8133C7.09915 10.7641 7.12334 10.6989 7.12088 10.632L6.96805 6.49719L6.96811 6.49713C6.96672 6.42941 6.93777 6.36512 6.88787 6.31926C6.83791 6.27345 6.77146 6.25004 6.7038 6.25443C6.63687 6.25726 6.57379 6.28645 6.52834 6.33563C6.48283 6.38481 6.45869 6.45 6.4611 6.51694L6.61393 10.6517C6.61712 10.7169 6.64517 10.7784 6.69236 10.8236C6.73949 10.8687 6.80215 10.8941 6.8674 10.8944L6.8674 10.8944Z"
                    fill="#E41D1D"
                  />
                  <path
                    d="M8.85421 10.8941H8.86318C8.92999 10.8956 8.9947 10.8708 9.04345 10.8251C9.09215 10.7794 9.12104 10.7163 9.12387 10.6496L9.2767 6.51477H9.27664C9.27911 6.44783 9.25491 6.38265 9.20946 6.33346C9.16396 6.28429 9.10088 6.25509 9.03394 6.25226C8.9655 6.24522 8.89724 6.26731 8.84589 6.31318C8.79455 6.35899 8.76493 6.42435 8.76427 6.49322L8.60964 10.628H8.6097C8.60627 10.6959 8.63022 10.7623 8.67621 10.8123C8.72214 10.8623 8.7863 10.8918 8.85421 10.8941L8.85421 10.8941Z"
                    fill="#E41D1D"
                  />
                </svg>
              )}
            </div>
          </div>
        ))}
      </ul>
    </div>
  ) : (
    <button
      className="resume-upload-button"
      style={{ width: "200px" }}
      onClick={() => window.location.reload()}
    >
      Add New
    </button>
  );
};

export default SavedResume;
