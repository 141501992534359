import React from "react";
import * as FaIcons from "react-icons/fa";
import Resume from "../Pages/Resume";
import GenerateResume from "../Pages/GenerateResume";

export const ResumeSideBarData = [
    {
      title: "Upload Resume",
      icon: <FaIcons.FaFileUpload />,
      component: <Resume />,
    },
    {
      title: "Generate Resume",
      icon: <FaIcons.FaFileExport />,
      component: <GenerateResume />,
    },
  ];