import React from "react";
import "./PathwayCard.css";

const PathwayCard = ({ program, handleClick }) => {
    return (
        <div className="st-skillbox-pathway-card" key={program.section_id}>
            <div className="st-skillbox-pathway-card__title">
                {program.section_name}
            </div>
            {/* <div className="st-skillbox-pathway-card__tag">
                <Badge
                    {...{[program.tag]: true }}
                />
            </div> */}
            <div className="st-skillbox-pathway-card_smp-text">
                <span>{program.module_count}{" "}</span>Modules <br />
            </div>
            
            <button className="st-skillbox-pathway-card__button" onClick={() => handleClick(program.section_id)}>
                View Modules
            </button>
        </div>
    );
}

export default PathwayCard;