import React from "react";

const RewardIconSvg = () => {
  return (
    <svg
      width="71"
      height="71"
      viewBox="0 0 71 71"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M44.3894 5.9248C50.9246 5.9248 56.2227 11.2228 56.2227 17.7581C56.2227 19.9149 55.6455 21.9368 54.6376 23.6781L68.056 23.6748V29.5914H62.1394V59.1748C62.1394 60.8087 60.8149 62.1331 59.181 62.1331H11.8477C10.2138 62.1331 8.88932 60.8087 8.88932 59.1748V29.5914H2.97266V23.6748L16.391 23.6781C15.383 21.9368 14.806 19.9149 14.806 17.7581C14.806 11.2228 20.104 5.9248 26.6393 5.9248C30.1752 5.9248 33.3489 7.47559 35.517 9.93411C37.6799 7.47559 40.8536 5.9248 44.3894 5.9248ZM38.4727 29.5914H32.556V59.1748H38.4727V29.5914ZM26.6393 11.8415C23.3716 11.8415 20.7227 14.4905 20.7227 17.7581C20.7227 20.8773 23.1363 23.4327 26.1978 23.6586L26.6393 23.6748H32.556V17.7581C32.556 14.7949 30.3778 12.3405 27.535 11.9088L27.0809 11.8577L26.6393 11.8415ZM44.3894 11.8415C41.2701 11.8415 38.7147 14.2551 38.489 17.3166L38.4727 17.7581V23.6748H44.3894C47.5084 23.6748 50.0638 21.2612 50.2898 18.1997L50.306 17.7581C50.306 14.4905 47.6572 11.8415 44.3894 11.8415Z"
        fill="#004C73"
      />
    </svg>
  );
};

export default RewardIconSvg;
