import React, { useContext, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./Module.css";
import { useHttpClient } from '../../../../../../../../../Components/Hooks/http-hook';
import { AuthContext } from '../../../../../../../../../context/auth-context';
import ClassCard from "./Components/Cards/ClassCard";
import Button from "../../../../../../../../../Components/Profile/Button/Button";
import Loading from "../../../../../../../../../Components/Loading";

const Module = ({ moduleId }) => {

  const navigate = useNavigate();
  const { cid, ct, pid } = useParams();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  const [pathway, setPathway] = useState({});
  const [classes, setClasses] = useState([]);
  const [module, setModule] = useState({});

  const fetchPathway = async () => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/db/section/${pid}`,
        "GET",
        null,
        {
          "Content-Type": "application/json",
          "Refresh-Token": auth.refresh_token,
          Authorization: auth.token,
        }
      );
      setPathway(responseData.data);
    }
    catch (err) {
      console.log(err)
    }
  }

  const fetchModule = async () => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/db/module/${moduleId}`,
        "GET",
        null,
        {
          "Content-Type": "application/json",
          "Refresh-Token": auth.refresh_token,
          Authorization: auth.token,
        }
      );
      if (responseData.data) {
        setModule(responseData.data);
      }
    }
    catch (err) {
      console.log(err)
    }
  }

  const fetchClasses = async () => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/db/classes/${moduleId}`,
        "GET",
        null,
        {
          "Content-Type": "application/json",
          "Refresh-Token": auth.refresh_token,
          Authorization: auth.token,
        }
      );
      if (responseData.data && responseData.data.length > 0) {
        setClasses(responseData.data);
      }
    }
    catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    fetchPathway();
  }, []);

  useEffect(() => {
    fetchClasses();
    fetchModule();
  }, [moduleId]);

  const goToHome = () => {
    navigate("/skillbox-v2");
  };

  const goToPathWay = () => {
    navigate(`/skillbox-v2/${cid}/${ct}`);
  }

  const goToClass = (class_id) => {
    navigate(`/skillbox-v2/${cid}/${ct}/pathway/${pid}/modules/${moduleId}/classes/${class_id}`)
  }

  return (
    <>
      <div className="stu-skillbox-nav-header">
        <span onClick={goToHome}>Home</span>
        <span>
          {" "}&gt;{" "}
        </span>
        <span onClick={goToPathWay}>Pathway</span>
      </div>
      {(isLoading || error) && (
          <div className="st-skillbox-programs-mn-ct-section">
              {isLoading && (
                  <Loading color="#004c73" customClass="st-cls-interview-loading" />
              )}
              {error && <div className="interview-questions-not-found-section">
                  {error}
              </div>}
          </div>
      )}
      {!isLoading && pathway && moduleId && (
        <div className="personal-interview-container">
          <div className="personal-interview-header">
            <div className="personal-interview-header-title">
              {pathway.section_name} - {module.module_name}
            </div>
            <Button
              text="Assignments"
              onClick={() => navigate(`/skillbox-v2/${cid}/${ct}/pathway/${pid}/modules/${moduleId}/assignments`)}
            />
          </div>
          <div className="personal-interview-body">
          <div className="st-skillbox-programs-mn-ct-section">
              <div className="st-skillbox-programs-mn-ct">
                {classes && classes.length>0 && classes.map((classItem, i) => (
                  <div key={i}>
                    <ClassCard classData={classItem} handleClick={goToClass} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Module;
