import React from 'react'
import ReferAndEarn from '../Pages/ReferAndEarn'
import RewardHistory from '../Pages/RewardHistory'
import Rewards from '../Pages/Rewards'
import ReferAndEarnSvg from '../Svgs/ReferAndEarnSvg'
import RewardHistorySvg from '../Svgs/RewardHistorySvg'
import RewardsSvg from '../Svgs/RewardsSvg'

export const ReferralSideBarData = [
    {
        title: 'Refer and Earn',
        component: <ReferAndEarn />,
        icon: <ReferAndEarnSvg />,
    },
    // {
    //     title: 'Rewards',
    //     component: <Rewards />,
    //     icon: <RewardsSvg />,
    // },
    {
        title: 'Referral History',
        component: <RewardHistory />,
        icon: <RewardHistorySvg />,
    },
]
