import React, { useState, useMemo } from 'react';
import Button from "../../../../../../../../../Components/Profile/Button/Button";

const Course = ({
    selectedInterviewType,
    courses,
    setSelectedCourse,
    selectedCourse,
    handleGoToHome,
    handleGoToAllInterview,
    handleGoToCourseBased,
    onNext
}) => {
    const [searchTerm, setSearchTerm] = useState("");

    const filteredCourses = useMemo(() => {
        return courses.filter(course =>
            course.label.toLowerCase().includes(searchTerm.toLowerCase())
        );
    }, [courses, searchTerm]);

    return (
        <div className="interview-preperation-personal-interview-page">
            <div className="interview-preperation-personal-interview-page-nav-menu">
                <div
                    onClick={handleGoToHome}
                    className="interview-preperation-personal-interview-page-nav-menu-home"
                >
                    Home
                </div>
                &nbsp; &gt; &nbsp;
                <div
                    onClick={handleGoToAllInterview}
                    className="interview-preperation-personal-interview-page-nav-menu-other"
                >
                    All Interview
                </div>
                &nbsp; &gt; &nbsp;
                <div
                    onClick={() => handleGoToCourseBased(false)}
                    className="interview-preperation-personal-interview-page-nav-menu-other"
                >
                    Course Based
                </div>
            </div>
            <div className="interview-preperation-personal-interview-container">
                <div className="interview-preperation-personal-interview-header">
                    <div className="interview-preperation-personal-interview-header-title">
                        {selectedInterviewType.title}
                    </div>
                </div>
                <div className="interview-preperation-personal-interview-body">
                    <div className="interview-preperation-personal-interview-body-4">
                        <div className="interview-preperation-personal-interview-body-4-1">
                            <div className="interview-preperation-personal-interview-title">
                                Select Course
                            </div>
                            <div className="interview-preperation-personal-interview-search">
                                <div className="interview-preperation-personal-interview-search-wrapper">
                                    <input
                                        type="text"
                                        placeholder="Search courses"
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                        className="interview-preperation-personal-interview-search-input"
                                    />
                                    <svg className="interview-preperation-personal-interview-search-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                        <circle cx="11" cy="11" r="8"></circle>
                                        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        {filteredCourses.length > 0 && (
                            <>
                                <div className="interview-preperation-personal-interview-body-4-2">
                                    <div className="interview-preperation-personal-interview-role-container-wrapper">
                                    <div className="interview-preperation-personal-interview-role-container">
                                        {filteredCourses.map((course, index) => (
                                            <div
                                                key={index}
                                                className={`interview-preperation-personal-interview-box ${selectedCourse === course.value ? 'selected' : ''}`}
                                                onClick={() => setSelectedCourse(course.value)}
                                            >
                                                {course.label}
                                            </div>
                                        ))}
                                        </div>
                                    </div>
                                </div>
                                <div className="interview-preperation-personal-interview-body-4-3">
                                    <Button
                                        text="NEXT"
                                        width="400px"
                                        disabled={selectedCourse === ""}
                                        onClick={onNext}
                                    />
                                </div>
                            </>
                        )}
                        {filteredCourses.length === 0 && (
                            <div className="interview-preperation-personal-interview-no-results">
                                No courses found matching your search.
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Course;
