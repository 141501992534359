import React from "react";

const RemoveFormattingSvg = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4 7V4H20V7M5 20H11M13 4L8 20M15 15L20 20M20 15L15 20" stroke="#444444" stroke-width="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    );
}

export default RemoveFormattingSvg;