import React, { useState, useEffect, useContext } from "react";
import "./AptitudePlatform.css";
import { useHttpClient } from "../../../Components/Hooks/http-hook";
import { AuthContext } from "../../../context/auth-context";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../../Components/Loading";
const swal = require("sweetalert2");

const AptitudePlatform = () => {
  const history = useNavigate();
  const [topics, setTopics] = useState([]);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  const [levels, setLevels] = useState([]);
  const [showInstructions, setShowInstructions] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);
  const [nextButtonLoading, setNextButtonLoading] = useState(false);
  const [levelNotSelectedError, setLevelNotSelectedError] = useState(false);
  const [topicNotSelectedError, setTopicNotSelectedError] = useState(false);

  const [selectedTopic, setSelectedTopic] = useState(null);
  const [selectedLevel, setSelectedLevel] = useState(null);
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [showInstructions]);


  useEffect(() => {
    const fetchTopicsAndLevels = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/prep/get-all-topics-and-levels`,
          "GET",
          null,
          {
            "Content-Type": "application/json",
            "Refresh-Token": auth.refresh_token,
            Authorization: auth.token,
          }
        );
        setTopics(responseData.data.topics);
        setLevels(responseData.data.levels);
      } catch (err) {
        console.log(err);
      }
    };
    fetchTopicsAndLevels();
  }, []);

  const handleTopicSelection = (selectedTopic) => {
    setSelectedTopic(selectedTopic);
    setTopicNotSelectedError(false);
    setErrorMessage("");
  };

  const handleLevelSelection = (selectedLevel) => {
    setSelectedLevel(selectedLevel);
    setLevelNotSelectedError(false);
    setErrorMessage("");
  };

  const handleNext = async () => {
    if (selectedLevel && selectedTopic) {
      setErrorMessage("");
      try{
        setNextButtonLoading(true);
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/prep/is-questions-available`,
          "POST",
          JSON.stringify({
            topic_id: selectedTopic.topic_id,
            level_id: selectedLevel.level_id,
          }),
          {
            "Content-Type": "application/json",
            "Refresh-Token": auth.refresh_token,
            Authorization: auth.token,
          }
        );
        if(responseData.data){
          setShowInstructions(true);
          setErrorMessage("");
        }
        else{
          setNextButtonLoading(false);
          swal.fire({
            title: "No Questions Found",
            text: "No questions found for the selected topic and level",
            icon: "warning",
            confirmButtonText: "Okay",
          });
        }
      }
      catch(err){
        console.log(err);
      }
    } else if (!selectedLevel && selectedTopic) {
      setErrorMessage("Please select a level");
      setLevelNotSelectedError(true);
    } else if (!selectedTopic && selectedLevel) {
      setErrorMessage("Please select a topic");
      setTopicNotSelectedError(true);
    } else {
      setErrorMessage("Please select both topic and level");
      setLevelNotSelectedError(true);
      setTopicNotSelectedError(true);
    }
  };
  

  const startTest = async () => {
    try {
      setButtonLoading(true);
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/prep/start-test`,
        "POST",
        JSON.stringify({
          topic_id: selectedTopic.topic_id,
          level_id: selectedLevel.level_id,
        }),
        {
          "Content-Type": "application/json",
          "Refresh-Token": auth.refresh_token,
          Authorization: auth.token,
        }
      );
      if(responseData.data){
        history(`/aptitude-platform/exam/${responseData.data}`);
        // full screen request
        if(document.body.requestFullscreen){
          document.body.requestFullscreen().catch(e=>{
            console.log("error while full screen ", e)
          });
        }
      }
      else{
        setButtonLoading(false);
        setNextButtonLoading(false);
        swal.fire({
          title: "Something went wrong",
          text: "Cannot start the test, please try again later",
          icon: "error",
          confirmButtonText: "Okay",
        });
        setShowInstructions(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="aptitude-page">
      <div className="aptitude-content">
        {!showInstructions ? (
          <div>
            <h3>Welcome to Surge Online Tests (Aptitude)</h3>
            <div className="app-grid-content">
              <div
                className={`app-grid-div ${
                  topicNotSelectedError ? "error" : ""
                }`}
              >
                <h3>Select a Topic</h3>
                <hr />
                <div style={{ overflowY: "auto", maxHeight: "270px" }}>
                  {!topics.length && <Loading color="#14144e" />}
                  {topics &&
                    topics.map((topic) => (
                      <ul key={topic.topic_id}>
                        <li
                          onClick={() => handleTopicSelection(topic)}
                          className={selectedTopic === topic ? "selected" : ""}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <span>{topic.topic_name}</span>
                          {selectedTopic === topic && (
                            <svg
                              version="1.0"
                              xmlns="http://www.w3.org/2000/svg"
                              style={{ marginLeft: "10px" }}
                              width="20.000000pt"
                              height="19.000000pt"
                              viewBox="0 0 20.000000 19.000000"
                              preserveAspectRatio="xMidYMid meet"
                            >
                              {" "}
                              <g
                                transform="translate(0.000000,19.000000) scale(0.100000,-0.100000)"
                                fill="#5fca77"
                                stroke="none"
                              >
                                {" "}
                                <path d="M116 120 l-66 -70 -28 17 c-27 17 -27 17 -12 -2 8 -11 23 -30 33 -42 l20 -22 34 57 c19 31 51 74 71 95 20 20 31 37 25 37 -6 0 -40 -32 -77 -70z" />{" "}
                              </g>{" "}
                            </svg>
                          )}
                        </li>
                      </ul>
                    ))}
                </div>
              </div>
              <div
                className={`app-grid-div ${
                  levelNotSelectedError ? "error" : ""
                }`}
              >
                <h3>Select a level</h3>
                <hr />
                {!levels.length && <Loading color="#14144e" />}
                {levels &&
                  levels.map((level) => (
                    <ul key={level.level_id}>
                      <li
                        onClick={() => handleLevelSelection(level)}
                        className={selectedLevel === level ? "selected" : ""}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {level.level_name}
                        {selectedLevel === level && (
                          <svg
                            version="1.0"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{ marginLeft: "10px" }}
                            width="20.000000pt"
                            height="19.000000pt"
                            viewBox="0 0 20.000000 19.000000"
                            preserveAspectRatio="xMidYMid meet"
                          >
                            {" "}
                            <g
                              transform="translate(0.000000,19.000000) scale(0.100000,-0.100000)"
                              fill="#5fca77"
                              stroke="none"
                            >
                              {" "}
                              <path d="M116 120 l-66 -70 -28 17 c-27 17 -27 17 -12 -2 8 -11 23 -30 33 -42 l20 -22 34 57 c19 31 51 74 71 95 20 20 31 37 25 37 -6 0 -40 -32 -77 -70z" />{" "}
                            </g>{" "}
                          </svg>
                        )}
                      </li>
                    </ul>
                  ))}
              </div>
            </div>
            {errorMessage && (
              <div className="error-message">{errorMessage}</div>
            )}

            <button className="next-button" onClick={handleNext}>
              {nextButtonLoading ? (
                <Loading />
              ) : (
                <>
                  <span className="button-text">Next</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-arrow-right"
                    viewBox="0 0 16 16"
                  >
                    <path d="M6.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L12.793 8 6.646 1.854a.5.5 0 0 1 0-.708z" />
                  </svg>
                </>
              )}
            </button>
          </div>
        ) : (
          <div className="instructions">
            <h3>Instructions for Online Aptitude Test</h3>
            <hr />
            <ul>
              <li>
                This online test is being conducted for your educational
                benefit, to gauge your learning progress, and to measure your
                understanding. Please be attentive and{" "}
                <b>answer all questions</b> to the best of your ability.
              </li>
              <li>
                The test comprises a total of <b>20 questions</b>, each designed
                to evaluate your aptitude in various areas.
              </li>
              <li>
                You will have <b>30 minutes</b> to complete the test. We
                recommend managing your time wisely to answer all questions
                within the stipulated time frame.
              </li>
              <li>
                Ensure a <b>stable internet connection</b> to avoid any
                disruptions during the test.
                <b> Do not refresh the page</b>, as this may result in data loss
                or disqualification from the test.
              </li>
              <li>
                This test is open to all eligible participants, and there are
                <b> no restrictions</b> based on age, gender, or any other
                criteria.
              </li>

              <li>
                We expect all participants to maintain the highest level of
                integrity and honesty during the test. Any attempt to use
                external aids or engage in cheating will result in{" "}
                <b>immediate disqualification</b>.
              </li>

              <li>
                We wish you the <b>best of luck</b> as you take this aptitude
                test. We hope that it provides valuable insights into your
                capabilities.
              </li>
            </ul>
            <button className="start-button" onClick={startTest}>
              {buttonLoading ? <Loading /> : <>Start Now</>}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default AptitudePlatform;
