import React, { useState, useEffect, useRef, useContext } from "react";
import "./AptitudeTest.css";
import { useParams } from "react-router-dom";
import { useHttpClient } from "../../../Components/Hooks/http-hook";
import { AuthContext } from "../../../context/auth-context";
import SurgeLogo from "../../../assets/logo4.png";
import MathJax from "react-mathjax";
import LoadingSpinner from "../../../Components/Input/LoadingSpinner";
const swal = require("sweetalert2");

const AptitudePractice = ({ isNoHeaderFooter, setIsNoHeaderFooter }) => {
  const { sendRequest } = useHttpClient();
  const auth = useContext(AuthContext);
  const [testData, setTestData] = useState(null);
  const [totalAttempted, setTotalAttempted] = useState(0);
  const [totalQuestions, setTotalQuestions] = useState(0);


  const divRefs = Array.from({ length: 50 }, () => useRef(null));

  useEffect(() => {
    const handleNavigation = () => {
      window.history.pushState(null, null, window.location.href);
      window.history.back();
      window.onpopstate = () => {
        window.history.forward();
      };
    };

    if (isNoHeaderFooter) {
      handleNavigation();
      return () => {
        window.onpopstate = null;
      };
    }
  }, [isNoHeaderFooter]);

  const { practiceid } = useParams();

  useEffect(() => {
    setIsNoHeaderFooter(true);
    const fetchPracticeData = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/prep/practice/${practiceid}`,
          "GET",
          null,
          {
            "Content-Type": "application/json",
            "Refresh-Token": auth.refresh_token,
            Authorization: auth.token,
          }
        );
        setTestData(responseData.data);
      } catch (err) {
        console.log(err);
        setIsNoHeaderFooter(false);
      }
    };
    fetchPracticeData();
  }, []);

  useEffect(() => {
    if (!isNoHeaderFooter) {
      window.location.reload();
    }
  }, [isNoHeaderFooter]);

  useEffect(() => {
    if (testData) {
      let total = 0;
      testData.generated_questions.map((ques) => {
        if (ques.is_selected) {
          total++;
        }
      });
      setTotalAttempted(total);
      setTotalQuestions(testData.generated_questions.length);
    }
  }, [testData]);

  const exitPractice = () => {
    swal.fire({
      title: "Are you sure?",
      text: "You will not be able to continue the practice!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, exit!",
      cancelButtonText: "No, continue!",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        setIsNoHeaderFooter(false);
      }
    });
  };

  const saveTest = async (newTestData) => {
    try {
      const dataToSend = {};
      newTestData.generated_questions.map((ques) => {
        dataToSend[ques.question_id] = ques.selected_option;
      });
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/prep/save-practice-answers/${practiceid}`,
        "PATCH",
        JSON.stringify({
          attempted_answers: dataToSend,
        }),
        {
          "Content-Type": "application/json",
          "Refresh-Token": auth.refresh_token,
          Authorization: auth.token,
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

  const handleOptionClick = (ques_id, option_id, mark) => {
    setTestData((prev) => {
      const newTestData = { ...prev };
      newTestData.generated_questions = newTestData.generated_questions.map(
        (ques) => {
          if (ques.question_id === ques_id) {
            if (mark && ques.selected_option === option_id) {
              return {
                ...ques,
                is_selected: false,
                selected_option: undefined,
              };
            } else {
              return { ...ques, is_selected: true, selected_option: option_id };
            }
          } else {
            return ques;
          }
        }
      );
      saveTest(newTestData);
      return newTestData;
    });
  };

  const submitTest = async () => {
    try {
      const dataToSend = {};
      testData.generated_questions.map((ques) => {
        dataToSend[ques.question_id] = ques.selected_option;
      });
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/prep/submit-practice/${practiceid}`,
        "PATCH",
        JSON.stringify({
          attempted_answers: dataToSend,
        }),
        {
          "Content-Type": "application/json",
          "Refresh-Token": auth.refresh_token,
          Authorization: auth.token,
        }
      );
      // setIsNoHeaderFooter(false);
      swal.fire({
        title: "Answers Submitted!",
        text: responseData.data.message + ", Your score is " + responseData.data.score,
        icon: "success",
        confirmButtonText: "OK",
      }).then((result) => {
        if (result.isConfirmed) {
          setIsNoHeaderFooter(false);
        }
      });
    } catch (err) {
      console.log(err);
    }
  };


  const submitPractice = async () => {
    const unansweredQuestions = totalQuestions - totalAttempted;
    const confirmationText = `You have ${unansweredQuestions} questions unanswered. Are you sure you want to submit?`;

    swal.fire({
        title: "Are you sure?",
        text: confirmationText,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, submit!",
        cancelButtonText: "No, continue!",
        reverseButtons: true,
    }).then((result) => {
        if (result.isConfirmed) {
            submitTest();
        }
    });
};


  const handleScroll = (id) => {
    divRefs[id].current.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };

  return (
    <div className="apptest-page">
      {!testData && <LoadingSpinner asOverlay />}
      {testData && (
        <>
          <div className="head-fixed">
            <div className="exam-heading">
              <div className="flex-item">
                <img src={SurgeLogo} alt="Surge Classes" />
              </div>
              <div className="flex-item">
                <h3>Practice ID : {testData.practice_id}</h3>
              </div>
              <div className="flex-item">
                
              </div>
              <div className="exit-btn flex-item">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  id="exit"
                  onClick={() => exitPractice()}
                >
                  <path
                    fill="#000"
                    d="M6 2a4 4 0 0 0-4 4v3h2V6a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-3H2v3a4 4 0 0 0 4 4h12a4 4 0 0 0 4-4V6a4 4 0 0 0-4-4H6Z"
                  ></path>
                  <path
                    fill="#000"
                    d="M3 11a1 1 0 1 0 0 2h9.582l-2.535 2.536a1 1 0 0 0 1.414 1.414l4.196-4.196a.998.998 0 0 0 0-1.508L11.46 7.05a1 1 0 1 0-1.414 1.414L12.582 11H3Z"
                  ></path>
                </svg>
              </div>
            </div>
            <hr />
          </div>
          <div className="practice-test">
            <div style={{ padding: 50 }}>
              <div className="fixed-box">
                <div className="question-palette">
                  <div className="palette-body">
                    {[...Array(testData.generated_questions.length).keys()].map(
                      (index) => (
                        <div
                          key={index + 1}
                          className={`question ${
                            testData.generated_questions[index].is_selected
                              ? "answered"
                              : "not-answered"
                          }`}
                          onClick={() => handleScroll(index)}
                        >
                          {index + 1}
                        </div>
                      )
                    )}
                  </div>
                </div>
                <div>
                  <button onClick={() => submitPractice()}>Submit Practice</button>
                </div>
                <div className="legend">
                  <div className="legend-box">
                    <div className="legend-item">
                      <div className="legend-color answered"></div>
                      <div className="legend-text">Answered</div>
                    </div>
                    <div className="legend-item">
                      <div className="legend-color not-answered"></div>
                      <div className="legend-text">Not Answered</div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <ul
                  style={{
                    listStyle: "number",
                    fontWeight: "bold",
                    fontSize: "19px",
                  }}
                >
                  {testData.generated_questions.map((ques, index) => {
                    return (
                      <div
                        className="question-box"
                        key={ques.question_id}
                        ref={divRefs[index]}
                      >
                        <li>
                          <span className="ques-text">
                            {ques.other_question_info &&
                            ques.other_question_info
                              .is_ques_text_mathematical ? (
                              <span className="ques-math-text">
                                <MathJax.Provider>
                                  <MathJax.Node formula={ques.question_text} />
                                </MathJax.Provider>
                              </span>
                            ) : (
                              <span className="ques-normal-text">
                                {ques.question_text}
                              </span>
                            )}
                          </span>
                        </li>
                        <div className="ques-options">
                          {ques.options[0].map((option, i) => {
                            return (
                              <div
                              key={i}
                                onClick={() => {
                                  handleOptionClick(
                                    ques.question_id,
                                    i,
                                    ques.is_selected
                                  );
                                }}
                                className={
                                  ques.is_selected && ques.selected_option == i
                                    ? "selected-option1"
                                    : "optionValue1"
                                }
                              >
                                <span>
                                  {i + 1}.
                                  <span className="option-text">
                                    {ques.other_question_info &&
                                    ques.other_question_info
                                      .is_options_mathematical ? (
                                      <MathJax.Provider>
                                        <MathJax.Node inline formula={option} />
                                      </MathJax.Provider>
                                    ) : (
                                      <>{option}</>
                                    )}
                                  </span>
                                </span>
                                <span className="option-tick">
                                  {ques.is_selected &&
                                    ques.selected_option == i && (
                                      <svg
                                        version="1.0"
                                        xmlns="http://www.w3.org/2000/svg"
                                        style={{ marginLeft: "10px" }}
                                        width="20.000000pt"
                                        height="19.000000pt"
                                        viewBox="0 0 20.000000 19.000000"
                                        preserveAspectRatio="xMidYMid meet"
                                      >
                                        {" "}
                                        <g
                                          transform="translate(0.000000,19.000000) scale(0.100000,-0.100000)"
                                          fill="#5fca77"
                                          stroke="none"
                                        >
                                          {" "}
                                          <path d="M116 120 l-66 -70 -28 17 c-27 17 -27 17 -12 -2 8 -11 23 -30 33 -42 l20 -22 34 57 c19 31 51 74 71 95 20 20 31 37 25 37 -6 0 -40 -32 -77 -70z" />{" "}
                                        </g>{" "}
                                      </svg>
                                    )}
                                </span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AptitudePractice;
