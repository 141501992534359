import React from "react";
import { useParams } from "react-router-dom";
import { useHttpClient } from "../../Components/Hooks/http-hook";
import { AuthContext } from "../../context/auth-context";
import { useContext, useEffect, useState } from "react";
import VideoPlayer from "./VideoPlayer";
import "./Class.css";
import Button from "../../Components/Profile/Button/Button";

const Class = () => {
  const { ct, clid } = useParams();

  const [classInfo, setClassInfo] = useState({});
  const [classContent, setClassContent] = useState([]);
  const [showVideo, setShowVideo] = useState(false);

  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const fetchContents = async () => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/db/contents/${clid}`,
        "GET",
        null,
        {
          "Content-Type": "application/json",
          "Refresh-Token": auth.refresh_token,
          Authorization: auth.token,
        }
      );
      setClassContent(responseData.data);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchClass = async () => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/db/class/${clid}`,
        "GET",
        null,
        {
          "Content-Type": "application/json",
          "Refresh-Token": auth.refresh_token,
          Authorization: auth.token,
        }
      );
      setClassInfo(responseData.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchContents();
    fetchClass();
  }, []);

  const showVideoHandler = () => {
    setShowVideo(true);
  };

  return (
    <div className="homepage">
      <div className="class-container">
        <h1>{classInfo && classInfo.class_name}</h1>
        <p>{classInfo && classInfo.class_description}</p>

        <div className="class-content">
          <h2>Class Videos</h2>
          {classContent &&
            classContent.map((item, index) => (
              <>
                {item.content_type === "video" && (
                  <>
                    <h2>{item.content_text}</h2>
                    <p>{item.content_summary}</p>
                    <div className="class-video">
                      <VideoPlayer
                        url={item.content_file_url}
                        title={classInfo.class_name}
                        courseId={ct}
                      />
                    </div>
                  </>
                )}
              </>
            ))}
          <h2>
            Class Resources ({" "}
            {classContent &&
              classContent.filter((item) => item.content_type !== "video")
                .length}
            )
          </h2>
          {classContent &&
            classContent.map((item, index) => (
              <div className="class-resources">
                <ul>
                  {item.content_type !== "video" && (
                    <li key={index}>
                      <div className="class-resources-list-numbers">
                        {index}.{" "}
                      </div>
                      <div className="class-resources-list-text">
                        {item.content_text}
                      </div>
                      <a
                        href={
                          (item.content_file_url &&
                            item.content_file_url.startsWith("http://")) ||
                          item.content_file_url.startsWith("https://")
                            ? item.content_file_url
                            : `${process.env.REACT_APP_BACKEND_URL}/${item.content_file_url}`
                        }
                        rel="noopener noreferrer"
                        download
                      >
                        <Button width="180px" text="Download" />
                      </a>
                    </li>
                  )}
                </ul>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Class;
