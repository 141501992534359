import React from "react";
import PopUp from "../UiComponents/Popup/PopUp";
import "./EditClass.css";
import { useState, useContext } from "react";
import { AdminContext } from "../../../../context/admin-context";
import { useHttpClient } from "../../../../Components/Hooks/http-hook";
import LoadingSpinner from "../../../../Components/Input/LoadingSpinner";
import { useEffect } from "react";
const swal = require("sweetalert2");

const EditClass = ({ classItem, showPopup, setShowPopup, onEdit }) => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [buttonLoading, setButtonLoading] = useState(false);
  const admin = useContext(AdminContext);

  const initialClassState = {
    class_name: classItem.class_name,
    class_description: classItem.class_description,
    class_lock: classItem.class_lock,
    class_order: classItem.class_order,
  };

  const [validationErrors, setValidationErrors] = useState({
    class_name: false,
    class_description: false,
    class_order: false,
  });

  const [classData, setClassData] = useState(initialClassState);

  useEffect(() => {
    setClassData(initialClassState);
  }, [classItem]);

  const handlechange = (e) => {
    const { name, value } = e.target;

    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: false,
    }));

    setClassData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const validateForm = () => {
    const requiredFields = ["class_name", "class_description", "class_order"];
    const newValidationErrors = {};
    for (const field of requiredFields) {
      if (!classData[field] || classData[field].trim().length === 0) {
        newValidationErrors[field] = true;
      }
    }
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      ...newValidationErrors,
    }));
    return Object.keys(newValidationErrors).length === 0;
  };

  const resetForm = () => {
    setClassData(initialClassState);
    setValidationErrors({
      class_name: false,
      class_description: false,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      setButtonLoading(false);
      return;
    }
    setButtonLoading(true);
    try {
      const response = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/db/class/${classItem.class_id}`,
        "PUT",
        JSON.stringify(classData),
        {
          "Content-Type": "application/json",
          "Refresh-Token": admin.refresh_token,
          Authorization: admin.token,
        }
      );
      if (response) {
        setButtonLoading(false);
        setShowPopup(false);
        onEdit({
          ...classItem,
          ...classData,
        })
        swal.fire({
          title: "Success!",
          text: "Class has been updated.",
          icon: "success",
          confirmButtonText: "Close",
        });
      }
    } catch (err) {
      setButtonLoading(false);
      swal.fire({
        title: "Error!",
        text: "Something went wrong, please try again later.",
        icon: "error",
        confirmButtonText: "Close",
      });
    }
  };

  useEffect(() => {
    if (error) {
      setButtonLoading(false);
      setShowPopup(false);
      swal.fire({
        title: "Error!",
        text: error || "Something went wrong, please try again later.",
        icon: "error",
        confirmButtonText: "Close",
      });
      clearError();
    }
  }
  , [error, clearError, setShowPopup]);

  return (
    <PopUp
      title={classItem.class_name}
      onClose={() => {
        resetForm();
        setShowPopup(false);
      }}
      show={showPopup}
    >
      {buttonLoading ? (
        <div className="edit_class_popup__loading">
        <LoadingSpinner />
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="edit_class_popup__content">
            <div className="edit_class_popup__content-row">
              <div className="edit_class_popup__content-row-title">
                <p>Class ID</p>
              </div>
              <div className="edit_class_popup__content-row-value">
                <p>{classItem.class_id}</p>
              </div>
            </div>
            <div className="edit_class_popup__content-row">
              <div className="edit_class_popup__content-row-title">
                <p>Class Name</p>
              </div>
              <div className="edit_class_popup__content-row-value">
                <input
                  type="text"
                  defaultValue={classItem.class_name}
                  onChange={handlechange}
                  name="class_name"
                  className={validationErrors.class_name ? "input_val_error" : ""}
                />
                {validationErrors.class_name && (
                  <p className="input_val_error-text">
                    Please enter a valid class name.
                  </p>
                )}
              </div>
            </div>
            <div className="edit_class_popup__content-row">
              <div className="edit_class_popup__content-row-title">
                <p>Class Description</p>
              </div>
              <div className="edit_class_popup__content-row-value">
                <input
                  type="text"
                  defaultValue={classItem.class_description}
                  onChange={handlechange}
                  name="class_description"
                  className={
                    validationErrors.class_description ? "input_val_error" : ""
                  }
                />
                {validationErrors.class_description && (
                  <p className="input_val_error-text">
                    Please enter a valid class description.
                  </p>
                )}
              </div>
            </div>
            <div className="edit_class_popup__content-row">
              <div className="edit_class_popup__content-row-title">
                <p>Is Active</p>
              </div>
              <div className="edit_class_popup__content-row-value">
                <select
                  name="class_lock"
                  defaultValue={classItem.class_lock}
                  onChange={handlechange}
                >
                  <option value="false">Live</option>
                  <option value="true">Not Live</option>
                </select>
              </div>
            </div>
            <div className="edit_class_popup__content-row">
              <div className="edit_class_popup__content-row-title">
                <p>Class Order</p>
              </div>
              <div className="edit_class_popup__content-row-value">
                <input
                  type="number"
                  defaultValue={classItem.class_order}
                  onChange={handlechange}
                  name="class_order"
                  className={validationErrors.class_order ? "input_val_error" : ""}
                />
                {validationErrors.class_order && (
                  <p className="input_val_error-text">
                    Please enter a valid class order.
                  </p>
                )}
              </div>
            </div>
            <div className="edit_class_popup__content-row">
              <div className="edit_class_popup__content-row-title">
                <p>Created On</p>
              </div>
              <div className="edit_class_popup__content-row-value">
                <p>{classItem.class_create_timestamp}</p>
              </div>
            </div>
            <div className="edit_class_popup__content-row">
              <div className="edit_class_popup__content-row-title">
                <p>Last Updated</p>
              </div>
              <div className="edit_class_popup__content-row-value">
                <p>{classItem.class_update_timestamp}</p>
              </div>
            </div>
          </div>
          <div className="edit_class_popup__buttons">
            <button
              className="edit_class_popup__buttons-cancel"
              type="button"
              onClick={() =>{ 
                resetForm();
                setShowPopup(false)
              }}
            >
              Cancel
            </button>
            <button
              className="edit_class_popup__buttons-save"
              type="submit"
            >
              Save
            </button>
          </div>
        </form>
      )}
    </PopUp>
  );
};

export default EditClass;
