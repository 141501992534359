import React from "react";

const Grades = [
    "O",
    "A+",
    "A",
    "B+",
    "B",
    "C",
    "P",
    "S",
    "F",
]

export default Grades;
