import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { MdDoubleArrow } from "react-icons/md";
import { FaCopy } from "react-icons/fa";
import { useHttpClient } from "../../../Components/Hooks/http-hook";
import { AdminContext } from "../../../context/admin-context";
import "../AdminLeads.css";
import "./Transactions.css";
import Scrollbars from "react-custom-scrollbars-2";
import moment from "moment/moment";
const swal = require("sweetalert2");

const Transactions = () => {
  const navigate = useNavigate();
  const { sendRequest } = useHttpClient();
  const adminCtx = useContext(AdminContext);
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/auth/admin/user_payments`,
          "GET",
          null,
          {
            "Content-Type": "application/json",
            Authorization: adminCtx.token,
          }
        );
        setTransactions(responseData.payments);
        setLoading(false);
      } catch (err) {
        console.log(err);
      }
    };
    fetchTransactions();
  }, [sendRequest, adminCtx.token]);

  const handleTransationClick = (id) => {
    // navigate(`/admin/payments/${id}`);
  };

  const formatDate = (date) => {
    date = parseInt(date) * 1000;
    return moment(date).format("DD-MM-YYYY hh:mm:ss A");
  };

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    swal.fire({
      icon: "success",
      title: "Copied to clipboard",
      showConfirmButton: false,
      timer: 1000,
    });
  };

  return (
    <div className="admin-transactions">
      <div className="admin-transactions-header">
        <h1>Razorpay Transactions</h1>
      </div>
      {loading && <div>Loading...</div>}
      {!loading && (
        <div className="leads-table">
          <Scrollbars
            autoHide
            autoHideTimeout={2000}
            autoHideDuration={310}
            autoHeightMin={300}
            autoHeightMax={800}
            thumbMinSize={30}
            universal={true}
            style={{
              width: `100%`,
              height: "25rem",
              background: "white",
              borderRadius: "6px",
            }}
          >
            <table class="module-table" cellSpacing={"0px"}>
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Payment ID</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Contact</th>
                  <th>Amount</th>
                  <th>Date</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {transactions &&
                  transactions.map((p, i) => {
                    return (
                      <tr onClick={() => handleTransationClick(p.id)}>
                        <td>{i + 1}</td>
                        <td>{p.payment_response && p.payment_response.id}</td>
                        <td>
                          {p.name && p.name}
                          {p.name && (
                          <FaCopy
                            onClick={() => handleCopy(p.name)}
                            className="rzp-copy-icon"
                          />
                          )}
                        </td>
                        <td>
                          {p.email && p.email}
                          {p.email && (
                          <FaCopy
                            onClick={() => handleCopy(p.email)}
                            className="rzp-copy-icon"
                          />
                          )}
                        </td>
                        <td>
                          {p.mobile && p.mobile}
                          {p.mobile && (
                          <FaCopy
                            onClick={() => handleCopy(p.mobile)}
                            className="rzp-copy-icon"
                          />
                          )}
                        </td>
                        <td>
                          Rs.{" "}
                          {p.payment_response &&
                            p.payment_response.amount &&
                            p.payment_response.amount / 100}
                        </td>
                        <td>
                          {p.payment_response &&
                            p.payment_response.created_at &&
                            formatDate(p.payment_response.created_at)}
                        </td>
                        <td>
                          {p.payment_response && p.payment_response.status}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </Scrollbars>
        </div>
      )}
    </div>
  );
};

export default Transactions;
