import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars-2";
import { BsBook } from "react-icons/bs";
import { FiPlayCircle } from "react-icons/fi";
import { CgFileDocument } from "react-icons/cg";
import {
  MdOutlineVideoLibrary,
  MdMeetingRoom,
  MdDirectionsRun,
  MdPlayArrow,
} from "react-icons/md";
import { FaTrashAlt, FaPencilAlt, FaHandPointRight } from "react-icons/fa";
import { GoPlus } from "react-icons/go";
import { HiMinus } from "react-icons/hi";
import { MdSlowMotionVideo } from "react-icons/md";
import { GrDocumentText } from "react-icons/gr";
import { BiLock } from "react-icons/bi";
import { FcLock } from "react-icons/fc";
import Pathway from "../Courses/Pathway";
// import LockIcon from "../../assets/lockicon.jpeg"

import "./HomePage.css";
import Course1 from "../../assets/Continuing-professional-development-1170x630.jpg";
import VideoPlayerPop from "./VideoPlayerPop";
import AssignmentPop from "../DSAAssignments/AssignmentPop";
import MseElementPop from "./MseElementPop";
import { useHttpClient } from "../../Components/Hooks/http-hook";
import { AuthContext } from "../../context/auth-context";
import ScheduleManagement from "./ScheduleManagement";
import Auth from "../Auth/Auth";
import StudentAssignmentPop from "../Assignments/StudentAssignmentPop";

const HomePage = () => {
  const videoDuration = "3 hrs 48 min";
  let { cid, ct } = useParams();

  const auth = useContext(AuthContext);
  const history = useNavigate();

  const [mainCourseIndex, setMainCourseIndex] = useState(0);

  const [isVideo, setIsVideo] = useState({
    isVideo: true,
  });

  const [isScheduleManagement, setIsScheduleManagement] = useState({
    isScheduleManagement: false,
  });

  const [isContent, setIsContent] = useState({
    isContent: false,
  });
  const [isSme, setIsSme] = useState({
    isSme: false,
  });
  const [isExpand, setIsExpand] = useState({
    // "title": "Week 1"
    title: "",
    module: "",
  });
  const [userCampusProgram, setUserCampusProgram] = useState({});

  const [smeContent, setSmeContent] = useState([]);

  const enroll_program_info = auth.course_ids_info;
  const dummy_titles = {
    "0001": "SRKREC Data Science Campus Program Batch 2",
    "0002": "Data Science Pro Program Batch 3",
  };

  const getCoursePathways = async () => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/db/sections/${cid}`,
        "GET",
        null,
        {
          "Content-Type": "application/json",
          "Refresh-Token": auth.refresh_token,
          Authorization: auth.token,
        }
      );
      if (responseData.ok) {
        return responseData.data;
      }
    } catch (err) {
      console.log("got error while getting course pathways", err);
    }
  };

  const getProgramAssignments = async () => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/s3/assignment/${cid}/${auth.surge_id}`,
        "GET"
      );
      if (responseData.ok) {
        console.log("HELLO, ", responseData.data);
        return responseData.data;
      }
    } catch (err) {
      console.log("got error while getting assignments", err);
    }
  };

  const [assignmentList, setAssignmentList] = useState(null);

  useEffect(() => {
    getCoursePathways().then((response) => setUserCampusProgram(response));
    getProgramAssignments().then((responseData) =>
      setAssignmentList(responseData)
    );
    console.log("userCampusProgram type:", typeof userCampusProgram);
    console.log("user campus program", userCampusProgram);
  }, []);

  const [isStudent, setIsStudent] = useState(false);
  useEffect(() => {
    if (auth.role == "student") {
      setIsStudent(true);
    }
  }, [auth.role]);
  const ContentClickHandler = (value) => {
    // setUserCampusProgram(content_list_json[0]);
    if (value == "video") {
      setIsVideo({ isVideo: true });
      setIsContent({ isContent: false });
      setIsSme({ isSme: false });
      setIsScheduleManagement({ isScheduleManagement: false });
    } else if (value == "schedule") {
      setIsScheduleManagement({ isScheduleManagement: true });
      setIsContent({ isContent: false });
      setIsVideo({ isVideo: false });
      setIsSme({ isSme: false });
    } else if (value == "sme") {
      setIsSme({ isSme: true });
      setIsContent({ isContent: false });
      setIsVideo({ isVideo: false });
      setIsScheduleManagement({ isScheduleManagement: false });
    } else if (value == "content") {
      setIsContent({ isContent: true });
      setIsVideo({ isVideo: false });
      setIsSme({ isSme: false });
      setIsScheduleManagement({ isScheduleManagement: false });
    } else {
      setIsContent({ isContent: false });
      setIsVideo({ isVideo: false });
      setIsSme({ isSme: false });
      setIsScheduleManagement({ isScheduleManagement: false });
    }
  };
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  useEffect(() => {
    const getSmeElementDetails = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/db/mse-element/0001`,
          "get"
        );
        if (responseData.ok) {
          setSmeContent(responseData.data);
        }
      } catch (err) {
        console.log("got error while getting programmes");
      }
    };
    getSmeElementDetails();
  }, []);

  const [isOpen, setIsOpen] = useState(false);
  const [isVideoLectureDetails, setIsVideoLectureDetails] = useState({
    title: "Ml Template class 1",
    url:
      "https://d2hkendb91rgug.cloudfront.net/02.+Welcome+To+Your+Nanodegree.mp4",
  });

  const videoLinkHandler = (course_name, class_title, url) => {
    // history(`/home/play-video/${course_name}`);
    // console.log(course_name, class_title, index)
    setIsOpen(!isOpen);
    setIsVideoLectureDetails({
      title: class_title,
      url: url,
    });
    console.log("video link pop up", isOpen);
  };

  const togglePopupVideo = () => {
    setIsOpen(!isOpen);
  };

  const [isAssgnOpen, setIsAssgnOpen] = useState(false);

  const [isAssignmentNumber, setIsAssignmentNumber] = useState("1");

  const assignClickHandler = (assign) => {
    setIsAssgnOpen(!isAssgnOpen);
    setIsAssignmentNumber(assign);
    console.log(isAssgnOpen, isAssignmentNumber);
  };

  const togglePopupAssign = () => {
    setIsAssgnOpen(!isAssgnOpen);
  };

  const CAMPUS_PROGRAMME_JSON = [
    {
      title: "Data Science Campus Course",
      image: Course1,
      isStarted: true,
    },
  ];

  const [toggle, setToggle] = useState({
    toggle: true,
  });

  const [mseElementPop, setMseElementPop] = useState(false);
  const [mseElementQuestionDetails, setMseElementQuestionDetails] = useState({
    mse_id: "",
    question: "",
    ques_parts: [],
  });
  const mseStartHandler = (mse_id, question, ques_parts) => {
    setMseElementPop(!mseElementPop);
    setMseElementQuestionDetails({
      mse_id: mse_id,
      question: question,
      ques_parts: ques_parts,
    });
    console.log(mseElementPop);
  };
  const togglePopup = () => {
    setMseElementPop(!mseElementPop);
  };

  const [isSchedule, setIsSchedule] = useState(true);

  const planAssignmentHandler = () => {
    setIsSchedule(!isSchedule);
  };

  const scheduleList = [
    {
      class_topic: "Session 1: Introduction",
      class_status: true,
    },
    {
      class_topic: "Session 2: Arrays",
      class_status: true,
    },
    {
      class_topic: "Assignment 1: Problems on Arrays",
      class_status: false,
    },
    {
      class_topic: "Session 3: Strings",
      class_status: false,
    },
    {
      class_topic: "Assignment 2: problems on strings",
      class_status: false,
    },
    {
      class_topic: "Session 4: Libraries , Linked Lists",
      class_status: false,
    },
    {
      class_topic: "Assignment 3: Problems in Linked Lists",
      class_status: false,
    },
    {
      class_topic: "Session 5: Searching and Recursion",
      class_status: false,
    },
    {
      class_topic: "Assignment 4: Problems of Searching and Recursion",
      class_status: false,
    },
    {
      class_topic: "Session 6: Sorting Techniques",
      class_status: false,
    },
    {
      class_topic: "Assignment 5: Problems in sorting",
      class_status: false,
    },
    {
      class_topic: "Session 7: Stacks , Queues and Deques",
      class_status: false,
    },
    {
      class_topic: "Assignment 6: Problems in Stacks , queues and Deques",
      class_status: false,
    },
    {
      class_topic: "Session 8: Trees Basic, Traversals, Binary Trees",
      class_status: false,
    },
    {
      class_topic: "Assignment 7: Problems in Trees Basic Traversal",
      class_status: false,
    },
    {
      class_topic: "Session 9: Heaps and Priority Queues",
      class_status: false,
    },
    {
      class_topic: "Assignment 8: Problems in Heaps and Priority Queues",
      class_status: false,
    },
    {
      class_topic: "Session 10: Intro Graphs",
      class_status: false,
    },
    {
      class_topic: "Assignment 9: Problems on graph",
      class_status: false,
    },
    {
      class_topic: "Session 11: Graphs Algorithms",
      class_status: false,
    },
    {
      class_topic: "Assignment 10: Problems on Graphs Algorithms",
      class_status: false,
    },
    {
      class_topic: "Session 12: Tries and MST",
      class_status: false,
    },
    {
      class_topic: "Assignment 11: Problems on Tries and MST",
      class_status: false,
    },
    {
      class_topic: "Session 13: Hashing and Greedy algorithms",
      class_status: false,
    },
    {
      class_topic: "Assignment 12: Problems on Hashing and Greedy algorithms",
      class_status: false,
    },
    {
      class_topic: "Session 14: Advance algorithms",
      class_status: false,
    },
    {
      class_topic: "Assignment 13: Problems on Advance algorithms",
      class_status: false,
    },
    {
      class_topic: "Session 15: DP",
      class_status: false,
    },
    {
      class_topic: "Assignment 14: Problems in DP",
      class_status: false,
    },
    {
      class_topic: "Session 16: CS Fundamentals",
      class_status: false,
    },
    {
      class_topic: "Session 17: Interview Tips and Tricks",
      class_status: false,
    },
  ];

  const assignmentLogStart = async (assignment_id, title, skills, deadline) => {
    // event.preventDefault();
    console.log("log start");
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/db/assignment-log-start`,
        "POST",
        JSON.stringify({
          assignment_id: assignment_id,
          title: title,
          skills: skills,
          deadline: deadline,
          email: `${auth.email}`,
          program_title: `${ct}`,
        }),
        {
          "Content-Type": "application/json",
          "Refresh-Token": auth.refresh_token,
          Authorization: auth.token,
        }
      );

      if (responseData.ok == true) {
        console(responseData.msg);
      } else {
        console(responseData.msg);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const [studentAssignmentpop, setStudentAssignmentPop] = useState(false);
  const [assignmentId, setAssignmentId] = useState("");
  const [assignmentTitle, setAssignmentTitle] = useState("");
  const [assignmentStatus, setAssignmentStatus] = useState();
  const [assignmentTime, setAssignmentTime] = useState();
  const [assignmentSkills, setAssignmentSkills] = useState();
  const [assignmentDeadline, setAssignmentDeadline] = useState();
  const [grade, setGrade] = useState();

  const toggleStuAssignPopup = (
    assignment_id,
    title,
    skills,
    deadline,
    status,
    time,
    grade
  ) => {
    setStudentAssignmentPop(!studentAssignmentpop);
    setAssignmentId(assignment_id);
    setAssignmentTitle(title);
    setAssignmentStatus(status);
    setAssignmentTime(time);
    setAssignmentSkills(skills);
    setAssignmentDeadline(deadline);
    setGrade(grade);
    history(`/skillbox/${cid}/${ct}`);
  };

  const goToPathway = (pathwayData) => {
    history(`/skillbox/${cid}/${ct}/pathway/${pathwayData.section_id}`);
  }

  return (
    <div className="homepage">
      <div className="main-heading">
        <span>{ct}</span>
      </div>
      <div className="homepagecontent">
        <div className="home-courses-flex1">
          {CAMPUS_PROGRAMME_JSON &&
            CAMPUS_PROGRAMME_JSON.map((item) => {
              return (
                <div className="grid-div-home">
                  <div className="grid-card-img-home">
                    <img src={item.image}></img>
                  </div>
                  <div>
                    {/* <h3>{item.title}</h3> */}
                    <br></br>
                    <button
                      className="class-content"
                      style={{
                        backgroundImage: isScheduleManagement.isScheduleManagement
                          ? "linear-gradient(to right, #044c74 0%, #f84f20  120%, #044c74  100%)"
                          : "linear-gradient(to right, #f84f20 0%, #044c74  120%, #C04848  100%)",
                      }}
                      onClick={() => ContentClickHandler("schedule")}
                    >
                      <MdMeetingRoom
                        style={{
                          fontSize: "20px",
                          marginRight: "0.4rem",
                          verticalAlign: "text-top",
                        }}
                      />
                      {isStudent ? "Mark Attendance" : "Schedule Management"}
                    </button>
                    <button
                      className="video-content"
                      style={{
                        backgroundImage: isVideo.isVideo
                          ? "linear-gradient(to right, #044c74 0%, #f84f20  120%, #044c74  100%)"
                          : "linear-gradient(to right, #f84f20 0%, #044c74  120%, #C04848  100%)",
                      }}
                      onClick={() => ContentClickHandler("video")}
                    >
                      <MdDirectionsRun
                        style={{
                          fontSize: "20px",
                          marginRight: "0.4rem",
                          verticalAlign: "text-top",
                        }}
                      />
                      Course Pathways
                    </button>
                    <button
                      className="class-content"
                      style={{
                        backgroundImage: isContent.isContent
                          ? "linear-gradient(to right, #044c74 0%, #f84f20  120%, #044c74  100%)"
                          : "linear-gradient(to right, #f84f20 0%, #044c74  120%, #C04848  100%)",
                      }}
                      onClick={() => ContentClickHandler("content")}
                    >
                      <CgFileDocument
                        style={{
                          fontSize: "20px",
                          marginRight: "0.4rem",
                          verticalAlign: "text-top",
                        }}
                      />
                      Assignments
                    </button>
                    {/* <button 
                                    className="class-content"
                                    style={{
                                        backgroundImage : isSme.isSme  ? "linear-gradient(to right, #044c74 0%, #f84f20  120%, #044c74  100%)" : "linear-gradient(to right, #f84f20 0%, #044c74  120%, #C04848  100%)"
                                    }}
                                    onClick={() => SmeClickHandler()}
                                >
                                    <CgFileDocument
                                        style={{
                                        fontSize: "20px",
                                        marginRight: "0.4rem",
                                        verticalAlign: "text-top"
                                        }}
                                    />
                                        Program Plan
                                </button> */}
                  </div>
                </div>
              );
            })}
        </div>
        <div className="home-courses-flex2">
          <Scrollbars
            autoHide
            autoHideTimeout={2000}
            autoHideDuration={310}
            autoHeightMin={300}
            autoHeightMax={800}
            thumbMinSize={30}
            universal={true}
            style={{
              width: `100%`,
              height: "25rem",
              background: "white",
              borderRadius: "6px",
            }}
          >
            {isVideo.isVideo && userCampusProgram && (
              <h1 style={{ textAlign: "center", color: "#044c74" }}>
                Pathways
              </h1>
            )}
            {isVideo.isVideo &&
              Array.isArray(userCampusProgram) &&
              userCampusProgram.map((module, index) => (
                <div className="module-div" key={index}>
                  <div className="pathway-container">
                    <div className="module-title">
                      <h3>
                        <span>{index + 1}.</span> {module.section_name}
                      </h3>
                    </div>
                    {module.section_lock && module.section_lock == true ? (
                      <div className="module-lock">
                        <FcLock
                          style={{
                            fontSize: "20px",
                            marginRight: "0.4rem",
                            verticalAlign: "text-top",
                          }}
                        />
                      </div>
                    ) : (
                      <div className="Resume-button-container">
                        <button className="Resume-button" onClick={() => goToPathway(module)}>
                          <MdPlayArrow
                            style={{
                              fontSize: "20px",
                              marginRight: "0.4rem",
                              verticalAlign: "text-top",
                              marginTop: "0.1rem",
                            }}
                          />
                          Resume
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              ))}

            {isContent.isContent && (
              <h1 style={{ textAlign: "center", color: "#044c74" }}>
                Assignments
              </h1>
            )}
            {isContent.isContent &&
              assignmentList &&
              assignmentList.todo_info && (
                <h3 style={{ textAlign: "left", color: "#f84f20" }}>
                  To Do Assignments
                </h3>
              )}
            {isContent.isContent &&
              assignmentList &&
              assignmentList.todo_info.length == 0 &&
              assignmentList.todo_info_track.length == 0 && (
                <h4 style={{ textAlign: "center", color: "#044c74" }}>
                  No To Do Assignments
                </h4>
              )}
            {isContent.isContent &&
              assignmentList &&
              assignmentList.todo_info &&
              assignmentList.todo_info.map((assignment, i) => {
                return (
                  <tr class="assign-row-table" cellSpacing={"1px"}>
                    <td>
                      <p
                        style={{
                          marginLeft: "2rem",
                          textAlign: "center",
                          fontSize: "18px",
                          fontWeight: "bold",
                        }}
                      >
                        {assignment.title}
                      </p>
                    </td>
                    <td>
                      <p
                        style={{
                          marginLeft: "1rem",
                          textAlign: "center",
                          color: "black",
                        }}
                      >
                        <span style={{ fontSize: "15px", fontWeight: "bold" }}>
                          Deadline:{" "}
                        </span>
                        <span
                          style={{
                            color: "#f84f20",
                            fontSize: "18px",
                            fontWeight: "bold",
                          }}
                        >
                          {assignment.deadline}
                        </span>
                      </p>
                    </td>
                    <td>
                      <button
                        style={{
                          marginLeft: "1rem",
                          backgroundColor: "#044c74",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          toggleStuAssignPopup(
                            assignment.assignment_id,
                            assignment.title,
                            assignment.skills,
                            assignment.deadline,
                            "Ideal",
                            null,
                            null
                          );
                          assignmentLogStart(
                            assignment.assignment_id,
                            assignment.title,
                            assignment.skills,
                            assignment.deadline
                          );
                        }}
                      >
                        Submit
                      </button>
                    </td>
                  </tr>
                );
              })}
            {isContent.isContent &&
              assignmentList &&
              assignmentList.todo_info_track &&
              assignmentList.todo_info_track.map((assignment, i) => {
                return (
                  <tr class="assign-row-table" cellSpacing={"1px"}>
                    <td>
                      <p
                        style={{
                          marginLeft: "1rem",
                          textAlign: "center",
                          fontSize: "18px",
                          fontWeight: "bold",
                        }}
                      >
                        {assignment.title}
                      </p>
                    </td>
                    <td>
                      <p
                        style={{
                          marginLeft: "1rem",
                          textAlign: "center",
                          color: "black",
                        }}
                      >
                        <span style={{ fontSize: "15px", fontWeight: "bold" }}>
                          Deadline:{" "}
                        </span>
                        <span
                          style={{
                            color: "#f84f20",
                            fontSize: "18px",
                            fontWeight: "bold",
                          }}
                        >
                          {assignment.deadline}
                        </span>
                      </p>
                    </td>
                    <td>
                      <button
                        style={{
                          marginLeft: "1rem",
                          backgroundColor: "#044c74",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          toggleStuAssignPopup(
                            assignment.assignment_id,
                            assignment.title,
                            assignment.skills,
                            assignment.deadline,
                            "No Submission",
                            null,
                            null
                          );
                          assignmentLogStart(
                            assignment.assignment_id,
                            assignment.title,
                            assignment.skills,
                            assignment.deadline
                          );
                        }}
                      >
                        Start
                      </button>
                    </td>
                  </tr>
                );
              })}
            {isContent.isContent &&
              assignmentList &&
              assignmentList.other_info && (
                <h3 style={{ textAlign: "left", color: "#f84f20" }}>
                  In Progress/Graded Assignments
                </h3>
              )}
            {isContent.isContent &&
              assignmentList &&
              assignmentList.other_info.length == 0 && (
                <h4 style={{ textAlign: "center", color: "#044c74" }}>
                  No Progress/Graded Assignments
                </h4>
              )}
            {isContent.isContent &&
              assignmentList &&
              assignmentList.other_info &&
              assignmentList.other_info.map((assignment, i) => {
                return (
                  <tr class="assign-row-table" cellSpacing={"1px"}>
                    <td>
                      <p
                        style={{
                          marginLeft: "1rem",
                          textAlign: "center",
                          fontSize: "18px",
                          fontWeight: "bold",
                        }}
                      >
                        {assignment.title}
                      </p>
                    </td>
                    <td>
                      <p
                        style={{
                          marginLeft: "1rem",
                          textAlign: "center",
                          color: "black",
                        }}
                      >
                        <span style={{ fontSize: "15px", fontWeight: "bold" }}>
                          Deadline:{" "}
                        </span>
                        <span
                          style={{
                            color: "#f84f20",
                            fontSize: "18px",
                            fontWeight: "bold",
                          }}
                        >
                          {assignment.deadline}
                        </span>
                      </p>
                    </td>
                    <td>
                      <button
                        style={{
                          marginLeft: "1rem",
                          backgroundColor: "#044c74",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          toggleStuAssignPopup(
                            assignment.assignment_id,
                            assignment.title,
                            assignment.skills,
                            assignment.deadline,
                            assignment.cur_status,
                            assignment.create_timestamp,
                            assignment.grade
                          );
                        }}
                      >
                        {assignment.cur_status}
                      </button>
                    </td>
                  </tr>
                );
              })}
            {studentAssignmentpop && (
              <StudentAssignmentPop
                handleClose={toggleStuAssignPopup}
                assignmentDetails={{
                  assignmentId: assignmentId,
                  title: assignmentTitle,
                  skills: assignmentSkills,
                  deadline: assignmentDeadline,
                  status: assignmentStatus,
                  submitTime: assignmentTime,
                  grade: grade,
                }}
              ></StudentAssignmentPop>
            )}

            {/* {isSme.isSme &&
                        <table className="sme-table">
                            <thead>
                                <tr>
                                    <th>MSE Title</th>
                                    <th>Element ID</th>
                                    <th>Start</th>
                                </tr>
                            </thead>
                        </table>} */}
            {isSme.isSme && ct.includes("DSA") && isSchedule && (
              <div>
                <div className="plan-heading">
                  <button
                    style={{
                      backgroundImage:
                        "linear-gradient(to right, #044c74 0%, #f84f20  120%, #044c74  100%)",
                    }}
                  >
                    Tentative Schedule
                  </button>
                  <button onClick={() => planAssignmentHandler()}>
                    Assignment Problems
                  </button>
                </div>
                <div>
                  <Scrollbars
                    autoHide
                    autoHideTimeout={2000}
                    autoHideDuration={310}
                    autoHeightMin={300}
                    autoHeightMax={800}
                    thumbMinSize={30}
                    universal={true}
                    style={{
                      width: `100%`,
                      height: "25rem",
                      background: "white",
                      borderRadius: "6px",
                    }}
                  >
                    {scheduleList &&
                      scheduleList.map((p, i) => {
                        return (
                          <div className="schedule-item">
                            <p style={{ width: "80%" }}>{p.class_topic}</p>
                            <p style={{ width: "20%", textAlign: "left" }}>
                              {p.class_status ? "Done" : "Upcoming"}
                            </p>
                          </div>
                        );
                      })}
                  </Scrollbars>
                </div>
                <p>
                  This Schedule is tentative and the order of topics can change
                </p>
              </div>
            )}
            {isSme.isSme && ct.includes("DSA") && !isSchedule && (
              <div>
                <div className="plan-heading">
                  <button
                    style={{
                      backgroundImage:
                        "linear-gradient(to right, #f84f20 0%, #044c74  120%, #C04848  100%)",
                    }}
                    onClick={() => planAssignmentHandler()}
                  >
                    Tentative Schedule
                  </button>
                  <button
                    style={{
                      backgroundImage:
                        "linear-gradient(to right, #044c74 0%, #f84f20  120%, #044c74  100%)",
                    }}
                  >
                    Assignment Problems
                  </button>
                </div>
                <div className="assignment-tab">
                  <p onClick={() => assignClickHandler("1")}>
                    Assignment 1 : Arrays
                  </p>
                </div>
                {isAssgnOpen && (
                  <AssignmentPop
                    number={isAssignmentNumber}
                    handleClose={togglePopupAssign}
                  />
                )}
              </div>
            )}
            {isSme.isSme && !ct.includes("DSA") && (
              <div
                style={{
                  textAlign: "center",
                  fontSize: "30px",
                  marginTop: "25%",
                  backgroundColor: "#eef6fc",
                }}
              >
                Work in Progress
              </div>
            )}

            {isScheduleManagement &&
              isScheduleManagement.isScheduleManagement && (
                <ScheduleManagement courseId={ct} />
              )}
          </Scrollbars>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
