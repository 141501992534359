import React from "react";
import "./ClassCard.css";

const ClassCard = ({ classData, handleClick }) => {
    return (
        <div className="st-skillbox-pathway-card" key={classData.class_id}>
            <div className="st-skillbox-pathway-card__title">
                {classData.class_name}
            </div>
            {/* <div className="st-skillbox-pathway-card__tag">
                <Badge
                    {...{[classData.tag]: true }}
                />
            </div> */}
            <div className="st-skillbox-pathway-card_smp-text">
                <span>{classData.video_count}{" "}</span>Lecture <br />
                <span>{classData.document_count}{" "}</span>Class Resources <br />
            </div>
            
            <button className="st-skillbox-pathway-card__button" onClick={() => handleClick(classData.class_id)}>
                View Class
            </button>
        </div>
    );
}

export default ClassCard;