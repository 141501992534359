import React from 'react'

import "./PasswordResetMsg.css";
import { AuthContext } from '../../context/auth-context';
import { useContext } from 'react';

const PasswordResetMsg = () => {

  const auth = useContext(AuthContext);
  return (
    <React.Fragment>
            <div className="password-reset-msg-page">
                <h4>A link to reset your password was sent to your registered email.Reset your password and come back here to login again</h4>
                <h5>Check your spam folders too</h5>                
                <button onClick={()=>auth.logout()}>Login</button>
            </div>
    </React.Fragment>
  );
};

export default PasswordResetMsg;