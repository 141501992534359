import React from "react";

import { ProfileIcon } from "../../ProfileAnalyzerPage/PA_Components/ProfileIcon";
import { FileIcon } from "../assets/icons/FileIcon";
import { BulletPointsIcon } from "../assets/icons/BulletPointsIcon";

import GenerateResumeSection from "../sections/GenerateResumeSection";
import ActionVerbsSection from "../sections/ActionVerbsSection";
import BulletPointsSection from "../sections/BulletPointsSection";



export const SkillboxSideBarData = [
  {
    title: "Generate Resume",
    icon: (
      <ProfileIcon />
    ),
    component: GenerateResumeSection,
  },
  {
    title: "Action Verbs",
    icon: (
      <FileIcon />
    ),
    component: ActionVerbsSection,
  },
  {
    title: "Bullet Points",
    icon: (
      <BulletPointsIcon />
    ),
    component: BulletPointsSection,
  },
];
