import React from "react";
import DropdownButton from "./DropdownButton";

const ResumeSuccess = ({
  templateImage,
  finalTemplate,
  generatePDF,
  generateDOCX,
  pdfRef,
  setEditMode,
}) => {
  return (
    <div>
      <div className="section-title">Resume Generated Successfully</div>
      <div className="section-content">
        <div className="create-resume-container">
          <div style={{ maxWidth: "40%" }}>
            <div className="create-resume-details">
              <div className="content-title">Create Resume</div>
              <div className="content-title-desc">
                Using our simple resume builder, you can stand out from the
                competition and land your ideal job right now.
              </div>
              <DropdownButton
                generatePDF={generatePDF}
                generateDOCX={generateDOCX}
              />
              {/* <div className="generate-resume-button" onClick={generatePDF}>
                Download Pdf
              </div>
              <div className="generate-resume-button" onClick={generateDOCX}>
                Download Docx
              </div> */}
              <div
                className="generate-resume-button-outline"
                onClick={() => setEditMode(true)}
              >
                Edit
              </div>
            </div>
          </div>
          <div>
            {/* <img
              className="template-image"
              src={templateImage}
              alt="template"
            /> */}
            <div className="template-resume-final-ui">
              <div
                ref={pdfRef}
                dangerouslySetInnerHTML={{ __html: finalTemplate }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResumeSuccess;
