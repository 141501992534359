import React, { useState } from "react";
import "./AdminPage.css";
import LocalLibraryIcon from "@material-ui/icons/LocalLibrary";
import AdminUserManagement from "./AdminUserManagement";
import Courses from "./CourseManagement/Courses";
import Transactions from "./Payments/Transactions";
import AdminQRGenerator from "./AdminQRGenerator";
import AdminAssignment from "../Assignments/AdminAssignment";
import AdminLiveClass from "./AdminLiveClass";
import AdminCampusProgramme from "./AdminCampusProgramme";
import AdminLeads from "./AdminLeads";
import FreeCourseEnquiry from "./FreeCourseEnquiry";
import AdminUserQueries from "./AdminUserQueries";
import { GrUserSettings, GrDatabase } from "react-icons/gr";
import { HiCode } from "react-icons/hi";
import { MdOutlineQrCodeScanner, MdHelpOutline } from "react-icons/md";
import { Payment } from "@material-ui/icons";
import { BsJournalBookmarkFill } from "react-icons/bs";
const AdminPage = () => {
  const [adminRes, setAdminRes] = useState("Campus Programme Management");

  const handleAdminResponse = (item) => {
    setAdminRes(item);
  };

  return (
    <div className="adminPage">
      <div className="admin-left-div">
        <hr />
        <div
          onClick={() => handleAdminResponse("User Management")}
          className="admin-left-sec"
        >
          <GrUserSettings style={{ marginRight: "1rem", fontSize: "1.3rem" }} />
          User Management
        </div>
        <hr />
        <div
          onClick={() => handleAdminResponse("Campus Programme Management")}
          className="admin-left-sec"
        >
          <GrDatabase style={{ marginRight: "1rem" }} />
          Campus Programme Management
        </div>
        <hr />
        <div
          onClick={() => handleAdminResponse("Leads")}
          className="admin-left-sec"
        >
          <HiCode style={{ marginRight: "1rem", fontSize: "1.3rem" }} />
          Leads
        </div>
        <hr />
        {/* <div
          onClick={() => handleAdminResponse("Live Class")}
          className="admin-left-sec"
        >
          <LocalLibraryIcon style={{ marginRight: "1rem" }} />
          Live Class
        </div>
        <hr /> */}
        <div
          onClick={() => handleAdminResponse("Assignments")}
          className="admin-left-sec"
        >
          <BsJournalBookmarkFill
            style={{ marginRight: "1rem", fontSize: "1.3rem" }}
          />
          Assignments
        </div>
        <hr />
        <div
          className="admin-left-sec"
          onClick={() => handleAdminResponse("User Queries")}
        >
          <MdHelpOutline style={{ marginRight: "1rem", fontSize: "1.3rem" }} />
          User Queries
        </div>
        <hr />
        <div
          className="admin-left-sec"
          onClick={() => handleAdminResponse("Payments")}
        >
          <Payment style={{ marginRight: "1rem", fontSize: "1.3rem" }} />
          Payments
        </div>
        <hr />
        <div
          onClick={() => handleAdminResponse("Free Course Enquiries")}
          className="admin-left-sec"
        >
          <HiCode
            style={{ marginRight: "1rem", fontSize: "1.3rem" }}
          />
          Free Course Enquiries
        </div>
        <hr />
      </div>
      <hr />
      <div className={`admin-right-div ${adminRes == "Free Course Enquiries" && "admin-right-div-free-course-enquiry"}`}>
        {adminRes == "User Management" && <AdminUserManagement />}
        {adminRes == "QR Code Generator" && <AdminQRGenerator />}
        {adminRes == "Leads" && <AdminLeads />}
        {adminRes == "Campus Programme Management" && <Courses />}
        {adminRes == "Assignments" && <AdminAssignment />}
        {adminRes == "User Queries" && <AdminUserQueries />}
        {adminRes == "Payments" && <Transactions />}
        {adminRes == "Free Course Enquiries" && <FreeCourseEnquiry />}
      </div>
    </div>
  );
};

export default AdminPage;
