import React, { useEffect, useState, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./Auth.css";
import { AuthContext } from "../../context/auth-context";
import { AdminContext } from "../../context/admin-context";
import Button from "../../Components/Input/Button";
import Input from "../../Components/Input/Input";
import LoadingSpinner from "../../Components/Input/LoadingSpinner";
import {
  VALIDATOR_EMAIL,
  VALIDATOR_MINLENGTH,
  VALIDATOR_REQUIRE,
} from "../../Components/Input/validators";
import useForm from "../../Components/Hooks/formhooks";
import ImageUpload from "../../Components/Input/ImageUpload";
import { useHttpClient } from "../../Components/Hooks/http-hook";
import ErrorModal from "../../Components/Input/ErrorModel";
const swal = require("sweetalert2");

const Auth = () => {
  const auth = useContext(AuthContext);
  const admin = useContext(AdminContext);
  const history = useNavigate();
  const [isLoginMode, setIsLoginMode] = useState(true);
  const [formState, inputHandler, setFormData] = useForm({
    inputs: {
      email: {
        value: "",
        isValid: false,
      },
      password: {
        value: "",
        isValid: false,
      },
    },
    isValid: false,
  });

  const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

  const myRef = useRef(null);
  const scrollTop = async () => scrollToRef(myRef);

  useEffect(() => {
    scrollTop();
  }, []);

  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const submitHandler = async (event) => {
    event.preventDefault();

    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/auth/login`,
        "POST",
        JSON.stringify({
          email: formState.inputs.email.value,
          password: formState.inputs.password.value,
        }),
        {
          "Content-Type": "application/json",
        }
      );

      if (responseData.data.role == "admin") {
        admin.login(
          responseData.data.localId,
          responseData.data.displayName,
          responseData.data.email,
          "",
          "1234567890",
          responseData.data.idToken,
          responseData.data.refreshToken,
        );
        history("/admin/home");
      } else if (responseData.data.role == "instructor") {
        auth.login(
          responseData.data.localId,
          responseData.data.displayName,
          responseData.data.email,
          "",
          "1234567890",
          responseData.data.idToken,
          responseData.data.refreshToken,
          responseData.data.role,
          responseData.data.course_ids,
          "",
          responseData.data.surge_id
        );
        // console.log(auth)
        history("/instructor-home");
      } else if (responseData.data.role == "mentor") {
        auth.login(
          responseData.data.localId,
          responseData.data.displayName,
          responseData.data.email,
          "",
          "1234567890",
          responseData.data.idToken,
          responseData.data.refreshToken,
          responseData.data.role,
          responseData.data.course_ids,
          "",
          responseData.data.surge_id
        );
        // console.log(auth)
        history("/mentor-home");
      } else {
        auth.login(
          responseData.data.localId,
          responseData.data.displayName,
          responseData.data.email,
          "",
          "1234567890",
          responseData.data.idToken,
          responseData.data.refreshToken,
          responseData.data.role,
          responseData.data.course_ids,
          "",
          responseData.data.surge_id
        );
        // console.log(auth)
        history("/skillbox-v2");
      }
    } catch (err) {
      console.log(err);
    }
  };


  useEffect(() => {
    const urlPrarams = new URLSearchParams(window.location.search);
    const token = urlPrarams.get("token");
    if (token) {
      try{
        const login = async () => {
          const responseData = await sendRequest(
            `${process.env.REACT_APP_BACKEND_URL}/auth/login_with_custom_token`,
            "POST",
            JSON.stringify({
              custom_token: token,
            }),
            {
              "Content-Type": "application/json",
            }
          );
          if(responseData){
            auth.login(
              responseData.data.localId,
              responseData.data.displayName,
              responseData.data.email,
              "",
              responseData.data.mobile,
              responseData.data.idToken,
              responseData.data.refreshToken,
              responseData.data.role,
              responseData.data.course_ids,
              "",
              responseData.data.surge_id
            );
            // let url = window.location.href;
            // url = url.replace(`?token=${token}`, "");
            // window.history.pushState({}, "", url);
            // window.location.reload();
          };
        }
        login();
      }catch(err){
        console.log(err);
      }
    }
  }, []);

  const enrollHandler = () => {
    history("/enroll");
  };

  const passwordResetHandler = () => {
    swal.fire({
      title:
        "Password Reset is not available for now. Please contact admin to reset your password.",
      icon: "warning",
      confirmButtonText: "OK",
    });
  };

  useEffect(() => {
    if (error) {
      swal.fire({
        title: "Error!",
        text: error || "Something went wrong, please try again later.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
    clearError();
  }, [error, clearError]);

  return (
    <React.Fragment>
      {isLoginMode && (
        <React.Fragment>
          {/* <ErrorModal error={error} onClear={clearError} /> */}
          {isLoading && <LoadingSpinner asOverlay />}
          <div ref={myRef} className="authentication">
            {/* <hr /> */}
            <form onSubmit={submitHandler}>
              <div className="auth-form">
                <div className="formnaya"></div>
                <div className="auth-box">
                  <h2 className="mylogin-title">Login</h2>
                  <div className="form-class1">
                    <Input
                      type="email"
                      //label="Email"
                      placeholder="Email"
                      id="email"
                      value={formState.inputs.email.value}
                      isValid={formState.inputs.email.isValid}
                      errorText="Enter a valid Email"
                      validators={[VALIDATOR_EMAIL()]}
                      element="input"
                      onInput={inputHandler}
                    />
                  </div>

                  <div className="form-class1">
                    <Input
                      className="password-input"
                      type="password"
                      placeholder="Password"
                      errorText="Enter a valid password"
                      id="password"
                      validators={[VALIDATOR_MINLENGTH(6)]}
                      element="input"
                      onInput={inputHandler}
                      value={formState.inputs.password.value}
                      isValid={formState.inputs.password.isValid}
                    />
                  </div>
                  <Button type="submit" disabled={!formState.isValid}>
                    Login
                  </Button>
                </div>
              </div>
            </form>
          </div>
          {
            <div className="Buttons">
              <Button onClick={() => enrollHandler()}>Sign Up</Button>
              <Button onClick={passwordResetHandler}>Password Reset</Button>
            </div>
          }
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default Auth;
