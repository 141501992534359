import React, { useState, useEffect, useContext } from "react";
import { useHttpClient } from "../../../Components/Hooks/http-hook";
import { AuthContext } from "../../../context/auth-context";
import { Scrollbars } from "react-custom-scrollbars-2";
import "./Attendance.css";
import moment from "moment";

const Attendance = () => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [attendanceData, setAttendanceData] = useState([]);
  const [courseInfoList, setCourseInfoList] = useState([]);
  const [programId, setProgramId] = useState("");
  const [programName, setProgramName] = useState("");
  const [loading, setLoading] = useState(false);

  const auth = useContext(AuthContext);

  const getAllAttendanceDataCourseWise = async () => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/s3/student-course-attendance-data`,
        "GET",
        null,
        {
          "Refresh-Token": auth.refresh_token,
          Authorization: auth.token,
        }
      );
      setCourseInfoList(responseData.data);
    } catch (err) {}
  };

  useEffect(() => {
    getAllAttendanceDataCourseWise();
  }, []);

  const attendancePercentage = (totalClasses, attendedClasses) => {
    if (!totalClasses || !attendedClasses) {
      return 0;
    }
    if (totalClasses === 0) {
      return 0;
    }
    return Math.round((attendedClasses / totalClasses) * 100);
  };

  const formatTime = (timeStr) => {
    timeStr = new Date(Date.parse(fixDate(timeStr)));
    return moment(timeStr).format("MMMM Do YYYY, h:mm:ss a");
  };

  const fixDate = (dateStr) => {
    let [date, time] = dateStr.split(" ");
    if (!date.includes("-")) {
      return dateStr;
    }
    date = date.split("-");
    date = date[1] + "/" + date[0] + "/" + date[2];
    dateStr = date + " " + time;
    return dateStr;
  };

  const calcDelay = (inTime, scheduledStart) => {
    inTime = new Date(Date.parse(fixDate(inTime)));
    scheduledStart = new Date(Date.parse(fixDate(scheduledStart)));
    return moment(inTime).diff(scheduledStart, "minutes");
  };

  const scrollToDiv = (id) => {
    const element = document.getElementById(id);
    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  };

  const fetchAttendance = async (programId, programName) => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/s3/student-attendance-data/${programId}`,
        "GET",
        null,
        {
          "Refresh-Token": auth.refresh_token,
          Authorization: auth.token,
        }
      );
      console.log(responseData);

      responseData.data[0].sort((a, b) => {
        return (
          new Date(Date.parse(fixDate(a.scheduled_start))) -
          new Date(Date.parse(fixDate(b.scheduled_start)))
        );
      });
      setAttendanceData(responseData.data[0]);
      setProgramName(programName);
      scrollToDiv("attendance-table-container");
    } catch (err) {}
  };

  const downloadAttendanceExcel = async (programId) => {
    try {
      setLoading(true);
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/s3/student-attendance-data-excel/${programId}`,
        {
          method: "GET",
          responseType: "blob",
          headers: {
            accept:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "Refresh-Token": auth.refresh_token,
            Authorization: auth.token,
          },
        }
      );
      if (response) {
        const blob = await response.blob();
        const downloadUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.download = "Attendance.xlsx";
        link.click();
      } else {
        const errorMessage = await response.text();
        console.error(`Failed to download Excel file: ${errorMessage}`);
      }
    } catch (err) {
      console.error("Error while downloading Excel file:", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="attendance-page">
        <div className="attendance-components">
          <h1 className="main-heading">Your Attendance </h1>
          <div>
            <div>
              <div className="attendance-summary-grid">
                {courseInfoList &&
                  courseInfoList.map((p, i) => {
                    return (
                      <div className="attendance-summary-card" key={i}>
                        <h3>{p.program_name}</h3>
                        <p className="total-classes">
                          Total Classes:{" "}
                          <span>{p.total_classes_per_program || 0}</span>
                        </p>
                        <div className="attendance-stats">
                          <div className="stat">
                            <p>Present</p>
                            <p>{p.total_classes_attended || 0}</p>
                          </div>
                          <div className="stat">
                            <p>Absent</p>
                            <p>
                              {p.total_classes_per_program -
                                p.total_classes_attended}
                            </p>
                          </div>
                          <div className="stat">
                            <p>Percentage</p>
                            <p>
                              {attendancePercentage(
                                p.total_classes_per_program,
                                p.total_classes_attended
                              )}
                              %
                            </p>
                          </div>
                        </div>
                        <div className="attendance-summary-btn">
                          {p.total_classes_per_program > 0 ? (
                            <>
                              <button
                                className="view-button"
                                onClick={() => {
                                  fetchAttendance(p.program_id, p.program_name);
                                }}
                              >
                                View
                              </button>
                              <button
                                className="download-button"
                                disabled={loading}
                                onClick={() => {
                                  downloadAttendanceExcel(p.program_id);
                                }}
                              >
                                Download
                              </button>
                            </>
                          ) : (
                            <button>No Data</button>
                          )}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
            <div id="attendance-table-container">
              <h1 className="main-heading">Attendance Class Wise </h1>
              <Scrollbars
                autoHide
                autoHideTimeout={2000}
                autoHideDuration={310}
                autoHeightMin={300}
                autoHeightMax={800}
                thumbMinSize={30}
                universal={true}
                style={{
                  width: `100%`,
                  height: "25rem",
                  background: "white",
                  borderRadius: "6px",
                }}
              >
                {programName && (
                  <>
                    <h3 className="attendance-table-heading">
                      {programName} Attendance
                    </h3>
                    <div className="attendance-table-stats">
                      <div>Total Classes: {attendanceData.length}</div>
                      <div>
                        Total Present:{" "}
                        {attendanceData.filter((p) => p.isPresent === 1).length}
                      </div>
                    </div>
                  </>
                )}
                <table className="attendance-table">
                  <thead>
                    <tr>
                      <th>S.No.</th>
                      <th>Title</th>
                      <th>Meeting Time</th>
                      <th>Attendance</th>
                    </tr>
                  </thead>
                  <tbody>
                    {attendanceData && attendanceData.length > 0 ? (
                      attendanceData.map((p, i) => {
                        return (
                          <tr key={i}>
                            <td>{attendanceData.indexOf(p) + 1}</td>
                            <td>{p.title}</td>
                            <td>{formatTime(p.scheduled_start)}</td>
                            <td>
                              {p.isPresent === 1 ? (
                                <span className="present">
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M9 16.17L4.83 12L3.41 13.41L9 19L21 7L19.59 5.59L9 16.17Z"
                                      fill="#00C48C"
                                    />
                                  </svg>
                                </span>
                              ) : (
                                <span className="absent">
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M18 6L6 18"
                                      stroke="#FF3D71"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M6 6L18 18"
                                      stroke="#FF3D71"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </span>
                              )}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="3">No Data Found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </Scrollbars>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Attendance;
