import React, { useState } from "react";
import DragDrop from "../PA_Components/DragDrop";

import Modal from "../PA_Components/ResumeModal";
import SavedResume from "./SavedResume_PA";
import JobDesc from "./JobDesc";
import CloseIcon from "../PA_Components/CloseIcon";

const NewResume = ({ addNew, setAddNew, fromSaved, setFromSaved }) => {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setAddNew(false);
    setFromSaved(false);
    selectedResume && setIsSelected(true);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const selectedFiles = [
    "resume.pdf",
    "resume.doc",
    "resume.docx",
  ];

  const handleClickOpen = () => {
    setOpen(true);
    setFromSaved(true);
  };

  const [selectedResume, setSelectedResume] = useState(null);

  const [isSelected, setIsSelected] = useState(false);

  const handleItemClick = (resume) => {
    setSelectedResume(resume);
    console.log(resume);
  };


  const [forScan, setForScan] = useState(false);

  const handleForScan = () => {
    setForScan(true);
  }


  return !addNew && !fromSaved && !isSelected ? (
    <div className="profile-contact-input-section">
      <div className="resume-upload-section">
        <div className="resume-upload-section-title">
          Add Resume
          <div className="resume-upload-section-subtitle">
            Upload your resume
          </div>
        </div>

        <button
          className="resume-upload-button"
          onClick={() => setAddNew(true)}
        >
          Add New
        </button>

        <div className="resume-upload-button-outline" onClick={handleClickOpen}>
          Select from Your Resume
        </div>
      </div>
    </div>
  ) : !fromSaved && !isSelected ? (
    <DragDrop setAddNew={setAddNew} />
  ) : !isSelected ? (
    <div
      style={{
        textAlign: "center",
        display: "block",
        padding: 30,
        margin: "auto",
      }}
    >
      <Modal
        isOpen={open}
        onClose={() => setOpen(false)}
        length={selectedFiles.length}
      >
        <div style={{ textAlign: "center" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <h2 style={{ marginLeft: "40%" }}>Select from Your Resume</h2>
            <h4 onClick={handleClose} style={{ cursor: "pointer", marginRight: "4%" }}>
              <CloseIcon />
            </h4>
          </div>
          <div
            style={{
              padding: "2% 20% 2%",
              height: selectedFiles.length > 4 ? "48vh" : "auto",
              overflowY: selectedFiles.length > 4 ? "scroll" : "auto",
            }}
          >
            <SavedResume
              selectedFiles={selectedFiles}
              onItemClick={handleItemClick}
              noDelete={true}
            />
          </div>
          <div style={{ display: "flex", width: "60%", margin: "0 auto" }}>
            <button style={{ fontFamily: "sans-serif" }} onClick={handleClose}>
              Select
            </button>
          </div>
        </div>
      </Modal>
    </div>
  ) : (
    <JobDesc selectedResume={selectedResume} />
  );
};

export default NewResume;
