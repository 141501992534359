import React, { useState, useEffect, useContext } from "react";
import DataTable from "react-data-table-component";
import CalendarSvg from "../../../../Components/Svgs/CalendarSvg";
import BackArrowSvg from "../../../../Components/Svgs/BackArrowSvg";
import NewCandidatesSvg from "../../../../Components/Svgs/NewCandidatesSvg";
import InfoBox from "../../../../Components/InfoBox/InfoBox";
import NavLegends from "./Components/NavLegends";
import "./CourseHistory.css";
import { AuthContext } from "../../../../../../../context/auth-context";
import { useHttpClient } from "../../../../../../../Components/Hooks/http-hook";

const customStyles = {
  headCells: {
    style: {
      paddingLeft: "8px",
      paddingRight: "8px",
      fontSize: "16px",
      fontWeight: "600",
      color: "#004C73",
    },
  },
  rows: {
    style: {
      borderBottom: "none",
      position: "relative",
    },
    highlightOnHoverStyle: {
      backgroundColor: "#e0e0e0",
    },
  },
  cells: {
    style: {
      borderBottom: "none",
    },
  },
  table: {
    style: {
      borderRadius: "20px",
      overflow: "scroll",
      scrollbarWidth: "none",
      padding: "0px 20px",
      height: "50vh",
      boxShadow: "0 0 10px rgba(0, 0, 0, 0.05)",
    },
  },
  pagination: {
    style: {
      marginTop: "20px",
      border: "none",
      backgroundColor: "none",
    },
    pageButtonsStyle: {
      backgroundColor: "#fff",
      borderRadius: "5px",
      marginRight: "5px",
      border: "1px solid #004C73",
      cursor: "pointer",
      svg: {
        fill: "#004C73",
      },
      "&:hover": {
        backgroundColor: "#D2F0FF",
        svg: {
          fill: "#004C73",
        },
      },
      "&:disabled": {
        backgroundColor: "#fff",
        border: "1px solid #ccc",
        svg: {
          fill: "#ccc",
        },
        "&:hover": {
          backgroundColor: "#fff",
        },
      },
      "&:active": {
        backgroundColor: "#D2F0FF",
        svg: {
          fill: "#fff",
        },
      },
    },
  },
  expanderButton: {
    style: {
      backgroundColor: "none",
      padding: "0px",
      margin: "0px",
      width: "20px",
      height: "20px",
      border: "none",
      borderRadius: "none",
      transition: "all 0.3s ease",
      boxShadow: "none",
      "&:hover": {
        backgroundColor: "none",
        boxShadow: "none",
      },
      "&:active": {
        backgroundColor: "none",
        boxShadow: "none",
      },
      "&:focus": {
        backgroundColor: "none",
        boxShadow: "none",
      },
    },
  },
};

const RoleBasedOverallIDataSummary = [
  {
    key: "totalQuestions",
    icon: <CalendarSvg />,
    count: 0,
    title: "Total no. of questions",
  },
  {
    key: "totalSubjects",
    icon: <NewCandidatesSvg />,
    count: 0,
    title: "Total no. of subjects",
  },
  {
    key: "totalTopics",
    icon: <CalendarSvg />,
    count: 0,
    title: "Total no. of topics",
  },
  // {
  //   key: "totalSubTopics",
  //   icon: <NewCandidatesSvg />,
  //   count: 0,
  //   title: "Total no. of subtopics",
  // },
];

const CourseHistory = ({
  handleTabChange,
  selectedCoursesData,
  previousTab
}) => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [courseData, setCourseData] = useState([]);
  const [infoBoxData, setInfoBoxData] = useState(RoleBasedOverallIDataSummary);
  const [selectedSemester, setSelectedSemester] = useState(null);
  const [selectedSemesterId, setSelectedSemesterId] = useState(1);
  const auth = useContext(AuthContext);

  useEffect(() => {
    const fetchCourseData = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/api/qgt/courses/${selectedCoursesData.id}`,
          "GET",
          null,
          {
            "Content-Type": "application/json",
            Authorization: auth.token,
          }
        );
        if (responseData.ok) {
          setCourseData(responseData.data);
          setInfoBoxData(prevData => prevData.map(item => ({
            ...item,
            count: responseData.infoBoxData[item.key]
          })));
          if (responseData.data.length > 0) {
            setSelectedSemester(responseData.data[0]);
          }
        }
      } catch (err) {
        console.log(err);
      }
    };

    if (selectedCoursesData) {
      fetchCourseData();
    }
  }, [sendRequest, selectedCoursesData, auth.token]);

  useEffect(() => {
    if (courseData.length > 0 && selectedSemesterId) {
      const selected = courseData.find(semester => semester.semester === `Semester ${selectedSemesterId}`);
      setSelectedSemester(selected || courseData[0]);
    }
  }, [selectedSemesterId, courseData]);

  const columns = [
    {
      name: "Sr. No.",
      cell: (row, index) => index + 1,
      width: "100px",
    },
    {
      name: "Subject",
      selector: (row) => row.subject,
      width: "250px",
    },
    {
      name: "No. of Questions",
      selector: (row) => row.noOfQuestions,
    },
    {
      name: "Easy",
      selector: (row) => row.easy,
    },
    {
      name: "Medium",
      selector: (row) => row.medium,
    },
    {
      name: "Hard",
      selector: (row) => row.hard,
    },
  ];

  const ExpandedComponent = ({ data }) => (
    <div style={{ padding: "10px 20px" }}>
      <strong>Topics:</strong>
      <ul>
        {data.topics.map((topic, index) => (
          <li key={index}>
            <strong>{topic.topic}</strong>: {topic.subTopics.join(", ")}
          </li>
        ))}
      </ul>
    </div>
  );

  const downloadCSV = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/qgt/courses/${selectedCoursesData.id}/download`,
        {
          method: 'GET',
          headers: {
            Authorization: auth.token,
          },
        }
      );

      if (response.ok) {
        const contentDisposition = response.headers.get('Content-Disposition');
        const filenameMatch = contentDisposition && contentDisposition.match(/filename="?(.+)"?/i);
        const filename = filenameMatch ? filenameMatch[1] : `course_${selectedCoursesData.id}_questions.csv`;

        const blob = await response.blob();
        
        const url = window.URL.createObjectURL(blob);
        
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      } else {
        throw new Error('Failed to download CSV');
      }
    } catch (error) {
      console.error('Error downloading CSV:', error);
    }
  };

  return (
    <div className="courseHistory">
      <div className="courseHistorySection1">
        <div className="courseHistoryHeader">
          <div className="courseHistoryHeaderContainer">
            <div
              className="courseHistoryHeaderIcon"
              onClick={() => handleTabChange(previousTab)}
            >
              <BackArrowSvg />
            </div>
            {selectedCoursesData.title}
          </div>
          <button className="courseHistoryHeaderButton" onClick={downloadCSV}>Download CSV</button>
        </div>
        <div className="courseHistoryBoxesContainer">
          <InfoBox InfoData={infoBoxData} />
          <NavLegends
            selectedSemesterId={selectedSemesterId}
            setSelectedSemesterId={setSelectedSemesterId}
            semesterLength={courseData.length}
            selectedData={courseData}
            setSelectedSemester={setSelectedSemester}
          />
        </div>
        <div className="courseHistoryTable datatable-wrapper">
          {isLoading ? (
            <div>Loading...</div>
          ) : error ? (
            <div>Error: {error}</div>
          ) : (
            <DataTable
              columns={columns}
              data={selectedSemester ? selectedSemester.subjects : []}
              pagination
              paginationPerPage={10}
              customStyles={customStyles}
              expandableRows
              expandableRowsComponent={ExpandedComponent}
              expandOnRowClicked
              expandableRowDisabled={(row) => row.topics.length === 0}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CourseHistory;
