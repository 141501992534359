import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars-2";
import { MdExpandMore, MdDoubleArrow } from "react-icons/md";
import { GoPlus } from "react-icons/go";
import { FaTrashAlt, FaPencilAlt, FaHandPointRight } from "react-icons/fa";
import {  AiFillEye } from "react-icons/ai"
import $ from "jquery";
import { MdOutlineSaveAlt } from "react-icons/md";

import './AdminCampusProgrammeEdit.css';
import { useHttpClient } from '../../Components/Hooks/http-hook';
import { AdminContext } from '../../context/admin-context';
import { AuthContext } from '../../context/auth-context';
import { AddModuleModal } from '../../Components/AddModuleModal';
import { AddCourseModal } from '../../Components/AddCourseModal';

var new_title = "";
var new_description = "";
var new_url = "";
var new_visibility = true;

var e_title = "";
var e_description = "";
var e_url = "";
var e_visibility = "";

{$(document).on('click', '.edit-icon', function() {
  // if (isLectureSaveOpen) {
  //   alert('save already edited info')
  // }
  $(this).parent().siblings('td.data').each(function(i) {  
    var content = $(this).html();  
    if(i!=3){
      $(this).html('<input value="' + content + '" class="new-data" />');
    } 
    else{
      if(content=="Locked"){
        $(this).html('<input type="checkbox" class="new-data" value="Locked" checked />');
      }
      else{
        $(this).html('<input type="checkbox" class="new-data" value="Visible" />');
      }
    } 
  });  
  $(this).siblings('.save-icon').show();  
  $(this).hide();
})}

$(document).on('click', '.save-icon', function() {  
  $('.new-data').each(function() {  
    var content = $(this).val() || " ";   
    $(this).html(content);  
    $(this).contents().unwrap();  
  });  
  $(this).siblings('.edit-icon').show();  
  $(this).hide(); 
  // setIsLectureSaveOpen(false) 
}); 

// $(document).on('click', '.delete-icon', function() {  
//   if(window.confirm("Are you sure you want to Delete?")==true){
//     $(this).parents('tr').remove();  
//   }
// });

// $(document).on('click', '.add-new-lecture', function() {  
//   $('.module-table').append('<tr><td class="table-title data">'+new_title+'</td><td class="table-description data">'+new_description+'</td><td class="table-url data">'+new_url+'</td><td class="table-lock data">'+new_visibility+'</td><td><button class="save-icon">Save</button><button style="font-size: 12px; font-family: sans-serif; height: 1.5rem;" class="edit-icon">EDIT</button></td><td><button style="font-size: 12px; font-family: sans-serif; height: 1.5rem;" class="delete-icon">DEL</button></td></tr>'); 
// }); 

$(document).on('change', '.add-title', function() {
  new_title = this.value;
});
$(document).on('change', '.add-description', function() {
  new_description = this.value;
});
$(document).on('change', '.add-url', function() {
  new_url = this.value;
});
$(document).on('change', '.add-visibility', function() {
  if($(".add-visibility option:selected").text()=="Visible"){
    new_visibility = false
  }
  else{
    new_visibility = true
  }
});

const AdminCampusProgrammeEdit = () => {
  // const demoClickHandler = () => {
  //   var tempArr = courseContentJson;
  //   console.log(tempArr.modules)
  // }
  const admin = useContext(AdminContext)
  const auth = useContext(AuthContext)
  const addModuleClickHandler = (new_module) => {
    console.log(new_module, 'check module')
    var tempJson = courseContentJson;
    tempJson.modules = [...tempJson.modules, { 'module_name': new_module, 'module_list': [] }]
    setCourseContentJson(courseContentJson = tempJson);
    setIsJsonEdited({
      "isJsonEdited": true
    })
  }
  const [isLectureSaveOpen, setIsLectureSaveOpen] = useState(false)

  const addModuleCourseClickHandler = (new_module_course) => {
    var tempJson = courseContentJson;
    console.log(tempJson.modules[moduleName.index], 'check.....')
    if(Object.keys(tempJson.modules[moduleName.index]["module_list"]).length>=1)
    {
      tempJson.modules[moduleName.index]["module_list"] = [ ...tempJson.modules[moduleName.index]["module_list"], { "module": new_module_course,"lectures": [] } ]
    }
    else
    {        
      tempJson.modules[moduleName.index]["module_list"] = Array({"module": new_module_course,"lectures": []})
    }
    setCourseContentJson(courseContentJson = tempJson);
    setModuleList(courseContentJson.modules[moduleName.index].module_list)
    setIsJsonEdited({
      "isJsonEdited": true
    })
  }

  const saveLectureClickHandler = (index, idx) => {
      $('.new-data').each(function(i) {  
      var content = $(this).val() || " ";  
      switch (i) {
        case 0:
          e_title = content;
          break;
        case 1:
          e_description = content;
          break;
        case 2:
          e_url = content;
          break;
        case 3:
          if($(this)[0].checked)
          {
            e_visibility = true;
          }
          else
          {
            e_visibility = false;
          }
          break;
      }
    });
    var tempJson = courseContentJson;
    tempJson.modules[moduleName.index]["module_list"][index]["lectures"][idx] = {"description":e_description, "lock": e_visibility, "title": e_title, "url": e_url}
    setCourseContentJson(courseContentJson = tempJson);
    setModuleList(courseContentJson.modules[moduleName.index].module_list)
    setIsJsonEdited({
      "isJsonEdited": true
    })
  }

  const deleteLectureClickHandler = (index, idx) => {
    if(window.confirm("Are you sure you want to Delete?")==true){
      var tempJson = courseContentJson;
      console.log(tempJson.modules[moduleName.index]["module_list"][index]["lectures"][idx])
      tempJson.modules[moduleName.index]["module_list"][index]["lectures"].splice(idx, 1);
      setCourseContentJson(courseContentJson = tempJson);
      setModuleList(courseContentJson.modules[moduleName.index].module_list)
      setIsJsonEdited({
        "isJsonEdited": true
      })
    }
  }

  const history = useNavigate();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  let [courseContentJson, setCourseContentJson] = useState({})
  const [batchTitleJson, setBatchTitleJson] = useState({})
  const [editType, setEditType] = useState({
    "type": "videos"
  })

  const [moduleName, setModuleName] = useState({
    "name": "",
    "index": null
  })
  const [moduleList, setModuleList] = useState([])
  const [moduleDropValue, setModuleDropValue] = useState("Module")
  const [courseDropValue, setcourseDropValue] = useState("Course")
  const [courseName, setCourseName] = useState({
    "name": ""
  })

  const [batch, setBatch] = useState({
    "name": ""
  })

  const editTypeClickHandler =(type) => {
    console.log(type)
    setEditType({
      "type": type
    })
  }

  const editModuleClickHandler =(module, index) => {
    console.log(module)
    setModuleName({
      "name": module,
      "index": index
    })
    setModuleList(courseContentJson.modules[index].module_list)
    setModuleDropValue(module)
  }
    
  const editModuleCourseClickHandler =(course) => {
    // console.log(module)
    setCourseName({
      "name": course
    })
    setcourseDropValue(course)
  }

  const editBatchClickHandler =(module) => {
    console.log(module)
    setBatch({
      "name": module
    })
  }  

  let { cid, bid } = useParams();

  const getCourseContentJson = async() => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/db/prog-info-json`,
        "POST",
        JSON.stringify({
          title:cid,
        }),
        {
          "Content-Type": "application/json",
          "Refresh-Token": admin.refresh_token,
          "Authorization": admin.token,
        }
      );
      console.log(admin)
      if (responseData.ok) {
        console.log(responseData.data)
        return responseData.data
      }
    } catch (err) {
      console.log("got error while getting programmes", err)
    }
  };

  useEffect(() => {
    getCourseContentJson().then(
      response => setCourseContentJson(response)
    )   
    getCourseContentJson().then(
      responseData => setModuleName({"name": responseData.modules[0].module_name, "index": 0})
    )
    getCourseContentJson().then(
      responseDataList => setModuleList(responseDataList.modules[0].module_list)
    )    
  }, [])


  // useEffect(() => {
  //     getBatchSSMEInfo();
  //   }, []);

  const getBatchSSMEInfo = async() => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/db/distinct-mse-ids/${bid}`,
          "GET",
        );
        if (responseData.ok) {
          console.log(responseData.data, 'batch title details')
          setBatchTitleJson(responseData.data)
          // return responseData.data
        }
      } catch (err) {
        console.log("got error while getting batch ids", err)
      }
  };


  const editTypeClickHandlerMse =(type) => {
      console.log(type)
      setEditType({
        "type": type
      })
      getBatchSSMEInfo();
    } 

  const [isAddActive, setIsAddActive] = useState({"isAddActive": false});
  const [isSMEActive, setIsSMEActive] = useState({"isAddActive": false});
  const [isJsonEdited, setIsJsonEdited] = useState({"isJsonEdited":false});

  const saveChangesClickHandler = () => {
    setIsJsonEdited({
      "isJsonEdited": false
    });
    try {
      const responseData1 = sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/db/prog-json-info-update`,
        "POST",
        JSON.stringify({
          title: cid,
          info_json: {title: cid, modules: courseContentJson.modules},
        }),
        {
          "Content-Type": "application/json",
          "Refresh-Token": admin.refresh_token,
          "Authorization": admin.token,
        }
      );
      // if (responseData.ok) {
      //   console.log(responseData.data)
      //   return responseData.data
      // }
    } catch (err) {console.log("error in updating Json ",err)}
    alert("Chages Saved Successfully!");
  }

  const addLectureClickHandler =(val, index) => {
    if (val == "true") {
      setIsAddActive({
          "isAddActive": true
      })
    } else {
      setIsAddActive({
        "isAddActive": false
      });
      var tempJson = courseContentJson;
      if(Object.keys(tempJson.modules[moduleName.index]["module_list"][index]).length>1)
      {
        tempJson.modules[moduleName.index]["module_list"][index].lectures = [ ...tempJson.modules[moduleName.index]["module_list"][index].lectures, { "description": new_description,"lock": new_visibility, "title": new_title, "url": new_url } ] 
        // tempJson.modules[index].lectures = [...tempJson.modules[index].lectures, { "description": new_description,"lock": new_visibility, "title": new_title, "url": new_url }]
      }
      else
      {        
        tempJson.modules[moduleName.index]["module_list"][index].lectures = Array({"description":new_description, "lock": new_visibility, "title": new_title, "url": new_url})
      }
      setCourseContentJson(courseContentJson = tempJson);
      setModuleList(courseContentJson.modules[moduleName.index].module_list)
      setIsJsonEdited({
        "isJsonEdited": true
      })
    }
  }  
  const [show, setShow] = useState(false);
  const [courseShow, setCourseShow] = useState(false);
  
  const addNewMSEAddHandler =(val) => {
    if (val == "true") {
      setIsSMEActive({
          "isAddActive": true
      })
    } else {
      setIsSMEActive({
            "isAddActive": false
        })
    }
    console.log(isAddActive)
  }
  
  const viewMSESubmission = (mse) => {
    history(`/admin/home/${cid}/${bid}/${mse}`);
  }

  if(isJsonEdited.isJsonEdited)
  {
    window.onbeforeunload = function(e) {
      return "Do you want to leave this page?";
    };
  }

  let editTypeDropValue = editType.type ? editType.type : "Select Edit Type"  

  return (
    <div className="admin-campus-programme-edit">
        <div style={{display: "flex", flexDirection: "row"}}>
        <h2 style={{width: "90%"}}>{cid} - {editType.type}</h2>
        {/* <button onClick={() => {demoClickHandler()}} style={{width: "auto", height:"2rem", color:"#fff", backgroundColor: "#bbb"}}>Click</button> */}
        {isJsonEdited.isJsonEdited && <button className="save-changes" onClick={() => saveChangesClickHandler()}><MdOutlineSaveAlt style={{fontSize: "1.6rem", marginRight: "0.5rem", verticalAlign: "middle", marginBottom: "0.2rem"}}></MdOutlineSaveAlt>Save changes</button>}
        {!isJsonEdited.isJsonEdited &&
        <div class="dropdown">
          <button class="dropbtn">{editTypeDropValue}<MdExpandMore style={{fontSize: "1.4rem", float: "right"}}></MdExpandMore></button>
          <div class="dropdown-content">
          <a onClick = {() => editTypeClickHandler("videos")}>Videos</a>
          <a onClick = {() => editTypeClickHandler("slides")}>Slides</a>
          <a onClick = {() => editTypeClickHandler("assignments")}>Assignments</a>
          <a onClick = {() => editTypeClickHandlerMse("MSEelement")}>MSE Element</a>
          </div>
        </div> 
        }
        </div>
        {/* <li style={{
          display: "flex",
          flexDirection: "row"
        }}
        >
          <button 
            className="edit-button"
            style={{
              backgroundImage: editType.type=="videos" ? "linear-gradient(to right, #044c74 0%, #f84f20  120%, #044c74  100%)" : "linear-gradient(to right, #f84f20 0%, #044c74  120%, #C04848  100%)"
            }}
            
          >
            Videos
          </button>
          <button 
            className="slides-button"
            style={{
              backgroundImage: editType.type=="slides" ? "linear-gradient(to right, #044c74 0%, #f84f20  120%, #044c74  100%)" : "linear-gradient(to right, #f84f20 0%, #044c74  120%, #C04848  100%)"
            }}
            
          >
            Slides
          </button>
          <button 
            className="assignments-button"
            style={{
              backgroundImage: editType.type=="assignments" ? "linear-gradient(to right, #044c74 0%, #f84f20  120%, #044c74  100%)" : "linear-gradient(to right, #f84f20 0%, #044c74  120%, #C04848  100%)"
            }}
            
          >
            Assignments
          </button>
        </li> */}
        <div>
          <Scrollbars
            autoHide
            autoHideTimeofut={1000}
            autoHideDuration={310}
            autoHeightMin={300}
            autoHeightMax={800}
            thumbMinSize={30}
            universal={true}
            style={{
            width: `100%`,
            height: "30rem",
            background: "#f5f5f0",
            borderRadius: "6px",
            }}
          >
            {editType.type=="videos" && courseContentJson.modules &&
            <div style={{display: "flex", flexDirection: "row"}}>
              <div class="dropdown dropdown-modules">
                <button class="dropbtn dropbtn-modules">{moduleDropValue}<MdExpandMore style={{fontSize: "1.5rem", float: "right"}}></MdExpandMore></button>
                <div class="dropdown-content">
                  {courseContentJson.modules.map((p, index) => {
                    return(
                      <div>
                        <a onClick = {() => editModuleClickHandler(p.module_name, index)}>{p.module_name}</a>
                      </div>
                    )
                  })} 
                </div>
              </div>
              <button class="add-new-module" onClick={() => setShow(true)}><GoPlus style={{fontSize: "1.2rem", marginRight: "0.5rem", verticalAlign: "middle"}}></GoPlus>Add New Module</button>
              <AddModuleModal onClose={() => setShow(false)} show={show} addType="Module" onAdd={(new_module) => addModuleClickHandler(new_module)}>
              </AddModuleModal>
            </div>
            }
            {!courseContentJson.modules &&              
              <div style={{display: "flex", flexDirection: "row"}}>
              <div class="dropdown dropdown-modules">
                <button class="dropbtn dropbtn-modules">{courseDropValue}<MdExpandMore style={{fontSize: "1.5rem", float: "right"}}></MdExpandMore></button>
              </div>
              <button class="add-new-module" onClick={() => setShow(true)}><GoPlus style={{fontSize: "1.2rem", marginRight: "0.5rem", verticalAlign: "middle"}}></GoPlus>Add New Module</button>
              <AddModuleModal onClose={() => setShow(false)} show={show} addType="Module" onAdd={(new_module) => addModuleClickHandler(new_module)}>
              </AddModuleModal>
            </div>  
            }
            {courseContentJson.modules && moduleName.name && moduleList &&              
              <div style={{display: "flex", flexDirection: "row"}}>
              <div class="dropdown dropdown-modules">
                <button class="dropbtn dropbtn-modules">{courseDropValue}<MdExpandMore style={{fontSize: "1.5rem", float: "right"}}></MdExpandMore></button>
                <div class="dropdown-content">
                  {moduleList.map((q, index) => {
                    return(
                      <a href="#" onClick = {() => editModuleCourseClickHandler(q.module)}>{q.module}</a>
                    )
                  })} 
                </div>
              </div>
              <button class="add-new-module" onClick={() => setCourseShow(true)}><GoPlus style={{fontSize: "1.2rem", marginRight: "0.5rem", verticalAlign: "middle"}}></GoPlus>Add New Course</button>
              <AddCourseModal onClose={() => setCourseShow(false)} show={courseShow} addType="Course" onAdd={(new_module) => addModuleCourseClickHandler(new_module)}>
              </AddCourseModal>
            </div>  
            }
            {courseContentJson.modules && moduleName.name && !moduleList &&              
              <div style={{display: "flex", flexDirection: "row"}}>
              <div class="dropdown dropdown-modules">
                <button class="dropbtn dropbtn-modules">{courseDropValue}<MdExpandMore style={{fontSize: "1.5rem", float: "right"}}></MdExpandMore></button>
              </div>
              <button class="add-new-module" onClick={() => setCourseShow(true)}><GoPlus style={{fontSize: "1.2rem", marginRight: "0.5rem", verticalAlign: "middle"}}></GoPlus>Add New Course</button>
              <AddCourseModal onClose={() => setCourseShow(false)} show={courseShow} addType="Course" onAdd={(new_module) => addModuleCourseClickHandler(new_module)}>
              </AddCourseModal>
            </div>  
            }
            <hr className="module-table-seperate"></hr>
            {editType.type=="videos" && courseContentJson.modules && moduleList && moduleList.map((p, index) => {
              console.log('edit type',editType.type)
              return (
                <div>
                  {courseName.name==p.module && 
                  <div>
                  <div className="module-title" style={{display: "flex", flexDirection: "row"}}>
                    <h3>
                      <MdDoubleArrow style={{color: "#9292d3", marginRight: "0.6rem", fontSize: "2rem", verticalAlign: "bottom"}}/>
                      {p.module}
                    </h3>
                    <button class="add-new-module" onClick = {() => addLectureClickHandler("true", index)} style={{marginLeft: "auto", fontSize: "17px", fontFamily: "sans-serif", marginTop: "0.7rem"}}><GoPlus style={{fontSize: "1.2rem", marginRight: "0.5rem", verticalAlign: "middle"}}></GoPlus>Add New Lecture</button>
                    </div>
                  {isAddActive.isAddActive && <div className="add-new-row" style={{display: "flex", flexDirection: "row", paddingLeft: "1rem", marginTop: "1rem"}}>
                  <input type={"text"} placeholder={"Title"} className="new-row add-title"/>
                    <input type={"text"} placeholder={"Description"} className="new-row add-description"/>
                    <input type={"text"} placeholder={"URL"} className="new-row add-url"/>
                    <select className="new-row add-visibility" style={{height: "2.2rem"}}>
                      <option value="" disabled selected hidden>Is Locked</option>
                      <option value="visible">Visible</option>
                      <option value="locked">Locked</option>
                    </select>
                    <button class="add-new-module add-new-lecture" onClick = {() => addLectureClickHandler("false", index)} style={{width: "10%", fontSize: "15px", fontFamily: "sans-serif", height: "2.1rem", marginTop: "0%"}}><GoPlus style={{fontSize: "0.9rem", marginRight: "0.5rem", verticalAlign: "middle"}}></GoPlus>Add</button>
                  </div> }
                  </div> }
                  {courseName.name==p.module && 
                  <table class="module-table" cellSpacing={"0px"}>
                    <thead>
                      <tr>
                        <th>Title</th>
                        <th>Description</th>
                        <th>URL</th>
                        <th>Is Locked</th>
                        <th>Edit</th>
                        <th>Delete</th>
                      </tr>
                    </thead>
                    {courseName.name==p.module && p.lectures && p.lectures.map((q, idx) => {
                      return (
                        <tr>
                          <td className="table-title data">{q.title}</td>
                          <td className="table-description data">{q.description}</td>
                          <td className="table-url data" style={{overflow: "hidden"}}>{q.url}</td>
                          <td className="table-lock data">{q.lock ? "Locked" : "Visible"}</td>
                          <td>
                          <button className="save-icon" onClick={() => saveLectureClickHandler(index,idx)}>Save</button>
                          <button className="edit-icon" onClick={() => setIsLectureSaveOpen(true)}><FaPencilAlt/></button>
                          </td>
                          <td><button className="delete-icon" onClick={() => deleteLectureClickHandler(index,idx)}><FaTrashAlt/></button></td>
                        </tr>
                      );
                    })}
                  </table>}
                </div>              
              );
            })}
            {editType.type=="slides" && courseContentJson.modules && courseContentJson.modules.map((p, index) => {
              console.log('edit type',editType.type)
              return(
                <div>
                  <h3> slides edit here</h3>
                </div>
              )
            })}
            {editType.type=="assignments" && courseContentJson.modules && courseContentJson.modules.map((p, index) => {
              console.log('edit type',editType.type)
              return(
                <div>
                  <h3> assignments edit here</h3>
                </div>
              )
            })}
            {/* {editType.type=="MSEelement" && batchTitleJson && Object.entries(batchTitleJson).map(([key, value]) => {
              console.log('edit type',editType.type, key, value)
              return(
                <div style={{display: "flex", flexDirection: "row"}}>
                  <div class="dropdown dropdown-modules">
                    <button class="dropbtn dropbtn-modules">Batches<MdExpandMore style={{fontSize: "1.5rem", float: "right"}}></MdExpandMore></button>
                    <div class="dropdown-content">
                      <a href="#" onClick = {() => editBatchClickHandler(key)}>{key}</a>
                      {courseContentJson.modules.map((p, index) => {
                        return(
                          <a href="#" onClick = {() => editModuleClickHandler(p.module)}>{p.module}</a>
                        )
                      })} 
                    </div>
                  </div>
                  <button class="add-new-module" onClick={() => setShow(true)}><GoPlus style={{fontSize: "1.2rem", marginRight: "0.5rem", verticalAlign: "middle"}}></GoPlus>Add New Batch</button>
                  <AddModuleModal onClose={() => setShow(false)} show={show}>
                  </AddModuleModal>
                )})} */}
              {editType.type=="MSEelement" && batchTitleJson &&
                <div style={{display: "flex", flexDirection: "row"}}>
                  <div class="dropdown dropdown-modules">
                    <button class="dropbtn dropbtn-modules">Batches<MdExpandMore style={{fontSize: "1.5rem", float: "right"}}></MdExpandMore></button>
                    <div class="dropdown-content">
                      {Object.entries(batchTitleJson).map(([key, value]) => {
                        return(
                          <a onClick = {() => editBatchClickHandler(key)}>{key}</a>
                          )
                        })} 
                    </div>
                  </div>
                  {/* <button class="add-new-module" onClick={() => setShow(true)}><GoPlus style={{fontSize: "1.2rem", marginRight: "0.5rem", verticalAlign: "middle"}}></GoPlus>Add New Batch</button> */}
                <AddModuleModal onClose={() => setShow(false)} show={show}>
                </AddModuleModal>
                </div>
              }
              <hr className="module-table-seperate"></hr>
              {editType.type=="MSEelement" && batchTitleJson && Object.entries(batchTitleJson).map(([key, value]) => {
                return(
                  <div>
                    {batch.name ==key &&
                      <div className="module-title" style={{display: "flex", flexDirection: "row"}}>
                      <h3>
                        <MdDoubleArrow style={{color: "#9292d3", marginRight: "0.6rem", fontSize: "2rem", verticalAlign: "bottom"}}/>
                        {key}
                      </h3>
                      <button class="add-new-module" onClick = {() => addNewMSEAddHandler("true")} style={{marginLeft: "auto", fontSize: "17px", fontFamily: "sans-serif", marginTop: "0.7rem"}}><GoPlus style={{fontSize: "1.2rem", marginRight: "0.5rem", verticalAlign: "middle"}}></GoPlus>Add New MSE Element</button>
                      </div>
                    }
                    {isSMEActive.isAddActive && <div className="add-new-row" style={{display: "flex", flexDirection: "row", paddingLeft: "1rem", marginTop: "1rem"}}>
                    <input type={"text"} placeholder={"Title"} name={"new_title"} className="new-row add-title"/>
                    <input type={"text"} placeholder={"Question"} name={"new_question"} className="new-row add-description"/>
                    <input type={"text"} placeholder={"Parts Details"} name={"parts_detaild"} className="new-row add-url"/>
                    <button class="add-new-module add-new-lecture" onClick = {() => addNewMSEAddHandler("false")} style={{width: "10%", fontSize: "15px", fontFamily: "sans-serif", height: "2.1rem", marginTop: "0%"}}><GoPlus style={{fontSize: "0.9rem", marginRight: "0.5rem", verticalAlign: "middle"}}></GoPlus>Add</button>
                    </div> }
                    <div>
                      {batch.name==key && 
                      <table class="module-table" cellSpacing={"0px"}>
                        <thead>
                          <tr>
                            <th>Title</th>
                            {/* <th>Question</th>
                            <th>Parts Details</th> */}
                            <th>View</th>
                            <th>Edit</th>
                            <th>Delete</th>
                          </tr>
                        </thead>
                        {batch.name==key && value && Object.entries(value).map((q, index) => {
                          console.log(value, q)
                          return (
                            <tr>
                              <td className="table-title data">{q[1]}</td>
                              {/* <td className="table-description data">{}</td>
                              <td className="table-url data" style={{overflow: "hidden"}}>{}</td> */}
                              <td><button onClick={()=>viewMSESubmission(q[1])} className="view-icon"><AiFillEye/></button></td>
                              <td>
                                <button className="save-icon">Save</button>
                                <button className="edit-icon"><FaPencilAlt/></button>
                              </td>
                              <td><button className="delete-icon"><FaTrashAlt/></button></td>
                            </tr>
                          );
                        })}
                      </table>}
                    </div>
                  </div>
  
                )

              })}

          </Scrollbars>
        </div>        
    </div>
  );
};

export default AdminCampusProgrammeEdit;