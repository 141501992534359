import React from "react";
import "../Common.css";
import CustomPopup from "../../../../../../../../../../../../Admin/SkillboxAdmin/Components/Popup/CustomPopup";
import FileUpload from "../../Components/FileUpload";

const Upload = ({
    show,
    setShow,
    assignmentData,
    setReload,
}) => {
    const handleClose = () => {
        setShow(false);
    };

    return (
        <CustomPopup
            title="Upload Submission"
            show={show}
            onClose={handleClose}
        >
            <div className="sk-st-asgnmt_popup-content">
               <FileUpload asgnId={assignmentData.assignment_id} onClose={handleClose} setReload={setReload} />
            </div>
        </CustomPopup>
    );
}

export default Upload;