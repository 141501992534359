import React from "react";

const ExpandSvg = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3 3H9V5H6.462L11.305 9.843L9.89 11.257L5 6.367V9H3V3ZM3 21H9V19H6.376L11.305 14.072L9.89 12.658L5 17.548V15H3V21ZM15 21H21V15H19V17.524L14.133 12.658L12.719 14.072L17.647 19H15V21ZM21 3H15V5H17.562L12.719 9.843L14.133 11.257L19 6.39V9H21V3Z" fill="#444444"/>
        </svg>
    );
}

export default ExpandSvg;