export const KeywordsData = [
  {
    id: 1,
    name: "Data Scientist",
    words: [
      "Data Science",
      "Machine Learning",
      "Analytics",
      "Python",
      "Programming",
      "Algorithms",
      "Data Visualization",
      "Statistics",
      "Statistical Tools",
      "Statistical Framework",
    ],
  },
  {
    id: 2,
    name: "Data Analyst",
    words: [
      "Data Analysis",
      "SQL",
      "Excel",
      "Statistics",
      "Reporting",
      "Data Cleaning",
      "Visualization",
      "Data Mining",
      "Data Interpretation",
      "Tableau/PowerBI",
    ],
  },
  {
    id: 3,
    name: "Product Manager",
    words: [
      "Product Management",
      "Roadmap",
      "Strategy",
      "User Experience (UX)",
      "User Research",
      "Market Analysis",
      "Feature Prioritization",
      "Agile Development",
      "Stakeholder Communication",
      "Scrum Tools (e.g., Jira, Trello)",
    ],
  },
  {
    id: 4,
    name: "Data Engineer",
    words: [
      "ETL (Extract, Transform, Load)",
      "Database Design",
      "Data Warehousing",
      "Data Integration",
      "SQL",
      "Hadoop/Spark",
      "Data Modeling",
      "Cloud Platforms (e.g., AWS, Azure, GCP)",
      "Data Pipelines",
      "Data Mining",
    ],
  },
  {
    id: 5,
    name: "Software Developer",
    words: [
      "Programming",
      "Algorithms",
      "Software Development",
      "Coding",
      "Debugging",
      "Version Control (e.g., Git)",
      "Testing",
      "Problem Solving",
      "Software Architecture",
      "Object-Oriented Programming (OOP)",
    ],
  },
  {
    id: 6,
    name: "Backend Developer",
    words: [
      "Server-Side Programming",
      "API Development",
      "Database Management",
      "Server Optimization",
      "Backend Frameworks (e.g., Django, Flask, Express)",
      "RESTful APIs",
      "Security Best Practices",
      "Cloud Services (e.g., AWS, Azure)",
      "Scalability",
      "Deployment",
    ],
  },
  {
    id: 7,
    name: "Business Analyst",
    words: [
      "Business Analysis",
      "Requirements Gathering",
      "Data Analysis",
      "Process Improvement",
      "Stakeholder Management",
      "Communication Skills",
      "Documentation",
      "Problem Solving",
      "Business Intelligence",
      "Business Modeling",
    ],
  },
  {
    id: 8,
    name: "Fullstack Developer",
    words: [
      "Frontend Development",
      "Backend Development",
      "Database Management",
      "Web Development",
      "JavaScript",
      "HTML/CSS",
      "React/Angular/Vue",
      "API Integration",
      "Version Control (e.g., Git)",
      "Full Stack Architecture",
    ],
  },
  {
    id: 9,
    name: "Frontend Developer",
    words: [
      "Frontend Development",
      "User Interface (UI) Design",
      "Responsive Design",
      "HTML/CSS",
      "JavaScript",
      "React/Angular/Vue",
      "Web Accessibility",
      "Cross-Browser Compatibility",
      "UX/UI Best Practices",
      "Version Control (e.g., Git)",
    ],
  },
];
