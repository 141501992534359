import { event } from 'jquery';
import React, {useEffect, useState } from 'react';
import {useNavigate } from "react-router-dom";

import './AdminCampusProgramme.css';
import { useHttpClient } from '../../Components/Hooks/http-hook';
import { type } from '@testing-library/user-event/dist/type';

const AdminCampusProgramme = () => {

    const history = useNavigate();

    const [isCourseList, setIsCourseList] = useState([]);

    const { isLoading, error, sendRequest, clearError } = useHttpClient();

    useEffect(() => {
        const getCampusProgrammeTitles = async() => {
            try {
                const responseData = await sendRequest(
                  `${process.env.REACT_APP_BACKEND_URL}/db/prog-titles`,
                  "get",              
                );
                if (responseData.ok) {
                    setIsCourseList(responseData.data)
                }
              } catch (err) {
                console.log("got error while getting programmes")
              }
            };
        getCampusProgrammeTitles();    
    }, []);
    
    const editClickHandler = (index) => {
        console.log('click done', isCourseList[index]);
        history(`/admin/home/${isCourseList[index]['title']}/${isCourseList[index]['program_id']}`);
    };

    return (
        <div className="campus-programme-admin">
            <h2 className="campus-programme-admin-title">List of Campus Programmes</h2>
            <table className="campus-programme-admin-listing-table">
                {isCourseList && isCourseList.map((p, index) => {
                    console.log(p, isCourseList)
                    return (                        
                        <tr className="campus-programme-admin-list">
                            <td className="campus-programme-admin-list-title">
                                {p.title}
                            </td>
                            <td className="campus-programme-admin-list-edit">
                                <button className="edit-button"
                                    style={{
                                        alignItems: "flex-end"
                                    }}
                                    onClick= {() => editClickHandler(index)}                                >
                                    edit
                                </button>
                            </td>
                        </tr>                             
                    )
                })}
            </table>
        </div>
  )
}

export default AdminCampusProgramme;