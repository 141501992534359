import React, { useState } from 'react';
import Info from '../../Components/Info';
import Profile from './pages/Profile';

const ProfileBasedInterview = ({ 
  isLoading, 
  error, 
  startInterviewHandler, 
  selectedInterviewType, 
  handleGoToHome, 
  handleGoToAllInterview 
}) => {
  const [showProfile, setShowProfile] = useState(false);
  const [selectedResume, setSelectedResume] = useState(null);

  const handleShowProfile = () => {
    setShowProfile(true);
  };

  return (
    <>
      {!showProfile ? (
        <Info
          selectedInterviewType={selectedInterviewType}
          handleGoToHome={handleGoToHome}
          handleGoToAllInterview={handleGoToAllInterview}
          onNext={handleShowProfile}
        />
      ) : (
        <Profile
          selectedInterviewType={selectedInterviewType}
          startInterviewHandler={startInterviewHandler}
          isLoading={isLoading}
          error={error}
          selectedResume={selectedResume}
          setSelectedResume={setSelectedResume}
          handleGoToHome={handleGoToHome}
          handleGoToAllInterview={handleGoToAllInterview}
          handleGoToProfileBased={setShowProfile}
        />
      )}
    </>
  );
};

export default ProfileBasedInterview;

