import React, { useState, useEffect, useContext } from "react";
import { useHttpClient } from "../../Components/Hooks/http-hook";
import { AuthContext } from "../../context/auth-context";
import { AdminContext } from "../../context/admin-context";
import MessagePopup from "./../../Components/MessagePopup/MessagePopup";
import "./AdminUserQueries.css";

const Queries = () => {
  const [queries, setQueries] = useState([]);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [showMessagePopup, setShowMessagePopup] = useState(false);
  const [selectedQueryId, setSelectedQueryId] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [filter, setFilter] = useState("all");

  const auth = useContext(AdminContext);
  useEffect(() => {
    const fetchQueries = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/auth/admin/queries${
            filter!="all" ? "?status=" + filter : ""
          }`,
          "GET",
          null,
          {
            "Content-Type": "application/json",
            "Refresh-Token": auth.refresh_token,
            Authorization: auth.token
          }
        );
        setQueries(responseData.queries.queries);
      } catch (err) {}
    };
    fetchQueries();
  }, [filter]);

  return (
    <div>
      <div className="queries-page">
        <MessagePopup
          showMessagePopup={showMessagePopup}
          setShowMessagePopup={setShowMessagePopup}
          queryId={selectedQueryId}
        />
        <div className="queries-components">
          <h1 className="main-heading">
            User Queries{" "}
            <span className="queries-count">({queries.length})</span>
          </h1>
          <div className="filter">
            <select
              name="status"
              id="status"
              onChange={(e) => setFilter(e.target.value)}
            >
              <option value="all">All</option>
              <option value="submitted">Submitted</option>
              <option value="inprogress">In Progress</option>
              <option value="resolved">Resolved</option>
            </select>
          </div>
          <table className="queries-table">
            <thead>
              <tr>
                <th>Title</th>
                <th>Surge ID</th>
                <th>Status</th>
                <th>Level</th>
                <th>Last Updated</th>
              </tr>
            </thead>
            <tbody>
              {isLoading && <p>Loading...</p>}
              {!isLoading && queries.length !== 0 &&
                queries.map((query) => (
                  <tr
                    key={query.id}
                    onClick={() => {
                      setSelectedQueryId(query.id);
                      setShowMessagePopup(true);
                    }}
                  >
                    <td>{query.title}</td>
                    <td>{query.surge_id}</td>
                    <td>
                      {query.status === "submitted" && (
                        <span className="status-yellow">Submitted</span>
                      )}
                      {query.status === "inprogress" && (
                        <span className="status-green">In Progress</span>
                      )}
                      {query.status === "resolved" && (
                        <span className="status-blue">Resolved</span>
                      )}
                    </td>
                    <td>
                      {query.level == "0" && (
                        <span className="level-yellow">Non-Urgent</span>
                      )}
                      {query.level == "1" && (
                        <span className="level-red">Urgent</span>
                      )}
                    </td>
                    <td>{query.lastupdated}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
export default Queries;
