import React from 'react';
import './StatusTag.css';

const status = {
    NEW: {
        label: 'New Submissions',
        labelCss: { background: '#00AD500D', color: '#11AF22' },
        countCss: { background: '#11AF22', color: '#FFFFFF' }
    },
    LATE: {
        label: 'Late Submissions',
        labelCss: { background: '#FA1B000D', color: '#FA1B00' },
        countCss: { background: '#FA1B00', color: '#FFFFFF' }
    },
    LTG: {
        label: 'Left to Grade',
        labelCss: { background: '#FFFAEB', color: '#FF8C04' },
        countCss: { background: '#FF8C04', color: '#FFFFFF' }
    },
    PEND: {
        label: 'Pending Submissions',
        labelCss: { background: '#FA1B000D', color: '#FA1B00' },
        countCss: { background: '#FA1B00', color: '#FFFFFF' }
    },
    TOT: {
        label: 'Total Submissions',
        labelCss: { background: '#00AD500D', color: '#11AF22' },
        countCss: { background: '#11AF22', color: '#FFFFFF' }
    },
};

const StatusTag = ({ count, NEW, LTG, LATE , PEND, TOT }) => {
    const type = NEW ? 'NEW' : LATE ? 'LATE' : LTG ? 'LTG' : PEND ? 'PEND' : TOT ? 'TOT' : 'NEW';

    const getCss = (type, key) => status[type][key];

    return (
        <div className="assignment-status-tag" style={getCss(type, 'labelCss')}>
            <div className="assignment-status-tag-value" style={getCss(type, 'countCss')}>
                {count}
            </div>
            {status[type].label}
        </div>
    );
};

export default StatusTag;
