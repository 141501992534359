import React, { useState, useContext, useEffect } from "react";
import "./PreviousDoubts.css";
import { AuthContext } from "../../../../../../../context/auth-context";
import { useHttpClient } from "../../../../../../../Components/Hooks/http-hook";
import Loading from "../../../../../../../Components/Loading";
import Button from "../../../../../../../Components/Profile/Button/Button";
import Popup from "../../../../../../../Components/Profile/Popup/PopUp";
import RaiseDoubt from "../../../../assets/svgs/RaiseDoubt";
import moment from "moment";
import swal from "sweetalert2";
import DataTable from "react-data-table-component";
import AddDoubt from '../Components/AddDoubt';
import ShowMessage from '../Components/ShowMessage';

const PreviousDoubts = () => {
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [queries, setQueries] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [showMessagePopup, setShowMessagePopup] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(9);
  const [selectedQueryId, setSelectedQueryId] = useState(null);

  const fetchQueries = async () => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/auth/user/queries`,
        "GET",
        null,
        {
          "Content-Type": "application/json",
          "Refresh-Token": auth.refresh_token,
          Authorization: auth.token,
        }
      );
      if (responseData.queries) {
        const formattedQueries = responseData.queries.map((query) => ({
          ...query,
          lastupdated: new Date(query.lastupdated).toLocaleString()
        }));
        setQueries(formattedQueries);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchQueries();
  }, [auth, sendRequest]);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleAddNewDoubt = () => {
    setShowPopup(true);
  };

  const handleCloseMessagePopup = () => {
    setShowMessagePopup(false);
  };

  const handleDateFormatter = (date) => {
    return moment(date).format("YYYY-MM-DD HH:mm:ss");
  };

  const handleOpenChat = (queryId) => {
    setSelectedQueryId(queryId);
    setShowMessagePopup(true);
  };

  const handleResolveQuery = (queryId) => {
    setQueries(prev => prev.map(query => 
        query._id === queryId 
            ? { ...query, status: 'resolved' }
            : query
    ));
  };

  const columns = [
    {
      name: "S.No.",
      selector: (_, index) => (currentPage - 1) * rowsPerPage + index + 1,
      width: "90px",
      center: true,
    },
    {
      name: "Title",
      selector: (row) => row.title,
      width: "300px",
    },
    {
      name: "Status",
      selector: (row) => row.status,
      cell: (row) => (
        <>
          {row.status === "submitted" && (
            <span className="sk-doubt-status-yellow">Pending</span>
          )}
          {row.status === "resolved" && (
            <span className="sk-doubt-status-green">Resolved</span>
          )}
          {row.status === "inprogress" && (
              <span className="sk-doubt-status-blue">In Progress</span>
          )}
        </>
      ),
    },
    {
      name: "Last Updated",
      selector: (row) => row.lastupdated,
    },
    {
      name: "Chat",
      center: true,
      selector: (row) => (
        <div className="sk-doubt-action-container">
          {(!row.isNew && row.status === "inprogress") ? <span className="sk-doubt-new-dot"></span>: 
          <span className="sk-doubt-new-dot-red-empty"></span>}
          <div 
            className="sk-doubt-open-chat-button"
            onClick={() => handleOpenChat(row.id)}
            style={{ cursor: 'pointer' }}
          >
            Open Chat
          </div>
        </div>
      ),
      width: "200px",
    }
  ];

  return (
    <div>
      <Popup title="Raise Doubt" onClose={handleClosePopup} show={showPopup}>
        <AddDoubt 
          onClose={handleClosePopup} 
          onDoubtAdded={() => {
            fetchQueries();
          }} 
        />
      </Popup>
      <div className="sk-student-doubts-header">
        <div className="sk-student-doubts-header-title">
          Previous Doubts
        </div>
        <Button text={<><RaiseDoubt /> Raise a New Doubt</>} width="auto" onClick={handleAddNewDoubt} />
      </div>
      {(isLoading || error) && (
        <div className="sk-student-doubts-body">
          <div className="sk-student-doubts-section">
            {isLoading && (
              <Loading
                color="#004c73"
                customClass="sk-student-doubts-interview-loading"
              />
            )}
            {!isLoading && error && (
              <div className="sk-student-doubts-not-found-section">
                Something went wrong, Please try again later
              </div>
            )}
          </div>
        </div>
      )}
      {!isLoading && queries && queries.length === 0 && (
        <div className="sk-student-doubts-body">
          <div className="sk-student-doubts-section">
            <div className="sk-student-doubts-not-found-section">
              No Raised Doubts
            </div>
          </div>
        </div>
      )}
      {!isLoading && queries && queries.length > 0 && (
        <div className="sk-student-doubts-body">
          <div className="sk-student-doubts-section">
            <DataTable
              columns={columns}
              data={queries}
              // onRowClicked={(row) => handleShowScoreDashboard(row.exam_id)}
              customStyles={{
                table: {
                  style: {
                    maxHeight: "600px", 
                    overflowY: "auto",  
                  },
                },        
                rows: {
                  style: {
                    minHeight: "48px",
                  },
                },
                headCells: {
                  style: {
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: "#004c73",
                  },
                },
                cells: {
                  style: {
                    fontSize: "14px",
                  },
                },
                pagination: {
                  style: {
                      marginTop: '20px',
                      justifyContent: 'flex-start',
                      border: 'none',
                      backgroundColor: 'none',
                  },
                  pageButtonsStyle: {
                    backgroundColor: '#fff',
                    borderRadius: '5px',
                    margin: '0px',
                    marginRight: '5px',
                    border: '1px solid #004C73',
                    cursor: 'pointer',
                    svg: {
                        fill: '#004C73',
                    },
                    '&:hover': {
                        backgroundColor: '#D2F0FF',
                        svg: {
                            fill: '#004C73',
                        },
                    },
                    '&:disabled': {
                        backgroundColor: '#fff',
                        border: '1px solid #ccc',
                        svg: {
                            fill: '#ccc',
                        },
                        '&:hover': {
                            backgroundColor: '#fff',
                        }
                    },
                    '&:active': {
                        backgroundColor: '#D2F0FF',
                        svg: {
                            fill: '#fff',
                        },
                    },
                  },
                }
              }}
              highlightOnHover
              pointerOnHover
              pagination
              paginationPerPage={rowsPerPage}
              onChangePage={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      )}
      <Popup title={`Ticket #${selectedQueryId}`} onClose={handleCloseMessagePopup} show={showMessagePopup}>
        <ShowMessage
          showMessagePopup={showMessagePopup}
          queryId={selectedQueryId}
          setShowMessagePopup={setShowMessagePopup}
          resolveQuery={handleResolveQuery}
        />
      </Popup>
    </div>
  );
};

export default PreviousDoubts;
