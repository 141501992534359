// import React, { useState } from "react";
import CustomSelect from "../components/CustomSelect";
import BulletPointsSelect from "../components/BulletPointsSelect";

import React, { useState, useRef } from "react";
import JoditEditor from "jodit-react";

const BulletPointsSection = () => {
  const jobTitles = [
    { id: "dataAnalyst", name: "Data Analyst" },
    { id: "softwareEngineer", name: "Software Engineer" },
    { id: "dataEngineer", name: "Data Engineer" },
    { id: "dataScientist", name: "Data Scientist" },
    { id: "leadershipSkills", name: "Functional Skills - Leadership" },
    { id: "communicationSkills", name: "Functional Skills - Communication" },
    { id: "problemSolvingSkills", name: "Functional Skills - Problem Solving" },
    { id: "productManager", name: "Product Manager" },
    { id: "salesforceDeveloper", name: "Salesforce Developer" },
  ];

  const actionVerbs = [
    { id: "dataAnalysis", name: "Data Analysis" },
    { id: "dataLake", name: "Data Lake" },
    { id: "dataManagement", name: "Data Management" },
    { id: "dataModeling", name: "Data Modeling" },
    { id: "dataProcessing", name: "Data Processing" },
    { id: "dataPipeline", name: "Data Pipeline" },
    { id: "dataQuality", name: "Data Quality" },
    { id: "dataScience", name: "Data Science" },
    { id: "dataVisualization", name: "Data Visualization" },
  ];

  const [selectedJob, setSelectedJob] = useState("hi");
  const [selectedActionVerb, setSelectedActionVerb] = useState("hi");
  const [isSearchClicked, setIsSearchClicked] = useState(false);

  const editor = useRef(null);
  const [content, setContent] = useState("");

  return (
    <div>
      <div>Home</div>
      <div className="section-title">Bullet Points Assistance</div>
      <div className="section-content">
        <div className="content-title">Select job title or enter a skill</div>
        <div className="content-title-desc">
          Use the pre-written samples below to get assistance creating your
          bullet points.
        </div>

        {/* Dropdown section container */}
        {/* <div className="dropdown-sections-container">
          <div className="select-dropdown">
            <div className="content-title">Select Category</div>
            <div className="content-title-desc">
              
              <CustomSelect options={options} onSelect={(option) => console.log(option)} />
            </div>
          </div>
          <div className="action-verbs">
            <div className="content-title">Action Verbs</div>
            <div className="content-title-desc">
              
              <CustomSelect options={options} onSelect={(option) => console.log(option)} />
            </div>
          </div>
        </div> */}

        {/* New Dropdown section container */}
        <div className="dropdown-sections-container-bullet">
          {/* Select Job Title */}
          <div className="select-category">
            <div className="content-title">Select Job Title</div>
            <div className="content-title-desc">
              {/* <select>
                <option value="1">Select category from dropdown</option>
                <option value="2">Category 2</option>
                <option value="3">Category 3</option>
                <option value="4">Category 4</option>
              </select> */}
              <BulletPointsSelect
                options={jobTitles}
                onSelect={(option) => setSelectedJob(option["id"])}
              />
            </div>
          </div>

          {/* Select Action Verb */}
          <div className="action-verbs-data">
            <div className="content-title">Action Verbs</div>
            <div className="content-title-desc">
              {/* <select>
                <option value="1">Action Verbs</option>
                <option value="2">Category 2</option>
                <option value="3">Category 3</option>
                <option value="4">Category 4</option>
              </select> */}
              <BulletPointsSelect
                options={actionVerbs}
                onSelect={(option) => setSelectedActionVerb(option["id"])}
              />
            </div>
          </div>

          {/* Search Btn */}
          <div
            className="bullet-search-btn"
            onClick={() => setIsSearchClicked(true)}
          >
            <svg
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.031 14.6168L20.3137 18.8995L18.8995 20.3137L14.6168 16.031C13.0769 17.263 11.124 18 9 18C4.032 18 0 13.968 0 9C0 4.032 4.032 0 9 0C13.968 0 18 4.032 18 9C18 11.124 17.263 13.0769 16.031 14.6168ZM14.0247 13.8748C15.2475 12.6146 16 10.8956 16 9C16 5.1325 12.8675 2 9 2C5.1325 2 2 5.1325 2 9C2 12.8675 5.1325 16 9 16C10.8956 16 12.6146 15.2475 13.8748 14.0247L14.0247 13.8748Z"
                fill="#004C73"
              />
            </svg>
          </div>
        </div>

        {isSearchClicked && selectedJob && selectedActionVerb && (
          // <BulletPointsContent selectedJob={selectedJob} selectedActionVerb={selectedActionVerb} />
          <div>
            <h1>
              You have selected {selectedJob} and {selectedActionVerb}
            </h1>
            <h1>
              You have selected {selectedJob} and {selectedActionVerb}
            </h1>
            <h1>
              You have selected {selectedJob} and {selectedActionVerb}
            </h1>
            <h1>
              You have selected {selectedJob} and {selectedActionVerb}
            </h1>
            <h1>
              You have selected {selectedJob} and {selectedActionVerb}
            </h1>
            <h1>
              You have selected {selectedJob} and {selectedActionVerb}
            </h1>
            <JoditEditor
              ref={editor}
              value={content}
              tabIndex={1} // tabIndex of textarea
              onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
              onChange={(newContent) => {}}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default BulletPointsSection;
