import React, { useState, useRef, useEffect } from "react";
import "./Select.css";

const Select = ({
  width = "200px",
  isDisabled,
  onChange,
  value = null,
  selectData = [],
  selectLabel = false,
  selectLabelText = "Select",
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const findOptionValue = (value) => {
    const data = selectData.find((option) => option.value === value);
    if (data) {
      return data
    }
    else {
      return selectData[0]
    }
  }

  const defaultOption =
  selectLabel && !value
    ? { label: selectLabelText, value: null }
    : (findOptionValue(value))

  const [selectedOption, setSelectedOption] = useState(defaultOption);
  const selectRef = useRef(null);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    if (typeof onChange === "function") {
      onChange(option);
    }
  };

  const handleOutsideClick = (e) => {
    if (selectRef.current && !selectRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  return (
    <div
      className="profile-custom-select"
      ref={selectRef}
      style={{ width: width }}
    >
      <div
        className={`profile-select-header ${isOpen ? "open" : ""} ${isDisabled ? "profile-input-component-container-disabled profile-select-header-disabled" : ""}`}
        onClick={() => !isDisabled && setIsOpen(!isOpen)}
      >
        {selectedOption.label}
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5 7.5L10 12.5L15 7.5"
            stroke="#777777"
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
      {isOpen && (
        <div className="profile-options-container">
          {selectData.map((option, i) => (
            <div
              key={i}
              className={`profile-option ${
                selectedOption === option ? "selected" : ""
              }`}
              onClick={() => handleOptionClick(option)}
            >
              {option.label}
              {selectedOption === option && (
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.6663 5L7.49967 14.1667L3.33301 10"
                    stroke="#004C73"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Select;
