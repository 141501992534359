import React, { useState, useEffect, useContext } from "react";
import DataTable from "react-data-table-component";
import ViewSvg from "../../../../Components/Svgs/ViewSvg";
import CalendarSvg from "../../../../Components/Svgs/CalendarSvg";
import BackArrowSvg from "../../../../Components/Svgs/BackArrowSvg";
import ExpandableSvg from "../../../../Components/Svgs/ExpandableSvg";
import NewCandidatesSvg from "../../../../Components/Svgs/NewCandidatesSvg";
import InfoBox from "../../../../Components/InfoBox/InfoBox";
import "./RolesHistory.css";
import { AuthContext } from "../../../../../../../context/auth-context";
import { useHttpClient } from "../../../../../../../Components/Hooks/http-hook";

const customStyles = {
  headCells: {
    style: {
      paddingLeft: "8px",
      paddingRight: "8px",
      fontSize: "16px",
      fontWeight: "600",
      color: "#004C73",
    },
  },
  rows: {
    style: {
      borderBottom: "none",
      position: 'relative',
    },
    highlightOnHoverStyle: {
      backgroundColor: "#e0e0e0",
    },
  },
  cells: {
    style: {
      borderBottom: "none",
    },
  },
  table: {
    style: {
      borderRadius: "20px",
      overflow: "scroll",
      padding: "0px 20px",
      height: "50vh",
      boxShadow: "0 0 10px rgba(0, 0, 0, 0.05)",
      scrollbarWidth: "none",
    },
  },
  pagination: {
    style: {
      marginTop: "20px",
      border: "none",
      backgroundColor: "none",
    },
    pageButtonsStyle: {
      backgroundColor: "#fff",
      borderRadius: "5px",
      marginRight: "5px",
      border: "1px solid #004C73",
      cursor: "pointer",
      svg: {
        fill: "#004C73",
      },
      "&:hover": {
        backgroundColor: "#D2F0FF",
        svg: {
          fill: "#004C73",
        },
      },
      "&:disabled": {
        backgroundColor: "#fff",
        border: "1px solid #ccc",
        svg: {
          fill: "#ccc",
        },
        "&:hover": {
          backgroundColor: "#fff",
        },
      },
      "&:active": {
        backgroundColor: "#D2F0FF",
        svg: {
          fill: "#fff",
        },
      },
    },
  },
  expanderButton: {
    style: {
      backgroundColor: 'none',
      padding: '0px',
      margin: '0px',
      width: '20px',
      height: '20px',
      border: 'none',
      borderRadius: 'none',
      transition: 'all 0.3s ease',
      boxShadow: 'none',  
      '&:hover': {
        backgroundColor: 'none',
        boxShadow: 'none',
      },
      '&:active': {
        backgroundColor: 'none',
        boxShadow: 'none',
      },
      '&:focus': {
        backgroundColor: 'none',
        boxShadow: 'none',
      },
    },
  },
};

const RoleBasedOverallIDataSummary = [
  {
    key: "totalQuestions",
    icon: <CalendarSvg />,
    count: 0,
    title: "Total no. of questions",
  },
  {
    key: "totalTopics",
    icon: <CalendarSvg />,
    count: 0,
    title: "Total no. of topics",
  },
  {
    key: "totalSubTopics",
    icon: <NewCandidatesSvg />,
    count: 0,
    title: "Total no. of subtopics",
  },
];

const RolesHistory = ({
  handleTabChange,
  selectedRolesData,
  previousTab
}) => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [rolesData, setRolesData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [infoBoxData, setInfoBoxData] = useState(RoleBasedOverallIDataSummary);
  const auth = useContext(AuthContext);

  useEffect(() => {
    const fetchRolesData = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/api/qgt/roles/${selectedRolesData.id}`,
          "GET",
          null,
          {
            "Content-Type": "application/json",
            Authorization: auth.token,
          }
        );
        if (responseData.ok) {
          setRolesData(responseData.data);
          setTotalRows(responseData.data.length);
          setInfoBoxData(prevData => prevData.map(item => ({
            ...item,
            count: responseData.infoBoxData[item.key]
          })));
        }
      } catch (err) {
        console.log(err);
      }
    };

    if (selectedRolesData) {
      fetchRolesData();
    }
  }, [sendRequest, selectedRolesData]);

  const SerialNumberCell = ({ row, rowIndex }) => {
    const serialNumber = (currentPage - 1) * 10 + rowIndex + 1;
    return <div>{serialNumber}</div>;
  };

  const columns = [
    {
      name: "Sr. No.",
      cell: (row, index) => <SerialNumberCell row={row} rowIndex={index} />,
      width: "100px",
    },
    {
      name: "Topic",
      selector: (row) => row.topic,
    },
    {
      name: "No. of Questions",
      selector: (row) => row.noOfQuestions,
    },
    {
      name: "Easy",
      selector: (row) => row.easy,
    },
    {
      name: "Medium",
      selector: (row) => row.medium,
    },
    {
      name: "Hard",
      selector: (row) => row.hard,
    },
  ];

  const ExpandedComponent = ({ data }) => (
    <div style={{ padding: "10px 20px" }}>
      <strong>Subtopics:</strong> {data.subTopics.join(", ")}
    </div>
  );

  const handlePageChange = page => {
    setCurrentPage(page);
  };

  const downloadCSV = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/qgt/roles/${selectedRolesData.id}/download`,
        {
          method: 'GET',
          headers: {
            Authorization: auth.token,
          },
        }
      );

      if (response.ok) {
        const contentDisposition = response.headers.get('Content-Disposition');
        const filenameMatch = contentDisposition && contentDisposition.match(/filename="?(.+)"?/i);
        const filename = filenameMatch ? filenameMatch[1] : `role_${selectedRolesData.id}_questions.csv`;

        const blob = await response.blob();
        
        const url = window.URL.createObjectURL(blob);
        
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      } else {
        throw new Error('Failed to download CSV');
      }
    } catch (error) {
      console.error('Error downloading CSV:', error);
    }
  };

  return (
    <div className="rolesHistory">
      <div className="rolesHistorySection1">
        <div className="rolesHistoryHeader">
          <div className="rolesHistoryHeaderContainer">
            <div
              className="rolesHistoryHeaderIcon"
              onClick={() => handleTabChange(previousTab)}
            >
              <BackArrowSvg />
            </div>
            {selectedRolesData.title}
          </div>
          <button className="rolesHistoryHeaderButton" onClick={downloadCSV}>Download CSV</button>
        </div>
        <InfoBox InfoData={infoBoxData} />
        <div className="rolesHistoryTable datatable-wrapper">
          <DataTable
            columns={columns}
            data={rolesData}
            pagination
            paginationTotalRows={totalRows}
            onChangePage={handlePageChange}
            loading={isLoading}
            error={error}
            paginationPerPage={10}
            customStyles={customStyles}
            expandableRows
            expandableRowsComponent={ExpandedComponent}
            expandOnRowClicked
            expandableRowDisabled={(row) => row.subTopics.length === 0}
          />
        </div>
      </div>
    </div>
  );
};

export default RolesHistory;
