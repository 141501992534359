import React, { useState, useMemo } from 'react';
import Button from "../../../../../../../../../Components/Profile/Button/Button";
import defaultIcon from '../../../../../../assets/skill_icons/java.png';

const Skills = ({
  selectedInterviewType,
  skills,
  setSelectedSkill,
  selectedSkill,
  handleGoToHome,
  handleGoToAllInterview,
  handleGoToSkillBased,
  onNext
}) => {
  const [searchTerm, setSearchTerm] = useState("");

  const filteredSkills = useMemo(() => {
    return skills.filter(skill =>
      skill.label.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [skills, searchTerm]);

  return (
    <div className="interview-preperation-personal-interview-page">
      <div className="interview-preperation-personal-interview-page-nav-menu">
        <div
          onClick={handleGoToHome}
          className="interview-preperation-personal-interview-page-nav-menu-home"
        >
          Home
        </div>
        &nbsp; &gt; &nbsp;
        <div
          onClick={handleGoToAllInterview}
          className="interview-preperation-personal-interview-page-nav-menu-other"
        >
          All Interview
        </div>
        &nbsp; &gt; &nbsp;
        <div
          onClick={() => handleGoToSkillBased(false)}
          className="interview-preperation-personal-interview-page-nav-menu-other"
        >
          Skill Based
        </div>
      </div>
      <div className="interview-preperation-personal-interview-container">
        <div className="interview-preperation-personal-interview-header">
          <div className="interview-preperation-personal-interview-header-title">
            {selectedInterviewType.title}
          </div>
        </div>
        <div className="interview-preperation-personal-interview-body">
          <div className="interview-preperation-personal-interview-body-4">
            <div className="interview-preperation-personal-interview-body-4-1">
              <div className="interview-preperation-personal-interview-title">
                Select Skill
              </div>
              <div className="interview-preperation-personal-interview-search">
                <div className="interview-preperation-personal-interview-search-wrapper">
                  <input
                    type="text"
                    placeholder="Search skills"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="interview-preperation-personal-interview-search-input"
                  />
                  <svg className="interview-preperation-personal-interview-search-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <circle cx="11" cy="11" r="8"></circle>
                    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                  </svg>
                </div>
              </div>
            </div>
            {filteredSkills.length > 0 && (
              <>
                <div className="interview-preperation-personal-interview-body-4-2">
                  <div className="interview-preperation-personal-interview-skill-container-wrapper">
                    <div className="interview-preperation-personal-interview-skill-container">
                      {filteredSkills.map((skill, index) => (
                        <div
                          key={index}
                          className={`interview-preperation-personal-interview-skill-box ${selectedSkill === skill.value ? 'selected' : ''}`}
                          onClick={() => setSelectedSkill(skill.value)}
                        >
                          <img 
                            src={skill.icon || defaultIcon} 
                            alt={skill.label} 
                            className="interview-preperation-personal-interview-skill-icon"
                          />
                          <hr className="interview-preperation-personal-interview-skill-line" />
                          {skill.label}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="interview-preperation-personal-interview-body-4-3">
                  <Button
                    text="NEXT"
                    width="400px"
                    disabled={selectedSkill === ""}
                    onClick={onNext}
                  />
                </div>
              </>
            )}
            {filteredSkills.length === 0 && (
              <div className="interview-preperation-personal-interview-no-results">
                No skills found matching your search.
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Skills;
