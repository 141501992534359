import React, { useEffect, useState, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "./Module.css";
import Course1 from "../../assets/Continuing-professional-development-1170x630.jpg";
import { useHttpClient } from "../../Components/Hooks/http-hook";
import { AuthContext } from "../../context/auth-context";

const Module = () => {
  const { cid, ct, pid, mid } = useParams();
  const [module, setModule] = useState({});
  const [classes, setClasses] = useState([]);

  const history = useNavigate();

  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const fetchClasses = async () => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/db/classes/${mid}`,
        "GET",
        null,
        {
          "Content-Type": "application/json",
          "Refresh-Token": auth.refresh_token,
          Authorization: auth.token,
        }
      );
      console.log(responseData);
      setClasses(responseData.data);
    }
    catch (err) {
      console.log(err);
    }
  }

  const fetchModule = async () => {
    try{
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/db/module/${mid}`,
        "GET",
        null,
        {
          "Content-Type": "application/json",
          "Refresh-Token": auth.refresh_token,
          Authorization: auth.token,
        }
      );
      setModule(responseData.data);
    }
    catch(err){
      console.log(err)
    }
  }

  useEffect(() => {
    fetchClasses();
    fetchModule();
  }
  , []);

  const goToClass = (class_id) => {
    history(`/skillbox/${cid}/${ct}/modules/${mid}/classes/${class_id}`)
  }

  return (
    <div className="homepage">
      <div className="custom-module-container">
        <div className="custom-module-content">
          <div className="module-info">
            <div className="module-div-left">
              <h1>{module.module_name}</h1>
              <div className="module-description">
                <p>{module.module_description}</p>
              </div>
            </div>
            <div className="module-div-right">
              <div className="module-image">
                <img
                  src={Course1}
                  alt={module.module_name}
                  className="custom-module-image"
                />
              </div>
            </div>
          </div>
          <div className="classes-section">
            <h2>Class List</h2>
            <ul>
              {classes && classes.map((c, index) => (
                  <li key={index} className="class-list">
                    <span className="class-number">{index + 1}. </span>
                    <span className="class-name">{c && c.class_name}</span>
                    <button className="class-button" onClick={()=>goToClass(c.class_id)}>Go to Class</button>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Module;
