import React from "react";
import "./TextColorTag.css";

const TextColorTag = ({ text, color , backgroundColor}) => {
    return (
        <div
            className="Ip_AdminTextColorTag"
            style={{ color: color, backgroundColor: backgroundColor }}
        >
            {text}
        </div>
    );
}

export default TextColorTag;