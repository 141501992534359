import React, { useState, useEffect, useContext } from "react";
import "./SkillboxAdmin.css";
import Navbar from "../../../Components/SkillboxNavbar/Navbar";
import { AdminSideBarData } from "./Data/AdminSideBarData";
import {AdminDropDownValues} from "./Data/AdminDropDownValues";
import Sidebar from "../../../Components/Profile/Sidebar/Sidebar";
import { useNavigate } from "react-router";

const SkillboxAdmin = ({ isNoHeaderFooter, setIsNoHeaderFooter }) => {
  const [SidebarComponent, setSidebarComponent] = useState(
    AdminSideBarData[0].component
  );
  const [selectedSidebarIndex, setSelectedSidebarIndex] = useState(0);

  const history = useNavigate();

  const renderSidebar = (data) => {
    setSidebarComponent(data.component);
  };

  useEffect(() => {
    renderSidebar(AdminSideBarData[0]);
  }, []);

  return (
    <>
      <div className="admin-skillbox-page">
        <Sidebar
          SidebarData={AdminSideBarData}
          renderSidebar={renderSidebar}
          setIsNoHeaderFooter={setIsNoHeaderFooter}
          selectedSidebarIndex={selectedSidebarIndex}
          setSelectedSidebarIndex={setSelectedSidebarIndex}
        />
        <div className="admin-skillbox-page-content">
          <div className="admin-skillbox-page-content-header">
            <Navbar
                setIsNoHeaderFooter={setIsNoHeaderFooter}
                setSelectedSidebarIndex={setSelectedSidebarIndex}
                dropdownValues={AdminDropDownValues}
            />
          </div>
          <div className="admin-skillbox-page-content-body">
            {SidebarComponent}
          </div>
        </div>
      </div>
    </>
  );
};

export default SkillboxAdmin;
