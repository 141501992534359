export const reportData = [
    {
      id: 1,
      name: "Strengths",
      color: "#026F41",
      bgcolor: "#67ec9192",
      value: [
        "You have Strong skills in This concept You have Strong skills in This concept You have Strong skills in This concept",
        "You have Strong skills in This concept",
        "You have Strong skills in This concept",
        "You have Strong skills in This concept",
        "You have Strong skills in This concept",
        "You have Strong skills in This concept",
      ],
    },
    {
      id: 2,
      name: "Weaknesses",
      color: "#B02218",
      bgcolor: "#f498915a",
      value: [
        "You must improve in This concept You must improve in This concept You must improve in This concept",
        "You must improve in This concept",
        "You must improve in This concept",
        "You must improve in This concept",
      ],
    },
    {
      id: 3,
      name: "Suggestions",
      color: "orangered",
      bgcolor: "#fee08476",
      value: [
        "You can learn This concept You can learn This concept You can learn This concept",
        "You can learn This concept",
        "You can learn This concept",
        "You can learn This concept",
        "You can learn This concept",
        "You can learn This concept",
        "You can learn This concept",
        "You can learn This concept",
      ],
    },
  ];