import React from "react";
import PopUp from "../UiComponents/Popup/PopUp";
import "./EditContent.css";
import { useState, useEffect, useContext } from "react";
import { AdminContext } from "../../../../context/admin-context";
import { useHttpClient } from "../../../../Components/Hooks/http-hook";
import fileToBase64 from "../../../../utilities/base64";
import fileToZip from "../../../../utilities/fileToZip";
import LoadingSpinner from "../../../../Components/Input/LoadingSpinner";
const swal = require("sweetalert2");

const EditContent = ({ content, showPopup, setShowPopup, onEdit }) => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [buttonLoading, setButtonLoading] = useState(false);
  const admin = useContext(AdminContext);

  const initialContentState = {
    content_text: content.content_text,
    content_summary: content.content_summary,
    content_lock: content.content_lock,
    content_type: content.content_type,
    content_file: null,
    content_order: content.content_order,
  };

  const [contentData, setContentData] = useState(initialContentState);

  const [validationErrors, setValidationErrors] = useState({
    content_text: false,
    content_summary: false,
    content_type: false,
    content_file: false,
    content_order: false,
  });

  useEffect(() => {
    setContentData(initialContentState);
  }, [content]);

  const convertFileToZip = async (file) => {
    try {
      const extension = file.name.split(".").pop();

      if (!["zip", "pdf", "png", "mp4", "jpeg", "jpg"].includes(extension)) {
        const zipFile = await fileToZip(file);
        const base64 = await fileToBase64(zipFile);
        return base64;
      } else {
        const base64 = await fileToBase64(file);
        return base64;
      }
    } catch (error) {
      swal.fire({
        title: "Error!",
        text: error || "Something went wrong, please try again later.",
        icon: "error",
        confirmButtonText: "Close",
      });
    }
  };

  const handlechange = (e) => {
    const { name, value } = e.target;

    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: false,
    }));

    setContentData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const validateForm = () => {
    const requiredFields = [
      "content_text",
      "content_summary",
      "content_type",
      "content_file",
      "content_order",
    ];
    const newValidationErrors = {};
    for (const field of requiredFields) {
      if (!contentData[field] || contentData[field].trim().length === 0) {
        newValidationErrors[field] = true;
      }
    }
    setValidationErrors((prev) => ({
      ...prev,
      ...newValidationErrors,
    }));
    return Object.keys(newValidationErrors).length === 0;
  };

  const resetForm = () => {
    setContentData(initialContentState);
    setValidationErrors({
      content_text: false,
      content_summary: false,
      content_type: false,
      content_file: false,
      content_order: false,
    });
  };

  const handleFileChange = async (file) => {
    const base64 = await convertFileToZip(file);
    setContentData((prev) => {
      return {
        ...prev,
        content_file: base64,
      };
    });

    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      content_file: false,
    }));
  };

  const editContent = async (e) => {
    e.preventDefault();
    setButtonLoading(true);
    try {
      const formData = new FormData();
      const response = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/db/content/${content.content_id}`,
        "PUT",
        JSON.stringify(contentData),
        {
          "Content-Type": "application/json",
          "Refresh-Token": admin.refresh_token,
          Authorization: admin.token,
        }
      );
      if (response) {
        setButtonLoading(false);
        setShowPopup(false);
        onEdit({
          ...content,
          ...contentData,
        });
        swal.fire({
          title: "Content Edited Successfully",
          icon: "success",
          confirmButtonText: "Okay",
        });
      }
    } catch (err) {
      setButtonLoading(false);
      swal.fire({
        title: "Error Occured",
        icon: err.message || "error",
        confirmButtonText: "Okay",
      });
    }
  };

  useEffect(() => {
    if (error) {
      setButtonLoading(false);
      setShowPopup(false);
      swal.fire({
        title: "Error!",
        text: error || "Something went wrong, please try again later.",
        icon: "error",
        confirmButtonText: "Close",
      });
      clearError();
    }
  }, [error, clearError, setShowPopup]);

  return (
    <PopUp
      title={content.content_type}
      onClose={() => {
        resetForm();
        setShowPopup(false);
      }}
      show={showPopup}
    >
      {buttonLoading ? (
        <div className="edit_content_popup__loading">
          <LoadingSpinner />
        </div>
      ) : (
        <form onSubmit={editContent}>
          <div className="edit_content_popup__content">
            <div className="edit_content_popup__content-row">
              <div className="edit_content_popup__content-row-title">
                <p>Content ID</p>
              </div>
              <div className="edit_content_popup__content-row-value">
                <p>{content.content_id}</p>
              </div>
            </div>
            <div className="edit_content_popup__content-row">
              <div className="edit_content_popup__content-row-title">
                <p>Content Name</p>
              </div>
              <div className="edit_content_popup__content-row-value">
                <input
                  type="text"
                  defaultValue={content.content_text}
                  name="content_text"
                  onChange={handlechange}
                  className={
                    validationErrors.content_text ? "input_val_error" : ""
                  }
                />
                {validationErrors.content_text && (
                  <small className="input_val_error-text">
                    Please enter a content name.
                  </small>
                )}
              </div>
            </div>
            <div className="edit_content_popup__content-row">
              <div className="edit_content_popup__content-row-title">
                <p>Content Summary</p>
              </div>
              <div className="edit_content_popup__content-row-value">
                <input
                  type="text"
                  defaultValue={content.content_summary}
                  name="content_summary"
                  onChange={handlechange}
                  className={
                    validationErrors.content_summary ? "input_val_error" : ""
                  }
                />
                {validationErrors.content_summary && (
                  <small className="input_val_error-text">
                    Please enter a content summary.
                  </small>
                )}
              </div>
            </div>
            <div className="edit_content_popup__content-row">
              <div className="edit_content_popup__content-row-title">
                <p>Is Active</p>
              </div>
              <div className="edit_content_popup__content-row-value">
                <select
                  defaultValue={content.content_lock}
                  onChange={handlechange}
                >
                  <option value="yes">Live</option>
                  <option value="no">Not Live</option>
                </select>
              </div>
            </div>
            <div className="edit_content_popup__content-row">
              <div className="edit_content_popup__content-row-title">
                <p>Content Order</p>
              </div>
              <div className="edit_content_popup__content-row-value">
                <input
                  type="number"
                  defaultValue={content.content_order}
                  onChange={handlechange}
                  name="content_order"
                  className={
                    validationErrors.content_order ? "input_val_error" : ""
                  }
                />
                {validationErrors.content_order && (
                  <small className="input_val_error-text">
                    Please enter a content order.
                  </small>
                )}
              </div>
            </div>
            <div className="edit_content_popup__content-row">
              <div className="edit_content_popup__content-row-title">
                <p>Content Type</p>
              </div>
              <div className="edit_content_popup__content-row-value">
                <select
                  defaultValue={content.content_type}
                  name="content_type"
                  onChange={handlechange}
                  className={
                    validationErrors.content_type ? "input_val_error" : ""
                  }
                >
                  <option value="video">Video</option>
                  <option value="document">Document</option>
                </select>
                {validationErrors.content_type && (
                  <p className="input_val_error-text">
                    Please enter a content type.
                  </p>
                )}
              </div>
            </div>
            <div className="edit_content_popup__content-row">
              <div className="edit_content_popup__content-row-title">
                <p>Content File</p>
              </div>
              <div className="edit_content_popup__content-row-value">
                <input
                  type="file"
                  name="content_file"
                  accept="*"
                  multiple={false}
                  onChange={(e) => handleFileChange(e.target.files[0])}
                />
              </div>
            </div>
          </div>
          <div className="edit_content_popup__buttons">
            <button
              className="edit_content_popup__buttons-cancel"
              type="button"
              onClick={() => {
                resetForm();
                setShowPopup(false);
              }}
            >
              Cancel
            </button>
            <button className="edit_content_popup__buttons-save" type="submit">
              Save
            </button>
          </div>
        </form>
      )}
    </PopUp>
  );
};

export default EditContent;
