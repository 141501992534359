import React from 'react';
import { useState, useEffect, useContext } from "react";
import "./Grade.css";
import Grades from './Data/Grades';
import Common from '../Common/Common';
import Button from '../../../../../../../../../Components/Profile/Button/Button';
import { useHttpClient } from "../../../../../../../../../Components/Hooks/http-hook";
import { AdminContext } from '../../../../../../../../../context/admin-context';
import { toast } from 'react-toastify';


const Grade = ({
    data,
    courseName,
    sectionName,
    moduleName,
    assignmentTitle,
    assignmentId,
    setRefresh,
    onClose
}) => {
    const admin = useContext(AdminContext);
    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const [selectedGrade, setSelectedGrade] = useState(data.grade_obtained || null);

    const gradeSelect = (grade) => {
        if (data.grading_status === "graded") {
            return;
        }
        setSelectedGrade(grade);
    }

    const gradeAssignment = async () => {
        try {
            const responseData = await sendRequest(
                `${process.env.REACT_APP_BACKEND_URL}/assignments/grade/${data.submission_id}`,
                "post",
                JSON.stringify({
                    grade: selectedGrade
                }),
                {
                    "Content-Type": "application/json",
                    "Refresh-Token": admin.refresh_token,
                    Authorization: admin.token,
                }
            );
            if (responseData) {
                toast.success("Assignment Graded Successfully");
                data.grade = selectedGrade;
                data.grading_status = "graded";
                setRefresh(true);
                onClose();
            }
        } catch (err) {
            toast.error("Error Grading Assignment");
            console.log(err);
        }
    }

    return (
        <div className="ad-sk-asgnmt_sbms_usr-dtls-gd-section">
            <Common
                userDetails={data}
                courseName={courseName}
                sectionName={sectionName}
                moduleName={moduleName}
                assignmentTitle={assignmentTitle}
            />
            <div className="ad-sk-asgnmt_sbms_usr-dtls-sub-section-tp">
                <div className="ad-sk-asgnmt_sbms_usr-dtls-sub-section-text">
                    Download Assignment Submission files
                </div>
                {data.submission_file && (
                    <>
                        {data.submission_file.startsWith("http://") ||
                            data.submission_file.startsWith("https://") ? (
                            <a href={data.submission_file} download>
                                <Button
                                    text="Download"
                                    width="auto"
                                />
                            </a>
                        ) : (
                            <a href={`${process.env.REACT_APP_BACKEND_URL
                                }${data.submission_file}`} download>
                                <Button
                                    text="Download"
                                    width="auto"
                                />
                            </a>
                        )}
                    </>
                )}
            </div>
            <div className="ad-sk-asgnmt_sbms_usr-dtls-sub-section-text-ts">
                Grade Assignment
            </div>
            <div className="ad-sk-asgnmt_sbms_usr-dtls-sub-section-ts">
                <div className="ad-sk-asgnmt_sbms_usr-dtls-gd-section-grades">
                    {Grades.map((grade, i) => (
                        <div key={i} className={`ad-sk-asgnmt_sbms_usr-dtls-gd-section-grade-box ${selectedGrade === grade ? "ad-sk-asgnmt_sbms_usr-dtls-gd-section-grade-box-active" : ""}`} onClick={() => gradeSelect(grade)} disabled={data.grading_status === "graded" ? true : false}>
                            {grade}
                        </div>
                    ))}
                </div>
                {isLoading ? (
                    <Button
                        text="Grading..."
                        width="auto"
                        disabled
                    />
                ) : (
                    <Button
                        text={data.grading_status === "graded" ? "Graded" : "Grade"}
                        width="auto"
                        onClick={() => gradeAssignment()}
                        disabled={(data.grading_status === "graded" ? true : false) || !selectedGrade}
                    />
                )}
            </div>
        </div>
    );
};

export default Grade;