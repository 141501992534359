import React, { useState, useRef, useEffect } from "react";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import "./DatePicker.css";
import moment from "moment/moment";

const CustomDatePicker = ({
  width = "100px",
  name = "",
  isDisabled = false,
  label = "",
  inputPlaceholderValue = "",
  defaultValue,
  onChangeFunction = () => {},
  inperror = "",
}) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const datePickerRef = useRef(null);

  const triggerDatePickerOnClick = () => {
    // datePickerRef.current.setFocus(true);
  };

  // const handleDateChange = (e) => {
  //   setSelectedDate(e);
  //   const date = moment(e).format("YYYY-MM-DD");
  //   onChangeFunction(date);
  // };

  const handleDateChange = (e) => {
    const newDate = e.target.value;
    setSelectedDate(newDate);
    const formattedDate = moment(newDate).format("YYYY-MM-DD");
    onChangeFunction(formattedDate);
  };

  useEffect(() => {
    if (datePickerRef.current) {
      // datePickerRef.current.setOpen(false);
    }
  }, []);

  const containerClassName = `profile-input-component-container ${
    isDisabled ? "profile-input-component-container-disabled" : ""
  }`;

  return (
    <div className={containerClassName} style={{ width: width }}>
      {label && <div className="profile-input-field-label">{label}</div>}
      <div
        className={`datepicker-container ${
          isDisabled ? "profile-input-datepicker-disabled" : ""
        }`}
        onClick={triggerDatePickerOnClick}
      >
        {/* <DatePicker
          ref={datePickerRef}
          selected={selectedDate}
          onChange={handleDateChange}
          dateFormat="MMMM d, yyyy"
          placeholderText={inputPlaceholderValue}
          disabled={isDisabled}
          showMonthDropdown
          showYearDropdown
          dropdownMode="scroll"
        /> */}
        {/* input type date */}
        <input
          ref={datePickerRef}
          type="date"
          name={name}
          className="profile-input-field"
          placeholder={inputPlaceholderValue}
          // value={selectedDate}
          onChange={handleDateChange}
          disabled={isDisabled}
          defaultValue={defaultValue && defaultValue.toISOString().substring(0,10)}
        />
        {/* <div
          className={`datepicker-icon ${
            isDisabled ? "profile-input-component-container-disabled" : ""
          }`}
        >
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 1.5V3.5H15V1.5H17V3.5H21C21.5523 3.5 22 3.94772 22 4.5V20.5C22 21.0523 21.5523 21.5 21 21.5H3C2.44772 21.5 2 21.0523 2 20.5V4.5C2 3.94772 2.44772 3.5 3 3.5H7V1.5H9ZM20 11.5H4V19.5H20V11.5ZM7 5.5H4V9.5H20V5.5H17V7.5H15V5.5H9V7.5H7V5.5Z"
              fill="#434343"
            />
          </svg>
        </div> */}
      </div>
    </div>
  );
};

export default CustomDatePicker;
