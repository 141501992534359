import React from "react";
import { useState, useEffect } from "react";
import CustomPopup from "../../../../../../Components/Popup/CustomPopup";
import Input from "../../../../../../../../../Components/Profile/Input/Input";
import Button from "../../../../../../../../../Components/Profile/Button/Button";
import Grade from "../Grade/Grade";
import Notify from "../Notify/Notify";

const View = ({
    show,
    setShow,
    data,
    courseName,
    sectionName,
    moduleName,
    assignmentTitle,
    assignmentId,
    setRefresh
}) => {
    const [submissionData, setSubmissionData] = useState(data);
    const handleClose = () => {
        setShow(false);
    }

    useEffect(() => {
        setSubmissionData(data);
    }
        , [data]);


    return (
        <>
            <CustomPopup
                title={submissionData && ((submissionData.grading_status === "submitted") ? "Grade Submission" : (submissionData.grading_status === "graded") ? "Submission Graded" : (submissionData.grading_status === "due_date_over") ? "Submission Overdue" : (submissionData.grading_status === "pending") ? "Submission Pending" : (submissionData.grading_status === "late_submission") ? "Late Submissions" : "Submission")}
                show={show}
                onClose={handleClose}
            >
                {submissionData && (submissionData.grading_status === "graded" || submissionData.grading_status === "submitted" || submissionData.grading_status === "late_submission") ? (
                    <Grade 
                        data={submissionData} 
                        assignmentId={assignmentId}
                        courseName={courseName}
                        sectionName={sectionName}
                        moduleName={moduleName}
                        assignmentTitle={assignmentTitle}
                        setRefresh={setRefresh}
                        onClose={handleClose}
                    />
                ) : (
                    <Notify 
                        data={submissionData} 
                        courseName={courseName}
                        sectionName={sectionName}
                        moduleName={moduleName}
                        assignmentTitle={assignmentTitle}
                        assignmentId={assignmentId}
                        setRefresh={setRefresh}
                        onClose={handleClose}
                    />
                )}

            </CustomPopup>
        </>
    );
}

export default View;