import React from "react";
import { useState, useEffect } from "react";
import Input from "../../../../Components/Profile/Input/Input";
import TextArea from "../../../../Components/Profile/Input/TextArea";
import Button from "../../../../Components/Profile/Button/Button";
import Checkmark from "../../../../Components/Profile/Checkmark/Checkmark";
import DatePicker from "../../../../Components/Profile/DatePicker/DatePicker";
import { WorkType } from "../Data/SelectData";
import Popup from "../../../../Components/Profile/Popup/PopUp";
import Loading from "../../../../Components/Loading";

const WorkExperienceForm = ({
  title,
  HandleSubmitFunction,
  onClose,
  show,
  isEdit = false,
  editWorkExperienceData,
}) => {
  const [WorkExperienceDetails, setWorkExperienceDetails] = useState({
    job_position: "",
    work_type: "",
    description: "",
    start_date: "",
    end_date: "",
    company_name: "",
    location: "",
  });

  const [errors, setErrors] = useState({
    job_position: false,
    work_type: false,
    description: false,
    start_date: false,
    end_date: false,
    company_name: false,
    location: false,
  });

  const [isFormValid, setIsFormValid] = useState(false);
  const [isFormFieldsEmpty, setIsFormFieldsEmpty] = useState(true);
  const [disableWorkEndDate, setDisableWorkEndDate] = useState(false);
  const [disableWorkLocation, setDisableWorkLocation] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isEdit) {
      setWorkExperienceDetails({ ...editWorkExperienceData });
    }
  }, [isEdit, editWorkExperienceData]);

  const validateInput = (name, value) => {
    switch (name) {
      case "description":
        return value.length <= 500;
      case "company_name":
        return value.length <= 100;
      case "job_position":
        return value.trim() !== "" && value.length <= 100;
      default:
        return true;
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const isValid = validateInput(name, value);
    setWorkExperienceDetails({ ...WorkExperienceDetails, [name]: value });
    setErrors((prevErrors) => ({ ...prevErrors, [name]: !isValid }));
  };

  const handleDateChange = (date, name) => {
    setWorkExperienceDetails({ ...WorkExperienceDetails, [name]: date });
    const isValid = date !== null;
    setErrors((prevErrors) => ({ ...prevErrors, [name]: !isValid }));
  };

  const handleLocationChange = (location, name) => {
    setWorkExperienceDetails({ ...WorkExperienceDetails, [name]: location });
    const isValid = location !== null;
    setErrors((prevErrors) => ({ ...prevErrors, [name]: !isValid }));
  };

  const handleSelectChange = (selectedOption) => {
    setWorkExperienceDetails({
      ...WorkExperienceDetails,
      work_type: selectedOption.value,
    });
    const isValid = selectedOption.value !== "";
    setErrors((prevErrors) => ({ ...prevErrors, work_type: !isValid }));
  };

  const validateForm = () => {
    const isFormValid = Object.values(errors).every((x) => x === false);
    setIsFormValid(isFormValid);
  };

  const validateFormField = () => {
    const isFormFieldEmpty = Object.values(WorkExperienceDetails).some(
      (x) => x === ""
    );
    const hasErrors = Object.values(errors).some((x) => x === true);
    setIsFormFieldsEmpty(isFormFieldEmpty || hasErrors);
  };

  useEffect(() => {
    validateFormField();
  }, [WorkExperienceDetails, errors]);

  const submitForm = async (e) => {
    setLoading(true);
    validateForm();
    e.preventDefault();
    try {
      await HandleSubmitFunction(WorkExperienceDetails);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Popup title={title} onClose={onClose} show={show}>
      <div className="profile-exp-form">
        <div className="profile-exp-form-section">
          <Input
            width="90%"
            name="job_position"
            label="Job Position"
            inputPlaceholderValue="Enter Project Title"
            value={
              WorkExperienceDetails.job_position !== ""
                ? WorkExperienceDetails.job_position
                : ""
            }
            onChangeFunction={handleInputChange}
            inperror={errors.job_position}
          />
          <Input
            selectWidth="90%"
            name="work_type"
            label="Work Type"
            isSelect={true}
            selectData={WorkType}
            selectLabel="Enter Work Type"
            onChangeFunction={handleSelectChange}
            isNoInputinSelect={true}
            inperror={errors.work_type}
            selectvalue={
              WorkExperienceDetails.work_type !== ""
                ? WorkExperienceDetails.work_type
                : ""
            }
          />
        </div>
        <TextArea
          width="100%"
          name="description"
          label="Work Description"
          inputPlaceholderValue="Enter Work Description"
          defaultValue={WorkExperienceDetails.description}
          onChangeFunction={handleInputChange}
          inperror={errors.description}
          value={
            WorkExperienceDetails.description !== ""
              ? WorkExperienceDetails.description
              : ""
          }
        />
        <div className="profile-exp-form-section">
          <div className="profile-form-section-date-container">
            <DatePicker
              width="100%"
              name="start_date"
              label="Work Start Date"
              inputPlaceholderValue="Enter Start Date"
              defaultValue={
                WorkExperienceDetails.start_date
                  ? new Date(WorkExperienceDetails.start_date)
                  : ""
              }
              onChangeFunction={(e) => handleDateChange(e, "start_date")}
              inperror={errors.start_date}
            />
          </div>
          <div className="profile-exp-form-section-checkmark">
            <DatePicker
              width="90%"
              name="end_date"
              label="Work End Date"
              isCheckmark={true}
              isDisabled={disableWorkEndDate ? true : false}
              inputPlaceholderValue="Enter End Date"
              defaultValue={
                WorkExperienceDetails.end_date
                  ? WorkExperienceDetails.end_date !== "Present" &&
                    new Date(WorkExperienceDetails.end_date)
                  : ""
              }
              onChangeFunction={(e) => handleDateChange(e, "end_date")}
              inperror={errors.end_date}
            />
            <Checkmark
              label="Currently Working here"
              name="end_date"
              setDisableDiv={setDisableWorkEndDate}
              defaultValue={
                WorkExperienceDetails.end_date === "Present" ? true : false
              }
              changeData="Present"
              handleChangeFunction={(e) => handleDateChange(e, "end_date")}
            />
          </div>
        </div>
        <div className="profile-exp-form-section">
          <Input
            width="93%"
            name="company_name"
            label="Company Name"
            inputPlaceholderValue="Enter Company Name"
            value={
              WorkExperienceDetails.company_name !== ""
                ? WorkExperienceDetails.company_name
                : ""
            }
            onChangeFunction={handleInputChange}
            inperror={errors.company_name}
          />
          <div className="profile-exp-form-section-checkmark">
            <Input
              width="95%"
              name="location"
              label="Company Location"
              inputPlaceholderValue="Enter Company Location"
              value={
                WorkExperienceDetails.location !== ""
                  ? WorkExperienceDetails.location !== "Remote"
                    ? WorkExperienceDetails.location
                    : ""
                  : ""
              }
              onChangeFunction={handleInputChange}
              isDisabled={disableWorkLocation ? true : false}
              inperror={errors.location}
            />
            <Checkmark
              label="Remote"
              name="location"
              changeData="Remote"
              defaultValue={
                WorkExperienceDetails.location === "Remote" ? true : false
              }
              setDisableDiv={setDisableWorkLocation}
              handleChangeFunction={(e) => handleLocationChange(e, "location")}
            />
          </div>
        </div>
        {loading ? (
          <div className="profile-delete-loading-container">
            <Loading color="#14144e" />
          </div>
        ) : (
          <div className="profile-exp-form-section-button">
            <Button
              text="SAVE"
              onClick={isFormFieldsEmpty ? null : submitForm}
              disabled={isFormFieldsEmpty}
            />
          </div>
        )}
      </div>
    </Popup>
  );
};

export default WorkExperienceForm;
