import React from 'react'

import './ScheduleCmr.css'

const ScheduleCmr = () => {
    const ScheduleJson = [
        {
          "Class": "27",
          "Date": "21-03-2023",
          "Day": "Tuesday",
          "Duration (Hrs)": "3",
          "Mode": "Online",
          "Course": "DataSciencePythonPackages Part-2",
          "Time Slot": "9.30 AM- 12.30 PM",
          "Status": ""
        },
        {
          "Class": "28",
          "Date": "21-03-2023",
          "Day": "Tuesday",
          "Duration (Hrs)": "3",
          "Mode": "Online",
          "Course": "DataSciencePythonPackages Part-2",
          "Time Slot": "1.30 PM - 4 PM",
          "Status": ""
        },
        {
          "Class": "29",
          "Date": "24-03-2023",
          "Day": "Friday",
          "Duration (Hrs)": "2",
          "Mode": "Online",
          "Course": "Advanced Python",
          "Time Slot": "Evening Time Slot",
          "Status": "7 PM to 9 PM"
        },
        {
          "Class": "30",
          "Date": "25-03-2023",
          "Day": "Saturday",
          "Duration (Hrs)": "3",
          "Mode": "Online",
          "Course": "Advanced Python",
          "Time Slot": "9.30 AM- 12.30 PM",
          "Status": ""
        },
        {
          "Class": "31",
          "Date": "25-03-2023",
          "Day": "Saturday",
          "Duration (Hrs)": "3",
          "Mode": "Online",
          "Course": "Advanced Python",
          "Time Slot": "1.30 PM - 4 PM",
          "Status": ""
        },
        {
          "Class": "32",
          "Date": "26-03-2023",
          "Day": "Sunday",
          "Duration (Hrs)": "3",
          "Mode": "Online",
          "Course": "Advanced Python",
          "Time Slot": "Instructor Comfort Slot",
          "Status": ""
        },
        {
          "Class": "33",
          "Date": "28-03-2023",
          "Day": "Tuesday",
          "Duration (Hrs)": "3",
          "Mode": "Online",
          "Course": "BuildingFirstMLModel",
          "Time Slot": "9.30 AM- 12.30 PM",
          "Status": ""
        },
        {
          "Class": "34",
          "Date": "28-03-2023",
          "Day": "Tuesday",
          "Duration (Hrs)": "3",
          "Mode": "Online",
          "Course": "BuildingFirstMLModel",
          "Time Slot": "1.30 PM - 4 PM",
          "Status": ""
        },
        {
          "Class": "35",
          "Date": "31-03-2023",
          "Day": "Friday",
          "Duration (Hrs)": "2",
          "Mode": "Online",
          "Course": "SQL",
          "Time Slot": "Evening Time Slot",
          "Status": "7 PM to 9 PM"
        },
        {
            "Class": "36",
            "Date": "01-04-2023",
            "Day": "Saturday",
            "Duration (Hrs)": "3",
            "Mode": "Online",
            "Course": "SQL",
            "Time Slot": "9.30 AM- 12.30 PM",
            "Status": ""
          },
          {
            "Class": "37",
            "Date": "01-04-2023",
            "Day": "Saturday",
            "Duration (Hrs)": "3",
            "Mode": "Online",
            "Course": "ProblemSolving",
            "Time Slot": "1.30 PM - 4 PM",
            "Status": ""
          },
          {
            "Class": "38",
            "Date": "02-04-2023",
            "Day": "Sunday",
            "Duration (Hrs)": "3",
            "Mode": "Online",
            "Course": "SQL",
            "Time Slot": "Instructor Comfort Slot",
            "Status": ""
          },
          {
            "Class": "39",
            "Date": "04-04-2023",
            "Day": "Tuesday",
            "Duration (Hrs)": "3",
            "Mode": "Online",
            "Course": "BuildingFirstMLModel",
            "Time Slot": "9.30 AM- 12.30 PM",
            "Status": ""
          },
          {
            "Class": "40",
            "Date": "04-04-2023",
            "Day": "Tuesday",
            "Duration (Hrs)": "3",
            "Mode": "Online",
            "Course": "BuildingFirstMLModel",
            "Time Slot": "1.30 PM - 4 PM",
            "Status": ""
          },
          {
            "Class": "41",
            "Date": "07-04-2023",
            "Day": "Friday",
            "Duration (Hrs)": "2",
            "Mode": "Online",
            "Course": "SQL",
            "Time Slot": "Evening Time Slot",
            "Status": "7 PM to 9 PM"
          },
          {
            "Class": "42",
            "Date": "08-04-2023",
            "Day": "Saturday",
            "Duration (Hrs)": "3",
            "Mode": "Online",
            "Course": "SQL",
            "Time Slot": "9.30 AM- 12.30 PM",
            "Status": ""
          },
          {
            "Class": "43",
            "Date": "08-04-2023",
            "Day": "Saturday",
            "Duration (Hrs)": "3",
            "Mode": "Online",
            "Course": "BuildingFirstMLModel",
            "Time Slot": "1.30 PM - 4 PM",
            "Status": ""
          },
          {
            "Class": "44",
            "Date": "09-04-2023",
            "Day": "Sunday",
            "Duration (Hrs)": "3",
            "Mode": "Online",
            "Course": "SQL",
            "Time Slot": "Instructor Comfort Slot",
            "Status": ""
          },
          {
            "Class": "45",
            "Date": "11-04-2023",
            "Day": "Tuesday",
            "Duration (Hrs)": "3",
            "Mode": "Online",
            "Course": "Mathematics_Fundamentals",
            "Time Slot": "9.30 AM- 12.30 PM",
            "Status": ""
          },
          {
            "Class": "46",
            "Date": "11-04-2023",
            "Day": "Tuesday",
            "Duration (Hrs)": "3",
            "Mode": "Online",
            "Course": "Mathematics_Fundamentals",
            "Time Slot": "1.30 PM - 4 PM",
            "Status": ""
          },
          {
            "Class": "47",
            "Date": "14-04-2023",
            "Day": "Friday",
            "Duration (Hrs)": "2",
            "Mode": "Online",
            "Course": "SQL",
            "Time Slot": "Evening Time Slot",
            "Status": "7 PM to 9 PM"
          },
          {
            "Class": "48",
            "Date": "15-04-2023",
            "Day": "Saturday",
            "Duration (Hrs)": "3",
            "Mode": "Online",
            "Course": "SQL Activity",
            "Time Slot": "9.30 AM- 12.30 PM",
            "Status": ""
          },
          {
            "Class": "49",
            "Date": "15-04-2023",
            "Day": "Saturday",
            "Duration (Hrs)": "3",
            "Mode": "Online",
            "Course": "Advanced Python Activity",
            "Time Slot": "1.30 PM - 4 PM",
            "Status": ""
          },
          {
            "Class": "50",
            "Date": "16-04-2023",
            "Day": "Sunday",
            "Duration (Hrs)": "3",
            "Mode": "Online",
            "Course": "ML Activity",
            "Time Slot": "Instructor Comfort Slot",
            "Status": ""
          }
    ]
      
  return (
    <div className='schedule-cmr'>
        {ScheduleJson && 
            <table class="module-table" cellSpacing={"0px"}>
                <thead>
                    <tr>
                        <th>Class</th>
                        <th>Date</th>
                        <th>Day</th>
                        <th>Duration</th>
                        <th>Mode</th>
                        <th>Course</th>
                        <th>Time Slot</th>
                        <th>Status</th>
                    </tr>
                </thead>
                {ScheduleJson && ScheduleJson.map((p, i) => {
                    return (
                        <tr>
                            <td>{p.Class}</td>
                            <td>{p.Date}</td>
                            <td>{p.Day}</td>
                            <td>{p["Duration (Hrs)"]}</td>
                            <td>{p.Mode}</td>
                            <td>{p.Course}</td>
                            <td>{p['Time Slot']}</td>
                            <td>{p.Status}</td>
                        </tr>
                    )
                })
                }
            </table>
        }            
    </div>
  )
}

export default ScheduleCmr