import React from "react";
import "./ProgramCard.css";
import Badge from "../../../../Components/UiComponents/Badge/Badge";

const ProgramCard = ({ program, handleClick }) => {
    return (
        <div className="admin-skillbox-assignments-program-card">
        <div className="admin-skillbox-assignments-program-card__title">
            {program.module_name}
        </div>
        <div className="admin-skillbox-assignments-program-card__tag">
            <Badge
                pending
            />
        </div>
        <div className="admin-skillbox-assignments-program-card__description">
            {program.assignment_count} Assignments
        </div>
        <button className="admin-skillbox-assignments-program-card__button" onClick={() => handleClick(program.module_id)}>
            Manage
        </button>
        </div>
    );
    }

export default ProgramCard;