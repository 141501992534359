import React, { useState, useEffect, useContext } from "react";
import "./QSkillbox.css";
import Navbar from "./Components/Navbar/Navbar";
import { AdminSideBarData } from "./Data/AdminSideBarData";
import {AdminDropDownValues} from "./Data/AdminDropDownValues";
import Sidebar from "./Components/Sidebar/Sidebar";
import { useNavigate } from "react-router";

const QSkillbox = ({isNoHeaderFooter, setIsNoHeaderFooter}) => {
  const [SidebarComponent, setSidebarComponent] = useState(
    AdminSideBarData[0]
  );
  const [currentPage, setCurrentPage] = useState("Dashboard");
  const [selectedSidebarIndex, setSelectedSidebarIndex] = useState(0);
  const [goToHome, setGoToHome] = useState(false);

  const history = useNavigate();

  const renderSidebar = (data) => {
    setSidebarComponent(data);
    setCurrentPage(data.title);
  };

  useEffect(() => {
    setIsNoHeaderFooter(true);
  }, []);

  useEffect(() => {
    renderSidebar(AdminSideBarData[0]);
  }, []);

  useEffect(() => {
    if (goToHome) {
      history("/");
    }
  }
  , [goToHome]);

  useEffect(() => {
    if (currentPage === "Dashboard") {
      setSelectedSidebarIndex(0);
      renderSidebar(AdminSideBarData[0]);
    }
    else if (currentPage === "Student List") {
      setSelectedSidebarIndex(1);
      renderSidebar(AdminSideBarData[1]);
    }
  }
  , [currentPage]);

  return (
    <>
      <div className="qcreator-skillbox-page">
        <Sidebar
          SidebarData={AdminSideBarData}
          renderSidebar={renderSidebar}
          selectedSidebarIndex={selectedSidebarIndex}
          setSelectedSidebarIndex={setSelectedSidebarIndex}
        />
        <div className="qcreator-skillbox-page-content">
          <div className="qcreator-skillbox-page-content-header">
            <Navbar
                setSelectedSidebarIndex={setSelectedSidebarIndex}
                dropdownValues={AdminDropDownValues}
                PageTitle={SidebarComponent.title}
            />
          </div>
          <div className="qcreator-skillbox-page-content-body">
            <SidebarComponent.component setGoToHome={setGoToHome} setCurrentPage={setCurrentPage} />
          </div>
        </div>
      </div>
    </>
  );
};

export default QSkillbox;
