import React, { useState } from "react";
import "./BookDemo.css";
import { ImCross } from "react-icons/im";
import Input from "./Input/Input.js";
import Button from "./Input/Button";
import {
  VALIDATOR_REQUIRE,
  VALIDATOR_MINLENGTH,
  VALIDATOR_EMAIL,
} from "./Input/validators";
import useForm from "./Hooks/formhooks";
import { useHttpClient } from "./Hooks/http-hook";

const MessageModel = (props) => {

    const toggleResponseMessage=()=>{
        props.toggleResponseMessage()
    }


  return (
    <div className="modal-primary-c">
      <div className="modal-primary1 search-modal1">
        <button
          className="modal-close-button1"
          aria-label="close button"
          onClick={toggleResponseMessage}
        >
          <ImCross style={{ fontSize: "18px" }} />
        </button>
        <h1>Surge <span style={{ color: "orangered" }}>Classes</span> </h1>
        <p>
          <span style={{fontWeight:"bold"}}>You have successfully register for a live demo.</span><br/> <br/>The exact Timing and Date
          of <span style={{ color: "orangered" }}>Demo Session</span> will be notified within 24 hours.<br/> Thanks for showing
          interest in Surge <span style={{ color: "orangered" }}>Classes</span>
        </p>
      </div>
    </div>
  );
};

export default MessageModel;
