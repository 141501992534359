// Modal.js

import React from "react";

const Modal = ({ isOpen, onClose, children, length }) => {
  if (!isOpen) return null;

  return (
    <div
      // onClick={onClose}
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        background: "rgba(0, 0, 0, 0.6)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        // overflow: length > 4 ? "scroll" : "hidden",
      }}
    >
      <div
        style={{
          background: "white",
          height: length > 4 ? "70vh" : "auto",
          width: "70vw",
          margin: "auto",
          padding: "2%",
          // border: "2px solid #000",
          border: "1px",
          borderRadius: "4px",
          boxShadow: "2px solid black",
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default Modal;
