import React from "react";
import "./LoadingQuestion.css";
import nextquestloadinganimation from "../../../../../../../assets/animations/nextquestloading.mp4";

const LoadingQuestion = () => {
  return (
    <div className="loading-interview-question-container">
      <div className="loading-interview-question-video-container">
        <video
          autoPlay
          loop
          muted
          className="loading-interview-question-video"
        >
          <source src={nextquestloadinganimation} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className="loading-interview-question-subheading">
        Hold on! Drafting the follow up question in context with your reply
      </div>
    </div>
  );
};

export default LoadingQuestion;
