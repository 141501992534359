import React from "react";
import { useContext, useEffect, useState } from "react";
import "./StudyMaterial.css";
import { useNavigate, useParams } from "react-router-dom";
import { useHttpClient } from '../../../../../../../../../Components/Hooks/http-hook';
import { AuthContext } from '../../../../../../../../../context/auth-context';
import Button from '../../../../../../../../../Components/Profile/Button/Button';
import VideoPlayer from '../../../../../../../../Courses/VideoPlayer';
import Loading from "../../../../../../../../../Components/Loading";
import moment from "moment";

const StudyMaterial = ({ handleBackToModules }) => {
    const navigate = useNavigate();
    const { cid, clid, ct, pid } = useParams();
    const [classInfo, setClassInfo] = useState({});
    const [classContent, setClassContent] = useState([]);
    const [showVideo, setShowVideo] = useState(false);
    const [pageLoading, setPageLoading] = useState(true);

    const auth = useContext(AuthContext);
    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const { isLoading: isLoading2, error: error2, sendRequest: sendRequest2, clearError: clearError2 } = useHttpClient();

    const fetchContents = async () => {
        try {
            const responseData = await sendRequest(
                `${process.env.REACT_APP_BACKEND_URL}/db/contents/${clid}`,
                "GET",
                null,
                {
                    "Content-Type": "application/json",
                    "Refresh-Token": auth.refresh_token,
                    Authorization: auth.token,
                }
            );
            setClassContent(responseData.data);
        } catch (err) {
            console.log(err);
        }
    };

    const fetchClass = async () => {
        try {
            const responseData = await sendRequest2(
                `${process.env.REACT_APP_BACKEND_URL}/db/class/${clid}`,
                "GET",
                null,
                {
                    "Content-Type": "application/json",
                    "Refresh-Token": auth.refresh_token,
                    Authorization: auth.token,
                }
            );
            setClassInfo(responseData.data);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        fetchContents();
        fetchClass();
    }, []);

    const formatDate = (date) => {
        return moment(date).format("DD-MM-YYYY") || "";
    };

    const goToHome = () => {
        navigate("/skillbox-v2");
    }

    const goToPathWay = () => {
        navigate(`/skillbox-v2/${cid}/${ct}`);
    }

    const goToModules = () => {
        navigate(`/skillbox-v2/${cid}/${ct}/pathway/${pid}`);
    }

    return (
        <>
            <div className="stu-skillbox-nav-header">
                <span onClick={goToHome}>Home</span>
                <span>
                    {" "}&gt;{" "}
                </span>
                <span onClick={goToPathWay}>Pathway</span>
                <span>
                    {" "}&gt;{" "}
                </span>
                <span onClick={handleBackToModules}>Modules</span>
            </div>
            <div className="stu-ts-skillbox-header">
                <div className="stu-ts-skillbox-header-title">
                Study Material {classInfo && Object.keys(classInfo).length !== 0 && (
                    <> - {classInfo.class_name} </>
                    )}
                </div>
                <div className="st-ts-cls-body-last-updated">
                    Download the study material for the class
                </div>
            </div>

            {(isLoading || isLoading2 || error || error2) && (
                <div className="st-cls-body">
                    <div className="st-cls-lt-section">
                        {pageLoading && (
                            <Loading color="#004c73" customClass="st-cls-interview-loading" />
                        )}
                        {error && <div className="interview-questions-not-found-section">
                            Something went wrong, Please try again later
                        </div>
                        }
                    </div>
                </div>
            )}
            {!isLoading && classContent && (
                <>
                    {(classContent.length === 0 || classContent.filter((item) => item.content_type === "document").length === 0) ? (
                        <div className="st-cls-body">
                            <div className="st-cls-lt-section">
                                <div className="interview-questions-not-found-section">
                                    No Study Material Found
                                </div>
                            </div>
                        </div>
                        ):(
                        <div className="st-cls-body">
                            <div className="st-cls-section">
                                <div className="st-cls-table">
                                    <div className="st-cls-table-header">
                                        <div className="st-cls-lt-table-header-row-1">
                                            Name
                                        </div>
                                        <div className="st-cls-lt-table-header-row-2">
                                            Date Updated
                                        </div>
                                        <div className="st-cls-lt-table-header-row-3">
                                            Type
                                        </div>
                                        <div className="st-cls-lt-table-header-row-4">
                                            Download
                                        </div>
                                    </div>
                                    <div className="st-cls-hr-line"></div>
                                    <div className="st-cls-table-body">
                                        {classContent && classContent.map((data, index) => (
                                            <div key={index}>
                                                {data.content_type !== "video" && (
                                                    <div
                                                        key={data.content_id}
                                                        className="st-cls-table-row"
                                                    >
                                                        <div className="st-cls-lt-table-row-1">
                                                            {data.content_text}
                                                        </div>
                                                        <div className="st-cls-lt-table-row-2">
                                                            {data.content_update_timestamp && formatDate(data.content_update_timestamp)}
                                                        </div>
                                                        <div className="st-cls-lt-table-row-2">
                                                            Document
                                                        </div>
                                                        <div className="st-cls-lt-table-row-4">
                                                            <a
                                                                href={
                                                                (data.content_file_url &&
                                                                    data.content_file_url.startsWith("http://")) ||
                                                                data.content_file_url.startsWith("https://")
                                                                    ? data.content_file_url
                                                                    : `${process.env.REACT_APP_CLOUDFRONT_URL}/${data.content_file_url}`
                                                                }
                                                                rel="noopener noreferrer"
                                                                download
                                                            >
                                                                Download
                                                            </a>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default StudyMaterial;
