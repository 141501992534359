import React, { useState } from "react";
import "./BookDemo.css";
import { ImCross } from "react-icons/im";
import Input from "./Input/Input.js";
import Button from "./Input/Button";
import {
  VALIDATOR_REQUIRE,
  VALIDATOR_MINLENGTH,
  VALIDATOR_EMAIL,
} from "./Input/validators";
import useForm from "./Hooks/formhooks";
import { useHttpClient } from "./Hooks/http-hook";
import BookingSlot from "./BookingSlot"

const BookDemo = (props) => {
  const [liveDemo, setLiveDemo] = useState({
    isView: true,
    isSlotBooking: false,
    name: "",
    email: "",
    mobile: "",
    slotBooking: "",
  });

  const [loadedMessage,setLoadedMessage] = useState()

  const initialState = {
    inputs: {
      name: { value: "", isValid: false },
      email: { value: "", isValid: false },
      mobile: { value: "", isValid: false },
    },
    isValid: false,
  };

  const [formState, inputHandler] = useForm(initialState);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const toggleLiveDemo = () => {
    props.toggleLiveDemo();
  };

  const toggleResponseMessage = () => {
   props.toggleResponseMessage()
  };

  const toggleSlotBooking = () => {
    setLiveDemo({
      ...liveDemo,
      isView: !liveDemo.isView,
    });
  };

  const handleChange=(event)=>{
    setLiveDemo({
      ...liveDemo,
      slotBooking: event.target.value
    })
    console.log(liveDemo)
  }

  const toggleMessage=()=>{
    props.toggleMessage()
  }

  const handleSubmit = async (event) => {
   
    
    event.preventDefault();

    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/user/livedemo/${formState.inputs.email.value}/${formState.inputs.mobile.value}`);

     setLoadedMessage(responseData.message);

     if(responseData.message!=null){
       toggleMessage()
     }

   } catch (err) {
        console.log(err)
    }

    toggleSlotBooking()

    
  };

  const submitHandler =async (event)=>{
    event.preventDefault();
    try {
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/user/livedemo`,
        "POST",
        JSON.stringify({
          name: formState.inputs.name.value,
          email: formState.inputs.email.value,
          mobile: formState.inputs.mobile.value,
          slot: liveDemo.slotBooking
        }),
        {
          "Content-Type": "application/json",
        }
      );
      console.log(liveDemo.slotBooking)
    } catch (err) {
      console.log(err);
    }
  
    await toggleLiveDemo()
    await toggleResponseMessage()
   
    
  }

  return liveDemo.isView ? (
    <div className="modal-primary-c">
      <div className="modal-primary1 search-modal1">
        <button
          className="modal-close-button1"
          aria-label="close button"
          onClick={toggleLiveDemo}
        >
          <ImCross style={{ fontSize: "18px" }} />
        </button>
        <h1>
          Book a <span style={{ color: "orangered" }}>FREE</span> live Demo.
          NOW!
        </h1>
        <form onSubmit={handleSubmit} className="form-live-class">
          <div className="curriculum-form-input-div">
            <Input
              element
              id="name"
              errorText="Please enter a valid Name"
              validators={[VALIDATOR_REQUIRE()]}
              label="Full Name"
              onInput={inputHandler}
            />
          </div>
          <div className="curriculum-form-input-div">
            <Input
              element
              id="email"
              errorText="Please enter a valid email"
              validators={[VALIDATOR_EMAIL()]}
              label="Email"
              onInput={inputHandler}
            />
          </div>
          <div className="curriculum-form-input-div">
            <Input
              element
              id="mobile"
              errorText="Please enter a valid number"
              validators={[VALIDATOR_MINLENGTH(10)]}
              label="Mobile"
              onInput={inputHandler}
            />
          </div>

          <Button disabled={!formState.isValid} type="submit">
            Book a Live Demo
          </Button>
        </form>
      </div>
    </div>
  ) : (
    <div className="modal-primary-c">
      <div className="modal-primary1 search-modal1">
        <button
          className="modal-close-button1"
          aria-label="close button"
          onClick={toggleLiveDemo}
        >
          <ImCross style={{ fontSize: "18px" }} />
        </button>
        <h1>
          Book a  <span style={{ color: "orangered" }}>SLOT </span>
          NOW!
        </h1>
        <form onSubmit={submitHandler} className="form-live-class">
          <div className="curriculum-form-input-div">
            <h3 style={{ color: "orangered" }}>Slots Available</h3>
            <input onChange={handleChange} type="radio" id="saturdayMorning" name="slot" value="Saturday Morning"/>
            <label for="saturdayMorning">Saturday Morning</label><br></br>
            <input onChange={handleChange} type="radio" id="saturdayEvening" name="slot" value="Saturday Evening"/>
            <label for="saturdayEvening">Saturday Evening</label><br></br>
            <input onChange={handleChange} type="radio" id="sundayMorning" name="slot" value="Sunday Morning"/>
            <label for="sundayMorning">Sunday Morning</label><br></br>
            <input onChange={handleChange} type="radio" id="sundayEvening" name="slot" value="Sunday Evening"/>
            <label for="sundayEvening">Sunday Evening</label><br></br>
          </div>
          <br/>
          <Button disabled={!formState.isValid} type="submit">
            Book a Live Demo
          </Button>
        </form>
      </div>
    </div>
  );
};

export default BookDemo;
