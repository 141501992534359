import React from 'react';
import './Badge.css';

const status = {
    GRD: {
        label: 'Graded',
        css: { background: '#ECFDF3', color: '#027A48' },
    },
    CMPT: {
        label: 'Completed',
        css: { background: '#ECFDF3', color: '#027A48' },
    },
    PND: {
        label: 'Pending',
        css: { background: '#FEF3F2', color: '#B42318' },
    },
    DDO: {
        label: 'Due date over',
        css: { background: '#E3F6FF', color: '#004C73' },
    },
    SBMT: {
        label: 'Submitted',
        css: { background: '#FFFAEB', color: '#B54708' },
    },
    LS: {
        label: 'Late Submission',
        css: { background: '#FEF3F2', color: '#B42318' },
    }
};

const Badge = ({ graded, pending, due_date_over, submitted, late_submission, completed }) => {
    const type = graded ? "GRD": pending ? "PND" :due_date_over? "DDO" : submitted ? "SBMT" : late_submission ? "LS": completed ? "CMPT" : "NONE";

    const getCss = (type, key) => status[type] && status[type][key];

    return (
        <div className="sk-as-badge" style={getCss(type, 'css')}>
            {status[type] && status[type].label}
        </div>
    );
};

export default Badge;
