export const matchingData = [
    {
      id: 1,
      name: "Keywords",
      width: "260px",
      value: [
        "Data Science",
        "Machine Learning",
        "Analytics",
        "Python",
        "Programming",
        "Algorithms",
        "Data Visualization",
      ],
    },
    {
      id: 2,
      name: "Matched",
      width: "80px",
      value: ["✅", "✅", "❌", "✅", "❌", "✅", "❌"],
    },
    {
      id: 3,
      name: "Count",
      width: "80px",
      value: [1, 2, 0, 1, 0, 1, 0],
    },
  ];