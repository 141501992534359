import React, { useState, useEffect, useContext } from "react";
import { useHttpClient } from "../../../../../../../Components/Hooks/http-hook";
import Loading from "../../../../../../../Components/Loading";
import { ToastContainer, toast } from "react-toastify";
import Dropdown from "../../../../Components/UiComponents/Dropdown/Dropdown";
import { AdminContext } from "../../../../../../../context/admin-context";
import ProgramCard from "../../components/cards/ProgramCard";

const Assignment = ({
  setSelectedTab,
  setSelectedModuleId,
  setSelectedModuleData,
  setSelectedCourseId,
}) => {
  const admin = useContext(AdminContext);
  const { sendRequest, isLoading, error, clearError } = useHttpClient();

  const [courses, setCourses] = useState([]);
  const [sections, setSections] = useState([]);
  const [modules, setModules] = useState([]);

  const [selectedCourse, setSelectedCourse] = useState(null);
  const [selectedSection, setSelectedSection] = useState(null);

  const [isCourseLoading, setIsCourseLoading] = useState(false);
  const [isSectionLoading, setIsSectionLoading] = useState(false);
  const [isModuleLoading, setIsModuleLoading] = useState(false);

  const getCourses = async () => {
    try {
      setCourses([]);
      setIsSectionLoading(true);
      setIsModuleLoading(true);
      setIsCourseLoading(true);
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/db/courses`,
        "get"
      );
      if (responseData.ok) {
        setCourses(responseData.data);
        setSelectedCourse({
          value: responseData.data[0].course_id,
          label: responseData.data[0].course_name,
        });
        setIsCourseLoading(false);
      }
    } catch (err) {
      setIsCourseLoading(false);
      setSelectedCourse(null);
      console.log("got error while getting programmes");
    }
  };

  const getCourseSections = async () => {
    try {
      setSections([]);
      setIsSectionLoading(true);
      setIsModuleLoading(true);
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/db/sections/${selectedCourse.value}`,
        "GET",
        null,
        {
          "Content-Type": "application/json",
          "Refresh-Token": admin.refresh_token,
          Authorization: admin.token,
        }
      );
      if (responseData.ok) {
        setSections(responseData.data);
        setSelectedSection({
          value: responseData.data[0].section_id,
          label: responseData.data[0].section_name,
        });
        setIsSectionLoading(false);
      }
    } catch (err) {
      setIsSectionLoading(false);
      setSelectedSection(null);
      console.log("got error while getting programmes");
    }
  };

  const getCourseModules = async () => {
    try {
      setModules([]);
      setIsModuleLoading(true);
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/db/modules/${selectedSection.value}`,
        "GET",
        null,
        {
          "Content-Type": "application/json",
          "Refresh-Token": admin.refresh_token,
          Authorization: admin.token,
        }
      );
      if (responseData.ok) {
        setModules(responseData.data);
        setIsModuleLoading(false);
      }
    } catch (err) {
      setIsModuleLoading(false);
      console.log("got error while getting programmes");
    }
  };

  useEffect(() => {
    getCourses();
  }, []);

  useEffect(() => {
    if (selectedCourse) {
      getCourseSections();
    }
  }, [selectedCourse]);

  useEffect(() => {
    if (selectedSection) {
      getCourseModules();
    }
  }, [selectedSection]);

  const viewAssignments = (id) => {
    setSelectedModuleId(id);
    setSelectedCourseId(selectedCourse.value);
    setSelectedModuleData({
      ...modules.find((module) => module.module_id === id),
      course_name: selectedCourse.label,
      section_name: selectedSection.label,
      course_id: selectedCourse.value,
    });
    setSelectedTab("assignments-list");
  }

  return (
    <div>
      <ToastContainer closeButton={false} />
      <div className="admin-skillbox-header">
        <div className="admin-skillbox-header-title">
          Assignment Management
        </div>
      </div>

      <div className="admin-skillbox-details-main-section">
        <div className="admin-skillbox-assignments-details-container-1">
          <div className="admin-skillbox-assignments-dropdown">
            <Dropdown
              title={"Select Course"}
              items={courses.map((course) => {
                return { value: course.course_id, label: course.course_name };
              })}
              onChange={(value) => setSelectedCourse(value)}
              isLoading={isCourseLoading}
              selectedValue={selectedCourse}
            />
          </div>
          <div className="admin-skillbox-assignments-dropdown">
            <Dropdown
              title={"Select Section"}
              items={sections.map((section) => {
                return { value: section.section_id, label: section.section_name };
              })}
              onChange={(value) => setSelectedSection(value)}
              isLoading={isSectionLoading}
              selectedValue={selectedSection}
            />
          </div>
        </div>
        {isModuleLoading && (
          <>
            {(!isSectionLoading && sections.length == 0) || (!isCourseLoading && courses.length == 0) ? (
              <div className="admin-skillbox-assignments-no-data">
                {sections.length == 0 ? "No Sections Found" : "No Courses Found"}
              </div>
            ) : (
              <Loading color="#004c73" customClass="admin-skillbox-assignments-no-data" />
            )}
          </>
        )}
        {!isModuleLoading && modules && (
          <>
            {modules.length === 0 ? (
              <div className="admin-skillbox-assignments-no-data">
                No Modules Found
              </div>
            ) : (
              <div className="admin-skillbox-assignments-details-container-2">
                {modules.map((program, index) => (
                  <ProgramCard key={index} program={program} handleClick={viewAssignments} />
                ))}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Assignment;
