import React from "react";
import { useEffect } from "react";
import "./NavLegends.css";

const NavLegends = ({
  selectedQuestionId,
  setSelectedQuestionId,
  questionLength,
  selectedData,
  setSelectedQuestion,
  setSelectDashboard,
}) => {
  useEffect(() => {
    setSelectedQuestion(
      selectedData &&
        selectedData.questions.find((data) => data.id === selectedQuestionId)
    );
    setSelectDashboard("first");
  }, [selectedData, setSelectedQuestion, setSelectedQuestionId]);

  const handleSelectedQuestionId = (id) => {
    setSelectedQuestionId(id);
    setSelectedQuestion(
      selectedData && selectedData.questions.find((data) => data.id === id)
    );
    setSelectDashboard("first");
  };

  const handleNextQuestion = () => {
    const nextId =
      selectedQuestionId + 1 > questionLength ? 1 : selectedQuestionId + 1;
    setSelectedQuestionId(nextId);
    setSelectedQuestion(
      selectedData && selectedData.questions.find((data) => data.id === nextId)
    );
    setSelectDashboard("first");
  };

  const handlePreviousQuestion = () => {
    const prevId =
      selectedQuestionId - 1 < 1 ? questionLength : selectedQuestionId - 1;
    setSelectedQuestionId(prevId);
    setSelectedQuestion(
      selectedData && selectedData.questions.find((data) => data.id === prevId)
    );
    setSelectDashboard("first");
  };

  const renderSquare = (index) => (
    <div
      key={index}
      className={`interview-feedback-nav-legends-square ${
        selectedQuestionId === index + 1
          ? "interview-feedback-nav-legends-square-active"
          : ""
      }`}
      onClick={() => handleSelectedQuestionId(index + 1)}
    >
      {index + 1}
    </div>
  );

  return (
    <div className="interview-feedback-nav-legends">
      <div className="interview-feedback-nav-legends-section-1">
        <div className="interview-feedback-nav-legends-svgs">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={handlePreviousQuestion}
          >
            <path
              d="M12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12C2 6.48 6.48 2 12 2ZM12 20C16.42 20 20 16.42 20 12C20 7.58 16.42 4 12 4C7.58 4 4 7.58 4 12C4 16.42 7.58 20 12 20ZM12 11H16V13H12V16L8 12L12 8V11Z"
              fill="#898989"
            />
          </svg>
        </div>
        <div className="interview-feedback-nav-legends-squares">
        {Array.from(Array(questionLength).keys()).map(renderSquare)}
        </div>
      </div>
      <div className="interview-feedback-nav-legends-section-2">
        <div className="interview-feedback-nav-legends-svgs">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={handleNextQuestion}
          >
            <path
              d="M12 11V8L16 12L12 16V13H8V11H12ZM12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12C2 6.48 6.48 2 12 2ZM12 20C16.42 20 20 16.42 20 12C20 7.58 16.42 4 12 4C7.58 4 4 7.58 4 12C4 16.42 7.58 20 12 20Z"
              fill="#898989"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default NavLegends;
