import React, { useContext } from 'react';
import { useNavigate } from "react-router-dom";

import "./PasswordReset.css";
import PasswordResetMsg from '../upSkill/PasswordResetMsg';
import { AuthContext } from '../../context/auth-context';
import ErrorModal from "../../Components/Input/ErrorModel";
import LoadingSpinner from '../../Components/Input/LoadingSpinner';

import { useHttpClient } from "../../Components/Hooks/http-hook";


const PasswordReset = () => {
    const auth = useContext(AuthContext);
    const history = useNavigate();
    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const passwordResetHandler = async (event) => {

        event.preventDefault();
        console.log(auth)
        try {
          const responseData = await sendRequest(
            `${process.env.REACT_APP_BACKEND_URL}/auth/reset-password`,
            "POST",
            JSON.stringify({
              email: `${auth.email}`
            }),
            {
              "Content-Type": "application/json",
              "Refresh-Token": auth.refresh_token,
              "Authorization": auth.token,
            }
          );
          console.log(responseData)
          if (responseData.ok) {
            console.log("reset mail sent")
            // auth.logout()
            history("/password-reset-msg");
          } else {
            console.log("error occurred")
            history("/error-404", {msg:responseData.error});
          }
        } catch (err) {
          console.log(err)
        }
      };
    
    return (
        <React.Fragment>
            <ErrorModal error={error} onClear={clearError} />
            {isLoading && <LoadingSpinner asOverlay />}
            {!isLoading && (
            <div className="password-reset-page">
                <h1>Forgot Password?</h1>
                <h3>No worries, we'll send you reset instructions</h3>
                <button onClick={passwordResetHandler} type>Reset Password</button>
            </div>
            )}
        </React.Fragment>
  )
};

export default PasswordReset;