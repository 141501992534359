import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router";
import { useHttpClient } from "../../../../Components/Hooks/http-hook";
import { AuthContext } from "../../../../context/auth-context";
import Button from "../../../../Components/Profile/Button/Button";
import "./AptitudePractice.css";
import Loading from "../../../../Components/Loading";
const swal = require("sweetalert2");

const AptitudePractice = () => {
  const aptitudePracticeLeft = 5;
  const history = useNavigate();

  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [categoriesLoading, setCategoriesLoading] = useState(false);

  const { error, clearError, sendRequest, isLoading } = useHttpClient();
  const { error:error1, clearError:clearError1, sendRequest:sendRequest1, isLoading:isLoading1 } = useHttpClient();
  const auth = useContext(AuthContext);

  const formatCategories = (data) => {
    return data.map((d) => {
      return {
        value: d,
        label: d,
      };
    });
  };

  useEffect(() => {
    const fetchCategories = async () => {
      setCategoriesLoading(true);
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/prep/get-all-categories`,
          "GET",
          null,
          {
            "Content-Type": "application/json",
            "Refresh-Token": auth.refresh_token,
            Authorization: auth.token,
          }
        );
        setCategories(formatCategories(responseData.data.categories));
        setCategoriesLoading(false);
      } catch (err) {
        console.log(err);
        setCategoriesLoading(false);
      }
    };
    fetchCategories();
  }, []);

  const handleCategoryClick = (category) => {
    if (selectedCategories.includes(category)) {
      setSelectedCategories(selectedCategories.filter((c) => c !== category));
    } else {
      setSelectedCategories([...selectedCategories, category]);
    }
  };

  const handleStartAptitudePractice = async () => {
    if (selectedCategories.length !== 0) {
      try {
        setButtonLoading(true);
        const responseData = await sendRequest1(
          `${process.env.REACT_APP_BACKEND_URL}/prep/start-aptitude-practice`,
          "POST",
          JSON.stringify({
            categories: selectedCategories,
          }),
          {
            "Content-Type": "application/json",
            "Refresh-Token": auth.refresh_token,
            Authorization: auth.token,
          }
        );
        if (responseData.data) {
          history(`/aptitude-platform/practice/${responseData.data}`);
          if (document.body.requestFullscreen) {
            document.body.requestFullscreen().catch((e) => {
              console.log("error while full screen ", e);
            });
          }
        } else {
          setButtonLoading(false);
          swal.fire({
            title: "Something went wrong",
            text: "Cannot start the test, please try again later",
            icon: "error",
            confirmButtonText: "Okay",
          });
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    if (error) {
      setCategoriesLoading(false);
      swal.fire({
        title: "Cannot Get Categories",
        text: "Unable to get categories, please try again later!",
        icon: "error",
      });
      clearError();
    }
  }
  , [error, clearError]);

  useEffect(() => {
    if (error1) {
      setButtonLoading(false);
      swal.fire({
        title: "Cannot Start Practice",
        text: "This test cannot be started right now, please try again later!",
        icon: "error",
      });
      clearError1();
    }
  }
  , [error1, clearError1]);

  return (
    <div className="aptitude-practice">
      <div className="aptitude-practice-top">
        <div className="aptitude-practice-test-left">
          <div className="aptitude-practice-title">Aptitude Practice</div>
          <div className="aptitude-practice-subtitle">
            You can pratice your aptitude skills
          </div>
          Total Practice Left: {aptitudePracticeLeft} / 10
        </div>
        <div className="aptitude-practice-test-right">
          <Button
            width="250px"
            onClick={() => history("/aptitude-platform")}
            text="Topic Wise Aptitude Practice"
          />
        </div>
      </div>
      <div className="aptitude-practice-filter-container">
        {categoriesLoading ? (
          <Loading color="blue" customClass="practice-loading-vertical-container" />
        ) : (
          <>
            <div className="aptitude-practice-filter">
              <h2>Select Categories:</h2>
              <ul>
                {categories.map((category) => (
                  <li
                    key={category.value}
                    onClick={() => handleCategoryClick(category.value)}
                  >
                    {category.label}{" "}
                    {selectedCategories.includes(category.value) && (
                      <span>&#10003;</span>
                    )}
                  </li>
                ))}
              </ul>
            </div>
            {buttonLoading ? (
              <Loading color="blue" customClass="practice-loading-container" />
            ) : (
              <Button
                width="300px"
                onClick={handleStartAptitudePractice}
                disabled={!selectedCategories.length}
                text="Start Practice"
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default AptitudePractice;
