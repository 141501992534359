import React, { useState, useEffect } from 'react';
import './Checkmark.css';

const Checkmark = ({ label,
   setDisableDiv = () => {},
   handleChangeFunction ,
   changeData= "",
   defaultValue = false
  }) => {
  const [isChecked, setChecked] = useState(defaultValue);

  useEffect(() => {
    if(defaultValue){
      setDisableDiv(true);
    }
  }
  ,[defaultValue]);

  const handleChange = (event) => {
    setChecked(event.target.checked);
    if (event.target.checked) {
      setDisableDiv(true);
      handleChangeFunction(changeData);
    }
    else{
      setDisableDiv(false);
      handleChangeFunction("");
    }
  };

  return (
    <div className='profile-checkmark-container'>
      <label className='profile-checkmark-label'>
        <input
          type="checkbox"
          className='profile-checkmark-inp'
          checked={isChecked}
          onChange={handleChange}
        />
        {label}
      </label>
    </div>
  );
};

export default Checkmark;
