import React, { useState, useEffect, useContext } from "react";
import RolesHistory from "../QtTool/layouts/RolesHistory/RolesHistory";
import Roles from "./Roles";

const QtToolRole = () => {
  const [selectedTab, setSelectedTab] = useState("roleshome");
  const [selectedRolesData, setSelectedRolesData] = useState({});

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  }

  return (
    <div className="assignments">
      {selectedTab === "roleshome" && <Roles handleTabChange={handleTabChange} setSelectedRolesData={setSelectedRolesData} selectedRolesData={selectedRolesData} />}
      {selectedTab === "roleselected" && <RolesHistory handleTabChange={handleTabChange} setSelectedRolesData={setSelectedRolesData} selectedRolesData={selectedRolesData} previousTab={"roleshome"} />}
    </div>
  );
};

export default QtToolRole;