import React from 'react'

import './AssignmentPop.css'

var html_string_dict = ['', 
    '<div style={{"fontSize":"16px","color":"#212121","fontWeight":"600","marginBottom":"10px"}}><strong><font size=\"+2\">217. Contains Duplicate</font></strong></div> \
    <div><div> \
        <br/> \
        <p>Given an integer array <code>nums</code>, return <code>true</code> if any value appears <strong>at least twice</strong> in the array, and return <code>false</code> if every element is distinct.</p> \
        <p><strong className="example">Example 1:</strong></p> \
        <pre><strong>Input:</strong>nums = [1,2,3,1]<strong>  Output:</strong>true</pre> \
        <p><strong className="example">Example 2:</strong></p> \
        <pre><strong>Input:</strong>nums = [1,2,3,4]<strong>  Output:</strong>false</pre> \
        <p><strong className="example">Example 3:</strong></p> \
        <pre><strong>Input:</strong>nums = [1,1,1,3,3,4,3,2,4,2]<strong>  Output:</strong> true</pre> \
        <p> </p> \
        <p><strong>Constraints:</strong></p> \
        <ul><li><code>1 &lt;= nums.length &lt;= 10<sup>5</sup></code></li><li><code>-10<sup>9</sup> &lt;= nums[i] &lt;= 10<sup>9</sup></code></li></ul> \
    </div></div> \
    <br/> \
    <br/> \
    <br/> \
    <br/> \
    <br/> \
    <div><strong><font size=\"+2\">242. Valid Anagram</font></strong></div> \
    <br/> \
    <div><div><p>Given two strings <code>s</code> and <code>t</code>, return <code>true</code> <em>if</em> <code>t</code> <em>is an anagram of</em> <code>s</code><em>, and</em> <code>false</code> <em>otherwise</em>.</p> \
    <p>An <strong>Anagram</strong> is a word or phrase formed by rearranging the letters of a different word or phrase, typically using all the original letters exactly once.</p> \
    <p><strong>Example 1:</strong></p> \
    <pre><strong>Input:</strong> s = "anagram", t = "nagaram"<strong>  Output:</strong> true</pre> \
    <p><strong>Example 2:</strong></p> \
    <pre><strong>Input:</strong> s = "rat", t = "car" <strong>  Output:</strong> false</pre> \
    <p> </p> \
    <p><strong>Constraints:</strong></p> \
    <ul><li><code>1 &lt;= s.length, t.length &lt;= 5 * 10<sup>4</sup></code></li><li><code>s</code> and <code>t</code> consist of lowercase English letters.</li></ul> \
    <p> </p> \
    <p><strong>Follow up:</strong> What if the inputs contain Unicode characters? How would you adapt your solution to such a case?</p> \
    </div></div> \
    <br/> \
    <br/> \
    <br/> \
    <br/> \
    <br/> \
    <div><strong><font size=\"+2\">347. Top K Frequent Elements</font></strong></div> \
    <br/> \
    <div><div><p>Given an integer array <code>nums</code> and an integer <code>k</code>, return <em>the</em> <code>k</code> <em>most frequent elements</em>. You may return the answer in <strong>any order</strong>.</p> \
    <p><strong>Example 1:</strong></p> \
    <pre><strong>Input:</strong> nums = [1,1,1,2,2,3], k = 2 <strong> Output:</strong> [1,2]</pre> \
    <p><strong >Example 2:</strong></p> \
    <pre><strong>Input:</strong> nums = [1], k = 1<strong>  Output:</strong> [1]</pre> \
    <p> </p> \
    <p><strong>Constraints:</strong></p> \
    <ul><li><code>1 &lt;= nums.length &lt;= 10<sup>5</sup></code></li><li><code>-10<sup>4</sup> &lt;= nums[i] &lt;= 10<sup>4</sup></code></li><li><code>k</code> is in the range <code>[1, the number of unique elements in the array]</code>.</li><li>It is <strong>guaranteed</strong> that the answer is <strong>unique</strong>.</li></ul> \
    <p> </p> \
    <p><strong>Follow up:</strong> Your algorithm\'s time complexity must be better than <code>O(n log n)</code>, where n is the array\'s size.</p> \
    </div></div> \
    <br/> \
    <br/> \
    <br/> \
    <br/> \
    <br/> \
    <div><strong><font size=\"+2\">36. Valid Sudoku</font></strong></div> \
    <br/> \
    <div><div><p>Determine if a <code>9 x 9</code> Sudoku board is valid. Only the filled cells need to be validated <strong>according to the following rules</strong>:</p> \
    <ol><li>Each row must contain the digits <code>1-9</code> without repetition.</li><li>Each column must contain the digits <code>1-9</code> without repetition.</li><li>Each of the nine <code>3 x 3</code> sub-boxes of the grid must contain the digits <code>1-9</code> without repetition.</li></ol> \
    <p><strong>Note:</strong></p> \
    <ul><li>A Sudoku board (partially filled) could be valid but is not necessarily solvable.</li> \
    <li>Only the filled cells need to be validated according to the mentioned rules.</li> \
    </ul> \
    <p> </p> \
    <p><strong >Example 1:</strong></p> \
    <img style={{"height": "250px", "width": "250px"}} src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/ff/Sudoku-by-L2G-20050714.svg/250px-Sudoku-by-L2G-20050714.svg.png"/> \
    <pre><strong>Input:</strong> board = \
    <p>[["5","3",".",".","7",".",".",".","."],</p>\
    <p> ["6",".",".","1","9","5",".",".","."],</p>\
    <p> [".","9","8",".",".",".",".","6","."],</p>\
    <p> ["8",".",".",".","6",".",".",".","3"],</p>\
    <p> ["4",".",".","8",".","3",".",".","1"],</p>\
    <p> ["7",".",".",".","2",".",".",".","6"],</p>\
    <p> [".","6",".",".",".",".","2","8","."],</p>\
    <p> [".",".",".","4","1","9",".",".","5"],</p>\
    <p> [".",".",".",".","8",".",".","7","9"]]\
    <strong>Output:</strong> true \
    </pre> \
    <p><strong >Example 2:</strong></p> \
    <pre><strong>Input:</strong> board = \
    <p>[["8","3",".",".","7",".",".",".","."],</p>\
    <p> ["6",".",".","1","9","5",".",".","."],</p>\
    <p> [".","9","8",".",".",".",".","6","."],</p>\
    <p> ["8",".",".",".","6",".",".",".","3"],</p>\
    <p> ["4",".",".","8",".","3",".",".","1"],</p>\
    <p> ["7",".",".",".","2",".",".",".","6"],</p>\
    <p> [".","6",".",".",".",".","2","8","."],</p>\
    <p> [".",".",".","4","1","9",".",".","5"],</p>\
    <p> [".",".",".",".","8",".",".","7","9"]]</p>\
    <strong>Output:</strong> false \
    <p><strong>Explanation:</strong> Same as Example 1, except with the <strong>5</strong> in the top left corner being modified to <strong>8</strong>.\nSince there are two 8\'s in the top left 3x3 sub-box, it is invalid.</p>\
    </pre> \
    <p> </p> \
    <p><strong>Constraints:</strong></p> \
    <ul> \
    <li><code>board.length == 9</code></li> \
    <li><code>board[i].length == 9</code></li> \
    <li><code>board[i][j]</code> is a digit <code>1-9</code> or <code>\'.\'</code>.</li> \
    </ul> \
    </div></div> \
    <br/> \
    <br/> \
    <br/> \
    <br/> \
    <br/> \
    <div ><strong><font size=\"+2\">48. Rotate Image</font></strong></div> \
    <br/> \
    <div ><div ><p>You are given an <code>n x n</code> 2D <code>matrix</code> representing an image, rotate the image by <strong>90</strong> degrees (clockwise).</p> \
    <p>You have to rotate the image <a href="https://en.wikipedia.org/wiki/In-place_algorithm" target="_blank"><strong>in-place</strong></a>, which means you have to modify the input 2D matrix directly. <strong>DO NOT</strong> allocate another 2D matrix and do the rotation.</p> \
    <p><strong >Example 1:</strong></p> \
    <img alt="" style={{"width": "500px", "height": "188px"}} src="https://assets.leetcode.com/uploads/2020/08/28/mat1.jpg"/> \
    <pre><strong>Input:</strong> matrix = [[1,2,3],[4,5,6],[7,8,9]] \
    <strong>Output:</strong> [[7,4,1],[8,5,2],[9,6,3]] \
    </pre> \
    <p><strong >Example 2:</strong></p> \
    <img alt="" style={{"width": "500px", "height": "201px"}} src="https://assets.leetcode.com/uploads/2020/08/28/mat2.jpg"/> \
    <pre><strong>Input:</strong> matrix = [[5,1,9,11],[2,4,8,10],[13,3,6,7],[15,14,12,16]] \
    <strong>Output:</strong> [[15,13,2,5],[14,3,4,1],[12,6,8,9],[16,7,10,11]] \
    </pre> \
    <p> </p> \
    <p><strong>Constraints:</strong></p> \
    <ul> \
    <li><code>n == matrix.length == matrix[i].length</code></li> \
    <li><code>1 &lt;= n &lt;= 20</code></li> \
    <li><code>-1000 &lt;= matrix[i][j] &lt;= 1000</code></li> \
    </ul> \
    </div></div> \
    <br/> \
    <br/> \
    <br/> \
    <br/> \
    <br/> \
    <div ><strong><font size=\"+2\">42. Trapping Rain Water</font></strong></div> \
    <br/> \
    <div ><div ><p>Given <code>n</code> non-negative integers representing an elevation map where the width of each bar is <code>1</code>, compute how much water it can trap after raining.</p> \
    <p><strong >Example 1:</strong></p> \
    <img style={{"width": "412px", "height": "161px"}} src="https://assets.leetcode.com/uploads/2018/10/22/rainwatertrap.png"/> \
    <pre><strong>Input:</strong> height = [0,1,0,2,1,0,1,3,2,1,2,1] \
    <strong>Output:</strong> 6 \
    <p><strong>Explanation:</strong> The above elevation map (black section) is represented by array [0,1,0,2,1,0,1,3,2,1,2,1].\nIn this case, 6 units of rain water (blue section) are being trapped.</p>\
    </pre> \
    <p><strong >Example 2:</strong></p> \
    <pre><strong>Input:</strong> height = [4,2,0,3,2,5] \
    <strong>Output:</strong> 9 \
    </pre> \
    <p> </p> \
    <p><strong>Constraints:</strong></p> \
    <ul> \
    <li><code>n == height.length</code></li> \
    <li><code>1 &lt;= n &lt;= 2 * 10<sup>4</sup></code></li> \
    <li><code>0 &lt;= height[i] &lt;= 10<sup>5</sup></code></li> \
    </ul> \
    </div></div>'
]


const AssignmentPop = (props) => {
  return (
    <div className='assignment-pop'>
        <div className='box'>
            <span className="close-icon" onClick={props.handleClose}>x</span>
            <div className="module-flex2" dangerouslySetInnerHTML={{__html: html_string_dict[props.number]}}>
                {/* {html_string_dict[props.number]} */}
            </div>
        </div>
    </div>
  )
}

export default AssignmentPop