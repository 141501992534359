import React from "react";

const QCreationSvg = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.7561 2.25C16.167 2.25 16.5 2.58371 16.5 2.99505V15.0049C16.5 15.4164 16.1585 15.75 15.7561 15.75H2.24385C1.83304 15.75 1.5 15.4163 1.5 15.0049V2.99505C1.5 2.58357 1.84148 2.25 2.24385 2.25H15.7561ZM15 3.75H3V14.25H15V3.75ZM13.5 11.25V12.75H4.5V11.25H13.5ZM9 5.25V9.75H4.5V5.25H9ZM13.5 8.25V9.75H10.5V8.25H13.5ZM7.5 6.75H6V8.25H7.5V6.75ZM13.5 5.25V6.75H10.5V5.25H13.5Z"
        fill="white"
      />
    </svg>
  );
};

export default QCreationSvg;
