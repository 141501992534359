import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "./Modules.css";
import Sidebar from "../../../../../../../Components/Profile/Sidebar/Sidebar";
import ProfileDropdown from "../../../../../../../Components/Profile/ProfileDropdown/ProfileDropdown";
import { ProfileDropdownValues } from "../../../../../StudentProfile/Data/ProfileDropdownValues";
import { AuthContext } from "../../../../../../../context/auth-context";
import { useHttpClient } from "../../../../../../../Components/Hooks/http-hook";
import Module from "./Layout/Modules/Module";
import NotFound from "./Layout/NotFound/NotFound";
import swal from "sweetalert2";
import LoadingSpinner from "../../../../../../../Components/Input/LoadingSpinner";

const Modules = ({ isNoHeaderFooter, setIsNoHeaderFooter }) => {
  const auth = useContext(AuthContext);
  const { pid, tabId, cid, ct } = useParams();
  const { setUserProfilePic, setUserName } = useContext(AuthContext);
  const { sendRequest } = useHttpClient();
  const { isLoading, error, sendRequest: sendRequest1, clearError } = useHttpClient();
  const [ isPageLoading, setIsPageLoading ] = useState(false);
  const [ModuleSideBarData, setModuleSideBarData] = useState([{
    title: "Modules",
    component: NotFound,
    props: {
      isLoading: isPageLoading,
      error: error,
    }
  }]);
  const [SidebarComponent, setSidebarComponent] = useState();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState({
    value: "skillbox",
    label: "Skillbox",
  });
  const [selectedSidebarIndex, setSelectedSidebarIndex] = useState(0);
  const history = useNavigate();
  const [lastSelectedModule, setLastSelectedModule] = useState(null);

  const renderSidebar = (data) => {
    setSidebarComponent(data);
  };

  const isValidUrl = (string) => {
    try {
      new URL(string);
    } catch (_) {
      return false;
    }
    return true;
  };    

  const fetchModules = async () => {
    try {
      const responseData = await sendRequest1(
        `${process.env.REACT_APP_BACKEND_URL}/db/modules/${pid}`,
        "GET",
        null,
        {
          "Content-Type": "application/json",
          "Refresh-Token": auth.refresh_token,
          Authorization: auth.token,
        }
      );
      if (responseData.data && responseData.data.length > 0) {
        const newModuleSideBarData = responseData.data.map((module, index) => ({
          title: module.module_name,
          component: Module,
          props: {
            setSelectedOption: setSelectedOption,
            moduleId: module.module_id,
          },
          tabId: `module-${module.module_id}`,
        }));
        setModuleSideBarData(newModuleSideBarData);
      } else {
        setModuleSideBarData([{
          title: "Modules",
          component: NotFound,
          props: {
            isLoading: isPageLoading,
            error: error,
          },
          tabId: "overview",
        }]);
      }
    } catch (err) {
      console.log(err);
      setModuleSideBarData([{
        title: "Modules",
        component: NotFound,
        props: {
          isLoading: isPageLoading,
          error: error,
        },
        tabId: "overview",
      }]);
    }
  };

  useEffect(() => {
    setIsPageLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    fetchModules();
  }
    , []);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/profile/user`,
          "GET",
          null,
          {
            "Content-Type": "application/json",
            "Refresh-Token": auth.refresh_token,
            Authorization: auth.token,
          }
        );
        if (responseData.data.name) {
          setUserName(responseData.data.name);
        }
        if (responseData.data.profile_pic_url) {
          if (isValidUrl(responseData.data.profile_pic_url)) {
            setUserProfilePic(responseData.data.profile_pic_url);
          } else {
            setUserProfilePic(
              `${process.env.REACT_APP_BACKEND_URL}/${responseData.data.profile_pic_url}`
            );
          }
        }
      } catch (err) {
        console.log(err);
      }
    };
    fetchProfile();
  }, []);

  useEffect(() => {
    if (selectedOption.value === "resume") {
      history("/resume");
    } else if (selectedOption.value === "profile") {
      history("/profile");
    } else if (selectedOption.value === "referralCode") {
      history("/referral-code");
    } else if (selectedOption.value === "logout") {
      swal
        .fire({
          title: "Logout",
          text: "Are you sure you want to logout?",
          showCancelButton: true,
          confirmButtonText: "Logout",
          confirmButtonColor: "#ff0000",
          cancelButtonText: "Cancel",
          cancelButtonColor: "#2874f0",
          allowOutsideClick: false,
        })
        .then((result) => {
          if (result.isConfirmed) {
            auth.logout();
            setIsNoHeaderFooter(false);
          } else {
            setSelectedOption({
              value: "skillbox",
              label: "Skillbox",
            });
          }
        });
    } else if (selectedOption.value === "resetPassword") {
      setIsNoHeaderFooter(false);
      history("/password-reset");
    } else {
      if (ModuleSideBarData && ModuleSideBarData.length > 0) {
        renderSidebar(ModuleSideBarData[0]);
      };
    }

    setSelectedSidebarIndex(0);
  }, [selectedOption]);

  useEffect(() => {
    setIsNoHeaderFooter(true);
  }, []);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        isDropdownOpen &&
        !event.target.closest(".profile-content-header-dropdown")
      ) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [isDropdownOpen, setIsDropdownOpen]);

  useEffect(() => {
    if (ModuleSideBarData && ModuleSideBarData.length > 0) {
      const tabIndex = tabId
        ? ModuleSideBarData.findIndex((item) => item.tabId === tabId)
        : 0;
      if (tabIndex !== -1) {
        renderSidebar(ModuleSideBarData[tabIndex]);
        setSelectedSidebarIndex(tabIndex);
      } else {
        renderSidebar(ModuleSideBarData[0]);
        setSelectedSidebarIndex(0);
      }
    }
  }, [ModuleSideBarData, tabId]);

  const handleSidebarItemClick = (index) => {
    setSelectedSidebarIndex(index);
    const selectedTab = ModuleSideBarData[index];
    setLastSelectedModule(selectedTab.tabId);
    history(`/skillbox-v2/${cid}/${ct}/pathway/${pid}/${selectedTab.tabId}`);
    renderSidebar(selectedTab);
  };

  const handleClassClick = (moduleId, classId) => {
    setLastSelectedModule(moduleId);
    history(`/skillbox-v2/${cid}/${ct}/pathway/${pid}/modules/${moduleId}/classes/${classId}`);
  };

  return (
    <div className="profile">
      {ModuleSideBarData && ModuleSideBarData.length > 0 && (
        <>
          <Sidebar
            SidebarData={ModuleSideBarData}
            renderSidebarUsingIndex={handleSidebarItemClick}
            setIsNoHeaderFooter={setIsNoHeaderFooter}
            selectedSidebarIndex={selectedSidebarIndex}
            setSelectedSidebarIndex={setSelectedSidebarIndex}
          />

          <div className="profile-content">
            <div className="profile-content-header">
              <div className="profile-content-header-dropdown">
                <ProfileDropdown
                  ProfileDropdownValues={ProfileDropdownValues}
                  selectedOption={selectedOption}
                  setSelectedOption={setSelectedOption}
                  isOpen={isDropdownOpen}
                  setIsOpen={setIsDropdownOpen}
                />
              </div>
            </div>
            <div className="profile-content-body">
              {SidebarComponent && SidebarComponent.component && (
                <SidebarComponent.component 
                  {...SidebarComponent.props} 
                  onClassClick={handleClassClick}
                  lastSelectedModule={lastSelectedModule}
                />
              )}
            </div>
          </div>
        </>
      )}

    </div>
  );
};

export default Modules;
