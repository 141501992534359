import React from "react";
import ProgressImage from "../../assets/under-progress.png";
import "./UnderProgress.css";

const UnderProgress = () => {

  return (
    <div className="under-progress">
      <h3>This page is currently under Progress...</h3>
      <img src={ProgressImage} alt="Surge Logo"></img>
    </div>
  );
};

export default UnderProgress;