import React from "react";
import { useState, useEffect } from "react";
import Input from "../../../../../Components/Profile/Input/Input";
import TextArea from "../../../../../Components/Profile/Input/TextArea";
import Button from "../../../../../Components/Profile/Button/Button";
import DatePicker from "../../../../../Components/Profile/DatePicker/DatePicker";
import Popup from "../../../../../Components/Profile/Popup/PopUp";
import Loading from "../../../../../Components/Loading";

const Workshop = ({
  type,
  title,
  HandleSubmitFunction,
  onClose,
  show,
  isEdit = false,
  editAchievementData,
}) => {
  const [WorkshopDetails, setWorkshopDetails] = useState({
    title: "",
    description: "",
    date: "",
    location: "",
    skills: "",
  });

  const [errors, setErrors] = useState({
    title: false,
    description: false,
    date: false,
    location: false,
    skills: false,
  });

  const [isFormValid, setIsFormValid] = useState(false);
  const [isFormFieldsEmpty, setIsFormFieldsEmpty] = useState(true);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (isEdit) {
      setWorkshopDetails({ ...editAchievementData });
    }
  }, [isEdit, editAchievementData]);

  const validateInput = (name, value) => {
    switch (name) {
      case "description":
        return value.length <= 500;
      case "title":
        return value.length <= 100;
      case "skills":
        return value.length <= 100;
      default:
        return true;
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const isValid = validateInput(name, value);
    setWorkshopDetails({ ...WorkshopDetails, [name]: value });
    setErrors((prevErrors) => ({ ...prevErrors, [name]: !isValid }));
  };

  const handleDateChange = (date, name) => {
    setWorkshopDetails({ ...WorkshopDetails, [name]: date });
    const isValid = date !== null;
    setErrors((prevErrors) => ({ ...prevErrors, [name]: !isValid }));
  };

  const validateForm = () => {
    const isFormValid = Object.values(errors).every((x) => x === false);
    setIsFormValid(isFormValid);
  };

  const validateFormField = () => {
    const isFormFieldEmpty = Object.values(WorkshopDetails).some(
      (x) => x === ""
    );
    const hasErrors = Object.values(errors).some((x) => x === true);
    setIsFormFieldsEmpty(isFormFieldEmpty || hasErrors);
  };

  useEffect(() => {
    validateFormField();
  }, [WorkshopDetails, errors]);

  const submitForm = async (e) => {
    setLoading(true);
    validateForm();
    e.preventDefault();
    try {
      WorkshopDetails.type = type;
      await HandleSubmitFunction(WorkshopDetails);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Popup title={title} onClose={onClose} show={show}>
      <div className="profile-exp-form">
        <div className="profile-exp-form-section">
          <Input
            width="100%"
            name="title"
            label="Title"
            inputPlaceholderValue="Enter Workshop Title"
            value={WorkshopDetails.title !== "" ? WorkshopDetails.title : ""}
            onChangeFunction={handleInputChange}
            inperror={errors.title}
          />
        </div>
        <TextArea
          width="100%"
          name="description"
          label="Description"
          inputPlaceholderValue="About Workshop"
          value={
            WorkshopDetails.description !== ""
              ? WorkshopDetails.description
              : ""
          }
          onChangeFunction={handleInputChange}
          inperror={errors.description}
        />
        <div className="profile-exp-form-section">
          <div className="profile-form-section-date-container">
            <DatePicker
              width="100%"
              name="date"
              label="Date"
              inputPlaceholderValue="Enter Date"
              defaultValue={
                WorkshopDetails.date !== ""
                  ? new Date(WorkshopDetails.date)
                  : ""
              }
              onChangeFunction={(e) => handleDateChange(e, "date")}
              inperror={errors.date}
            />
          </div>
          <Input
            width="90%"
            name="location"
            label="Loacation"
            inputPlaceholderValue="Enter Location"
            value={
              WorkshopDetails.location !== "" ? WorkshopDetails.location : ""
            }
            onChangeFunction={handleInputChange}
            inperror={errors.location}
          />
        </div>
        <div className="profile-exp-form-section">
          <Input
            width="100%"
            name="skills"
            label="Learnings"
            inputPlaceholderValue="Enter skills"
            value={WorkshopDetails.skills !== "" ? WorkshopDetails.skills : ""}
            onChangeFunction={handleInputChange}
            inperror={errors.skills}
          />
        </div>
        <div className="profile-exp-form-section-button">
          {loading ? (
            <div className="profile-delete-loading-container">
              <Loading color="#14144e" />
            </div>
          ) : (
            <Button
              text="SAVE"
              onClick={isFormFieldsEmpty ? null : submitForm}
              disabled={isFormFieldsEmpty}
            />
          )}
        </div>
      </div>
    </Popup>
  );
};

export default Workshop;
