import React from "react";
import {useNavigate } from "react-router-dom";
import "./MyCoursePage.css";
import Course1 from "../../assets/Continuing-professional-development-1170x630.jpg";
import Course2 from "../../assets/react-js-projects.jpg"
import ProgressBar from "@ramonak/react-progress-bar";

const MyCoursePage = () => {
  const history = useNavigate();

  const courseHandler = (item) => {
    history(`/mycourses/${item}`);
  };

  const courses_full = [{
    title: "Data Science Campus Course",
    image: Course1,
    specialization: "(Artificial Intelligence Specialization)",
    course_id: "01",
  },
  {
    title: "React Crash Course",
    image: Course2,
    specialization: "(Web Development Specialization)",
    course_id: "02",
  }
  ]

  const DUMMY_COURSES = [
    {
      title: "Data Science Campus Course",
      image: Course1,
      specialization: "(Artificial Intelligence Specialization)",
      isStarted: true,
      percentageComplete: 40,
    },
    {
      title: "React Crash Course",
      image: Course2,
      specialization: "(Web Development Specialization)",
      isStarted: false,
      percentageComplete: 0,
    },
    // {
    //   title: "MERN Full Stack Development",
    //   image: Course1,
    //   specialization: "(Web Development Specialization)",
    //   isStarted: false,
    //   percentageComplete: 0,
    // },
  ];

  return (
    <div className="mycoursepage">
      <h2>My Courses</h2>
      <div className="courses-grid">
        {DUMMY_COURSES &&
          DUMMY_COURSES.map((item) => {
            return (
              <div className="grid-div" onClick={()=>courseHandler(item.title)}>
                <div className="grid-card-img">
                  <img src={item.image}></img>
                </div>
                <h3>{item.title}</h3>
                <p>{item.specialization}</p>
                <div className="progress-bar">
                  {item.isStarted ? (
                    <React.Fragment>
                    <ProgressBar
                      completed={item.percentageComplete}
                      height={7}
                      bgColor="#1d3557"
                      isLabelVisible={false}
                    />
                    <span style={{color:"# rgb(31, 31, 71)", marginTop:"0.2rem"}}> {item.percentageComplete}% Complete</span>
                    </React.Fragment>
    
                  ) : (
                    <span>START COURSE</span>
                  )}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default MyCoursePage;
