import React from 'react';
import styled from 'styled-components';
import BoldSvg from "../assets/svgs/BoldSvg";
import ItalicSvg from "../assets/svgs/ItalicSvg";
import UnderlineSvg from "../assets/svgs/UnderlineSvg";
import CopySvg from "../assets/svgs/CopySvg";
import CutSvg from "../assets/svgs/CutSvg";
import ExpandSvg from "../assets/svgs/ExpandSvg";
import FlagSvg from "../assets/svgs/FlagSvg";
import RedoSvg from "../assets/svgs/RedoSvg";
import UndoSvg from "../assets/svgs/UndoSvg";
import SpellCheckSvg from "../assets/svgs/SpellCheckSvg";
import LinkUrlSvg from "../assets/svgs/LinkUrlSvg";
import LinkBreakSvg from "../assets/svgs/LinkBreakSvg";
import ImageAttachSvg from "../assets/svgs/ImageAttachSvg";
import TableSvg from "../assets/svgs/TableSvg";
import TableHorizontalLineSvg from "../assets/svgs/TableHorizontalLineSvg";
import SpecialCharacterSvg from "../assets/svgs/SpecialCharacterSvg";
import RemoveFormattingSvg from "../assets/svgs/RemoveFormattingSvg";
import StrikeThroughSvg from "../assets/svgs/StrikeThroughSvg";
import OrderedListSvg from "../assets/svgs/OrderedListSvg";
import UnorderedListSvg from "../assets/svgs/UnorderedListSvg";
import LeftAlignSvg from "../assets/svgs/LeftAlignSvg";
import CenterAlignSvg from "../assets/svgs/CenterAlignSvg";
import RightAlignSvg from "../assets/svgs/RightAlignSvg";
import BlockQuoteSvg from "../assets/svgs/BlockQuoteSvg";
import FontColorSvg from "../assets/svgs/FontColorSvg";
import FontSizeSvg from "../assets/svgs/FontSizeSvg";
import DropdownButton from "../sections/GenerateResumeSection/DropdownButton";

const ToolbarWrapper = styled.div`
  margin-top: 10px;
`;

const ColorPicker = ({ onSelectColor }) => {
  const handleColorChange = (event) => {
    onSelectColor(event.target.value);
    document.execCommand("foreColor", false, event.target.value);
  };

  return (
    <input
      type="color"
      className="ToolbarItemColorPicker"
      onChange={handleColorChange}
    />
  );
};

const HorizontalLine = styled.hr`
  border: none;
  height: 1px;
  background-color: #ccc;
  margin: 4px 0;
`;


const handleInsertHorizontalLine = () => {
  const hr = document.createElement('hr');
  const selection = window.getSelection();
  if (selection.rangeCount > 0) {
    const range = selection.getRangeAt(0);
    range.deleteContents();
    range.insertNode(hr);
  }
};

const handleInsertImage = () => {
    const url = window.prompt("Enter the URL of the image:");
    if (url) {
      const img = document.createElement('img');
      img.src = url;
      const selection = window.getSelection();
      if (selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        range.deleteContents();
        range.insertNode(img);
      }
    }
  };

  const handleInsertTable = () => {
    const rows = prompt("Enter number of rows:");
    const cols = prompt("Enter number of columns:");
    if (rows && cols) {
      const table = document.createElement('table');
      for (let i = 0; i < rows; i++) {
        const tr = document.createElement('tr');
        for (let j = 0; j < cols; j++) {
          const td = document.createElement('td');
          td.textContent = `Row ${i + 1}, Col ${j + 1}`;
          tr.appendChild(td);
        }
        table.appendChild(tr);
      }
      const selection = window.getSelection();
      if (selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        range.deleteContents();
        range.insertNode(table);
      }
    }
  };

  const handleInsertSpecialCharacter = () => {
    const char = prompt("Enter the special character:");
    if (char) {
      const span = document.createElement('span');
      span.textContent = char;
      const selection = window.getSelection();
      if (selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        range.deleteContents(); 
        range.insertNode(span);
      }
    }
  };

  const spellCheck = () => {
    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        const text = range.toString();
        const url = `https://api.textgears.com/check.php?text=${text}&key=1L2J2J2J2J2J2J2J`;
        fetch(url)
            .then((response) => response.json())
            .then((data) => {
                if (data.errors) {
                    alert(`Spelling errors: ${data.errors.map((error) => error.bad).join(", ")}`);
                } else {
                    alert("No spelling errors found!");
                }
            });
    }
};


const Toolbar = ({ handleCommand, handleCreateLink, handleColorChange, handleDownloadAsPdf, generateDOCX }) => {
  return (
    <ToolbarWrapper className="CustomToolbar">
      <div className="section-1">
        <div className="ToolbarItem" onClick={() => handleCommand("cut")}>
          <CutSvg />
        </div>
        <div className="ToolbarItem" onClick={() => handleCommand("copy")}>
          <CopySvg />
        </div>
        <HorizontalLine />
        <div className="ToolbarItem" onClick={() => handleCommand("undo")}>
          <UndoSvg />
        </div>
        <div className="ToolbarItem" onClick={() => handleCommand("redo")}>
          <RedoSvg />
        </div>
        <HorizontalLine />
        <div className="ToolbarItem" onClick={spellCheck}>
          <SpellCheckSvg />
        </div>
        <HorizontalLine />
        <div className="ToolbarItem" onClick={handleCreateLink}>
          <LinkUrlSvg />
        </div>
        <div className="ToolbarItem" onClick={() => handleCommand("unlink")}>
          <LinkBreakSvg />
        </div>
        <HorizontalLine />
        <div className="ToolbarItem" onClick={handleInsertImage}>
          <ImageAttachSvg />
        </div>
        <div className="ToolbarItem" onClick={handleInsertTable}>
          <TableSvg />
        </div>
        <HorizontalLine />
        <div className="ToolbarItem" onClick={() => handleCommand("insertHorizontalRule")}>
          <TableHorizontalLineSvg />
        </div>
        <div className="ToolbarItem" onClick={handleInsertSpecialCharacter}>
          <SpecialCharacterSvg />
        </div>
        <div className="ToolbarItem" onClick={() => handleCommand("selectAll")}>
          <ExpandSvg />
        </div>
        <div className="ToolbarItem" onClick={() => handleCommand("bold")}>
          <BoldSvg />
        </div>
        <div className="ToolbarItem" onClick={() => handleCommand("italic")}>
          <ItalicSvg />
        </div>
        <div className="ToolbarItem" onClick={() => handleCommand("underline")}>
          <UnderlineSvg />
        </div>
        <div className="ToolbarItem" onClick={() => handleCommand("strikethrough")}>
          <StrikeThroughSvg />
        </div>
        <HorizontalLine />
        <div className="ToolbarItem" onClick={() => handleCommand("removeFormat")}>
          <RemoveFormattingSvg />
        </div>
        <HorizontalLine />
        <div className="ToolbarItem" onClick={() => handleCommand("insertUnorderedList")}>
          <UnorderedListSvg />
        </div>
        <div className="ToolbarItem" onClick={() => handleCommand("insertOrderedList")}>
          <OrderedListSvg />
        </div>
        <HorizontalLine />
        <div className="ToolbarItem" onClick={() => handleCommand("justifyLeft")}>
          <LeftAlignSvg />
        </div>
        <div className="ToolbarItem" onClick={() => handleCommand("justifyCenter")}>
          <CenterAlignSvg />
        </div>
        <div className="ToolbarItem" onClick={() => handleCommand("justifyRight")}>
          <RightAlignSvg />
        </div>
        <HorizontalLine />
        <div className="ToolbarItem" onClick={() => handleCommand("formatBlock")}>
          <BlockQuoteSvg />
        </div>
        <div className="ToolbarItem" onClick={() => handleCommand("foreColor")}>
          <FontColorSvg />
        </div>
        <div className="ToolbarItem" onClick={() => handleCommand("fontSize")}>
          <FontSizeSvg />
        </div>
        <ColorPicker onSelectColor={handleColorChange} />
      </div>
      <div className="section-2">
        <DropdownButton
          generatePDF={handleDownloadAsPdf}
          generateDOCX={generateDOCX}
        />
      </div>
    </ToolbarWrapper>
  );
};

export default Toolbar;
