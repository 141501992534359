import React, { useState, useContext, useEffect } from "react";
import "./Register.css";
// import { useHistory } from "react-router-dom";
import {
  VALIDATOR_REQUIRE,
  VALIDATOR_MINLENGTH,
  VALIDATOR_EMAIL,
  VALIDATOR_MAXLENGTH,
} from "../../Components/Input/validators";
import PersonIcon from "@material-ui/icons/Person";
import ErrorModal from "../../Components/Input/ErrorModel";
import LoadingSpinner from "../../Components/Input/LoadingSpinner";
import EmailIcon from "@material-ui/icons/Email";
import PermPhoneMsgIcon from "@material-ui/icons/PermPhoneMsg";
import AssignmentIcon from "@material-ui/icons/Assignment";
import SchoolIcon from "@material-ui/icons/School";
import FiberPinIcon from "@material-ui/icons/FiberPin";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import PersonPinIcon from "@material-ui/icons/PersonPin";
import BrandingWatermarkIcon from "@material-ui/icons/BrandingWatermark";
import Input from "../../Components/Input/Input";
import ImageUpload from "../../Components/Input/ImageUpload";
import ImageUpload1 from "../../Components/Input/ImageUpload1";
import Button from "../../Components/Input/Button";
import RegisterImg from "../../assets/register11.svg";
import RegisterImg1 from "../../assets/register15.svg";
import RegisterImg2 from "../../assets/register7.svg";
import useForm from "../../Components/Hooks/formhooks";
import { useHttpClient } from "../../Components/Hooks/http-hook";
import { AuthContext } from "../../context/auth-context";
import Modal from "../../Components/Model.js";
import { FaMinus, FaPlus } from "react-icons/fa";
// import {
//   BrowserView,
//   MobileView,
//   isBrowser,
//   isMobile,
// } from "react-device-detect";

const Register = () => {
  const initialState = {
    inputs: {
      firstname: { value: "", isValid: false },
      lastname: { value: "", isValid: false },
      email: { value: "", isValid: false },
      mobile: { value: "", isValid: false },
      permanentAddress: { value: "", isValid: false },
      district: { value: "", isValid: false },
      pincode: { value: "", isValid: false },
      state: { value: "", isValid: false },
      cgpa: { value: "", isValid: false },
      rank: { value: "", isValid: false },
    },
    isValid: false,
  };

//   const history = useHistory();
  const auth = useContext(AuthContext);

  const [userMessage, setUserMessage] = useState({
    isView: false,
    message: "",
  });
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [formState, inputHandler] = useForm(initialState);
  const [inputList, setInputList] = useState([
    { skill: "", level: "", description: "" },
  ]);
  const [intermediateclgName, setClgName] = useState();
  const [intermediateclgAddress, setClgAddress] = useState();
  const [userSelect, setUserSelect] = useState(true);
  const [myclgInfo, setClgInfo] = useState({
    name: "CMRCET",
    clgId: "CMRCET",
    address: "1, Medchal Rd, Kandlakoya",
    district: "Hyderabad",
    state: "Telangana",
    pinCode: "501401",
  });

  const collegeName = [
    {
        name: "CMRCET",
        clgId: "CMRCET",
        address: "1, Medchal Rd, Kandlakoya",
        district: "Hyderabad",
        state: "Telangana",
        pinCode: "501401",
      },
      {
        name: "CMREC",
        clgId: "CMREC",
        address: "1, Medchal Rd, Kandlakoya",
        district: "Hyderabad",
        state: "Telangana",
        pinCode: "501401",
      },
      {
        name: "CMRIT",
        clgId: "CMRIT",
        address: "1, Medchal Rd, Kandlakoya",
        district: "Hyderabad",
        state: "Telangana",
        pinCode: "501401",
      },
      {
        name: "CMRTC",
        clgId: "CMRTC",
        address: "1, Medchal Rd, Kandlakoya",
        district: "Hyderabad",
        state: "Telangana",
        pinCode: "501401",
      },
  ];

  const DUM_SKILL = [
    "MS Office",
    "Python",
    "C Programming",
    "Javascript",
    "Java",
    "Data Structures and Algorithms",
    "DBMS",
    "C++ Programming",
    "SQL",
    "React.js",
    "Node.js",
    "Angular",
    "HTML",
    "CSS",
    "MongoDb",
    "Bootstrap",
    "JQuery",
    "AJAX",
    "Statistics",
  ];

  const handleChange = (e) => {
    if (e.target.value == "Others") {
      setUserSelect(false);
    }

    const myDumData = collegeName.filter((p) => p.name == e.target.value);
    setClgInfo(myDumData[0]);
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
    console.log(inputList)
  };

  const handleAddClick = () => {
    setInputList([...inputList, { skill: "", level: "", description: "" }]);
  };

  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  const myClgHandler = (e) => {
    if (e.target.id == "intermediateName") {
      setClgName(e.target.value);
    } else if (e.target.id == "intermediateAddress") {
      setClgAddress(e.target.value);
    }
  };

  const notdisablehandler = (e) => {
    setClgInfo({
      ...myclgInfo,
      [e.target.id]: e.target.value,
    });
    console.log(myclgInfo);
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    const mySkill = inputList.map((p) => {
      return p.skill;
    });

    const myLevel = inputList.map((p) => {
      return p.level;
    });

    const myDescription = inputList.map((p) => {
      return p.description;
    });

    // console.log(myDescription, myLevel, mySkill);

    const formData = new FormData();

    formData.append("fname", formState.inputs.firstname.value);
    formData.append("lname", formState.inputs.lastname.value);
    formData.append("email", formState.inputs.email.value);
    formData.append("mobile", formState.inputs.mobile.value);
    formData.append(
      "permanentAddress",
      formState.inputs.permanentAddress.value
    );
    formData.append("district", formState.inputs.district.value);
    formData.append("state", formState.inputs.state.value);
    formData.append("pincode", formState.inputs.pincode.value);
    // formData.append("resume", formState.inputs.resume.value);
    formData.append("collegeName", myclgInfo.name);
    formData.append("collegeAddress", myclgInfo.address);
    formData.append(
      "collegeDistrict",
      myclgInfo.district + ", " + myclgInfo.state
    );
    formData.append("collegePinCode", myclgInfo.pinCode);
    formData.append("collegeIdNumber", myclgInfo.clgId);
    formData.append("intermediateName", intermediateclgName);
    formData.append("intermediateAddress", intermediateclgAddress);
    formData.append("skill", mySkill);
    formData.append("level", myLevel);
    formData.append("description", myDescription);
    // formData.append("collegeId", formState.inputs.collegeId.value);
    formData.append("CGPA", formState.inputs.cgpa.value);
    formData.append("Rank", formState.inputs.rank.value);
    console.log(formData, typeof(formData), 'form data')
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/db/student-enroll`,
        "POST",
        JSON.stringify({
            "fname": formState.inputs.firstname.value,
            "lname": formState.inputs.lastname.value,
            "email": formState.inputs.email.value,
            "mobile": formState.inputs.mobile.value,
            "permanentAddress": formState.inputs.permanentAddress.value,
            "district": formState.inputs.district.value,
            "state": formState.inputs.state.value,
            "pincode": formState.inputs.pincode.value,
            // "resume": formState.inputs.resume.value,
            "collegeName": myclgInfo.name,
            "collegeAddress": myclgInfo.address,
            "collegeDistrict": myclgInfo.district + ", " + myclgInfo.state,
            "collegePinCode": myclgInfo.pinCode,
            "collegeIdNumber": myclgInfo.clgId,
            "intermediateName": intermediateclgName,
            "intermediateAddress": intermediateclgAddress,
            "description": myDescription,
            // "collegeId": formState.inputs.collegeId.value,
            "CGPA": formState.inputs.cgpa.value,
            "Rank": formState.inputs.rank.value
        }),
          {
            "Content-Type": "application/json",
          }
        
      );
      // setUserMessage({
      //   ...userMessage,
      //   isView: true,
      //   message: responseData.message,
      // });
      // console.log(responseData.message);

    //   auth.login(
    //     responseData.enrollmentId,
    //     responseData.name,
    //     responseData.email
    //   );

      console.log(
        responseData
      );

      alert("Thank you for enrolling.")

    //   history.push("/smat/dashboard");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <React.Fragment>
    <div className="register-page">
        <hr />

        <div className="register-flex">
        <div className="register-image-block">
            <img
            src={RegisterImg}
            alt="register image"
            className="register-img"
            />
            <img
            src={RegisterImg1}
            alt="register image1"
            className="register-img1"
            />
            <img
            src={RegisterImg2}
            alt="register image2"
            className="register-img2"
            />
        </div>

        {userMessage.isView ? (
            <div className="register-form">
            <p style={{ fontSize: "25px" }}>{userMessage.message}</p>
            </div>
        ) : (
            <div className="register-form">
            <ErrorModal error={error} onClear={clearError} />
            {isLoading && <LoadingSpinner asOverlay />}
            {!isLoading && (
                <form onSubmit={submitHandler}>
                <h2>Register Now</h2>
                <p>Please fill this form to Enroll for Campus Programme</p>
                <hr />
                <div className="glass-box">
                    <h3>Personal Details</h3>
                    <hr />
                    <div className="register1line2">
                    <div className="registerline-left">
                        <div className="form-group">
                        <div className="input-group">
                            <div className="input-box">
                            <PersonIcon className="input-icon" />
                            <Input
                                element
                                id="firstname"
                                errorText="Please enter a valid First Name"
                                validators={[VALIDATOR_REQUIRE()]}
                                label="First Name"
                                onInput={inputHandler}
                            />
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="registerline-right">
                        <div className="form-group">
                        <div className="input-group">
                            <div className="input-box">
                            <PersonIcon className="input-icon" />
                            <Input
                                element
                                id="lastname"
                                errorText="Please enter a valid Last Name"
                                validators={[VALIDATOR_REQUIRE()]}
                                label="Last Name"
                                onInput={inputHandler}
                            />
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>

                    <div className="register1line2">
                    <div className="registerline-left">
                        <div className="form-group">
                        <div className="input-group">
                            <div className="input-box">
                            <EmailIcon className="input-icon" />
                            <Input
                                element
                                id="email"
                                errorText="Please enter a valid Email"
                                validators={[VALIDATOR_EMAIL()]}
                                label="Email"
                                onInput={inputHandler}
                            />
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="registerline-right">
                        <div className="form-group">
                        <div className="input-group">
                            <div className="input-box">
                            <PermPhoneMsgIcon className="input-icon" />
                            <Input
                                element
                                id="mobile"
                                errorText="Please enter a valid Number"
                                validators={[VALIDATOR_MINLENGTH(10)]}
                                label="Mobile"
                                onInput={inputHandler}
                            />
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>

                    <div className="register1line2">
                    <div className="registerline-left">
                        <div className="form-group">
                        <div className="input-group">
                            <div className="input-box">
                            <PersonPinIcon className="input-icon" />
                            <Input
                                element
                                id="permanentAddress"
                                errorText="Please enter a valid Permanent Address"
                                validators={[VALIDATOR_REQUIRE()]}
                                label="Permanent Address"
                                onInput={inputHandler}
                            />
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="registerline-right">
                        <div className="form-group">
                        <div className="input-group">
                            <div className="input-box">
                            <PersonPinIcon className="input-icon" />
                            <Input
                                element
                                id="district"
                                errorText="Please enter a valid District"
                                validators={[VALIDATOR_REQUIRE()]}
                                label="District"
                                onInput={inputHandler}
                            />
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>

                    <div className="register1line2">
                    <div className="registerline-left">
                        <div className="form-group">
                        <div className="input-group">
                            <div className="input-box">
                            <PersonPinIcon className="input-icon" />
                            <Input
                                element
                                id="state"
                                errorText="Please enter a valid State"
                                validators={[VALIDATOR_REQUIRE()]}
                                label="State"
                                onInput={inputHandler}
                            />
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="registerline-right">
                        <div className="form-group">
                        <div className="input-group">
                            <div className="input-box">
                            <LocationOnIcon className="input-icon" />
                            <Input
                                element
                                id="pincode"
                                errorText="Please enter a valid PinCode"
                                validators={[VALIDATOR_MAXLENGTH(6)]}
                                label="Pin Code"
                                onInput={inputHandler}
                            />
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>

                    <div className="register1line2">
                    <div className="registerline-left">
                        <div className="form-group">
                        <div className="input-group">
                            <div className="input-box">
                            <SchoolIcon className="input-icon" />
                            <Input
                                element
                                id="cgpa"
                                errorText="Please enter a valid CGPA / %"
                                validators={[VALIDATOR_REQUIRE()]}
                                label="CGPA / %"
                                placeholder="until recent sem"
                                onInput={inputHandler}
                            />
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="registerline-right">
                        <div className="form-group">
                        <div className="input-group">
                            <div className="input-box">
                            <SchoolIcon className="input-icon" />
                            <Input
                                element
                                id="rank"
                                errorText="Please enter a valid Rank"
                                validators={[VALIDATOR_MAXLENGTH(9)]}
                                label="Entrance Rank(EAMCET/TSCET)"
                                placeholder="Or other rank,whichever applies"
                                onInput={inputHandler}
                            />
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                    <br />

                    <h3>Graduation Details</h3>
                    <hr />

                    <div className="register1line2">
                    <div className="registerline-left1">
                        <div className="form-group">
                        <div className="input-group">
                            <div className="input-box">
                            <SchoolIcon className="input-icon" />
                            <div className="college-des-div3">
                                <label>College Name</label>
                                {userSelect ? (
                                <select
                                    className="select-item-css"
                                    id="collegeName"
                                    name="collegeName"
                                    onChange={handleChange}
                                    value={myclgInfo.name}
                                >
                                    {collegeName.map((item) => {
                                    return (
                                        <option value={item.name}>
                                        {item.name}
                                        </option>
                                    );
                                    })}
                                </select>
                                ) : (
                                <input
                                    style={{
                                    width: "30rem",
                                    marginTop: "1rem",
                                    }}
                                    id="name"
                                    name="name"
                                    label="College Name"
                                    value={myclgInfo.name}
                                    onInput={notdisablehandler}
                                />
                                )}
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>

                    <div className="register1line2">
                    <div className="registerline-left1">
                        <div className="form-group">
                        <div className="input-group">
                            <div className="input-box">
                            <SchoolIcon className="input-icon" />
                            <div className="college-des-div">
                                <label>College Address</label>
                                {userSelect ? (
                                <input
                                    disabled
                                    id="collegeAddress"
                                    label="College Address"
                                    value={myclgInfo.address}
                                />
                                ) : (
                                <input
                                    id="address"
                                    label="College Address"
                                    value={myclgInfo.address}
                                    onInput={notdisablehandler}
                                />
                                )}
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="registerline-right1">
                        <div className="form-group">
                        <div className="input-group">
                            <div className="input-box">
                            <LocationOnIcon className="input-icon" />
                            <div className="college-des-div1">
                                <label>District</label>
                                {userSelect ? (
                                <input
                                    disabled
                                    name="clgdistrict"
                                    id="clgdistrict"
                                    value={myclgInfo.district}
                                />
                                ) : (
                                <input
                                    name="district"
                                    id="district"
                                    value={myclgInfo.district}
                                    onInput={notdisablehandler}
                                />
                                )}
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>

                    <div className="register1line2">
                    <div className="registerline-left1">
                        <div className="form-group">
                        <div className="input-group">
                            <div className="input-box">
                            <LocationOnIcon className="input-icon" />
                            <div className="college-des-div">
                                <label>State</label>
                                {userSelect ? (
                                <input
                                    disabled
                                    name="clgstate"
                                    id="clgstate"
                                    value={myclgInfo.state}
                                />
                                ) : (
                                <input
                                    name="state"
                                    id="state"
                                    value={myclgInfo.state}
                                    onInput={notdisablehandler}
                                />
                                )}
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="registerline-right1">
                        <div className="form-group">
                        <div className="input-group">
                            <div className="input-box">
                            <LocationOnIcon className="input-icon" />
                            <div className="college-des-div1">
                                <label>Pincode</label>
                                {userSelect ? (
                                <input
                                    disabled
                                    minlength="6"
                                    id="clgpinCode"
                                    value={myclgInfo.pinCode}
                                />
                                ) : (
                                <input
                                    minlength="6"
                                    id="pinCode"
                                    value={myclgInfo.pinCode}
                                    onInput={notdisablehandler}
                                />
                                )}
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>

                    <h3>Intermediate(11,12) Details</h3>
                    <hr />

                    <div className="register1line2">
                    <div className="registerline-left1">
                        <div className="form-group">
                        <div className="input-group">
                            <div className="input-box">
                            <SchoolIcon className="input-icon" />
                            <div className="college-des-div">
                                <label>School/College Name</label>
                                <input
                                style={{ width: "30rem" }}
                                id="intermediateName"
                                value={intermediateclgName}
                                onInput={myClgHandler}
                                />
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>

                    <div className="register1line2">
                    <div className="registerline-left1">
                        <div className="form-group">
                        <div className="input-group">
                            <div className="input-box">
                            <LocationOnIcon className="input-icon" />
                            <div className="college-des-div">
                                <label>School/College Address</label>
                                <input
                                style={{ width: "30rem" }}
                                id="intermediateAddress"
                                placeholder="City and State info would be sufficient"
                                value={intermediateclgAddress}
                                onInput={myClgHandler}
                                />
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                    <br />
                    <br />

                    <div
                    style={{ marginTop: "-2rem" }}
                    className="form-group"
                    >
                    {/* <div className="input-group">
                        <div className="input-box">
                        <AssignmentIcon className="input-icon" />
                        <label style={{ flex: "1" }} for="skills">
                            Skill
                        </label>
                        <label style={{ flex: "1" }} for="skills">
                            Level
                        </label>
                        </div>
                        {inputList.map((x, i) => {
                        return (
                            <div className="box">
                            <div className="box-div">
                                <select
                                className="select-item-css"
                                id="skill"
                                name="skill"
                                value={x.skill}
                                onChange={(e) => handleInputChange(e, i)}
                                >
                                {DUM_SKILL.map((item) => {
                                    return (
                                    <option value={item}>{item}</option>
                                    );
                                })}
                                </select>
                                <select
                                className="select-item-css"
                                id="level"
                                name="level"
                                value={x.level}
                                onChange={(e) => handleInputChange(e, i)}
                                >
                                <option selected value="Beginner">
                                    Beginner
                                </option>
                                <option value="Average">Average</option>
                                <option value="Proficient">
                                    Proficient
                                </option>
                                </select>
                            </div>

                            <textarea
                                className="text-area-div"
                                placeholder="Describe about your skill( more than 15 words)"
                                type="text"
                                minLength={15}
                                name="description"
                                value={x.description}
                                onChange={(e) => handleInputChange(e, i)}
                            />

                            <div className="btn-box">
                                {inputList.length !== 1 && (
                                <button
                                    style={{
                                    marginLeft: "0.5rem",
                                    marginRight: "0.5rem",
                                    }}
                                    className="mr10"
                                    onClick={() => handleRemoveClick(i)}
                                >
                                    <FaMinus style={{ color: "#1D0B51" }} />
                                </button>
                                )}
                                {inputList.length - 1 === i && (
                                <button
                                    style={{
                                    marginLeft: "0.5rem",
                                    marginRight: "0.5rem",
                                    }}
                                    className="mr101"
                                    onClick={handleAddClick}
                                >
                                    <FaPlus style={{ color: "#1D0B51" }} />
                                </button>
                                )}
                            </div>
                            </div>
                        );
                        })}
                    </div> */}
                    </div>

                    {/* <div className="register1line2">
                    <div className="registerline-left">
                        <div className="form-group">
                        <div className="input-group">
                            <div className="input-box">
                            <AssignmentIcon className="input-icon" />
                            <h4 className="form-label">
                                {" "}
                                Upload Your Resume
                            </h4>
                            <ImageUpload
                                id="resume"
                                onInput={inputHandler}
                            />
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="registerline-right">
                        <div className="form-group">
                        <div className="input-group">
                            <div className="input-box">
                            <BrandingWatermarkIcon className="input-icon" />
                            <h4 className="form-label">
                                {" "}
                                Upload Your College Id
                            </h4>
                            <ImageUpload1
                                id="collegeId"
                                onInput={inputHandler}
                            />
                            </div>
                        </div>
                        </div>
                    </div>
                    </div> */}
                </div>

                <div className="form-register-button">
                    <Button type="submit" disabled={!formState.isValid}>
                    {" "}
                    Click to Register{" "}
                    </Button>
                </div>
                </form>
            )}
            </div>
        )}
        </div>
    </div>
    </React.Fragment>
  );
};

export default Register;
