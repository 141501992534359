import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import websiteMaintenance from "../../../../../../../../../assets/website-maintenance.svg";
import Loading from "../../../../../../../../../Components/Loading";

const NotFound = () => {
    const navigate = useNavigate();
    const { cid, ct } = useParams();
    const [isLoading, setIsLoading] = useState(true);

    const goToHome = () => {
        navigate("/skillbox-v2");
    }

    const goToPathWay = () => {
        navigate(`/skillbox-v2/${cid}/${ct}`);
    }

    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);
        }, 5000);
    }, []);

    return (
        <>
            <div className="stu-skillbox-nav-header">
                <span onClick={goToHome}>Home</span>
                <span>
                    {" "}&gt;{" "}
                </span>
                <span onClick={goToPathWay}>Pathway</span>
            </div>
            <div className="under-development-page">
                {isLoading ? (
                    <div className="st-skillbox-programs-mn-ct-section">
                        <Loading color="#004c73" customClass="st-cls-interview-loading" />
                    </div>
                ) : (
                        <div className="under-development-container">
                            <div className="construction-svg-container">
                                <img
                                    src={websiteMaintenance}
                                    alt="website-maintenance"
                                />
                            </div>
                            <p className="under-development-text">
                                Module and Classes Not found. Please Check again in a while.
                            </p>
                            <button
                                className="previous-version-button"
                                onClick={() => goToHome()}
                            >
                                Go to Back
                            </button>
                        </div>
                    )}
            </div>
        </>
    );
};

export default NotFound;
