import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router";
import { useHttpClient } from "../../../../Components/Hooks/http-hook";
import { AuthContext } from "../../../../context/auth-context";
import Select from "../../../../Components/Profile/Input/Select";
import Button from "../../../../Components/Profile/Button/Button";
import "./CodingAssessment.css";
import Loading from "../../../../Components/Loading";
const swal = require("sweetalert2");

const Levels = [
  { value: "Easy", label: "Easy" },
  { value: "Medium", label: "Medium" },
  { value: "Hard", label: "Hard" },
];

const CodingAssessment = () => {
  const codingTestsLeft = 5;
  const history = useNavigate();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const {
    isLoading: isLoading1,
    error: error1,
    sendRequest: sendRequest1,
    clearError: clearError1,
  } = useHttpClient();
  const {
    isLoading: isLoading2,
    error: error2,
    sendRequest: sendRequest2,
    clearError: clearError2,
  } = useHttpClient();
  const auth = useContext(AuthContext);

  const [topics, setTopics] = useState([]);
  const [levels, setLevels] = useState([]);
  const [codingTopics, setCodingTopics] = useState([]);
  const [selectedTab, setSelectedTab] = useState("mcqs");
  const [selectedTopic, setSelectedTopic] = useState("");
  const [selectedLevel, setSelectedLevel] = useState("");
  const [selectedTopicForCodingQuestions, setSelectedTopicForCodingQuestions] =
    useState("");
  const [selectedLevelForCodingQuestions, setSelectedLevelForCodingQuestions] =
    useState("");
  const [buttonLoading, setButtonLoading] = useState(false);
  const [codingButtonLoading, setCodingButtonLoading] = useState(false);

  const formatSelectTopics = (data) => {
    return data.map((d) => {
      return {
        value: d.topic_id,
        label: d.topic_name,
      };
    });
  };

  const formatCodingSelectTopics = (data) => {
    return data.map((d) => {
      return {
        value: d.id,
        label: d.topic_name,
      };
    });
  };

  const formatSelectLevels = (data) => {
    return data.map((d) => {
      return {
        value: d.level_id,
        label: d.level_name,
      };
    });
  };

  useEffect(() => {
    const fetchTopicsAndLevels = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/prep/get-all-programming-topics-and-levels`,
          "GET",
          null,
          {
            "Content-Type": "application/json",
            "Refresh-Token": auth.refresh_token,
            Authorization: auth.token,
          }
        );
        setTopics(formatSelectTopics(responseData.data.topics));
        setLevels(formatSelectLevels(responseData.data.levels));
      } catch (err) {
        console.log(err);
      }
    };
    fetchTopicsAndLevels();
  }, []);

  useEffect(() => {
    const fetchCodingTopics = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/api/coding/get-all-coding-topics`,
          "GET",
          null,
          {
            "Content-Type": "application/json",
            "Refresh-Token": auth.refresh_token,
            Authorization: auth.token,
          }
        );
        setCodingTopics(formatCodingSelectTopics(responseData.data.topics));
      } catch (err) {
        console.log(err);
      }
    };
    fetchCodingTopics();
  }, []);

  const handleStartMcqTest = async () => {
    if (selectedLevel && selectedTopic) {
      try {
        setButtonLoading(true);
        const responseData = await sendRequest1(
          `${process.env.REACT_APP_BACKEND_URL}/prep/start-test`,
          "POST",
          JSON.stringify({
            topic_id: selectedTopic.value,
            level_id: selectedLevel.value,
          }),
          {
            "Content-Type": "application/json",
            "Refresh-Token": auth.refresh_token,
            Authorization: auth.token,
          }
        );
        if (responseData.data && responseData.data) {
          history(`/aptitude-platform/exam/${responseData.data}`);
          if (document.body.requestFullscreen) {
            document.body.requestFullscreen().catch((e) => {
              console.log("error while full screen ", e);
            });
          }
        } else {
          setButtonLoading(false);
          swal.fire({
            title: "Something went wrong",
            text: "Cannot start the test, please try again later",
            icon: "error",
            confirmButtonText: "Okay",
          });
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handleStartCodingTest = async () => {
    if (selectedTopicForCodingQuestions && selectedLevelForCodingQuestions) {
      setCodingButtonLoading(true);
      try {
        const responseData = await sendRequest2(
          `${process.env.REACT_APP_BACKEND_URL}/api/coding/start_exam/${selectedTopicForCodingQuestions.value}/${selectedLevelForCodingQuestions.value}`,
          "GET",
          null,
          {
            "Content-Type": "application/json",
            "Refresh-Token": auth.refresh_token,
            Authorization: auth.token,
          }
        );
        if (responseData) {
          setCodingButtonLoading(false);
          history(`/coding-platform/exam/${responseData.data}`);
        } else {
          setCodingButtonLoading(false);
          swal.fire({
            title: "Error!",
            text: responseData.message || "Something went wrong",
            icon: "error",
            confirmButtonText: "Okay",
          });
        }
      } catch (err) {
        setCodingButtonLoading(false);
        swal.fire({
          title: "Error!",
          text: "Something went wrong",
          icon: "error",
          confirmButtonText: "Okay",
        });
      }
    }
  };

  useEffect(() => {
    if (error1) {
      setButtonLoading(false);
      swal.fire({
        title: "Error!",
        text: "Cannot start the test, please try again later",
        icon: "error",
        confirmButtonText: "Okay",
      });
      clearError1();
    }
  }
  , [error1, clearError1]);

  useEffect(() => {
    if (error2) {
      setCodingButtonLoading(false);
      swal.fire({
        title: "Error!",
        text: "Cannot start the test, please try again later",
        icon: "error",
        confirmButtonText: "Okay",
      });
      clearError2();
    }
  }
  , [error2, clearError2]);

  return (
    <div className="coding-assessment">
      <div className="coding-assessment-top">
        <div className="coding-assessment-test-left">
          <div className="coding-assessment-title">Coding Assessment</div>
          <div className="coding-assessment-subtitle">
            You can schedule a test to assess your coding skills and improve
          </div>
          Total Tests Left: {codingTestsLeft} / 10
        </div>
        <div className="coding-assessment-test-right">
          <Button
            width="250px"
            onClick={() => history("/coding-platform")}
            text="Start Random Coding Test"
          />
        </div>
      </div>

      <div className="coding-assessment-tab-container">
        <div className="coding-assessment-tab-container-section-1">
          <div className="coding-assessment-tabs">
            <div
              className={`coding-assessment-tab ${
                selectedTab === "mcqs" && "active"
              }`}
              onClick={() => setSelectedTab("mcqs")}
            >
              Programming MCQs
            </div>
            <div
              className={`coding-assessment-tab ${
                selectedTab === "questions" && "active"
              }`}
              onClick={() => setSelectedTab("questions")}
            >
              Coding Questions
            </div>
          </div>
        </div>
        <div className="coding-assessment-tab-container-section-2">
          {selectedTab === "mcqs" && (
            <div className="coding-assessment-container">
              <div className="coding-assessment-subtitle-info">
                Please select the topic and level to attempt the practice coding
                mcqs challenges
              </div>
              <div className="coding-assessment-horizontal-line"></div>
              <div className="coding-assessment-selects">
                <div className="coding-assessment-select-1">
                  <div className="coding-assessment-select-label">
                    Select Topic
                  </div>
                  <Select
                    width="300px"
                    isDisabled={false}
                    onChange={(value) => setSelectedTopic(value)}
                    selectData={topics}
                    selectLabel={true}
                  />
                </div>
                <div className="coding-assessment-select-2">
                  <div className="coding-assessment-select-label">
                    Select Level
                  </div>
                  <Select
                    width="300px"
                    isDisabled={false}
                    onChange={(value) => setSelectedLevel(value)}
                    selectData={levels}
                    selectLabel={true}
                  />
                </div>
              </div>
              {buttonLoading ? (
                <Loading
                  color="blue"
                  customClass="assesment-loading-container"
                />
              ) : (
                <Button
                  width="350px"
                  onClick={handleStartMcqTest}
                  disabled={!selectedTopic || !selectedLevel}
                  text="Start Test"
                />
              )}
            </div>
          )}

          {selectedTab === "questions" && (
            <div className="coding-assessment-container">
              <div className="coding-assessment-subtitle-info">
                Please select the topic and level to attempt the practice coding
                questions challenges
              </div>
              <div className="coding-assessment-horizontal-line"></div>
              <div className="coding-assessment-selects">
                <div className="coding-assessment-select-1">
                  <div className="coding-assessment-select-label">
                    Select Topic
                  </div>
                  <Select
                    width="300px"
                    isDisabled={false}
                    onChange={(value) =>
                      setSelectedTopicForCodingQuestions(value)
                    }
                    selectData={codingTopics}
                    selectLabel={true}
                  />
                </div>
                <div className="coding-assessment-select-2">
                  <div className="coding-assessment-select-label">
                    Select Level
                  </div>
                  <Select
                    width="300px"
                    isDisabled={false}
                    onChange={(value) =>
                      setSelectedLevelForCodingQuestions(value)
                    }
                    selectData={Levels}
                    selectLabel={true}
                  />
                </div>
              </div>
              {codingButtonLoading ? (
                <Loading
                  color="blue"
                  customClass="assesment-loading-container"
                />
              ) : (
                <Button
                  width="350px"
                  onClick={handleStartCodingTest}
                  disabled={
                    !selectedTopicForCodingQuestions ||
                    !selectedLevelForCodingQuestions
                  }
                  text="Start Test"
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CodingAssessment;
