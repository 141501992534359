import React, { useEffect, useRef } from "react";
import "./BestOfLuck.css";
import startinterviewanimation from "../../../../../../../assets/animations/startinterview.mp4";

const BestOfLuck = ({
  setShowInterviewPlatformPopup,
  setShowInterviewBestOfLuckPopup,
}) => {
  const videoRef = useRef(null);

  useEffect(() => {
    const handleVideoEnd = () => {
      setShowInterviewPlatformPopup(true);
      setShowInterviewBestOfLuckPopup(false);
    };
    if (videoRef.current) {
      videoRef.current.addEventListener("ended", handleVideoEnd);
    }

    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener("ended", handleVideoEnd);
      }
    };
  }, []);

  return (
    <div className="best-of-luck-container">
      <div className="best-of-luck-video-container">
        <video ref={videoRef} autoPlay className="best-of-luck-video">
          <source src={startinterviewanimation} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className="best-of-luck-subheading">
        Prepare yourself! We are about to begin now{" "}
      </div>
    </div>
  );
};

export default BestOfLuck;
