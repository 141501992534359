import React from "react";

export const FaqsData = [
    {
        id: 1,
        question: 'What is the referral program?',
        answer: 'The referral program is a way to earn rewards by referring Surge Classes Courses to your friends and family. You can refer Surge Classes to your friends and family and earn rewards when they make a purchase.'
    },
    {
        id: 2,
        question: 'How do I refer a friend?',
        answer: 'You can refer a friend by sharing your referral link with them. You can find your referral link in your account dashboard.'
    },
    {
        id: 3,
        question: 'How do I earn rewards?',
        answer: 'You can earn rewards by referring Surge Classes to your friends and family. You will earn rewards when they make a purchase using your referral link or code.'
    },
    {
        id: 4,
        question: 'How do I redeem my rewards?',
        answer: 'You can redeem your rewards on Skillbox by going to claim rewards section and it will be transferred to your account within 1-2 week.'
    },
    {
        id: 5,
        question: 'How do I track my rewards?',
        answer: 'You can track your rewards in your Skillbox referral code section. You will be able to see status of your rewards and when they will be transferred to your account.'
    },
    {
        id: 6,
        question: 'How do I share my referral link?',
        answer: 'You can share your referral link by copying it and pasting it into an email, text message, or social media post. You can also share your referral link by clicking the share button on your account dashboard.'
    },
    {
        id: 7,
        question: 'How do I know if someone has used my referral link?',
        answer: 'It will be instantly updated in your Skillbox referral code section. You will be able to see who has used your referral link and when they made a purchase.'
    },
    {
        id: 8,
        question: 'How do I contact customer support?',
        answer: 'You can contact customer support by emailing or calling us. You can find our contact information on our website.'
    },
]


