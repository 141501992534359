import React, { useState, useEffect, useRef, useContext } from "react";
import SearchSvg from "../../Components/Svgs/SearchSvg";
import AddSvg from "../../Components/Svgs/AddSvg";
import PopUp from "../../../../../Components/Profile/Popup/PopUp";
import MinusSvg from "../../Components/Svgs/MinusSvg";
import PlusSvg from "../../Components/Svgs/PlusSvg";
import Loading from "../../../../../Components/Loading";
import { AuthContext } from "../../../../../context/auth-context";
import "./Roles.css";
import { useHttpClient } from "../../../../../Components/Hooks/http-hook";

const Roles = ({
  handleTabChange,
  setSelectedRolesData,
  selectedRolesData,
}) => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const {isLoading: isLoadingAddRole, error: errorAddRole, sendRequest: sendRequestAddRole, clearError: clearErrorAddRole} = useHttpClient();
  const auth = useContext(AuthContext);
  const [roles, setRoles] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredRoles, setFilteredRoles] = useState([]);
  const [showAddRoleModal, setShowAddRoleModal] = useState(false);
  const [newRoleName, setNewRoleName] = useState("");
  const [isAddingRole, setIsAddingRole] = useState(false);
  const [addRoleError, setAddRoleError] = useState("");
  const newRoleInputRef = useRef(null);
  const [showPopup, setShowPopup] = useState(false);
  const [questionCount, setQuestionCount] = useState(50);
  const [isGenerating, setIsGenerating] = useState(false);
  const [generateProgress, setGenerateProgress] = useState(0);
  const [generateError, setGenerateError] = useState(null);
  const [csvGenerated, setCsvGenerated] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const [generatedRoleData, setGeneratedRoleData] = useState(null);

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/api/qgt/roles`,
          "GET",
          null,
          {
            "Content-Type": "application/json",
            Authorization: auth.token,
          }
        );
        if (responseData.ok) {
          setRoles(responseData.data);
          setFilteredRoles(responseData.data);
        }
      } catch (err) {
        console.log(err);
      }
    };

    fetchRoles();
  }, [sendRequest]);

  useEffect(() => {
    if (searchTerm && searchTerm.trim() !== "") {
      setFilteredRoles(
        roles.filter(
          (role) =>
            role &&
            role.title &&
            role.title.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    } else {
      setFilteredRoles(roles);
    }
  }, [searchTerm, roles]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleAddRole = async () => {
    if (newRoleName.trim()) {
      if (roles.some(role => role.title.toLowerCase() === newRoleName.trim().toLowerCase())) {
        setAddRoleError("This role already exists");
      } else {
        setIsAddingRole(false);
        setShowPopup(true);
      }
    }
  };

  const handleGenerateCSV = async () => {
    setIsGenerating(true);
    setGenerateError(null);
    setCsvGenerated(false);
    setShowSuccessMessage(false);
    clearErrorAddRole();
    setGenerateProgress(0);

    try {
      const responseData = await sendRequestAddRole(
        `${process.env.REACT_APP_BACKEND_URL}/api/qgt/roles/add`,
        "POST",
        JSON.stringify({
          title: newRoleName.trim(),
          questionCount: questionCount
        }),
        {
          "Content-Type": "application/json",
          Authorization: auth.token,
        }
      );

      if (responseData.ok) {
        setRoles([...roles, responseData.data]);
        setGeneratedRoleData(responseData.data);
        setCsvGenerated(true);
        setShowSuccessMessage(true);
        setGenerateProgress(100);
      } else {
        throw new Error(responseData.message || "Failed to add role");
      }
    } catch (err) {
      setGenerateError(err.message);
    } finally {
      setIsGenerating(false);
    }
  };

  const handleDownloadCSV = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/qgt/roles/${generatedRoleData.id}/download`,
        {
          method: 'GET',
          headers: {
            Authorization: auth.token,
          },
        }
      );

      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = `${generatedRoleData.title}_questions.csv`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      } else {
        throw new Error('Failed to download CSV');
      }
    } catch (error) {
      console.error('Error downloading CSV:', error);
      // You might want to show an error message to the user here
    }
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    setQuestionCount(50);
    setIsGenerating(false);
    setGenerateProgress(0);
    setGenerateError(null);
    setCsvGenerated(false);
    setShowSuccessMessage(false);
    setIsAddingRole(false);
    setAddRoleError("");
    setNewRoleName("");
    clearErrorAddRole();
  };

  useEffect(() => {
    if (isAddingRole && newRoleInputRef.current) {
      newRoleInputRef.current.focus();
    }
  }, [isAddingRole]);

  const handleDecrementCount = () => {
    setQuestionCount(Math.max(10, questionCount - 1));
  };

  const handleIncrementCount = () => {
    setQuestionCount(Math.min(100, questionCount + 1));
  };

  return (
    <div className="qt-roles-container">
      {isLoading || error ? (
        <div className="qt-error-section">
          {isLoading && (
            <Loading color="#004c73" customClass="qt-roles-loading" />
          )}
          {error && <div className="qt-roles-error-message">{error}</div>}
        </div>
      ) : (
        <>
          <div className="qt-roles-header">Select role</div>
          <div className="qt-roles-actions">
            <div className="qt-search-box">
              <input
                type="text"
                placeholder="Search for role"
                value={searchTerm}
                onChange={handleSearch}
                className="qt-search-container-input"
              />
              <SearchSvg />
            </div>
            <div className="orText">Or</div>
            {!isAddingRole ? (
              <div
                className="qt-add-role-btn"
                onClick={() => setIsAddingRole(true)}
              >
                Add New Role <AddSvg />
              </div>
            ) : (
              <div className="qt-add-role-input-container">
                {addRoleError && (
                  <div className="qt-add-role-error">{addRoleError}</div>
                )}
                <div className="qt-add-role-input-wrapper">
                  <input
                    ref={newRoleInputRef}
                    type="text"
                    className={`qt-add-role-input ${
                      addRoleError ? "error" : ""
                    }`}
                    placeholder="Add new role"
                    value={newRoleName}
                    onChange={(e) => setNewRoleName(e.target.value)}
                    onKeyPress={(e) => e.key === "Enter" && handleAddRole()}
                    onBlur={() => {
                      if (!newRoleName.trim()) {
                        setIsAddingRole(false);
                        setAddRoleError("");
                      }
                    }}
                  />
                  <button
                    className="qt-add-role-button"
                    onClick={handleAddRole}
                    disabled={!newRoleName.trim() || addRoleError}
                  >
                    <AddSvg />
                  </button>
                </div>
              </div>
            )}
          </div>
          <div className="qt-roles-header">
            Existing roles ({filteredRoles.length})
          </div>
          <div className="qt-roles-grid">
            {filteredRoles &&
              filteredRoles.map((role, index) => (
                <div
                  key={index}
                  className="qt-role-box"
                  onClick={() => {
                    setSelectedRolesData(role);
                    handleTabChange("roleselected");
                  }}
                >
                  {role.title}
                  {/* <div className="qt-role-box-question-count">
                    <span className="qt-role-box-question-count-text">
                      Questions Count:
                    </span>{" "} {role.questionCount}
                  </div> */}
                </div>
              ))}
          </div>
          {showAddRoleModal && (
            <div className="modal">
              <div className="modal-content">
                <h2>Add New Role</h2>
                <input
                  type="text"
                  placeholder="New role name"
                  value={newRoleName}
                  onChange={(e) => setNewRoleName(e.target.value)}
                />
                <div className="modal-buttons">
                  <button onClick={handleAddRole}>Add</button>
                  <button onClick={() => setShowAddRoleModal(false)}>
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          )}
          <PopUp
            title={`${newRoleName}`}
            onClose={handleClosePopup}
            show={showPopup}
          >
            <div className="qgt-slider-container-wrapper">
              {!csvGenerated && !showSuccessMessage && (
                <div className="qgt-slider-container">
                  <label htmlFor="questionCount" className="qgt-slider-label">
                    Select no. of questions per topic
                  </label>
                  <div className="qgt-slider-wrapper">
                    <div
                      className="qgt-slider-button"
                      onClick={handleDecrementCount}
                    >
                      <MinusSvg />
                    </div>
                    <div className="qgt-slider-input-wrapper">
                      <input
                        type="range"
                        id="questionCount"
                        className="qgt-slider"
                        min="10"
                        max="100"
                        value={questionCount}
                        onChange={(e) =>
                          setQuestionCount(parseInt(e.target.value))
                        }
                      />
                      <div
                        className="qgt-slider-value"
                        style={{
                          left: `calc(${((questionCount - 10) / 90) * 100}%)`,
                        }}
                      >
                        {questionCount}
                      </div>
                    </div>
                    <div
                      className="qgt-slider-button"
                      onClick={handleIncrementCount}
                    >
                      <PlusSvg />
                    </div>
                  </div>
                </div>
              )}
              {/* {isGenerating && (
                <div className="qgt-progress-container">
                  <div className="qgt-progress-bar">
                    <div 
                      className="qgt-progress-fill" 
                      style={{ width: `${generateProgress}%` }}
                    ></div>
                  </div>
                  <p>Generating questions... {generateProgress}%</p>
                </div>
              )} */}
              {isLoadingAddRole && (
                <div className="qgt-loading">
                  <Loading color="#004c73" customClass="qt-roles-loading" />
                  <p>Generating questions...</p>
                </div>
              )}
              {!csvGenerated && !errorAddRole && !showSuccessMessage && !isGenerating && (
                <div className="qgt-generate-csv-button-wrapper">
                  <button
                    onClick={handleGenerateCSV}
                    className="rolesHistoryHeaderButton"
                    disabled={isGenerating}
                  >
                    Generate CSV
                  </button>
                </div>
              )}
              {showSuccessMessage && (
                <div className="qgt-success-message">
                  <div className="qgt-success-message-text">
                    Questions for {newRoleName} generated successfully, you can
                    download the CSV or view the details.
                  </div>
                  <div className="qgt-success-actions">
                    <button
                      onClick={handleDownloadCSV}
                      style={{ width: "150px" }}
                      className="rolesHistoryHeaderButton"
                    >
                      Download CSV
                    </button>
                    <button
                      onClick={() => {
                        setShowAddRoleModal(false);
                        setShowSuccessMessage(false);
                        setIsGenerating(false);
                        setGenerateProgress(0);
                        setGenerateError(null);
                        setCsvGenerated(false);
                        setNewRoleName("");
                        setAddRoleError("");
                        setSelectedRolesData(generatedRoleData);
                        handleTabChange("roleselected");
                      }}
                      style={{ width: "150px" }}
                      className="rolesHistoryHeaderButton"
                    >
                      View Details
                    </button>
                  </div>
                </div>
              )}
              {(errorAddRole || generateError) && (
                <div className="qgt-error-actions">
                  <p className="qgt-error-message">{errorAddRole || generateError}</p>
                  <button onClick={handleGenerateCSV} className="qgt-action-button">
                    Retry
                  </button>
                  <button
                    onClick={handleClosePopup}
                    className="qgt-action-button"
                  >
                    Go Back
                  </button>
                </div>
              )}
            </div>
          </PopUp>
        </>
      )}
    </div>
  );
};

export default Roles;
