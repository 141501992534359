import React from "react";
import PopUp from "../UiComponents/Popup/PopUp";
import "./DeleteCourse.css";
import { useState, useContext, useEffect } from "react";
import { AdminContext } from "../../../../context/admin-context";
import { useHttpClient } from "../../../../Components/Hooks/http-hook";
import LoadingSpinner from "../../../../Components/Input/LoadingSpinner";
const swal = require("sweetalert2");

const DeleteCourse = ({ course, showPopup, setShowPopup, onDelete }) => {

  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const admin = useContext(AdminContext);
  const [buttonLoading, setButtonLoading] = useState(false);

  const handleDelete = async (e) => {
    e.preventDefault();
    setButtonLoading(true);
    try {
      const response = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/db/course/${course.course_id}`,
        "DELETE",
        null,
        {
          "Content-Type": "application/json",
          "Refresh-Token": admin.refresh_token,
          Authorization: admin.token,
        }
      );
      if (response) {
        setButtonLoading(false);
        setShowPopup(false);
        onDelete({
          course_id: course.course_id,
        })
        swal.fire({
          title: "Success",
          text: "Course Deleted Successfully",
          icon: "success",
          confirmButtonText: "Ok",
        });
      }
    } catch (err) {
      setButtonLoading(false);
      setShowPopup(false);
      swal.fire({
        title: "Error Occured",
        text: err.message,
        icon: "error",
        confirmButtonText: "Ok",
      });
    }
  }

  useEffect(() => {
    if (error) {
      setButtonLoading(false);
      setShowPopup(false);
      swal.fire({
        title: "Error!",
        text: error || "Something went wrong, please try again later.",
        icon: "error",
        confirmButtonText: "Close",
      });
      clearError();
    }
  }
  , [error, clearError, setShowPopup]);


  return (
    <PopUp onClose={() => setShowPopup(false)} show={showPopup}>
      {buttonLoading ? (
      <div className="delete_course_popup__loading">
        <LoadingSpinner />
      </div>
      ) : (
      <div className="delete_course_popup__content">
        <div className="delete_course_popup__content-row">
          <div className="delete_course_popup__content-row-title">
            <p>{course.course_name}</p>
          </div>
          Are you sure you want to delete this course, This action can't be
          undone?
          <div className="delete_course_popup__buttons">
            <button
              className="delete_course_popup__buttons-cancel"
              onClick={() => setShowPopup(false)}
            >
              Cancel
            </button>
            <button
              className="delete_course_popup__buttons-delete"
              onClick={handleDelete}
            >
              Delete
            </button>
          </div>
        </div>
      </div>
      )}
    </PopUp>
  );
};
export default DeleteCourse;
