import React, { useState } from "react";
import "./AdminSmatInfo.css";
import { useHttpClient } from "../../Components/Hooks/http-hook";
import LoadingSpinner from "../../Components/Input/LoadingSpinner";
import { useEffect } from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import LogoImg from "../../assets/logo1.png";

const AdminSmatInfo = () => {
  const [totalReg, setTotalReg] = useState();
  const [allUser, setAllUser] = useState([]);
  const [clgArray, setClgArray] = useState([]);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/admin/smatinfo`,
          "GET"
        );

        setTotalReg(responseData.totalReg);
        setAllUser(responseData.allUser);
        setClgArray(responseData.clgArray);
      } catch (error) {}
    };

    fetchProfileData();
  }, []);

  return isLoading ? (
    <LoadingSpinner asOverlay />
  ) : (
    <div className="adminSmatPage">
      <div className="smat-admin-h">
        <h3>Total Number of Students Registered in SMAT : {totalReg}</h3>
      </div>
      <div className="smat-info-flex">
        <div className="smat-info-1">
          <Scrollbars
            className="smat-info1-div"
            autoHide
            autoHideTimeout={1000}
            autoHideDuration={310}
            autoHeightMin={200}
            autoHeightMax={1080}
            thumbMinSize={30}
            universal={true}
            style={{ width: `100%`, height: `30rem` }}
          >
            {allUser &&
              allUser.map((item) => {
                return (
                  <div className="info1-div-map">
                    <div className="map-flex-img">
                      <img src={LogoImg}></img>
                    </div>
                    <div className="map-flex-content">
                      <h1>{item.name}</h1>
                      <h3>{item.enrollmentId}</h3>
                      <p>{item.email}</p>
                      <p>{item.mobile}</p>
                    </div>
                    <div className="map-flex-content1">
                      <button>Show More</button>
                    </div>
                  </div>
                );
              })}
          </Scrollbars>
        </div>
        <div className="smat-info-2">
          <h2>College Wise Student Registration</h2>
          {clgArray &&
            clgArray.map((item) => {
              return (
                <div className="info2-flex">
                  <h3>{item.collegeName}</h3>
                  <p>{item.count}</p>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default AdminSmatInfo;
