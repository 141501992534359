import React, { useEffect, useState } from "react";
import "./ModuleContent.css";
import ProgressBar from "@ramonak/react-progress-bar";
import { useNavigate, useParams } from "react-router-dom";
import { TiTickOutline } from "react-icons/ti";
import { FiPlayCircle } from "react-icons/fi";
import { BsArrowRight, BsBook } from "react-icons/bs";
import ReactPlayer from "react-player";
import { Scrollbars } from "react-custom-scrollbars-2";

import {
  IoIosArrowBack,
  IoIosArrowDown,
  IoIosArrowForward,
  IoIosArrowUp,
  IoMdClose,
} from "react-icons/io";
import DataScienceImg from "../../assets/Continuing-professional-development-1170x630.jpg";

const ModuleContent = () => {
  const [myVideo, setMyVideo] = useState({
    title: "Python for Data Science",
    url: "https://youtu.be/AgyTHzjBS-c",
    isPlay: false,
    index: 1,
  });

  const [myReactVideo, setMyReactVideo] = useState({
    title: "React Basics for web Development",
    url:
      "https://skillbox-input.s3.ap-south-1.amazonaws.com/02.+Welcome+To+Your+Nanodegree.mp4",
    isPlay: false,
    index: 1,
  });

  let { cid } = useParams();
  let { c2id } = useParams();
  let { c3id } = useParams();
  let { c4id } = useParams();

  const [userCourseTitle, setUserCourseTitle] = useState(cid);
  const [userModuleTitle, setUserModuleTitle] = useState(c2id);
  const [mainCourseTitle, setMainCourseTitle] = useState(c3id);

  useEffect(() => {
    
    setUserCourseTitle(cid);
    setUserModuleTitle(c2id);
    setModuleHandle({
      isView: true,
      title: cid,
    });

    if (cid == "React Basics for web Development") {
      let i = 0;
      DUMMY_REACT[c3id].modules.map((item, index) => {
        if (item.title == cid) {
          i = index + 1;
        }
      });

      let myMain = DUMMY_REACT[c3id].modules.filter((p) => p.title == cid);
      let myUrl = myMain[0].lectures[parseInt(c4id, 10) - 1];
      handleOpenVideo(myUrl.url, parseInt(c4id, 10), myMain[0].title, 0);
    } else {
      let cIndex;
      DUMMY_COURSES[c3id].modules.filter((p, i) => {
        if (p.title) {
          cIndex = i;
        }
      });
      console.log(cIndex);
      let myMain = DUMMY_COURSES[c3id].modules.filter((p) => p.title == cid);

      let myUrl = myMain[0].lectures[parseInt(c4id, 10) - 1];
      handleOpenVideo(myUrl.url, parseInt(c4id, 10), myMain[0].title, cIndex);
    }
  }, []);

  const [handleIndex, setIndex] = useState(0);
  const [isLeftView, setIsLeftView] = useState(true);
  const [moduleHandle, setModuleHandle] = useState({
    isView: false,
    title: "Python for Data Science",
  });

  const [moduleReactHandle, setModuleReactHandle] = useState({
    isView: true,
    title: "React Basics for web Development",
  });

  const [userModule, setUserModule] = useState({
    title: "Data Science Foundations",
    isStarted: true,
    percentageComplete: 40,
    modules: [
      {
        title: "Python for Data Science",
        isStarted: true,
        description:
          "This course will helps us to learn the Python Programming language used for Data Science. We start with python basics learn keywords,syntax and writing usable code of this language.",
        percentageComplete: 40,
        lectures: [
          {
            url: "https://youtu.be/AgyTHzjBS-c",
          },
          {
            url: "https://youtu.be/qcSz4ef9UHA",
          },
          {
            url:
              "https://youtu.be/pCj0Jr-ktp4?list=RDCMUCBwmMxybNva6P_5VmxjzwqA",
          },
          {
            url: "https://youtu.be/jtn-hRJjl68",
          },
          {
            url: "https://youtu.be/AgyTHzjBS-c",
          },
          {
            url: "https://youtu.be/jtn-hRJjl68",
          },
        ],
      },
      {
        title: "Statistics",
        isStarted: false,
        description:
          "This course will helps us to learn the Python Programming language used for Data Science. We start with python basics learn keywords,syntax and writing usable code of this language.",
        percentageComplete: 0,
        lectures: [
          {
            url: "https://youtu.be/jtn-hRJjl68",
          },
          {
            url: "https://youtu.be/qcSz4ef9UHA",
          },
          {
            url:
              "https://youtu.be/uULy2rc6YDc?list=RDCMUCBwmMxybNva6P_5VmxjzwqA",
          },
          {
            url: "https://youtu.be/AgyTHzjBS-c",
          },

          {
            url: "https://youtu.be/qcSz4ef9UHA",
          },

          {
            url: "https://youtu.be/AgyTHzjBS-c",
          },
        ],
      },
      {
        title: "SQL Programming",
        isStarted: true,
        description:
          "This course will helps us to learn the Python Programming language used for Data Science. We start with python basics learn keywords,syntax and writing usable code of this language.",
        percentageComplete: 38,
        lectures: [
          {
            url: "https://youtu.be/AgyTHzjBS-c",
          },
          {
            url: "https://youtu.be/qcSz4ef9UHA",
          },
          {
            url: "https://youtu.be/AgyTHzjBS-c",
          },
          {
            url: "https://youtu.be/qcSz4ef9UHA",
          },
          {
            url:
              "https://youtu.be/uULy2rc6YDc?list=RDCMUCBwmMxybNva6P_5VmxjzwqA",
          },
          {
            url: "https://youtu.be/AgyTHzjBS-c",
          },
        ],
      },
      {
        title: "Machine Learning Fundamentals",
        isStarted: true,
        description:
          "This course will helps us to learn the Python Programming language used for Data Science. We start with python basics learn keywords,syntax and writing usable code of this language.",
        percentageComplete: 76,
        lectures: [
          {
            url: "https://youtu.be/jtn-hRJjl68",
          },
          {
            url: "https://youtu.be/AgyTHzjBS-c",
          },
          {
            url: "https://youtu.be/qcSz4ef9UHA",
          },
          {
            url:
              "https://youtu.be/ZB-7eq4txjA?list=RDCMUCBwmMxybNva6P_5VmxjzwqA",
          },
          {
            url: "https://youtu.be/qcSz4ef9UHA",
          },
          {
            url:
              "https://youtu.be/pCj0Jr-ktp4?list=RDCMUCBwmMxybNva6P_5VmxjzwqA",
          },
        ],
      },
      {
        title: "Deep Learning Fundamentals ",
        isStarted: false,
        description:
          "This course will helps us to learn the Python Programming language used for Data Science. We start with python basics learn keywords,syntax and writing usable code of this language.",
        percentageComplete: 0,
        lectures: [
          {
            url: "https://youtu.be/jtn-hRJjl68",
          },
          {
            url: "https://youtu.be/qcSz4ef9UHA",
          },
          {
            url:
              "https://youtu.be/pCj0Jr-ktp4?list=RDCMUCBwmMxybNva6P_5VmxjzwqA",
          },
          {
            url: "https://youtu.be/qcSz4ef9UHA",
          },
          {
            url:
              "https://youtu.be/uULy2rc6YDc?list=RDCMUCBwmMxybNva6P_5VmxjzwqA",
          },
          {
            url: "https://youtu.be/qcSz4ef9UHA",
          },
        ],
      },
      {
        title: "Exploratory Data Analysis",
        isStarted: false,
        description:
          "This course will helps us to learn the Python Programming language used for Data Science. We start with python basics learn keywords,syntax and writing usable code of this language.",
        percentageComplete: 10,
        lectures: [
          {
            url: "https://youtu.be/jtn-hRJjl68",
          },
          {
            url: "https://youtu.be/qcSz4ef9UHA",
          },
          {
            url:
              "https://youtu.be/pCj0Jr-ktp4?list=RDCMUCBwmMxybNva6P_5VmxjzwqA",
          },
          {
            url: "https://youtu.be/qcSz4ef9UHA",
          },
          {
            url:
              "https://youtu.be/uULy2rc6YDc?list=RDCMUCBwmMxybNva6P_5VmxjzwqA",
          },
          {
            url: "https://youtu.be/qcSz4ef9UHA",
          },
        ],
      },
    ],
  });

  const DUMMY_COURSES = [
    {
      title: "Data Science Foundations",
      isStarted: true,
      percentageComplete: 40,
      modules: [
        {
          title: "Python for Data Science",
          isStarted: true,
          description:
            "This course will helps us to learn the Python Programming language used for Data Science. We start with python basics learn keywords,syntax and writing usable code of this language.",
          percentageComplete: 40,
          lectures: [
            {
              url:
              "https://d1pqq5c8djeagm.cloudfront.net/02.+Welcome+To+Your+Nanodegree.mp4",
            },
            {
              url:
                "https://d1pqq5c8djeagm.cloudfront.net/02.+Welcome+To+Your+Nanodegree.mp4",
            },
            {
              url:
              "https://d1pqq5c8djeagm.cloudfront.net/04.+Meet+the+Careers+Team.mp4",
            },
            {
              url:
                "https://d1pqq5c8djeagm.cloudfront.net/02.+Welcome+To+Your+Nanodegree.mp4",
            },
            {
              url:
              "https://d1pqq5c8djeagm.cloudfront.net/04.+Meet+the+Careers+Team.mp4",
            },
            {
              url:
                "https://skillbox-input.s3.ap-south-1.amazonaws.com/02.+Welcome+To+Your+Nanodegree.mp4",
            },
          ],
        },
        {
          title: "Statistics",
          isStarted: false,
          description:
            "This course will helps us to learn the Python Programming language used for Data Science. We start with python basics learn keywords,syntax and writing usable code of this language.",
          percentageComplete: 0,
          lectures: [
            {
              url:
                "https://skillbox-input.s3.ap-south-1.amazonaws.com/02.+Welcome+To+Your+Nanodegree.mp4",
            },
            {
              url:
                "https://skillbox-input.s3.ap-south-1.amazonaws.com/02.+Welcome+To+Your+Nanodegree.mp4",
            },
            {
              url:
                "https://skillbox-input.s3.ap-south-1.amazonaws.com/02.+Welcome+To+Your+Nanodegree.mp4",
            },
            {
              url:
                "https://skillbox-input.s3.ap-south-1.amazonaws.com/02.+Welcome+To+Your+Nanodegree.mp4",
            },
            {
              url:
                "https://skillbox-input.s3.ap-south-1.amazonaws.com/02.+Welcome+To+Your+Nanodegree.mp4",
            },
            {
              url:
                "https://skillbox-input.s3.ap-south-1.amazonaws.com/02.+Welcome+To+Your+Nanodegree.mp4",
            },
          ],
        },
        {
          title: "SQL Programming",
          isStarted: true,
          description:
            "This course will helps us to learn the Python Programming language used for Data Science. We start with python basics learn keywords,syntax and writing usable code of this language.",
          percentageComplete: 38,
          lectures: [
            {
              url:
                "https://skillbox-input.s3.ap-south-1.amazonaws.com/02.+Welcome+To+Your+Nanodegree.mp4",
            },
            {
              url:
                "https://skillbox-input.s3.ap-south-1.amazonaws.com/02.+Welcome+To+Your+Nanodegree.mp4",
            },
            {
              url:
                "https://skillbox-input.s3.ap-south-1.amazonaws.com/02.+Welcome+To+Your+Nanodegree.mp4",
            },
            {
              url:
                "https://skillbox-input.s3.ap-south-1.amazonaws.com/02.+Welcome+To+Your+Nanodegree.mp4",
            },
            {
              url:
                "https://skillbox-input.s3.ap-south-1.amazonaws.com/02.+Welcome+To+Your+Nanodegree.mp4",
            },
            {
              url:
                "https://skillbox-input.s3.ap-south-1.amazonaws.com/02.+Welcome+To+Your+Nanodegree.mp4",
            },
          ],
        },
        {
          title: "Machine Learning Fundamentals",
          isStarted: true,
          description:
            "This course will helps us to learn the Python Programming language used for Data Science. We start with python basics learn keywords,syntax and writing usable code of this language.",
          percentageComplete: 76,
          lectures: [
            {
              url:
                "https://skillbox-input.s3.ap-south-1.amazonaws.com/02.+Welcome+To+Your+Nanodegree.mp4",
            },
            {
              url:
                "https://skillbox-input.s3.ap-south-1.amazonaws.com/02.+Welcome+To+Your+Nanodegree.mp4",
            },
            {
              url:
                "https://skillbox-input.s3.ap-south-1.amazonaws.com/02.+Welcome+To+Your+Nanodegree.mp4",
            },
            {
              url:
                "https://skillbox-input.s3.ap-south-1.amazonaws.com/02.+Welcome+To+Your+Nanodegree.mp4",
            },
            {
              url:
                "https://skillbox-input.s3.ap-south-1.amazonaws.com/02.+Welcome+To+Your+Nanodegree.mp4",
            },
            {
              url:
                "https://skillbox-input.s3.ap-south-1.amazonaws.com/02.+Welcome+To+Your+Nanodegree.mp4",
            },
          ],
        },
        {
          title: "Deep Learning Fundamentals ",
          isStarted: false,
          description:
            "This course will helps us to learn the Python Programming language used for Data Science. We start with python basics learn keywords,syntax and writing usable code of this language.",
          percentageComplete: 0,
          lectures: [
            {
              url:
                "https://skillbox-input.s3.ap-south-1.amazonaws.com/02.+Welcome+To+Your+Nanodegree.mp4",
            },
            {
              url:
                "https://skillbox-input.s3.ap-south-1.amazonaws.com/02.+Welcome+To+Your+Nanodegree.mp4",
            },
            {
              url:
                "https://skillbox-input.s3.ap-south-1.amazonaws.com/02.+Welcome+To+Your+Nanodegree.mp4",
            },
            {
              url:
                "https://skillbox-input.s3.ap-south-1.amazonaws.com/02.+Welcome+To+Your+Nanodegree.mp4",
            },
            {
              url:
                "https://skillbox-input.s3.ap-south-1.amazonaws.com/02.+Welcome+To+Your+Nanodegree.mp4",
            },
            {
              url:
                "https://skillbox-input.s3.ap-south-1.amazonaws.com/02.+Welcome+To+Your+Nanodegree.mp4",
            },
          ],
        },
        {
          title: "Exploratory Data Analysis",
          isStarted: false,
          description:
            "This course will helps us to learn the Python Programming language used for Data Science. We start with python basics learn keywords,syntax and writing usable code of this language.",
          percentageComplete: 10,
          lectures: [
            {
              url:
                "https://skillbox-input.s3.ap-south-1.amazonaws.com/02.+Welcome+To+Your+Nanodegree.mp4",
            },
            {
              url:
                "https://skillbox-input.s3.ap-south-1.amazonaws.com/02.+Welcome+To+Your+Nanodegree.mp4",
            },
            {
              url:
                "https://skillbox-input.s3.ap-south-1.amazonaws.com/02.+Welcome+To+Your+Nanodegree.mp4",
            },
            {
              url:
                "https://skillbox-input.s3.ap-south-1.amazonaws.com/02.+Welcome+To+Your+Nanodegree.mp4",
            },
            {
              url:
                "https://skillbox-input.s3.ap-south-1.amazonaws.com/02.+Welcome+To+Your+Nanodegree.mp4",
            },
            {
              url:
                "https://skillbox-input.s3.ap-south-1.amazonaws.com/02.+Welcome+To+Your+Nanodegree.mp4",
            },
          ],
        },
      ],
    },
    {
      title: "Machine Learning",
      isStarted: false,
      percentageComplete: 0,
      modules: [
        {
          title: "Supervised Learning",
          isStarted: false,
          description:
            "This course will helps us to learn the Python Programming language used for Data Science. We start with python basics learn keywords,syntax and writing usable code of this language.",
          percentageComplete: 10,
          lectures: [
            {
              url:
                "https://skillbox-input.s3.ap-south-1.amazonaws.com/02.+Welcome+To+Your+Nanodegree.mp4",
            },
            {
              url:
                "https://skillbox-input.s3.ap-south-1.amazonaws.com/02.+Welcome+To+Your+Nanodegree.mp4",
            },
            {
              url:
                "https://skillbox-input.s3.ap-south-1.amazonaws.com/02.+Welcome+To+Your+Nanodegree.mp4",
            },
            {
              url:
                "https://skillbox-input.s3.ap-south-1.amazonaws.com/02.+Welcome+To+Your+Nanodegree.mp4",
            },
            {
              url:
                "https://skillbox-input.s3.ap-south-1.amazonaws.com/02.+Welcome+To+Your+Nanodegree.mp4",
            },
            {
              url:
                "https://skillbox-input.s3.ap-south-1.amazonaws.com/02.+Welcome+To+Your+Nanodegree.mp4",
            },
          ],
        },
        {
          title: "Unsupervised Learning",
          isStarted: false,
          description:
            "This course will helps us to learn the Python Programming language used for Data Science. We start with python basics learn keywords,syntax and writing usable code of this language.",
          percentageComplete: 10,
          lectures: [
            {
              url:
                "https://skillbox-input.s3.ap-south-1.amazonaws.com/02.+Welcome+To+Your+Nanodegree.mp4",
            },
            {
              url:
                "https://skillbox-input.s3.ap-south-1.amazonaws.com/02.+Welcome+To+Your+Nanodegree.mp4",
            },
            {
              url:
                "https://skillbox-input.s3.ap-south-1.amazonaws.com/02.+Welcome+To+Your+Nanodegree.mp4",
            },
            {
              url:
                "https://skillbox-input.s3.ap-south-1.amazonaws.com/02.+Welcome+To+Your+Nanodegree.mp4",
            },
            {
              url:
                "https://skillbox-input.s3.ap-south-1.amazonaws.com/02.+Welcome+To+Your+Nanodegree.mp4",
            },
            {
              url:
                "https://skillbox-input.s3.ap-south-1.amazonaws.com/02.+Welcome+To+Your+Nanodegree.mp4",
            },
          ],
        },
        {
          title: "Time Series Forecasting",
          isStarted: false,
          description:
            "This course will helps us to learn the Python Programming language used for Data Science. We start with python basics learn keywords,syntax and writing usable code of this language.",
          percentageComplete: 10,
          lectures: [
            {
              url:
                "https://skillbox-input.s3.ap-south-1.amazonaws.com/02.+Welcome+To+Your+Nanodegree.mp4",
            },
            {
              url:
                "https://skillbox-input.s3.ap-south-1.amazonaws.com/02.+Welcome+To+Your+Nanodegree.mp4",
            },
            {
              url:
                "https://skillbox-input.s3.ap-south-1.amazonaws.com/02.+Welcome+To+Your+Nanodegree.mp4",
            },
            {
              url:
                "https://skillbox-input.s3.ap-south-1.amazonaws.com/02.+Welcome+To+Your+Nanodegree.mp4",
            },
            {
              url:
                "https://skillbox-input.s3.ap-south-1.amazonaws.com/02.+Welcome+To+Your+Nanodegree.mp4",
            },
            {
              url:
                "https://skillbox-input.s3.ap-south-1.amazonaws.com/02.+Welcome+To+Your+Nanodegree.mp4",
            },
          ],
        },
        {
          title: "Model Selection and Tuning",
          isStarted: false,
          description:
            "This course will helps us to learn the Python Programming language used for Data Science. We start with python basics learn keywords,syntax and writing usable code of this language.",
          percentageComplete: 10,
          lectures: [
            {
              url:
                "https://skillbox-input.s3.ap-south-1.amazonaws.com/02.+Welcome+To+Your+Nanodegree.mp4",
            },
            {
              url:
                "https://skillbox-input.s3.ap-south-1.amazonaws.com/02.+Welcome+To+Your+Nanodegree.mp4",
            },
            {
              url:
                "https://skillbox-input.s3.ap-south-1.amazonaws.com/02.+Welcome+To+Your+Nanodegree.mp4",
            },
            {
              url:
                "https://skillbox-input.s3.ap-south-1.amazonaws.com/02.+Welcome+To+Your+Nanodegree.mp4",
            },
            {
              url:
                "https://skillbox-input.s3.ap-south-1.amazonaws.com/02.+Welcome+To+Your+Nanodegree.mp4",
            },
            {
              url:
                "https://skillbox-input.s3.ap-south-1.amazonaws.com/02.+Welcome+To+Your+Nanodegree.mp4",
            },
          ],
        },
        {
          title: "Model Deployment",
          isStarted: false,
          description:
            "This course will helps us to learn the Python Programming language used for Data Science. We start with python basics learn keywords,syntax and writing usable code of this language.",
          percentageComplete: 10,
          lectures: [
            {
              url:
                "https://skillbox-input.s3.ap-south-1.amazonaws.com/02.+Welcome+To+Your+Nanodegree.mp4",
            },
            {
              url:
                "https://skillbox-input.s3.ap-south-1.amazonaws.com/02.+Welcome+To+Your+Nanodegree.mp4",
            },
            {
              url:
                "https://skillbox-input.s3.ap-south-1.amazonaws.com/02.+Welcome+To+Your+Nanodegree.mp4",
            },
            {
              url:
                "https://skillbox-input.s3.ap-south-1.amazonaws.com/02.+Welcome+To+Your+Nanodegree.mp4",
            },
            {
              url:
                "https://skillbox-input.s3.ap-south-1.amazonaws.com/02.+Welcome+To+Your+Nanodegree.mp4",
            },
            {
              url:
                "https://skillbox-input.s3.ap-south-1.amazonaws.com/02.+Welcome+To+Your+Nanodegree.mp4",
            },
          ],
        },
      ],
    },
    {
      title: "Bussiness Intelligence",
      isStarted: true,
      percentageComplete: 10,
      modules: [
        {
          title: "Business Intelligence Tool Tableau",
          isStarted: false,
          description:
            "This course will helps us to learn the Python Programming language used for Data Science. We start with python basics learn keywords,syntax and writing usable code of this language.",
          percentageComplete: 10,
          lectures: [
            {
              url:
                "https://skillbox-input.s3.ap-south-1.amazonaws.com/02.+Welcome+To+Your+Nanodegree.mp4",
            },
            {
              url:
                "https://skillbox-input.s3.ap-south-1.amazonaws.com/02.+Welcome+To+Your+Nanodegree.mp4",
            },
            {
              url:
                "https://skillbox-input.s3.ap-south-1.amazonaws.com/02.+Welcome+To+Your+Nanodegree.mp4",
            },
            {
              url:
                "https://skillbox-input.s3.ap-south-1.amazonaws.com/02.+Welcome+To+Your+Nanodegree.mp4",
            },
            {
              url:
                "https://skillbox-input.s3.ap-south-1.amazonaws.com/02.+Welcome+To+Your+Nanodegree.mp4",
            },
            {
              url:
                "https://skillbox-input.s3.ap-south-1.amazonaws.com/02.+Welcome+To+Your+Nanodegree.mp4",
            },
          ],
        },
        {
          title: "Data Analysis in Excel",
          isStarted: false,
          description:
            "This course will helps us to learn the Python Programming language used for Data Science. We start with python basics learn keywords,syntax and writing usable code of this language.",
          percentageComplete: 10,
          lectures: [
            {
              url:
                "https://skillbox-input.s3.ap-south-1.amazonaws.com/02.+Welcome+To+Your+Nanodegree.mp4",
            },
            {
              url:
                "https://skillbox-input.s3.ap-south-1.amazonaws.com/02.+Welcome+To+Your+Nanodegree.mp4",
            },
            {
              url:
                "https://skillbox-input.s3.ap-south-1.amazonaws.com/02.+Welcome+To+Your+Nanodegree.mp4",
            },
            {
              url:
                "https://skillbox-input.s3.ap-south-1.amazonaws.com/02.+Welcome+To+Your+Nanodegree.mp4",
            },
            {
              url:
                "https://skillbox-input.s3.ap-south-1.amazonaws.com/02.+Welcome+To+Your+Nanodegree.mp4",
            },
            {
              url:
                "https://skillbox-input.s3.ap-south-1.amazonaws.com/02.+Welcome+To+Your+Nanodegree.mp4",
            },
          ],
        },
        {
          title: "Bussiness Case Studies",
          isStarted: false,
          description:
            "This course will helps us to learn the Python Programming language used for Data Science. We start with python basics learn keywords,syntax and writing usable code of this language.",
          percentageComplete: 10,
          lectures: [
            {
              url:
                "https://skillbox-input.s3.ap-south-1.amazonaws.com/02.+Welcome+To+Your+Nanodegree.mp4",
            },
            {
              url:
                "https://skillbox-input.s3.ap-south-1.amazonaws.com/02.+Welcome+To+Your+Nanodegree.mp4",
            },
            {
              url:
                "https://skillbox-input.s3.ap-south-1.amazonaws.com/02.+Welcome+To+Your+Nanodegree.mp4",
            },
            {
              url:
                "https://skillbox-input.s3.ap-south-1.amazonaws.com/02.+Welcome+To+Your+Nanodegree.mp4",
            },
            {
              url:
                "https://skillbox-input.s3.ap-south-1.amazonaws.com/02.+Welcome+To+Your+Nanodegree.mp4",
            },

            {
              url: "https://youtu.be/qcSz4ef9UHA",
            },
          ],
        },

        {
          title: "BFSI ML Applications",
          isStarted: false,
          description:
            "This course will helps us to learn the Python Programming language used for Data Science. We start with python basics learn keywords,syntax and writing usable code of this language.",
          percentageComplete: 10,
          lectures: [
            {
              url:
                "https://skillbox-input.s3.ap-south-1.amazonaws.com/02.+Welcome+To+Your+Nanodegree.mp4",
            },
            {
              url:
                "https://skillbox-input.s3.ap-south-1.amazonaws.com/02.+Welcome+To+Your+Nanodegree.mp4",
            },
            {
              url:
                "https://skillbox-input.s3.ap-south-1.amazonaws.com/02.+Welcome+To+Your+Nanodegree.mp4",
            },
            {
              url:
                "https://skillbox-input.s3.ap-south-1.amazonaws.com/02.+Welcome+To+Your+Nanodegree.mp4",
            },
            {
              url:
                "https://skillbox-input.s3.ap-south-1.amazonaws.com/02.+Welcome+To+Your+Nanodegree.mp4",
            },
            {
              url:
                "https://skillbox-input.s3.ap-south-1.amazonaws.com/02.+Welcome+To+Your+Nanodegree.mp4",
            },
          ],
        },
      ],
    },
    {
      title: "Artificial Intelligence",
      isStarted: false,
      percentageComplete: 0,
      modules: [
        {
          title: "Neutral Networks and Deep Learning",
          isStarted: false,
          description:
            "This course will helps us to learn the Python Programming language used for Data Science. We start with python basics learn keywords,syntax and writing usable code of this language.",
          percentageComplete: 10,
          lectures: [
            {
              url:
                "https://d2hkendb91rgug.cloudfront.net/artificial-intelligence/neutral-networks-and-deep-learning/NN_1.mp4",
            },
            {
              url:
                "https://d2hkendb91rgug.cloudfront.net/artificial-intelligence/neutral-networks-and-deep-learning/NN_2.mp4",
            },
            {
              url:
                "https://d2hkendb91rgug.cloudfront.net/artificial-intelligence/neutral-networks-and-deep-learning/NN_3.mp4",
            },
            {
              url:
                "https://d2hkendb91rgug.cloudfront.net/artificial-intelligence/neutral-networks-and-deep-learning/NN_4.mp4",
            },
            {
              url:
                "https://d2hkendb91rgug.cloudfront.net/artificial-intelligence/neutral-networks-and-deep-learning/NN_5.mp4",
            },
            {
              url:
                "https://d2hkendb91rgug.cloudfront.net/artificial-intelligence/neutral-networks-and-deep-learning/NN_6.mp4",
            },
            {
              url:
                "https://d2hkendb91rgug.cloudfront.net/artificial-intelligence/neutral-networks-and-deep-learning/NN_6_1.mp4",
            },
            {
              url:
                "https://d2hkendb91rgug.cloudfront.net/artificial-intelligence/neutral-networks-and-deep-learning/NN_7.mp4",
            },
            {
              url:
                "https://d2hkendb91rgug.cloudfront.net/artificial-intelligence/neutral-networks-and-deep-learning/NN_8.mp4",
            },
            {
              url:
                "https://d2hkendb91rgug.cloudfront.net/artificial-intelligence/neutral-networks-and-deep-learning/NN_9.mp4",
            },
            {
              url:
                "https://d2hkendb91rgug.cloudfront.net/artificial-intelligence/neutral-networks-and-deep-learning/NN_10.mp4",
            },
            {
              url:
                "https://d2hkendb91rgug.cloudfront.net/artificial-intelligence/neutral-networks-and-deep-learning/NN_11.mp4",
            },
            {
              url:
                "https://d2hkendb91rgug.cloudfront.net/artificial-intelligence/neutral-networks-and-deep-learning/NN_12.mp4",
            },
            {
              url:
                "https://d2hkendb91rgug.cloudfront.net/artificial-intelligence/neutral-networks-and-deep-learning/NN_13.mp4",
            },
            {
              url:
                "https://d2hkendb91rgug.cloudfront.net/artificial-intelligence/neutral-networks-and-deep-learning/NN_14.mp4",
            },
            {
              url:
                "https://d2hkendb91rgug.cloudfront.net/artificial-intelligence/neutral-networks-and-deep-learning/NN_15.mp4",
            },
          ],
        },
        {
          title: "Natural Language Processing",
          isStarted: false,
          description:
            "This course will helps us to learn the Python Programming language used for Data Science. We start with python basics learn keywords,syntax and writing usable code of this language.",
          percentageComplete: 10,
          lectures: [
            {
              url:
                "https://d2hkendb91rgug.cloudfront.net/artificial-intelligence/natural-language-processing/Pradip_1_Spam+Detector_TFIDF.mp4",
            },
            {
              url:
                "https://d2hkendb91rgug.cloudfront.net/artificial-intelligence/natural-language-processing/Pradip_2_WordVector_Gensim.mp4",
            },
            {
              url:
                "https://d2hkendb91rgug.cloudfront.net/artificial-intelligence/natural-language-processing/Pradip_3_NER_InformationExtraction_Part_1.mp4",
            },
            {
              url:
                "https://d2hkendb91rgug.cloudfront.net/artificial-intelligence/natural-language-processing/Pradip_4_Topic_Modeling.mp4",
            },
            {
              url:
                "https://d2hkendb91rgug.cloudfront.net/artificial-intelligence/natural-language-processing/Pradip_5_NER_InformationExtraction_Part_2.mp4",
            },
            {
              url:
                "https://d2hkendb91rgug.cloudfront.net/artificial-intelligence/natural-language-processing/Pradip_6_Transfer_Learning_Transformers.mp4",
            },
            {
              url:
                "https://d2hkendb91rgug.cloudfront.net/artificial-intelligence/natural-language-processing/Pradip_7_Sentence_Embeddings.mp4",
            },
            {
              url:
                "https://d2hkendb91rgug.cloudfront.net/artificial-intelligence/natural-language-processing/Pradip_8_Pretrained_Model.mp4",
            },
          ],
        },
      ],
    },
  ];

  const DUMMY_REACT = [
    {
      title: "React",
      isStarted: false,
      percentageComplete: 0,
      modules: [
        {
          title: "React Basics for web Development",
          isStarted: false,
          description:
            "This course will helps us to learn web development. We start with React basics learn keywords,syntax and writing usable code of this language.",
          percentageComplete: 0,
          lectures: [
            {
              url:
                "https://d2rl5xjwpscq89.cloudfront.net/Class1.mp4",
            },
            {
              url:
                "https://d2rl5xjwpscq89.cloudfront.net/Class2.mp4",
            },
            {
              url:
                "https://d2rl5xjwpscq89.cloudfront.net/Class3.mp4",
            },
          ],
        },
      ],
    },
  ];

  const moduleClickHandler = (value) => {
    if (cid != "React Basics for web Development") {
      setModuleHandle({
        isView: true,
        title: value,
      });
    } else {
      setModuleReactHandle({
        isView: true,
        title: value,
      });
    }
  };

  const moduleClickBackHandler = (value) => {
    if (cid != "React Basics for web Development") {
      setModuleHandle({
        isView: false,
        title: value,
      });
    } else {
      setModuleReactHandle({
        isView: false,
        title: value,
      });
    }
  };

  const handleOpenVideo = (url, index, title, cIndex) => {
    setIndex(cIndex);
    if (cid != "React Basics for web Development") {
      setMyVideo({
        title: title,
        url: url,
        isPlay: true,
        index: index,
      });
    } else {
      setMyReactVideo({
        title: title,
        url: url,
        isPlay: true,
        index: index,
      });
    }
  };

  const handleBackVideoClick = (mIndex, cIndex) => {
    let myTitle, myUrl;

    if (cid != "React Basics for web Development") {
      if (mIndex == 1) {
        cIndex = cIndex - 1;
        mIndex = 6;
        myTitle = DUMMY_COURSES[c3id].modules[cIndex].title;
        myUrl = DUMMY_COURSES[c3id].modules[cIndex].lectures[mIndex - 1].url;
        handleOpenVideo(myUrl, mIndex, myTitle, cIndex);
      } else {
        mIndex = mIndex - 1;
        myTitle = DUMMY_COURSES[c3id].modules[cIndex].title;
        myUrl = DUMMY_COURSES[c3id].modules[cIndex].lectures[mIndex - 1].url;
        handleOpenVideo(myUrl, mIndex, myTitle, cIndex);
      }
    } else {
    }
  };

  const handleForwardVideoClick = (mIndex, cIndex) => {
    let myTitle, myUrl;

    if (cid == "React Basics for web Development") {
      console.log("Yash");
      mIndex = mIndex + 1;
      let myTitle = DUMMY_REACT[c3id].modules[cIndex].title;
      let myUrl = DUMMY_REACT[c3id].modules[cIndex].lectures[mIndex - 1].url;
      handleOpenVideo(myUrl, mIndex, myTitle, cIndex);
    } else {
      if (mIndex == DUMMY_COURSES[c3id].modules[cIndex].lectures.length) {
        cIndex = cIndex + 1;
        mIndex = 1;
        myTitle = DUMMY_COURSES[c3id].modules[cIndex].title;
        myUrl = DUMMY_COURSES[c3id].modules[cIndex].lectures[mIndex - 1].url;
        handleOpenVideo(myUrl, mIndex, myTitle, cIndex);
      } else {
        mIndex = mIndex + 1;
        myTitle = DUMMY_COURSES[c3id].modules[cIndex].title;
        myUrl = DUMMY_COURSES[c3id].modules[cIndex].lectures[mIndex - 1].url;
        handleOpenVideo(myUrl, mIndex, myTitle, cIndex);
      }
    }
  };

  const userMainModuleCollapse = () => {
    setUserModuleTitle(false);
  };

  const userMainModuleDropdown = (value) => {
    setUserModuleTitle(value);
  };

  return (
    <div className="module-content-page">
      <div className="module-content-flex">
        {isLeftView ? (
          <div className="module-flex1">
            <div className="module-flex1-banner">
              <h2 style={{ flex: 1, marginLeft: "0.7rem", fontSize: "23px" }}>
                Module Overview
              </h2>
              <IoMdClose
                onClick={() => setIsLeftView(!isLeftView)}
                style={{
                  alignSelf: "center",
                  fontSize: "22px",
                  fontWeight: "bold",
                  textAlign: "center",
                  marginRight: "0.3rem",
                }}
              />
            </div>
            <Scrollbars
              autoHide
              autoHideTimeout={1000}
              autoHideDuration={310}
              autoHeightMin={300}
              autoHeightMax={800}
              thumbMinSize={30}
              universal={true}
              style={{ width: `100%`, height: `82%` }}
            >
              {cid != "React Basics for web Development"
                ? DUMMY_COURSES.map((mainTitle, mainIndex) => {
                    if (mainTitle.title == userModuleTitle) {
                      return (
                        <React.Fragment>
                          <div className="main-course-heading">
                            <h2>{mainTitle.title}</h2>
                            <div
                              style={{
                                alignSelf: "center",
                                textAlign: "center",
                                fontSize: "20px",
                                paddingRight: "0.5rem",
                              }}
                              onClick={() => userMainModuleCollapse()}
                            >
                              <IoIosArrowUp />
                            </div>
                          </div>
                          {mainTitle.modules.map((item, i) => {
                            if (
                              item.title == moduleHandle.title &&
                              moduleHandle.isView
                            ) {
                              return (
                                <div className="module-flex1-div">
                                  <div className="flex1-div-content1">
                                    {item.percentageComplete == 100 ? (
                                      <TiTickOutline
                                        style={{
                                          color: "green",
                                          fontSize: "20px",
                                          marginRight: "0.7rem",
                                          marginLeft: "0.8rem",
                                        }}
                                      />
                                    ) : (
                                      <BsBook
                                        style={{
                                          color: "green",
                                          fontSize: "20px",
                                          marginRight: "0.7rem",
                                          marginLeft: "0.8rem",
                                        }}
                                      />
                                    )}
                                  </div>
                                  <div className="flex2-div-content">
                                    {" "}
                                    <h2
                                      onClick={() =>
                                        moduleClickBackHandler(item.title)
                                      }
                                    >
                                      {item.title}{" "}
                                    </h2>
                                    <div className="progress-bar-module">
                                      {item.isStarted ? (
                                        <React.Fragment>
                                          <ProgressBar
                                            completed={item.percentageComplete}
                                            height={7}
                                            bgColor="#1d3557"
                                            isLabelVisible={false}
                                          />

                                          <span
                                            style={{
                                              color: "#rgb(31, 31, 71)",
                                              marginTop: "0.2rem",
                                            }}
                                          >
                                            {item.percentageComplete}% Complete
                                          </span>
                                        </React.Fragment>
                                      ) : (
                                        <span>NOT STARTED</span>
                                      )}
                                    </div>
                                    <div className="flex1-video-grid">
                                      <ul>
                                        {item.lectures.map((p, index) => {
                                          return (
                                            <li
                                              onClick={() =>
                                                handleOpenVideo(
                                                  p.url,
                                                  index + 1,
                                                  item.title,
                                                  i
                                                )
                                              }
                                            >
                                              <FiPlayCircle
                                                style={{
                                                  fontSize: "18px",
                                                  marginRight: "0.4rem",
                                                }}
                                              />
                                              {item.title} Lecture {index + 1}
                                            </li>
                                          );
                                        })}
                                      </ul>
                                    </div>
                                  </div>
                                  <div className="flex3-div-content">
                                    <IoIosArrowUp
                                      onClick={() =>
                                        moduleClickBackHandler(item.title)
                                      }
                                    />
                                  </div>
                                </div>
                              );
                            } else {
                              return (
                                <div
                                  onClick={() => moduleClickHandler(item.title)}
                                  className="module-flex1-div"
                                >
                                  <div className="flex1-div-content">
                                    {item.percentageComplete == 100 ? (
                                      <TiTickOutline
                                        style={{
                                          color: "green",
                                          fontSize: "20px",
                                          marginRight: "0.7rem",
                                          marginLeft: "0.8rem",
                                        }}
                                      />
                                    ) : (
                                      <BsBook
                                        style={{
                                          color: "green",
                                          fontSize: "20px",
                                          marginRight: "0.7rem",
                                          marginLeft: "0.8rem",
                                        }}
                                      />
                                    )}
                                  </div>
                                  <div className="flex2-div-content">
                                    {" "}
                                    <h2>{item.title} </h2>
                                    <div className="progress-bar-module">
                                      {item.isStarted ? (
                                        <React.Fragment>
                                          <ProgressBar
                                            completed={item.percentageComplete}
                                            height={7}
                                            bgColor="#1d3557"
                                            isLabelVisible={false}
                                          />

                                          <span
                                            style={{
                                              color: "#rgb(31, 31, 71)",
                                              marginTop: "0.2rem",
                                            }}
                                          >
                                            {item.percentageComplete}% Complete
                                          </span>
                                        </React.Fragment>
                                      ) : (
                                        <span>NOT STARTED</span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="flex3-div-content">
                                    <IoIosArrowDown />
                                  </div>
                                </div>
                              );
                            }
                          })}
                        </React.Fragment>
                      );
                    } else {
                      return (
                        <div className="main-course-heading">
                          <h2>{mainTitle.title}</h2>
                          <div
                            onClick={() =>
                              userMainModuleDropdown(mainTitle.title)
                            }
                            style={{
                              alignSelf: "center",
                              textAlign: "center",
                              fontSize: "20px",
                              paddingRight: "0.5rem",
                            }}
                          >
                            <IoIosArrowDown />
                          </div>
                        </div>
                      );
                    }
                  })
                : DUMMY_REACT.map((mainTitle, mainIndex) => {
                    if (mainTitle.title == userModuleTitle) {
                      return (
                        <React.Fragment>
                          <div className="main-course-heading">
                            <h2>{mainTitle.title}</h2>
                            <div
                              style={{
                                alignSelf: "center",
                                textAlign: "center",
                                fontSize: "20px",
                                paddingRight: "0.5rem",
                              }}
                              onClick={() => userMainModuleCollapse()}
                            >
                              <IoIosArrowUp />
                            </div>
                          </div>
                          {mainTitle.modules.map((item, i) => {
                            if (
                              item.title == moduleReactHandle.title &&
                              moduleReactHandle.isView
                            ) {
                              return (
                                <div className="module-flex1-div">
                                  <div className="flex1-div-content1">
                                    {item.percentageComplete == 100 ? (
                                      <TiTickOutline
                                        style={{
                                          color: "green",
                                          fontSize: "20px",
                                          marginRight: "0.7rem",
                                          marginLeft: "0.8rem",
                                        }}
                                      />
                                    ) : (
                                      <BsBook
                                        style={{
                                          color: "green",
                                          fontSize: "20px",
                                          marginRight: "0.7rem",
                                          marginLeft: "0.8rem",
                                        }}
                                      />
                                    )}
                                  </div>
                                  <div className="flex2-div-content">
                                    {" "}
                                    <h2
                                      onClick={() =>
                                        moduleClickBackHandler(item.title)
                                      }
                                    >
                                      {item.title}{" "}
                                    </h2>
                                    <div className="progress-bar-module">
                                      {item.isStarted ? (
                                        <React.Fragment>
                                          <ProgressBar
                                            completed={item.percentageComplete}
                                            height={7}
                                            bgColor="#1d3557"
                                            isLabelVisible={false}
                                          />

                                          <span
                                            style={{
                                              color: "#rgb(31, 31, 71)",
                                              marginTop: "0.2rem",
                                            }}
                                          >
                                            {item.percentageComplete}% Complete
                                          </span>
                                        </React.Fragment>
                                      ) : (
                                        <span>NOT STARTED</span>
                                      )}
                                    </div>
                                    <div className="flex1-video-grid">
                                      <ul>
                                        {item.lectures.map((p, index) => {
                                          return (
                                            <li
                                              onClick={() =>
                                                handleOpenVideo(
                                                  p.url,
                                                  index + 1,
                                                  item.title,
                                                  i
                                                )
                                              }
                                            >
                                              <FiPlayCircle
                                                style={{
                                                  fontSize: "18px",
                                                  marginRight: "0.4rem",
                                                }}
                                              />
                                              {item.title} Lecture {index + 1}
                                            </li>
                                          );
                                        })}
                                      </ul>
                                    </div>
                                  </div>
                                  <div className="flex3-div-content">
                                    <IoIosArrowUp
                                      onClick={() =>
                                        moduleClickBackHandler(item.title)
                                      }
                                    />
                                  </div>
                                </div>
                              );
                            } else {
                              return (
                                <div
                                  onClick={() => moduleClickHandler(item.title)}
                                  className="module-flex1-div"
                                >
                                  <div className="flex1-div-content">
                                    {item.percentageComplete == 100 ? (
                                      <TiTickOutline
                                        style={{
                                          color: "green",
                                          fontSize: "20px",
                                          marginRight: "0.7rem",
                                          marginLeft: "0.8rem",
                                        }}
                                      />
                                    ) : (
                                      <BsBook
                                        style={{
                                          color: "green",
                                          fontSize: "20px",
                                          marginRight: "0.7rem",
                                          marginLeft: "0.8rem",
                                        }}
                                      />
                                    )}
                                  </div>
                                  <div className="flex2-div-content">
                                    {" "}
                                    <h2>{item.title} </h2>
                                    <div className="progress-bar-module">
                                      {item.isStarted ? (
                                        <React.Fragment>
                                          <ProgressBar
                                            completed={item.percentageComplete}
                                            height={7}
                                            bgColor="#1d3557"
                                            isLabelVisible={false}
                                          />

                                          <span
                                            style={{
                                              color: "#rgb(31, 31, 71)",
                                              marginTop: "0.2rem",
                                            }}
                                          >
                                            {item.percentageComplete}% Complete
                                          </span>
                                        </React.Fragment>
                                      ) : (
                                        <span>NOT STARTED</span>
                                      )}
                                    </div>
                                  </div>
                                  <div className="flex3-div-content">
                                    <IoIosArrowDown />
                                  </div>
                                </div>
                              );
                            }
                          })}
                        </React.Fragment>
                      );
                    } else {
                      return (
                        <div className="main-course-heading">
                          <h2>{mainTitle.title}</h2>
                          <div
                            onClick={() =>
                              userMainModuleDropdown(mainTitle.title)
                            }
                            style={{
                              alignSelf: "center",
                              textAlign: "center",
                              fontSize: "20px",
                              paddingRight: "0.5rem",
                            }}
                          >
                            <IoIosArrowDown />
                          </div>
                        </div>
                      );
                    }
                  })}
            </Scrollbars>
          </div>
        ) : (
          <div
            className="module-flex11"
            onClick={() => setIsLeftView(!isLeftView)}
          >
            <div className="module-flex11-arrow">
              <p>Module Overview</p>
              <BsArrowRight style={{ alignSelf: "center" }} />
            </div>
          </div>
        )}

        {isLeftView && <hr style={{ background: "black", color: "black" }} />}

        {cid != "React Basics for web Development" ? (
          <div className="module-flex2" onMouseDown={(e)=>e.preventDefault()} onContextMenu={(e)=>e.preventDefault()}>
            <h2>
              {myVideo.title} Lecture {myVideo.index}
            </h2>

            <div className="video-player-div">
              {!(handleIndex == 0 && myVideo.index == 1) && (
                <div
                  onClick={() =>
                    handleBackVideoClick(myVideo.index, handleIndex)
                  }
                  className="video-player-control"
                >
                  <IoIosArrowBack />
                </div>
              )}

              <ReactPlayer
                
                posterimage={DataScienceImg}
                url={myVideo.url}
                thumbnail={DataScienceImg}
                // onEnded={handleVideoSubmit}
                width="100%"
                height="470px"
                loop={true}
                controls
                config={{ file: { 
                  attributes: {
                    controlsList: 'nodownload'  //<- this is the important bit
                  }
              }}}
                playing={myVideo.isPlay}
              ></ReactPlayer>
              {!(
                handleIndex == DUMMY_COURSES[c3id].modules.length - 1 &&
                myVideo.index ==
                  DUMMY_COURSES[c3id].modules[handleIndex].lectures.length
              ) && (
                <div
                  onClick={() =>
                    handleForwardVideoClick(myVideo.index, handleIndex)
                  }
                  className="video-player-control1"
                >
                  <IoIosArrowForward style={{ alignSelf: "center" }} />
                </div>
              )}
            </div>

            {/* <p>{userModule.description}</p> */}
          </div>
        ) : (
          <div className="module-flex2" onMouseDown={(e)=>e.preventDefault()} onContextMenu={(e)=>e.preventDefault()}>
            <h2>
              {myReactVideo.title} Lecture {myReactVideo.index}
            </h2>

            <div className="video-player-div">
              {!(handleIndex == 0 && myReactVideo.index == 1) && (
                <div
                  onClick={() =>
                    handleBackVideoClick(myReactVideo.index, handleIndex)
                  }
                  className="video-player-control"
                >
                  <IoIosArrowBack />
                </div>
              )}

              {/*

              <video
                controls
                poster={DataScienceImg}
                style={{ width: "100%", height: "470px" }}
                loop
                controlsList="settings"
                preload="auto"
                playing={myReactVideo.isPlay}
                src="https://youtu.be/qcSz4ef9UHA"
              ></video>

              */}

              <ReactPlayer
                poster={DataScienceImg}
                url={myReactVideo.url}
                thumbnail={DataScienceImg}
                // onEnded={handleVideoSubmit}
                width="100%"
                height="470px"
                loop={true}
                controls
                config={{ file: { 
                  attributes: {
                    controlsList: 'nodownload'  //<- this is the important bit
                  }
                }}}
                playing={myReactVideo.isPlay}
              ></ReactPlayer>
              {!(
                handleIndex == DUMMY_REACT[c3id].modules.length - 1 &&
                myReactVideo.index ==
                  DUMMY_REACT[c3id].modules[handleIndex].lectures.length
              ) && (
                <div className="video-player-control1">
                  <IoIosArrowForward style={{ alignSelf: "center" }} />
                </div>
              )}
            </div>

            {/* <p>{userModule.description}</p> */}
          </div>
        )}
      </div>
    </div>
  );
};

export default ModuleContent;
