import React from "react";
import PopUp from "../UiComponents/Popup/PopUp";
import "./ViewSection.css";

const ViewSection = ({ section, showPopup, setShowPopup }) => (
  <PopUp
    title={section.section_name}
    onClose={() => setShowPopup(false)}
    show={showPopup}
  >
    <div className="section_popup__content">
      <div className="section_popup__content-row">
        <div className="section_popup__content-row-title">
          <p>Section ID</p>
        </div>
        <div className="section_popup__content-row-value">
          <p>{section.section_id}</p>
        </div>
      </div>
      <div className="section_popup__content-row">
        <div className="section_popup__content-row-title">
          <p>Section Name</p>
        </div>
        <div className="section_popup__content-row-value">
          <p>{section.section_name}</p>
        </div>
      </div>
      <div className="section_popup__content-row">
        <div className="section_popup__content-row-title">
          <p>Section Description</p>
        </div>
        <div className="section_popup__content-row-value">
          <p>{section.section_description}</p>
        </div>
      </div>
      <div className="section_popup__content-row">
        <div className="section_popup__content-row-title">
          <p>Is Active</p>
        </div>
        <div className="section_popup__content-row-value">
          <p>{section.section_lock ? "No" : "Yes"}</p>
        </div>
      </div>
      <div className="section_popup__content-row">
        <div className="section_popup__content-row-title">
          <p>Section Order</p>
        </div>
        <div className="section_popup__content-row-value">
          <p>{section.section_order || "N/A"}</p>
        </div>
      </div>
      <div className="section_popup__content-row">
        <div className="section_popup__content-row-title">
          <p>Created On</p>
        </div>
        <div className="section_popup__content-row-value">
          <p>{section.section_create_timestamp}</p>
        </div>
      </div>
      <div className="section_popup__content-row">
        <div className="section_popup__content-row-title">
          <p>Last Updated</p>
        </div>
        <div className="section_popup__content-row-value">
          <p>{section.section_update_timestamp}</p>
        </div>
      </div>
    </div>
  </PopUp>
);

export default ViewSection;
