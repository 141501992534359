import React from "react";
import "../Common.css";
import CustomPopup from "../../../../../../Components/Popup/CustomPopup";
import Input from "../../../../../../../../../Components/Profile/Input/Input";

const View = ({
    show,
    setShow,
    assignmentData,
    courseName,
    sectionName,
    moduleName,
}) => {
    const handleClose = () => {
        setShow(false);
    };

    return (
        <CustomPopup
            title={assignmentData.title}
            subtitle={`<b>Course:</b> ${courseName} | <b>Pathway:</b> ${sectionName} <br/> <b>Module:</b> ${moduleName}`}
            show={show}
            onClose={handleClose}
        >
            <div className="sk-ad-asgnmt_popup-content">
                <div className="sk-ad-asgnmt_popup-content-row">
                    <label htmlFor="title" className="sk-ad-asgnmt_popup-content-row-label">
                        Title
                    </label>
                    <Input
                        width="100%"
                        name="title"
                        value={assignmentData.title}
                        isDisabled={true}
                    />
                </div>
                <div className="sk-ad-asgnmt_popup-content-row">
                    <label htmlFor="skills" className="sk-ad-asgnmt_popup-content-row-label">
                        Skills
                    </label>
                    <Input
                        width="100%"
                        name="skills"
                        value={assignmentData.skills}
                        isDisabled={true}
                    />
                </div>
                <div className="sk-ad-asgnmt_popup-content-row">
                    <label htmlFor="file" className="sk-ad-asgnmt_popup-content-row-label">
                        File
                    </label>
                    {assignmentData.file_location_s3_url && (
                        <>
                            {assignmentData.file_location_s3_url.startsWith("http://") ||
                                assignmentData.file_location_s3_url.startsWith("https://") ? (
                                <a href={assignmentData.file_location_s3_url} className="sk-ad-asgnmt_popup-content-row-file" download>
                                    Download File
                                </a>
                            ) : (
                                <a href={`${process.env.REACT_APP_BACKEND_URL
                                    }${assignmentData.file_location_s3_url}`} className="sk-ad-asgnmt_popup-content-row-file" download>
                                    Download File
                                </a>
                            )}
                        </>
                    )}
                </div>
                <div className="sk-ad-asgnmt_popup-content-row-sp">
                    <div className="sk-ad-asgnmt_popup-content-row">
                        <label htmlFor="due_days" className="sk-ad-asgnmt_popup-content-row-label">
                            Due Days
                        </label>
                        <Input
                            inpType="number"
                            width="95%"
                            name="due_days"
                            value={assignmentData.due_days}
                            isDisabled={true}
                        />
                    </div>
                    <div className="sk-ad-asgnmt_popup-content-row">
                        <label htmlFor="order" className="sk-ad-asgnmt_popup-content-row-label">
                            Order
                        </label>
                        <Input
                            inpType="number"
                            width="95%"
                            name="order"
                            value={assignmentData.order}
                            isDisabled={true}
                        />
                    </div>
                </div>
            </div>
        </CustomPopup>
    );
}

export default View;