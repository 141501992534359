import React from "react";
import "./UnderDevelopment.css";
import websiteMaintenance from "../../../../assets/website-maintenance.svg";

const UnderDevelopment = ({
    setSelectedOption,
}) => {

    const goToHome = () => {
        setSelectedOption({
            value: "skillbox",
            label: "Skillbox",
        });
    }

  return (
    <>
    <div className="profile-content-header-nav-menu-under-development" onClick={goToHome}>
        Home
    </div>
    <div className="under-development-page">
        <div className="under-development-container">
        <div className="construction-svg-container">
            <img
            src={websiteMaintenance}
            alt="website-maintenance"
            />
        </div>
        <p className="under-development-text">
            This feature is under development. Please check back after a while. We
            are working on lots of updates right now.
        </p>
        <button
            className="previous-version-button"
            onClick={() => (window.location.href = "/")}
        >
            Go to Previous Version
        </button>
        </div>
    </div>
    </>
  );
};

export default UnderDevelopment;
