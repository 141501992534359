import React from "react";
import "./Aptitude.css";
import NavApti from "../../Components/NavApti/NavApti";
import { useNavigate, useParams } from "react-router-dom";

const Aptitude = () => {
  const history = useNavigate();

  const aptitudeHandler = (item) => {
    history(`/aptitude/${item}`);
  };

  const testHandler = (item) => {
    history(`/aptitude/test/${item}`);
  };

  return (
    <div className="aptitude-page">
      <div className="aptitude-content">
        <h3>Welcome to Surge Aptitude</h3>
        <p>
          Here, you can read the aptitude questions and answers for your
          interview and entrance preparation.
        </p>
        <div className="app-grid-content">
          <div className="app-grid-div">
            <h3>General Aptitude & Reasoning</h3>
            <hr />
            <ul>
              <li onClick={() => aptitudeHandler("Arithmetic Aptitude")}>
                Arithmetic Aptitude
              </li>
              <li onClick={() => aptitudeHandler("Data Interpretation")}>
                Data Interpretation
              </li>
              <li
                onClick={() => aptitudeHandler("Verbal Ability and Reasoning")}
              >
                Verbal Ability and Reasoning
              </li>
              <li onClick={() => aptitudeHandler("Logical Reasoning")}>
                Logical Reasoning
              </li>
              <li onClick={() => aptitudeHandler("Non Verbal Reasoning")}>
                Non Verbal Reasoning
              </li>
              <li onClick={() => aptitudeHandler("Puzzles")}>Puzzles</li>
            </ul>
          </div>

          <div className="app-grid-div">
            <h3>GK & Interview Preparations</h3>
            <hr />
            <ul>
              <li onClick={() => aptitudeHandler("Current Affairs and GK")}>
                Current Affairs and GK
              </li>
              <li
                onClick={() =>
                  aptitudeHandler("General Science and Inventions")
                }
              >
                General Science and Inventions
              </li>

              <li onClick={() => aptitudeHandler("Interview Preparations")}>
                Interview Preparations
              </li>
            </ul>
          </div>

          <div className="app-grid-div">
            <h3>Online Test</h3>
            <hr />
            <ul>
              <li onClick={() => testHandler("Aptitude")}>Aptitude Test</li>
              <li onClick={() => testHandler("Verbal Ability")}>Verbal Ability Test</li>
              <li onClick={() => testHandler("Logical Reasoning")}>Logical Reasoning Test</li>
              <li onClick={() => testHandler("General Knowledge")}>General Knowledge Test</li>
              <li onClick={() => testHandler("Interview Preparations")}>Interview Preparation</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Aptitude;
