import React from "react";
import "./AdminQRGenerator.css";
import { useHttpClient } from "../../Components/Hooks/http-hook";

const AdminQRGenerator = () => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const handleQRCode = async () => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/auth/qr-generator`,
        "GET"
      );

      alert(responseData.message);
    } catch (err) {}
  };

  return (
    <div className="adminQR">
      <button onClick={() => handleQRCode()}>QR Code Generator</button>
    </div>
  );
};

export default AdminQRGenerator;
