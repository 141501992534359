export const additionalKeywords = [
    {
      id: 1,
      name: "Big Data",
    },
    {
      id: 2,
      name: "Artificial Intelligence",
    },
    {
      id: 3,
      name: "AWS",
    },
    {
      id: 4,
      name: "Data Mining",
    },
    {
      id: 5,
      name: "Data Visualization",
    },
    {
      id: 6,
      name: "Data Analytics",
    },
    {
      id: 7,
      name: "Data Science",
    },
    {
      id: 8,
      name: "Machine Learning",
    },
  ];