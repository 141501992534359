import React from 'react';
import "./AssignmentList.css";
import { useEffect, useState, useContext } from 'react';
import { ToastContainer, toast } from "react-toastify";
import Loading from "../../../../../../../Components/Loading";
import Button from "../../../../../../../Components/Profile/Button/Button";
import Add from "../Assignment/Actions/Add/Add";
import View from "../Assignment/Actions/View/View";
import Edit from "../Assignment/Actions/Edit/Edit";
import ViewSvg from '../../../../Components/Svgs/ViewSvg';
import EditSvg from '../../../../Components/Svgs/EditSvg';
import DeleteSvg from '../../../../Components/Svgs/DeleteSvg';
import { AdminContext } from "../../../../../../../context/admin-context";
import { useHttpClient } from "../../../../../../../Components/Hooks/http-hook";
import swal from 'sweetalert2';


const AssignmentList = ({
    setSelectedTab,
    selectedModuleData,
    moduleId,
    setSelectedAssignmentId,
    setSelectedAssignmentData
}) => {
    const admin = useContext(AdminContext);
    const { sendRequest, isLoading, error, clearError } = useHttpClient();
    const { sendRequest: sendRequest1 , isLoading: isLoading1, error: error1, clearError: clearError1 } = useHttpClient();
    const [showAddPopup, setShowAddPopup] = useState(false);
    const [showViewPopup, setShowViewPopup] = useState(false);
    const [showEditPopup, setShowEditPopup] = useState(false);
    const [selectedAssignment, setSelectedAssignment] = useState({});
    const [assignments, setAssignments] = useState([]);

    const goToAssignment = () => {
        setSelectedTab("assignment");
    };

    const handleSubmission = (id) => {
        setSelectedAssignmentId(id);
        setSelectedAssignmentData({
            ...assignments.find((assignment) => assignment.id === id),
            course_name: selectedModuleData.course_name,
            section_name: selectedModuleData.section_name,
            module_name: selectedModuleData.module_name,
            course_id: selectedModuleData.course_id,
        });
        setSelectedTab("submissions");
    };

    const viewAssignment = (data) => {
        setSelectedAssignment(data);
        setShowViewPopup(true);
    };

    const editAssignment = (data) => {
        setSelectedAssignment(data);
        setShowEditPopup(true);
    };

    const handleOnChange = async () => {
        await getAssignments();
    };

    const handleDelete = async (id) => {
        swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover this assignment!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it',
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const responseData = await sendRequest1(
                        `${process.env.REACT_APP_BACKEND_URL}/assignments/delete/${id}`,
                        "DELETE",
                        null,
                        {
                            "Refresh-Token": admin.refresh_token,
                            Authorization: admin.token,
                        }
                    );
                    if (responseData) {
                        toast.success("Assignment Deleted Successfully");
                        getAssignments();
                    }
                } catch (err) {
                    console.log(err);
                }
            }
        });
    };

    useEffect(() => {
        if (error1) {
            toast.error("Something went wrong, Please try again later");
            clearError1();
        }
    }, [error1, clearError1]);

    const getAssignments = async () => {
        try {
            const responseData = await sendRequest(
                `${process.env.REACT_APP_BACKEND_URL}/assignments/assignments/${moduleId}`,
                "GET",
                null,
                {
                    "Refresh-Token": admin.refresh_token,
                    Authorization: admin.token,
                }
            );
            if (responseData) {
                setAssignments(responseData.assignments);
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        getAssignments();
    }, [moduleId]);

    return (
        <div>
            <ToastContainer closeButton={false} />
            <div className="admin-skillbox-nav-header" onClick={goToAssignment}>
                Assignments
            </div>
            <Add
                show={showAddPopup}
                setShow={setShowAddPopup}
                moduleId={moduleId}
                courseName={selectedModuleData && selectedModuleData.course_name}
                sectionName={selectedModuleData && selectedModuleData.section_name}
                moduleName={selectedModuleData && selectedModuleData.module_name}
                onAdd={handleOnChange}
            />
            <View
                show={showViewPopup}
                setShow={setShowViewPopup}
                moduleId={moduleId}
                assignmentData={selectedAssignment}
                courseName={selectedModuleData && selectedModuleData.course_name}
                sectionName={selectedModuleData && selectedModuleData.section_name}
                moduleName={selectedModuleData && selectedModuleData.module_name}
            />
            <Edit
                show={showEditPopup}
                setShow={setShowEditPopup}
                moduleId={moduleId}
                assignmentData={selectedAssignment}
                courseName={selectedModuleData && selectedModuleData.course_name}
                sectionName={selectedModuleData && selectedModuleData.section_name}
                moduleName={selectedModuleData && selectedModuleData.module_name}
                onEdit={handleOnChange}
            />

            <div className="admin-skillbox-header">
                <div className="admin-skillbox-header-title">
                    {selectedModuleData && selectedModuleData.module_name}
                    <div className="admin-skillbox-header-subtitle">
                        <b>Course: </b> {selectedModuleData && selectedModuleData.course_name} |{" "}
                        <b>Pathway: </b> {selectedModuleData && selectedModuleData.section_name}
                    </div>
                </div>
                <div className="admin-skillbox-header-button">
                    <Button
                        text="Create New Assignment"
                        width="auto"
                        onClick={() => setShowAddPopup(true)}
                    />
                </div>
            </div>

            {(isLoading || error) && (
                <div className="ad-assgn-body">
                    <div className="ad-assgn-lt-section">
                        {isLoading && (
                            <Loading color="#004c73" customClass="ad-assgn-interview-loading" />
                        )}
                        {error && <div className="interview-questions-not-found-section">
                            Something went wrong, Please try again later
                        </div>
                        }
                    </div>
                </div>
            )}
            {!isLoading && assignments && assignments.length === 0 && (
                <div className="ad-assgn-body">
                    <div className="ad-assgn-lt-section">
                        <div className="interview-questions-not-found-section">
                            No Assignments Found
                        </div>
                    </div>
                </div>
            )}
            {assignments && assignments.length > 0 && (
                <div className="ad-assgn-body">
                    <div className="ad-assgn-lt-section">
                        <div className="ad-assgn-table">
                            <div className="ad-assgn-table-header">
                                <div className="ad-assgn-lt-table-header-row-1">
                                    Title
                                </div>
                                <div className="ad-assgn-lt-table-header-row-2">
                                    Due Days
                                </div>
                                <div className="ad-assgn-lt-table-header-row-3">
                                    Files
                                </div>
                                <div className="ad-assgn-lt-table-header-row-4">
                                    Submissions
                                </div>
                            </div>
                            <div className="ad-assgn-hr-line"></div>
                            <div className="ad-assgn-table-body">
                                {assignments.map((data) => (
                                    <div
                                        key={data.id}
                                        className="ad-assgn-table-row"
                                    >
                                        <div className="ad-assgn-lt-table-row-1">
                                            {data.title}
                                        </div>
                                        <div className="ad-assgn-lt-table-row-2">
                                            {data.due_days}
                                        </div>
                                        <div className="ad-assgn-lt-table-row-3">
                                            <span onClick={() => viewAssignment(data)}>
                                                <ViewSvg clname="ad-assgn-lt-table-icn-act" />
                                            </span>
                                            <span onClick={() => editAssignment(data)}>
                                                <EditSvg clname="ad-assgn-lt-table-icn-act" />
                                            </span>
                                            <span onClick={() => handleDelete(data.id)}>
                                                <DeleteSvg clname="ad-assgn-lt-table-icn-act" />
                                            </span>
                                        </div>
                                        <div className="ad-assgn-lt-table-row-4" onClick={() => handleSubmission(data.id)}>
                                            View
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AssignmentList;