import React, { useEffect } from "react";
import "./NavLegends.css";

const NavLegends = ({
  selectedSemesterId,
  setSelectedSemesterId,
  semesterLength,
  selectedData,
  setSelectedSemester,
}) => {
  useEffect(() => {
    if (selectedData && selectedSemesterId) {
      const semesterKey = `semester${selectedSemesterId}`;
      setSelectedSemester(selectedData[semesterKey]);
    }
  }, [selectedData, selectedSemesterId, setSelectedSemester]);

  const handleSelectedSemesterId = (id) => {
    setSelectedSemesterId(id);
    const semesterKey = `semester${id}`;
    setSelectedSemester(selectedData[semesterKey]);
  };

  const handleNextSemester = () => {
    const nextId =
      selectedSemesterId + 1 > semesterLength ? 1 : selectedSemesterId + 1;
    handleSelectedSemesterId(nextId);
  };

  const handlePreviousSemester = () => {
    const prevId =
      selectedSemesterId - 1 < 1 ? semesterLength : selectedSemesterId - 1;
    handleSelectedSemesterId(prevId);
  };

  const renderSquare = (index) => (
    <div
      key={index}
      className={`course-qgt-nav-legends-square ${
        selectedSemesterId === index + 1
          ? "course-qgt-nav-legends-square-active"
          : ""
      }`}
      onClick={() => handleSelectedSemesterId(index + 1)}
    >
      {index + 1}
    </div>
  );

  return (
    <div className="course-qgt-nav-legends-container">
      <div className="course-qgt-nav-legends-title">
        Select Semester
      </div>
      <div className="course-qgt-nav-legends">
        <div className="course-qgt-nav-legends-section-1">
          <div className="course-qgt-nav-legends-svgs">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={handlePreviousSemester}
            >
              <path
                d="M12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12C2 6.48 6.48 2 12 2ZM12 20C16.42 20 20 16.42 20 12C20 7.58 16.42 4 12 4C7.58 4 4 7.58 4 12C4 16.42 7.58 20 12 20ZM12 11H16V13H12V16L8 12L12 8V11Z"
                fill="#898989"
              />
            </svg>
          </div>
          <div className="course-qgt-nav-legends-squares">
            {Array.from(Array(semesterLength).keys()).map(renderSquare)}
          </div>
        </div>
        <div className="course-qgt-nav-legends-section-2">
          <div className="course-qgt-nav-legends-svgs">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={handleNextSemester}
            >
              <path
                d="M12 11V8L16 12L12 16V13H8V11H12ZM12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12C2 6.48 6.48 2 12 2ZM12 20C16.42 20 20 16.42 20 12C20 7.58 16.42 4 12 4C7.58 4 4 7.58 4 12C4 16.42 7.58 20 12 20Z"
                fill="#898989"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavLegends;
