import React, { useState } from "react";
// import "./CustomSelect.css";

const ActionVerbsSelect = ({ options, onSelect }) => {
  const [isActive, setIsActive] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [tempList, setTempList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const filteredOptions = options.filter((option) =>
    option.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  //   const toggleOption = (option) => {
  //     const index = selectedOptions.findIndex((o) => o.id === option.id);
  //     if (index !== -1) {
  //       const updatedOptions = [...selectedOptions];
  //       updatedOptions.splice(index, 1);
  //       setSelectedOptions(updatedOptions);
  //     } else {
  //       setSelectedOptions([...selectedOptions, option]);
  //     }
  //   };

  const toggleOption = (option) => {
    const index = selectedOptions.findIndex((o) => o.id === option.id);
    let updatedOptions = [];
    if (index !== -1) {
      updatedOptions = [...selectedOptions];
      updatedOptions.splice(index, 1);
    } else {
      updatedOptions = [...selectedOptions, option];
    }
    setSelectedOptions(updatedOptions);
    onSelect(updatedOptions.map((o) => o.id));
  };


  return (
    <div className={`new-custom-select ${isActive ? "active" : ""}`}>
      <button
        className="new-select-button"
        role="combobox"
        aria-labelledby="select button"
        aria-haspopup="listbox"
        aria-expanded={isActive}
        onClick={() => setIsActive(!isActive)}
      >
        <span className="selected-value">
          {selectedOptions.length > 0
            ? selectedOptions.length <= 2
              ? selectedOptions.map((option) => option.name).join(", ")
              : selectedOptions
                  .slice(0, 2)
                  .map((option) => option.name)
                  .join(", ") + ", ..."
            : "Select options"}
        </span>
        {/* Dropdown Arrow */}
        <span className="arrow">
          <svg
            width="12"
            height="8"
            viewBox="0 0 12 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 1.5L6 6.5L11 1.5"
              stroke="#777777"
              strokeWidth="1.66667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
      </button>

      <ul className="select-from-dropdown" role="listbox">
        <li className="search-box">
          <input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="search-input"
          />
        </li>
        <div className="options-container">
          {filteredOptions.map((option) => (
            <li
              key={option.id}
              role="option"
              onClick={() => toggleOption(option)}
            >
              <input
                type="checkbox"
                style={{ width: "fit-content", marginLeft: "4px" }}
                id={option.id}
                checked={selectedOptions.some((o) => o.id === option.id)}
                readOnly
              />
              {/* <label htmlFor={option.id} onClick={() => toggleOption(option)}> */}
                {option.name}
              {/* </label> */}
            </li>
          ))}
        </div>
      </ul>

      {/* {console.log("Selected Options: " + JSON.stringify(selectedOptions, null, 2))} */}
      {/* {selectedOptions.length > 0 && (
        <button onClick={handleSelect}>Apply Selection</button>
      )} */}
    </div>
  );
};

export default ActionVerbsSelect;
