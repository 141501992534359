import React, { useEffect, useState, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./AdminUserManagement.css";
import { AuthContext } from "../../context/auth-context";
import { AdminContext } from "../../context/admin-context";
import Button from "../../Components/Input/Button";
import Input from "../../Components/Input/Input";
import LoadingSpinner from "../../Components/Input/LoadingSpinner";
import Scrollbars from "react-custom-scrollbars-2";

import {
  VALIDATOR_EMAIL,
  VALIDATOR_MINLENGTH,
  VALIDATOR_REQUIRE,
  VALIDATOR_MOBILE,
} from "../../Components/Input/validators";
import useForm from "../../Components/Hooks/formhooks";
import { useHttpClient } from "../../Components/Hooks/http-hook";
import ErrorModal from "../../Components/Input/ErrorModel";

const AdminUserManagement = () => {
  const auth = useContext(AuthContext);
  const admin = useContext(AdminContext);
  const history = useNavigate();
  const [isLoginMode, setIsLoginMode] = useState(true);
  const [isAddUserMode, setIsAddUserMode] = useState(false);
  const [isEditUserMode, setIsEditUserMode] = useState(false);
  const [registeredUsers, setRegisteredUsers] = useState([]);

  const [formState, inputHandler, setFormData] = useForm({
    inputs: {
      name: {
        value: "",
        isValid: false,
      },
      mobile: {
        value: "",
        isValid: false,
      },
      email: {
        value: "",
        isValid: false,
      },
      role: {
        value: "",
        isValid: false,
      },
    },
    isValid: false,
  });

  const userRoles = [
    { value: "student", label: "Student" },
    { value: "moderator", label: "Moderator" },
    { value: "instructor", label: "Instructor" },
    { value: "mentor", label: "Mentor" },
  ];

  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const submitHandler = async (event) => {
    event.preventDefault();

    try {
      // const formData = new FormData();

      // formData.append("email", formState.inputs.email.value);
      // formData.append("password", formState.inputs.password.value);

      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/auth/user`,
        "POST",
        JSON.stringify({
          name: formState.inputs.name.value,
          mobile: formState.inputs.mobile.value,
          email: formState.inputs.email.value,
          role: formState.inputs.role.value,
        }),
        {
          "Content-Type": "application/json",
          "Authorization": `${admin.token}`,
          "Refresh-Token": `${admin.refresh_token}`,
        }
      );

      console.log(admin.token, "yash aa rha hai bhai");

      alert(responseData.message);
    } catch (err) {}
  };

  const getRegisteredUsers = async () => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/auth/users/registered`,
        "GET",
        null,
        {
          "Content-Type": "application/json",
          "Authorization": admin.token,
          "Refresh-Token": admin.refresh_token,
        }
      );
      if (responseData.ok) {
        setRegisteredUsers(responseData.users);
      } else {
        alert(responseData.error);
      }
    } catch (err) {}
  };

  useEffect(() => {
    if (!admin.token) {
      history("/admin");
    } else {
      getRegisteredUsers();
    }
  }, [admin.token, history]);

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      {isLoading && <LoadingSpinner asOverlay />}
      {!isLoading && (
        <div>
          {isAddUserMode ? (
            <div className="users">
              <div className="users-heading">
                <h3>Add User</h3>
                <a
                  className="users-back"
                  onClick={() => {
                    setIsAddUserMode(false);
                    getRegisteredUsers();
                  }}
                  href="#"
                >
                  <button>Back to users list</button>
                </a>
              </div>
              <div className="adduser-container">
              <form onSubmit={submitHandler}>
                <div className="form-class1">
                  <Input
                    type="text"
                    placeholder="Name"
                    id="name"
                    validators={[VALIDATOR_REQUIRE()]}
                    value={formState.inputs.name.value}
                    isValid={formState.inputs.name.isValid}
                    onInput={inputHandler}
                    element="input"
                  />
                </div>

                <div className="form-class1">
                  <Input
                    type="tel"
                    placeholder="Mobile"
                    id="mobile"
                    errorText="Valid mobile format is +911234567890"
                    validators={[VALIDATOR_MOBILE()]}
                    value={formState.inputs.mobile.value}
                    isValid={formState.inputs.mobile.isValid}
                    onInput={inputHandler}
                    element="input"
                  />
                </div>
                <div className="form-class1">
                  <Input
                    type="email"
                    //label="Email"
                    placeholder="Email"
                    id="email"
                    value={formState.inputs.email.value}
                    isValid={formState.inputs.email.isValid}
                    errorText="Enter a valid Email"
                    validators={[VALIDATOR_EMAIL()]}
                    element="input"
                    onInput={inputHandler}
                  />
                </div>

                <div className="form-class1">
                  <Input
                    className="select-input"
                    placeholder="Select Role"
                    errorText="Enter a valid Role"
                    id="role"
                    defaultOption="Select Role"
                    validators={[VALIDATOR_REQUIRE()]}
                    element="dropdown"
                    onInput={inputHandler}
                    value={formState.inputs.role.value}
                    isValid={formState.inputs.role.isValid}
                    options={userRoles}
                  />
                </div>

                <Button type="submit" disabled={!formState.isValid}>
                  Create User
                </Button>
              </form>
              </div>
            </div>
          ) : (
            <div className="users">
              <div className="users-heading">
                <h3>Registered Users</h3>
                <a
                  onClick={() => {
                    setIsAddUserMode(true);
                  }}
                  href="#"
                >
                  <button>Add User</button>
                </a>
              </div>
              <div className="users-table">
                <Scrollbars
                  autoHide
                  autoHideTimeout={2000}
                  autoHideDuration={310}
                  autoHeightMin={300}
                  autoHeightMax={800}
                  thumbMinSize={30}
                  universal={true}
                  style={{
                  width: `100%`,
                  height: "25rem",
                  background: "white",
                  borderRadius: "6px",
                  }}
                >
                <table className="module-table" cellSpacing={"0px"}>
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Mobile</th>
                    </tr>
                  </thead>
                    <tbody>
                  {registeredUsers &&
                    registeredUsers.map((p, i) => {
                      return (
                        <tr key={p.id}>
                          <td>{i + 1}</td>
                          <td>{p.name}</td>
                          <td>{p.email}</td>
                          <td>{p.mobile}</td>
                        </tr>
                      );
                    })}
                    </tbody>
                </table>
                </Scrollbars>
              </div>
            </div>
          )}

          <br />
          <br />
          <br />
        </div>
      )}
    </React.Fragment>
  );
};

export default AdminUserManagement;
