import React, { useState, useEffect, useContext } from "react";
import "./OtherAchievements.css";
import Button from "../../../../Components/Profile/Button/Button";
import { AchievementType } from "../Data/SelectData";
import Popup from "../../../../Components/Profile/Popup/PopUp";
import { useHttpClient } from "../../../../Components/Hooks/http-hook";
import { AuthContext } from "../../../../context/auth-context";
import Loading from "../../../../Components/Loading";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Delete from "../Popups/Delete";


const OtherAchievements = () => {
  const auth = useContext(AuthContext);
  const { sendRequest, error: requestError } = useHttpClient();
  const [achievementsList, setAchievementsList] = useState([]);
  const [showAchievementsPopup, setShowAchievementsPopup] = useState(false);
  const [AchievementComponent, setAchievementComponent] = useState(
    AchievementType[0]
  );
  const [isAchievementComponent, setIsAchievementComponent] = useState(false);
  const [selectedAchievementType, setSelectedAchievementType] = useState(null);
  const [pageLoading, setPageLoading] = useState(true);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [deleteAchievementId, setDeleteAchievementId] = useState(null);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [editAchievementData, setEditAchievementData] = useState(null);

  const showDeleteConfirmation = (id) => {
    setDeleteAchievementId(id);
    setShowDeletePopup(true);
  };

  const closeDeleteConfirmation = () => {
    setDeleteAchievementId(null);
    setShowDeletePopup(false);
  };

  const showEditAchievementPopup = (data) => {
    setEditAchievementData(data);
    setShowEditPopup(true);
    setSelectedAchievementType(
      AchievementType.find((item) => item.value === data.type)
    );
    setIsAchievementComponent(true);
    setAchievementComponent(
      AchievementType.find((item) => item.value === data.type)
    );
  };

  const getLabelOfAchievementType = (value) => {
    let label = "";
    AchievementType.forEach((item) => {
      if (item.value === value) {
        label = item.label;
      }
    });
    return label;
  };

  const deleteAchievement = async (id) => {
    const responseData = await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/profile/achievement/${id}`,
      "DELETE",
      null,
      {
        "Content-Type": "application/json",
        "Refresh-Token": auth.refresh_token,
        Authorization: auth.token,
      }
    );
    if (responseData && responseData.message) {
      toast(responseData.message, {
        autoClose: 3000,
        type: toast.TYPE.SUCCESS,
      });
      setShowDeletePopup(false);
      const updatedAchievementList = achievementsList.filter(
        (item) => item.id !== id
      );
      setAchievementsList(updatedAchievementList);
    }
  };

  const changeTimestampToDaysAgo = (timestamp) => {
    const date = moment(timestamp);
    const currentDate = moment();
    const daysAgo = currentDate.diff(date, "days");
    if (daysAgo === 0) {
      return "Updated today";
    } else if (daysAgo > 30) {
      const monthsAgo = currentDate.diff(date, "months");
      return `${monthsAgo} months ago`;
    } else {
      return `${daysAgo} days ago`;
    }
  };

  const addNewAchievement = async (data) => {
    const responseData = await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/profile/achievement`,
      "POST",
      JSON.stringify(data),
      {
        "Content-Type": "application/json",
        "Refresh-Token": auth.refresh_token,
        Authorization: auth.token,
      }
    );
    if (responseData && responseData.data) {
      toast(responseData.message, {
        autoClose: 3000,
        type: toast.TYPE.SUCCESS,
      });
      setShowAchievementsPopup(false);
      const updatedAchievementList = [
        ...achievementsList,
        {
          ...responseData.data,
          created_at: "Just now",
        },
      ];
      setAchievementsList(updatedAchievementList);
    }
  };

  const editAchievement = async (data) => {
    const responseData = await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/profile/achievement/${data.id}`,
      "PUT",
      JSON.stringify(data),
      {
        "Content-Type": "application/json",
        "Refresh-Token": auth.refresh_token,
        Authorization: auth.token,
      }
    );
    if (responseData && responseData.data) {
      toast(responseData.message, {
        autoClose: 3000,
        type: toast.TYPE.SUCCESS,
      });
      setShowEditPopup(false);
      const updatedAchievementList = achievementsList.map((item) =>
        item.id === data.id
          ? { ...responseData.data, created_at: "Just Now" }
          : item
      );
      setAchievementsList(updatedAchievementList);
      setAchievementComponent(null);
    }
  };

  useEffect(() => {
    const getAchievementsDetails = async () => {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/profile/achievements`,
        "GET",
        null,
        {
          "Content-Type": "application/json",
          "Refresh-Token": auth.refresh_token,
          Authorization: auth.token,
        }
      );
      if (responseData && responseData.data) {
        setAchievementsList(
          responseData.data.map((item) => ({
            ...item,
            created_at: changeTimestampToDaysAgo(item.update_timestamp),
          }))
        );
      }
      setPageLoading(false);
    };
    getAchievementsDetails();
  }, []);

  useEffect(() => {
    if (requestError) {
      toast(requestError, {
        autoClose: 3000,
        type: toast.TYPE.ERROR,
      });
      setPageLoading(false);
    }
  }, [requestError]);

  return (
    <div className="profile-achievements-page">
      <div className="profile-achievements-header">
        <div className="profile-achievements-header-title">
          My Profile - Other Achievements
        </div>
        {achievementsList && achievementsList.length > 0 && (
          <Button
            text="Add New"
            width="100px"
            onClick={() => setShowAchievementsPopup(true)}
          />
        )}
      </div>
      {pageLoading ? (
        <div className="profile-loader-container">
          <div className="profile-loader-sub-container">
            <Loading
              color="#14144e"
              customClass="profile-loader-container-for-loader"
            />
          </div>
        </div>
      ) : (
        <>
          {showAchievementsPopup && (
            <>
              {isAchievementComponent && AchievementComponent ? (
                <AchievementComponent.component
                  type={AchievementComponent.value}
                  title={AchievementComponent.label}
                  onClose={() => {
                    setSelectedAchievementType(null);
                    setAchievementComponent(null);
                    setIsAchievementComponent(false);
                    setShowAchievementsPopup(false);
                  }}
                  HandleSubmitFunction={addNewAchievement}
                  show={showAchievementsPopup}
                  isEdit={false}
                />
              ) : (
                <Popup
                  title={
                    isAchievementComponent && selectedAchievementType
                      ? selectedAchievementType.label
                      : "Add Education Details"
                  }
                  onClose={() => {
                    setShowAchievementsPopup(false);
                    setIsAchievementComponent(false);
                    setSelectedAchievementType(null);
                  }}
                  show={showAchievementsPopup}
                >
                  <div className="profile-education-popup-education-type-container">
                    <div className="profile-education-popup-education-type-title">
                      Select Type
                    </div>
                    {AchievementType.map((achievement, i) => (
                      <div
                        className={
                          selectedAchievementType == achievement
                            ? "profile-education-popup-type-item profile-education-popup-type-item-selected "
                            : "profile-education-popup-type-item"
                        }
                        key={i}
                        onClick={() => {
                          setSelectedAchievementType(achievement);
                        }}
                      >
                        {achievement.label}
                      </div>
                    ))}
                    <Button
                      text="SELECT"
                      width="250px"
                      onClick={() => {
                        if (selectedAchievementType) {
                          setIsAchievementComponent(true);
                          setAchievementComponent(selectedAchievementType);
                        }
                      }}
                      disabled={!selectedAchievementType}
                    />
                  </div>
                </Popup>
              )}
            </>
          )}
          <ToastContainer closeButton={false} />

          {achievementsList && achievementsList.length > 0 ? (
            <div className="profile-achievements-section-1">
              {achievementsList.map((achievements) => (
                <div
                  className="profile-achievements-card"
                  key={achievements.id}
                >
                  <div className="profile-achievements-card-header">
                    <div className="profile-achievements-card-header-section-1">
                      <div className="profile-achievements-card-header-title">
                        {achievements.title}
                      </div>
                      <div className="profile-achievements-card-header-subtitle">
                        {achievements.type && getLabelOfAchievementType(achievements.type)}
                      </div>
                    </div>
                    <div className="profile-achievements-card-header-section-2">
                      <div className="profile-achievements-card-body-title-1">
                        {achievements.created_at}
                      </div>
                    </div>
                  </div>
                  <div className="profile-achievements-card-body">
                    <div className="profile-achievements-card-body-container-1">
                      <div className="profile-achievements-card-body-container-1-section-1">
                        <div className="profile-achievements-card-body-title">
                          Date
                        </div>
                        <div className="profile-achievements-card-body-data-value">
                          {achievements.date}
                        </div>
                      </div>
                      <div className="profile-achievements-card-body-container-1-section-2">
                        <div className="profile-achievements-card-body-title">
                          {achievements.type === "publication"
                            ? "Number of Authors"
                            : "Location"}
                        </div>
                        <div className="profile-achievements-card-body-data-value">
                          {achievements.type === "publication"
                            ? achievements.no_of_authors
                            : achievements.location}
                        </div>
                      </div>
                      <div className="profile-achievements-card-body-container-1-section-3">
                        <div className="profile-achievements-card-body-title">
                          {achievements.type === "seminar" ||
                          achievements.type === "workshop"
                            ? "Learnings"
                            : "Key Skills"}
                        </div>
                        <div className="profile-achievements-card-body-data-value">
                          {achievements.skills}
                        </div>
                      </div>
                    </div>
                    <div className="profile-achievements-card-body-container-2">
                      <div className="profile-achievements-card-body-title">
                        Description
                      </div>
                      <div className="profile-achievements-card-body-data-value">
                        {achievements.description}
                      </div>
                    </div>
                  </div>
                  <div className="profile-achievements-card-footer">
                    <div className="profile-achievements-card-edit">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.41421 15.8895L16.5563 5.74738L15.1421 4.33316L5 14.4753V15.8895H6.41421ZM7.24264 17.8895H3V13.6468L14.435 2.21184C14.8256 1.82132 15.4587 1.82132 15.8492 2.21184L18.6777 5.04027C19.0682 5.43079 19.0682 6.06395 18.6777 6.45447L7.24264 17.8895ZM3 19.8895H21V21.8895H3V19.8895Z"
                          fill="#004C73"
                        />
                      </svg>
                      <div
                        className="profile-achievements-card-edit-title"
                        onClick={() => showEditAchievementPopup(achievements)}
                      >
                        Edit Details
                      </div>
                    </div>
                    <div
                      className="profile-achievements-card-delete"
                      onClick={() => showDeleteConfirmation(achievements.id)}
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7 4V2H17V4H22V6H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V6H2V4H7ZM6 6V20H18V6H6ZM9 9H11V17H9V9ZM13 9H15V17H13V9Z"
                          fill="#004C73"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="profile-achievements-section-2">
              <div className="profile-achievements-add-new">
                <div className="profile-achievements-add-new-title">
                  Add Other Achievements Details{" "}
                </div>
                <div className="profile-achievements-add-new-subtitle">
                  Your publications, position of responsibilities, workshop,
                  seminars attented etc.
                </div>
                <Button
                  text="Add New"
                  width="100px"
                  onClick={() => setShowAchievementsPopup(true)}
                />
              </div>
            </div>
          )}
        </>
      )}
      {isAchievementComponent && AchievementComponent && (
        <AchievementComponent.component
          type={AchievementComponent.value}
          title={AchievementComponent.label}
          onClose={() => {
            setIsAchievementComponent(false);
            setSelectedAchievementType(null);
            setAchievementComponent(null);
            setShowEditPopup(false);
          }}
          HandleSubmitFunction={editAchievement}
          show={showEditPopup}
          editAchievementData={editAchievementData}
          isEdit={true}
        />
      )}
      <Delete
        show={showDeletePopup}
        text="Are you sure you want to delete this achievement?"
        onClose={closeDeleteConfirmation}
        onDelete={() => deleteAchievement(deleteAchievementId)}
      />
    </div>
  );
};

export default OtherAchievements;
