import React from "react";

const BlockQuoteSvg = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_339_1980)">
        <path
          d="M16.0922 12.9671C16.5854 12.9654 17.0698 12.8369 17.4989 12.5938C16.8948 14.1574 15.8929 15.5363 14.5922 16.5938C14.5178 16.6551 14.4562 16.7304 14.4109 16.8155C14.3656 16.9006 14.3375 16.9938 14.3282 17.0897C14.3189 17.1857 14.3286 17.2825 14.3568 17.3747C14.3849 17.4669 14.431 17.5527 14.4922 17.6271C14.5535 17.7015 14.6289 17.7631 14.714 17.8084C14.7991 17.8537 14.8922 17.8818 14.9882 17.8911C15.0841 17.9004 15.181 17.8907 15.2732 17.8626C15.3654 17.8344 15.4512 17.7884 15.5256 17.7271C18.1922 15.5604 19.3789 12.5604 19.3789 10.7004C19.3743 10.0428 19.1783 9.40069 18.8149 8.85256C18.4514 8.30443 17.9363 7.87398 17.3322 7.61377C16.9413 7.42441 16.5132 7.32422 16.0789 7.32044C15.6966 7.30186 15.3145 7.36111 14.9558 7.49461C14.5971 7.62811 14.2692 7.83308 13.992 8.09708C13.7149 8.36107 13.4943 8.67861 13.3435 9.03043C13.1927 9.38225 13.115 9.76102 13.115 10.1438C13.115 10.5265 13.1927 10.9053 13.3435 11.2571C13.4943 11.6089 13.7149 11.9265 13.992 12.1905C14.2692 12.4545 14.5971 12.6594 14.9558 12.7929C15.3145 12.9264 15.6966 12.9857 16.0789 12.9671H16.0922Z"
          fill="#444444"
        />
        <path
          d="M8.66646 12.967C9.15969 12.9659 9.64427 12.8373 10.0731 12.5936C9.46926 14.1557 8.46996 15.534 7.17312 16.5936C7.09195 16.6522 7.02362 16.7267 6.97232 16.8126C6.92102 16.8985 6.88784 16.994 6.87483 17.0932C6.86181 17.1924 6.86924 17.2932 6.89665 17.3894C6.92406 17.4857 6.97088 17.5753 7.03421 17.6527C7.09755 17.7302 7.17607 17.7939 7.26494 17.8399C7.35381 17.8858 7.45114 17.9131 7.55096 17.9201C7.65078 17.9271 7.75096 17.9135 7.84535 17.8803C7.93973 17.8471 8.02632 17.7949 8.09979 17.727C10.7665 15.5603 11.9531 12.5603 11.9531 10.7003C11.95 10.0501 11.7597 9.41454 11.405 8.86956C11.0504 8.32457 10.5463 7.8932 9.95312 7.62698C9.55232 7.43207 9.11214 7.33172 8.66646 7.33364C8.28415 7.31506 7.90205 7.37432 7.54333 7.50782C7.18461 7.64132 6.85674 7.84628 6.57959 8.11028C6.30245 8.37428 6.08181 8.69181 5.93105 9.04363C5.78029 9.39545 5.70256 9.77422 5.70256 10.157C5.70256 10.5397 5.78029 10.9185 5.93105 11.2703C6.08181 11.6221 6.30245 11.9397 6.57959 12.2037C6.85674 12.4677 7.18461 12.6726 7.54333 12.8061C7.90205 12.9396 8.28415 12.9989 8.66646 12.9803V12.967Z"
          fill="#444444"
        />
      </g>
      <defs>
        <clipPath id="clip0_339_1980">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="matrix(-1 0 0 -1 24 24)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BlockQuoteSvg;
