import React from "react";
import Assignments from "../Layout/Assignments/Assignments";

export const AssignmentsSideBarData = [
  {
    title: "Assignments",
    icon: (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5 21C4.45 21 3.97933 20.8043 3.588 20.413C3.19667 20.0217 3.00067 19.5507 3 19V5C3 4.45 3.196 3.97933 3.588 3.588C3.98 3.19667 4.45067 3.00067 5 3H9.2C9.41667 2.4 9.77933 1.91667 10.288 1.55C10.7967 1.18333 11.3673 1 12 1C12.6333 1 13.2043 1.18333 13.713 1.55C14.2217 1.91667 14.584 2.4 14.8 3H19C19.55 3 20.021 3.196 20.413 3.588C20.805 3.98 21.0007 4.45067 21 5V19C21 19.55 20.8043 20.021 20.413 20.413C20.0217 20.805 19.5507 21.0007 19 21H5ZM5 19H19V5H5V19ZM7 17H14V15H7V17ZM7 13H17V11H7V13ZM7 9H17V7H7V9ZM12 4.25C12.2167 4.25 12.396 4.179 12.538 4.037C12.68 3.895 12.7507 3.716 12.75 3.5C12.75 3.28333 12.679 3.10433 12.537 2.963C12.395 2.82167 12.216 2.75067 12 2.75C11.7833 2.75 11.6043 2.821 11.463 2.963C11.3217 3.105 11.2507 3.284 11.25 3.5C11.25 3.71667 11.321 3.896 11.463 4.038C11.605 4.18 11.784 4.25067 12 4.25Z" fill="#004C73"/>
      </svg>      
    ),
    component: Assignments,
  }
];
