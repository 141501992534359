import React from "react";
import { useState, useEffect } from "react";
import Input from "../../../../Components/Profile/Input/Input";
import TextArea from "../../../../Components/Profile/Input/TextArea";
import Button from "../../../../Components/Profile/Button/Button";
import DatePicker from "../../../../Components/Profile/DatePicker/DatePicker";
import Popup from "../../../../Components/Profile/Popup/PopUp";
import Loading from "../../../../Components/Loading";

const CertificationForm = ({
  title,
  HandleSubmitFunction,
  onClose,
  show,
  isEdit = false,
  editCertificationData,
}) => {
  const [certificationDetails, setCertificationDetails] = useState({
    title: "",
    issuer: "",
    description: "",
    start_date: "",
    end_date: "",
    certification_url: "",
  });

  const [errors, setErrors] = useState({
    title: false,
    issuer: false,
    description: false,
    start_date: false,
    end_date: false,
    certification_url: false,
  });

  const [isFormValid, setIsFormValid] = useState(false);
  const [isFormFieldsEmpty, setIsFormFieldsEmpty] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isEdit) {
      setCertificationDetails({ ...editCertificationData });
    }
  }, [isEdit, editCertificationData]);

  const validateInput = (name, value) => {
    switch (name) {
      case "description":
        return value.length <= 500;
      case "title":
        return value.length <= 100;
      case "certification_url":
        return value.length <= 100;
      default:
        return true;
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const isValid = validateInput(name, value);
    setCertificationDetails({ ...certificationDetails, [name]: value });
    setErrors((prevErrors) => ({ ...prevErrors, [name]: !isValid }));
  };

  const handleDateChange = (date, name) => {
    setCertificationDetails({ ...certificationDetails, [name]: date });
    const isValid = date !== null;
    setErrors((prevErrors) => ({ ...prevErrors, [name]: !isValid }));
  };

  const validateForm = () => {
    const isFormValid = Object.values(errors).every((x) => x === false);
    setIsFormValid(isFormValid);
  };

  const validateFormField = () => {
    const isFormFieldEmpty = Object.values(certificationDetails).some(
      (x) => x === ""
    );
    const hasErrors = Object.values(errors).some((x) => x === true);
    setIsFormFieldsEmpty(isFormFieldEmpty || hasErrors);
  };

  useEffect(() => {
    validateFormField();
  }, [certificationDetails, errors]);

  const submitForm = async (e) => {
    setLoading(true);
    validateForm();
    e.preventDefault();
    try {
      await HandleSubmitFunction(certificationDetails);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Popup title={title} onClose={onClose} show={show}>
      <div className="profile-exp-form">
        <div className="profile-exp-form-section">
          <Input
            width="90%"
            name="title"
            label="Certification Title"
            inputPlaceholderValue="Enter Certification Title"
            value={
              certificationDetails.title !== ""
                ? certificationDetails.title
                : ""
            }
            onChangeFunction={handleInputChange}
            inperror={errors.title}
          />
          <Input
            width="90%"
            name="issuer"
            label="Certification Issuer"
            selectLabel="Enter Certification Issuer"
            value={
              certificationDetails.issuer !== ""
                ? certificationDetails.issuer
                : ""
            }
            onChangeFunction={handleInputChange}
            inperror={errors.issuer}
          />
        </div>
        <TextArea
          width="100%"
          name="description"
          label="Certification Description"
          inputPlaceholderValue="Enter Certification Description"
          value={
            certificationDetails.description !== ""
              ? certificationDetails.description
              : ""
          }
          onChangeFunction={handleInputChange}
          inperror={errors.description}
        />
        <div className="profile-exp-form-section">
          <div className="profile-form-section-date-container">
            <DatePicker
              width="100%"
              name="start_date"
              label="Certification Start Date"
              inputPlaceholderValue="Enter Start Date"
              defaultValue={
                certificationDetails.start_date
                  ? new Date(certificationDetails.start_date)
                  : ""
              }
              onChangeFunction={(e) => handleDateChange(e, "start_date")}
              inperror={errors.start_date}
            />
          </div>
          <div className="profile-form-section-date-container-2">
          <DatePicker
            width="90%"
            name="end_date"
            label="Certification End Date"
            inputPlaceholderValue="Enter End Date"
            defaultValue={
              certificationDetails.end_date
                ? new Date(certificationDetails.end_date)
                : ""
            }
            onChangeFunction={(e) => handleDateChange(e, "end_date")}
            inperror={errors.end_date}
          />
          </div>
        </div>
        <div className="profile-exp-form-section">
          <Input
            width="100%"
            name="certification_url"
            label="Certification Link"
            inputPlaceholderValue="Enter Certification Link"
            value={
              certificationDetails.certification_url !== ""
                ? certificationDetails.certification_url
                : ""
            }
            onChangeFunction={handleInputChange}
            inperror={errors.certification_url}
          />
        </div>
        {loading ? (
          <div className="profile-delete-loading-container">
            <Loading color="#14144e" />
          </div>
        ) : (
          <div className="profile-exp-form-section-button">
            <Button
              text="SAVE"
              onClick={isFormFieldsEmpty ? null : submitForm}
              disabled={isFormFieldsEmpty}
            />
          </div>
        )}
      </div>
    </Popup>
  );
};

export default CertificationForm;
