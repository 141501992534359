import React, { useState, useEffect, useContext } from "react";
import CoursesHistory from "../QtTool/layouts/CourseHistory/CourseHistory";
import Courses from "./Courses";

const QtToolCourses = () => {
  const [selectedTab, setSelectedTab] = useState("courseshome");
  const [selectedCoursesData, setSelectedCoursesData] = useState({});

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  }

  return (
    <div className="assignments">
      {selectedTab === "courseshome" && <Courses handleTabChange={handleTabChange} setSelectedCoursesData={setSelectedCoursesData} />}
      {selectedTab === "coursesselected" && <CoursesHistory handleTabChange={handleTabChange} selectedCoursesData={selectedCoursesData} previousTab={"courseshome"} />}
    </div>
  );
};

export default QtToolCourses;