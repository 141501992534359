import React, { useState, useEffect, useContext } from "react";
import "./SkillboxV2User.css";
import { SkillboxSideBarData } from "./Data/SidebarData";
import Sidebar from "../../../Components/Profile/Sidebar/Sidebar";
import ProfileDropdown from "../../../Components/Profile/ProfileDropdown/ProfileDropdown";
import { ProfileDropdownValues } from "./Data/ProfileDropdownValues";
import { AuthContext } from "../../../context/auth-context";
import { useNavigate } from "react-router";
import { useHttpClient } from "../../../Components/Hooks/http-hook";
import swal from "sweetalert2";
// import LoadingSpinner from "../../../Components/Input/LoadingSpinner";

const SkillboxV2User = ({ isNoHeaderFooter, setIsNoHeaderFooter }) => {
  const auth = useContext(AuthContext);
  const { setUserProfilePic, setUserName } = useContext(AuthContext);
  const { sendRequest } = useHttpClient();
  const [SidebarComponent, setSidebarComponent] = useState(
    SkillboxSideBarData[0]
  );
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState({
    value: "skillbox",
    label: "Skillbox",
  });
  // const [profileLoading, setProfileLoading] = useState(true);
  const [selectedSidebarIndex, setSelectedSidebarIndex] = useState(0);

  const history = useNavigate();

  const renderSidebar = (data) => {
    setSidebarComponent(data);
  };

  const isValidUrl = (string) => {
    try {
      new URL(string);
    } catch (_) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (selectedOption.value === "logout") {
      swal
        .fire({
          title: "Logout",
          text: "Are you sure you want to logout?",
          showCancelButton: true,
          confirmButtonText: "Logout",
          confirmButtonColor: "#ff0000",
          cancelButtonText: "Cancel",
          cancelButtonColor: "#2874f0",
          allowOutsideClick: false,
        })
        .then((result) => {
          if (result.isConfirmed) {
            auth.logout();
            setIsNoHeaderFooter(false);
          } else {
            setSelectedOption({
              value: "skillbox",
              label: "Skillbox",
            });
          }
        });
    } else if (selectedOption.value === "resetPassword") {
      setIsNoHeaderFooter(false);
      history("/password-reset");
    } else {
      renderSidebar(SkillboxSideBarData[0]);
    }

    setSelectedSidebarIndex(0);
  }, [selectedOption]);

  useEffect(() => {
    setIsNoHeaderFooter(true);
  }, []);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        isDropdownOpen &&
        !event.target.closest(".profile-content-header-dropdown")
      ) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [isDropdownOpen, setIsDropdownOpen]);

  useEffect(() => {
    renderSidebar(SkillboxSideBarData[0]);
  }, []);

  return (
    <>
      {/* {profileLoading ? (
        <div className="profile">
          <LoadingSpinner asOverlay />
        </div>
      ) : (
        <> */}
      <div className="profile">
        <Sidebar
          SidebarData={SkillboxSideBarData}
          renderSidebar={renderSidebar}
          setIsNoHeaderFooter={setIsNoHeaderFooter}
          selectedSidebarIndex={selectedSidebarIndex}
          setSelectedSidebarIndex={setSelectedSidebarIndex}
        />

        <div className="profile-content">
          <div className="profile-content-header">
            <div className="profile-content-header-dropdown">
              <ProfileDropdown
                ProfileDropdownValues={ProfileDropdownValues}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                isOpen={isDropdownOpen}
                setIsOpen={setIsDropdownOpen}
              />
            </div>
          </div>
          <div className="profile-content-body">
            <SidebarComponent.component setSelectedOption={setSelectedOption} />
          </div>
        </div>
      </div>
      {/* </>
      )} */}
    </>
  );
};

export default SkillboxV2User;
