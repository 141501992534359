import React, { useContext } from "react";
import "./ProfileDropdown.css";
import { AuthContext } from "../../../context/auth-context";
import { AdminContext } from "../../../context/admin-context";

const ProfileDropdown = ({
  ProfileDropdownValues,
  selectedOption,
  setSelectedOption,
  isOpen,
  setIsOpen,
}) => {
  const auth = useContext(AuthContext);
  const admin = useContext(AdminContext);

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  const getFirstWord = (str) => {
    if (str) {
      return str.split(" ")[0];
    }
    return "";
  };

  return (
    <div className="custom-profile-dropdown">
      <div
        className="profile-dropdown-header"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="profile-user-info">
          <div className="profile-pic-container">
            <div className="online-indicator"></div>
            {auth.profile_pic ? (
              <img
                className="profile-pic-image-container"
                src={auth.profile_pic}
                alt="Profile"
              />
            ) : (
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.8337 15.5625V14.1042C14.8337 13.3306 14.5264 12.5888 13.9794 12.0418C13.4324 11.4948 12.6905 11.1875 11.917 11.1875H6.08366C5.31011 11.1875 4.56825 11.4948 4.02126 12.0418C3.47428 12.5888 3.16699 13.3306 3.16699 14.1042V15.5625M11.917 5.35417C11.917 6.965 10.6112 8.27083 9.00033 8.27083C7.3895 8.27083 6.08366 6.965 6.08366 5.35417C6.08366 3.74334 7.3895 2.4375 9.00033 2.4375C10.6112 2.4375 11.917 3.74334 11.917 5.35417Z"
                  stroke="#004C73"
                  strokeWidth="1.45833"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}
          </div>
          <span className="username">
            {admin.isLoggedIn ? <>Admin</> : getFirstWord(auth.userName)}
          </span>
        </div>
        <div className="profile-dropdown-arrow">
          <svg
            width="14"
            height="11"
            viewBox="0 0 14 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.95039 10.1504L13.1504 0.850391L0.750391 0.85039L6.95039 10.1504Z"
              fill="#3C3C3C"
            />
          </svg>
        </div>
      </div>
      {isOpen && (
        <ul className="profile-dropdown-options">
          {ProfileDropdownValues.map((option) => (
            <li
              key={option.value}
              className={
                selectedOption &&
                selectedOption === option &&
                option.value !== "resetPassword" &&
                option.value !== "logout"
                  ? "selected"
                  : ""
              }
              onClick={() => handleOptionSelect(option)}
            >
              {option.label}
              {selectedOption &&
                selectedOption === option &&
                option.value !== "resetPassword" &&
                option.value !== "logout" && (
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.6663 5L7.49967 14.1667L3.33301 10"
                      stroke="#004C73"
                      strokeWidth="1.66667"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default ProfileDropdown;
