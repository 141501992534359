import React, { useState } from 'react';
import Info from '../../Components/Info';
import Skills from './pages/Skills';
import SelectLevel from './pages/SelectLevel';
import "./SkillBasedInterview.css";
import { skills } from './data/skills';

const questionLevel = [
    { value: "beginner", label: "Beginner" },
    { value: "intermediate", label: "Intermediate" },
    { value: "advanced", label: "Advanced" },
];

const SkillBasedInterview = ({ 
  startInterviewHandler,
  selectedInterviewType, 
  handleGoToHome, 
  handleGoToAllInterview 
}) => {
  const [currentPage, setCurrentPage] = useState('info');
  const [selectedSkill, setSelectedSkill] = useState("");
  const [selectedLevel, setSelectedLevel] = useState("");

  const handleShowSkills = () => {
    setCurrentPage('skills');
  };

  const handleShowSelectLevel = () => {
    setCurrentPage('selectLevel');
  };

  return (
    <>
      {currentPage === 'info' && (
        <Info
          selectedInterviewType={selectedInterviewType}
          handleGoToHome={handleGoToHome}
          handleGoToAllInterview={handleGoToAllInterview}
          onNext={handleShowSkills}
        />
      )}
      {currentPage === 'skills' && (
        <Skills
          selectedInterviewType={selectedInterviewType}
          skills={skills}
          setSelectedSkill={setSelectedSkill}
          selectedSkill={selectedSkill}
          handleGoToHome={handleGoToHome}
          handleGoToAllInterview={handleGoToAllInterview}
          handleGoToSkillBased={() => setCurrentPage('info')}
          onNext={handleShowSelectLevel}
        />
      )}
      {currentPage === 'selectLevel' && (
        <SelectLevel
          selectedInterviewType={selectedInterviewType}
          startInterviewHandler={startInterviewHandler}
          level={questionLevel}
          setSelectedLevel={setSelectedLevel}
          selectedLevel={selectedLevel}
          selectedSkill={selectedSkill}
          handleGoToHome={handleGoToHome}
          handleGoToAllInterview={handleGoToAllInterview}
          handleGoToSkills={() => setCurrentPage('info')}
          handleGoToSelectSkill={() => setCurrentPage('skills')}
        />
      )}
    </>
  );
};

export default SkillBasedInterview;
