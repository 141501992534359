import React from "react";
import { useContext, useEffect, useState } from "react";
import "./LectureRecordings.css";
import { useNavigate, useParams } from "react-router-dom";
import { useHttpClient } from '../../../../../../../../../Components/Hooks/http-hook';
import { AuthContext } from '../../../../../../../../../context/auth-context';
import Button from '../../../../../../../../../Components/Profile/Button/Button';
import VideoPlayer from '../../Components/VideoPlayer';
import Loading from "../../../../../../../../../Components/Loading";

const LectureRecordings = ({ handleBackToModules }) => {
    const navigate = useNavigate();
    const { cid, clid, ct, pid } = useParams();
    const [classInfo, setClassInfo] = useState({});
    const [classContent, setClassContent] = useState([]);
    const [showVideo, setShowVideo] = useState(false);
    const [pageLoading, setPageLoading] = useState(true);
  
    const auth = useContext(AuthContext);
    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const { isLoading: isLoading2, error: error2, sendRequest: sendRequest2, clearError: clearError2 } = useHttpClient();

    const fetchContents = async () => {
        try {
          const responseData = await sendRequest(
            `${process.env.REACT_APP_BACKEND_URL}/db/contents/${clid}`,
            "GET",
            null,
            {
              "Content-Type": "application/json",
              "Refresh-Token": auth.refresh_token,
              Authorization: auth.token,
            }
          );
          setClassContent(responseData.data);
        } catch (err) {
          console.log(err);
        }
      };
    
    const fetchClass = async () => {
        try {
          const responseData = await sendRequest2(
            `${process.env.REACT_APP_BACKEND_URL}/db/class/${clid}`,
            "GET",
            null,
            {
              "Content-Type": "application/json",
              "Refresh-Token": auth.refresh_token,
              Authorization: auth.token,
            }
          );
          setClassInfo(responseData.data);
        } catch (err) {
          console.log(err);
        }
      };
    
      useEffect(() => {
        fetchContents();
        fetchClass();
      }, []);
    

    const goToHome = () => {
        navigate("/skillbox-v2");
    }

    const goToPathWay = () => {
        navigate(`/skillbox-v2/${cid}/${ct}`);
      }

    const goToModules = () => {
        navigate(`/skillbox-v2/${cid}/${ct}/pathway/${pid}`);
    }

    return (
        <>
            <div className="stu-skillbox-nav-header">
                <span onClick={goToHome}>Home</span>
                <span>
                    {" "}&gt;{" "}
                </span>
                <span onClick={goToPathWay}>Pathway</span>
                <span>
                    {" "}&gt;{" "}
                </span>
                <span onClick={handleBackToModules}>Modules</span>
            </div>
            <div className="stu-ts-skillbox-header">
                <div className="stu-ts-skillbox-header-title">
                   Lecture Recordings {classInfo && Object.keys(classInfo).length !== 0 && (
                    <> - {classInfo.class_name} </>
                    )}
                </div>
                <div className="st-ts-cls-body-last-updated">
                    View the recordings of the class
                </div>
            </div>

            {(isLoading || isLoading2 || error || error2) && (
                <div className="st-cls-body">
                    <div className="st-cls-lt-section">
                        {pageLoading && (
                            <Loading color="#004c73" customClass="st-cls-interview-loading" />
                        )}
                        {error && <div className="interview-questions-not-found-section">
                            Something went wrong, Please try again later
                        </div>
                        }
                    </div>
                </div>
            )}
            {!isLoading && classContent && (
                <>
                    {(classContent.length === 0 || classContent.filter((item) => item.content_type === "video").length === 0) ? (
                        <div className="st-cls-body">
                            <div className="st-cls-lt-section">
                                <div className="interview-questions-not-found-section">
                                    No Recordings Found
                                </div>
                            </div>
                        </div>
                        ):(
                        <div className="st-cls-body">
                            <div className="st-cls-section">
                            {classContent &&
                                classContent.map((item, index) => (
                                <div key={index}>
                                    {item.content_type === "video" && (
                                    <div className="stu-cls-vid-container">
                                        <div className="stu-cls-cls-vid-title">
                                            {item.content_text || "Class Recording"}
                                        </div>
                                        <div className="st-cls-class-video">
                                            <VideoPlayer
                                                url={item.content_file_url}
                                                title={classInfo.class_name}
                                                courseId={ct}
                                            />
                                        </div>
                                    </div>
                                    )}
                                </div>
                                ))}
                            </div>
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default LectureRecordings;
