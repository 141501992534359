import React from "react";
import DragDrop from "./DragDrop";

const NewResume = ({ addNew, setAddNew }) => {
  return !addNew ? (
    <div className="profile-contact-input-section">
      <div className="resume-upload-section">
        <div className="resume-upload-section-title">
          Add Resume
          <div className="resume-upload-section-subtitle">
            Upload your resume
          </div>
        </div>
        {/* <div className="resume-upload-section-input"> */}

        <button
          className="resume-upload-button"
          onClick={() => setAddNew(true)}
        >
          Add New
        </button>
      </div>
    </div>
  ) : (
    <DragDrop setAddNew={setAddNew} />
  );
};

export default NewResume;
