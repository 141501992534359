import React from "react";
import { useEffect } from "react";
import "./NavLegends.css";

const NavLegends = ({
  selectedQuestionId,
  setSelectedQuestionId,
  questionLength,
  questions,
  setSelectedQuestion,
  setQuestionSpoken,
}) => {
  useEffect(() => {
    setSelectedQuestion(
      questions && questions.find((data) => data.id === selectedQuestionId)
    );
    setQuestionSpoken(false);
  }, [questions, setSelectedQuestion, setSelectedQuestionId]);

  const renderSquare = (index) => (
    <div
      key={index}
      className={`personal-interview-nav-legends-square ${
        selectedQuestionId === index + 1
          ? "personal-interview-nav-legends-square-active"
          : ""
      }`}
    >
      {index + 1}
    </div>
  );

  return (
    <div className="personal-interview-nav-legends">
      <div className="personal-interview-nav-legends-squares">
        {Array.from(Array(questionLength).keys()).map(renderSquare)}
      </div>
    </div>
  );
};

export default NavLegends;
