import React, { useEffect, useState, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./Auth.css";
import { AuthContext } from "../../context/auth-context";
import Button from "../../Components/Input/Button";
import Input from "../../Components/Input/Input";
import LoadingSpinner from "../../Components/Input/LoadingSpinner";
import {
  VALIDATOR_EMAIL,
  VALIDATOR_MINLENGTH,
  VALIDATOR_REQUIRE,
} from "../../Components/Input/validators";
import useForm from "../../Components/Hooks/formhooks";
import ImageUpload from "../../Components/Input/ImageUpload";
import { useHttpClient } from "../../Components/Hooks/http-hook";
import ErrorModal from "../../Components/Input/ErrorModel";
import EmailIcon from "@material-ui/icons/Email";
import AssignmentIcon from "@material-ui/icons/Assignment";
import { AdminContext } from "../../context/admin-context";

const Adminauth = () => {
  const auth = useContext(AdminContext);
  const history = useNavigate();
  const [formState, inputHandler, setFormData] = useForm({
    inputs: {
      email: {
        value: "",
        isValid: false,
      },
      password: {
        value: "",
        isValid: false,
      },
    },
    isValid: false,
  });

  

  // const myRef = useRef(null);
  // const scrollTop = async () => scrollToRef(myRef);

  // useEffect(() => {
  //   scrollTop();
  // }, []);

  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const submitHandler = async (event) => {
    event.preventDefault();

    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/admin/login`,
        "POST",
        JSON.stringify({
          email: formState.inputs.email.value,
          password: formState.inputs.password.value,
        }),
        {
          "Content-Type": "application/json",
        }
      );
      auth.login(responseData.email);
      history.push("/admin/home");
    } catch (err) {}
  };

  return (
    <React.Fragment>
      <div className="auth-form-flex">
        <div className="auth-learning">
          <div className="auth-sec">
            <h2>
              Surge <span style={{ color: "#004466" }}>Classes</span>
            </h2>
            <h3>
              Improve your Skills{" "}
              <span style={{ fontWeight: "300" }}>
                Online
                <br /> with our Surge Classes Learning Platform.{" "}
              </span>
            </h3>
            <p>
              Lets start your learning now,
              <br /> Sign In with us...
            </p>
          </div>
          <ErrorModal error={error} onClear={clearError} />
          {isLoading && <LoadingSpinner asOverlay />}
          {!isLoading && (
            <div className="authentication">
              {/* <hr /> */}
              <form onSubmit={submitHandler}>
                <div className="auth-form">
                  <div className="formnaya"></div>
                  <div className="auth-box">
                    <h2 className="mylogin-title">Admin Login Page</h2>
                    <div className="form-class1">
                      <div className="iinput-box">
                        <EmailIcon
                          style={{ marginRight: "1rem" }}
                          className="input-icon"
                        />
                        {/* <PersonIcon className="input-icon" /> */}
                        <Input
                          type="email"
                          // label="Email"
                          placeholder="Enter Valid Email id"
                          id="email"
                          value={formState.inputs.email.value}
                          isValid={formState.inputs.email.isValid}
                          // errorText="Enter a valid Email"
                          validators={[VALIDATOR_EMAIL()]}
                          element="input"
                          onInput={inputHandler}
                        />
                      </div>
                    </div>

                    <div className="form-class1">
                      <div className="iinput-box">
                        <AssignmentIcon
                          style={{ marginRight: "1rem" }}
                          className="input-icon"
                        />
                        <Input
                          className="password-input"
                          type="password"
                          placeholder="Type Password"
                          // errorText="Enter a valid password"
                          id="password"
                          validators={[VALIDATOR_MINLENGTH(6)]}
                          element="input"
                          onInput={inputHandler}
                          value={formState.inputs.password.value}
                          isValid={formState.inputs.password.isValid}
                        />
                      </div>
                    </div>

                    <div className="form-class1">
                      <Button
                        className="form-register-button"
                        type="submit"
                        disabled={!formState.isValid}
                      >
                        Login
                      </Button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Adminauth;
