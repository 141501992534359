import React, { useEffect, useState, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "./Pathway.css";
import Course1 from "../../assets/Continuing-professional-development-1170x630.jpg";
import { useHttpClient } from "../../Components/Hooks/http-hook";
import { AuthContext } from "../../context/auth-context";

const Pathway = () => {
  const { cid, ct, pid } = useParams();
  const [modules, setModules] = useState([]);
  const [pathway, setPathway] = useState({});

  const history = useNavigate();

  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const fetchModules = async () => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/db/modules/${pid}`,
        "GET",
        null,
        {
          "Content-Type": "application/json",
          "Refresh-Token": auth.refresh_token,
          Authorization: auth.token,
        }
      );
      setModules(responseData.data);
    }
    catch (err) {
      console.log(err);
    }
  }

  const fetchPathway = async () => {
    try{
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/db/section/${pid}`,
        "GET",
        null,
        {
          "Content-Type": "application/json",
          "Refresh-Token": auth.refresh_token,
          Authorization: auth.token,
        }
      );
      setPathway(responseData.data);
    }
    catch(err){
      console.log(err)
    }
  }

  useEffect(() => {
    fetchModules();
    fetchPathway();
  }
  , []);

  const goToModule = (module_id) => {
    history(`/skillbox/${cid}/${ct}/modules/${module_id}`)
  }

  return (
    <div className="homepage">
      <div className="custom-pathway-container">
        <div className="custom-pathway-content">
          <div className="pathway-info">
            <div className="pathway-div-left">
              <h1>{pathway.section_name}</h1>
              <div className="pathway-description">
                <p>{pathway.section_description}</p>
              </div>
            </div>
            <div className="pathway-div-right">
              <div className="pathway-image">
                <img
                  src={Course1}
                  alt={pathway.section_name}
                  className="custom-pathway-image"
                />
              </div>
            </div>
          </div>
          <div className="modules-section">
            <h2>Module List</h2>
            <ul>
              {modules.map((module, index) => (
                <li key={index} className="module-list">
                  <span className="module-number">{index + 1}. </span>
                  {module && module.module_lock === true && (
                  <span className="module-lock-icon">🔒</span>
                  )}
                  <span className="module-name">
                  {module && module.module_name}
                  </span>
                  <button 
                    className="module-button" 
                    onClick={()=>goToModule(module.module_id)}
                  >View</button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pathway;
