import React from "react";
import "../Common.css";
import { useState, useEffect, useContext } from "react";
import CustomPopup from "../../../../../../Components/Popup/CustomPopup";
import Input from "../../../../../../../../../Components/Profile/Input/Input";
import Button from "../../../../../../../../../Components/Profile/Button/Button";
import { AdminContext } from "../../../../../../../../../context/admin-context";
import { useHttpClient } from "../../../../../../../../../Components/Hooks/http-hook";
import { toast } from "react-toastify";

const Edit = ({
    show,
    setShow,
    assignmentData,
    courseName,
    sectionName,
    moduleName,
    onEdit
}) => {
    const admin = useContext(AdminContext);
    const { sendRequest, isLoading, error, clearError } = useHttpClient();
    const [formData, setFormData] = useState({});
    const [isFileUrl, setIsFileUrl] = useState(true);

    useEffect(() => {
        setFormData({
            id: assignmentData.id,
            title: assignmentData.title,
            skills: assignmentData.skills,
            file: assignmentData.file_location_s3_url,
            due_days: assignmentData.due_days,
            order: assignmentData.order
        });
    }, [assignmentData]);

    const onSubmit = async () => {
        const formDataToSend = new FormData();
        formDataToSend.append("title", formData.title);
        formDataToSend.append("skills", formData.skills);
        formDataToSend.append("file", formData.file);
        formDataToSend.append("due_days", formData.due_days);
        formDataToSend.append("order", formData.order);

        try {
            const responseData = await sendRequest(
                `${process.env.REACT_APP_BACKEND_URL}/assignments/edit/${formData.id}`,
                "PATCH",
                formDataToSend,
                {
                    "Refresh-Token": admin.refresh_token,
                    Authorization: admin.token,
                }
            );
            if (responseData) {
                toast.success("Assignment Updated Successfully");
                onEdit();
                handleClose();
            }
        } catch (err) {
            toast.error("Assignment Updation Failed");
            console.log(err);
        }
    }

    const handleClose = () => {
        setShow(false);
        setIsFileUrl(true);
        setFormData({
            title: "",
            skills: "",
            file: "",
            due_days: "",
            order: "",
        });
    };

    return (
        <CustomPopup
            title={assignmentData.title}
            subtitle={`<b>Course:</b> ${courseName} | <b>Pathway:</b> ${sectionName} <br/> <b>Module:</b> ${moduleName}`}
            show={show}
            onClose={handleClose}
        >
            <div className="sk-ad-asgnmt_popup-content">
                <div className="sk-ad-asgnmt_popup-content-row">
                    <label htmlFor="title" className="sk-ad-asgnmt_popup-content-row-label">
                        Title
                    </label>
                    <Input
                        width="100%"
                        name="title"
                        value={formData.title}
                        onChangeFunction={(e) => setFormData({
                            ...formData,
                            title: e.target.value
                        })}
                        isError={false}
                    />
                </div>
                <div className="sk-ad-asgnmt_popup-content-row">
                    <label htmlFor="skills" className="sk-ad-asgnmt_popup-content-row-label">
                        Skills
                    </label>
                    <Input
                        width="100%"
                        name="skills"
                        value={formData.skills}
                        onChangeFunction={(e) => setFormData({
                            ...formData,
                            skills: e.target.value
                        })}
                        isError={false}
                    />
                </div>
                <div className="sk-ad-asgnmt_popup-content-row">
                    <label htmlFor="file" className="sk-ad-asgnmt_popup-content-row-label">
                        File
                    </label>
                    {isFileUrl ? (
                        <>
                            {formData.file && (
                                <>
                                    {formData.file.startsWith("http://") ||
                                        formData.file.startsWith("https://") ? (
                                        <a href={formData.file} className="sk-ad-asgnmt_popup-content-row-file" download>
                                            Download File
                                        </a>
                                    ) : (
                                        <a href={`${process.env.REACT_APP_BACKEND_URL
                                            }${formData.file}`} className="sk-ad-asgnmt_popup-content-row-file" download>
                                            Download File
                                        </a>
                                    )}
                                </>
                            )}
                            <span onClick={() => {
                                setFormData({
                                    ...formData,
                                    file: ""
                                });
                                setIsFileUrl(false);
                            }} className="sk-ad-asgnmt_popup-content-row-clear-crs-icn">
                                ✖
                            </span>
                        </>

                    ) : (
                        <input
                            type="file"
                            accept=".zip"
                            onChange={(e) => setFormData({
                                ...formData,
                                file: e.target.files[0]
                            })}
                            className="sk-ad-asgnmt_popup-content-row-sp-fl-inp"
                        />
                    )}
                </div>
                <div className="sk-ad-asgnmt_popup-content-row-sp">
                    <div className="sk-ad-asgnmt_popup-content-row">
                        <label htmlFor="due_days" className="sk-ad-asgnmt_popup-content-row-label">
                            Due Days
                        </label>
                        <Input
                            inpType="number"
                            width="95%"
                            name="due_days"
                            value={formData.due_days}
                            onChangeFunction={(e) => setFormData({
                                ...formData,
                                due_days: e.target.value
                            })
                            }
                            isError={false}
                        />
                    </div>
                    <div className="sk-ad-asgnmt_popup-content-row">
                        <label htmlFor="order" className="sk-ad-asgnmt_popup-content-row-label">
                            Order
                        </label>
                        <Input
                            inpType="number"
                            width="95%"
                            name="order"
                            value={formData.order}
                            onChangeFunction={(e) => setFormData({
                                ...formData,
                                order: e.target.value
                            })}
                            isError={false}
                        />
                    </div>
                </div>
                <div className="sk-ad-asgnmt_popup-content-row-btn-dupcn">
                    {isLoading ? (
                        <Button
                            text="Updating Assignment..."
                            disabled={true}
                            width="60%"
                        />
                    ) : (
                        <Button
                            text={"Update Assignment"}
                            onClick={onSubmit}
                            disabled={formData.title === "" || formData.skills === "" || formData.due_days === "" || formData.order === "" || formData.file === ""}
                            width="60%"
                        />
                    )}
                </div>
            </div>
        </CustomPopup>
    );
}

export default Edit;