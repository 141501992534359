import React, { useEffect, useContext, useState, useRef } from "react";
import "./ShowMessage.css";
import { AuthContext } from "../../../../../../../context/auth-context";
import { useHttpClient } from "../../../../../../../Components/Hooks/http-hook";
import SendMessageSvg from "../../../../assets/svgs/SendMessageSvg";
const ShowMessage = ({ showMessagePopup, queryId, setShowMessagePopup, resolveQuery }) => {
    const [query, setQuery] = useState({});
    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const [newMessage, setNewMessage] = useState("");
    const messagesEndRef = useRef(null);
    const [sendingMessage, setSendingMessage] = useState(false);
    const [loadingMessages, setLoadingMessages] = useState(false);
    const [isResolved, setIsResolved] = useState(false);
    const [zoomLevel, setZoomLevel] = React.useState(1);

    const auth = useContext(AuthContext);

    const scrollToBottom = () => {
        messagesEndRef &&
            messagesEndRef.current &&
            messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(scrollToBottom, [query]);

    useEffect(() => {
        const fetchQuery = async () => {
            try {
                setLoadingMessages(true);
                if (!queryId) return;
                const url = `${process.env.REACT_APP_BACKEND_URL}/auth/user/queries/${queryId}`;
                const responseData = await sendRequest(url, "GET", null, {
                    "Content-Type": "application/json",
                    "Refresh-Token": auth.refresh_token,
                    Authorization: auth.token,
                });
                setLoadingMessages(false);
                setQuery(responseData.query);
                setIsResolved(responseData.query.status === "resolved");
            } catch (err) { }
        };
        fetchQuery();
    }, [queryId]);

    const handleSubmitMessage = async (event) => {
        event.preventDefault();
        setSendingMessage(true);
        const messageData = JSON.stringify({ message: newMessage });
        try {
            const url = `${process.env.REACT_APP_BACKEND_URL}/auth/user/queries/chat/${queryId}`;
            await sendRequest(url, "POST", messageData, {
                "Content-Type": "application/json",
                "Refresh-Token": auth.refresh_token,
                Authorization: auth.token,
            });
            setSendingMessage(false);
            const newQuery = { ...query };
            newQuery.query_messages.push({
                message: newMessage,
                sender: "student",
                sendtime: new Date().toDateString(),
            });
            setQuery(newQuery);
            setNewMessage("");
            scrollToBottom();
        } catch (err) { }
    };

    const handleResolve = async (event) => {
        event.preventDefault();
        try {
            let url = `${process.env.REACT_APP_BACKEND_URL}/auth/user/queries/resolve/${queryId}`;
            const responseData = await sendRequest(url, "PATCH", null, {
                "Content-Type": "application/json",
                "Refresh-Token": auth.refresh_token,
                Authorization: auth.token,
            });
            setIsResolved(true);
            resolveQuery(queryId);
        } catch (err) { }
    };

    const handleDownload = () => {
        const bytes = atob(query.query_zip_path.base64);
        let length = bytes.length;
        let out = new Uint8Array(length);

        while (length--) {
            out[length] = bytes.charCodeAt(length);
        }

        let blob = new Blob([out], { type: "application/pdf" });
        // const blob = base64toBlob(fileDetails.base64)
        console.log(blob, "blob");
        const url = URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = url;
        const filename = query.query_zip_path.filename;
        console.log(filename);
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        console.log(url);
        link.click();
    };

    if (loadingMessages) {
        return (
            <div className="sk-doubts-loading-container">
                <div className="sk-doubts-loading-spinner"></div>
                <p>Loading conversation...</p>
            </div>
        );
    }

    return (
        <div className="sk-doubts-show-message-content">

            <div className="sk-doubts-message-list">
                <div className="sk-doubts-attachments">
                    {query && query.query_image_path && query.query_image_path.map((file, i) => (
                        <div key={file.filename} className="sk-doubts-image-container">
                            <img
                                style={{ transform: `scale(${zoomLevel})` }}
                                src={`data:image/jpeg;base64,${file.base64}`}
                                alt={`Attachment ${i + 1}`}
                            />
                        </div>
                    ))}

                    {query && query.query_zip_path && (
                        <button className="sk-doubts-download-btn" onClick={handleDownload}>
                            <span className="download-icon">↓</span>
                            Download Attachments
                        </button>
                    )}
                </div>

                {query && query.query_messages && query.query_messages.map((message, index) => {
                    const isOwnMessage = message.sender === "student";
                    return (
                        <div
                            className={`sk-doubts-message ${isOwnMessage ? "sk-doubts-message__sender" : "sk-doubts-message__receiver"}`}
                            key={index}
                        >
                            <div
                                className={`sk-doubts-message__content ${isOwnMessage ? "sk-doubts-message__sender__text" : "sk-doubts-message__receiver__text"}`}
                            >
                                <div className="sk-doubts-message__content__text">
                                    {message.message}
                                </div>
                                <div className="sk-doubts-message__content__time">
                                    {message.sendtime}
                                </div>
                            </div>
                        </div>
                    );
                })}
                <div ref={messagesEndRef} />
            </div>

            {!isResolved && (
                <form onSubmit={handleSubmitMessage} className="sk-doubts-message-form">
                    <textarea
                        placeholder="Type your message here..."
                        value={newMessage}
                        readOnly={sendingMessage}
                        required
                        onChange={(event) => setNewMessage(event.target.value)}
                    />
                    <div className="sk-doubts-form-actions">
                        {sendingMessage ? (
                            <div className="sk-doubts-sending-indicator">
                                <div className="sk-doubts-sending-spinner"></div>
                                Sending...
                            </div>
                        ) : (
                            <>
                                <div className="sk-doubts-send-message-icon" type="submit"
                                    disabled={sendingMessage}
                                    onClick={handleSubmitMessage}
                                >
                                    <SendMessageSvg />
                                </div>
                            </>
                        )}
                    </div>
                </form>
            )}
            {isResolved ? (
                <div className="sk-doubts-resolved-banner">
                    <span className="sk-doubts-resolved-icon">✓</span>
                    This ticket has been resolved
                </div>
            ) : (
                <div className="sk-doubts-resolve-btn-container">
                <div
                    className="sk-doubts-resolve-btn"
                    onClick={handleResolve}
            >
                    Mark as Resolved
                    </div>
                </div>
            )}
        </div>
    );
};

export default ShowMessage;
