export const courses = [
  { value: "btech_cse", label: "B.Tech Computer Science" },
  { value: "btech_mechanical", label: "B.Tech Mechanical" },
  { value: "btech_electrical", label: "B.Tech Electrical" },
  { value: "btech_civil", label: "B.Tech Civil Engineering" },
  { value: "btech_electronics", label: "B.Tech Electronics and Communication" },
  { value: "btech_chemical", label: "B.Tech Chemical Engineering" },
  { value: "btech_aerospace", label: "B.Tech Aerospace Engineering" },
  { value: "btech_biotech", label: "B.Tech Biotechnology" },
  { value: "btech_it", label: "B.Tech Information Technology" },
  { value: "mtech_cse", label: "M.Tech Computer Science" },
  { value: "mtech_mechanical", label: "M.Tech Mechanical Engineering" },
  { value: "mtech_electrical", label: "M.Tech Electrical Engineering" },
  { value: "bsc_physics", label: "B.Sc Physics" },
  { value: "bsc_chemistry", label: "B.Sc Chemistry" },
  { value: "bsc_mathematics", label: "B.Sc Mathematics" },
  { value: "bsc_biology", label: "B.Sc Biology" },
  { value: "msc_physics", label: "M.Sc Physics" },
  { value: "msc_chemistry", label: "M.Sc Chemistry" },
  { value: "msc_mathematics", label: "M.Sc Mathematics" },
  { value: "ba_english", label: "BA English" },
  { value: "ba_history", label: "BA History" },
  { value: "ba_economics", label: "BA Economics" },
  { value: "ma_english", label: "MA English" },
  { value: "ma_history", label: "MA History" },
  { value: "bcom", label: "B.Com" },
  { value: "mcom", label: "M.Com" },
  { value: "bca", label: "BCA" },
  { value: "mca", label: "MCA" },
  { value: "bba", label: "BBA" },
  { value: "mba", label: "MBA" },
];

