import React, { useContext, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./Pathways.css";
import PathwayCard from './Components/Cards/PathwayCard';
import { useHttpClient } from '../../../../../../../../../Components/Hooks/http-hook';
import { AuthContext } from '../../../../../../../../../context/auth-context';
import Button from '../../../../../../../../../Components/Profile/Button/Button';
import Loading from '../../../../../../../../../Components/Loading';

const Pathways = () => {

  const navigate = useNavigate();
  const { cid, ct } = useParams();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [pathways, setPathways] = useState([]);
  const auth = useContext(AuthContext);

  const goToHome = () => {
    navigate("/skillbox-v2");
  };

  const getCoursePathways = async () => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/db/sections/${cid}`,
        "GET",
        null,
        {
          "Content-Type": "application/json",
          "Refresh-Token": auth.refresh_token,
          Authorization: auth.token,
        }
      );
      if (responseData && responseData.ok && responseData.data) {
        setPathways(responseData.data);
        return responseData.data;
      }
    } catch (err) {
      console.log("got error while getting course pathways", err);
    }
  };

  useEffect(() => {
    const fetchCoursePathways = async () => {
      const pathways = await getCoursePathways();
    };
    fetchCoursePathways();
  }, []);

  const handleClick = (pathway_id) => {
    navigate(`/skillbox-v2/${cid}/${ct}/pathway/${pathway_id}`);
  }

  return (
    <>
      <div className="profile-content-header-nav-menu-under-development" onClick={goToHome}>
        Home
      </div>
      {(isLoading || error) && (
        <div className="st-skillbox-programs-mn-ct-section">
          {isLoading && (
            <Loading color="#004c73" customClass="st-cls-interview-loading" />
          )}
          {error && <div className="interview-questions-not-found-section">
            {error}
          </div>}
        </div>
      )}
      {!isLoading && (
        <>
          {pathways.length === 0 ? <div>No Pathways found</div> : (
            <div className="personal-interview-container">
              <div className="personal-interview-header">
                <div className="personal-interview-header-title">
                  {ct}
                </div>
              </div>
              <div className="personal-interview-body">
                <div className="st-skillbox-programs-mn-ct-section">
                  <div className="st-skillbox-programs-mn-ct">
                    {pathways.length > 0 && pathways.map((pathway, i) => (
                      <div key={i}>
                        <PathwayCard program={pathway} handleClick={handleClick} />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Pathways;
