import React from "react";
import { useState, useEffect, useContext } from "react";
import "./PersonalInterview.css";
import TickSvg from "../../../assets/svgs/TickSvg";
import Popup from "../../../../../../Components/Profile/Popup/PopUp";
import InterviewStart from "./Pages/InterviewStart";
import BestOfLuck from "./Pages/BestOfLuck";
import InterviewPlatform from "./Pages/InterviewPlatform";
import { AuthContext } from "../../../../../../context/auth-context";
import { useHttpClient } from "../../../../../../Components/Hooks/http-hook";
import RoleBasedInterview from "./InterviewTypes/RoleBasedInterview";
import SkillBasedInterview from "./InterviewTypes/SkillBasedInterview";
import CommunicationBasedInterview from "./InterviewTypes/CommunicationBasedInterview";
import PlacementPrepInterview from "./InterviewTypes/PlacementPrepInterview";
import ProfileBasedInterview from "./InterviewTypes/ProfileBasedInterview";
const swal = require("sweetalert2");

const level = [
  { value: "fresher", label: "Fresher" },
  { value: "mid", label: "Mid-Level" },
  { value: "senior", label: "Senior" },
];

const questionLevel = [
  { value: "beginner", label: "Beginner" },
  { value: "intermediate", label: "Intermediate" },
  { value: "advanced", label: "Advanced" },
];

const skills = [
  { value: "javascript", label: "JavaScript" },
  { value: "angular", label: "Angular" },
  { value: "c", label: "C" },
  { value: "hibernate", label: "Hibernate" },
  { value: "cpp", label: "C++" },
  { value: "docker", label: "Docker" },
  { value: "django", label: "Django" },
  { value: "mongodb", label: "MongoDB" },
  { value: "spring-boot", label: "Spring Boot" },
  { value: "linux", label: "Linux" },
  { value: "java", label: "Java" },
  { value: "multithreading", label: "Multithreading" },
  { value: "html", label: "HTML" },
  { value: "hadoop", label: "Hadoop" },
  { value: "cucumber", label: "Cucumber" },
  { value: "microservices", label: "Microservices" },
  { value: "python", label: "Python" },
  { value: "linked-list", label: "Linked List" },
  { value: "sql", label: "SQL" },
  { value: "ssis", label: "SSIS" },
  { value: "kafka", label: "Kafka" },
  { value: "jcl", label: "JCL" },
  { value: "iot", label: "IoT" },
  { value: "golang", label: "Golang" },
  { value: "ionic", label: "Ionic" },
  { value: "algorithm", label: "Algorithm" },
  { value: "design-patterns", label: "Design Patterns" },
  { value: "junit", label: "JUnit" },
  { value: "sap-hana", label: "SAP HANA" },
  { value: "mvc", label: "MVC" },
  { value: "db2", label: "DB2" },
  { value: "elasticsearch", label: "Elasticsearch" },
  { value: "sql-query", label: "SQL Query" },
  { value: "c-sharp", label: "C#" },
  { value: "web-api", label: "Web API" },
  { value: "laravel", label: "Laravel" },
  { value: "kubernetes", label: "Kubernetes" },
  { value: "aws", label: "AWS" },
  { value: "git", label: "Git" },
  { value: "dbms", label: "DBMS" },
  { value: "azure", label: "Azure" },
  { value: "angularjs", label: "AngularJS" },
  { value: "ccna", label: "CCNA" },
  { value: "entity-framework", label: "Entity Framework" },
  { value: "mulesoft", label: "Mulesoft" },
  { value: "mobile-testing", label: "Mobile Testing" },
  { value: "snowflake", label: "Snowflake" },
  { value: "selenium", label: "Selenium" },
  { value: "react", label: "React" },
  { value: "troubleshooting", label: "Troubleshooting" },
  { value: "vue-js", label: "Vue.js" },
  { value: "node-js", label: "Node.js" },
  { value: "oops", label: "OOPs" },
  { value: "mysql", label: "MySQL" },
  { value: "operating-system", label: "Operating System" },
  { value: "pl-sql", label: "PL/SQL" },
  { value: "automation-testing", label: "Automation Testing" },
  { value: "spark", label: "Spark" },
  { value: "bootstrap", label: "Bootstrap" },
  { value: "java-collections", label: "Java Collections" },
  { value: "data-warehouse", label: "Data Warehouse" },
  { value: "spring-security", label: "Spring Security" },
  { value: "qa", label: "QA" },
  { value: "functional-testing", label: "Functional Testing" },
  { value: "ci-cd", label: "CI/CD" },
  { value: "sre", label: "SRE" },
  { value: "data-structure", label: "Data Structure" },
  { value: "machine-learning", label: "Machine Learning" },
  { value: "dot-net", label: ".NET" },
  { value: "java-8", label: "Java 8" },
  { value: "networking", label: "Networking" },
  { value: "android", label: "Android" },
  { value: "rest-api", label: "REST API" },
  { value: "data-science", label: "Data Science" },
  { value: "spring", label: "Spring" },
  { value: "embedded-c", label: "Embedded C" },
  { value: "pyspark", label: "PySpark" },
  { value: "performance-testing", label: "Performance Testing" },
  { value: "system-design", label: "System Design" },
  { value: "front-end-developer", label: "Front-End Developer" },
  { value: "software-engineering", label: "Software Engineering" },
  { value: "probability", label: "Probability" },
  { value: "lwc", label: "LWC" },
  { value: "css", label: "CSS" },
  { value: "react-native", label: "React Native" },
  { value: "tableau", label: "Tableau" },
  { value: "jenkins", label: "Jenkins" },
  { value: "software-testing", label: "Software Testing" },
  { value: "angular-8", label: "Angular 8" },
  { value: "cloud-computing", label: "Cloud Computing" },
  { value: "big-data", label: "Big Data" },
  { value: "servicenow", label: "ServiceNow" },
  { value: "kotlin", label: "Kotlin" },
  { value: "aem", label: "AEM" },
  { value: "ios", label: "iOS" },
  { value: "bgp", label: "BGP" },
  { value: "artificial-intelligence", label: "Artificial Intelligence" },
  { value: "tosca", label: "Tosca" },
  { value: "nlp", label: "NLP" },
  { value: "sdet", label: "SDET" },
  { value: "power-apps", label: "Power Apps" },
  { value: "openshift", label: "OpenShift" },
  { value: "full-stack-developer", label: "Full-Stack Developer" },
  { value: "low-level-design", label: "Low-Level Design" },
  { value: "wordpress", label: "WordPress" },
  { value: "sql-joins", label: "SQL Joins" },
  { value: "computer-science", label: "Computer Science" },
  { value: "aws-lambda", label: "AWS Lambda" },
  { value: "gcp", label: "GCP" },
  { value: "linear-regression", label: "Linear Regression" },
  { value: "star", label: "Star" },
  { value: "pandas", label: "Pandas" },
  { value: "devops", label: "DevOps" },
  { value: "web-services", label: "Web Services" },
  { value: "data-analyst", label: "Data Analyst" },
  { value: "api-testing", label: "API Testing" },
  { value: "etl-testing", label: "ETL Testing" },
  { value: "sdlc", label: "SDLC" },
  { value: "unix", label: "Unix" },
  { value: "typescript", label: "TypeScript" },
  { value: "flutter", label: "Flutter" },
  { value: "vmware", label: "VMware" },
  { value: "informatica", label: "Informatica" },
  { value: "deep-learning", label: "Deep Learning" },
  { value: "terraform", label: "Terraform" },
  { value: "wpf", label: "WPF" },
  { value: "robot-framework", label: "Robot Framework" },
  { value: "swift", label: "Swift" },
  { value: "teradata", label: "Teradata" },
  { value: "technical-support", label: "Technical Support" },
  { value: "array", label: "Array" },
  { value: "hashmap", label: "Hashmap" },
  { value: "ruby-on-rails", label: "Ruby on Rails" },
  { value: "java-programming", label: "Java Programming" },
  { value: "numpy", label: "NumPy" },
  { value: "engineering-manager", label: "Engineering Manager" },
  { value: "jpa", label: "JPA" },
  { value: "behavioral", label: "Behavioral" },
  { value: "talend", label: "Talend" },
  { value: "jquery", label: "jQuery" },
  { value: "appium", label: "Appium" },
  { value: "qlikview", label: "QlikView" },
  { value: "manual-testing", label: "Manual Testing" },
  { value: "exception-handling", label: "Exception Handling" },
  { value: "statistics", label: "Statistics" },
  { value: "power-bi", label: "Power BI" },
  { value: "cyber-security", label: "Cyber Security" },
  { value: "uipath", label: "UiPath" },
  { value: "j2ee", label: "J2EE" },
  { value: "data-modelling", label: "Data Modelling" },
  { value: "uft", label: "UFT" },
  { value: "sccm", label: "SCCM" },
  { value: "sitecore", label: "Sitecore" },
  { value: "sap-security", label: "SAP Security" },
  { value: "checkpoint", label: "Checkpoint" },
  { value: "webmethods", label: "WebMethods" },
  { value: "qc", label: "QC" },
  { value: "java-string", label: "Java String" },
  { value: "application-support", label: "Application Support" },
  { value: "sap-basis", label: "SAP Basis" },
  { value: "magento-2", label: "Magento 2" },
  { value: "struts", label: "Struts" },
  { value: "salesforce-admin", label: "Salesforce Admin" },
  { value: "agile-testing", label: "Agile Testing" },
  { value: "vsam", label: "VSAM" },
  { value: "web-designer", label: "Web Designer" },
  { value: "azure-data-factory", label: "Azure Data Factory" },
  { value: "perl", label: "Perl" },
  { value: "as400", label: "AS400" },
  { value: "azure-databricks", label: "Azure Databricks" },
  { value: "r", label: "R" },
  { value: "loadrunner", label: "LoadRunner" },
  { value: "hive", label: "Hive" },
  { value: "matlab", label: "MATLAB" },
  { value: "data-engineer", label: "Data Engineer" },
  { value: "excel", label: "Excel" },
  { value: "cics", label: "CICS" },
  { value: "agile", label: "Agile" },
  { value: "sap-mm", label: "SAP MM" },
  { value: "vb-net", label: "VB.NET" },
  { value: "asp-net", label: "ASP.NET" }
];

const jobpackage = [
  { value: "4lpa", label: "< 4 LPA" },
  { value: "7lpa", label: "4 - 7 LPA" },
];

const PersonalInterview = ({
  setSelectedTab,
  setSelectedOption,
  selectedInterviewType,
}) => {
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [showInterviewPopup, setShowInterviewPopup] = useState(false);
  const [showInterviewBestOfLuckPopup, setShowInterviewBestOfLuckPopup] =
    useState(false);
  const [showInterviewStartPopup, setShowInterviewStartPopup] = useState(false);
  const [showInterviewPlatformPopup, setShowInterviewPlatformPopup] =
    useState(false);
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedLevel, setSelectedLevel] = useState("");
  const [selectedQuestionLevel, setSelectedQuestionLevel] = useState("");
  const [selectedSkill, setSelectedSkill] = useState("");
  const [selectedPackage, setSelectedPackage] = useState("");
  const [resume, setResume] = useState("");
  const [examId, setExamId] = useState("");
  const [questions, setQuestions] = useState([]);
  const [examData, setExamData] = useState({});

  const goToAllInterview = () => {
    setSelectedTab("interviewHome");
  };

  const goToFeedback = () => {
    setSelectedTab("interviewFeedback");
  };

  const goToHome = () => {
    setSelectedOption({
      value: "skillbox",
      label: "Skillbox",
    });
  };

  const getRoles = async () => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/api/interview_prep/get_all_interview_preparation_roles`,
        "GET",
        null,
        {
          "Content-Type": "application/json",
          "Refresh-Token": auth.refresh_token,
          Authorization: auth.token,
        }
      );
      if (responseData.data.length === 0) {
        swal.fire({
          title: "No roles found",
          icon: "error",
          confirmButtonText: "OK",
        });
        return;
      } else {
        const roles = responseData.data.map((role) => {
          return { value: role.id, label: role.role_name };
        });
        setRoles(roles);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getRoles();
  }, []);

  const getInterviewData = async (examId) => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/api/interview_prep/get_interview_preperation_exam_details/${examId}`,
        "GET",
        null,
        {
          "Content-Type": "application/json",
          "Refresh-Token": auth.refresh_token,
          Authorization: auth.token,
        }
      );
      if (responseData.data.length === 0) {
        swal.fire({
          title: "No questions found",
          icon: "error",
          confirmButtonText: "OK",
        });
        return;
      } else {
        const questionData = responseData.data.questions.map((question, i) => {
          return {
            id: i + 1,
            question_id: question.question_id,
            question: question.question,
          };
        });
        setQuestions(questionData);
        setExamData(responseData.data.exam);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const startInterviewApi = async (role, level) => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/api/interview_prep/start_interview_preparation_exam`,
        "POST",
        JSON.stringify({
          role_id: role,
          level: level,
        }),
        {
          "Content-Type": "application/json",
          "Refresh-Token": auth.refresh_token,
          Authorization: auth.token,
        }
      );
      const exam_id = responseData.data;
      await getInterviewData(exam_id);
      setExamId(responseData.data);
    } catch (error) {
      console.log(error);
    }
  };

  const startInterviewApiNew = async (
    role = null,
    level = null,
    joblevel = null,
    skill = null,
    resume = null,
    companypackage = null
  ) => {
    try {
      const formData = new FormData();
      formData.append("type", selectedInterviewType.type_slug);
      formData.append("role_id", role);
      formData.append("level", level);
      formData.append("joblevel", joblevel);
      formData.append("skill", skill);
      if (resume) {
        formData.append("resume", resume);
      }
      formData.append("companypackage", companypackage);
  
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/api/interview_prep/start_interview_preparation_exam`,
        "POST",
        formData,
        {
          "Refresh-Token": auth.refresh_token,
          Authorization: auth.token,
        }
      );
  
      const exam_id = responseData.data;
      await getInterviewData(exam_id);
      setExamId(responseData.data);
    } catch (error) {
      console.log(error);
    }
  };
  

  const startInterview = (role, level) => {
    startInterviewApi(role, level);
    setShowInterviewPopup(true);
    setShowInterviewStartPopup(true);
  };

  const startInterviewHandler = ({role, level, joblevel, skill, resume, companypackage }) => {
    startInterviewApiNew(role, level, joblevel, skill, resume, companypackage);
    setShowInterviewPopup(true);
    setShowInterviewStartPopup(true);
  };

  const closeInterviewPopup = () => {
    setShowInterviewPopup(false);
    setShowInterviewStartPopup(false);
    setShowInterviewBestOfLuckPopup(false);
    setShowInterviewPlatformPopup(false);
  };

  return (
    <div className="personal-interview-page">
      {showInterviewPopup && examData && (
        <Popup
          show={true}
          onClose={closeInterviewPopup}
          title={
            showInterviewStartPopup &&
            `Attempt: ${examData.attempt_number} | Role: ${examData.role} | Level : ${examData.level}`
          }
        >
          {showInterviewStartPopup && (
            <InterviewStart
              totalNoOfQuestions={questions.length}
              setShowInterviewBestOfLuckPopup={setShowInterviewBestOfLuckPopup}
              setShowInterviewStartPopup={setShowInterviewStartPopup}
              examId={examId}
              setQuestions={setQuestions}
            />
          )}
          {showInterviewBestOfLuckPopup && (
            <BestOfLuck
              setShowInterviewPlatformPopup={setShowInterviewPlatformPopup}
              setShowInterviewBestOfLuckPopup={setShowInterviewBestOfLuckPopup}
            />
          )}
          {showInterviewPlatformPopup && (
            <InterviewPlatform
              questions={questions}
              examId={examId}
              setSelectedTab={setSelectedTab}
            />
          )}
        </Popup>
      )}
      <div className="personal-interview-page-nav-menu">
        <div
          onClick={goToHome}
          className="personal-interview-page-nav-menu-home"
        >
          Home
        </div>
        &nbsp; &gt; &nbsp;
        <div
          onClick={goToAllInterview}
          className="interview-feedback-page-nav-menu-interview-preperation"
        >
          All Interview
        </div>
      </div>
      <div className="personal-interview-container">
        <div className="personal-interview-header">
          <div className="personal-interview-header-title">
            {selectedInterviewType && selectedInterviewType.title}
          </div>
        </div>
        <div className="personal-interview-body">
          <div className="personal-interview-body-1-section-2-2">
            <div className="personal-interview-title">
              Embrace the Future of Training and Education
            </div>
            <div className="personal-interview-subtitle">
              Try interacting with the AI like you would in an interview to see
              what we have in store for you.
            </div>
          </div>
          <div className="personal-interview-body-1">
            <div className="personal-interview-body-1-section-2">
              <div className="personal-interview-body-1-section-2-1">
                <div className="personal-interview-cover-list">
                  <TickSvg />
                  {selectedInterviewType &&
                    selectedInterviewType.keyPoints[0]}{" "}
                </div>
                <div className="personal-interview-cover-list">
                  <TickSvg />
                  {selectedInterviewType &&
                    selectedInterviewType.keyPoints[1]}{" "}
                </div>
                <div className="personal-interview-cover-list">
                  <TickSvg />
                  {selectedInterviewType &&
                    selectedInterviewType.keyPoints[2]}{" "}
                </div>
                <div className="personal-interview-cover-list">
                  <TickSvg />
                  {selectedInterviewType &&
                    selectedInterviewType.keyPoints[3]}{" "}
                </div>
              </div>
            </div>
            <div className="personal-interview-body-1-section-1">
              <img
                src={selectedInterviewType && selectedInterviewType.cover}
                alt="rolecover"
              />
            </div>
          </div>
          <div className="personal-interview-body-2">
            <div className="personal-interview-body-2-1">
              <div className="personal-interview-title">
                {selectedInterviewType &&
                  selectedInterviewType.InterviewStartTitle}
              </div>
            </div>
            {selectedInterviewType &&
              (selectedInterviewType.type_slug === "roleBasedInterview" ? (
                <RoleBasedInterview
                  isLoading={isLoading}
                  error={error}
                  roles={roles}
                  level={level}
                  setSelectedRole={setSelectedRole}
                  setSelectedLevel={setSelectedLevel}
                  selectedRole={selectedRole}
                  selectedLevel={selectedLevel}
                  startInterviewHandler={startInterviewHandler}
                />
              ) : selectedInterviewType &&
                selectedInterviewType.type_slug === "skillBasedInterview" ? (
                <SkillBasedInterview
                  skills={skills}
                  level={questionLevel}
                  setSelectedRole={setSelectedSkill}
                  setSelectedLevel={setSelectedQuestionLevel}
                  selectedSkill={selectedSkill}
                  selectedLevel={selectedQuestionLevel}
                  startInterviewHandler={startInterviewHandler}
                />
              ) : selectedInterviewType &&
                selectedInterviewType.type_slug === "communicationBasedInterview" ? (
                <CommunicationBasedInterview 
                  level={questionLevel}
                  setSelectedLevel={setSelectedQuestionLevel}
                  selectedLevel={selectedQuestionLevel}
                  startInterviewHandler={startInterviewHandler}
                />
              ): selectedInterviewType &&
                selectedInterviewType.type_slug === "placementBasedInterview" ? (
                <PlacementPrepInterview
                  jobpackage={jobpackage}
                  setSelectedPackage={setSelectedPackage}
                  selectedPackage={selectedPackage}
                  startInterviewHandler={startInterviewHandler}
                />
              ) : selectedInterviewType &&
                selectedInterviewType.type_slug === "profileBasedInterview" ? (
                <ProfileBasedInterview
                  selectedResume={resume}
                  setSelectedResume={setResume}
                  startInterviewHandler={startInterviewHandler}
                />
              ) :
              null)}
          </div>
          <div className="personal-interview-body-3">
            <div
              onClick={goToFeedback}
              className="personal-interview-body-3-navigation"
            >
              View Previous Interview Feedback
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalInterview;
