import React, { useState } from "react";

const BulletPointsSelect = ({ options, onSelect }) => {
  const [isActive, setIsActive] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  const filteredOptions = options.filter((option) =>
    option.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const toggleOption = (option) => {
    if (selectedOption && selectedOption.id === option.id) {
      setSelectedOption(null);
    } else {
      setSelectedOption(option);
    }
    onSelect(option);
    setIsActive(false);
  };

  return (
    <div className={`new-custom-select ${isActive ? "active" : ""}`}>
      <button
        className="new-select-button"
        role="combobox"
        aria-labelledby="select button"
        aria-haspopup="listbox"
        aria-expanded={isActive}
        onClick={() => setIsActive(!isActive)}
      >
        <span className="selected-value">
          {selectedOption ? selectedOption.name : "Select an option"}
        </span>
        {/* Dropdown Arrow */}
        <span className="arrow">
          <svg
            width="12"
            height="8"
            viewBox="0 0 12 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 1.5L6 6.5L11 1.5"
              stroke="#777777"
              strokeWidth="1.66667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </span>
      </button>

      <ul className="select-from-dropdown" role="listbox">
        <li className="search-box">
          <input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="search-input"
          />
        </li>
        <div className="options-container">
          {filteredOptions.map((option) => (
            <li
              key={option.id}
              role="option"
              onClick={() => toggleOption(option)}
            >
              <label htmlFor={option.id}>{option.name}</label>
            </li>
          ))}
        </div>
      </ul>
    </div>
  );
};

export default BulletPointsSelect;
