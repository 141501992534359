import React from "react";

const RaiseDoubt = () => {
    return (
        <svg width="17" height="17" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="sk-raise-doubt-icon" >
            <path d="M10.05 4.575C10.05 4.36817 10.0093 4.16336 9.93011 3.97227C9.85096 3.78119 9.73495 3.60756 9.58869 3.46131C9.44244 3.31505 9.26881 3.19904 9.07773 3.11989C8.88664 3.04074 8.68183 3 8.475 3C8.26817 3 8.06336 3.04074 7.87227 3.11989C7.68119 3.19904 7.50756 3.31505 7.36131 3.46131C7.21505 3.60756 7.09904 3.78119 7.01989 3.97227C6.94074 4.16336 6.9 4.36817 6.9 4.575V7.575M10.05 4.575V3.075C10.05 2.65728 10.2159 2.25668 10.5113 1.96131C10.8067 1.66594 11.2073 1.5 11.625 1.5C12.0427 1.5 12.4433 1.66594 12.7387 1.96131C13.0341 2.25668 13.2 2.65728 13.2 3.075V4.575M10.05 4.575L10.125 10.5M6.9 7.575C6.9 7.15728 6.73406 6.75668 6.43869 6.46131C6.14332 6.16594 5.74272 6 5.325 6C4.90728 6 4.50668 6.16594 4.21131 6.46131C3.91594 6.75668 3.75 7.15728 3.75 7.575V15.75C3.75 17.5402 4.46116 19.2571 5.72703 20.523C6.9929 21.7888 8.70979 22.5 10.5 22.5H12.518C13.9103 22.4998 15.2456 21.9466 16.23 20.962L17.962 19.23C18.9466 18.2456 19.4998 16.9103 19.5 15.518L19.503 13.494C19.504 13.317 19.5752 13.1476 19.701 13.023C19.8473 12.8767 19.9633 12.703 20.0425 12.5119C20.1217 12.3208 20.1624 12.1159 20.1624 11.909C20.1624 11.7021 20.1217 11.4972 20.0425 11.3061C19.9633 11.115 19.8473 10.9413 19.701 10.795C19.5547 10.6487 19.381 10.5327 19.1899 10.4535C18.9988 10.3743 18.7939 10.3336 18.587 10.3336C18.3801 10.3336 18.1752 10.3743 17.9841 10.4535C17.793 10.5327 17.6193 10.6487 17.473 10.795C16.7595 11.5076 16.3568 12.4736 16.353 13.482M6.9 7.575V12M13.2 4.575V11.25M13.2 4.575C13.2 4.15728 13.3659 3.75668 13.6613 3.46131C13.9567 3.16594 14.3573 3 14.775 3C15.1927 3 15.5933 3.16594 15.8887 3.46131C16.1841 3.75668 16.35 4.15728 16.35 4.575V15M16.35 15C15.7593 14.9997 15.1744 15.116 14.6287 15.3421C14.083 15.5683 13.5873 15.8999 13.17 16.318M16.35 15H16.352" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export default RaiseDoubt;
