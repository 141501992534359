import React, { useState } from 'react';
import "./DropdownButton.css";
import DropdownSvg from "../../assets/svgs/DropdownSvg";

const DropdownButton = ({ generatePDF, generateDOCX }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (action) => {
    action();
    setIsOpen(false);
  };

  return (
    <div className="resume-gen-download-dropdown">
      <button className="resume-gen-download-dropdown-button" onClick={toggleDropdown}>
        Download As<DropdownSvg />
      </button>
      {isOpen && (
        <div className="resume-gen-download-dropdown-menu">
          <div
            className="resume-gen-download-dropdown-menu-item"
            onClick={() => handleOptionClick(generatePDF)}
          >
            Pdf
          </div>
          <div
            className="resume-gen-download-dropdown-menu-item"
            onClick={() => handleOptionClick(generateDOCX)}
          >
            Doc
          </div>
        </div>
      )}
    </div>
  );
};

export default DropdownButton;
