import React, { useEffect } from "react";
import PopUp from "../UiComponents/Popup/PopUp";
import "./EditModule.css";
import { useState, useContext } from "react";
import { AdminContext } from "../../../../context/admin-context";
import { useHttpClient } from "../../../../Components/Hooks/http-hook";
import LoadingSpinner from "../../../../Components/Input/LoadingSpinner";
const swal = require("sweetalert2");

const EditModule = ({ module, showPopup, setShowPopup, onEdit }) => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const admin = useContext(AdminContext);

  const [buttonLoading, setButtonLoading] = useState(false);

  const initialModuleState = {
    module_name: module.module_name,
    module_description: module.module_description,
    module_lock: module.module_lock,
    module_order: module.module_order,
  };

  const [moduleData, setModuleData] = useState(initialModuleState);

  const [validationErrors, setValidationErrors] = useState({
    module_name: false,
    module_description: false,
    module_order: false,
  });

  const resetForm = () => {
    setModuleData(initialModuleState);
    setValidationErrors({
      module_name: false,
      module_description: false,
      module_order: false,
    });
  };

  useEffect(() => {
    setModuleData(initialModuleState);
  }, [module]);

  const validateForm = () => {
    const requiredFields = ["module_name", "module_description", "module_order"];
    const newValidationErrors = {};

    requiredFields.forEach((field) => {
      if (!moduleData[field]) {
        newValidationErrors[field] = true;
      }
    });

    setValidationErrors((prev) => ({
      ...prev,
      ...newValidationErrors,
    }));

    return Object.keys(newValidationErrors).length === 0;
  };

  const handlechange = (e) => {
    const { name, value } = e.target;

    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: false,
    }));

    setModuleData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      setButtonLoading(false);
      return;
    }
    setButtonLoading(true);
    try {
      const response = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/db/module/${module.module_id}`,
        "PUT",
        JSON.stringify(moduleData),
        {
          "Content-Type": "application/json",
          "Refresh-Token": admin.refresh_token,
          Authorization: admin.token,
        }
      );
      if (response) {
        setButtonLoading(false);
        onEdit({
          ...module,
          ...moduleData,
        });
        setShowPopup(false);
        swal.fire({
          title: "Module Updated Successfully",
          icon: "success",
          confirmButtonText: "Okay",
        });
      }
    } catch (err) {
      setButtonLoading(false);
      swal.fire({
        title: "Error",
        text: err.message || "Something went wrong",
        icon: "error",
        confirmButtonText: "Okay",
      });
    }
  };

  useEffect(() => {
    if (error) {
      setButtonLoading(false);
      setShowPopup(false);
      swal.fire({
        title: "Error!",
        text: error || "Something went wrong, please try again later.",
        icon: "error",
        confirmButtonText: "Close",
      });
      clearError();
    }
  }
  , [error, clearError, setShowPopup]);

  return (
    <PopUp
      title={module.module_name}
      onClose={() => setShowPopup(false)}
      show={showPopup}
    >
      {buttonLoading ? (
        <div className="edit_module_popup__loading">
          <LoadingSpinner />
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="edit_module_popup__content">
            <div className="edit_module_popup__content-row">
              <div className="edit_module_popup__content-row-title">
                <p>Module ID</p>
              </div>
              <div className="edit_module_popup__content-row-value">
                <p>{module.module_id}</p>
              </div>
            </div>
            <div className="edit_module_popup__content-row">
              <div className="edit_module_popup__content-row-title">
                <p>Module Name</p>
              </div>
              <div className="edit_module_popup__content-row-value">
                <input
                  type="text"
                  defaultValue={module.module_name}
                  onChange={handlechange}
                  name="module_name"
                  className={
                    validationErrors.module_name ? "input_val_error" : ""
                  }
                />
                {validationErrors.module_name && (
                  <small className="input_val_error-text">
                    Module Name is required
                  </small>
                )}
              </div>
            </div>
            <div className="edit_module_popup__content-row">
              <div className="edit_module_popup__content-row-title">
                <p>Module Description</p>
              </div>
              <div className="edit_module_popup__content-row-value">
                <input
                  type="text"
                  defaultValue={module.module_description}
                  onChange={handlechange}
                  name="module_description"
                  className={
                    validationErrors.module_description ? "input_val_error" : ""
                  }
                />
                {validationErrors.module_description && (
                  <small className="input_val_error-text">
                    Module Description is required
                  </small>
                )}
              </div>
            </div>
            <div className="edit_module_popup__content-row">
              <div className="edit_module_popup__content-row-title">
                <p>Is Active</p>
              </div>
              <div className="edit_module_popup__content-row-value">
                <select
                  name="module_lock"
                  defaultValue={module.module_lock}
                  onChange={handlechange}
                >
                  <option value="false">Live</option>
                  <option value="true">Not Live</option>
                </select>
              </div>
            </div>
            <div className="edit_module_popup__content-row">
              <div className="edit_module_popup__content-row-title">
                <p>Module Order</p>
              </div>
              <div className="edit_module_popup__content-row-value">
                <input
                  type="number"
                  defaultValue={module.module_order}
                  onChange={handlechange}
                  name="module_order"
                  className={
                    validationErrors.module_order ? "input_val_error" : ""
                  }
                />
                {validationErrors.module_order && (
                  <small className="input_val_error-text">
                    Module Order is required
                  </small>
                )}
              </div>
            </div>
            <div className="edit_module_popup__content-row">
              <div className="edit_module_popup__content-row-title">
                <p>Created On</p>
              </div>
              <div className="edit_module_popup__content-row-value">
                <p>{module.module_create_timestamp}</p>
              </div>
            </div>
            <div className="edit_module_popup__content-row">
              <div className="edit_module_popup__content-row-title">
                <p>Last Updated</p>
              </div>
              <div className="edit_module_popup__content-row-value">
                <p>{module.module_update_timestamp}</p>
              </div>
            </div>
          </div>
          <div className="edit_module_popup__buttons">
            <button
              className="edit_module_popup__buttons-cancel"
              type="button"
              onClick={() => {
                resetForm();
                setShowPopup(false);
              }}
            >
              Cancel
            </button>
            <button
              className="edit_module_popup__buttons-save"
              type="submit"
            >
              Save
            </button>
          </div>
        </form>
      )}
    </PopUp>
  );
};

export default EditModule;
