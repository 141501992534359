import React from "react";
import "../PAC.css";
import ViewIcon from "../PA_Components/ViewIcon";
import DeleteIcon from "../PA_Components/DeleteIcon";

const SavedResume = ({ selectedFiles, handleFileDelete, noDelete }) => {
  return selectedFiles.length > 0 ? (
    <div className="resume-list">
      <ul>
        {selectedFiles.map((fileName) => (
          <div className={`resume-list-container gray-border`}>
            <li key={fileName}>{fileName}</li>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <p>Uploaded on {`21/01/2022`}</p>

              <ViewIcon />

              {!noDelete && (
                <div
                  onClick={() => handleFileDelete(fileName)}
                >
                  <DeleteIcon />
                </div>
              )}
            </div>
          </div>
        ))}
      </ul>
    </div>
  ) : (
    <button
      className="resume-upload-button"
      style={{ width: "200px" }}
      onClick={() => window.location.reload()}
    >
      Add New
    </button>
  );
};

export default SavedResume;
