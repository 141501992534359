import React, { useState, useContext, useEffect } from "react";
import "./InterviewAttempted.css";
import { AuthContext } from "../../../../../../../context/auth-context";
import { useHttpClient } from "../../../../../../../Components/Hooks/http-hook";
import Loading from "../../../../../../../Components/Loading";
import moment from "moment";

const InterviewAttempted = ({ handleShowScoreDashboard }) => {
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [interviews, setInterviews] = useState([]);
  const [lastUpdated, setLastUpdated] = useState("");

  useEffect(() => {
    const fetchInterviews = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/api/interview_prep/get_interview_exam_attempts`,
          "GET",
          null,
          {
            "Content-Type": "application/json",
            "Refresh-Token": auth.refresh_token,
            Authorization: auth.token,
          }
        );
        if (
          responseData.exams &&
          responseData.exams.length > 0 &&
          responseData.last_updated
        ) {
          setInterviews(responseData.exams);
          setLastUpdated(changeDateFormat(responseData.last_updated));
        }
      } catch (err) {
        console.log(err);
      }
    };
    fetchInterviews();
  }, []);

  const changeDateFormat = (date) => {
    return moment(date).format("DD/MM/YYYY");
  };

  return (
    <div>
      <div className="interview-feedback-header">
        <div className="interview-feedback-header-title">
          Interview Attempted
        </div>
        <div className="interview-feedback-body-last-updated">
          Last Updated: {lastUpdated}
        </div>
      </div>
      {(isLoading || error) && (
        <div className="interview-feedback-body">
          <div className="interview-feedback-section">
            {isLoading && (
            <Loading color="#004c73" customClass="interview-feedback-interview-loading"/>
            )}
            {error && <div className="interview-questions-not-found-section">
              Something went wrong, Please try again later
            </div>
            }
          </div>
        </div>
      )}
      {!isLoading && !error && interviews && interviews.length === 0 && (
        <div className="interview-feedback-body">
          <div className="interview-feedback-section">
            <div className="interview-questions-not-found-section">
              No Interview Attempted
            </div>
          </div>
        </div>
      )}
      {interviews && interviews.length > 0 && (
        <div className="interview-feedback-body">
          <div className="interview-feedback-section">
            <div className="interview-feedback-table">
              <div className="interview-feedback-table-header">
                <div className="interview-feedback-table-header-job-role">
                  Exam ID
                </div> 
                <div className="interview-feedback-table-header-marks">
                  Job Role
                </div>
                <div className="interview-feedback-table-header-level">
                  Level
                </div>
                <div className="interview-feedback-table-header-attempt">
                  Attempt
                </div>
                {/* <div className="interview-feedback-table-header-marks">
                  Marks
                </div> */}
              </div>
              <div className="interview-feedback-hr-line"></div>
              <div className="interview-feedback-table-body">
                {interviews.map((data) => (
                  <div
                    key={data.id}
                    className="interview-feedback-table-row"
                    // onClick={() => handleShowScoreDashboard(data.exam_id)}
                  >
                    <div className="interview-feedback-table-job-role">
                      {data.exam_id}
                    </div>
                    <div className="interview-feedback-table-marks">
                      {data.role}
                    </div>
                    <div className="interview-feedback-table-level">
                      {data.level}
                    </div>
                    <div className="interview-feedback-table-attempt">
                      {data.attempt_number}
                    </div>
                    {/* <div className="interview-feedback-table-marks">
                      {data.obtained_score}
                    </div> */}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default InterviewAttempted;
