import React from "react";

const UndoSvg = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.2209 20.9996C19.9925 21.0062 19.7686 20.9345 19.5867 20.7962C19.4047 20.6579 19.2757 20.4615 19.2209 20.2396C18.7706 18.4556 17.7791 16.8547 16.3825 15.6568C14.9859 14.459 13.2527 13.7229 11.4209 13.5496V14.6696C11.4207 15.0199 11.3172 15.3623 11.1233 15.654C10.9294 15.9458 10.6538 16.1738 10.3309 16.3096C9.98076 16.4705 9.59157 16.5269 9.21015 16.4719C8.82872 16.4169 8.47131 16.2529 8.1809 15.9996L3.1209 11.5896C2.92687 11.4244 2.77103 11.219 2.66417 10.9876C2.5573 10.7562 2.50195 10.5044 2.50195 10.2496C2.50195 9.99474 2.5573 9.74293 2.66417 9.51157C2.77103 9.2802 2.92687 9.07481 3.1209 8.90959L8.1809 4.48959C8.47513 4.2319 8.83846 4.06614 9.22593 4.01282C9.6134 3.95949 10.008 4.02095 10.3609 4.18959C10.6838 4.32537 10.9594 4.5534 11.1533 4.84513C11.3472 5.13686 11.4507 5.4793 11.4509 5.82959V6.99959C14.1671 7.20959 16.706 8.43006 18.5665 10.4201C20.427 12.4102 21.474 15.0254 21.5009 17.7496C21.4995 18.5891 21.3954 19.4253 21.1909 20.2396C21.1362 20.4615 21.0071 20.6579 20.8251 20.7962C20.6432 20.9345 20.4193 21.0062 20.1909 20.9996H20.2209Z" fill="#777777"/>
        </svg>
    );
}

export default UndoSvg;