import React, { useState, useEffect } from 'react';
import { useContext } from 'react';
import DataTable, { Alignment } from "react-data-table-component";
import ViewSvg from "../../../../Components/Svgs/ViewSvg";
import View from './Actions/View/View';
import { useHttpClient } from "../../../../../../../Components/Hooks/http-hook";
import { AuthContext } from "../../../../../../../context/auth-context";
import "./MyHistory.css";

const customStyles = {
    headCells: {
        style: {
            padding: '0px',
            fontSize: '16px',
            fontWeight: '600',
            color: '#004C73',
        },
    },
    rows: {
        style: {
            borderBottom: 'none',
        },
        highlightOnHoverStyle: {
            backgroundColor: '#e0e0e0',
        },
    },
    cells: {
        style: {
            borderBottom: 'none',
            padding: '0px',
        },
    },
    table: {
        style: {
            borderRadius: '20px',
            overflow: 'scroll',
            padding: '0px 20px',
            scrollbarWidth: "none", 
        },
    },
    pagination: {
        style: {
            marginTop: '20px',
            border: 'none',
            backgroundColor: 'none',
        },
        pageButtonsStyle: {
            backgroundColor: '#fff',
            borderRadius: '5px',
            marginRight: '5px',
            border: '1px solid #004C73',
            cursor: 'pointer',
            svg: {
                fill: '#004C73',
            },
            '&:hover': {
                backgroundColor: '#D2F0FF',
                svg: {
                    fill: '#004C73',
                },
            },
            '&:disabled': {
                backgroundColor: '#fff',
                border: '1px solid #ccc',
                svg: {
                    fill: '#ccc',
                },
                '&:hover': {
                    backgroundColor: '#fff',
                }
            },
            '&:active': {
                backgroundColor: '#D2F0FF',
                svg: {
                    fill: '#fff',
                },
            },
        }
    },
};

const MyHistory = ({handleTabChange, setSelectedRolesData, setSelectedCoursesData}) => {
    const columns = [
        {
            name: "Sr. No.",
            cell: (row, index) => index + 1,
            width: "100px",
            sortable: true,
        },
        {
            name: "Type",
            selector: row => row.type,
            sortable: true,
        },
        {
            name: "Title",
            selector: row => row.title,
            sortable: true,
            width: "250px",
        },
        {
            name: "No. of Questions",
            selector: row => row.questionCount,
            sortable: true,
            center: "true",
        },
        {
            name: "Created On",
            selector: row => row.createdOn,
            sortable: true,
            center: "true",
        },
        {
            name: "View Details",
            cell: (row) => (
                <div>
                    <div className="StudentViewIcon" onClick={() => viewInterviewDetails(row)}>
                    <ViewSvg />
                    </div>
                </div>
            ),
            center: "true",
        },
    ];
    const [searchText, setSearchText] = useState('');
    const [showViewPopup, setShowViewPopup] = useState(false);
    const [selectedStudent, setSelectedStudent] = useState({});
    const [historyData, setHistoryData] = useState([]);
    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const auth = useContext(AuthContext);

    useEffect(() => {
        const fetchMyHistory = async () => {
            try {
                const responseData = await sendRequest(
                    `${process.env.REACT_APP_BACKEND_URL}/api/qgt/my-history`,
                    "GET",
                    null,
                    {
                        "Content-Type": "application/json",
                        Authorization: auth.token,
                    }
                );
                if (responseData.ok) {
                    setHistoryData(responseData.data);
                }
            } catch (err) {
                console.error(err);
            }
        };

        fetchMyHistory();
    }, [sendRequest, auth.token]);

    const handleSearch = (event) => {
        setSearchText(event.target.value);
    };

    const viewStudent = (data) => {
        setSelectedStudent(data);
        setShowViewPopup(true);
    };

    const viewInterviewDetails = (data) => {
        if(data.type === "Role Based"){
            setSelectedRolesData({
                ...data,
                id: data.role_id
            });
            handleTabChange('roleshistoryhome');
        }   
        else if(data.type === "Course Based"){
            setSelectedCoursesData({
                ...data,
                id: data.course_id
            });
            handleTabChange('coursehistoryhome');
        }
    };

    const filteredData = historyData.filter(item =>
        item.title.toLowerCase().includes(searchText.toLowerCase()) ||
        item.type.toLowerCase().includes(searchText.toLowerCase()) ||
        item.id.toString().includes(searchText) ||
        item.generated_question_count.toString().includes(searchText) ||
        item.create_timestamp.toLowerCase().includes(searchText.toLowerCase())
    );

    return (
        <div className="students-container">
            <View
                show={showViewPopup}
                setShow={setShowViewPopup}
                studentData={selectedStudent}
            />
            <div className="datatable-wrapper">
                {isLoading ? (
                    <div>Loading...</div>
                ) : error ? (
                    <div>Error: {error}</div>
                ) : (
                    <DataTable
                        columns={columns}
                        data={filteredData}
                        fixedHeader
                        fixedHeaderScrollHeight="70vh"
                        pagination
                        paginationPerPage={10}
                        paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
                        customStyles={customStyles}
                    />
                )}
            </div>
        </div>
    );
}

export default MyHistory;
