import React, { useState, useEffect, useContext } from "react";
import DataTable, { Alignment } from "react-data-table-component";
import CalendarSvg from "../../../Components/Svgs/CalendarSvg";
import CustomPieChart from "../../../Components/Charts/CustomPieChart";
import CustomBarChart from "../../../Components/Charts/CustomBarChart";
import NewCandidatesSvg from "../../../Components/Svgs/NewCandidatesSvg";
import TextColorTag from "../../../Components/Tags/TextColorTag";
import InfoBox from "../../../Components/InfoBox/InfoBox";
import "./Dashboard.css";
import { AuthContext } from "../../../../../../context/auth-context";
import { useHttpClient } from "../../../../../../Components/Hooks/http-hook";

const barchartData = [
  { name: "Data Science", value: 600 , color: "#FA1B00", backgroundColor: "#FFE1DE"},
  { name: "AI/ML", value: 400 , color: "#CD7B00", backgroundColor: "#FFF5DB"},
  { name: "Python", value: 380 , color: "#1F7A08", backgroundColor: "#D9FFE3"},
  { name: "DevOps", value: 450 , color: "#004C73", backgroundColor: "#E4F6FF"},
];

const data = [
  {
    roleId: "2jlkjlkj",
    name: "Data Science",
    topics_count: 10,
    subtopics_count: 10,
    questions_count: 200,
    levelwise_count: "Low (50), Medium (70), High (30)",
    date: "12/12/2021",
  },
  {
    roleId: "1jlkjlkj",
    name: "AI/ML",
    topics_count: 20,
    subtopics_count: 10,
    questions_count: 100,
    levelwise_count: "Low (50), Medium (70), High (30)",
    date: "12/12/2021",
  },
  {
    roleId: "1jlkjlkj",
    name: "Python",
    topics_count: 89,
    subtopics_count: 10,
    questions_count: 90,
    levelwise_count: "Low (50), Medium (70), High (30)",
    date: "12/12/2021",
  },
  {
    roleId: "1jlkjlkj",
    name: "DevOps",
    topics_count: 98,
    subtopics_count: 10,
    questions_count: 800,
    levelwise_count: "Low (50), Medium (70), High (30)",
    date: "12/12/2021",
  },
];

const customStyles = {
  headCells: {
    style: {
      paddingLeft: "8px",
      paddingRight: "8px",
      fontSize: "16px",
      fontWeight: "600",
      color: "#004C73",
      borderBottom: "none",
    },
  },
  rows: {
    style: {
      border: "none !important",
    },
    highlightOnHoverStyle: {
      backgroundColor: "#e0e0e0",
    },
  },
  cells: {
    // padding: "8px",
    style: {
      borderBottom: "none",
      padding: "0px",
    },
  },
  table: {
    style: {
      borderRadius: "20px",
      overflow: "scroll",
      padding: "10px 20px",
      borderBottom: "none",
      // height: "20vh",
      boxShadow: "0 0 10px rgba(0, 0, 0, 0.05)",
    },
  },
};

const DashboardOverallInfoData = [
  {
    key: "totalRoles",
    icon: <NewCandidatesSvg />,
    count: 68,
    title: "No. of roles in Database",
  },
  {
    key: "totalCourses",
    icon: <CalendarSvg />,
    count: 54,
    title: "Total no. of courses",
  },
  {
    key: "totalQuestions",
    icon: <CalendarSvg />,
    count: 166000,
    title: "Total no. of questions",
  },
];

const analyticsData = [
  {
    title: "No. of questions and roles",
    data: [
      { name: "Roles", value: 79 },
      { name: "Questions", value: 99000 },
      { name: "Topics", value: 500 },
      { name: "Subtopics", value: 1000 },
    ],
    legends: [
      { text: "Roles: 79", color: "#1F7A08", backgroundColor: "#D9FFE3" },
      { text: "Questions: 99k", color: "#FA1B00", backgroundColor: "#FFE1DE" },
      { text: "Topics: 500", color: "#CD7B00", backgroundColor: "#FFF5DB" },
      { text: "Subtopics: 1k", color: "#004C73", backgroundColor: "#E4F6FF" },
    ],
    colors: ["#1F7A08", "#FA1B00", "#CD7B00", "#004C73"],
  },
  {
    title: "Levels of questions",
    data: [
      { name: "Easy", value: 100 },
      { name: "Medium", value: 200 },
      { name: "Hard", value: 300 },
    ],
    legends: [
      { text: "Easy: 100", color: "#1F7A08", backgroundColor: "#D9FFE3" },
      { text: "Medium: 200", color: "#CD7B00", backgroundColor: "#FFF5DB" },
      { text: "Hard: 300", color: "#FA1B00", backgroundColor: "#FFE1DE" }
    ],
    colors: ["#1F7A08", "#CD7B00", "#FA1B00"],
  },
  {
    title: "Avg. Questions Success Rate",
    data: [
      { name: "Generated Questions", value: 80 },
      { name: "Rejected Questions", value: 20 }
    ],
    legends: [
      { text: "Generated Questions: 80%", color: "#FA1B00", backgroundColor: "#FFE1DE" },
      { text: "Rejected Questions: 20%", color: "#004C73", backgroundColor: "#E4F6FF" }
    ],
    colors: ["#FA1B00", "#004C73"],
  },
  {
    title: "Downloads and generated ques",
    data: [
      { name: "Generated Questions", value: 80 },
      { name: "Downloads", value: 20 }
    ],
    legends: [
      { text: "Generated Questions: 80%", color: "#FA1B00", backgroundColor: "#FFE1DE" },
      { text: "Downloads: 20%", color: "#004C73", backgroundColor: "#E4F6FF" }
    ],
    colors: ["#FA1B00", "#004C73"],
  }
];

const Dashboard = ({ setCurrentPage }) => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  const [dashboardData, setDashboardData] = useState(null);

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/api/qgt/dashboard`,
          "GET",
          null,
          {
            "Content-Type": "application/json",
            Authorization: auth.token,
          }
        );
        if (responseData.ok) {
          setDashboardData(responseData.data);
        }
      } catch (err) {
        console.log(err);
      }
    };

    fetchDashboardData();
  }, [sendRequest, auth.token]);

  if (isLoading || !dashboardData) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const columns = [
    {
      name: "Role Title",
      selector: (row) => row.name,
      width: "130px",
    },
    {
      name: "Topics Count",
      cell: (row) => (
        <TextColorTag
          text={`${row.topics_count} Topics`}
          color="#004C73"
          backgroundColor="#E4F6FF"
        />
      ),
      width: "100px",
    },
    {
      name: "Subtopics Count",
      cell: (row) => (
        <TextColorTag
          text={`${row.subtopics_count} Subtopics`}
          color="#CD7B00"
          backgroundColor="#FFF5DB"
        />
      ),
    },
    {
      name: "Questions Count",
      cell: (row) => (
        <TextColorTag
          text={`${row.questions_count} Questions`}
          color="#FA1B00"
          backgroundColor="#FFE1DE"
        />
      ),
    },
    {
      name: "Levelwise Count",
      cell: (row) => (
        <TextColorTag
          text={`Level: ${row.levelwise_count}`}
          color="#1F7A08"
          backgroundColor="#D9FFE3"
        />
      ),
      width: "270px",
    },
  ];

  const DashboardOverallInfoData = [
    {
      key: "totalRoles",
      icon: <NewCandidatesSvg />,
      count: dashboardData.overall_info.totalRoles,
      title: "No. of roles in Database",
    },
    {
      key: "totalCourses",
      icon: <CalendarSvg />,
      count: dashboardData.overall_info.totalCourses,
      title: "Total no. of courses",
    },
    {
      key: "totalQuestions",
      icon: <CalendarSvg />,
      count: dashboardData.overall_info.totalQuestions,
      title: "Total no. of questions",
    },
  ];

  const analyticsData = [
    {
      title: "No. of questions and roles",
      data: dashboardData.analytics.questions_and_roles,
      legends: [
        { text: `Roles: ${dashboardData.analytics.questions_and_roles[0].value}`, color: "#1F7A08", backgroundColor: "#D9FFE3" },
        { text: `Topics: ${dashboardData.analytics.questions_and_roles[2].value}`, color: "#CD7B00", backgroundColor: "#FFF5DB" },
        { text: `Questions: ${dashboardData.analytics.questions_and_roles[1].value}`, color: "#FA1B00", backgroundColor: "#FFE1DE" },
        // { text: `Subtopics: ${dashboardData.analytics.questions_and_roles[3].value}`, color: "#004C73", backgroundColor: "#E4F6FF" },
      ],
      colors: ["#1F7A08", "#FA1B00", "#CD7B00", "#004C73"],
    },
    {
      title: "Levels of questions",
      data: dashboardData.analytics.question_levels,
      legends: dashboardData.analytics.question_levels.map((level, index) => ({
        text: `${level.name}: ${level.value}`,
        color: ["#1F7A08", "#CD7B00", "#FA1B00"][index],
        backgroundColor: ["#D9FFE3", "#FFF5DB", "#FFE1DE"][index],
      })),
      colors: ["#1F7A08", "#CD7B00", "#FA1B00"],
    },
    {
      title: "Avg. Questions Success Rate",
      data: dashboardData.analytics.question_success_rate,
      legends: [
        { text: `Generated Ques: ${dashboardData.analytics.question_success_rate[0].value}`, color: "#FA1B00", backgroundColor: "#FFE1DE" },
        { text: `Rejected Questions: ${dashboardData.analytics.question_success_rate[1].value}`, color: "#004C73", backgroundColor: "#E4F6FF" }
      ],
      colors: ["#FA1B00", "#004C73"],
    },
    {
      title: "Downloads and generated ques",
      data: dashboardData.analytics.downloads_and_generated,
      legends: [
        { text: `Generated Ques: ${dashboardData.analytics.downloads_and_generated[0].value}`, color: "#FA1B00", backgroundColor: "#FFE1DE" },
        { text: `Downloads: ${dashboardData.analytics.downloads_and_generated[1].value}`, color: "#004C73", backgroundColor: "#E4F6FF" }
      ],
      colors: ["#FA1B00", "#004C73"],
    }
  ];

  const topRolesToShow = dashboardData.top_roles.length > 3 ? dashboardData.top_roles.slice(0, 3) : dashboardData.top_roles;

  return (
    <div>
      <div className="dashboard">
        <div className="dashboardSection1">
          <div className="dashboardSection1Container1">
            <div className="dashboardHeader">This week summary</div>
            <InfoBox InfoData={DashboardOverallInfoData} />
          </div>
          <div className="dashboardSection1Container2">
            <div className="dashboardSubHeadersContainer">
              <div className="dashboardSubHeader">Roles</div>
              <div
                className="dashboardSubHeaderClickable"
                onClick={() => setCurrentPage("Student List")}
              >
                View More
              </div>
            </div>
            <div className="dashboardTable">
              <DataTable
                columns={columns}
                data={dashboardData.top_roles}
                pagination={false}
                customStyles={customStyles}
                noTableHead
                responsive
              />
            </div>
          </div>
        </div>
        <div className="dashboardSection2">
          <div className="dashboardSection2Container1">
            <div className="dashboardSubHeader">Comparison</div>
            <div className="dashboardAnalyticsContainer">
              <div className="dashboardAnalytics">
                <div className="dashboardAnalyticsTitle">
                  Questions Generated
                </div>
                <div className="dashboardAnalyticsBarChartContainer">
                  <CustomBarChart 
                    data={topRolesToShow.map(role => ({
                      name: role.name,
                      value: role.questions_count
                    }))} 
                    colors={topRolesToShow.map(item => item.color)} 
                  />
                </div>
                <div className="dashboardAnalyticsBarChartLegendsGrid">
                  {topRolesToShow.map((item, index) => (
                    <TextColorTag
                      key={index}
                      text={`${item.name}: ${item.questions_count}`}
                      color={item.color}
                      backgroundColor={item.backgroundColor}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="dashboardSection3">
        <div className="dashboardSubHeader">Analytics</div>
        <div className="dashboardAnalyticsRowContainer">
          {analyticsData.map((item, index) => (
            <div key={index} className="dashboardAnalyticsPieChart">
              <div className="dashboardAnalyticsTitlePieChart">
                {item.title}
              </div>
              <CustomPieChart
                width={150}
                height={150}
                data={item.data}
                outerRadius={60}
                colors={item.colors}
              />
              <div className="dashboardAnalyticsPieChartLegendsColumn">
                {item.legend ? (
                  <TextColorTag
                    text={item.legend.text}
                    color={item.legend.color}
                    backgroundColor={item.legend.backgroundColor}
                  />
                ) : (
                  item.legends.map((legend, idx) => (
                    <TextColorTag
                      key={idx}
                      text={legend.text}
                      color={legend.color}
                      backgroundColor={legend.backgroundColor}
                    />
                  ))
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
