import React, { useState, useEffect, useContext } from 'react'

import './StudentInfoPop.css'
import { useHttpClient } from '../../Components/Hooks/http-hook';
import { AuthContext } from '../../context/auth-context';
import ErrorModal from "../../Components/Input/ErrorModel";
import LoadingSpinner from "../../Components/Input/LoadingSpinner";

const StudentInfoPop = (props) => {

    const [isPop, setIsPop] = useState(true)
    const { isLoading, error, sendRequest, clearError } = useHttpClient();

    const auth = useContext(AuthContext)
    const [assignmentList, setAssignmentList] = useState(null)
    const [attendanceList, setAttendanceList] = useState(null)

    const formatDate = new Intl.DateTimeFormat("en-GB" , {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        timeZone: "UTC",
        hour12: false
      });

    const getProgramAssignments = async() => {
        try {
            const responseData = await sendRequest(
              `${process.env.REACT_APP_BACKEND_URL}/s3/assignment/CMR Data Science Campus Program Batch 1/${props.studentDetails.studentId}`,
              "GET"
            );
            if (responseData.ok) {
              return responseData.data
            }
          } catch (err) {
            console.log("got error while getting assignments", err)
          }  
      }

    const getStudentAttendanceStats = async() => {
    // event.preventDefault();
    try {
        const responseData = await sendRequest(
            `${process.env.REACT_APP_BACKEND_URL}/db/attendance-stats/0004/${props.studentDetails.studentEmail}`,
            "GET",
            );

            if (responseData.ok == true) {
                return responseData.data
            } else {
                alert(responseData.msg)
            }
    }
    catch (err) {console.log(err)}
    }

    useEffect(() => {
    getProgramAssignments().then(
        responseData => setAssignmentList(responseData)
    )
    getStudentAttendanceStats().then(
        responseStudentData => setAttendanceList(responseStudentData)
    )
    }, [])

  return (
        <React.Fragment>
        <ErrorModal error={error} onClear={clearError} />
        {isLoading && <LoadingSpinner asOverlay />}
        {
        !isLoading && (
            <div className='student-assignment-pop'>
                {isPop &&
                    <div className='box'>
                        <p style={{"fontWeight":"bold","fontSize":"25px","textAlign":"center", "color": "#f84f20"}}>Student Dashboard</p>
                        <span className="close-icon" onClick={props.handleClose}>x</span>
                        <div className='student-info'>
                            <p><span style={{"fontSize": "20px", "fontWeight": "bold", "color": "#044c74"}}>Name:</span> {props.studentDetails.studentName}</p>
                            <p><span style={{"fontSize": "20px", "fontWeight": "bold", "color": "#044c74"}}>Mobile:</span> {props.studentDetails.studentMobile}</p>
                            <p><span style={{"fontSize": "20px", "fontWeight": "bold", "color": "#044c74"}}>Email:</span> {props.studentDetails.studentEmail}</p>
                        </div>
                        {attendanceList && 
                            <p style={{"fontWeight":"bold","fontSize":"20px","textAlign":"left", "color": "#f84f20"}}>Attendance Status Info</p>
                        }
                        {attendanceList &&
                            <table class="module-table" cellSpacing={"0px"}>
                            <thead>
                                <tr>
                                    <th>Title</th>
                                    <th>Scheduled at</th>
                                    <th>Attendance Punch Time</th>
                                </tr>
                            </thead>
                            {attendanceList != 0 && attendanceList.map((p, i) => {
                                return(
                                    <tr>
                                        <td>{p.title}</td>
                                        <td>{formatDate.format(new Date(p.scheduled_start))}</td>
                                        <td>{p.in_time}</td>
                                    </tr>
                                    )    
                                }
                            )}
                            </table>
                        }
                        {assignmentList && 
                            <p style={{"fontWeight":"bold","fontSize":"20px","textAlign":"left", "color": "#f84f20"}}>Assignments Status Info</p>
                        }
                        {assignmentList &&
                            <table class="module-table" cellSpacing={"0px"}>
                            <thead>
                                <tr>
                                    <th>Assignment</th>
                                    <th>Status</th>
                                    <th>Grade</th>
                                    <th>Deadline</th>
                                    <th>Submitted On</th>
                                </tr>
                            </thead>
                            {assignmentList.todo_info != 0 && assignmentList.todo_info.map((p, i) => {
                                return(
                                    <tr>
                                        <td>{p.title}</td>
                                        <td>No Submission</td>
                                        <td>-</td>
                                        <td>{p.deadline}</td>
                                        <td>-</td>
                                    </tr>
                                    )    
                                }
                            )}
                            {assignmentList.todo_info_track != 0 && assignmentList.todo_info_track.map((p, i) => {
                                return(
                                    <tr>
                                        <td>{p.title}</td>
                                        <td>No Submission</td>
                                        <td>-</td>
                                        <td>{p.deadline}</td>
                                        <td>--</td>
                                    </tr>
                                    )    
                                }
                            )}
                            {assignmentList.other_info != 0 && assignmentList.other_info.map((p, i) => {
                                return(
                                    <tr>
                                        <td>{p.title}</td>
                                        <td>{p.cur_status}</td>
                                        <td>{p.cur_status == 'submitted' | p.cur_status == 'Graded' ? p.grade : '-'}</td>
                                        <td>{p.deadline}</td>
                                        <td>{p.cur_status == 'submitted' | p.cur_status == 'Graded' ? p.create_timestamp : '-'}</td>
                                    </tr>
                                    )    
                                }
                            )}
                        </table>                    
                        }
                    </div>
                }
            </div>
        )
        }
        </React.Fragment>
  )
}

export default StudentInfoPop