import React from "react";
import { useState, useEffect, useContext } from "react";
import "./InterviewPreperation.css";
import PersonalInterview from "./PersonalInterview/PersonalInterview";
import InterviewFeedback from "./InterviewFeedback/InterviewFeedback";

const InterviewPreperation = ({ setSelectedOption }) => {
  const [selectedTab, setSelectedTab] = useState("personalInterview");

  const renderSelectedTab = () => {
    if (selectedTab === "personalInterview") {
      return (
        <PersonalInterview
          setSelectedTab={setSelectedTab}
          setSelectedOption={setSelectedOption}
        />
      );
    } else if (selectedTab === "interviewFeedback") {
      return (
        <InterviewFeedback
          setSelectedTab={setSelectedTab}
          setSelectedOption={setSelectedOption}
        />
      );
    }
  };

  return <div>{renderSelectedTab()}</div>;
};
export default InterviewPreperation;
