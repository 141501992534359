import React, { useState, useMemo } from "react";
import Button from "../../../../../../../../../Components/Profile/Button/Button";
import Loading from "../../../../../../../../../Components/Loading";

const Roles = ({
    isLoading,
    error,
    // roles,
    setSelectedRole,
    selectedRole,
    selectedInterviewType,
    handleGoToHome,
    handleGoToAllInterview,
    handleGoToRoleBased,
    onNext,
}) => {
    const [searchTerm, setSearchTerm] = useState("");

    // 20 random roles
    const roles = [
        { value: "software_engineer", label: "Software Engineer" },
        { value: "data_scientist", label: "Data Scientist" },
        { value: "product_manager", label: "Product Manager" },
        { value: "ux_designer", label: "UX Designer" },
        { value: "devops_engineer", label: "DevOps Engineer" },
        { value: "frontend_developer", label: "Frontend Developer" },
        { value: "backend_developer", label: "Backend Developer" },
        { value: "full_stack_developer", label: "Full Stack Developer" },
        { value: "mobile_app_developer", label: "Mobile App Developer" },
        { value: "ai_engineer", label: "AI Engineer" },
        { value: "cloud_architect", label: "Cloud Architect" },
        { value: "cybersecurity_analyst", label: "Cybersecurity Analyst" },
        { value: "data_engineer", label: "Data Engineer" },
        { value: "network_engineer", label: "Network Engineer" },
        { value: "systems_analyst", label: "Systems Analyst" },
        { value: "qa_engineer", label: "QA Engineer" },
        { value: "business_analyst", label: "Business Analyst" },
        { value: "scrum_master", label: "Scrum Master" },
        { value: "technical_writer", label: "Technical Writer" },
        { value: "database_administrator", label: "Database Administrator" },
    ];

    const filteredRoles = useMemo(() => {
        return roles.filter(role =>
            role.label.toLowerCase().includes(searchTerm.toLowerCase())
        );
    }, [roles, searchTerm]);

    return (
        <div className="interview-preperation-personal-interview-page">
            <div className="interview-preperation-personal-interview-page-nav-menu">
                <div
                    onClick={handleGoToHome}
                    className="interview-preperation-personal-interview-page-nav-menu-home"
                >
                    Home
                </div>
                &nbsp; &gt; &nbsp;
                <div
                    onClick={handleGoToAllInterview}
                    className="interview-preperation-personal-interview-page-nav-menu-other"
                >
                    All Interview
                </div>
                &nbsp; &gt; &nbsp;
                <div
                    onClick={() => handleGoToRoleBased(false)}
                    className="interview-preperation-personal-interview-page-nav-menu-other"
                >
                    Role Based
                </div>
            </div>
            <div className="interview-preperation-personal-interview-container">
                <div className="interview-preperation-personal-interview-header">
                    <div className="interview-preperation-personal-interview-header-title">
                        {selectedInterviewType.title}
                    </div>
                </div>
                <div className="interview-preperation-personal-interview-body">
                    <div className="interview-preperation-personal-interview-body-4">
                        <div className="interview-preperation-personal-interview-body-4-1">
                            <div className="interview-preperation-personal-interview-title">
                                Select Role
                            </div>
                            <div className="interview-preperation-personal-interview-search">
                                <div className="interview-preperation-personal-interview-search-wrapper">
                                    <input
                                        type="text"
                                        placeholder="Search roles"
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                        className="interview-preperation-personal-interview-search-input"
                                    />
                                    <svg className="interview-preperation-personal-interview-search-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                        <circle cx="11" cy="11" r="8"></circle>
                                        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                                    </svg>
                                </div>
                            </div>
                        </div>

                        {isLoading && (
                            <div className="interview-preperation-personal-interview-body-4-2">
                                <Loading color="#004c73" />
                            </div>
                        )}
                        {error && (
                            <div className="interview-preperation-personal-interview-error">
                                Something went wrong. Please try again later.
                            </div>
                        )}
                        {filteredRoles.length > 0 && (
                            <>
                                <div className="interview-preperation-personal-interview-body-4-2">
                                    <div className="interview-preperation-personal-interview-role-container-wrapper">
                                    <div className="interview-preperation-personal-interview-role-container">
                                        {filteredRoles.map((role, index) => (
                                            <div
                                                key={index}
                                                className={`interview-preperation-personal-interview-box ${selectedRole === role.value ? 'selected' : ''}`}
                                                onClick={() => setSelectedRole(role.value)}
                                            >
                                                {role.label}
                                            </div>
                                        ))}
                                        </div>
                                    </div>
                                </div>
                                <div className="interview-preperation-personal-interview-body-4-3">
                                    <Button
                                        text="NEXT"
                                        width="400px"
                                        disabled={selectedRole === ""}
                                        onClick={onNext}
                                    />
                                </div>
                            </>
                        )}
                        {filteredRoles.length === 0 && !isLoading && !error && (
                            <div className="interview-preperation-personal-interview-no-results">
                                No roles found matching your search.
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Roles;
