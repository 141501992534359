import React, { useState } from "react";
import "./TextArea.css";

const TextArea = ({
  width = "99%",
  height = "130px",
  inputPlaceholderValue,
  onChangeFunction,
  value,
  name,
  label = false,
  inperror = false,
  maxCharCount = 500,
}) => {
  const [charCount, setCharCount] = useState(0);
  const [charCountErr, setCharCountErr] = useState(false);
  const [errDesc, setErrDesc] = useState("");

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setCharCount(inputValue.length);

    if (inputValue.length > maxCharCount) {
      setCharCountErr(true);
      setErrDesc("Character limit exceeded");
    }
    if (inputValue.length <= maxCharCount) {
      setCharCountErr(false);
      setErrDesc("");
    }
    onChangeFunction(e);
  };
  
  return (
    <div
      className="profile-textarea-component-container"
      style={{ width: width }}
    >
      {label && <div className="profile-textarea-field-label">{label}</div>}
      <div className="profile-textarea-field-container-outer">
        <div className="profile-textarea-field-container">
          <textarea
            type="text"
            style={{ width: width, height: height}}
            placeholder={inputPlaceholderValue}
            onChange={handleInputChange}
            value={value}
            name={name}
            className={
              inperror
                ? "profile-textarea-field profile-textarea-field-err"
                : "profile-textarea-field"
            }
          />
          <div
            className={charCountErr ?
              "profile-textarea-char-count-and-error-container":"profile-textarea-char-count-container"
            }
          >
            {charCountErr && (
              <div className="profile-textarea-err-desc">{errDesc}</div>
            )}
            <div className={charCountErr ? "profile-textarea-char-count-err" : ""}>
              {charCount}/{maxCharCount}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TextArea;
