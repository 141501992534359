import React, { useState } from "react";
import Popup from "../../../../Components/Profile/Popup/PopUp";
import Button from "../../../../Components/Profile/Button/Button";
import "./Delete.css";
import Loading from "../../../../Components/Loading";

const DeletePopup = ({ title, text, show, onClose, onDelete }) => {
  const [loading, setLoading] = useState(false);

  const handleDelete = async () => {
    try {
      setLoading(true);
      await onDelete();
    } finally {
      setLoading(false);
    }
  };
  return (
    <Popup title={title} show={show} onClose={onClose}>
      <div className="profile-delete-confirmation-container">
        <div className="profile-delete-confirmation-text">{text}</div>
        {loading ? (
          <div className="profile-delete-loading-container">
            <Loading color="#14144e" />
          </div>
        ) : (
          <div className="profile-delete-popup-buttons">
            <Button text="Cancel" onClick={onClose} width="100px" />
            <Button
              text="Delete"
              onClick={handleDelete}
              width="100px"
              disabled={loading}
            />
          </div>
        )}
      </div>
    </Popup>
  );
};

export default DeletePopup;
