import React, { useState, useEffect } from 'react'
import { Scrollbars } from "react-custom-scrollbars-2";

import './MentorGrageAssignment.css'
import ErrorModal from "../../Components/Input/ErrorModel";
import LoadingSpinner from "../../Components/Input/LoadingSpinner";
import { useHttpClient } from '../../Components/Hooks/http-hook';

const MentorGrageAssignment = (props) => {
    
    const [isPop, setIsPop] = useState(true)
    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const [zipFileDetails, setZipFileDetails] = useState({})
    const [showGrade, setShowGrade] = useState(false)
    const [grade, setGrade] = useState()

    const handleGetAssignment = async(id,surge_id, assign_title) => {

        try {
          const responseData = await sendRequest(
            `${process.env.REACT_APP_BACKEND_URL}/s3/assignment/${id}/${surge_id}/${assign_title}`,
            "GET",
          );
    
          if (responseData.ok == true) {
              console.log('log added')
              return responseData.data
            } else {
                alert(responseData.msg)
            }
        } 
        catch (err) {console.log(err)}  
      }
  
      const handleDownload = () => {
        const bytes = atob(zipFileDetails.base64);
        let length = bytes.length;
        let out = new Uint8Array(length);
    
        while (length--) {
            out[length] = bytes.charCodeAt(length);
        }
    
        let blob = new Blob([out], { type: 'application/pdf' });
        // const blob = base64toBlob(fileDetails.base64)
        console.log(blob, 'blob')
        const url = URL.createObjectURL(blob)
        
        const link = document.createElement('a');
        link.href = url;
        const filename = zipFileDetails.filename
        console.log(filename)
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        console.log(url)
        link.click();
      }
      
      useEffect(() => {
        handleGetAssignment(props.programId, props.assignmentDetails.surge_id, props.assignmentDetails.title).then(
          response => setZipFileDetails(response)
          )
      }, [])

      const handleGradeAssignment = async(id,surge_id,assign_title,grade,mentor) => {
        try {
            const responseData = await sendRequest(
              `${process.env.REACT_APP_BACKEND_URL}/s3/assignment-grade/${mentor}/${id}/${surge_id}/${assign_title}/${grade}`,
              "PUT",
            );
      
            if (responseData.ok == true) {
                alert(responseData.msg)
              } else {
                alert(responseData.msg)
              }
          } 
          catch (err) {console.log(err)}  
        }

  return (
    <React.Fragment>
        <ErrorModal error={error} onClear={clearError} />
        {isLoading && <LoadingSpinner asOverlay />}
        {
        !isLoading && (
            <div className='popup-box'>
                {isPop &&        
                    <div className='box'>
                        <p style={{fontWeight:"bold", fontSize:"20px", textAlign:"center"}}>Grade Assignment</p>
                        <span className="close-icon" onClick={props.handleClose}>x</span>
                        <div className='add-heading-assign'>
                            <Scrollbars
                                autoHide
                                autoHideTimeout={2000}
                                autoHideDuration={310}
                                autoHeightMin={300}
                                autoHeightMax={800}
                                thumbMinSize={30}
                                universal={true}
                                style={{
                                width: `100%`,
                                height: "25rem",
                                background: "white",
                                borderRadius: "6px",
                                }}
                            >
                                <div className='mentor-assign-box'>
                                    <div className='left-flex'>
                                        <div className='download-files-section'>
                                            <p>Download Assignment Submission Files</p>
                                            <button onClick={handleDownload}>Download</button>              
                                        </div>
                                        <div className='Assignment-status-info'>
                                            <h3>Submission Info</h3>
                                            <p><span style={{"fontWeight":"bold"}}>Assignment :</span> {props.assignmentDetails.title}</p>
                                            <p><span style={{"fontWeight":"bold"}}>Deadline Until :</span> {props.assignmentDetails.deadline}</p>
                                            <p><span style={{"fontWeight":"bold"}}>Submitted On :</span>   {props.assignmentDetails.submit_on}</p>
                                        </div>
                                    </div>
                                        <div className='right-flex'>
                                            <p><span style={{"fontWeight":"bold"}}>Status :</span> {props.assignmentDetails.status}</p>
                                            <div className='grading-part'>
                                                <button onClick={()=>setShowGrade(true)}>Add Grade</button>
                                                {showGrade && 
                                                <form style={{"width": "70%", "marginLeft":"10%", "marginRight":"10%"}}>
                                                    <label>Grade
                                                        <input
                                                            style={{"width": "75%"}}
                                                            type="int"
                                                            placeholder='Out of 10'                                            
                                                            onChange={(e) => setGrade(e.target.value)}
                                                        />                                
                                                    </label>
                                                </form>
                                                }
                                                {showGrade && <button onClick={()=>handleGradeAssignment(props.programId, props.assignmentDetails.surge_id, props.assignmentDetails.title,grade,props.assignmentDetails.mentor)}>Grade</button>}
                                            </div>
                                        </div>
                                </div>
                            </Scrollbars>
                        </div>
                    </div>
                }
            </div>        
        )
        }
    </React.Fragment>
  )
}

export default MentorGrageAssignment