import React from 'react';

const CloseIcon = () => {
  return (
    <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.82348 0.632195L8.99948 6.8082L15.1435 0.664195C15.2792 0.519743 15.4427 0.404187 15.6241 0.324455C15.8056 0.244723 16.0013 0.202458 16.1995 0.200195C16.6238 0.200195 17.0308 0.368766 17.3309 0.668824C17.6309 0.968883 17.7995 1.37585 17.7995 1.8002C17.8032 1.99636 17.7668 2.19121 17.6925 2.37281C17.6182 2.5544 17.5076 2.71889 17.3675 2.8562L11.1435 9.0002L17.3675 15.2242C17.6312 15.4822 17.7858 15.8315 17.7995 16.2002C17.7995 16.6245 17.6309 17.0315 17.3309 17.3316C17.0308 17.6316 16.6238 17.8002 16.1995 17.8002C15.9956 17.8087 15.7921 17.7746 15.6021 17.7003C15.412 17.6259 15.2395 17.5128 15.0955 17.3682L8.99948 11.1922L2.83948 17.3522C2.70429 17.4918 2.54278 17.6033 2.36428 17.6802C2.18578 17.7571 1.99382 17.7979 1.79948 17.8002C1.37513 17.8002 0.968166 17.6316 0.668108 17.3316C0.36805 17.0315 0.199479 16.6245 0.199479 16.2002C0.195749 16.004 0.232144 15.8092 0.306431 15.6276C0.380718 15.446 0.491331 15.2815 0.631479 15.1442L6.85548 9.0002L0.631479 2.7762C0.367775 2.51821 0.213142 2.16885 0.199479 1.8002C0.199479 1.37585 0.36805 0.968883 0.668108 0.668824C0.968166 0.368766 1.37513 0.200195 1.79948 0.200195C2.18348 0.204995 2.55148 0.360195 2.82348 0.632195Z"
                  fill="#434343"
                />
              </svg>
  )
};

export default CloseIcon;