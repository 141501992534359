import React from "react";

import UploadResumeContent from "../UploadResume/UploadResumeContent";
import ProfileAnalysisContent from "../ProfileAnalysis/ProfileAnalysisContent";
import { FileIcon } from "../PA_Components/FileIcon";
import { ProfileIcon } from "../PA_Components/ProfileIcon";



export const SkillboxSideBarData = [
  {
    title: "Profile Analyzer",
    icon: (
      <FileIcon />
    ),
    component: ProfileAnalysisContent,
  },
  {
    title: "Upload Resume",
    icon: (
      <ProfileIcon />
    ),
    component: UploadResumeContent,
  },
];
