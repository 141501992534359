import React from "react";
import "./InfoBox.css";

const InfoBox = ({ InfoData }) => {
    return (
        <div className="Ip_AdminInterviewInfoBox">
            {InfoData.map((data, index) => (
                <div className="Ip_AdminInterviewInfo" key={index}>
                    <div className="Ip_AdminInterviewInfoIcon">{data.icon}</div>
                    <div className="Ip_AdminInterviewInfoData">
                        <div className="Ip_AdminInterviewInfoDataCount">{data.count.toLocaleString()}</div>
                        <div className="Ip_AdminInterviewInfoDataTitle">{data.title}</div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default InfoBox;