import React from "react";
import { useState, useEffect, useContext } from "react";
import "./Doubts.css";
import AllDoubts from "./AllDoubts/AllDoubts";

const Doubts = ({ setSelectedOption }) => {
  return (
    <div>
      <AllDoubts setSelectedOption={setSelectedOption} />
    </div>
  );
};
export default Doubts;
