import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { ImPhone, ImLocation, ImMail2 } from "react-icons/im";
import { AuthContext } from "../context/auth-context";
import Popup from "../Components/QueryPopup/Popup";
import {
  FaFacebook,
  FaInstagram,
  FaTwitter,
  FaLinkedinIn,
  FaYoutube,
} from "react-icons/fa";
import { MdMailOutline } from "react-icons/md";
import "./PageFooter.css";

import surgeImg from "../assets/logo2.png";
import surgelcassesImg from "../assets/logo4.png";
import { icons } from "react-icons";

const PageFooter = () => {
  const auth = useContext(AuthContext);
  const [showPopup, setShowPopup] = useState(false);
  return (
    <div className="container-fluid">
      {auth.role === "student" && (
        <>
          <Popup showPopup={showPopup} setShowPopup={setShowPopup} />
          <div className="raise-query-floating-btn">
            <button
              className="raise-query-btn"
              onClick={() => setShowPopup(true)}
            >
              Have a Doubt?
            </button>
          </div>
        </>
      )}
      <div className="flex-footer">
        <div className="flex-footer1">
          <img
            className="footer-surgeimg"
            src={surgelcassesImg}
            alt="surge classes"
          />
          <h5>
            {/* {" "} */}
            {/* <span style={{ color: "red", fontWeight: "600", fontSize: "24px" }}>
              Surge
            </span>{" "}
            <span
              style={{ color: "#023047", fontSize: "24px", fontWeight: "600" }}
            >
              Classes
            </span>{" "} */}
            {/* <br />  */}
            Edtech product by Jalla Labs Pvt. Ltd
          </h5>
          {/* <li> */}
          <ImLocation
            style={{
              color: "#233f58",
              fontSize: "15px",
              alignItems: "left",
            }}
          />
          <p
            style={{
              color: "black",
              fontSize: "13px",
              fontWeight: "500",
              marginLeft: "1.0rem",
              marginTop: "-1.0rem",
              textAlign: "left",
            }}
          >
            No. 15, 25Th A Main, Mariswamy Layout Agara,
            <br /> HSR Layout, Sector - 1 Bengaluru
          </p>
          <MdMailOutline
            style={{
              color: "#233f58",
              fontSize: "15px",
              alignItems: "left",
            }}
          />
          <p
            style={{
              color: "black",
              fontSize: "15px",
              fontWeight: "500",
              marginLeft: "1.0rem",
              marginTop: "-1.0rem",
              textAlign: "left",
            }}
          >
            connect@surgeclasses.com
          </p>
          {/* </li> */}
        </div>
        <div className="flex-footer2">
          <h5>Learning Programmes</h5>
          <a
            target="_blank"
            href="https://www.surgeclasses.com/data-science-master-program"
          >
            Data Science Campus Programme <br />{" "}
            <span style={{ color: "orangered" }}>
              {" "}
              (Artificial Intelligence Specialization)
            </span>
          </a>
          <a
            target="_blank"
            href="https://www.surgeclasses.com/data-science-campus-program-ai"
          >
            Data Science Campus Programme <br />{" "}
            <span style={{ color: "orangered" }}>
              (Software Engineering Specialization)
            </span>
          </a>
          <a
            target="_blank"
            href="https://surgeclasses.com/data-science-pro-program"
          >
            Data Science Pro Programme <br />{" "}
            <span style={{ color: "orangered" }}>
              (for working professionals/freshers)
            </span>
          </a>
        </div>
        <div className="flex-footer3">
          <h5>Resources</h5>
          <a className="flex-footer3-a-1" href="/about">
            About us
          </a>
          <a className="flex-footer3-a-2" href="/contact">
            Contact us
          </a>
          <a className="flex-footer3-a-3" href="/termsConditions">
            Terms and Conditions
          </a>
          <div>
            <li>
              <a
                href="https://www.facebook.com/Surge-Classes-111312501672213"
                target="_blank"
              >
                <FaFacebook
                  style={{
                    color: "#233f58",
                    fontSize: "16px",
                    marginRight: "0.4rem",
                    marginTop: "0.4rem",
                  }}
                />
              </a>
              <a
                href="https://www.linkedin.com/company/surge-classes/"
                target="_blank"
              >
                <FaLinkedinIn
                  style={{
                    color: "#233f58",
                    fontSize: "16px",
                    marginRight: "0.4rem",
                    marginTop: "0.4rem",
                    marginLeft: "0.4rem",
                  }}
                />
              </a>
              <a href="https://www.instagram.com/surgeclasses/" target="_blank">
                <FaInstagram
                  style={{
                    color: "#233f58",
                    fontSize: "16px",
                    marginRight: "0.4rem",
                    marginTop: "0.4rem",
                    marginLeft: "0.4rem",
                  }}
                />
              </a>
              <a href="https://www.instagram.com/surgeclasses/" target="_blank">
                <FaYoutube
                  style={{
                    color: "#233f58",
                    fontSize: "16px",
                    marginRight: "0.4rem",
                    marginTop: "0.4rem",
                    marginLeft: "0.4rem",
                  }}
                />
              </a>
            </li>
          </div>
        </div>
        {/* <div className="flex-footer4"> */}
        {/* <h5>Follow us on</h5> */}
        {/* <div className="flex4-div">
            <a
              href="https://www.facebook.com/Surge-Classes-111312501672213"
              target="_blank"
              className="flex4-div"
            >
              <FaFacebook
                style={{
                  color: "#233f58",
                  fontSize: "18px",
                  marginRight: "0.4rem",
                  marginTop: "0.4rem"
                }}
              />{" "}
              <span>Facebook</span>
            </a>
          </div>
          <div className="flex4-div">
            <a
              href="https://www.linkedin.com/company/surge-classes/"
              target="_blank"
              className="flex4-div"
            >
              <FaLinkedinIn
                style={{
                  color: "#233f58",
                  fontSize: "18px",
                  marginRight: "0.85rem",
                  marginTop: "0.4rem"
                }}
              />{" "}
              Linkedin
            </a>
          </div>
          <div className="flex4-div">
            <a
              href="https://www.instagram.com/surgeclasses/"
              target="_blank"
              className="flex4-div"
            >
              <FaInstagram
                style={{
                  color: "#233f58",
                  fontSize: "20px",
                  marginRight: "1.3rem",
                  marginTop: "0.4rem"
                }}
              />{" "}
              Instagram
            </a>
          </div>
          <div className="flex4-div">
            <a>
              <FaYoutube
                style={{
                  color: "#233f58",
                  fontSize: "18px",
                  marginRight: "1.75rem",
                  marginTop: "0.4rem"
                }}
              />{" "}
              Youtube
            </a>
          </div>
          {/*<div className="flex4-div">
            <a>
              <FaTwitter
                style={{
                  color: "#233f58",
                  fontSize: "18px",
                  marginRight: "0.2rem",
                }}
              />{" "}
              Twitter
            </a>
              </div>*/}
        {/* </div> */}
      </div>
      <br />
      <div className="copyright">Copyright &copy; 2021 SurgeClasses</div>
    </div>
  );
};

export default PageFooter;
