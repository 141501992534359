import React from "react";
import "./AptitudeContent.css";
import { useNavigate, useParams } from "react-router-dom";

const AptitudeContent = () => {
  let { tid } = useParams();
  const history=useNavigate();

  const contentHandler=(item)=>{
    history(`/aptitude/${tid}/${item}`);
  }

  const TopicList = [
    {
      title: "Arithmetic Aptitude",
      topics: [
        {
          name: "Problems on Trains",
        },
        {
          name: "Time and Distance",
        },
        {
          name: "Height and Distance",
        },
        {
          name: "Time and Work",
        },
        {
          name: "Simple Interest",
        },
        {
          name: "Compound Interest",
        },
        {
          name: "Profit and Loss",
        },
        {
          name: "Problems on Ages",
        },
        {
          name: "Aligation and Mixtures",
        },
        {
          name: "Percentage",
        },
        {
          name: "Ratio and Proportion",
        },
        {
          name: "Partnership",
        },
        {
          name: "Calendar",
        },
        {
          name: "Area and Volumes",
        },
        {
          name: "Average",
        },
        {
          name: "Problems on Numbers",
        },
        {
          name: "Decimal Fraction",
        },
        {
          name: "Problems on H.C.F and L.C.M",
        },
        {
          name: "Simplification",
        },
        {
          name: "Surds and Indices",
        },
        {
          name: "Chain Rule",
        },

        {
          name: "Boats and Streams",
        },
        {
          name: "Logarithm",
        },
        {
          name: "Banker's Discount",
        },
        {
          name: "True Discount",
        },
        {
          name: "Odd Man Out and Series",
        },
        {
          name: "Probability",
        },
      ],
    },
    {
      title: "Data Interpretation",
      topics: [
        {
          name: "Table Charts",
        },
        {
          name: "Bar Charts",
        },
        {
          name: "Pie Charts",
        },
        {
          name: "Line Charts",
        },
      ],
    },
    {
      title: "Verbal Ability and Reasoning",
      topics: [
        {
          name: "Spotting Errors",
        },
        {
          name: "Antonyms and Synonyms",
        },
        {
          name: "Spellings Check",
        },
        {
          name: "Sentance Formation and Correction",
        },
        {
          name: "Idoms and Phrases",
        },
        {
          name: "Change of Speech and Voice",
        },
        {
          name: "Comprehension",
        },
        {
          name: "Blood Relation Test",
        },
        {
          name: "Series Completion",
        },
        {
          name: "Venn Diagrams",
        },
        {
          name: "Syllogism",
        },
        {
          name: "Aritmetic Reasoning",
        },
        {
          name: "Dice",
        },
        {
          name: "Seating Arrangement",
        },
        {
          name: "Data Sufficiency",
        },
      ],
    },
    {
      title: "Logical Reasoning",
      topics: [
        {
          name: "Number Series",
        },
        {
          name: "Verbal Classification",
        },
        {
          name: "Analogies",
        },
        {
          name: "Letter and Symbol Series",
        },
        {
          name: "Statement and Conclusion",
        },
        {
          name: "Statement and Assumption",
        },
        {
          name: "Cause and Effect",
        },
        {
          name: "Making Judgements",
        },
        {
          name: "Logical Problems and Deduction",
        },
      ],
    },
    {
      title: "Non Verbal Reasoning",
      topics: [
        {
          name: "Series",
        },
        {
          name: "Classification",
        },
        {
          name: "Analogies",
        },
        {
          name: "Mirror and Water Images",
        },
        {
          name: "Figure Matrix",
        },
        {
          name: "Paper Folding",
        },
        {
          name: "Cube and Dice",
        },
        {
          name: "Dot Situation",
        },
        {
          name: "Grouping of Images",
        },
        {
          name: "Shape Construction",
        },
      ],
    },
    {
      title: "Current Affairs and GK",
      topics: [
        {
          name: "State",
        },
        {
          name: "Politics",
        },
        {
          name: "Art and Culture",
        },
        {
          name: "Sports",
        },
        {
          name: "National",
        },
        {
          name: "Defence",
        },
        {
          name: "International",
        },
        {
          name: "Agriculture",
        },
        {
          name: "Banking",
        },
        {
          name: "Environment",
        },
        {
          name: "Science",
        },
        {
          name: "Education",
        },
        {
          name: "Important Days",
        },
        {
          name: "Economy",
        },
        {
          name: "Bussiness and Finance",
        },
        {
          name: "Places",
        },
        {
          name: "Awards and Honors",
        },
        {
          name: "Bills and Acts",
        },
        {
          name: "Persons",
        },
        {
          name: "Basic Gerenal Knowledge"
        }
      ],
    },
    {
      title: "General Science and Inventions",
      topics: [
        {
          name: "Elements and Metals",
        },
        {
          name: "Measurements",
        },
        {
          name: "Nuclear Science",
        },
        {
          name: "Synthetic Materials",
        },
        {
          name: "Inventions",
        },
      ],
    },
  ];

  return (
    <div className="aptitudecontent-page">
      {TopicList &&
        TopicList.map((item) => {
          if (item.title == tid) {
            return (
              <div className="topic-wise-app">
                <h3>{item.title}</h3>
                <div className="topic-grid-div">
                  {item.topics.map((i) => {
                    return <li onClick={()=>contentHandler(i.name)}>{i.name}</li>;
                  })}
                </div>
                <p><span style={{color:"orangered"}}> Online Test :</span> Take an online test on {item.title} NOW! </p>
              </div>
            );
          }
        })}
    </div>
  );
};

export default AptitudeContent;
