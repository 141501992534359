import { createContext } from "react";

const adminModel = {
  userId: "",
  userName:"",
  email:"",
  profile:"",
  mobile: "",
  token: "",
  refresh_token: "",
  isLoggedIn: false,
  isInstructor: false,
  login: () => {},
  logout: () => {},
};

export const AdminContext = createContext(adminModel);
