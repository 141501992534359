import React from "react";

const SpecialCharacterSvg = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 5C10.4667 5 9.16667 5.53333 8.1 6.6C7.03333 7.66667 6.5 8.96667 6.5 10.5C6.5 11.55 6.775 12.5167 7.325 13.4C7.875 14.2833 8.625 14.9583 9.575 15.425C9.975 15.625 10.3127 15.896 10.588 16.238C10.8633 16.58 11.0007 16.9673 11 17.4V19.5C11 19.9167 10.8543 20.271 10.563 20.563C10.2717 20.855 9.91733 21.0007 9.5 21H4.5C4.08333 21 3.72933 20.8543 3.438 20.563C3.14667 20.2717 3.00067 19.9173 3 19.5C3 19.0833 3.146 18.7293 3.438 18.438C3.73 18.1467 4.084 18.0007 4.5 18H8C6.6 17.25 5.5 16.2083 4.7 14.875C3.9 13.5417 3.5 12.0833 3.5 10.5C3.5 8.13333 4.325 6.125 5.975 4.475C7.625 2.825 9.63333 2 12 2C14.3667 2 16.375 2.825 18.025 4.475C19.675 6.125 20.5 8.13333 20.5 10.5C20.5 12.0833 20.1 13.5417 19.3 14.875C18.5 16.2083 17.4 17.25 16 18H19.5C19.9167 18 20.271 18.146 20.563 18.438C20.855 18.73 21.0007 19.084 21 19.5C21 19.9167 20.8543 20.271 20.563 20.563C20.2717 20.855 19.9173 21.0007 19.5 21H14.5C14.0833 21 13.7293 20.8543 13.438 20.563C13.1467 20.2717 13.0007 19.9173 13 19.5V17.4C13 16.9667 13.1377 16.5793 13.413 16.238C13.6883 15.8967 14.0257 15.6257 14.425 15.425C15.375 14.9583 16.125 14.2833 16.675 13.4C17.225 12.5167 17.5 11.55 17.5 10.5C17.5 8.96667 16.9667 7.66667 15.9 6.6C14.8333 5.53333 13.5333 5 12 5Z" fill="#444444"/>
        </svg>
    );
}

export default SpecialCharacterSvg;