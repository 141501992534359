import React from "react";
import { useState } from "react";
import "./RatingBox.css";

const RatingBox = ({ ratingPoints, ratingTitle }) => {
  const calculateRatingCircleCountAndColor = (ratingPoints) => {
    let ratingCircleCount = Math.floor(ratingPoints / 2);
    const isOddRating = ratingPoints % 2 !== 0;
    const ratingCircleTotalCount = 5;
    let ratingCircleColorClassArray = [
      "interview-feedback-rating-box__rating-circle--orange",
      "interview-feedback-rating-box__rating-circle--yellow",
      "interview-feedback-rating-box__rating-circle--green",
    ];
    let halfRatingCircleColorClassArray = [
      "interview-feedback-rating-box__rating-circle--orange-half",
      "interview-feedback-rating-box__rating-circle--yellow-half",
      "interview-feedback-rating-box__rating-circle--green-half",
    ];
    let ratingCircleColorClass = "";
    let halfRatingCircleColorClass = "";

    if (ratingPoints >= 0 && ratingPoints <= 4) {
      ratingCircleColorClass = ratingCircleColorClassArray[0];
      halfRatingCircleColorClass = halfRatingCircleColorClassArray[0];
    } else if (ratingPoints > 4 && ratingPoints <= 6) {
      ratingCircleColorClass = ratingCircleColorClassArray[1];
      halfRatingCircleColorClass = halfRatingCircleColorClassArray[1];
    } else if (ratingPoints > 6 && ratingPoints <= 10) {
      ratingCircleColorClass = ratingCircleColorClassArray[2];
      halfRatingCircleColorClass = halfRatingCircleColorClassArray[2];
    }

    return {
      ratingCircleCount,
      isOddRating,
      ratingCircleTotalCount,
      ratingCircleColorClass,
      halfRatingCircleColorClass,
    };
  };

  const {
    ratingCircleCount,
    isOddRating,
    ratingCircleTotalCount,
    ratingCircleColorClass,
    halfRatingCircleColorClass,
  } = calculateRatingCircleCountAndColor(ratingPoints);

  const ratingCircles = Array.from(
    { length: ratingCircleTotalCount },
    (v, index) => (
      <div
        key={index}
        className={`interview-feedback-rating-box__rating-circle ${
          index < ratingCircleCount ? ratingCircleColorClass : ""
        } ${
          isOddRating && index === ratingCircleCount
            ? halfRatingCircleColorClass
            : ""
        }`}
      ></div>
    )
  );

  return (
    <div className="interview-feedback-rating-box">
      <div className="interview-feedback-rating-box__section-1">
        <div className="interview-feedback-rating-box__title">
          {ratingTitle}
        </div>
      </div>
      <div className="interview-feedback-rating-box__section-2">
        <div className="interview-feedback-rating-box__rating">
          {ratingCircles}
        </div>
        <div className="interview-feedback-rating-box__points">
          {ratingPoints} / 10
        </div>
      </div>
    </div>
  );
};

export default RatingBox;
