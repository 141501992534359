import React, { useEffect } from "react";
import PopUp from "../UiComponents/Popup/PopUp";
import "./EditSection.css";
import { useState, useContext } from "react";
import { AdminContext } from "../../../../context/admin-context";
import { useHttpClient } from "../../../../Components/Hooks/http-hook";
import LoadingSpinner from "../../../../Components/Input/LoadingSpinner";
const swal = require("sweetalert2");

const EditSection = ({ section, showPopup, setShowPopup, onEdit }) => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [buttonLoading, setButtonLoading] = useState(false);
  const admin = useContext(AdminContext);

  const intialSectionState = {
    section_name: section.section_name,
    section_description: section.section_description,
    section_lock: section.section_lock,
    section_order: section.section_order,
  };

  const [sectionData, setSectionData] = useState(intialSectionState);

  const [validationErrors, setValidationErrors] = useState({
    section_name: false,
    section_description: false,
    section_order: false,
  });

  useEffect(() => {
    setSectionData(intialSectionState);
  }, [section]);

  const resetForm = () => {
    setSectionData(intialSectionState);
    setValidationErrors({
      section_name: false,
      section_description: false,
      section_order: false,
    });
  };

  const handlechange = (e) => {
    const { name, value } = e.target;

    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: false,
    }));

    setSectionData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  }

  const validateForm = () => {
    const requiredFields = ["section_name", "section_description", "section_order"];
    const newValidationErrors = {};

    requiredFields.forEach((field) => {
      if (!sectionData[field]) {
        newValidationErrors[field] = true;
      }
    });

    setValidationErrors((prev) => ({
      ...prev,
      ...newValidationErrors,
    }));

    return Object.keys(newValidationErrors).length === 0;
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      setButtonLoading(false);
      return;
    }
    setButtonLoading(true);
    try {
      const response = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/db/section/${section.section_id}`,
        "PUT",
        JSON.stringify(sectionData),
        {
          "Content-Type": "application/json",
          "Refresh-Token": admin.refresh_token,
          Authorization: admin.token,
        }
      );
      if (response) {
        setButtonLoading(false);
        onEdit({
          ...section,
          ...sectionData
        });
        setShowPopup(false);
        swal.fire({
          title: "Section Updated Successfully",
          icon: "success",
          confirmButtonText: "Okay",
        });
      }
    } catch (err) {
      setButtonLoading(false);
      swal.fire({
        title: "Error",
        text: err.message || "Something went wrong",
        icon: "error",
        confirmButtonText: "Okay",
      });
    }
  }

  useEffect(() => {
    if (error) {
      setButtonLoading(false);
      setShowPopup(false);
      swal.fire({
        title: "Error!",
        text: error || "Something went wrong, please try again later.",
        icon: "error",
        confirmButtonText: "Close",
      });
      clearError();
    }
  }
  , [error, clearError, setShowPopup]);



  return (
  <PopUp
    title={section.section_name}
    onClose={() => {
      resetForm();
      setShowPopup(false);
    }}
    show={showPopup}
  >
    {buttonLoading ? (
              <div className="edit_section_popup__loading">

      <LoadingSpinner />
              </div>
    ) : (
    <form onSubmit={handleSubmit}>
    <div className="edit_section_popup__content">
      <div className="edit_section_popup__content-row">
        <div className="edit_section_popup__content-row-title">
          <p>Section ID</p>
        </div>
        <div className="edit_section_popup__content-row-value">
          <p>{section.section_id}</p>
        </div>
      </div>
      <div className="edit_section_popup__content-row">
        <div className="edit_section_popup__content-row-title">
          <p>Section Name</p>
        </div>
        <div className="edit_section_popup__content-row-value">
          <input type="text" defaultValue={section.section_name}
          onChange={handlechange}
          name="section_name"
          className={validationErrors.section_name ? "input_val_error" : ""}
           />
          {validationErrors.section_name && (
            <small className="input_val_error-text">
              Please enter a section name
            </small>
          )}
        </div>
      </div>
      <div className="edit_section_popup__content-row">
        <div className="edit_section_popup__content-row-title">
          <p>Section Description</p>
        </div>
        <div className="edit_section_popup__content-row-value">
          <input type="text" defaultValue={section.section_description}
          onChange={handlechange}
          name="section_description"
          className={validationErrors.section_description ? "input_val_error" : ""}
          />
          {validationErrors.section_description && (
            <small className="input_val_error-text">
              Please enter a section description
            </small>
          )}
        </div>
      </div>
      <div className="edit_section_popup__content-row">
        <div className="edit_section_popup__content-row-title">
          <p>Is Active</p>
        </div>
        <div className="edit_section_popup__content-row-value">
          <select
          onChange={handlechange}
          name="section_lock"
          defaultValue={section.section_lock?"true":"false"}
          >
            <option value="false">Live</option>
            <option value="true">Not Live</option>
          </select>
        </div>
      </div>
      <div className="edit_section_popup__content-row">
        <div className="edit_section_popup__content-row-title">
          <p>Section Order</p>
        </div>
        <div className="edit_section_popup__content-row-value">
          <input type="number" defaultValue={section.section_order}
          onChange={handlechange}
          name="section_order"
          className={validationErrors.section_order ? "input_val_error" : ""}
          />
        </div>
      </div>
      <div className="edit_section_popup__content-row">
        <div className="edit_section_popup__content-row-title">
          <p>Created On</p>
        </div>
        <div className="edit_section_popup__content-row-value">
          <p>{section.section_create_timestamp}</p>
        </div>
      </div>
      <div className="edit_section_popup__content-row">
        <div className="edit_section_popup__content-row-title">
          <p>Last Updated</p>
        </div>
        <div className="edit_section_popup__content-row-value">
          <p>{section.section_update_timestamp}</p>
        </div>
      </div>
    </div>
    <div className="edit_section_popup__buttons">
      <button
        className="edit_section_popup__buttons-cancel"
        type="button"
      onClick={() => 
        {
          resetForm();
          setShowPopup(false);
        }
      }
      >
        Cancel
      </button>
      <button
        className="edit_section_popup__buttons-save"
        type="submit"
      >
        Save
      </button>
    </div>
    </form>
    )}
  </PopUp>
);
  }

export default EditSection;
