import React, { useState, useEffect, useContext } from "react";
import "./ReferAndEarn.css";
import { useHttpClient } from "../../../../Components/Hooks/http-hook";
import { AuthContext } from "../../../../context/auth-context";
import RewardIconSvg from "../Svgs/RewardIconSvg";
import FaqArrowSvg from "../Svgs/FaqArrowSvg";
import FaqDownArrowSvg from "../Svgs/FaqDownArrowSvg";
import { FaqsData } from "../Data/FaqsData";
import Milestones from "../Components/Milestones";
import Loading from "../../../../Components/Loading";
import { ToastContainer, toast } from "react-toastify";

const ReferAndEarn = () => {
  const [referralCode, setReferralCode] = useState("");
  const [pageLoading, setPageLoading] = useState(true);
  const { sendRequest, isLoading, error, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  const [selectedFaq, setSelectedFaq] = useState(null);

  const toggleAnswer = (faqId) => {
    setSelectedFaq(selectedFaq === faqId ? null : faqId);
  };

  const handleShareLink = () => {
    if (navigator.share) {
      navigator
        .share({
          title: "Surge Classes",
          text: "Join Surge Classes and get 50% off on your first purchase",
          url: `https://surgeclasses.com/course/Data-science-and-AI-self-paced-program?referral_code=${referralCode}`,
        })
        .then(() => console.log("Successful share"))
        .catch((error) => toast.error("Error sharing", error));
    } else {
      toast.error("Your browser does not support this feature");
    }
  };

  useEffect(() => {
    const fetchReferralCode = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/referral/get-referral-code`,
          "GET",
          null,
          {
            "Content-Type": "application/json",
            "Refresh-Token": auth.refresh_token,
            Authorization: auth.token,
          }
        );
        if (responseData.referral_code) {
          setReferralCode(responseData.referral_code);
        }
        setPageLoading(false);
      } catch (err) {}
    };
    fetchReferralCode();
  }, []);

  useEffect(() => {
    if (error) {
      toast.error(error);
      setPageLoading(false);
      clearError();
    }
  }, [error, clearError]);

  return (
    <div>
      <ToastContainer closeButton={false} />
      <div className="referral-header">
        <div className="referral-header-title">Refer and Earn</div>
      </div>
      {pageLoading ? (
        <div className="referral-loader-container">
          <div className="referral-loader-sub-container">
            <Loading
              color="#14144e"
              customClass="referral-loader-container-for-loader"
            />
          </div>
        </div>
      ) : (
        <div className="refer-and-earn-main-section">
          {referralCode ? (
            <>
              <Milestones />
              <div className="referral-code-container">
                <div className="referral-code-left-section">
                  <RewardIconSvg />
                </div>
                <div className="referral-code-right-section">
                  <div className="referral-code-title">Your Referral Code</div>
                  <div className="referral-code">
                    <div
                      className="referral-code-sub-container"
                      onClick={() => {
                        navigator.clipboard.writeText(referralCode);
                        toast.success("Referral code copied to clipboard");
                      }}
                    >
                      {referralCode}
                    </div>
                    <div
                      className="referral-code-copy-button"
                      onClick={() => {
                        navigator.clipboard.writeText(referralCode);
                        toast.success("Referral code copied to clipboard");
                      }}
                    >
                      Copy to clipboard
                    </div>
                  </div>
                  <div className="referral-code-card-container">OR </div>
                  <div className="referral-code">
                    <div
                      className="referral-code-sub-container"
                      onClick={() => {
                        navigator.clipboard.writeText(
                          `https://surgeclasses.com/course/Data-science-and-AI-self-paced-program?referral_code=${referralCode}`
                        );
                        toast.success("Referral link copied to clipboard");
                      }}
                    >
                      <a
                        href={`https://surgeclasses.com/course/Data-science-and-AI-self-paced-program?referral_code=${referralCode}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        https://surgeclasses.com/course/Data-science-and-AI-self-paced-program?referral_code={referralCode}
                      </a>
                    </div>
                    <div
                      className="referral-code-share-button"
                      onClick={handleShareLink}
                    >
                      Share Your Link
                    </div>
                  </div>
                </div>
              </div>
              <div className="referral-code-faq-container">
                <div className="referral-code-faq-title">FAQ's</div>
                <div className="referral-code-faq-content">
                  {FaqsData.map((faq) => (
                    <div
                      key={faq.id}
                      className={`referral-code-faq-card ${
                        selectedFaq === faq.id ? "show-answer" : ""
                      }`}
                      onClick={() => toggleAnswer(faq.id)}
                    >
                      <div className="referral-code-faq-arrow">
                        {selectedFaq === faq.id ? (
                          <FaqDownArrowSvg />
                        ) : (
                          <FaqArrowSvg />
                        )}
                      </div>
                      <div className="referral-code-faq-question-and-answer">
                        <div className="referral-code-faq-question">
                          {faq.question}
                        </div>
                        <div className="referral-code-faq-answer">
                          {faq.answer}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </>
          ) : (
            <div className="referral-error-container">
              Something went wrong. Please try again later.
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ReferAndEarn;
