import React from "react";

const SpellCheckSvg = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.3255 16.6021C14.1495 16.6021 15.2475 15.2986 15.2475 13.1371V12.0841C15.2475 9.92411 14.157 8.62661 12.354 8.62661C11.241 8.62661 10.362 9.14711 10.1055 9.96011H10.011V5.97461H8.076V16.5001H9.981V15.3136H10.077C10.392 16.1116 11.241 16.6036 12.3255 16.6036V16.6021ZM11.6805 15.0646C10.6905 15.0646 10.011 14.2876 10.011 13.1446V12.1801C10.011 10.9426 10.641 10.1656 11.658 10.1656C12.684 10.1656 13.2705 10.9426 13.2705 12.2896V12.9646C13.2705 14.2966 12.6915 15.0646 11.6805 15.0646ZM3.306 16.6171C4.4565 16.6171 5.1075 16.0831 5.415 15.5116H5.5035V16.5001H7.3275V11.2786C7.3275 9.30761 5.907 8.60411 4.1625 8.60411C2.0325 8.60411 1.125 9.63011 1.035 10.9051H2.859C2.955 10.4206 3.3285 10.0771 4.119 10.0771C4.9095 10.0771 5.415 10.4506 5.415 11.2336V11.9296H3.519C1.7175 11.9296 0.75 12.8521 0.75 14.2441C0.75 15.7096 1.7895 16.6171 3.306 16.6171ZM3.936 15.1966C3.276 15.1966 2.6685 14.8441 2.6685 14.1196C2.6685 13.5271 3.072 13.0936 3.9285 13.0936H5.415V13.9006C5.415 14.6551 4.749 15.1966 3.936 15.1966ZM17.2815 16.0471C16.416 15.4471 15.9315 14.4151 15.9315 13.0726V12.0976C15.9315 9.96761 17.2725 8.59061 19.389 8.59061C21.417 8.59061 22.5675 9.82061 22.5975 11.2996H20.817C20.757 10.7731 20.3925 10.1356 19.44 10.1356C18.429 10.1356 17.8725 10.9111 17.8725 12.1276V13.0651C17.8725 13.7671 18.054 14.3161 18.387 14.6656L17.2815 16.0471Z" fill="#444444"/>
        <path d="M21.7042 14.1212C21.8197 14.2135 21.9159 14.3277 21.9873 14.4573C22.0586 14.5868 22.1038 14.7291 22.1201 14.8761C22.1364 15.0231 22.1235 15.1718 22.0823 15.3138C22.041 15.4559 21.9722 15.5883 21.8797 15.7037L15.8797 23.2037C15.7808 23.3272 15.6569 23.4284 15.5162 23.5008C15.3755 23.5731 15.2211 23.615 15.0632 23.6236C14.9052 23.6322 14.7472 23.6075 14.5994 23.5509C14.4517 23.4943 14.3175 23.4072 14.2057 23.2952L10.4557 19.5452C10.3452 19.4422 10.2565 19.318 10.195 19.18C10.1336 19.042 10.1005 18.8931 10.0978 18.742C10.0952 18.591 10.123 18.4409 10.1795 18.3008C10.2361 18.1607 10.3203 18.0335 10.4272 17.9267C10.534 17.8198 10.6612 17.7356 10.8013 17.679C10.9414 17.6225 11.0914 17.5947 11.2425 17.5973C11.3936 17.6 11.5425 17.6331 11.6805 17.6946C11.8185 17.756 11.9427 17.8447 12.0457 17.9552L14.9077 20.8157L20.1217 14.2967C20.214 14.1812 20.3282 14.085 20.4578 14.0137C20.5873 13.9423 20.7296 13.8972 20.8766 13.8809C21.0236 13.8646 21.1723 13.8774 21.3143 13.9187C21.4563 13.9599 21.5888 14.0287 21.7042 14.1212Z" fill="#444444"/>
        </svg>
    );
}

export default SpellCheckSvg;