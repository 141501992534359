import React from "react";
import { useState, useEffect } from "react";
import Input from "../../../../Components/Profile/Input/Input";
import TextArea from "../../../../Components/Profile/Input/TextArea";
import Button from "../../../../Components/Profile/Button/Button";
import Checkmark from "../../../../Components/Profile/Checkmark/Checkmark";
import DatePicker from "../../../../Components/Profile/DatePicker/DatePicker";
import Loading from "../../../../Components/Loading";
import { ProjectType } from "../Data/SelectData";
import Popup from "../../../../Components/Profile/Popup/PopUp";

const ProjectForm = ({
  title,
  HandleSubmitFunction,
  onClose,
  show,
  isEdit = false,
  editProjectData,
}) => {
  const [projectDetails, setProjectDetails] = useState({
    title: "",
    description: "",
    project_url: "",
    skills: "",
    start_date: "",
    end_date: "",
    type: "",
  });

  const [errors, setErrors] = useState({
    title: false,
    description: false,
    project_url: false,
    skills: false,
    start_date: false,
    end_date: false,
    type: false,
  });

  const [isFormValid, setIsFormValid] = useState(false);
  const [isFormFieldsEmpty, setIsFormFieldsEmpty] = useState(true);
  const [disableProjectEndDate, setDisableProjectEndDate] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isEdit) {
      setProjectDetails({ ...editProjectData });
    }
  }, [isEdit, editProjectData]);

  const validateInput = (name, value) => {
    switch (name) {
      case "description":
        return value.length <= 500;
      case "project_url":
        return value.length <= 100;
      case "title":
        return value.trim() !== "" && value.length <= 100;
      case "skills":
        return value.trim() !== "";
      default:
        return true;
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const isValid = validateInput(name, value);
    setProjectDetails({ ...projectDetails, [name]: value });
    setErrors((prevErrors) => ({ ...prevErrors, [name]: !isValid }));
  };

  const handleDateChange = (date, name) => {
    setProjectDetails({ ...projectDetails, [name]: date });
    const isValid = date !== null;
    setErrors((prevErrors) => ({ ...prevErrors, [name]: !isValid }));
  };

  const handleSelectChange = (selectedOption) => {
    setProjectDetails({
      ...projectDetails,
      type: selectedOption.value,
    });
    const isValid = selectedOption.value !== "";
    setErrors((prevErrors) => ({ ...prevErrors, type: !isValid }));
  };

  const validateForm = () => {
    const newErrors = {};

    Object.keys(projectDetails).forEach((name) => {
      const value = projectDetails[name];
      const isValid = validateInput(name, value);
      newErrors[name] = !isValid;
    });

    setErrors(newErrors);

    const isFormValid = Object.values(newErrors).every((x) => x === false);
    setIsFormValid(isFormValid);
  };

  const validateFormField = async () => {
    const isFormFieldEmpty = Object.values(projectDetails).some(
      (x) => x === ""
    );
    const hasErrors = Object.values(errors).some((x) => x === true);
    setIsFormFieldsEmpty(isFormFieldEmpty || hasErrors);
  };

  useEffect(() => {
    validateFormField();
  }, [projectDetails, errors]);

  const submitForm = async (e) => {
    setLoading(true);
    validateForm();
    e.preventDefault();
    try {
      await HandleSubmitFunction(projectDetails);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Popup title={title} onClose={onClose} show={show}>
      <div className="profile-project-form">
        <div className="profile-exp-form-section">
          <Input
            width="90%"
            name="title"
            label="Project Title"
            inputPlaceholderValue="Enter Project Title"
            value={projectDetails.title !== "" ? projectDetails.title : ""}
            onChangeFunction={handleInputChange}
            inperror={errors.title}
          />
          <Input
            selectWidth="90%"
            name="type"
            label="Project Type"
            isSelect={true}
            selectData={ProjectType}
            selectLabel="Enter Project Type"
            onChangeFunction={handleSelectChange}
            isNoInputinSelect={true}
            inperror={errors.type}
            selectvalue={projectDetails.type !== "" ? projectDetails.type : ""}
          />
        </div>
        <TextArea
          width="100%"
          height="50px"
          name="description"
          label="Project Description"
          inputPlaceholderValue="Enter Project Description"
          value={
            projectDetails.description !== "" ? projectDetails.description : ""
          }
          onChangeFunction={handleInputChange}
          inperror={errors.description}
        />
        <div className="profile-exp-form-section">
          <div className="profile-form-section-date-container">
            <DatePicker
              width="100%"
              name="start_date"
              label="Project Start Date"
              inputPlaceholderValue="Enter Start Date"
              defaultValue={
                projectDetails.start_date !== ""
                  ? new Date(projectDetails.start_date)
                  : ""
              }
              onChangeFunction={(e) => handleDateChange(e, "start_date")}
              inperror={errors.start_date}
            />
          </div>
          <div className="profile-exp-form-section-checkmark">
            <DatePicker
              width="90%"
              name="end_date"
              label="Project End Date"
              isCheckmark={true}
              isDisabled={disableProjectEndDate ? true : false}
              inputPlaceholderValue="Enter End Date"
              defaultValue={
                projectDetails.end_date
                  ? projectDetails.end_date !== "Present" &&
                    new Date(projectDetails.end_date)
                  : ""
              }
              onChangeFunction={(e) => handleDateChange(e, "end_date")}
              inperror={errors.end_date}
            />
            <Checkmark
              label="Ongoing"
              name="end_date"
              setDisableDiv={setDisableProjectEndDate}
              defaultValue={
                projectDetails.end_date === "Present" ? true : false
              }
              changeData="Present"
              handleChangeFunction={(e) => handleDateChange(e, "end_date")}
            />
          </div>
        </div>
        <Input
          width="100%"
          name="project_url"
          label="Project Link"
          inputPlaceholderValue="Enter Project Link"
          value={
            projectDetails.project_url !== "" ? projectDetails.project_url : ""
          }
          onChangeFunction={handleInputChange}
          inperror={errors.project_url}
        />
        <Input
          width="100%"
          name="skills"
          label="Skills Used"
          inputPlaceholderValue="Enter Skills Used"
          value={projectDetails.skills !== "" ? projectDetails.skills : ""}
          onChangeFunction={handleInputChange}
          inperror={errors.skills}
        />
        {loading ? (
          <div className="profile-delete-loading-container">
            <Loading color="#14144e" />
          </div>
        ) : (
          <div className="profile-project-form-section-button">
            <Button
              text="SAVE"
              onClick={isFormFieldsEmpty ? null : submitForm}
              disabled={isFormFieldsEmpty}
            />
          </div>
        )}
      </div>
    </Popup>
  );
};

export default ProjectForm;
