import React from 'react';
import './AssessmentType.css';
import coding from './Assets/coding.png';
import aptitude from './Assets/aptitude.png';
import companywise from './Assets/companywise.png';
import profilecover from "../../../assets/profilecover.png";
import rolecover from "../../../assets/rolecover.png";
import skillcover from "../../../assets/skillcover.png";
import Button from '../../../../../../Components/Profile/Button/Button';
import InterviewStart from '../AssessmentsDash/Pages/InterviewStart';

const AssessmentTypeCard = ({ assessment, handleClick }) => {
    return (
        <div className="st-skillbox-interview-type-card" key={assessment.type_slug} onClick={handleClick}>
            <div className='st-skillbox-interview-type-card__img'>
                <img src={assessment.image} alt={assessment.title} />
            </div>
            <div className="st-skillbox-interview-type-card__title">
                {assessment.title}
            </div>
        </div>
    );
}

const assessmentTypeList = [
    {
        type_slug: "coding",
        title: "Coding Assessment",
        cover: rolecover,
        image: coding,
        keyPoints: [
            "AI-powered feedback on communications",
            "Analyze your performance and identify areas for growth",
            "Comprehensive analytics panel",
            "Improved student-teacher engagement",
        ],
        InterviewStartTitle: "Choose a role you want to interview for",
    },
    {
        type_slug: "aptitude",
        title: "Aptitude Assessment",
        cover: skillcover,
        image: aptitude,
        keyPoints: [
            "Analyze your responses and pinpoint areas for improvement",
            "Evaluation of speech and content",
            "Receive a detailed performance report to track your progress",
            "Get customized tips to enhance your body language and tone",
        ],
        InterviewStartTitle: "Choose a skill you want to interview for",
    },
    {
        type_slug: "company",
        title: "Company wise assessment",
        cover: profilecover,  
        image: companywise,
        keyPoints: [
          "Practice answering a range of common and behavioral interview questions",
          "Real-time feedback on your responses, body language, and tone",
          "Customized tips to enhance your communication and presentation skills",
          "Detailed performance reports to track your progress and identify areas for growth"
        ],  
        InterviewStartTitle: "Upload your resume to get started",
    }
  ];
  

const AssessmentType = ({
    setSelectedTab,
    setSelectedOption,
    setSelectedInterviewType
}) => {

    const goToHome = () => {
        setSelectedOption({
            value: "skillbox",
            label: "Skillbox",
        });
    };


    const handleInterviewData = (data) => {
        setSelectedInterviewType(data);
        setSelectedTab("personalInterview");
    }


    return (
        <>
            <div className="personal-interview-page-nav-menu">
                <div
                    onClick={goToHome}
                    className="personal-interview-page-nav-menu-home"
                >
                    Home
                </div>
            </div>
            <div className="profile-user-resume-header">
                <div className="profile-user-resume-header-title">
                    Assessment
                </div>
                <Button
                    text="View Dashboard"
                    width="180px"
                    onClick={() => setSelectedTab("interviewFeedback")}
                />
            </div>
            <div className="st-skillbox-programs-mn-ct">
                {assessmentTypeList && assessmentTypeList.length > 0 && assessmentTypeList.map((p, i) => (
                    <div key={i}>
                        <AssessmentTypeCard assessment={p} handleClick={() => handleInterviewData(p)} />
                    </div>
                ))}
            </div>
        </>
    );
};

export default AssessmentType;
