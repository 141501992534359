import React, { useState, useEffect } from "react";
import CreatableSelect from "react-select/creatable"; // Import CreatableSelect
import Input from "../../../../Components/Profile/Input/Input";
import Button from "../../../../Components/Profile/Button/Button";
import Popup from "../../../../Components/Profile/Popup/PopUp";
import Loading from "../../../../Components/Loading";

const SkillForm = ({
  title,
  HandleSubmitFunction,
  onClose,
  show,
  isEdit = false,
  editSkillData,
  existingSkillTypes = [],
}) => {
  const [skillDetails, setSkillDetails] = useState({
    skillType: null,
    skill: "",
  });

  const [errors, setErrors] = useState({
    skillType: false,
    skill: false,
  });

  const [isFormFieldsEmpty, setIsFormFieldsEmpty] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isEdit) {
      setSkillDetails({ ...editSkillData });
    }
  }, [isEdit, editSkillData]);

  const validateInput = (name, value) => {
    switch (name) {
      case "skillType":
      case "skill":
        return value.length <= 100;
      default:
        return true;
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const isValid = validateInput(name, value);
    setSkillDetails({ ...skillDetails, [name]: value });
    setErrors((prevErrors) => ({ ...prevErrors, [name]: !isValid }));
  };

  const handleSkillTypeChange = (selectedOption) => {
    setSkillDetails({
      ...skillDetails,
      skillType: selectedOption,
      skill: selectedOption ? skillDetails.skill : "",
    });
  };

  const validateFormField = () => {
    const isFormFieldEmpty = Object.values(skillDetails).some((x) => x === "" || x === null);
    const hasErrors = Object.values(errors).some((x) => x === true);
    setIsFormFieldsEmpty(isFormFieldEmpty || hasErrors);
  };

  useEffect(() => {
    validateFormField();
  }, [skillDetails, errors]);

  const submitForm = async (e) => {
    setLoading(true);
    e.preventDefault();
    try {
      await HandleSubmitFunction(skillDetails);
    } finally {
      setLoading(false);
    }
  };

  const skillTypeOptions = existingSkillTypes.map((type) => ({
    value: type,
    label: type,
  }));

  return (
    <Popup title={title} onClose={onClose} show={show}>
      <div className="profile-exp-form">
        <div className="profile-exp-form-section">
          <label htmlFor="skillType">Select or Create Skill Type</label>
          <CreatableSelect
            id="skillType"
            options={skillTypeOptions}
            value={skillDetails.skillType}
            onChange={handleSkillTypeChange}
            placeholder="Select or Create Skill Type"
          />
        </div>
        {skillDetails.skillType && (
          <Input
            width="90%"
            name="skill"
            label="Skill"
            inputPlaceholderValue="Enter Skill"
            value={skillDetails.skill}
            onChangeFunction={handleInputChange}
            inperror={errors.skill}
          />
        )}
        {loading ? (
          <div className="profile-delete-loading-container">
            <Loading color="#14144e" />
          </div>
        ) : (
          <div className="profile-exp-form-section-button">
            <Button
              text="SAVE"
              onClick={isFormFieldsEmpty ? null : submitForm}
              disabled={isFormFieldsEmpty}
            />
          </div>
        )}
      </div>
    </Popup>
  );
};

export default SkillForm;
