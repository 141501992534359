import React from "react";

export const BulletPointsIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 6.004H2V4H4.004V6.004H3.004H3ZM3 13.004H2V11H4.004V13.004H3.004H3ZM2 20.004H4.004V18H2V19.004V20.004ZM8 4H7V6H22V4H21H8ZM7 11H22V13H7V11ZM8 18H7V20H22V18H21H8Z"
        fill="#434343"
      />
    </svg>
  );
};
