import React from "react";
import PopUp from "../UiComponents/Popup/PopUp";
import "./ViewClass.css";

const ViewClass = ({ classItem, showPopup, setShowPopup }) => (
  <PopUp
    title="Class Details"
    onClose={() => setShowPopup(false)}
    show={showPopup}
  >
    <div className="class_popup__content">
      <div className="class_popup__content-row">
        <div className="class_popup__content-row-title">
          <p>Class ID</p>
        </div>
        <div className="class_popup__content-row-value">
          <p>{classItem.class_id}</p>
        </div>
      </div>
      <div className="class_popup__content-row">
        <div className="class_popup__content-row-title">
          <p>Class Name</p>
        </div>
        <div className="class_popup__content-row-value">
          <p>{classItem.class_name}</p>
        </div>
      </div>
      <div className="class_popup__content-row">
        <div className="class_popup__content-row-title">
          <p>Class Description</p>
        </div>
        <div className="class_popup__content-row-value">
          <p>{classItem.class_description}</p>
        </div>
      </div>
      <div className="class_popup__content-row">
        <div className="class_popup__content-row-title">
          <p>Is Active</p>
        </div>
        <div className="class_popup__content-row-value">
          <p>{classItem.class_lock ? "No" : "Yes"}</p>
        </div>
      </div>
      <div className="class_popup__content-row">
        <div className="class_popup__content-row-title">
          <p>Class Order</p>
        </div>
        <div className="class_popup__content-row-value">
          <p>{classItem.class_order || "N/A"}</p>
        </div>
      </div>
      <div className="class_popup__content-row">
        <div className="class_popup__content-row-title">
          <p>Created On</p>
        </div>
        <div className="class_popup__content-row-value">
          <p>{classItem.class_create_timestamp}</p>
        </div>
      </div>
      <div className="class_popup__content-row">
        <div className="class_popup__content-row-title">
          <p>Last Updated</p>
        </div>
        <div className="class_popup__content-row-value">
          <p>{classItem.class_update_timestamp}</p>
        </div>
      </div>
    </div>
  </PopUp>
);

export default ViewClass;
