import React from 'react'
import Assignments from '../layout/Assignments/Assignments';
import Leads from '../layout/Leads/Pages/Leads';
import AssignmentIconSvg from '../Svgs/AssignmentIconSvg'
import UnderDevelopment from '../Construction/UnderDevelopment';
import Referral from '../layout/Referral/Pages/ReferralCodeDetails';
import { FaUserAlt , FaBook , FaTicketAlt , FaUserGraduate , FaMoneyBillWaveAlt , FaUserFriends } from 'react-icons/fa';


export const AdminSideBarData = [
    {
        title: 'Assignments',
        component: <Assignments />,
        icon: <AssignmentIconSvg />,
    },
    {
        title: 'User Management',
        component: <UnderDevelopment />,
        icon: <FaUserAlt />,
    },
    {
        title: 'Program Management',
        component: <UnderDevelopment />,
        icon: <FaBook />,
    },
    {
        title: 'Leads',
        component: <Leads />,
        icon: <FaUserFriends />,
    },
    {
        title: 'User Queries',
        component: <UnderDevelopment />,
        icon: <FaTicketAlt />,
    },
    {
        title: 'Payments',
        component: <UnderDevelopment />,
        icon: <FaMoneyBillWaveAlt />,
    },
    {
        title: 'Referrals',
        component: <Referral />,
        icon: <FaUserGraduate />,
    },
]
