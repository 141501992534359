import React, { useState } from "react";
import "./Practice.css";
import Dashboard from "./Pages/Dashboard";
import CodingPractice from "./Pages/CodingPractice";
import AptitudePractice from "./Pages/AptitudePractice";
import CompanyPractice from "./Pages/CompanyPractice";

const Practice = () => {
  const [activeTab, setActiveTab] = useState("dashboard");

  const renderTabContent = () => {
    switch (activeTab) {
      case "dashboard":
        return <Dashboard />;
      case "coding":
        return <CodingPractice />;
      case "aptitude":
        return <AptitudePractice />;
      case "company":
        return <CompanyPractice />;
      default:
        return null;
    }
  };

  return (
    <div className="practice-page">
      <h1 className="main-heading">Skillbox Practice </h1>
      <div className="practice-tabs">
        <div
          className={activeTab === "dashboard" ? "practice-active-tab" : ""}
          onClick={() => setActiveTab("dashboard")}
        >
          Dashboard
        </div>
        <div
          className={activeTab === "coding" ? "practice-active-tab" : ""}
          onClick={() => setActiveTab("coding")}
        >
          Coding Practice
        </div>
        <div
          className={activeTab === "aptitude" ? "practice-active-tab" : ""}
          onClick={() => setActiveTab("aptitude")}
        >
          Aptitude Practice
        </div>
        <div
          className={activeTab === "company" ? "practice-active-tab" : ""}
          onClick={() => setActiveTab("company")}
        >
          Company Wise Practice
        </div>
      </div>
      <div className="practice-tab-content">{renderTabContent()}</div>
    </div>
  );
};

export default Practice;
