import React from 'react'
import QtTool from '../layout/QtTool/QtTool';
import Dashboard from '../layout/Dashboard/Pages/Dashboard';
import DashboardSvg from '../Svgs/DashboardSvg'
import HistorySvg from '../Svgs/HistorySvg'
import QCreationSvg from '../Svgs/QCreationSvg';
import QCreationCoursesSvg from '../Svgs/QCreationCoursesSvg';
import QtToolRole from '../layout/Roles/QtToolRole';
import QtToolCourses from '../layout/Courses/QtToolCourses';
export const AdminSideBarData = [
    {
        title: 'Dashboard',
        component: Dashboard,
        icon: <DashboardSvg />,
    },
    {
        title: 'Roles',
        component: QtToolRole,
        icon: <QCreationSvg />,
    },
    {
        title: 'Courses',
        component: QtToolCourses,
        icon: <QCreationCoursesSvg />,
    },
    {
        title: 'My History',
        component: QtTool,
        icon: <HistorySvg />,
    }
];
