import cpp from "../../../../../../assets/skill_icons/cpp.png";
import cssIcon from "../../../../../../assets/skill_icons/css.png";
import htmlIcon from "../../../../../../assets/skill_icons/html.png";
import javaIcon from "../../../../../../assets/skill_icons/java.png";
import pythonIcon from "../../../../../../assets/skill_icons/python.png";
import sqlIcon from "../../../../../../assets/skill_icons/sql.png";
import javascriptIcon from "../../../../../../assets/skill_icons/js.png";
import angular from "../../../../../../assets/skill_icons/angular.png";
import c from "../../../../../../assets/skill_icons/c.png";
import hibernate from "../../../../../../assets/skill_icons/hibernate.png";
import docker from "../../../../../../assets/skill_icons/docker.png";
import django from "../../../../../../assets/skill_icons/django.png";
import mongo from "../../../../../../assets/skill_icons/mongo.png";
import springboot from "../../../../../../assets/skill_icons/springboot.png";
import linux from "../../../../../../assets/skill_icons/linux.png";
import multithreading from "../../../../../../assets/skill_icons/multithreading.png";
import hadoop from "../../../../../../assets/skill_icons/hadoop.png";
import cucumber from "../../../../../../assets/skill_icons/cucumber.png";
import microservices from "../../../../../../assets/skill_icons/microservices.png";
import linkedlist from "../../../../../../assets/skill_icons/linkedlist.png";
import ssis from "../../../../../../assets/skill_icons/ssis.png";
import kafka from "../../../../../../assets/skill_icons/kafka.png";
import ionic from "../../../../../../assets/skill_icons/ionic.png";
import aws from "../../../../../../assets/skill_icons/aws.png";
import git from "../../../../../../assets/skill_icons/git.png";
import azure from "../../../../../../assets/skill_icons/azure.png";
import ml from "../../../../../../assets/skill_icons/ml.png";
import android from "../../../../../../assets/skill_icons/android.png";
import csharp from "../../../../../../assets/skill_icons/csharp.png";
import iot from "../../../../../../assets/skill_icons/iot.png";
import go from "../../../../../../assets/skill_icons/go.png";
import junit from "../../../../../../assets/skill_icons/junit.png";
import sap from "../../../../../../assets/skill_icons/sap.png";
import jenkins from "../../../../../../assets/skill_icons/jenkins.png";
import mvc from "../../../../../../assets/skill_icons/mvc.png";
import mysql from "../../../../../../assets/skill_icons/mysql.png";
import react from "../../../../../../assets/skill_icons/react.png";
import kubernetes from "../../../../../../assets/skill_icons/kubernetes.png";
import mulesoft from "../../../../../../assets/skill_icons/mulesoft.png";
import vue from "../../../../../../assets/skill_icons/vue.png";
import node from "../../../../../../assets/skill_icons/node.png";
import sdet from "../../../../../../assets/skill_icons/sdet.png";
import restapi from "../../../../../../assets/skill_icons/restapi.png";
import selenium from "../../../../../../assets/skill_icons/selenium.png";
import spark from "../../../../../../assets/skill_icons/spark.png";
import plsql from "../../../../../../assets/skill_icons/plsql.png";
import frontend from "../../../../../../assets/skill_icons/frontend.png";
import cicd from "../../../../../../assets/skill_icons/cicd.png";
import pyspark from "../../../../../../assets/skill_icons/pyspark.png";
import warehouse from "../../../../../../assets/skill_icons/warehouse.png";
import snowflake from "../../../../../../assets/skill_icons/snowflake.png";
import java8 from "../../../../../../assets/skill_icons/java8.png";
import bootstrap from "../../../../../../assets/skill_icons/bootstrap.png";
import spring1 from "../../../../../../assets/skill_icons/spring1.png";
import vscode from "../../../../../../assets/skill_icons/vscode.png";
import springsecurity from "../../../../../../assets/skill_icons/springsecurity.png";
import tableau from "../../../../../../assets/skill_icons/tableau.png";
import kotlin from "../../../../../../assets/skill_icons/kotlin.png";
import lambda from "../../../../../../assets/skill_icons/lambda.png";
import gcp from "../../../../../../assets/skill_icons/gcp.png";
import wordpress from "../../../../../../assets/skill_icons/wordpress.png";
import powerapps from "../../../../../../assets/skill_icons/powerapps.png";
import vmware from "../../../../../../assets/skill_icons/vmware.png";
import terraform from "../../../../../../assets/skill_icons/terraform.png";
import devops from "../../../../../../assets/skill_icons/devops.png";
import flutter from "../../../../../../assets/skill_icons/flutter.png";
import informatica from "../../../../../../assets/skill_icons/informatica.png";
import swift from "../../../../../../assets/skill_icons/swift.png";
import ts from "../../../../../../assets/skill_icons/ts.png";
import uipath from "../../../../../../assets/skill_icons/uipath.png";
import j2ee from "../../../../../../assets/skill_icons/j2ee.png";
import datamodelling from "../../../../../../assets/skill_icons/datamodelling.png";
import uft from "../../../../../../assets/skill_icons/uft.png";
import sccm from "../../../../../../assets/skill_icons/sccm.png";
import sitecore from "../../../../../../assets/skill_icons/sitecore.png";
import apium from "../../../../../../assets/skill_icons/apium.png";
import jquery from "../../../../../../assets/skill_icons/jquery.png";
import qlikview from "../../../../../../assets/skill_icons/qlikview.png";
import talend from "../../../../../../assets/skill_icons/talend.png";
import numpy from "../../../../../../assets/skill_icons/numpy.png";
import jpa from "../../../../../../assets/skill_icons/jpa.png";
import rubyonrails from "../../../../../../assets/skill_icons/rubyonrails.png";
import hashmap from "../../../../../../assets/skill_icons/hashmap.png";
import teradata from "../../../../../../assets/skill_icons/teradata.png";
import unix from "../../../../../../assets/skill_icons/unix.png";
import robotframework from "../../../../../../assets/skill_icons/robotframework.png";
import openshift from "../../../../../../assets/skill_icons/openshift.png";
import powerbi from "../../../../../../assets/skill_icons/powerbi.png";
import sapsecurity from "../../../../../../assets/skill_icons/sapsecurity.png";
import checkpoint from "../../../../../../assets/skill_icons/checkpoint.png";
import javastring from "../../../../../../assets/skill_icons/javastring.png";
import sapbasis from "../../../../../../assets/skill_icons/sapbasis.png";
import magento from "../../../../../../assets/skill_icons/magento.png";
import struts from "../../../../../../assets/skill_icons/struts.png";
import salesforce from "../../../../../../assets/skill_icons/salesforce.png";
import datafactory from "../../../../../../assets/skill_icons/datafactory.png";
import perl from "../../../../../../assets/skill_icons/perl.png";
import databrick from "../../../../../../assets/skill_icons/databrick.png";
import r from "../../../../../../assets/skill_icons/r.png";
import loadrunner from "../../../../../../assets/skill_icons/loadrunner.png";
import beehive from "../../../../../../assets/skill_icons/beehive.png";
import matlab from "../../../../../../assets/skill_icons/matlab.png";
import excel from "../../../../../../assets/skill_icons/excel.png";
import agile from "../../../../../../assets/skill_icons/agile.png";
import sapmm from "../../../../../../assets/skill_icons/sapmm.png";
import vbnet from "../../../../../../assets/skill_icons/vbnet.png";
import aspnet from "../../../../../../assets/skill_icons/aspnet.png";

export const skills = [
    { value: "javascript", label: "JavaScript", icon: javascriptIcon },
    { value: "angular", label: "Angular", icon: angular },
    { value: "c", label: "C", icon: c },
    { value: "hibernate", label: "Hibernate", icon: hibernate },
    { value: "cpp", label: "C++", icon: cpp },
    { value: "docker", label: "Docker", icon: docker },
    { value: "django", label: "Django", icon: django },
    { value: "mongodb", label: "MongoDB", icon: mongo },
    { value: "spring-boot", label: "Spring Boot", icon: springboot },
    { value: "linux", label: "Linux", icon: linux },
    { value: "java", label: "Java", icon: javaIcon },
    { value: "multithreading", label: "Multithreading", icon: multithreading },
    { value: "html", label: "HTML", icon: htmlIcon },
    { value: "hadoop", label: "Hadoop", icon: hadoop },
    { value: "cucumber", label: "Cucumber", icon: cucumber },
    { value: "microservices", label: "Microservices", icon: microservices },
    { value: "python", label: "Python", icon: pythonIcon },
    { value: "linked-list", label: "Linked List", icon: linkedlist },
    { value: "sql", label: "SQL", icon: sqlIcon },
    { value: "ssis", label: "SSIS", icon: ssis },
    { value: "kafka", label: "Kafka", icon: kafka },
    { value: "jcl", label: "JCL", icon: javaIcon },
    { value: "iot", label: "IoT", icon: iot },
    { value: "golang", label: "Golang", icon: go },
    { value: "ionic", label: "Ionic", icon: ionic },
    { value: "algorithm", label: "Algorithm", icon: pythonIcon },
    { value: "design-patterns", label: "Design Patterns", icon: javaIcon },
    { value: "junit", label: "JUnit", icon: junit },
    { value: "sap-hana", label: "SAP HANA", icon: sap },
    { value: "mvc", label: "MVC", icon: mvc },
    { value: "db2", label: "DB2", icon: sqlIcon },
    { value: "elasticsearch", label: "Elasticsearch", icon: javaIcon },
    { value: "sql-query", label: "SQL Query", icon: sqlIcon },
    { value: "c-sharp", label: "C#", icon: csharp },
    { value: "web-api", label: "Web API", icon: javascriptIcon },
    { value: "laravel", label: "Laravel", icon: pythonIcon },
    { value: "kubernetes", label: "Kubernetes", icon: kubernetes },
    { value: "aws", label: "AWS", icon: aws },
    { value: "git", label: "Git", icon: git },
    { value: "dbms", label: "DBMS", icon: sqlIcon },
    { value: "azure", label: "Azure", icon: azure },
    { value: "angularjs", label: "AngularJS", icon: angular },
    { value: "ccna", label: "CCNA", icon: pythonIcon },
    { value: "entity-framework", label: "Entity Framework", icon: vscode },
    { value: "mulesoft", label: "Mulesoft", icon: mulesoft },
    { value: "mobile-testing", label: "Mobile Testing", icon: pythonIcon },
    { value: "snowflake", label: "Snowflake", icon: snowflake },
    { value: "selenium", label: "Selenium", icon: selenium },
    { value: "react", label: "React", icon: react },
    { value: "troubleshooting", label: "Troubleshooting", icon: pythonIcon },
    { value: "vue-js", label: "Vue.js", icon: vue },
    { value: "node-js", label: "Node.js", icon: node },
    { value: "oops", label: "OOPs", icon: javaIcon },
    { value: "mysql", label: "MySQL", icon: mysql },
    { value: "operating-system", label: "Operating System", icon: pythonIcon },
    { value: "pl-sql", label: "PL/SQL", icon: plsql },
    { value: "automation-testing", label: "Automation Testing", icon: pythonIcon },
    { value: "spark", label: "Spark", icon: spark },
    { value: "bootstrap", label: "Bootstrap", icon: bootstrap },
    { value: "java-collections", label: "Java Collections", icon: javaIcon },
    { value: "data-warehouse", label: "Data Warehouse", icon: warehouse },
    { value: "spring-security", label: "Spring Security", icon: springsecurity },
    { value: "qa", label: "QA", icon: pythonIcon },
    { value: "functional-testing", label: "Functional Testing", icon: pythonIcon },
    { value: "ci-cd", label: "CI/CD", icon: cicd },
    { value: "sre", label: "SRE", icon: pythonIcon },
    { value: "data-structure", label: "Data Structure", icon: javaIcon },
    { value: "machine-learning", label: "Machine Learning", icon: ml },
    { value: "dot-net", label: ".NET", icon: javaIcon },
    { value: "java-8", label: "Java 8", icon: java8 },
    { value: "networking", label: "Networking", icon: pythonIcon },
    { value: "android", label: "Android", icon: android },
    { value: "rest-api", label: "REST API", icon: restapi },
    { value: "data-science", label: "Data Science", icon: pythonIcon },
    { value: "spring", label: "Spring", icon: spring1 },
    { value: "embedded-c", label: "Embedded C", icon: javaIcon },
    { value: "pyspark", label: "PySpark", icon: pyspark },
    { value: "performance-testing", label: "Performance Testing", icon: pythonIcon },
    { value: "system-design", label: "System Design", icon: javaIcon },
    { value: "front-end-developer", label: "Front-End Developer", icon: frontend },
    { value: "software-engineering", label: "Software Engineering", icon: javaIcon },
    { value: "probability", label: "Probability", icon: pythonIcon },
    { value: "lwc", label: "LWC", icon: javascriptIcon },
    { value: "css", label: "CSS", icon: cssIcon },
    { value: "react-native", label: "React Native", icon: react },
    { value: "tableau", label: "Tableau", icon: tableau },
    { value: "jenkins", label: "Jenkins", icon: jenkins },
    { value: "software-testing", label: "Software Testing", icon: pythonIcon },
    { value: "angular-8", label: "Angular 8", icon: angular },
    { value: "cloud-computing", label: "Cloud Computing", icon: pythonIcon },
    { value: "big-data", label: "Big Data", icon: pythonIcon },
    { value: "servicenow", label: "ServiceNow", icon: javascriptIcon },
    { value: "kotlin", label: "Kotlin", icon: kotlin },
    { value: "aem", label: "AEM", icon: javaIcon },
    { value: "ios", label: "iOS", icon: javaIcon },
    { value: "bgp", label: "BGP", icon: pythonIcon },
    { value: "artificial-intelligence", label: "Artificial Intelligence", icon: pythonIcon },
    { value: "tosca", label: "Tosca", icon: pythonIcon },
    { value: "nlp", label: "NLP", icon: pythonIcon },
    { value: "sdet", label: "SDET", icon: sdet },
    { value: "power-apps", label: "Power Apps", icon: powerapps },
    { value: "openshift", label: "OpenShift", icon: openshift },
    { value: "full-stack-developer", label: "Full-Stack Developer", icon: javascriptIcon },
    { value: "low-level-design", label: "Low-Level Design", icon: javaIcon },
    { value: "wordpress", label: "WordPress", icon: wordpress },
    { value: "sql-joins", label: "SQL Joins", icon: sqlIcon },
    { value: "computer-science", label: "Computer Science", icon: pythonIcon },
    { value: "aws-lambda", label: "AWS Lambda", icon: lambda },
    { value: "gcp", label: "GCP", icon: gcp },
    { value: "linear-regression", label: "Linear Regression", icon: pythonIcon },
    { value: "star", label: "Star", icon: pythonIcon },
    { value: "pandas", label: "Pandas", icon: pythonIcon },
    { value: "devops", label: "DevOps", icon: devops },
    { value: "web-services", label: "Web Services", icon: javaIcon },
    { value: "data-analyst", label: "Data Analyst", icon: pythonIcon },
    { value: "api-testing", label: "API Testing", icon: pythonIcon },
    { value: "etl-testing", label: "ETL Testing", icon: sqlIcon },
    { value: "sdlc", label: "SDLC", icon: javaIcon },
    { value: "unix", label: "Unix", icon: unix },
    { value: "typescript", label: "TypeScript", icon: ts },
    { value: "flutter", label: "Flutter", icon: flutter },
    { value: "vmware", label: "VMware", icon: vmware },
    { value: "informatica", label: "Informatica", icon: informatica },
    { value: "deep-learning", label: "Deep Learning", icon: ml },
    { value: "terraform", label: "Terraform", icon: terraform },
    { value: "wpf", label: "WPF", icon: javaIcon },
    { value: "robot-framework", label: "Robot Framework", icon: robotframework },
    { value: "swift", label: "Swift", icon: swift },
    { value: "teradata", label: "Teradata", icon: teradata },
    { value: "technical-support", label: "Technical Support", icon: pythonIcon },
    { value: "array", label: "Array", icon: javaIcon },
    { value: "hashmap", label: "Hashmap", icon: hashmap },
    { value: "ruby-on-rails", label: "Ruby on Rails", icon: rubyonrails },
    { value: "java-programming", label: "Java Programming", icon: javaIcon },
    { value: "numpy", label: "NumPy", icon: numpy },
    { value: "engineering-manager", label: "Engineering Manager", icon: javaIcon },
    { value: "jpa", label: "JPA", icon: jpa },
    { value: "behavioral", label: "Behavioral", icon: pythonIcon },
    { value: "talend", label: "Talend", icon: talend },
    { value: "jquery", label: "jQuery", icon: jquery },
    { value: "appium", label: "Appium", icon: apium },
    { value: "qlikview", label: "QlikView", icon: qlikview },
    { value: "manual-testing", label: "Manual Testing", icon: pythonIcon },
    { value: "exception-handling", label: "Exception Handling", icon: javaIcon },
    { value: "statistics", label: "Statistics", icon: pythonIcon },
    { value: "power-bi", label: "Power BI", icon: powerbi },
    { value: "cyber-security", label: "Cyber Security", icon: pythonIcon },
    { value: "uipath", label: "UiPath", icon: uipath },
    { value: "j2ee", label: "J2EE", icon: j2ee },
    { value: "data-modelling", label: "Data Modelling", icon: datamodelling },
    { value: "uft", label: "UFT", icon: uft },
    { value: "sccm", label: "SCCM", icon: sccm },
    { value: "sitecore", label: "Sitecore", icon: sitecore },
    { value: "sap-security", label: "SAP Security", icon: sapsecurity },
    { value: "checkpoint", label: "Checkpoint", icon: checkpoint },
    { value: "webmethods", label: "WebMethods", icon: javaIcon },
    { value: "qc", label: "QC", icon: pythonIcon },
    { value: "java-string", label: "Java String", icon: javastring },
    { value: "application-support", label: "Application Support", icon: pythonIcon },
    { value: "sap-basis", label: "SAP Basis", icon: sapbasis },
    { value: "magento-2", label: "Magento 2", icon: magento },
    { value: "struts", label: "Struts", icon: struts },
    { value: "salesforce-admin", label: "Salesforce Admin", icon: javascriptIcon },
    { value: "agile-testing", label: "Agile Testing", icon: agile },
    { value: "vsam", label: "VSAM", icon: javaIcon },
    { value: "web-designer", label: "Web Designer", icon: htmlIcon },
    { value: "azure-data-factory", label: "Azure Data Factory", icon: datafactory },
    { value: "perl", label: "Perl", icon: perl },
    { value: "as400", label: "AS400", icon: javaIcon },
    { value: "azure-databricks", label: "Azure Databricks", icon: databrick },
    { value: "r", label: "R", icon: r },
    { value: "loadrunner", label: "LoadRunner", icon: pythonIcon },
    { value: "hive", label: "Hive", icon: beehive },
    { value: "matlab", label: "MATLAB", icon: matlab },
    { value: "data-engineer", label: "Data Engineer", icon: pythonIcon },
    { value: "excel", label: "Excel", icon: excel },
    { value: "cics", label: "CICS", icon: javaIcon },
    { value: "agile", label: "Agile", icon: agile },
    { value: "sap-mm", label: "SAP MM", icon: sapmm },
    { value: "vb-net", label: "VB.NET", icon: vbnet },
    { value: "asp-net", label: "ASP.NET", icon: aspnet },
];
