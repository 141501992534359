import React from "react";

const DocumentTemplate2 = `<main style="background-color: #fff; display: flex; max-width: 210mm; /* Set to A4 portrait width */ flex-direction: column; padding: 51px 25px;">
    <header style="display: flex; width: 100%; gap: 20px; justify-content: space-between;">
      <h1 style="color: #000; width: 200px; font: 600 24px Inter; text-transform: uppercase; word-wrap: break-word;">
        {{basic_details.name}}
      </h1>      
      <div style="display: flex; gap: 9px;">
        <div style="align-self: start; display: flex; flex-direction: column; align-items: start;">
            <div style="display: flex; align-items: center;">
                <img loading="lazy" src="http://localhost:3000/resumetemplate/mail.png" alt="" style="width: 12px; margin-top: 5px; margin-right: 5px;">
                <div style="font: 600 12px Inter; margin-top: 6px;">{{basic_details.email}}</div>
            </div>
            <div style="display: flex; align-items: center;">
                <img loading="lazy" src="http://localhost:3000/resumetemplate/school.png" alt="" style="width: 12px; margin-top: 5px; margin-right: 5px;">
                <div style="font: 600 12px Inter; margin-top: 6px;">S.R.K.R.Engineering College</div>
            </div>
            <div style="display: flex; align-items: center;">
                <img loading="lazy" src="http://localhost:3000/resumetemplate/phone.png" alt="" style="width: 12px; margin-top: 5px; margin-right: 5px;">
                <div style="font: 600 12px Inter; margin-top: 6px;">{{contact_details.phone}}</div>
            </div>
            <div style="display: flex; align-items: center;">
                <img loading="lazy" src="http://localhost:3000/resumetemplate/location.png" alt="" style="width: 12px; margin-top: 5px; margin-right: 5px;">
                <div style="font: 600 12px Inter; margin-top: 6px;">{{contact_details.state}}, {{contact_details.country}}</div>
            </div>
        </div>
    </div>
    </header>
    <hr style="margin-top: 20px;"/>
    
    <div>
        <h2 style="color: #4e5fff; letter-spacing: 1.1px; text-transform: uppercase; margin-top: 24px; font: 700 14px Inter;">Technical skills :</h2>
        <div style="color: #575757; margin-top: 16px; font: 600 12px Inter;">
          <div style="color: #000000; font: 700 12px Inter">Programming Languages :</div>
          <span>Python, C/C++, MySQL, MS office.</span>
        </div>
        <div style="color: #575757; margin-top: 16px; font: 600 12px Inter;">
          <div style="color: #000000; font: 700 12px Inter">Packages :</div>
          <span>Pandas, NumPy, Plotly, Matplotlib, Seaborn, NLTK, Spacy, OpenCV, Pytorch, PyTesseract, SkLearn.</span>
        </div>
        <div style="color: #575757; margin-top: 16px; font: 600 12px Inter;">
          <div style="color: #000000; font: 700 12px Inter">Algorithms :</div>
          <span>Random Forest, Gradient Boosting, AdaBoost, PCA, XG Boost, Decision Trees, Regression, Logistic Regression.</span>
        </div>
      </div>
    
      <div>
        <h2 style="color: #4e5fff; letter-spacing: 1.1px; text-transform: uppercase; margin-top: 44px; font: 700 14px Inter;">Data Science Projects :</h2>
        <div style="color: #575757; margin-top: 13px; font: 600 12px Inter;">
          <div style="color: #000000; font: 700 12px Inter">Multi-Variate Regression Model for Prediction of Wind Turbine Efficiency</div>
          <ul style="list-style: disc; margin-left: 20px;">
            <li style="display: list-item;">Developed multivariate regression model to predict the efficiency of a Wind Power Plant Turbine.</li>
            <li style="display: list-item;">Build models using Linear Regression, RandomForest Regressor, Adaboost, XGB Regressor and feature selection by EDA, RFE, Forward selection and Backward elimination techniques.</li>
            <li style="display: list-item;">Evaluated model using Adjusted R-squared value (86%) and used statistical hypothesis testing for significance of co-efficients and addressed multi-collinearity using VIF values.</li>
            <li style="display: list-item;">Algorithms & Tools: Python, Sklearn, Linear Regression, XGB Regressor, Adaboost Regressor.</li>
          </ul>        
        </div>
        
        <div style="color: #575757; margin-top: 13px; font: 600 12px Inter;">
          <div style="color: #000000; font: 700 12px Inter">Binary Classification Model for Prediction of Term Deposit Subscription</div>
          <ul style="list-style: disc; margin-left: 20px;">
            <li style="display: list-item;">Developed binary classification predictive model to identify term deposit subscription by customers.</li>
            <li style="display: list-item;">Build model using algorithms like Logistic regression, Random Forest and implemented hyper parameter tuning with GridsearchCV.</li>
            <li style="display: list-item;">Implemented EDA by visualizing relation between variables through various plots and selected business driving features.</li>
            <li style="display: list-item;">Evaluated model using recall score (81%), precision (79%), Gain chart and Lift charts.</li>
            <li style="display: list-item;">Algorithms & Tools: Python, Logistic Regression, Random Forest, Gradient Boosting, AdaBoost.</li>
          </ul>
        </div>
        
        <div style="color: #575757; margin-top: 13px; font: 600 12px Inter;">
          <div style="color: #000000; font: 700 12px Inter">Speech Sentiment Recognition Through Multi-Layer Perceptron Classifier and BERT</div>
          <ul style="list-style: disc; margin-left: 20px;">
            <li style="display: list-item;">Developed a Neural Network Model to recognize Speech and classified it based on the sentiment through Bi-directional encoder representation of transformer architecture.</li>
            <li style="display: list-item;">Recognized speech and converted to text using Multi-Layer Perceptron classifier.</li>
            <li style="display: list-item;">Generation of word embeddings and tokenization of words had been done through BERT.</li>
            <li style="display: list-item;">Evaluated model through recall score (90%), precision (84%).</li>
            <li style="display: list-item;">Algorithms & Tools: Tensorflow, Keras, Gensim, soundfile, librosa, sklearn, MLPClassifier.</li>
          </ul>
        </div>
        
        <div style="color: #575757; margin-top: 13px; font: 600 12px Inter;">
          <div style="color: #000000; font: 700 12px Inter">Content Based Image Search Engine</div>
          <ul style="list-style: disc; margin-left: 20px;">
            <li style="display: list-item;">Developed robust Deep Learning model to extract similar or related images based on the similarity of the input image, through Euclidean distance metric and hashing.</li>
            <li style="display: list-item;">Used AutoEncoder and CNN algorithm for feature extraction from the images and indexed the images based on the features.</li>
            <li style="display: list-item;">Algorithms & Tools: PyTorch, AutoEncoder, Convolutional Neural Networks (CNN).</li>
          </ul>
        </div>
        
        <div style="color: #575757; margin-top: 13px; font: 600 12px Inter;">
          <div style="color: #000000; font: 700 12px Inter">Spam Detection Through BERT</div>
          <ul style="list-style: disc; margin-left: 20px;">
            <li style="display: list-item;">Developed a Deep Learning Model to classify the spam emails through bi-directional encoder representation of transformer architecture.</li>
            <li style="display: list-item;">Generated word embeddings and tokenization of words had been done through Gensim package.</li>
            <li style="display: list-item;">Evaluated model through recall score (96%), precision (93%).</li>
            <li style="display: list-item;">Algorithms & Tools: Tensorflow, Keras, Gensim, sklearn.</li>
          </ul>
        </div>
      </div>      

      <div>
        <h2 style="color: #4e5fff; letter-spacing: 1.1px; text-transform: uppercase; margin-top: 24px; font: 700 14px Inter;">Academic Projects :</h2>
        <div style="color: #575757; margin-top: 13px; font: 600 12px Inter;">
          <div style="color: #000000; font: 700 12px Inter">Data Retrieval through OCR</div>
          <ul style="list-style: disc; margin-left: 20px;">
            <li style="display: list-item;">Designed an optical character recognition model to identify text details from the documents.</li>
            <li style="display: list-item;">Image Optimization through binarization, deskewing and cropping techniques through OpenCV and used PyTesseract to extract the text from images and for layout recognition.</li>
            <li style="display: list-item;">Automatic post-correction of OCR captured prints with integrated optical interactive correction and Neural Network's finite set Transducers.</li>
            <li style="display: list-item;">Algorithms & Tools: PyTesseract, OpenCV, GTTS</li>
          </ul>
        </div>
        <div style="color: #575757; margin-top: 13px; font: 600 12px Inter;">
          <div style="color: #000000; font: 700 12px Inter">Design of Multi-Plate Clutch for KTM 200 Duke Bike</div>
          <ul style="list-style: disc; margin-left: 20px;">
            <li style="display: list-item;">Designed Multi-Plate Clutch based on uniform wear theory for KTM 200 Duke bike model.</li>
            <li style="display: list-item;">Constrained for optimal service conditions for reliable transfer of engine torque to transmission parts</li>
            <li style="display: list-item;">Performed finite element analysis - Dynamic analysis for different working conditions.</li>
            <li style="display: list-item;">Selected Ceramic friction material for better power transmission and heat dissipation.</li>
            <li style="display: list-item;">Design Softwares: AutoCAD, Catia, Ansys.</li>
          </ul>
        </div>
        <h2 style="color: #4e5fff; letter-spacing: 1.1px; text-transform: uppercase; margin-top: 24px; font: 700 14px Inter;">Educational details :</h2>
        <div style="display: flex; margin-top: 16px; width: 100%; gap: 20px; max-width: 100%; flex-wrap: wrap;">
          <div style="flex: 1 1 calc(33.33% - 20px); max-width: calc(33.33% - 20px); min-width: 0;">
            <div style="color: #575757; margin-top: 13px; font: 600 12px Inter;">
              <div style="color: #000000; font: 700 12px Inter;">SSC - SSCAP</div>
              <ul style="list-style: disc; margin-left: 5px;">
                <li style="display: list-item;">Roots School</li>
                <li style="display: list-item;">CGPA - 9.3</li>
              </ul>
            </div>
          </div>
          <div style="flex: 1 1 calc(33.33% - 20px); max-width: calc(33.33% - 20px); min-width: 0;">
            <div style="color: #575757; margin-top: 13px; font: 600 12px Inter;">
              <div style="color: #000000; font: 700 12px Inter;">Diploma - SBTET</div>
              <ul style="list-style: disc; margin-left: 5px;">
                <li style="display: list-item;">S.M.V.M. Polytechnic College</li>
                <li style="display: list-item;">92%</li>
              </ul>
            </div>
          </div>
          <div style="flex: 1 1 calc(33.33% - 20px); max-width: calc(33.33% - 20px); min-width: 0;">
            <div style="color: #575757; margin-top: 13px; font: 600 12px Inter;">
              <div style="color: #000000; font: 700 12px Inter;">B.Tech in Mechanical Engg. - JNTUK</div>
              <ul style="list-style: disc; margin-left: 5px;">
                <li style="display: list-item;">S.R.K.R. Engineering College</li>
                <li style="display: list-item;">CGPA – 9.0</li>
              </ul>
            </div>
          </div>
        </div>        
        <h2 style="color: #4e5fff; letter-spacing: 1.1px; text-transform: uppercase; margin-top: 24px; font: 700 14px Inter;">Area of interest :</h2>
        <ul style="list-style: disc; margin-left: 20px; color: #575757; margin-top: 13px; font: 600 12px Inter;">
          <li style="display: list-item;">Applied Statistics</li>
          <li style="display: list-item;">Machine Learning</li>
          <li style="display: list-item;">Deep Learning</li>
          <li style="display: list-item;">Data structures and algorithms</li>
        </ul>
        <h2 style="color: #4e5fff; letter-spacing: 1.1px; text-transform: uppercase; margin-top: 24px; font: 700 14px Inter;">Achievements and Extra-Curricular Activities:</h2>
        <ul style="list-style: disc; margin-left: 20px; color: #575757; margin-top: 13px; font: 600 12px Inter;">
          <li style="display: list-item;">Winner of CADmanic, contest of creating 3D profiles.</li>
          <li style="display: list-item;">Runner up in SLIDE-2019 PPT presentation contest on Automation of Industrial Practices and Robotics.</li>
          <li style="display: list-item;">Stood 2nd ranking in the academics among the Mechanical Engineering Branch.</li>
          <li style="display: list-item;">Secured 8th rank in TS Engineering Common Entrance Exam (ECET) and 29th rank in AP Engineering Common Entrance Exam (ECET).</li>
          <li style="display: list-item;">Member of National Cadet Corps (NCC)</li>
        </ul>      
    </div>
  </main>
  `;

export default DocumentTemplate2;
