import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router";
import Select from "../../../../../../../Components/Profile/Input/Select";
import Button from "../../../../../../../Components/Profile/Button/Button";
import "./CompanyAssessment.css";
import { useHttpClient } from "../../../../../../../Components/Hooks/http-hook";
import { AuthContext } from "../../../../../../../context/auth-context";
import Loading from "../../../../../../../Components/Loading";
const swal = require("sweetalert2");


const CompanyAssessment = () => {
  const companyBasedTestLeft = 5;
  const history = useNavigate();
  const auth = useContext(AuthContext);
  const { error, clearError, sendRequest, isLoading } = useHttpClient();
  const [buttonLoading, setButtonLoading] = useState(false);

  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");

  const handleStartCompanyTest = async () => {
    if (selectedCompany) {
      try {
        setButtonLoading(true);
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/prep/start-aptitude-test-company-based`,
          "POST",
          JSON.stringify({
            company_id: selectedCompany.value,
          }),
          {
            "Content-Type": "application/json",
            "Refresh-Token": auth.refresh_token,
            Authorization: auth.token,
          }
        );
        if (responseData.data) {
          history(`/aptitude-platform/exam/${responseData.data}`);
          if (document.body.requestFullscreen) {
            document.body.requestFullscreen().catch((e) => {
              console.log("error while full screen ", e);
            });
          }
        } else {
          setButtonLoading(false);
          swal.fire({
            title: "Something went wrong",
            text: "Cannot start the test, please try again later",
            icon: "error",
            confirmButtonText: "Okay",
          });
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const formatSelectCompany = (data) => {
    return data.map((d) => {
      return {
        value: d.company_id,
        label: d.company_name,
      };
    });
  };

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/prep/get_all_companies_for_mcq_questions`,
          "GET",
          null,
          {
            "Content-Type": "application/json",
            "Refresh-Token": auth.refresh_token,
            Authorization: auth.token,
          }
        );
        setCompanies(formatSelectCompany(responseData.data.companies));
      } catch (err) {
        console.log(err);
      }
    };
    fetchCompanies();
  }, []);

  const role = [
    { value: "Software Developer", label: "Software Developer" },
    { value: "Software Engineer", label: "Software Engineer" },
    { value: "Frontend Developer", label: "Frontend Developer" },
    { value: "Backend Developer", label: "Backend Developer" },
    { value: "Full Stack Developer", label: "Full Stack Developer" },
    { value: "Data Analyst", label: "Data Analyst" },
    { value: "Data Scientist", label: "Data Scientist" },
    { value: "Business Analyst", label: "Business Analyst" },
    { value: "System Analyst", label: "System Analyst" },
    { value: "Product Manager", label: "Product Manager" },
    { value: "Project Manager", label: "Project Manager" },
    { value: "UI/UX Designer", label: "UI/UX Designer" },
    { value: "QA Engineer", label: "QA Engineer" },
    { value: "DevOps Engineer", label: "DevOps Engineer" },
    { value: "Cloud Engineer", label: "Cloud Engineer" },
    { value: "Security Engineer", label: "Security Engineer" },
    { value: "Network Engineer", label: "Network Engineer" },
    { value: "Database Administrator", label: "Database Administrator" },
    { value: "System Administrator", label: "System Administrator" },
    { value: "Other", label: "Other" },
  ];

  return (
    <div className="company-based-assessment">
      <div className="company-based-assessment-top">
        <div className="company-based-assessment-test-left">
          <div className="company-based-assessment-title">Company Based Assessment</div>
          <div className="company-based-assessment-subtitle">
            You can schedule a test to get hired by top companies
          </div>
        </div>
        <div className="company-based-assessment-test-right">
          Total Tests Left: {companyBasedTestLeft} / 10
        </div>
      </div>
      <div className="company-based-assessment-filter-container">
        <div className="coding-assessment-selects">
          <div className="coding-assessment-select-1">
            <div className="coding-assessment-select-label">
              Select Companies
            </div>
            <Select
              width="300px"
              isDisabled={false}
              onChange={(value) => setSelectedCompany(value)}
              selectData={companies}
              selectLabel={true}
            />
          </div>
          {/* <div className="coding-assessment-select-2">
            <div className="coding-assessment-select-label">Select Role</div>
            <Select
              width="300px"
              isDisabled={false}
              onChange={(value) => setSelectedRole(value)}
              selectData={role}
              selectLabel={true}
            />
          </div> */}
          <div className="coding-assessment-select-3">
          <Button
            width="350px"
            onClick={handleStartCompanyTest}
            disabled={selectedCompany === "" ? true : false}
            text="Start Test"
          />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyAssessment;
