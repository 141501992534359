import React from "react";
import "./InterviewAttempted.css";

const ScoreDashboardFirst = ({ selectedQuestion, selectedQuestionId }) => {

    const highlightedText = selectedQuestion.personalized_corrected_answer
    .split(/\*\*(.*?)\*\*/)
    .map((part, index) =>
      index % 2 === 0 ? (
        <span key={index}>{part}</span>
      ) : (
        <span key={index} className="interview-feedback__score-dashboard-first-answer-highlighted-text">
          {part}
        </span>
      )
    );

  return (
    <div className="interview-feedback__score-dashboard">
      <div className="interview-feedback__score-dashboard-first-section-1">
        <div className="interview-feedback__score-dashboard-first-title">
          Question {selectedQuestionId} : {selectedQuestion.question}
        </div>
        <div className="interview-feedback__score-dashboard-first-answer">
          <div className="interview-feedback__score-dashboard-first-subtitle">
            Your Response:
          </div>
          {selectedQuestion.attempted_answer}
        </div>
      </div>
      <div className="interview-feedback__score-dashboard-first-section-2">
        <div className="interview-feedback__score-dashboard-first-title">
          Personalized Feedback
        </div>
        <div className="interview-feedback__score-dashboard-first-answer">
            {highlightedText}
        </div>
        <div className="interview-feedback__score-dashboard-first-answer">
          <div className="interview-feedback__score-dashboard-first-subtitle">
            Your Feedback:
          </div>
          {selectedQuestion.answer_feedback}
        </div>
      </div>
    </div>
  );
};

export default ScoreDashboardFirst;
