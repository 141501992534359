import React from "react";

import "./SWOT_List.css";

const SWOT_List = ({ reportData }) => {
  return (
    <ul className="swot-list-container">
      {reportData.map((data) => (
        <div className="swot-list" key={data.id}>
          <div className="swot-title">{data.name}</div>
          <div className="swot-list-item">
            {data.value.map((item, index) => (
              <p className="swot-list-item-value" style={{ color: data.color, backgroundColor: data.bgcolor }} key={index}>{item}</p>
            ))}
          </div>
        </div>
      ))}
    </ul>
  );
};

export default SWOT_List;
