import React, { useEffect } from "react";
import PopUp from "../UiComponents/Popup/PopUp";
import "./AddClass.css";
import { useState, useContext } from "react";
import { AdminContext } from "../../../../context/admin-context";
import { useHttpClient } from "../../../../Components/Hooks/http-hook";
import LoadingSpinner from "../../../../Components/Input/LoadingSpinner";
const swal = require("sweetalert2");

const AddClass = ({ module, showPopup, setShowPopup, onAdd }) => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [buttonLoading, setButtonLoading] = useState(false);
  const admin = useContext(AdminContext);

  const initialClassState = {
    class_name: "",
    class_description: "",
    class_lock: true,
    class_order: null,
  };

  const [classData, setClassData] = useState(initialClassState);

  const [validationErrors, setValidationErrors] = useState({
    class_name: false,
    class_description: false,
    class_order: false,
  });

  const handlechange = (e) => {
    const { name, value } = e.target;

    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: false,
    }));

    setClassData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const validateForm = () => {
    const requiredFields = ["class_name", "class_description", "class_order"];
    const newValidationErrors = {};
    for (const field of requiredFields) {
      if (!classData[field] || classData[field].trim().length === 0) {
        newValidationErrors[field] = true;
      }
    }
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      ...newValidationErrors,
    }));
    return Object.keys(newValidationErrors).length === 0;
  };

  const resetForm = () => {
    setClassData(initialClassState);
    setValidationErrors({
      class_name: false,
      class_description: false,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      setButtonLoading(false);
      return;
    }
    setButtonLoading(true);
    try {
      const response = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/db/class/${module.module_id}`,
        "POST",
        JSON.stringify(classData),
        {
          "Content-Type": "application/json",
          "Refresh-Token": admin.refresh_token,
          Authorization: admin.token,
        }
      );
      if (response) {
        setButtonLoading(false);
        setShowPopup(false);
        onAdd(response.class_);
        swal.fire({
          title: "Success!",
          text: "Class has been added.",
          icon: "success",
          confirmButtonText: "Close",
        });
      }
    } catch (err) {
      setButtonLoading(false);
      swal.fire({
        title: "Error!",
        text: err.message,
        icon: "error",
        confirmButtonText: "Close",
      });
    }
  };

  useEffect(() => {
    if (error) {
      setButtonLoading(false);
      setShowPopup(false);
      swal.fire({
        title: "Error!",
        text: error || "Something went wrong, please try again later.",
        icon: "error",
        confirmButtonText: "Close",
      });
      clearError();
    }
  }
  , [error, clearError, setShowPopup]);

  return (
    <PopUp
      title="Add Class"
      onClose={() => {
        resetForm();
        setShowPopup(false);
      }}
      show={showPopup}
    >
      {buttonLoading ? (
        <div className="add_class_popup__loading">
          <LoadingSpinner />
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="add_class_popup__content">
            <div className="add_class_popup__content-row">
              <div className="add_class_popup__content-row-title">
                <p>Class Name</p>
              </div>
              <div className="add_class_popup__content-row-value">
                <input
                  type="text"
                  placeholder="Enter Class Name"
                  onChange={handlechange}
                  name="class_name"
                  className={
                    validationErrors.class_name
                      ? "input_val_error"
                      : ""
                  }
                />
                {validationErrors.class_name && (
                  <small className="input_val_error-text">
                    Class Name is required
                  </small>
                )}
              </div>
            </div>
            <div className="add_class_popup__content-row">
              <div className="add_class_popup__content-row-title">
                <p>Class Description</p>
              </div>
              <div className="add_class_popup__content-row-value">
                <input
                  type="text"
                  placeholder="Enter Class Description"
                  onChange={handlechange}
                  name="class_description"
                  className={
                    validationErrors.class_description
                      ? "input_val_error"
                      : ""
                  }
                />
                {validationErrors.class_description && (
                  <small className="input_val_error-text">
                    Class Description is required
                  </small>
                )}
              </div>
            </div>
            <div className="add_class_popup__content-row">
              <div className="add_class_popup__content-row-title">
                <p>Is Active</p>
              </div>
              <div className="add_class_popup__content-row-value">
                <select onChange={handlechange} name="class_lock">
                  <option value="false">Live</option>
                  <option value="true">Not Live</option>
                </select>
              </div>
            </div>
            <div className="add_class_popup__content-row">
              <div className="add_class_popup__content-row-title">
                <p>Class Order</p>
              </div>
              <div className="add_class_popup__content-row-value">
                <input
                  type="number"
                  placeholder="Enter Class Order"
                  onChange={handlechange}
                  name="class_order"
                  className={
                    validationErrors.class_order
                      ? "input_val_error"
                      : ""
                  }
                />
                {validationErrors.class_order && (
                  <small className="input_val_error-text">
                    Class Order is required
                  </small>
                )}
              </div>
            </div>
            <div className="add_class_popup__buttons">
              <button
                className="add_class_popup__buttons-cancel"
                type="button"
                onClick={() => setShowPopup(false)}
              >
                Cancel
              </button>
              <button
                className="add_class_popup__buttons-save"
                type="submit"
              >
                Add
              </button>
            </div>
          </div>
        </form>
      )}
    </PopUp>
  );
};
export default AddClass;
