import React, { useState } from 'react';
import Info from '../../Components/Info';
import Course from './pages/Course';
import SelectLevel from './pages/SelectLevel';
import { courses } from './data/courses';

const CourseBasedInterview = ({ 
  startInterviewHandler, 
  selectedInterviewType, 
  handleGoToHome, 
  handleGoToAllInterview 
}) => {
  const [currentPage, setCurrentPage] = useState('info');
  const [selectedLevel, setSelectedLevel] = useState("");
  const [selectedCourse, setSelectedCourse] = useState("");

  const level = [
    { value: "beginner", label: "Beginner" },
    { value: "intermediate", label: "Intermediate" },
    { value: "advanced", label: "Advanced" },
  ];

  const handleShowCourse = () => {
    setCurrentPage('course');
  };

  const handleShowSelectLevel = () => {
    setCurrentPage('selectLevel');
  };

  return (
    <>
      {currentPage === 'info' && (
        <Info
          selectedInterviewType={selectedInterviewType}
          handleGoToHome={handleGoToHome}
          handleGoToAllInterview={handleGoToAllInterview}
          onNext={handleShowCourse}
        />
      )}
      {currentPage === 'course' && (
        <Course
          selectedInterviewType={selectedInterviewType}
          courses={courses}
          setSelectedCourse={setSelectedCourse}
          selectedCourse={selectedCourse}
          handleGoToHome={handleGoToHome}
          handleGoToAllInterview={handleGoToAllInterview}
          handleGoToCourseBased={() => setCurrentPage('info')}
          onNext={handleShowSelectLevel}
        />
      )}
      {currentPage === 'selectLevel' && (
        <SelectLevel
          selectedInterviewType={selectedInterviewType}
          startInterviewHandler={startInterviewHandler}
          level={level}
          setSelectedLevel={setSelectedLevel}
          selectedLevel={selectedLevel}
          selectedCourse={selectedCourse}
          handleGoToHome={handleGoToHome}
          handleGoToAllInterview={handleGoToAllInterview}
          handleGoToCourses={() => setCurrentPage('info')}
          handleGoToSelectCourse={() => setCurrentPage('course')}
        />
      )}
    </>
  );
};

export default CourseBasedInterview;
