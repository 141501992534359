import React from "react";
import PopUp from "../UiComponents/Popup/PopUp";
import "./DeleteSection.css";
import { useState, useContext, useEffect } from "react";
import { AdminContext } from "../../../../context/admin-context";
import { useHttpClient } from "../../../../Components/Hooks/http-hook";
import LoadingSpinner from "../../../../Components/Input/LoadingSpinner";
const swal = require("sweetalert2");

const DeleteSection = ({ section, showPopup, setShowPopup, onDelete }) => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const admin = useContext(AdminContext);
  const [buttonLoading, setButtonLoading] = useState(false);

  const deleteSection = async (e) => {
    e.preventDefault();
    setButtonLoading(true);
    try {
      const response = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/db/section/${section.section_id}`,
        "DELETE",
        null,
        {
          "Content-Type": "application/json",
          "Refresh-Token": admin.refresh_token,
          Authorization: admin.token,
        }
      );
      if (response) {
        setButtonLoading(false);
        setShowPopup(false);
        onDelete({ 
          section_id: section.section_id 
        });
        swal.fire({
          title: "Section Deleted Successfully",
          icon: "success",
          confirmButtonText: "Okay",
        });
      }
    } catch (err) {
      setButtonLoading(false);
      swal.fire({
        title: "Error",
        text: err.message || "Something went wrong",
        icon: "error",
        confirmButtonText: "Okay",
      });
    }
  };

  useEffect(() => {
    if (error) {
      setButtonLoading(false);
      setShowPopup(false);
      swal.fire({
        title: "Error!",
        text: error || "Something went wrong, please try again later.",
        icon: "error",
        confirmButtonText: "Close",
      });
      clearError();
    }
  }
  , [error, clearError, setShowPopup]);

  return (
    <PopUp onClose={() => setShowPopup(false)} show={showPopup}>
      {buttonLoading ? (
        <div className="delete_section_popup__loading">
          <LoadingSpinner />
        </div>
      ) : (
        <div className="delete_section_popup__content">
          <div className="delete_section_popup__content-row">
            <div className="delete_section_popup__content-row-title">
              <p>{section.section_name}</p>
            </div>
            Are you sure you want to delete this section, This action can't be
            undone?
            <div className="delete_section_popup__buttons">
              <button
                className="delete_section_popup__buttons-cancel"
                onClick={() => setShowPopup(false)}
              >
                Cancel
              </button>
              <button
                className="delete_section_popup__buttons-delete"
                onClick={deleteSection}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </PopUp>
  );
};
export default DeleteSection;
