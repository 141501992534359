import React, { useState, useEffect, useContext } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars-2";

import './ListAssignments.css'
import { AuthContext } from '../../context/auth-context'
import { useHttpClient } from '../../Components/Hooks/http-hook'
import MentorGrageAssignment from './MentorGrageAssignment';

const ListAssignments = () => {

    const [assignmentInfoList, setAssignmentInfoList] = useState([])

    const [assignTitle, setAssignTitle] = useState()
    const [surgeID, setsurgeID] = useState()
    const [deadline, setdeadline] = useState()
    const [status, setStatus] = useState()
    const [submitOn, setSubmitOn] = useState()
    const [mentor, setMentor] = useState()

    const [pop, setPop] = useState(false)

    const auth = useContext(AuthContext);

    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    let { cid, title } = useParams()

    const togglePopup = () => {
        setPop(!pop);
    }

    
    const handleGradeClick = (assignment) => {
        console.log(assignment)
        setAssignTitle(assignment.assignment.title)
        setsurgeID(assignment.assignment.student_surge_id)
        setdeadline(assignment.assignment.deadline)
        setStatus(assignment.assignment.cur_status)
        setSubmitOn(assignment.assignment.create_timestamp)
        setMentor(assignment.assignment.mentor)
        setPop(true)
    }

    const getAssignmentDetails = async() => {
        try {   
            const responseData = await sendRequest(
                `${process.env.REACT_APP_BACKEND_URL}/db/mentor-course-details/${auth.surge_id}/${cid}`,
                "get",              
                );
                if (responseData.ok) {
                    return responseData.data
                }
            } catch (err) {
                console.log(err)
            }
        }
    
    useEffect(() => {
        getAssignmentDetails().then(
            response => setAssignmentInfoList(response)
            )
        }, [])
    
return (
    <div className='list-assignments'>
        <Scrollbars
            autoHide
            autoHideTimeout={2000}
            autoHideDuration={310}
            autoHeightMin={300}
            autoHeightMax={800}
            thumbMinSize={30}
            universal={true}
            style={{
            width: `100%`,
            height: "15rem",
            background: "white",
            borderRadius: "6px",
            }}
        >
            <div className='assign-list'>
                {assignmentInfoList && assignmentInfoList.length != 0 &&
                    <tr class="list-assign-row-table" cellSpacing={"1px"}>
                        <th>Id</th>
                        <th>Title</th>
                        <th>Deadline</th>
                        <th>Status</th>
                    </tr>
                }
                {assignmentInfoList && assignmentInfoList.length != 0 && assignmentInfoList.map((assignment, i)=> {
                    return(
                        <tr class="list-assign-row-table" cellSpacing={"1px"}>
                            <td>{assignment.student_surge_id}</td>
                            <td>{assignment.title}</td>
                            <td>{assignment.deadline}</td>
                            <td>{assignment.cur_status}</td>
                            <td><button onClick={()=>handleGradeClick({assignment})}>View</button></td>
                        </tr>
                    )
                }) 
                }
            </div>
        </Scrollbars>
        {pop && <MentorGrageAssignment 
                    handleClose={togglePopup} 
                    programId={cid} assignmentDetails={{"title":assignTitle,"surge_id":surgeID,"deadline":deadline,"status":status,"submit_on":submitOn,"mentor":mentor}}>
                </MentorGrageAssignment>
        }
    </div>
  )
}

export default ListAssignments