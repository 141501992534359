import React from 'react';
import { javascript } from '@codemirror/legacy-modes/mode/javascript';
import { python } from '@codemirror/legacy-modes/mode/python';
import { clike } from '@codemirror/legacy-modes/mode/clike';

export const codingLanguages = ["Python", "JavaScript", "C", "C++", "Java", "PHP"];

export const codingLanguageExtensions = {
    Python: python,
    JavaScript: javascript,
    C: javascript,
    "C++": javascript,
    Java: javascript,
    PHP: javascript,
};

export const codingLanguageDefaultCodes = {
    C: `#include <stdio.h>
        int main() {
            printf("Hello World");
            return 0;
        }`,
    "C++": `#include <iostream>
        using namespace std;
        int main() {
            cout << "Hello World!";
            return 0;
        }`,
    Java: `class Main {
            public static void main(String[] args) {
            System.out.println("Hello World");
            }
        }`,
    Python: `print("Hello World")`,
    JavaScript: `// Standard input setup for Skillbox
process.stdin.resume();
process.stdin.setEncoding('utf-8');
let input = '';

// Read input from stdin
process.stdin.on('data', function (data) {
    input += data;
});

// Process input when the stdin stream ends
process.stdin.on('end', function () {
    main(input);
});

// Main function where the logic will be implemented
function main(input) {
    // Parse the input as needed
    const parsedInput = parseInput(input);

    // Uncomment and implement the logic below
    // const result = yourLogicFunction(parsedInput);

    // Output the result
    // process.stdout.write(result.toString());
}

// Function to parse input if needed
function parseInput(input) {
    // Implement parsing logic if needed
    // Example: return parseInt(input.trim());
}

// Uncomment and implement the actual logic of the problem
// function yourLogicFunction(parsedInput) {
//   // Your logic goes here
// }`,
    "C#": `using System;
        class Program {
            static void Main(string[] args) {
            Console.WriteLine("Hello World");
            }
        }`,
    PHP: `<?php
        echo "Hello World";
        ?>`,
    Go: `package main
        import "fmt"
        func main() {
            fmt.Println("Hello World")
        }`,
    Ruby: `puts "Hello World"`,
    Swift: `print("Hello World")`,
    Rust: `fn main() {
            println!("Hello World");
        }`,
    TypeScript: `console.log("Hello World")`,
    Kotlin: `fun main() {
            println("Hello World")
        }`,
  };