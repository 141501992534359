import React, { useState, useRef } from "react";
import styled from "styled-components";
import "../components/toolbar.css";
import Toolbar from '../components/Toolbar';
import html2pdf from "html2pdf.js";
import { saveAs } from "file-saver";
import htmlDocx from "html-docx-js/dist/html-docx";

const EditorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ContentEditable = styled.div`
  flex: 1;
  background-color: #ffffff;
  overflow-y: auto;
  margin-top: 10px;
  justify-content: center;
  outline: none; /* Remove the default focus outline */
  &:focus {
    outline: none; /* Ensure no outline on focus */
  }
`;

const DocumentEditor = ({ initialContent }) => {
  const [content, setContent] = useState(initialContent);
  const [selectedColor, setSelectedColor] = useState("#000000");

  const contentEditableRef = useRef(null);

  const handleCommand = (command) => {
    document.execCommand(command);
  };

  const handleCreateLink = () => {
    const url = window.prompt("Enter the URL:");
    if (url) {
      document.execCommand("createLink", false, url);
    }
  };

  const handleColorChange = (color) => {
    setSelectedColor(color);
  };

  const handleDownloadAsPdf = () => {
    const element = contentEditableRef.current;
    const options = {
      margin: 0.5,
      filename: "resume.pdf",
      image: { type: "png", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      pagebreak: { mode: ["css", "legacy"] },
    };
    html2pdf().from(element).set(options).save();
  };

  const generateDOCX = () => {
    const element = contentEditableRef.current;
    const htmlString = element.innerHTML;
    const docxBlob = htmlDocx.asBlob(htmlString);
    saveAs(docxBlob, "resume.docx");
  }

  return (
    <>
      <div className="section-title">Edit Resume </div>
      <div className="section-content">
        <EditorWrapper>
          <Toolbar 
            handleCommand={handleCommand}
            handleCreateLink={handleCreateLink}
            handleColorChange={handleColorChange}
            handleDownloadAsPdf={handleDownloadAsPdf}
            generateDOCX={generateDOCX}
          />
          <ContentEditable
            ref={contentEditableRef}
            contentEditable={true}
            dangerouslySetInnerHTML={{ __html: content }}
            onBlur={() => setContent(contentEditableRef.current.innerHTML)}
          />
        </EditorWrapper>
      </div>
    </>
  );
};

export default DocumentEditor;
