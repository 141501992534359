import React, { useState } from "react";
import "./InterviewPreperation.css";
import InterviewType from "./InterviewType/InterviewType";
import PersonalInterview from "./PersonalInterview/PersonalInterview";
import InterviewFeedback from "./InterviewFeedback/InterviewFeedback";

const InterviewPreperation = ({ setSelectedOption }) => {
  const [selectedTab, setSelectedTab] = useState("interviewHome");
  const [selectedInterviewType, setSelectedInterviewType] = useState({
    type_slug: "",
    title: "",
    cover: null,
    image: null,
    keyPoints: [],
  });

  const tabComponents = {
    interviewHome: InterviewType,
    personalInterview: PersonalInterview,
    interviewFeedback: InterviewFeedback,
  };

  const SelectedComponent = tabComponents[selectedTab];

  return (
    <div>
      <SelectedComponent
        setSelectedTab={setSelectedTab}
        setSelectedOption={setSelectedOption}
        setSelectedInterviewType={setSelectedInterviewType}
        selectedInterviewType={selectedInterviewType}
      />
    </div>
  );
};

export default InterviewPreperation;
