import React, { useState, useRef, useEffect, useContext } from "react";
import "./About.css";
import Input from "../../../../Components/Profile/Input/Input";
import DatePicker from "../../../../Components/Profile/DatePicker/DatePicker";
import { useHttpClient } from "../../../../Components/Hooks/http-hook";
import { AuthContext } from "../../../../context/auth-context";
import Loading from "../../../../Components/Loading";
import { ToastContainer, toast } from "react-toastify";

const About = () => {
  const [userDetails, setUserDetails] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    institute: "",
    course_name: "",
    gender: "",
    year_of_passing: "",
    primary_specialization: "",
    university: "",
    language_known: "",
    date_of_birth: "",
    profile_pic_url: "",
  });

  const [pageLoading, setPageLoading] = useState(true);
  const [profilePicUrl, setProfilePicUrl] = useState("");
  const [autosave, setAutosave] = useState(false);
  const [updateRequired, setUpdateRequired] = useState(false);

  const profilePic = useRef(null);

  const { sendRequest } = useHttpClient();
  const auth = useContext(AuthContext);
  const { setUserProfilePic } = useContext(AuthContext);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserDetails({ ...userDetails, [name]: value });
    setUpdateRequired(true);
  };

  const handleUploadProfilePic = (e) => {
    if (
      e.target.files[0] &&
      e.target.files[0].size &&
      e.target.files[0].size > 10000000
    ) {
      toast("File size should be less than 10MB", {
        autoClose: 3000,
        type: "error",
      });
    } else {
      setProfilePicUrl(URL.createObjectURL(e.target.files[0]));
      uploadProfilePic(e.target.files[0]);
    }
  };

  const handleDateChange = (date, name) => {
    setUserDetails({ ...userDetails, [name]: date });
    setUpdateRequired(true);
  };

  const splitNameToFirstNameMiddleNameLastName = (name) => {
    const [firstName, ...rest] = name.split(" ");

    return {
      firstName,
      middleName: rest.slice(0, -1).join(" "),
      lastName: rest.slice(-1).join(" "),
    };
  };

  const isValidUrl = (string) => {
    try {
      new URL(string);
    } catch (_) {
      return false;
    }
    return true;
  };

  const updateUserDetails = async () => {
    setAutosave(true);
    await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/profile/about`,
      "PUT",
      JSON.stringify(userDetails),
      {
        "Content-Type": "application/json",
        "Refresh-Token": auth.refresh_token,
        Authorization: auth.token,
      }
    );
    setAutosave(false);
  };

  const uploadProfilePic = async (file) => {
    setAutosave(true);
    Blob.prototype.convertToBase64 = function () {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onerror = reject;
        reader.onload = (e) => resolve(e.target.result);
        reader.readAsDataURL(this);
      });
    };
    const base64 = await file.convertToBase64();
    const responseData = await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/profile/about/profile_pic`,
      "POST",
      JSON.stringify({ profile_pic: base64 }),
      {
        "Content-Type": "application/json",
        "Refresh-Token": auth.refresh_token,
        Authorization: auth.token,
      }
    );
    if (responseData.data) {
      if (isValidUrl(responseData.data)) {
        setUserProfilePic(responseData.data);
      } else {
        setUserProfilePic(
          `${process.env.REACT_APP_BACKEND_URL}/${responseData.data}`
        );
      }
    }
    setAutosave(false);
  };

  useEffect(() => {
    if (updateRequired) {
      updateUserDetails();
      setUpdateRequired(false);
    }
  }, [updateRequired]);

  useEffect(() => {
    const getUserBasicDetails = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/profile/about`,
          "GET",
          null,
          {
            "Content-Type": "application/json",
            "Refresh-Token": auth.refresh_token,
            Authorization: auth.token,
          }
        );
        const splitNames = splitNameToFirstNameMiddleNameLastName(
          responseData.data.name
        );
        setUserDetails({
          ...userDetails,
          ...responseData.data,
          ...splitNames,
        });
        if (responseData.data.profile_pic_url) {
          if (isValidUrl(responseData.data.profile_pic_url)) {
            setProfilePicUrl(responseData.data.profile_pic_url);
          } else {
            setProfilePicUrl(
              `${process.env.REACT_APP_BACKEND_URL}/${responseData.data.profile_pic_url}`
            );
          }
        }

        setPageLoading(false);
      } catch (err) {}
    };
    getUserBasicDetails();
  }, []);

  return (
    <div className="profile-about-page">
      <ToastContainer closeButton={false} />
      <div className="profile-header">
        <div className="profile-header-title">My Profile - Basic Details</div>
        <div className="profile-autosave">
          {autosave ? <>Autosaving...</> : <>Autosave is on</>}
        </div>
      </div>
      {pageLoading ? (
        <div className="profile-loader-container">
          <div className="profile-loader-sub-container">
            <Loading
              color="#14144e"
              customClass="profile-loader-container-for-loader"
            />
          </div>
        </div>
      ) : (
        <div className="profile-input-section">
          <div className="profile-input-section-container-1">
            <div className="profile-image-container">
              <div className="profile-image">
                {profilePicUrl ? (
                  <img
                    src={profilePicUrl}
                    alt="Profile"
                    width="100%"
                    height="100%"
                  />
                ) : (
                  <svg
                    width="90"
                    height="90"
                    viewBox="0 0 110 110"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M76.6663 44.1667C76.6663 49.913 74.3836 55.424 70.3203 59.4873C66.257 63.5506 60.746 65.8333 54.9997 65.8333C49.2533 65.8333 43.7423 63.5506 39.679 59.4873C35.6157 55.424 33.333 49.913 33.333 44.1667C33.333 38.4203 35.6157 32.9093 39.679 28.846C43.7423 24.7827 49.2533 22.5 54.9997 22.5C60.746 22.5 66.257 24.7827 70.3203 28.846C74.3836 32.9093 76.6663 38.4203 76.6663 44.1667Z"
                      fill="#BFC3C5"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M52.7897 109.123C23.8999 107.964 0.833008 84.1738 0.833008 54.9997C0.833008 25.0834 25.0834 0.833008 54.9997 0.833008C84.9159 0.833008 109.166 25.0834 109.166 54.9997C109.166 84.9159 84.9159 109.166 54.9997 109.166C54.7523 109.168 54.5049 109.168 54.2576 109.166C53.7674 109.166 53.2772 109.15 52.7897 109.123ZM20.2409 89.1788C19.8359 88.0158 19.6981 86.7765 19.8376 85.5529C19.9771 84.3293 20.3904 83.1528 21.0469 82.1108C21.7033 81.0688 22.586 80.188 23.6294 79.5338C24.6728 78.8796 25.8501 78.4689 27.074 78.332C48.1882 75.9947 61.9411 76.2059 82.9524 78.3807C84.1779 78.5083 85.3582 78.914 86.4033 79.5667C87.4484 80.2194 88.3307 81.102 88.9831 82.1472C89.6356 83.1925 90.0409 84.3728 90.1682 85.5984C90.2955 86.824 90.1415 88.0624 89.7178 89.2195C98.7228 80.1084 103.766 67.8099 103.75 54.9997C103.75 28.0761 81.9232 6.24967 54.9997 6.24967C28.0761 6.24967 6.24967 28.0761 6.24967 54.9997C6.24967 68.3138 11.5878 80.3822 20.2409 89.1788Z"
                      fill="#BFC3C5"
                    />
                  </svg>
                )}
              </div>
              <input
                type="file"
                onChange={handleUploadProfilePic}
                style={{ display: "none" }}
                ref={profilePic}
                multiple={false}
                accept=".jpg, .jpeg, .png"
              />
              <div
                className="upload-profile-pic-button"
                onClick={() => profilePic.current.click()}
              >
                Upload Profile Pic
              </div>
            </div>
            <div className="profile-input-name-email-container">
              <div className="profile-input-name-container">
                <div className="profile-input-first-name-container">
                  <Input
                    width="100%"
                    inputPlaceholderValue="Enter your first name"
                    isDisabled={true}
                    name="firstName"
                    label="First Name"
                    value={
                      userDetails.firstName !== ""
                        ? userDetails.firstName
                        : null
                    }
                  />
                </div>
                <div className="profile-input-middle-name-container">
                  <Input
                    width="100%"
                    inputPlaceholderValue="Enter your middle name"
                    isDisabled={true}
                    name="middleName"
                    label="Middle Name"
                    value={
                      userDetails.middleName !== ""
                        ? userDetails.middleName
                        : null
                    }
                  />
                </div>
                <div className="profile-input-last-name-container">
                  <Input
                    width="100%"
                    inputPlaceholderValue="Enter your last name"
                    isDisabled={true}
                    name="lastName"
                    label="Last Name"
                    value={
                      userDetails.lastName !== "" ? userDetails.lastName : null
                    }
                  />
                </div>
              </div>
              <div className="profile-input-email-container">
                <Input
                  width="100%"
                  inputPlaceholderValue="Enter your email ID"
                  isDisabled={true}
                  name="email"
                  label="Email ID"
                  value={userDetails.email !== "" ? userDetails.email : null}
                />
              </div>
            </div>
          </div>
          <div className="profile-input-section-container-2">
            <div className="profile-input-section-container-left">
              <div className="profile-input-college-container">
                <Input
                  width="100%"
                  inputPlaceholderValue="Enter Institute name"
                  onChangeFunction={handleInputChange}
                  name="institute"
                  label="Institute"
                  value={
                    userDetails.institute !== "" ? userDetails.institute : null
                  }
                />
              </div>
              <div className="profile-input-year-of-passing-container">
                <Input
                  width="100%"
                  inputPlaceholderValue="Enter year of passing"
                  onChangeFunction={handleInputChange}
                  name="year_of_passing"
                  label="Year of Passing"
                  value={
                    userDetails.year_of_passing !== ""
                      ? userDetails.year_of_passing
                      : null
                  }
                />
              </div>
              <div className="profile-input-university-container">
                <Input
                  width="100%"
                  inputPlaceholderValue="Enter university name"
                  onChangeFunction={handleInputChange}
                  name="university"
                  label="University"
                  value={
                    userDetails.university !== ""
                      ? userDetails.university
                      : null
                  }
                />
              </div>
              <div className="profile-input-gender-container">
                <Input
                  width="100%"
                  inputPlaceholderValue="Enter your Gender"
                  onChangeFunction={handleInputChange}
                  name="gender"
                  label="Gender"
                  value={userDetails.gender !== "" ? userDetails.gender : null}
                />
              </div>
            </div>
            <div className="profile-input-section-container-right">
              <div className="profile-input-course-container">
                <Input
                  width="100%"
                  inputPlaceholderValue="Enter your course name"
                  onChangeFunction={handleInputChange}
                  name="course_name"
                  label="Course"
                  value={
                    userDetails.course_name !== ""
                      ? userDetails.course_name
                      : null
                  }
                />
              </div>
              <div className="profile-input-primary-specialization-container">
                <Input
                  width="100%"
                  inputPlaceholderValue="Enter primary specialization"
                  onChangeFunction={handleInputChange}
                  name="primary_specialization"
                  label="Primary Specialization"
                  value={
                    userDetails.primary_specialization !== ""
                      ? userDetails.primary_specialization
                      : null
                  }
                />
              </div>
              <div className="profile-input-languages-known-container">
                <Input
                  width="100%"
                  inputPlaceholderValue="Enter languages known"
                  onChangeFunction={handleInputChange}
                  name="language_known"
                  label="Languages Known"
                  value={
                    userDetails.language_known !== ""
                      ? userDetails.language_known
                      : null
                  }
                />
              </div>
              <div className="profile-input-date-of-birth-container">
                <DatePicker
                  width="100%"
                  name="date_of_birth"
                  label="Date of Birth"
                  isCheckmark={true}
                  inputPlaceholderValue="December 31, 2020"
                  onChangeFunction={(e) => handleDateChange(e, "date_of_birth")}
                  defaultValue={
                    userDetails.date_of_birth
                      ? new Date(userDetails.date_of_birth)
                      : null
                  }
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default About;
