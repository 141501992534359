import React from 'react';
import TickSvg from '../../../../assets/svgs/TickSvg';
import Button from "../../../../../../../Components/Profile/Button/Button";

const Info = ({ selectedInterviewType, handleGoToHome, handleGoToAllInterview, onNext }) => {
  return (
    <div className="interview-preperation-personal-interview-page">
      <div className="interview-preperation-personal-interview-page-nav-menu">
        <div
          onClick={handleGoToHome}
          className="interview-preperation-personal-interview-page-nav-menu-home"
        >
          Home
        </div>
        &nbsp; &gt; &nbsp;
        <div
          onClick={handleGoToAllInterview}
          className="interview-preperation-personal-interview-page-nav-menu-other"
        >
          All Interview
        </div>
      </div>
      <div className="interview-preperation-personal-interview-container">
        <div className="interview-preperation-personal-interview-header">
          <div className="interview-preperation-personal-interview-header-title">
            {selectedInterviewType.title}
          </div>
        </div>
        <div className="interview-preperation-personal-interview-body">
          <div className="interview-preperation-personal-interview-body-1-section-2-2">
            <div className="interview-preperation-personal-interview-title">
              Embrace the Future of Training and Education
            </div>
            <div className="interview-preperation-personal-interview-subtitle">
              Try interacting with the AI like you would in an interview to see
              what we have in store for you.
            </div>
          </div>
          <div className="interview-preperation-personal-interview-body-1">
            <div className="interview-preperation-personal-interview-body-1-section-2">
              <div className="interview-preperation-personal-interview-body-1-section-2-1">
                {selectedInterviewType.keyPoints.map((point, index) => (
                  <div key={index} className="interview-preperation-personal-interview-cover-list">
                    <TickSvg />
                    {point}
                  </div>
                ))}
              </div>
            </div>
            <div className="interview-preperation-personal-interview-body-1-section-1">
              <img
                src={selectedInterviewType.cover}
                alt="rolecover"
              />
            </div>
          </div>
        </div>
        <div className="interview-preperation-personal-interview-info-page-nav-button">
          <Button
            text="NEXT"
            width="400px"
            onClick={onNext}
          />
        </div>
      </div>
    </div>
  );
};

export default Info;
