import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { CSSTransition } from "react-transition-group";
import "./AddModuleModal.css";
import $ from "jquery";
import { RiFolderAddLine } from "react-icons/ri"

var new_module = "";
$(document).on('change', '.add-module', function() {
  new_module = this.value;
});

export const AddCourseModal = props => {
  const handleAddClick = event => {
    props.onClose();
    props.onAdd(new_module);
  };

  const closeOnEscapeKeyDown = e => {
    if ((e.charCode || e.keyCode) === 27) {
      props.onClose();
    }
  };

  useEffect(() => {
    document.body.addEventListener("keydown", closeOnEscapeKeyDown);
    return function cleanup() {
      document.body.removeEventListener("keydown", closeOnEscapeKeyDown);
    };
  }, []);

  return ReactDOM.createPortal(
    <CSSTransition
      in={props.show}
      unmountOnExit
      timeout={{ enter: 0, exit: 300 }}
    >
      <div className="modal" onClick={props.onClose}>
        <div className="modal-content" onClick={e => e.stopPropagation()}>
          <div className="modal-header">
            <RiFolderAddLine style={{color: "#303077", fontSize: "2.3rem", marginRight: "0.5rem", marginTop: "0.1rem"}}/>
            <h2>Add New Course</h2>
          </div>
          <div className="modal-body">
            <input type="text" className="add-module" placeholder="Enter Course Name"/>
          </div>
          <div className="modal-footer">
            <button onClick={props.onClose} className="close-button">
              Close
            </button>
            <button onClick={handleAddClick} className="save-button">
              ADD
            </button>
          </div>
        </div>
      </div>
    </CSSTransition>,
    document.getElementById("root")
  );
}