import React from "react";
import "./Milestones.css";

const Milestones = () => {
  const milestoneSteps = ["Step 1", "Step 2", "Step 3"];
  const milestoneDescriptions = [
    "Share your unique referral code with your friends to gain surge benefits.",
    "Get Rs. 500 off each time when someone enrolls using your referral code.",
    "Later you will get a reward Rs. 500 in your bank account."
  ];

  return (
    <div className="referral-milestones-container">
      <div className="referral-milestone-step-header">
        {milestoneSteps.map((step, index) => (
          <div key={index} className="referral-milestone-step">
            {step}
          </div>
        ))}
      </div>
      <div className="referral-milestone-line">
        {milestoneSteps.map((_, index) => (
          <div key={index} className="referral-milestone-circle">
            <div className="referral-milestone-circle-inner"></div>
            <div className="referral-milestone-circle-outer"></div>
          </div>
        ))}
      </div>
      <div className="referral-milestone-descriptions">
        {milestoneDescriptions.map((description, index) => (
          <div key={index} className="referral-milestone-description">
            {description}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Milestones;