import React, { useState, useEffect } from 'react'

import './AdminAssignment.css'
import AssignmentPop from './AssignmentPop'
import { useHttpClient } from '../../Components/Hooks/http-hook'

const AdminAssignment = () => {
    const [pop, setPop] = useState(false)

    const createAssignmentHandler = () => {
        setPop(!pop)
    }

    const togglePopup = () => {
        setPop(!pop);
    }    
    
    const [isCourseList, setIsCourseList] = useState([]);

    const { isLoading, error, sendRequest, clearError } = useHttpClient();

    const [selectedOption, setSelectedOption] = useState("SRKREC Data Science Campus Program Batch 2");
    const [selectedProgramId, setSelectedProgramId] = useState("0001");

    const handleProgramIdChange = (title) => {
        let assignList = isCourseList.find(obj => obj.title === title)
        console.log(assignList)
        setSelectedProgramId(assignList.program_id)
        console.log(selectedProgramId)
    }
    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
        handleProgramIdChange(event.target.value)
        console.log(selectedProgramId)
    };

    const [assignmentList, setAssignmentList] = useState([])

    useEffect(() => {
        const getCampusProgrammeTitles = async() => {
            try {
                const responseData = await sendRequest(
                  `${process.env.REACT_APP_BACKEND_URL}/db/prog-titles`,
                  "get",              
                );
                if (responseData.ok) {
                    setIsCourseList(responseData.data)
                }
              } catch (err) {
                console.log("got error while getting programmes")
              }
            };
            const getProgramAssignments = async() => {
                try {
                    const responseData = await sendRequest(
                      `${process.env.REACT_APP_BACKEND_URL}/s3/assignment`,
                      "get",              
                    );
                    if (responseData.ok) {
                        setAssignmentList(responseData.data)
                        console.log('api done', selectedOption)
                        console.log(assignmentList)
                    }
                  } catch (err) {
                    console.log("got error while getting programmes")
                  }
                };
    
        getCampusProgrammeTitles();    
        getProgramAssignments();    
    }, []);    

  return (
    <div className='admin-assignment'>
        <div className='assignment-list-heading'>
            <h3>Assignment List</h3>
            <div>
                <label htmlFor="dropdown">Select an Program:</label>
                <select id="dropdown" value={selectedOption} onChange={handleSelectChange}>
                    <option value="SRKREC Data Science Campus Program Batch 2">SRKREC Data Science Campus Program Batch 2</option>
                    {isCourseList.map((option) => (
                    <option key={option.program_id} value={option.title}>
                        {option.title}
                    </option>
                    ))}
                </select>
            </div>
            <button
            onClick={() => createAssignmentHandler()}
            >
                Create Assignment
            </button>
        </div>
        <div className='assignment-list'>
            {selectedOption && assignmentList && assignmentList.map((assign, i) => {
                if (assign.program_id == selectedProgramId) {
                    return (
                        <div>
                            <tr>
                                <td>{assign.title}</td>
                            </tr>                        
                        </div>
                    )    
                }
            })
            }
        </div>
        {pop && <AssignmentPop handleClose={togglePopup} programDetails={selectedProgramId}></AssignmentPop>}
    </div>
  )
}

export default AdminAssignment