import React from "react";
import PopUp from "../UiComponents/Popup/PopUp";
import "./AddSection.css";
import { useState, useContext, useEffect } from "react";
import { AdminContext } from "../../../../context/admin-context";
import { useHttpClient } from "../../../../Components/Hooks/http-hook";
import LoadingSpinner from "../../../../Components/Input/LoadingSpinner";
const swal = require("sweetalert2");

const AddSection = ({ course, showPopup, setShowPopup, onAdd }) => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [buttonLoading, setButtonLoading] = useState(false);
  const admin = useContext(AdminContext);

  const intialSectionState = {
    section_name: "",
    section_description: "",
    section_lock: true,
    section_order: null,
  };

  const [section, setSection] = useState(intialSectionState);

  const [validationErrors, setValidationErrors] = useState({
    section_name: false,
    section_description: false,
    section_order: false,
  });

  const handlechange = (e) => {
    const { name, value } = e.target;

    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: false,
    }));

    setSection((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const resetForm = () => {
    setSection(intialSectionState);
    setValidationErrors({
      section_name: false,
      section_description: false,
      section_order: false,
    });
  };

  const validateForm = () => {
    const requiredFields = ["section_name", "section_description", "section_order"];
    const newValidationErrors = {};

    requiredFields.forEach((field) => {
      if (!section[field]) {
        newValidationErrors[field] = true;
      }
    });

    setValidationErrors((prev) => ({
      ...prev,
      ...newValidationErrors,
    }));

    return Object.keys(newValidationErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      setButtonLoading(false);
      return;
    }
    setButtonLoading(true);
    try {
      const response = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/db/section/${course.course_id}`,
        "POST",
        JSON.stringify(section),
        {
          "Content-Type": "application/json",
          "Refresh-Token": admin.refresh_token,
          Authorization: admin.token,
        }
      );
      if (response) {
        setButtonLoading(false);
        setShowPopup(false);
        onAdd(response.section)
        swal.fire({
          title: "Section Added Successfully",
          icon: "success",
          confirmButtonText: "Okay",
        });
      }
    } catch (err) {
      setButtonLoading(false);
      swal.fire({
        title: "Error",
        text: err.message || "Something went wrong",
        icon: "error",
        confirmButtonText: "Okay",
      });
    }
  };

  useEffect(() => {
    if (error) {
      setButtonLoading(false);
      setShowPopup(false);
      swal.fire({
        title: "Error!",
        text: error || "Something went wrong, please try again later.",
        icon: "error",
        confirmButtonText: "Close",
      });
      clearError();
    }
  }
  , [error, clearError, setShowPopup]);

  return (
    <PopUp
      title="Add Section"
      onClose={() => {
        resetForm();
        setShowPopup(false);
      }}
      show={showPopup}
    >
      {buttonLoading ? (
      <div className="add_section_popup__loading">
        <LoadingSpinner />
      </div>
      ) : (
      <form onSubmit={handleSubmit}>
      <div className="add_section_popup__content">
        <div className="add_section_popup__content-row">
          <div className="add_section_popup__content-row-title">
            <p>Section Name</p>
          </div>
          <div className="add_section_popup__content-row-value">
            <input
              type="text"
              placeholder="Enter Section Name"
              onChange={handlechange}
              name="section_name"
              value={section.section_name}
              className={validationErrors.section_name ? "input_val_error" : ""}
            />
            {validationErrors.section_name && (
              <small className="input_val_error-text">
                Please enter a section name
              </small>
            )}
          </div>
        </div>
        <div className="add_section_popup__content-row">
          <div className="add_section_popup__content-row-title">
            <p>Section Description</p>
          </div>
          <div className="add_section_popup__content-row-value">
            <input
              type="text"
              placeholder="Enter Section Description"
              onChange={handlechange}
              name="section_description"
              value={section.section_description}
              className={
                validationErrors.section_description
                  ? "input_val_error"
                  : ""
              }
            />
            {validationErrors.section_description && (
              <small className="input_val_error-text">
                Please enter a section description
              </small>
            )}
          </div>
        </div>
        <div className="add_section_popup__content-row">
          <div className="add_section_popup__content-row-title">
            <p>Is Active</p>
          </div>
          <div className="add_section_popup__content-row-value">
            <select
              onChange={handlechange}
              name="section_lock"
              value={section.section_lock}
            >
              <option value="true">Live</option>
              <option value="false">Not Live</option>
            </select>
          </div>
        </div>
        <div className="add_section_popup__content-row">
          <div className="add_section_popup__content-row-title">
            <p>Section Order</p>
          </div>
          <div className="add_section_popup__content-row-value">
            <input
              type="number"
              placeholder="Enter Section Order"
              onChange={handlechange}
              name="section_order"
              value={section.section_order}
              className={validationErrors.section_order ? "input_val_error" : ""}
            />
            {validationErrors.section_order && (
              <small className="input_val_error-text">
                Please enter a section order
              </small>
            )}
          </div>
        </div>
        <div className="add_section_popup__buttons">
          <button
            className="add_section_popup__buttons-cancel"
            type="button"
            onClick={() => setShowPopup(false)}
          >
            Cancel
          </button>
          <button
            className="add_section_popup__buttons-save"
            type="submit"
          >
            Add
          </button>
        </div>
      </div>
      </form>
      )}
    </PopUp>
  );
};
export default AddSection;
