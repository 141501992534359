import React, { useState, useCallback, useEffect, useContext } from "react";
import Navbar from "./Components/Navbar/Navbar";
import {
  BrowserRouter,
  Routes,
  Route,
  Redirect,
  Navigate,
} from "react-router-dom";
import "./App.css";
import PageFooter from "./Components/PageFooter";
import { AuthContext } from "./context/auth-context";
import { AdminContext } from "./context/admin-context";
import Auth from "./pages/Auth/Auth";
import AdmInsAuth from "./pages/Auth/AdmInsAuth";
import Adminauth from "./pages/Auth/Adminauth";
import AdminPage from "./pages/Admin/AdminPage";
import AdminSmatInfo from "./pages/Admin/AdminSmatInfo";
import MyCoursePage from "./pages/upSkill/MyCoursePage";
import CourseContent from "./pages/upSkill/CourseContent";
import ModuleContent from "./pages/upSkill/ModuleContent";
import Aptitude from "./pages/aptitude/Aptitude";
import AptitudeContent from "./pages/aptitude/AptitudeTopics/AptitudeContent";
import PracticeContent from "./pages/aptitude/Practice/PracticeContent";
import AppTest from "./pages/aptitude/Test/AptitudeTest";
import PasswordReset from "./pages/Auth/PasswordReset";
import PasswordResetMsg from "./pages/upSkill/PasswordResetMsg";
import Error404 from "./pages/Error/Error404";
import HomePage from "./pages/Home/HomePage";
import VideoPlayer from "./pages/Home/VideoPlayerPop";
import AdminCampusProgrammeEdit from "./pages/Admin/AdminCampusProgrammeEdit";
import UnderProgress from "./pages/miscellaneous/UnderProgress";
import AdminCampusProgrammeMse from "./pages/Admin/AdminCampusProgrammeMse";
import Skillbox from "./pages/Home/Skillbox";
import InstructorHome from "./pages/InstructorHome/InstructorHome";
import ScheduleManagement from "./pages/Home/ScheduleManagement";
import Assignment1 from "./pages/DSAAssignments/Assignment1";
import { Home } from "@material-ui/icons";
import InstructorCourse from "./pages/InstructorHome/InstructorCourse";
import Register from "./pages/Register/Register";
import Enroll from "./pages/Enroll/Enroll";
import InterviewEnroll from "./pages/Enroll/InterviewEnroll";
import MentorHome from "./pages/Mentor/MentorHome";
import MentorCoursePage from "./pages/Mentor/MentorCoursePage";
import Queries from "./pages/Home/Query/Queries";
import Profile from "./pages/Home/StudentProfile/Profile";
import ModeratorHome from "./pages/ModeratorHome/ModeratorHome";
import Attendance from "./pages/Home/Attendance/Attendance";
import AptitudePlatform from "./pages/aptitude/AptitudePlatform/AptitudePlatform";
import AptitudeTest from "./pages/aptitude/AptitudePlatform/AptitudeTest";
import AptitudePractice from "./pages/aptitude/AptitudePlatform/AptitudePractice";
import Pathway from "./pages/Courses/Pathway";
import Module from "./pages/Courses/Module";
import Class from "./pages/Courses/Class";
import CodingPlatform from "./pages/Coding/CodingPlatform";
import CodingTest from "./pages/Coding/CodingTest";
import CodingPractice from "./pages/Coding/CodingPractice";
import SkillboxV2 from "./pages/Home/SkillboxV2/SkillboxV2";
import SkillboxV2User from "./pages/User/SkillboxV2User/SkillboxV2User";
import QSkillbox from "./pages/Qcreator/QSkillbox/QSkillbox";
import Resume from "./pages/Home/Resume/Resume";
import Referral from "./pages/Home/Referral/Referral";
import Assessment from "./pages/Home/Assessment/Assessment";
import Practice from "./pages/Home/Practice/Practice";
import SkillboxAdmin from "./pages/Admin/SkillboxAdmin/SkillboxAdmin";
import Section from "./pages/Home/SkillboxV2/Layout/Courses/Pages/Section/Section";
import Modules from "./pages/Home/SkillboxV2/Layout/Courses/Pages/Modules/Modules";
import Assignments from "./pages/Home/SkillboxV2/Layout/Courses/Pages/Modules/Layout/Assignments/Assignments";
import ClassPage from "./pages/Home/SkillboxV2/Layout/Courses/Pages/Classes/ClassPage";

import ProfileAnalyzer from "./pages/ProfileAnalyzerPage/ProfileAnalyzer";

import GenerateResumePage from "./pages/GenerateResume/GenerateResumePage";

function App() {
  const [token, setToken] = useState();
  const [adminToken, setAdminToken] = useState();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userId, setUserId] = useState();
  const [name, setName] = useState();
  const [userEmail, setUserEmail] = useState();
  const [profile, setUserProfile] = useState();
  const [userMob, setUserMob] = useState();
  const [isAdmin, setIsAdmin] = useState(false);
  const [courseIdsInfo, setCourseIdsInfo] = useState([]);
  const [role, setRole] = useState();
  const [surgeId, setSurgeId] = useState();
  const [refreshToken, setRefreshToken] = useState();
  const [adminRefreshToken, setAdminRefreshToken] = useState();
  const [isNoHeaderFooter, setIsNoHeaderFooter] = useState(false);

  // useEffect(() => {
  // const shouldNotDisplayHeaderFooter = window.location.pathname.match(/aptitude-platform\/exam\/.*/);
  // const shouldNotDisplayHeaderFooter2 = window.location.pathname.match(/coding-platform\/exam\/.*/);

  // setIsNoHeaderFooter(shouldNotDisplayHeaderFooter);
  // setIsNoHeaderFooter(shouldNotDisplayHeaderFooter2);

  // },[window.location, window.location.pathname])

  useEffect(() => {
    const shouldNotDisplayHeaderFooter = window.location.pathname.match(
      /aptitude-platform\/exam\/.*/
    );
    const shouldNotDisplayHeaderFooter2 = window.location.pathname.match(
      /coding-platform\/exam\/.*/
    );
    const shouldNotDisplayHeaderFooter3 =
      window.location.pathname.match(/profile\/.*/);

    if (
      shouldNotDisplayHeaderFooter ||
      shouldNotDisplayHeaderFooter2 ||
      shouldNotDisplayHeaderFooter3
    ) {
      setIsNoHeaderFooter(true);
    }
  }, [window.location, window.location.pathname]);

  const login = useCallback(
    (
      uid,
      name,
      email,
      profile,
      mobile,
      token,
      refresh_token,
      role,
      course_ids_info,
      expirationDate,
      surge_id
    ) => {
      setToken(token);
      setIsLoggedIn(true);
      setUserId(uid);
      setUserMob(mobile);
      setUserProfile(profile);
      setUserEmail(email);
      setName(name);
      setCourseIdsInfo(course_ids_info);
      setRole(role);
      setSurgeId(surge_id);
      setRefreshToken(refresh_token);

      const tokenExpirationDate =
        expirationDate || new Date(new Date().getTime() + 1000 * 60 * 60 * 3);
      localStorage.setItem(
        "userData",
        JSON.stringify({
          userId: uid,
          name: name,
          email: email,
          profile: profile,
          mobile: mobile,
          token: token,
          refresh_token: refresh_token,
          role: role,
          course_ids_info: course_ids_info,
          expiration: tokenExpirationDate.toISOString(),
          surge_id: surge_id,
        })
      );
    },
    []
  );

  const logout = useCallback(() => {
    setToken(null);
    setIsLoggedIn(false);
    setUserId(null);
    setUserEmail(null);
    setName(null);
    setRole(null);
    setCourseIdsInfo([]);
    setSurgeId(null);
    setRefreshToken(null);
    localStorage.removeItem("userData");
  }, []);

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("userData"));
    if (storedData && new Date(storedData.expiration) > new Date()) {
      login(
        storedData.userId,
        storedData.name,
        storedData.email,
        storedData.profile,
        storedData.mobile,
        storedData.token,
        storedData.refresh_token,
        storedData.role,
        storedData.course_ids_info,
        new Date(storedData.expiration),
        storedData.surge_id
      );
      setIsLoggedIn(true);
    }

    if (storedData && new Date(storedData.expiration) <= new Date()) {
      logout();
    }
  }, [login]);

  const loginAdmin = useCallback(
    (
      uid,
      name,
      email,
      profile,
      mobile,
      token,
      refresh_token,
      expirationDate
    ) => {
      setIsAdmin(true);
      setAdminToken(token);
      setAdminRefreshToken(refresh_token);
      const adminExpirationDate =
        expirationDate || new Date(new Date().getTime() + 1000 * 60 * 60 * 4);
      localStorage.setItem(
        "adminData",
        JSON.stringify({
          userId: uid,
          name: name,
          email: email,
          profile: profile,
          mobile: mobile,
          token: token,
          refresh_token: refresh_token,
          expiration: adminExpirationDate.toISOString(),
        })
      );
    }
  );

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("adminData"));
    if (storedData && new Date(storedData.expiration) > new Date()) {
      loginAdmin(
        storedData.userId,
        storedData.name,
        storedData.email,
        storedData.profile,
        storedData.mobile,
        storedData.token,
        storedData.refresh_token,
        new Date(storedData.expiration)
      );
    }

    if (storedData && new Date(storedData.expiration) <= new Date()) {
      logoutAdmin();
    }
  }, [loginAdmin]);

  const logoutAdmin = useCallback(() => {
    setIsAdmin(false);
    localStorage.removeItem("adminData");
  });

  let routes;
  if (isLoggedIn && role == "student") {
    routes = (
      <Routes>
        <Route path={"/mycourses"} element={<MyCoursePage />} />
        <Route path="/mycourses/:cid" element={<CourseContent />} />
        <Route
          path="/mycourses/:cid/:c2id/:c3id/:c4id"
          element={<ModuleContent />}
        />
        <Route path="/aptitude" element={<Aptitude />} />
        <Route path="/aptitude/:tid" element={<AptitudeContent />} />
        <Route path="/aptitude/:tid/:mid" element={<PracticeContent />} />
        <Route path="/aptitude/test/:tid" element={<AppTest />} />
        <Route path="/password-reset" element={<PasswordReset />} />
        <Route path="/password-reset-msg" element={<PasswordResetMsg />} />
        <Route path="/error-404" element={<Error404 />} />
        <Route path="/home" element={<HomePage />} />
        <Route path="/home/play-video/:cid" element={<VideoPlayer />} />
        <Route path="/wip" element={<UnderProgress />} />
        <Route path="/skillbox" element={<Skillbox />} />
        <Route path="/skillbox/:cid/:ct" element={<HomePage />} />
        <Route path="/skillbox/:cid/:ct/pathway/:pid" element={<Pathway />} />
        <Route path="/skillbox/:cid/:ct/modules/:mid" element={<Module />} />
        <Route
          path="/skillbox/:cid/:ct/modules/:mid/classes/:clid"
          element={<Class />}
        />
        <Route path="/queries" element={<Queries />} />
        <Route
          path="/profile"
          element={
            <Profile
              isNoHeaderFooter={isNoHeaderFooter}
              setIsNoHeaderFooter={setIsNoHeaderFooter}
            />
          }
        />
        <Route
          path="/resume"
          element={
            <Resume
              isNoHeaderFooter={isNoHeaderFooter}
              setIsNoHeaderFooter={setIsNoHeaderFooter}
            />
          }
        />
        <Route
          path="/skillbox-v2"
          element={
            <SkillboxV2
              isNoHeaderFooter={isNoHeaderFooter}
              setIsNoHeaderFooter={setIsNoHeaderFooter}
            />
          }
        />
        <Route 
          path="/skillbox-v2/:cid/:ct" 
          element={<Section 
            isNoHeaderFooter={isNoHeaderFooter}
            setIsNoHeaderFooter={setIsNoHeaderFooter}
          />} 
        />        
        <Route 
          path="/skillbox-v2/:cid/:ct/pathway/:pid/:tabId?" 
          element={<Modules 
            isNoHeaderFooter={isNoHeaderFooter}
            setIsNoHeaderFooter={setIsNoHeaderFooter}
          />} 
        />
         <Route
          path="/skillbox-v2/:cid/:ct/pathway/:pid/modules/:mid/assignments"
          element={<Assignments 
            isNoHeaderFooter={isNoHeaderFooter}
            setIsNoHeaderFooter={setIsNoHeaderFooter}
          />}
        />        
        <Route
          path="/skillbox-v2/:cid/:ct/pathway/:pid/modules/:mid/classes/:clid"
          element={<ClassPage 
            isNoHeaderFooter={isNoHeaderFooter}
            setIsNoHeaderFooter={setIsNoHeaderFooter}
          />}
        />        

        <Route
          path="/referral-code"
          element={
            <Referral
              isNoHeaderFooter={isNoHeaderFooter}
              setIsNoHeaderFooter={setIsNoHeaderFooter}
            />
          }
        />
        <Route path="/attendance" element={<Attendance />} />
        <Route path="/assessment" element={<Assessment />} />
        <Route path="/practice" element={<Practice />} />
        <Route path="/aptitude-platform" element={<AptitudePlatform />} />
        <Route
          path="/aptitude-platform/exam/:examid"
          element={
            <AptitudeTest
              isNoHeaderFooter={isNoHeaderFooter}
              setIsNoHeaderFooter={setIsNoHeaderFooter}
            />
          }
        />
        <Route
          path="/aptitude-platform/practice/:practiceid"
          element={
            <AptitudePractice
              isNoHeaderFooter={isNoHeaderFooter}
              setIsNoHeaderFooter={setIsNoHeaderFooter}
            />
          }
        />
        <Route path="/coding-platform" element={<CodingPlatform />} />
        <Route
          path="/coding-platform/exam/:examid"
          element={
            <CodingTest
              isNoHeaderFooter={isNoHeaderFooter}
              setIsNoHeaderFooter={setIsNoHeaderFooter}
            />
          }
        />
        <Route
          path="/coding-platform/practice/:examid"
          element={
            <CodingPractice
              isNoHeaderFooter={isNoHeaderFooter}
              setIsNoHeaderFooter={setIsNoHeaderFooter}
            />
          }
        />
        <Route path="/profile-analyzer" element={<ProfileAnalyzer isNoHeaderFooter={isNoHeaderFooter} setIsNoHeaderFooter={setIsNoHeaderFooter} />}/>
        <Route path="/generate-resume" element={<GenerateResumePage isNoHeaderFooter={isNoHeaderFooter} setIsNoHeaderFooter={setIsNoHeaderFooter} />}/>
        <Route path="*" element={<Navigate to="/skillbox-v2" />} />
      </Routes>
    );
  } else if (isLoggedIn && role == "instructor") {
    routes = (
      <Routes>
        <Route path="/wip" element={<UnderProgress />} />
        <Route path="/instructor-home" element={<InstructorHome />} />
        <Route
          path="/instructor-home/:cid/:title"
          element={<InstructorCourse />}
        />
        <Route path="*" element={<Navigate to="/instructor-home" />} />
      </Routes>
    );
  } else if (isLoggedIn && role == "mentor") {
    routes = (
      <Routes>
        <Route path="/wip" element={<UnderProgress />} />
        <Route path="/mentor-home" element={<MentorHome />} />
        <Route path="/mentor-home/:cid/:title" element={<MentorCoursePage />} />
        <Route path="*" element={<Navigate to="/mentor-home" />} />
      </Routes>
    );
  } else if (isLoggedIn && role == "moderator") {
    routes = (
      <Routes>
        <Route path="/wip" element={<UnderProgress />} />
        <Route path="/moderator-home" element={<ModeratorHome />} />
        <Route path="*" element={<Navigate to="/moderator-home" />} />
      </Routes>
    );
  } else if(isLoggedIn && role == "user") {
    routes = (
      <Routes>
        <Route path="/skillbox" element={<SkillboxV2User isNoHeaderFooter={isNoHeaderFooter} setIsNoHeaderFooter={setIsNoHeaderFooter} />} />
        <Route path="*" element={<Navigate to="/skillbox" />} />
      </Routes>
    );
  } else if(isLoggedIn && role == "qcreator") {
    routes = (
      <Routes>
        <Route path="/skillbox-v2" element={<QSkillbox isNoHeaderFooter={isNoHeaderFooter} setIsNoHeaderFooter={setIsNoHeaderFooter} />} />
        <Route path="*" element={<Navigate to="/skillbox-v2" />} />
      </Routes>
    );
  } 
   else if (isAdmin) {
    routes = (
      <Routes>
        <Route path="/admin" element={<SkillboxAdmin isNoHeaderFooter={isNoHeaderFooter} setIsNoHeaderFooter={setIsNoHeaderFooter} />} />
        <Route path="/admin/home" element={<AdminPage />} />
        {/* <Route
          path="/admin/home/:bid/:cid"
          element={<AdminCampusProgrammeEdit />}
        /> */}
        <Route
          path="/admin/home/:cid/:bid/:mse"
          element={<AdminCampusProgrammeMse />}
        />
        <Route path="/admin/smat-info" element={<AdminSmatInfo />} />
        <Route path="*" element={<Navigate to="/admin/home" />} />
      </Routes>
    );
  } else {
    routes = (
      <Routes>
        <Route path="/" element={<Auth />} />
        <Route path="/register" element={<Register />} />
        <Route path="/enroll" element={<Enroll />} />
        <Route path="/interview-enroll" element={<InterviewEnroll />} />
        {/* <Route path="/admin" element={<AdmInsAuth />} />
        <Route path="/admin" element={<Adminauth />} /> */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: isLoggedIn,
        token: token,
        refresh_token: refreshToken,
        role: role,
        userName: name,
        email: userEmail,
        mobile: userMob,
        profile: profile,
        profile_pic: profile,
        userId: userId,
        course_ids_info: courseIdsInfo,
        surge_id: surgeId,
        login: login,
        logout: logout,
        setUserProfilePic: (newProfilePic) => {
          setUserProfile(newProfilePic);
        },
        setUserName: (newName) => {
          setName(newName);
        },
      }}
    >
      <AdminContext.Provider
        value={{
          isLoggedIn: isAdmin,
          token: adminToken,
          refresh_token: adminRefreshToken,
          login: loginAdmin,
          logout: logoutAdmin,
        }}
      >
        <BrowserRouter>
          {!isNoHeaderFooter && <Navbar />}
          <main>{routes}</main>
          {!isNoHeaderFooter && <PageFooter />}
        </BrowserRouter>
      </AdminContext.Provider>
    </AuthContext.Provider>
  );
}

export default App;
