import React, { useState, useEffect, useContext } from "react";
import "./Contact.css";
import Input from "../../../../Components/Profile/Input/Input";
import { phoneExtension, SocialMediaLinks } from "../Data/SelectData";
import { useHttpClient } from "../../../../Components/Hooks/http-hook";
import { AuthContext } from "../../../../context/auth-context";
import Loading from "../../../../Components/Loading";

const Contact = () => {
  const [userDetails, setUserDetails] = useState({
    college_email_id: "",
    phone: {
      extn: "+91",
      number: "",
    },
    web_links: [],
    address: "",
    country: "",
    state: "",
    city: "",
    pincode: "",
  });

  const [pageLoading, setPageLoading] = useState(true);
  const [autosave, setAutosave] = useState(false);
  const [updateRequired, setUpdateRequired] = useState(false);
  const auth = useContext(AuthContext);
  const { sendRequest } = useHttpClient();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserDetails({
      ...userDetails,
      [name]: value,
    });
    setUpdateRequired(true);
  };

  const handlePhoneChange = (e) => {
    const { value } = e.target;
    setUserDetails({
      ...userDetails,
      phone: {
        ...userDetails.phone,
        number: value,
      },
    });
  };

  const handleSelectPhoneExtensionChange = (selectedOption) => {
    setUserDetails({
      ...userDetails,
      phone: {
        ...userDetails.phone,
        extn: selectedOption.value,
      },
    });
  };

  const handleLinkChange = (e, i) => {
    const { value } = e.target;
    const web_links = userDetails.web_links;
    web_links[i].value = value;
    setUserDetails({
      ...userDetails,
      web_links: web_links,
    });
    setUpdateRequired(true);
  };

  const handleLinkTypeChange = (e, i) => {
    const { value } = e;
    const web_links = userDetails.web_links;
    web_links[i].type = value;
    setUserDetails({
      ...userDetails,
      web_links: web_links,
    });
    setUpdateRequired(true);
  };

  const AddNewWebLink = () => {
    setUserDetails({
      ...userDetails,
      web_links: [...userDetails.web_links, { id: "", type: "", value: "" }],
    });
    setUpdateRequired(true);
  };

  const seperateExtensionAndNumber = (phone) => {
    if (phone.length === 10) {
      return {
        extn: "+91",
        number: phone,
      };
    } else if (phone.length === 13) {
      return {
        extn: phone.substring(0, 3),
        number: phone.substring(3),
      };
    } else if (phone.length === 12) {
      return {
        extn: "+" + phone.substring(0, 2),
        number: phone.substring(2),
      };
    }
  };

  const deleteWebLink = (i) => {
    const web_links = userDetails.web_links;
    web_links.splice(i, 1);
    setUserDetails({
      ...userDetails,
      web_links: web_links,
    });
    setUpdateRequired(true);
  };

  const updateContactDetails = async () => {
    setAutosave(true);
    await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/profile/contact`,
      "PUT",
      JSON.stringify(userDetails),
      {
        "Content-Type": "application/json",
        "Refresh-Token": auth.refresh_token,
        Authorization: auth.token,
      }
    );
    setAutosave(false);
  };

  useEffect(() => {
    if (updateRequired) {
      updateContactDetails();
      setUpdateRequired(false);
    }
  }, [updateRequired]);

  useEffect(() => {
    const getContactDetails = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/profile/contact`,
          "GET",
          null,
          {
            "Content-Type": "application/json",
            "Refresh-Token": auth.refresh_token,
            Authorization: auth.token,
          }
        );
        const phone = seperateExtensionAndNumber(responseData.data.phone);
        setUserDetails({
          ...userDetails,
          ...responseData.data,
          phone: phone,
        });
        setPageLoading(false);
      } catch (err) {}
    };
    getContactDetails();
  }, []);

  return (
    <div className="profile-contact-page">
      <div className="profile-header">
        <div className="profile-header-title">My Profile - Contact Details</div>
        <div className="profile-autosave">
        {autosave ? <>Autosaving...</> : <>Autosave is on</>}
        </div>
      </div>
      {pageLoading ? (
        <div className="profile-loader-container">
          <div className="profile-loader-sub-container">
            <Loading
              color="#14144e"
              customClass="profile-loader-container-for-loader"
            />
          </div>
        </div>
      ) : (
        <div className="profile-contact-input-section">
          <div className="profile-contact-input-section-container-1">
            <div className="profile-contact-input-college-email-container">
              <Input
                width="100%"
                inputPlaceholderValue="Enter your college email ID"
                onChangeFunction={handleInputChange}
                name="college_email_id"
                label="College Email ID"
                value={
                  userDetails.college_email_id !== ""
                    ? userDetails.college_email_id
                    : ""
                }
              />
            </div>
            <div className="profile-contact-input-phone-number-container">
              <Input
                width="100%"
                inputPlaceholderValue="Enter phone number"
                // onChangeFunction={handlePhoneChange}
                // onChangeSelectFunction={handleSelectPhoneExtensionChange}
                name="phone"
                label="Phone Number"
                isSelect={true}
                selectData={phoneExtension}
                isDisabled={true}
                isSelectDisabled={true}
                value={
                  userDetails.phone.number !== ""
                    ? userDetails.phone.number
                    : ""
                }
                selectvalue={
                  userDetails.phone.extn !== "" ? userDetails.phone.extn : ""
                }
              />
            </div>
            <div className="profile-contact-input-web-links-container">
              <div className="profile-input-field-label">Web Links</div>
              {userDetails.web_links &&
                userDetails.web_links.length > 0 &&
                userDetails.web_links.map((webLink, i) => (
                  <div className="profile-contact-input-web-links-sub-container" key={i}>
                  <Input
                    key={i}
                    selectInpWidth="90%"
                    width="100%"
                    inputPlaceholderValue="Enter link"
                    onChangeFunction={(e) => handleLinkChange(e, i)}
                    onChangeSelectFunction={(e) => handleLinkTypeChange(e, i)}
                    name="web_links"
                    isSelect={true}
                    selectData={SocialMediaLinks}
                    selectLabel={true}
                    value={webLink.value}
                    selectvalue={webLink.type}
                  />
                  <div className="profile-contact-input-web-links-delete-button"
                    onClick={() => deleteWebLink(i)}
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7 4V2H17V4H22V6H20V21C20 21.5523 19.5523 22 19 22H5C4.44772 22 4 21.5523 4 21V6H2V4H7ZM6 6V20H18V6H6ZM9 9H11V17H9V9ZM13 9H15V17H13V9Z"
                        fill="#004C73"
                      />
                    </svg>
                  </div>
                  </div>
                ))}
              {(userDetails.web_links && userDetails.web_links.length < 7) ? (
                <div className="add-new-web-link-button" onClick={AddNewWebLink}>
                  + Add New
                </div>
              ):(
                <div className="add-new-web-link-button-error">
                  You can add only 7 links
                </div>
              ) }
            </div>
            <div className="profile-input-address-container">
              <Input
                width="100%"
                inputPlaceholderValue="Enter your address"
                onChangeFunction={handleInputChange}
                name="address"
                label="Address"
                value={userDetails.address !== "" ? userDetails.address : ""}
              />
            </div>
          </div>
          <div className="profile-contact-input-section-container-2">
            <div className="profile-contact-input-section-container-left">
              <div className="profile-input-country-container">
                <Input
                  width="100%"
                  inputPlaceholderValue="Enter country"
                  onChangeFunction={handleInputChange}
                  name="country"
                  label="Country"
                  value={userDetails.country !== "" ? userDetails.country : ""}
                />
              </div>
              <div className="profile-input-city-container">
                <Input
                  width="100%"
                  inputPlaceholderValue="Enter city"
                  onChangeFunction={handleInputChange}
                  name="city"
                  label="City"
                  value={userDetails.city !== "" ? userDetails.city : ""}
                />
              </div>
            </div>
            <div className="profile-contact-input-section-container-right">
              <div className="profile-input-state-container">
                <Input
                  width="100%"
                  inputPlaceholderValue="Enter state"
                  onChangeFunction={handleInputChange}
                  name="state"
                  label="State"
                  value={userDetails.state !== "" ? userDetails.state : ""}
                />
              </div>
              <div className="profile-input-pincode-container">
                <Input
                  width="100%"
                  inputPlaceholderValue="Enter pincode"
                  onChangeFunction={handleInputChange}
                  name="pincode"
                  label="Pincode"
                  value={userDetails.pincode !== "" ? userDetails.pincode : ""}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Contact;
