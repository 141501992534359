import React, { useState, useEffect, useContext } from "react";
import { useHttpClient } from "../../../Components/Hooks/http-hook";
import { AuthContext } from "../../../context/auth-context";
import Popup from "../../../Components/QueryPopup/Popup";
import MessagePopup from "../../../Components/MessagePopup/MessagePopup";
import "./Queries.css";

const Queries = () => {
  const [queries, setQueries] = useState([]);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [showMessagePopup, setShowMessagePopup] = useState(false);
  const [selectedQueryId, setSelectedQueryId] = useState(null);
  const [showPopup, setShowPopup] = useState(false);

  const auth = useContext(AuthContext);
  useEffect(() => {
    const fetchQueries = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/auth/user/queries`,
          "GET",
          null,
          {
            "Content-Type": "application/json",
            "Refresh-Token": auth.refresh_token,
            Authorization: auth.token,
          }
        );
        responseData.queries= responseData.queries.map((query) => {
          return {
            ...query,
            lastupdated: new Date(query.lastupdated).toLocaleString(),
          }});
        setQueries(responseData.queries);

      } catch (err) {}
    };
    fetchQueries();
  }, []);

  const resolveQuery = (queryId) => {
    const updatedQueries = queries.map((query) => {
      if (query.id === queryId) {
        query.status = "resolved";
      }
      return query;
    });
    setQueries(updatedQueries);
  };

  return (
    <div>
      <div className="queries-page">
        <MessagePopup
          showMessagePopup={showMessagePopup}
          setShowMessagePopup={setShowMessagePopup}
          queryId={selectedQueryId}
          resolveQuery={resolveQuery}
        />
        <Popup showPopup={showPopup} setShowPopup={setShowPopup} />
        <div className="queries-components">
          <h1 className="main-heading">
            Raised Doubts{" "}
            <span className="queries-count">({queries.length})</span>
          </h1>
          <button
            className="raise-query-btn"
            onClick={() => setShowPopup(true)}
          >
            Raise a new Doubt{" "}
          </button>

          <table className="queries-table">
            <thead>
              <tr>
                <th>Title</th>
                {/* <th>Description</th> */}
                <th>Status</th>
                <th>Last Updated</th>
              </tr>
            </thead>
            <tbody>
              {isLoading && <p>Loading...</p>}
              {!isLoading && queries.map((query) => (
                <tr
                  key={query.id}
                  onClick={() => {
                    setSelectedQueryId(null);
                    setSelectedQueryId(query.id);
                    setShowMessagePopup(true);
                  }}
                >
                  <td>{query.title}</td>
                  {/* <td>{query.description}</td> */}
                  <td>
                    {query.status === "submitted" && (
                      <span className="status-yellow">Submitted</span>
                    )}
                    {query.status === "inprogress" && (
                      <span className="status-green">In Progress</span>
                    )}
                    {query.status === "resolved" && (
                      <span className="status-blue">Resolved</span>
                    )}
                  </td>
                  <td>{query.lastupdated}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
export default Queries;
