import React, { useState, useEffect, useContext } from "react";
import "./ReferralCodeDetails.css";
import { useHttpClient } from "../../../../../../Components/Hooks/http-hook";
import Loading from "../../../../../../Components/Loading";
import { ToastContainer, toast } from "react-toastify";

const ReferralCodeDetails = () => {
  const [referralCode, setReferralCode] = useState("123456");
  const [pageLoading, setPageLoading] = useState(false);
  const { sendRequest, isLoading, error, clearError } = useHttpClient();

  return (
    <div>
      <ToastContainer closeButton={false} />
      <div className="admin-skillbox-header">
        <div className="admin-skillbox-header-title">Student Referral code Details</div>
      </div>
      {pageLoading ? (
        <div className="admin-skillbox-loader-container">
          <div className="admin-skillbox-loader-sub-container">
            <Loading
              color="#14144e"
              customClass="admin-skillbox-loader-container-for-loader"
            />
          </div>
        </div>
      ) : (
        <div className="admin-skillbox-details-main-section">
          {referralCode ? (
            <>
              test 1
            </>
          ) : (
            <div className="admin-skillbox-error-container">
              Something went wrong. Please try again later.
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ReferralCodeDetails;
