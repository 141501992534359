import React from "react";
import "./AllDoubts.css";
import PreviousDoubts from "./Pages/PreviousDoubts";

const AllDoubts = ({ setSelectedOption }) => {
  const goToHome = () => {
    setSelectedOption({
      value: "skillbox",
      label: "Skillbox",
    });
  };

  return (
    <div className="sk-student-doubts-page">
      <div className="sk-student-doubts-page-nav-menu">
        <div
          onClick={goToHome}
          className="sk-student-doubts-page-nav-menu-home"
        >
          Home
        </div>
      </div>
      <div className="sk-student-doubts-container">
        <PreviousDoubts />
      </div>
    </div>
  );
};

export default AllDoubts;
