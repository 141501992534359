import React from "react";

const FaqArrowSvg = () => {
  return (
    <svg
      width="9"
      height="15"
      viewBox="0 0 9 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.78341 0.296834L8.77188 6.94591C8.85483 7.02507 8.91372 7.11082 8.94856 7.20317C8.9834 7.29551 9.00054 7.39446 8.99999 7.5C8.99999 7.60554 8.98257 7.70449 8.94773 7.79683C8.91289 7.88918 8.85427 7.97493 8.77188 8.05409L1.78341 14.723C1.58986 14.9077 1.34792 15 1.0576 15C0.767281 15 0.518434 14.9011 0.311061 14.7032C0.103687 14.5053 8.90241e-07 14.2744 8.67174e-07 14.0106C8.44108e-07 13.7467 0.103687 13.5158 0.311061 13.3179L6.40782 7.5L0.31106 1.68206C0.117512 1.49736 0.0207364 1.26966 0.0207364 0.998946C0.0207364 0.728233 0.124424 0.494196 0.331796 0.296834C0.539169 0.0989454 0.781104 7.18519e-07 1.0576 6.94346e-07C1.3341 6.70174e-07 1.57603 0.0989453 1.78341 0.296834Z"
        fill="#464646"
      />
    </svg>
  );
};

export default FaqArrowSvg;
