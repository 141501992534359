import React from "react";
import PopUp from "../UiComponents/Popup/PopUp";
import VideoPopup from "../UiComponents/Popup/PopUp";
import "./ViewContent.css";
import { useState } from "react";
import { MdContentCopy } from "react-icons/md";
import VideoPlayer from "../../../Courses/VideoPlayer";

const ViewContent = ({ content, showPopup, setShowPopup }) => {
  const [isCopied, setIsCopied] = useState(false);
  const [showVideo, setShowVideo] = useState(false);

  const copyToClipboard = () => {
    const urlToCopy = content.content_file_url
      ? content.content_file_url.startsWith("http://") ||
        content.content_file_url.startsWith("https://")
        ? content.content_file_url
        : `${process.env.REACT_APP_CLOUDFRONT_URL}${content.content_file_url}`
      : "No Url";

    navigator.clipboard
      .writeText(urlToCopy)
      .then(() => setIsCopied(!isCopied))
      .catch((err) => console.error("Unable to copy to clipboard", err));
  };

  return (
    <PopUp
      title={content.content_type}
      onClose={() => {
        setIsCopied(false);
        setShowVideo(false);
        setShowPopup(false);
      }}
      show={showPopup}
    >
      {showVideo ? (
        <>
          <button
            onClick={() => {
              setShowVideo(false);
            }}
          >
            Close Video
          </button>
          <VideoPlayer
            url={content.content_file_url}
            title={content.content_text}
            courseId={content.content_id}
          />
        </>
      ):(
      <div className="content_popup__content">
        <div className="content_popup__content-row">
          <div className="content_popup__content-row-title">
            <p>Content ID</p>
          </div>
          <div className="content_popup__content-row-value">
            <p>{content.content_id}</p>
          </div>
        </div>
        <div className="content_popup__content-row">
          <div className="content_popup__content-row-title">
            <p>Content Name</p>
          </div>
          <div className="content_popup__content-row-value">
            <p>{content.content_text}</p>
          </div>
        </div>
        <div className="content_popup__content-row">
          <div className="content_popup__content-row-title">
            <p>Content Summary</p>
          </div>
          <div className="content_popup__content-row-value">
            <p>{content.content_summary}</p>
          </div>
        </div>
        <div className="content_popup__content-row">
          <div className="content_popup__content-row-title">
            <p>Is Active</p>
          </div>
          <div className="content_popup__content-row-value">
            <p>{content.active_tag ? "No" : "Yes"}</p>
          </div>
        </div>
        <div className="content_popup__content-row">
          <div className="content_popup__content-row-title">
            <p>Content Order</p>
          </div>
          <div className="content_popup__content-row-value">
            <p>{content.content_order || "N/A"}</p>
          </div>
        </div>
        <div className="content_popup__content-row">
          <div className="content_popup__content-row-title">
            <p>Content Type</p>
          </div>
          <div className="content_popup__content-row-value">
            <p>{content.content_type}</p>
          </div>
        </div>
        <div className="content_popup__content-row">
          <div className="content_popup__content-row-title">
            <p>Content Url</p>
          </div>
          <div className="content_popup__content-row-value content-url-container">
            <p>
              {content.content_file_url ? (
                <>
                  <div className="content-url">
                    {content.content_file_url.startsWith("http://") ||
                    content.content_file_url.startsWith("https://") ? (
                      <span>{content.content_file_url}</span>
                    ) : (
                      <span>{`${process.env.REACT_APP_CLOUDFRONT_URL}${content.content_file_url}`}</span>
                    )}
                  </div>
                  <div>
                    <span
                      className="content-url-copy-button"
                      onClick={copyToClipboard}
                    >
                      <MdContentCopy />
                    </span>
                    <span className="content-url-copy-text">
                      {isCopied ? "Copied!" : "Copy"}
                    </span>
                  </div>
                </>
              ) : (
                <span>No Url</span>
              )}
              {content.content_type !== "video" ? (
                <a
                  href={
                    (content.content_file_url &&
                      content.content_file_url.startsWith("http://")) ||
                    content.content_file_url.startsWith("https://")
                      ? content.content_file_url
                      : `${process.env.REACT_APP_CLOUDFRONT_URL}/${content.content_file_url}`
                  }
                  rel="noopener noreferrer"
                  download
                >
                  <button>Download</button>
                </a>
              ) : (
                <button
                  onClick={() => {
                    setShowVideo(true);
                  }}
                >
                  Show Video
                </button>
              )}
            </p>
          </div>
        </div>
        <div className="content_popup__content-row">
          <div className="content_popup__content-row-title">
            <p>Created On</p>
          </div>
          <div className="content_popup__content-row-value">
            <p>{content.content_create_timestamp}</p>
          </div>
        </div>
        <div className="content_popup__content-row">
          <div className="content_popup__content-row-title">
            <p>Last Updated</p>
          </div>
          <div className="content_popup__content-row-value">
            <p>{content.content_update_timestamp}</p>
          </div>
        </div>
      </div>
      )}
    </PopUp>
  );
};

export default ViewContent;
