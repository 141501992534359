import React from "react";
import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import "./CodingPlatform.css";
import { useHttpClient } from "../../Components/Hooks/http-hook";
import { AuthContext } from "../../context/auth-context";
import Loading from "../../Components/Loading";
const swal = require("sweetalert2");

const CodingPlatform = () => {
  const [buttonLoading, setButtonLoading] = useState(false);
  const nav = useNavigate();
  const { sendRequest } = useHttpClient();
  const auth = useContext(AuthContext);

  const startExam = async () => {
    console.log("start exam");
    setButtonLoading(true);
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/api/coding/start`,
        "GET",
        null,
        {
          "Content-Type": "application/json",
          "Refresh-Token": auth.refresh_token,
          Authorization: auth.token,
        }
      );
      if (responseData) {
        setButtonLoading(false);
        nav(`/coding-platform/exam/${responseData.data}`);
        // if (document.body.requestFullscreen) {
        //   document.body.requestFullscreen().catch((e) => {
        //     console.log("error while full screen ", e);
        //   });
        // }
      } else {
        setButtonLoading(false);
        swal.fire({
          title: "Error!",
          text: responseData.message || "Something went wrong",
          icon: "error",
          confirmButtonText: "Okay",
        });
      }
    } catch (err) {
      setButtonLoading(false);
      swal.fire({
        title: "Error!",
        text: "Something went wrong",
        icon: "error",
        confirmButtonText: "Okay",
      });
    }
  };

  return (
    <div className="coding-page">
      <div className="coding-container">
        <div className="coding-content">
          <h1>WELCOME TO SURGE CODING PLATFORM</h1>
          <div className="coding-instructions">
            <h2>INSTRUCTIONS</h2>
            <ul>
              <li>Click on the start button to begin the test.</li>
              <li>Familiarize yourself with the test interface components.</li>
              <li>Read and understand each coding task prompt.</li>
              <li>
                Ensure a stable internet connection to avoid any disruptions
                during the test.
              </li>
              <li>
                <b> Do not refresh the page</b>, as this may result in data loss
                or disqualification from the test.
              </li>
              <li>
                Write code in the designated editor, testing with provided
                samples.
              </li>

              <li>
                Manage time effectively and submit completed tasks before the
                deadline.
              </li>
              <li>
                Ensure not to copy code from any other source. If found, you
                will be disqualified.
              </li>
            </ul>
          </div>
          <button
            className="coding-exam-start-button"
            onClick={startExam}
          >
            {buttonLoading ? <Loading /> : <>Start Now</>}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CodingPlatform;
