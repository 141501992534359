import React, { useEffect, useState , useContext} from "react";
import "./Submissions.css";
import { ToastContainer, toast } from "react-toastify";
import Loading from "../../../../../../../Components/Loading";
import StatusTag from "../../../../Components/UiComponents/Tags/StatusTag";
import Badge from "../../../../Components/UiComponents/Badge/Badge";
import Dropdown from '../../../../Components/UiComponents/Dropdown/Dropdown';
import View from "./Actions/View/View";
import { AdminContext } from "../../../../../../../context/admin-context";
import { useHttpClient } from "../../../../../../../Components/Hooks/http-hook";

const Submissions = ({
    setSelectedTab,
    assignmentId,
    selectedAssignmentData,
    courseId
}) => {
    const admin = useContext(AdminContext);
    const {isLoading, error, sendRequest, clearMessages} = useHttpClient();
    const [submissions, setSubmissions] = useState([]);
    const [selectedSubmission, setSelectedSubmission] = useState({});
    const [showViewPopup, setShowViewPopup] = useState(false);
    const [filterd, setFilter] = useState({});
    const [filteredSubmissions, setFilteredSubmissions] = useState([]);
    const [submissionCounts, setSubmissionCounts] = useState({});
    const [refresh, setRefresh] = useState(false);

    const goToAssignment = () => {
        setSelectedTab("assignment");
    };

    const goToAssignmentList = () => {
        setSelectedTab("assignments-list");
    };

    const showSubmissions = (data) => {
        setSelectedSubmission(data);
        setShowViewPopup(true);
    };


    const getSubmissions = async () => {
        try {
            const responseData = await sendRequest(
                `${process.env.REACT_APP_BACKEND_URL}/assignments/submissions/${courseId}/${assignmentId}`,
                "GET",
                null,
                {
                    "Refresh-Token": admin.refresh_token,
                    Authorization: admin.token,
                }
            );
            if (responseData) {
                setSubmissions(responseData.submissions);
                setSubmissionCounts(responseData.submission_counts);
                setFilter({ value: "", label: "All"})
            }
        }
        catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getSubmissions();
    }
    , [refresh]);

    useEffect(() => {
        if (submissions && submissions.length > 0) {
            const filteredSubmissions = (filterd && filterd.value) ? submissions.filter((data) => data.grading_status === filterd.value) : submissions;
            setFilteredSubmissions(filteredSubmissions);
        }
    }, [filterd]);


    return (
        <div>
            <ToastContainer closeButton={false} />
            <div className="admin-skillbox-nav-header">
                <span onClick={goToAssignment}>
                    Assignments
                </span> &gt; <span onClick={goToAssignmentList}>
                    All Assignments
                </span>
            </div>
            <View
                show={showViewPopup}
                setShow={setShowViewPopup}
                data={selectedSubmission}
                courseName={selectedAssignmentData && selectedAssignmentData.course_name}
                sectionName={selectedAssignmentData && selectedAssignmentData.section_name}
                moduleName={selectedAssignmentData && selectedAssignmentData.module_name}
                assignmentTitle={selectedAssignmentData && selectedAssignmentData.title}
                assignmentId={assignmentId}
                setRefresh={setRefresh}
            />

            <div className="admin-skillbox-header">
                <div className="admin-skillbox-header-title">
                    {selectedAssignmentData && selectedAssignmentData.title}&nbsp;
                    - {selectedAssignmentData && selectedAssignmentData.module_name}
                    <div className="admin-skillbox-header-subtitle">
                        <b>Course: </b> {selectedAssignmentData && selectedAssignmentData.course_name}&nbsp;|&nbsp;
                        <b>Pathway: </b> {selectedAssignmentData && selectedAssignmentData.section_name}
                    </div>
                </div>
                <div className="ad-assgn-body-subs-filter">
                    <Dropdown
                        title={"Filter"}
                        items={[
                            { value: "", label: "All"},
                            { value: "pending", label: "Pending" },
                            { value: "due_date_over", label: "Due date over" },
                            { value: "submitted", label: "Submitted" },
                            { value: "graded", label: "Graded" }
                        ]}
                        onChange={(value) => setFilter(value)}
                    />
                </div>
            </div>

            {(isLoading || error) && (
                <div className="ad-assgn-body">
                    <div className="ad-assgn-subs-nsection">
                        {isLoading && (
                            <Loading color="#004c73" customClass="ad-assgn-interview-loading" />
                        )}
                        {error && <div className="interview-questions-not-found-section">
                            Something went wrong, Please try again later
                        </div>
                        }
                    </div>
                </div>
            )}
            {!isLoading && submissions && submissions.length === 0 && (
                <div className="ad-assgn-body">
                    <div className="ad-assgn-subs-nsection">
                        <div className="interview-questions-not-found-section">
                            No Assignments Found
                        </div>
                    </div>
                </div>
            )}
            {submissions && submissions.length > 0 && (
                <>
                    <div className="ad-assgn-tags">
                        <StatusTag 
                            count={(submissionCounts && submissionCounts.total_submissions) ? submissionCounts.total_submissions : 0}
                            TOT 
                        />
                        <StatusTag 
                            count={(submissionCounts && submissionCounts.pending_submissions) ? submissionCounts.pending_submissions : 0}
                            PEND 
                        />
                        <StatusTag 
                            count={(submissionCounts && submissionCounts.left_to_grade) ? submissionCounts.left_to_grade : 0}
                            LTG 
                        />
                    </div>
                    <div className="ad-assgn-body">
                        <div className="ad-assgn-subs-section">
                            <div className="ad-assgn-table">
                                <div className="ad-assgn-subs-table-header">
                                    <div className="ad-assgn-subs-table-header-row-1">
                                        Student Name
                                    </div>
                                    <div className="ad-assgn-subs-table-header-row-2">
                                        Status
                                    </div>
                                    <div className="ad-assgn-subs-table-header-row-3">
                                        Submissions
                                    </div>
                                </div>
                                <div className="ad-assgn-hr-line"></div>
                                {(filterd && filteredSubmissions) && filteredSubmissions.map((data) => (
                                    <div
                                        key={data.id}
                                        className="ad-assgn-subs-table-row"
                                    >
                                        <div className="ad-assgn-subs-table-row-1">
                                            {data.name}
                                        </div>
                                        <div className="ad-assgn-subs-table-row-2">
                                            <Badge
                                                {...{ [data.grading_status]: true }}
                                            />
                                        </div>
                                        <div className="ad-assgn-subs-table-row-3" onClick={() => showSubmissions(data)}>
                                            View
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default Submissions;