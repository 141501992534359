import React from "react";

export const AssignmentIconSvg = () => {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 20C1.45 20 0.979333 19.8043 0.588 19.413C0.196667 19.0217 0.000666667 18.5507 0 18V4C0 3.45 0.196 2.97933 0.588 2.588C0.98 2.19667 1.45067 2.00067 2 2H6.2C6.41667 1.4 6.77933 0.916667 7.288 0.55C7.79667 0.183333 8.36733 0 9 0C9.63333 0 10.2043 0.183333 10.713 0.55C11.2217 0.916667 11.584 1.4 11.8 2H16C16.55 2 17.021 2.196 17.413 2.588C17.805 2.98 18.0007 3.45067 18 4V18C18 18.55 17.8043 19.021 17.413 19.413C17.0217 19.805 16.5507 20.0007 16 20H2ZM2 18H16V4H2V18ZM4 16H11V14H4V16ZM4 12H14V10H4V12ZM4 8H14V6H4V8ZM9 3.25C9.21667 3.25 9.396 3.179 9.538 3.037C9.68 2.895 9.75067 2.716 9.75 2.5C9.75 2.28333 9.679 2.10433 9.537 1.963C9.395 1.82167 9.216 1.75067 9 1.75C8.78333 1.75 8.60433 1.821 8.463 1.963C8.32167 2.105 8.25067 2.284 8.25 2.5C8.25 2.71667 8.321 2.896 8.463 3.038C8.605 3.18 8.784 3.25067 9 3.25Z"
        fill="#004C73"
      />
    </svg>
  );
};

export default AssignmentIconSvg;
