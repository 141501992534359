import React from "react";
import "../Common.css";
import CustomPopup from "../../../../../../../../../../../../Admin/SkillboxAdmin/Components/Popup/CustomPopup";
import Input from "../../../../../../../../../../../../../Components/Profile/Input/Input";
import Badge from "../../../../../../../../../../../../Admin/SkillboxAdmin/Components/UiComponents/Badge/Badge";
import { FaDownload } from "react-icons/fa";

const View = ({
    show,
    setShow,
    assignmentData,
}) => {
    const handleClose = () => {
        setShow(false);
    };

    return (
        <CustomPopup
            title={assignmentData.title}
            subtitle={`<b>Course:</b> Data Science & AI Program | <b>Pathway:</b> ML fundamentals <br/> <b>Module:</b> Python Programming`}
            show={show}
            onClose={handleClose}
        >
            <div className="sk-st-asgnmt_popup-content">
                <div className="sk-st-asgnmt_popup-content-row">
                    <label htmlFor="title" className="sk-st-asgnmt_popup-content-row-label">
                        Title
                    </label>
                    <Input
                        width="100%"
                        name="title"
                        value={assignmentData.title}
                        isDisabled={true}
                    />
                </div>
                <div className="sk-st-asgnmt_popup-content-row">
                    <label htmlFor="skills" className="sk-st-asgnmt_popup-content-row-label">
                        Skills
                    </label>
                    <Input
                        width="100%"
                        name="skills"
                        value={assignmentData.skills}
                        isDisabled={true}
                    />
                </div>
                <div className="sk-st-asgnmt_popup-content-row">
                    <label htmlFor="file" className="sk-st-asgnmt_popup-content-row-label">
                        Assignment File
                    </label>
                    {assignmentData.assignment_file && (
                        <>
                            {assignmentData.assignment_file.startsWith("http://") ||
                                assignmentData.assignment_file.startsWith("https://") ? (
                                <a href={assignmentData.assignment_file} className="sk-ad-asgnmt_popup-content-row-file" download>
                                    Download Assignment <FaDownload />
                                </a>
                            ) : (
                                <a href={`${process.env.REACT_APP_BACKEND_URL
                                    }${assignmentData.assignment_file}`} className="sk-ad-asgnmt_popup-content-row-file" download>
                                    Download Assignment <FaDownload />
                                </a>
                            )}
                        </>
                    )}
                </div>
                {assignmentData.submission_file && (
                    <div className="sk-st-asgnmt_popup-content-row">
                        <label htmlFor="file" className="sk-st-asgnmt_popup-content-row-label">
                            Your Submission
                        </label>
                        {assignmentData.submission_file && (
                            <>
                                {assignmentData.submission_file.startsWith("http://") ||
                                    assignmentData.submission_file.startsWith("https://") ? (
                                    <a href={assignmentData.submission_file} className="sk-ad-asgnmt_popup-content-row-file" download>
                                        Download Submission <FaDownload />
                                    </a>
                                ) : (
                                    <a href={`${process.env.REACT_APP_BACKEND_URL
                                        }${assignmentData.submission_file}`} className="sk-ad-asgnmt_popup-content-row-file" download>
                                        Download Submission <FaDownload />
                                    </a>
                                )}
                            </>
                        )}
                    </div>
                )}
                <div className="sk-st-asgnmt_popup-content-row-sp">
                    <div className="sk-st-asgnmt_popup-content-row">
                        <label htmlFor="due_date" className="sk-st-asgnmt_popup-content-row-label">
                            Due Date
                        </label>
                        <Input
                            width="95%"
                            name="due_date"
                            value={assignmentData.deadline}
                            isDisabled={true}
                        />
                    </div>
                    <div className="sk-st-asgnmt_popup-content-row">
                        <label htmlFor="status" className="sk-st-asgnmt_popup-content-row-label">
                            Status
                        </label>
                        <Badge
                            {...{ [assignmentData.grading_status]: true }}
                        />
                    </div>
                </div>
            </div>
        </CustomPopup>
    );
}

export default View;