import React from "react";
import PopUp from "../UiComponents/Popup/PopUp";
import "./AddCourse.css";
import { useState, useContext, useEffect } from "react";
import { AdminContext } from "../../../../context/admin-context";
import { useHttpClient } from "../../../../Components/Hooks/http-hook";
import LoadingSpinner from "../../../../Components/Input/LoadingSpinner";
const swal = require("sweetalert2");

const AddCourse = ({ showPopup, setShowPopup, onAdd }) => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [buttonLoading, setButtonLoading] = useState(false);
  const admin = useContext(AdminContext);

  const initialCourseState = {
    course_id: "",
    course_name: "",
    course_description: "",
    active_tag: false,
    start_date: "",
  };

  const [course, setCourse] = useState(initialCourseState);

  const [validationErrors, setValidationErrors] = useState({
    course_id: false,
    course_name: false,
    course_description: false,
    start_date: false,
  });

  const handlechange = (e) => {
    const { name, value } = e.target;

    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: false,
    }));

    setCourse((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const resetForm = () => {
    setCourse(initialCourseState);
    setValidationErrors({
      course_id: false,
      course_name: false,
      course_description: false,
      start_date: false,
    });
  };

  const validateForm = () => {
    const requiredFields = [
      "course_id",
      "course_name",
      "course_description",
      "start_date",
    ];
    const newValidationErrors = {};

    requiredFields.forEach((field) => {
      if (!course[field].trim()) {
        newValidationErrors[field] = true;
      }
    });

    setValidationErrors(newValidationErrors);

    return Object.keys(newValidationErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      setButtonLoading(false);
      return;
    }

    setButtonLoading(true);
    try {
      const response = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/db/course`,
        "POST",
        JSON.stringify(course),
        {
          "Content-Type": "application/json",
          "Refresh-Token": admin.refresh_token,
          Authorization: admin.token,
        }
      );
      if (response) {
        setButtonLoading(false);
        setShowPopup(false);
        onAdd(response.course);
        swal.fire({
          title: "Success",
          text: "Course Added Successfully",
          icon: "success",
          confirmButtonText: "Ok",
        });
      }
    } catch (err) {
      swal.fire({
        title: "Error Occured",
        text: err.message,
        icon: "error",
        confirmButtonText: "Ok",
      });
    }
  };

  useEffect(() => {
    if (error) {
      setButtonLoading(false);
      setShowPopup(false);
      swal.fire({
        title: "Error!",
        text: error || "Something went wrong, please try again later.",
        icon: "error",
        confirmButtonText: "Close",
      });
      clearError();
    }
  }
  , [error, clearError, setShowPopup]);

  return (
    <PopUp
      title="Add Course"
      onClose={() => {
        resetForm();
        setShowPopup(false);
      }}
      show={showPopup}
    >
      {buttonLoading ? (
        <div className="add_course_popup__loading">
          <LoadingSpinner />
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="add_course_popup__content">
            <div className="add_course_popup__content-row">
              <div className="add_course_popup__content-row-title">
                <p>Course ID</p>
              </div>
              <div className="add_course_popup__content-row-value">
                <input
                  type="text"
                  placeholder="Enter a 4 digit Course ID"
                  onChange={handlechange}
                  name="course_id"
                  value={course.course_id}
                  className={
                    validationErrors.course_id ? "input_val_error" : ""
                  }
                />
                {validationErrors.course_id && (
                  <small className="input_val_error-text">
                    Course ID is required
                  </small>
                )}
              </div>
            </div>
            <div className="add_course_popup__content-row">
              <div className="add_course_popup__content-row-title">
                <p>Course Name</p>
              </div>
              <div className="add_course_popup__content-row-value">
                <input
                  type="text"
                  placeholder="Enter Course Name"
                  onChange={handlechange}
                  name="course_name"
                  value={course.course_name}
                  className={
                    validationErrors.course_name ? "input_val_error" : ""
                  }
                />
                {validationErrors.course_name && (
                  <small className="input_val_error-text">
                    Course Name is required
                  </small>
                )}
              </div>
            </div>
            <div className="add_course_popup__content-row">
              <div className="add_course_popup__content-row-title">
                <p>Course Description</p>
              </div>
              <div className="add_course_popup__content-row-value">
                <input
                  type="text"
                  placeholder="Enter Course Description"
                  onChange={handlechange}
                  name="course_description"
                  value={course.course_description}
                  className={
                    validationErrors.course_description ? "input_val_error" : ""
                  }
                />
                {validationErrors.course_description && (
                  <small className="input_val_error-text">
                    Course Description is required
                  </small>
                )}
              </div>
            </div>
            <div className="add_course_popup__content-row">
              <div className="add_course_popup__content-row-title">
                <p>Active Tag</p>
              </div>
              <div className="add_course_popup__content-row-value">
                <select
                  name="active_tag"
                  value={course.active_tag}
                  onChange={handlechange}
                >
                  <option value="true">Live</option>
                  <option value="false">Not Live</option>
                </select>
              </div>
            </div>
            <div className="add_course_popup__content-row">
              <div className="add_course_popup__content-row-title">
                <p>Start Date</p>
              </div>
              <div className="add_course_popup__content-row-value">
                <input
                  type="date"
                  onChange={handlechange}
                  name="start_date"
                  value={course.start_date}
                  className={
                    validationErrors.start_date ? "input_val_error" : ""
                  }
                />
                <div>
                  {validationErrors.start_date && (
                    <small className="input_val_error-text">
                      Start Date is required
                    </small>
                  )}
                </div>
              </div>
            </div>

            <div className="add_course_popup__buttons">
              <button
                className="add_course_popup__buttons-cancel"
                type="button"
                onClick={() => setShowPopup(false)}
              >
                Cancel
              </button>
              <button className="add_course_popup__buttons-save" type="submit">
                Add
              </button>
            </div>
          </div>
        </form>
      )}
    </PopUp>
  );
};

export default AddCourse;
