import React, { useContext, useEffect, useState } from 'react'
import { Scrollbars } from "react-custom-scrollbars-2";

import './ModeratorHome.css'
import { AuthContext } from '../../context/auth-context'
import { useHttpClient } from '../../Components/Hooks/http-hook'
import StudentInfoPop from './StudentInfoPop';
import ScheduleCmr from './ScheduleCmr';
import download from "../../assets/download.png";

const ModeratorHome = () => {
  const auth = useContext(AuthContext);
  const [isDashboard, setIsDashboard] = useState(true);
  const [isSchedule, setIsSchedule] = useState(false);
  const [isAttendance, setIsAttendance] = useState(false);

  const [statsInfo, setStatsInfo] = useState();
  const [selectedOption, setselectedOption] = useState("CMRIT");

  const campusList = ["CMRIT", "CMREC", "CMRTC", "CMRCET"];

  const [attendanceStats, setAttendanceStats] = useState();

  const [studentInfo, setStudentInfo] = useState();
  const [loading, setLoading] = useState(false);

  const tabCLickHandaler = (value) => {
    if (value == "dashboard") {
      setIsDashboard(true);
      setIsSchedule(false);
      setIsAttendance(false);
    } else if (value == "schedule") {
      setIsDashboard(false);
      setIsSchedule(true);
      setIsAttendance(false);
    } else if (value == "attendance") {
      setIsDashboard(false);
      setIsSchedule(false);
      setIsAttendance(true);
    }
  };

  const handleSelectChange = (event) => {
    setselectedOption(event.target.value);
  };

  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const program_id = auth.course_ids_info[0].program_id;

  const getSubmissionStats = async () => {
    // event.preventDefault();
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/s3/assignment-submissions-stats-cmr/${program_id}`,
        "GET"
      );

      if (responseData.ok == true) {
        return responseData.data;
      } else {
        alert(responseData.msg);
      }
    } catch (err) {
        console.log(err);
        }
  };

  const getAttendanceStats = async () => {
    // event.preventDefault();
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/db/attendance-stats/0004`,
        "GET"
      );

      if (responseData.ok == true) {
        return responseData.data;
      } else {
        alert(responseData.msg);
      }
    } catch (err) {
                    console.log(err);
                  }
  };

  const getStudentInfoByCampus = async () => {
    // event.preventDefault();
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/s3/student-list`,
        "GET"
      );

      if (responseData.ok == true) {
        return responseData.data;
      } else {
        alert(responseData.msg);
      }
    } catch (err) {
                    console.log(err);
                  }
  };

   const downloadReport = async (reportType) => {
    try {
      setLoading(true);

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/s3/${reportType}/0004`,
        {
          method: 'GET',
          headers: {
            Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            "Refresh-Token": auth.refresh_token,
            Authorization: auth.token,
          },
          responseType: 'blob',
        }
      );

      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${reportType}.xlsx`);
        document.body.appendChild(link);
        link.click();
      } else {
        const errorMessage = await response.text();
        console.error(`Failed to download Excel file: ${errorMessage}`);
      }
    } catch (err) {
      console.error('Error while downloading Excel file:', err);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    getSubmissionStats().then((response) => setStatsInfo(response));
    getAttendanceStats().then((responseAttendanceInfo) =>
      setAttendanceStats(responseAttendanceInfo)
    );
    getStudentInfoByCampus().then((responseInfo) =>
      setStudentInfo(responseInfo)
    );
  }, []);

  const handleStudentClick = (id, name, mobile, email) => {
    setStudentInfoPop(true);
    setStudentId(id);
    setStudentName(name);
    setStudentMobile(mobile);
    setStudentEmail(email);
  };

  const [studentInfoPop, setStudentInfoPop] = useState(false);
  const [studentId, setStudentId] = useState("");
  const [studentName, setStudentName] = useState("");
  const [studentMobile, setStudentMobile] = useState("");
  const [studentEmail, setStudentEmail] = useState("");

  const toggleStuAssignPopup = () => {
    setStudentInfoPop(!studentInfoPop);
  };

  const formatDate = new Intl.DateTimeFormat("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    timeZone: "UTC",
    hour12: false,
  });

  return (
    <div className="moderator-home">
      <div className="tab-flex">
        <div
          style={{
            backgroundImage: isDashboard
              ? "linear-gradient(to right, #eef6fc 0%, #f84f20  120%, #f84f20  100%)"
              : "linear-gradient(to right, #eef6fc 0%, #044c74  120%, #eef6fc  100%)",
          }}
          className="tab-flex-element"
          onClick={() => tabCLickHandaler("dashboard")}
        >
          Dashboard
        </div>
        <hr />
        <div
          style={{
            backgroundImage: isSchedule
              ? "linear-gradient(to right, #eef6fc 0%, #f84f20  120%, #f84f20  100%)"
              : "linear-gradient(to right, #eef6fc 0%, #044c74  120%, #eef6fc  100%)",
          }}
          className="tab-flex-element"
          onClick={() => tabCLickHandaler("schedule")}
        >
          Schedule
        </div>
        <hr />
        {/* <hr/>
            <div 
                style={{"backgroundImage": isAttendance ? "linear-gradient(to right, #eef6fc 0%, #f84f20  120%, #f84f20  100%)" : "linear-gradient(to right, #eef6fc 0%, #044c74  120%, #eef6fc  100%)"}}
                className='tab-flex-element'
                onClick={()=>tabCLickHandaler("attendance")}
            >
                Attendance
            </div> */}
      </div>
      {/* <vr/> */}
      <div className="info-flex">
        {isDashboard && (
          <div className="dashboard-stats">
            <p>{auth.course_ids_info[0].institute_info_json.institute_name}</p>
            <p>
              No Of Students :{" "}
              {auth.course_ids_info[0].institute_info_json.no_of_students}
            </p>
          </div>
        )}
            <div className="download-stats">
                <button
                    className="download-btn"
                    disabled={loading}
                    onClick={() => downloadReport('attendance-report')}
                >
                    <p>Download Attendance Report</p><img src={download} />
                </button>

                <button
                    className="download-btn"
                    disabled={loading}
                    onClick={() => downloadReport('class-schedule-report')}
                >
                    <p>Download Class Schedule Report</p> <img src={download} />
                </button>
            </div>
        {isDashboard && attendanceStats && attendanceStats.length != 0 && (
          <h3>Attendance Stats</h3>
        )}
        <Scrollbars
          autoHide
          autoHideTimeout={2000}
          autoHideDuration={310}
          autoHeightMin={300}
          autoHeightMax={800}
          thumbMinSize={30}
          universal={true}
          style={{
            width: `100%`,
            height: "25rem",
            background: "white",
            borderRadius: "6px",
          }}
        >
          {isDashboard && attendanceStats && attendanceStats.length != 0 && (
            <table class="module-table" cellSpacing={"0px"}>
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Title</th>
                  <th>Scheduled on</th>
                  <th>Total Attendance</th>
                  <th>Attendance in First 45mins</th>
                </tr>
              </thead>
              {attendanceStats.length != 0 &&
                attendanceStats.map((p, i) => {
                  return (
                    <tr>
                      <td>{i + 1}</td>
                      <td>{p.title}</td>
                      <td>{formatDate.format(new Date(p.scheduled_start))}</td>
                      <td>{p.total_attendance}</td>
                      <td>{p.attendance_in45}</td>
                    </tr>
                  );
                })}
            </table>
          )}
        </Scrollbars>
        {isDashboard && statsInfo && statsInfo.assign_info.length != 0 && (
          <h3>Assignment Submission Stats</h3>
        )}
        <Scrollbars
          autoHide
          autoHideTimeout={2000}
          autoHideDuration={310}
          autoHeightMin={300}
          autoHeightMax={800}
          thumbMinSize={30}
          universal={true}
          style={{
            width: `100%`,
            height: "25rem",
            background: "white",
            borderRadius: "6px",
          }}
        >
          {isDashboard && statsInfo && statsInfo.assign_info.length != 0 && (
            <table class="module-table" cellSpacing={"0px"}>
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Assignment</th>
                  <th>Deadline</th>
                  <th>Submissions</th>
                  <th>Graded</th>
                </tr>
              </thead>
              {statsInfo.assign_info.length != 0 &&
                statsInfo.assign_info.map((p, i) => {
                  return (
                    <tr>
                      <td>{i + 1}</td>
                      <td>{p.title}</td>
                      <td>{p.deadline}</td>
                      <td>
                        {
                          statsInfo.stats_info.find(
                            (obj) => obj.title === p.title
                          ).submissions_count
                        }
                      </td>
                      <td>
                        {
                          statsInfo.stats_info.find(
                            (obj) => obj.title === p.title
                          ).gradings_count
                        }
                      </td>
                    </tr>
                  );
                })}
            </table>
          )}
        </Scrollbars>
        {isDashboard && studentInfo && studentInfo.length != 0 && (
          <div className="subs-status-heading">
            <h3>Students Submissions Status</h3>
            <div className="subs-status-heading-dropdown">
              <label htmlFor="dropdown">Campus:</label>
              <select
                id="dropdown"
                value={selectedOption}
                onChange={handleSelectChange}
              >
                <option value="CMRIT">CMRIT</option>
                {campusList.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
          </div>
        )}
        {isDashboard && (
          <Scrollbars
            autoHide
            autoHideTimeout={2000}
            autoHideDuration={310}
            autoHeightMin={300}
            autoHeightMax={800}
            thumbMinSize={30}
            universal={true}
            style={{
              width: `100%`,
              height: "25rem",
              background: "white",
              borderRadius: "6px",
            }}
          >
            {isDashboard && studentInfo && studentInfo.length != 0 && (
              <table class="module-table" cellSpacing={"0px"}>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Mobile</th>
                    <th>Email</th>
                  </tr>
                </thead>
                {studentInfo.length != 0 &&
                  studentInfo.map((p, i) => {
                    if (p.college_name == selectedOption) {
                      return (
                        <tr>
                          <td>{p.name}</td>
                          <td>{p.mobile}</td>
                          <td>{p.email}</td>
                          <td>
                            <button
                              onClick={() =>
                                handleStudentClick(
                                  p.surge_id,
                                  p.name,
                                  p.mobile,
                                  p.email
                                )
                              }
                            >
                              check
                            </button>
                          </td>
                        </tr>
                      );
                    }
                  })}
              </table>
            )}
          </Scrollbars>
        )}
        {studentInfoPop && (
          <StudentInfoPop
            handleClose={toggleStuAssignPopup}
            studentDetails={{
              studentId: studentId,
              studentName: studentName,
              studentMobile: studentMobile,
              studentEmail: studentEmail,
            }}
          ></StudentInfoPop>
        )}
        {isSchedule && <ScheduleCmr />}
      </div>
    </div>
  );
};

export default ModeratorHome