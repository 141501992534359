import React, { useState, useEffect, useContext } from "react";
import "./QtTool.css";
import MyHistory from "./layouts/MyHistory/MyHistory";
import RolesHistory from "./layouts/RolesHistory/RolesHistory";
import CourseHistory from "./layouts/CourseHistory/CourseHistory";

const QtTool = () => {
  const [selectedTab, setSelectedTab] = useState("myhistoryhome");
  const [selectedRolesData, setSelectedRolesData] = useState([]);
  const [selectedCoursesData, setSelectedCoursesData] = useState([]);

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  }

  return (
    <div className="assignments">
      {selectedTab === "myhistoryhome" && <MyHistory handleTabChange={handleTabChange} setSelectedRolesData={setSelectedRolesData} setSelectedCoursesData={setSelectedCoursesData} />}
      {selectedTab === "roleshistoryhome" && <RolesHistory handleTabChange={handleTabChange} selectedRolesData={selectedRolesData} previousTab={"myhistoryhome"} />}
      {selectedTab === "coursehistoryhome" && <CourseHistory handleTabChange={handleTabChange} selectedCoursesData={selectedCoursesData} previousTab={"myhistoryhome"} />}
    </div>
  );
};

export default QtTool;