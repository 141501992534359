import React from "react";

const QCreationCoursesSvg = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.875 11.3512L5.85562 9.3318L5.0625 10.1249L7.875 12.9374L12.9375 7.87492L12.1444 7.07617L7.875 11.3512Z"
        fill="white"
      />
      <path
        d="M14.0625 2.8125H12.375V2.25C12.375 1.95163 12.2565 1.66548 12.0455 1.4545C11.8345 1.24353 11.5484 1.125 11.25 1.125H6.75C6.45163 1.125 6.16548 1.24353 5.9545 1.4545C5.74353 1.66548 5.625 1.95163 5.625 2.25V2.8125H3.9375C3.63913 2.8125 3.35298 2.93103 3.142 3.142C2.93103 3.35298 2.8125 3.63913 2.8125 3.9375V15.75C2.8125 16.0484 2.93103 16.3345 3.142 16.5455C3.35298 16.7565 3.63913 16.875 3.9375 16.875H14.0625C14.3609 16.875 14.647 16.7565 14.858 16.5455C15.069 16.3345 15.1875 16.0484 15.1875 15.75V3.9375C15.1875 3.63913 15.069 3.35298 14.858 3.142C14.647 2.93103 14.3609 2.8125 14.0625 2.8125ZM6.75 2.25H11.25V4.5H6.75V2.25ZM14.0625 15.75H3.9375V3.9375H5.625V5.625H12.375V3.9375H14.0625V15.75Z"
        fill="white"
      />
    </svg>
  );
};

export default QCreationCoursesSvg;
