import React, { useState, useEffect, useContext } from "react";
import "../PAC.css";

import Loading from "../../../Components/Loading";
import NewResume from "../PA_Components/NewResume_PA_UR";

const UploadResumeContent = ({ setSelectedOption }) => {
  const [pageLoading, setPageLoading] = useState(false);

  const goToHome = () => {
    setSelectedOption({
      value: "skillbox",
      label: "Skillbox",
    });
  };
  const [autosave, setAutosave] = useState(false);

  const [addNew, setAddNew] = useState(false);

  return (
    <div className="profile-contact-page">
      <div
        className="profile-content-header-nav-menu-under-development"
        onClick={goToHome}
      >
        Home
      </div>
      <div className="profile-header">
        <div
          className="profile-header-title"
          style={{ fontSize: "20px", paddingTop: "10px", fontWeight: "600" }}
        >
          Upload Resume
        </div>
        <div className="profile-autosave">
        {autosave ? <>Autosaving...</> : <>Autosave is on</>}
        </div>
      </div>
      {pageLoading ? (
        <div className="profile-loader-container">
          <div className="profile-loader-sub-container">
            <Loading
              color="#14144e"
              customClass="profile-loader-container-for-loader"
            />
          </div>
        </div>
      ) : (
        <div>
          <NewResume addNew={addNew} setAddNew={setAddNew} />

        </div>
      )}
    </div>
  );
};

export default UploadResumeContent;
