import React , { useState, useEffect, useContext } from "react";
import "./Dashboard.css";
import { useHttpClient } from "../../../../Components/Hooks/http-hook";
import { AuthContext } from "../../../../context/auth-context";
import Loading from "../../../../Components/Loading";
import moment from "moment";
const swal = require("sweetalert2");

const Dashboard = () => {
  const { error, clearError, sendRequest, isLoading } = useHttpClient();
  const auth = useContext(AuthContext);
  const [attemptedPractice, setAttemptedPractice] = useState([]);

  const handleDateFormatter = (date) => {
    return moment(date).format("YYYY-MM-DD HH:mm:ss");
  };

  useEffect(() => {
    const fetchAttemptedTests = async () => {
      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/prep/get-all-previously-solved-practice`,
          "GET",
          null,
          {
            "Content-Type": "application/json",
            "Refresh-Token": auth.refresh_token,
            Authorization: auth.token,
          }
        );
        if (responseData.data) {
          setAttemptedPractice(responseData.data);
        }
      } catch (err) {
        console.log(err);
      }
    };
    fetchAttemptedTests();
  }, []);

  const handleUpgrade = () => {
    swal.fire({
      title: "Upgrade to Premium",
      text: "Premium features include unlimited practice questions, detailed analysis and much more, it's about to come soon!",
      icon: "info",
    });
  };

  return (
    <div className="practice-dashboard">
      <div className="practice-dashboard-total-count-details">
        <div className="practice-dashboard-progress">
          <p className="practice-dashboard-practice-attempted">
            {attemptedPractice.length} / 140 Practice Attempted
          </p>
          <p className="practice-dashboard-daily-streak">
            {/* Daily Streak: {totalCount.dailyStreak} Days */}
            Welcome to the Practice Dashboard
          </p>
        </div>
        <div className="practice-dashboard-upgrade">
          <button className="upgrade-button" onClick={handleUpgrade}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-star"
            >
              <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
            </svg>
            Upgrade to Premium
          </button>
        </div>
      </div>

      <h2 className="practice-dashboard-attempted-practice">
        Last Practice - {attemptedPractice.length}
      </h2>
      <div className="practice-dashboard-info">
        You are making a good progress. Keep it up!
      </div>
      <table className="practice-dashboard-attempted-practice-table">
        <thead>
          <tr>
            <th className="practice-dashboard-practice-id">S. No.</th>
            <th className="practice-dashboard-practice-title">Practice ID</th>
            <th className="practice-dashboard-practice-type">Type</th>
            <th className="practice-dashboard-practice-status">Status</th>
            <th className="practice-dashboard-practice-score">
              Score
            </th>
            <th className="practice-dashboard-practice-time">Time</th>
          </tr>
        </thead>
        <tbody>
          {attemptedPractice.map((practice, index) => (
            <tr key={index}>
              <td className="practice-dashboard-practice-id">{index + 1}</td>
              <td className="practice-dashboard-practice-title">{practice.practice_id}</td>
              <td className="practice-dashboard-practice-type">{practice.type}</td>
              <td className="practice-dashboard-practice-status">{practice.status}</td>
              <td className="practice-dashboard-practice-score">{practice.score_gained} / {practice.total_score}</td>
              <td className="practice-dashboard-practice-time">{handleDateFormatter(practice.practice_date)}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* <h2 className="practice-dashboard-practice-suggestions">Suggestions</h2>
      <div className="practice-dashboard-info">
        Here are few curated pracitce questions for you.
      </div>
      <div className="practice-dashboard-practice-suggestions-list">
        {practiceSuggestions.map((practice, index) => (
          <div className="practice-dashboard-practice-suggestion-card" key={index}>
            <div className="practice-dashboard-practice-suggestion-title">
              {practice.title}
            </div>
            <p className="practice-dashboard-practice-suggestion-type">
              Type: {practice.type}
            </p>
            <p className="practice-dashboard-practice-suggestion-date">
              Date: {practice.date}
            </p>
            <button className="practice-dashboard-attend-practice-button" onClick={handleSchedulePractice}>
              Practice Now
            </button>
          </div>
        ))}
      </div> */}
    </div>
  );
};

export default Dashboard;
