import React, { useState } from "react";
import "./Assessment.css";
import Dashboard from "./Pages/Dashboard";
import CodingAssessment from "./Pages/CodingAssessment";
import AptitudeAssessment from "./Pages/AptitudeAssessment";
import CompanyAssessment from "./Pages/CompanyAssessment";

const AssessmentPage = () => {
  const [activeTab, setActiveTab] = useState("dashboard");

  const renderTabContent = () => {
    switch (activeTab) {
      case "dashboard":
        return <Dashboard />;
      case "coding":
        return <CodingAssessment />;
      case "aptitude":
        return <AptitudeAssessment />;
      case "company":
        return <CompanyAssessment />;
      default:
        return null;
    }
  };

  return (
    <div className="assessment-page">
      <h1 className="main-heading">Skillbox Assessment </h1>
      <div className="assesment-tabs">
        <div
          className={activeTab === "dashboard" ? "assessment-active-tab" : ""}
          onClick={() => setActiveTab("dashboard")}
        >
          Dashboard
        </div>
        <div
          className={activeTab === "coding" ? "assessment-active-tab" : ""}
          onClick={() => setActiveTab("coding")}
        >
          Coding Test
        </div>
        <div
          className={activeTab === "aptitude" ? "assessment-active-tab" : ""}
          onClick={() => setActiveTab("aptitude")}
        >
          Aptitude Test
        </div>
        <div
          className={activeTab === "company" ? "assessment-active-tab" : ""}
          onClick={() => setActiveTab("company")}
        >
          Company Wise Test
        </div>
      </div>
      <div className=".assesment-tab-content">{renderTabContent()}</div>
    </div>
  );
};

export default AssessmentPage;
