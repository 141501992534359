import React from "react";
import SWOT_List from "./SWOT_List";
import MatchingScore from "./MatchingScore";
import AdditionalKeywordsandNote from "./AdditionalKeywordsandNote";
import { reportData } from "./ReportData";
import { matchingData } from "./MatchingData";
import { additionalKeywords } from "./AdditionalKeywordsData";

const ScanResult = ({ keywordsData }) => {

  return (
    <div style={{ textAlign: "center" }}>
      <SWOT_List reportData={reportData} />

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          padding: "10px 40px",
        }}
      >
        <div
          className="matching-score"
          style={{ width: "40%", height: "100%" }}
        >
          <MatchingScore matchingData={matchingData} />
        </div>
        <div
          className="addl-keywords-note"
          style={{
            width: "50%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            textAlign: "left",
            padding: "10px",
          }}
        >
          <AdditionalKeywordsandNote additionalKeywords={additionalKeywords} />
        </div>
      </div>
    </div>
  );
};

export default ScanResult;
