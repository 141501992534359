import React , { useState, useContext } from 'react';
import "./Notify.css";
import Common from '../Common/Common';
import Button from '../../../../../../../../../Components/Profile/Button/Button';
import { useHttpClient } from "../../../../../../../../../Components/Hooks/http-hook";
import { AdminContext } from '../../../../../../../../../context/admin-context';
import { toast } from 'react-toastify';

const Notify = ({
        data,
        courseName,
        sectionName,
        moduleName,
        assignmentTitle,
        assignmentId,
        setRefresh,
        onClose
    }) => {
        const admin = useContext(AdminContext);
        const { isLoading, error, sendRequest, clearError } = useHttpClient();

        const [notified, setNotified] = useState(false);

        const notifyAssignment = async () => {
            try {
                const responseData = await sendRequest(
                    `${process.env.REACT_APP_BACKEND_URL}/assignments/notify`,
                    "post",
                    JSON.stringify({
                        email : data.email,
                        grading_status : data.grading_status.toUpperCase().replace(/_/g, " "),
                        course_name : courseName,
                        assignment_deadline : data.deadline,
                        assignment_title : assignmentTitle,
                        student_name : data.name,
                    }),
                    {
                        "Content-Type": "application/json",
                        "Refresh-Token": admin.refresh_token,
                        Authorization: admin.token,
                    }
                );
                if (responseData) {
                    toast.success("Assignment Notification Sent Successfully");
                    setNotified(true);
                    setRefresh(true);
                    onClose();
                }
            } catch (err) {
                toast.error("Error Sending Assignment Notification");
                console.log(err);
            }
        }
    return (
        <div className="ad-sk-asgnmt_sbms_usr-dtls-ntfy-section">
            <Common 
                userDetails={data} 
                courseName={courseName}
                sectionName={sectionName}
                moduleName={moduleName}
                assignmentTitle={assignmentTitle}
            />
            <div className="ad-sk-asgnmt_sbms_usr-dtls-ntfy-sub-section">
                <div className="ad-sk-asgnmt_sbms_usr-dtls-ntfy-sub-section-text">
                    Send Assignment Alert Notification on email
                </div>
                <a href={data.submission_files && data.submission_files} download>
                    {isLoading ? (
                        <Button
                            text="Notifying..."
                            width="auto"
                            disabled
                        />
                    ) : (
                        <Button
                            text={notified ? "Notified" : "Notify"}
                            width="auto"
                            onClick={notifyAssignment}
                            disabled={notified}
                        />
                    )}
                </a>
            </div>
        </div>
    );
};

export default Notify;