import React, { useState } from "react";
import "../PAC.css";
import SavedResume from "../UploadResume/SavedResume_UR";
import CloseIcon from "./CloseIcon";
import DeleteIcon from "./DeleteIcon";
import CloudIcon from "./CloudIcon";

const DragDrop = ({ setAddNew }) => {
  const [selectedFiles, setSelectedFiles] = useState([]);

  const [btnText, setBtnText] = useState("Submit");

  const [uploadText, setUploadText] = useState("Uploading File(s):");

  const [isSaved, setIsSaved] = useState(false);

  const handleFileChange = (event) => {
    const newFiles = Array.from(event.target.files);
    const fileNames = newFiles.map((file) => file.name);
    setSelectedFiles(fileNames);
  };

  const handleFileDelete = (fileName) => {
    setSelectedFiles(selectedFiles.filter((file) => file !== fileName));
  };

  const handleUploadText = () => {
    setTimeout(() => {
      setUploadText("Uploaded");
    }, 2000);
  };

  const handleSubmit = () => {
    setBtnText("Uploading...");
    handleUploadText();
    setTimeout(() => {
      setBtnText("Save");
    }, 2000);
    setTimeout(() => {
      setIsSaved(true);
    }, 5000);
  };

  return !isSaved ? (
    <div className="profile-contact-input-section">
      <div
        className={`upload-container ${
          selectedFiles.length > 1 ? "has-scroll" : ""
        }`}
      >
        <div className={`upload-navbar`}>
          <div
            style={{
              fontSize: "28px",
              fontWeight: "400",
              marginLeft: "auto",
              marginRight: "auto",
              paddingLeft: "40px",
            }}
          >
            Upload
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => setAddNew(false)}
          >
            <CloseIcon />
          </div>
        </div>
        <input
          className="file-input"
          type="file"
          name="resume"
          id="resume"
          accept=".pdf"
          multiple
          onChange={handleFileChange}
        />
        <label for="resume" className="drag-drop-section">
          <CloudIcon />
          <div style={{ padding: "14px" }}>
            Drag & drop files or
            <span
              style={{
                cursor: "pointer",
                color: "#004C73",
                paddingLeft: "5px",
                textDecoration: "underline",
              }}
            >
              Browse
            </span>
          </div>
          <div style={{ paddingBottom: "14px", color: "#676767" }}>
            Supported formats: PDF
          </div>
        </label>

        {selectedFiles.length > 0 && (
          <div className="uploaded-resume-section">
            <p
              style={{
                marginBottom: "0px",
                color: "#676767",
                fontWeight: "500",
              }}
            >
              {uploadText}
            </p>
            <ul>
              {selectedFiles.map((fileName) => (
                <div
                  className={`resume-list-container ${
                    uploadText === "Uploaded" ? "green-border" : "gray-border"
                  }`}
                >
                  <li key={fileName}>{fileName}</li>
                  <div onClick={() => handleFileDelete(fileName)}>
                    <DeleteIcon />
                  </div>
                  
                </div>
              ))}
            </ul>
          </div>
        )}

        {selectedFiles.length > 0 && (
          <button
            className="resume-upload-button"
            style={{
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              width: "200px",
            }}
            onClick={handleSubmit}
          >
            {btnText}
          </button>
        )}
      </div>
    </div>
  ) : (
    <SavedResume selectedFiles={selectedFiles} handleFileDelete={handleFileDelete} />
  );
};

export default DragDrop;
