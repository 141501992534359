import React, { useState } from "react";

import "./JobDesc.css";
import ScanResume from "./ScanResume";

import { KeywordsData } from "../PA_Components/KeywordsData";
import PdfIcon from "../PA_Components/PdfIcon";
import DeleteIcon from "../PA_Components/DeleteIcon";

const JobDesc = ({ selectedResume }) => {
  const [selectedJobRole, setSelectedJobRole] = useState("");

  const [keywordsData, setKeywordsData] = useState(KeywordsData);

  const [isJobSelected, setIsJobSelected] = useState(false);

  const handleOptionClick = (id) => {
    setSelectedJobRole(id);
    setIsJobSelected(true);
  };

  const renderKeywordsGrid = () => {
    const selectedRole = keywordsData.find(
      (role) => role.id === parseInt(selectedJobRole)
    );

    if (selectedRole) {
      const words = selectedRole.words;
      const numRows = Math.ceil(words.length / 2);

      return Array.from({ length: numRows }, (_, rowIndex) => (
        <div key={rowIndex} style={{ display: "flex" }}>
          {Array.from({ length: 2 }, (_, colIndex) => {
            const wordIndex = rowIndex * 2 + colIndex;
            return (
              <div
                key={colIndex}
                style={{
                  width: "50%",
                  fontSize: "14px",
                  border: "1px solid gray",
                  borderRadius: "6px",
                  padding: "10px",
                  textAlign: "center",
                  margin: "4px",
                }}
              >
                {words[wordIndex]}
              </div>
            );
          })}
        </div>
      ));
    } else {
      return (
        <div style={{ color: "gray" }}>Select job role to view keywords</div>
      );
    }
  };

  const [isScanBtnClicked, setIsScanBtnClicked] = useState(false);

  const handleScanClick = () => {
    if (selectedJobRole) {
      setIsScanBtnClicked(!isScanBtnClicked);
    } else {
      alert("Please select a job role before scanning.");
    }
  };

  const [isLoading, setIsLoading] = useState(true);

  return (
    <div>
      <div>
        <div className={`saved-resume-list-container gray-border`}>
          
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              alignItems: "center",
            }}
          >
            {/* Pdf icon */}
            <PdfIcon />

            {/* Resume Name */}
            <li key={selectedResume}>{selectedResume}</li>
          </div>

          {/* Delete icon */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              gap: "10px",
              alignItems: "center",
            }}
          >
            <DeleteIcon />
          </div>
        </div>

        {/* Select Job Role */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              fontWeight: "bold",
              fontSize: "16px",
              paddingBottom: "10px",
            }}
          >
            Select Job Role
          </div>
          <div style={{ color: "gray" }}>
            Select a job role, and the keywords required will appear below. Just
            check that you have them all by taking a quick look at your resume.
          </div>

          {/* DropDown & Keywords Titles */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "60%",
              justifyContent: "space-between",
              margin: "20px 40px 0px 40px",
            }}
          >
            <div
              style={{
                fontWeight: "bold",
                fontSize: "16px",
                paddingBottom: "10px",
              }}
            >
              Select
            </div>
            <div
              style={{
                fontWeight: "bold",
                fontSize: "16px",
                paddingBottom: "10px",
              }}
            >
              Keywords
            </div>
          </div>

          {/* Actual DropDown & Keywords Areas */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              margin: "0 40px 0 40px", // t r b l
            }}
          >
            {/* Job Role Dropdown */}
            <div className="select-job-role">
              <select
                id="jobRole"
                name="jobRole"
                value={selectedJobRole}
                onChange={(e) => handleOptionClick(e.target.value)}
                style={{
                  width: "100%",
                  padding: "10px",
                  color: "gray",
                  fontSize: "16px",
                }}
              >
                <option value="" disabled>
                  Select job role
                </option>
                {keywordsData.map((role) => (
                  <option key={role.id} value={role.id}>
                    {role.name}
                  </option>
                ))}
              </select>
            </div>
            {/* Keywords */}
            <div className="keyword-area">
              <div style={{ display: "flex", flexDirection: "column" }}>
                {renderKeywordsGrid()}
              </div>
            </div>
          </div>

          <button
            style={{ margin: "20px", fontFamily: "sans-serif" }}
            onClick={handleScanClick}
          >
            Scan
          </button>
        </div>
      </div>
      {isScanBtnClicked && (
        <ScanResume
          setIsScanBtnClicked={setIsScanBtnClicked}
          isScanBtnClicked={isScanBtnClicked}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          keywordsData={keywordsData}
        />
      )}
    </div>
  );
};

export default JobDesc;
