import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars-2";

import "./ScheduleManagement.css";
import { useHttpClient } from "../../Components/Hooks/http-hook";
import { AuthContext } from "../../context/auth-context";

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop - 80);

const ScheduleManagement = (props) => {
  const [isStudent, setIsStudent] = useState(false);
  const [meetingList, setMeetingList] = useState([]);
  const [editedMeetingList, setEditedMeetingList] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [editMeetingId, setEditMeetingId] = useState(null);
  const myRef = useRef(null);

  const [newMeeting, setNewMeeting] = useState({
    meeting_title: "",
    description: "",
    time: "",
    duration: "",
    meeting_link: "",
    otp: "",
    live_status: false,
    is_edited: false,
  });

  let { cid, title } = useParams();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);

  const createMeetingHandler = async () => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/auth/meetings/${cid}`,
        "POST",
        JSON.stringify({
          meeting_title: newMeeting.meeting_title,
          description: newMeeting.description,
          time: newMeeting.time,
          duration: newMeeting.duration,
          meeting_link: newMeeting.meeting_link,
          otp: newMeeting.otp,
        }),
        {
          "Content-Type": "application/json",
          "Refresh-Token": auth.refresh_token,
          Authorization: auth.token,
        }
      );
      if (responseData.ok) {
        alert("Meeting Created");
        setMeetingList((prevList) => {
          let newList = [...prevList];
          newList.push({
            meeting_id: responseData.meeting_id,
            meeting_title: newMeeting.meeting_title,
            description: newMeeting.description,
            time: newMeeting.time,
            duration: newMeeting.duration,
            meeting_link: newMeeting.meeting_link,
            otp: newMeeting.otp,
            live_status: newMeeting.live_status,
          });
          return newList;
        });
        setNewMeeting({
          meeting_title: "",
          description: "",
          time: "",
          duration: "",
          meeting_link: "",
          otp: "",
          live_status: false,
        });
      } else {
        if (responseData.message) {
          alert(responseData.message);
        } else {
          alert(responseData.error);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const editMeeting = (meetingId, isEdited = false) => {
    setEditMode(true);
    setEditMeetingId(meetingId);
    let meeting;
    if (isEdited) {
      meeting = editedMeetingList.find((meeting) => meeting.id === meetingId);
    } else {
      meeting = meetingList.find((meeting) => meeting.meeting_id === meetingId);
    }
    if (!meeting) {
      return;
    }
    if (meeting.duration && meeting.duration % 1 === 0) {
      let hours = Math.floor(meeting.duration / 60);
      let minutes = meeting.duration % 60;
      meeting.duration = `${hours}:${minutes}`;
    }
    setNewMeeting({
      meeting_title: meeting.meeting_title,
      description: meeting.description,
      time: new Date(meeting.time).toISOString().slice(0, -1),
      duration: parseInt(
        meeting.duration.split(":").reduce((acc, curr) => 60 * acc + +curr) / 60
      ),
      meeting_link: meeting.meeting_link,
      otp: meeting.otp,
      live_status: meeting.live_status,
      is_edited: isEdited,
    });
    scrollToRef(myRef);
  };

  const cancelEditHandler = () => {
    setEditMode(false);
    setEditMeetingId(null);
    setNewMeeting({
      meeting_title: "",
      description: "",
      time: "",
      duration: "",
      meeting_link: "",
      otp: "",
      live_status: false,
    });
  };

  const editMeetingHandler = async () => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/auth/meetings/${editMeetingId}`,
        "PATCH",
        JSON.stringify({
          meeting_title: newMeeting.meeting_title,
          description: newMeeting.description,
          time: newMeeting.time,
          duration: newMeeting.duration,
          meeting_link: newMeeting.meeting_link,
          otp: newMeeting.otp,
          live_status: newMeeting.live_status,
          is_edited: newMeeting.is_edited,
        }),
        {
          "Content-Type": "application/json",
          "Refresh-Token": auth.refresh_token,
          Authorization: auth.token,
        }
      );
      if (responseData.ok) {
        cancelEditHandler();
        if (newMeeting.is_edited) {
          setEditedMeetingList((prevList) => {
            return prevList.map((meeting) => {
              if (meeting.id === editMeetingId) {
                return {
                  ...meeting,
                  meeting_title: newMeeting.meeting_title,
                  description: newMeeting.description,
                  time: newMeeting.time,
                  duration: newMeeting.duration,
                  meeting_link: newMeeting.meeting_link,
                  otp: newMeeting.otp,
                  live_status: newMeeting.live_status,
                };
              } else {
                return meeting;
              }
            });
          });
        } else {
          setEditedMeetingList((prevList) => {
            let newList = [...prevList];
            newList.push({
              id: responseData.id,
              meeting_title: newMeeting.meeting_title,
              description: newMeeting.description,
              time: newMeeting.time,
              duration: newMeeting.duration,
              meeting_link: newMeeting.meeting_link,
              otp: newMeeting.otp,
              live_status: newMeeting.live_status,
            });
            return newList;
          });
        }
        alert("Meeting Updated");
      } else {
        if (responseData.message) {
          alert(responseData.message);
        } else {
          alert(responseData.error);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getMeetingLink = async (meetingId) => {
    try {
      const otp = prompt("Enter OTP");
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/auth/meetings/${meetingId}`,
        "put",
        JSON.stringify({
          otp: otp,
        }),
        {
          "Content-Type": "application/json",
          "Refresh-Token": auth.refresh_token,
          Authorization: auth.token,
        }
      );
      if (responseData.ok) {
        window.open(responseData.meeting_link, "_blank");
      } else {
        if (responseData.message) {
          alert(responseData.message);
        } else {
          alert(responseData.error);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getMeetingList = async () => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/auth/meetings/${cid}`,
        "get",
        null,
        {
          "Content-Type": "application/json",
          "Refresh-Token": auth.refresh_token,
          Authorization: auth.token,
        }
      );
      if (responseData.ok) {
        return responseData;
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    if (auth.isLoggedIn) {
      setIsStudent(true);
    }
  }, [auth.isLoggedIn]);

  useEffect(() => {
    getMeetingList().then((data) => {
      if (data) {
        setMeetingList(data.meetings);
        if (data.edited_meetings) {
          setEditedMeetingList(data.edited_meetings);
        }
      }
    });
  }, [cid, meetingList && meetingList.length, editedMeetingList && editedMeetingList.length]);

  return (
    <div className="schedule-management">
      <div className="main-heading">
        {auth.role === "instructor" ? (
        <span>Master Meetings List</span>
        ) : (
        <span>Class Meetings List</span>
        )}
      </div>
      <Scrollbars
        autoHide
        autoHeight={false}
        autoHeightMin={0}
        style={{
          width: `100%`,
          height: "25rem",
          background: "white",
          borderRadius: "6px",
        }}
      >
        <div className="meeting-list">
          {meetingList && meetingList.length == 0 && (
            <h4 style={{ textAlign: "center", color: "#044c74" }}>
              No class mettings scheduled
            </h4>
          )}
          {meetingList &&
            meetingList.map((meeting, i) => {
              return (
                <div className="meeting-item" key={i}>
                  <div className="meeting-title">{meeting.meeting_title}</div>
                  <div className="meeting-time">{meeting.time}</div>
                  <div className="meeting-description">
                    {meeting.description}
                  </div>
                  <div className="meeting-otp">OTP: {meeting.otp}</div>
                  {meeting.meeting_link ? (
                    <div className="meeting-link">
                      <a href={meeting.meeting_link} target="_blank">
                        <button>Meeting Link</button>
                      </a>
                      <br />
                    </div>
                  ) : (
                    <div
                      className="join-meeting"
                      onClick={() => {
                        getMeetingLink(meeting.meeting_id);
                      }}
                    >
                      <button>Join Meeting</button>
                    </div>
                  )}
                  {auth.role === "instructor" && (
                    <div
                      className="edit-meeting"
                      onClick={() => {
                        editMeeting(meeting.meeting_id);
                      }}
                    >
                      <button>Edit</button>
                    </div>
                  )}
                </div>
              );
            })}
        </div>
      </Scrollbars>
      <br />
      {auth.role === "instructor" && (
        <>
          <div className="main-heading">
            <span>Class Meeting List</span>
          </div>

          <div className="meeting-list">
            <Scrollbars
              autoHide
              autoHideTimeout={2000}
              autoHideDuration={310}
              autoHeightMin={300}
              autoHeightMax={800}
              thumbMinSize={30}
              universal={true}
              style={{
                width: `100%`,
                height: "25rem",
                background: "white",
                borderRadius: "6px",
              }}
            >
              <table className="module-table" cellSpacing={"0px"}>
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Meeting Title</th>
                    <th>Meeting Time</th>
                    <th>Meeting OTP</th>
                    <th>Meeting Status</th>
                    <th>Meeting Link</th>
                    <th>{auth.role === "instructor" && <p>Edit Meeting</p>}</th>
                  </tr>
                </thead>
                <tbody>
                  {editedMeetingList &&
                    editedMeetingList.map((meeting, i) => {
                      return (
                        <tr key={meeting.id}>
                          <td>{i + 1}</td>
                          <td>{meeting.meeting_title}</td>
                          <td>{meeting.time}</td>
                          <td>{meeting.otp}</td>
                          <td>
                            {meeting.live_status ? (
                              <span className="live">Live</span>
                            ) : (
                              <span className="not-live">Not Live</span>
                            )}
                          </td>
                          <td>
                            <a href={meeting.meeting_link} target="_blank">
                              <button>Link</button>
                            </a>
                          </td>
                          <td>
                            {auth.role === "instructor" && (
                              <div
                                className="edit-meeting"
                                onClick={() => {
                                  editMeeting(meeting.id, true);
                                }}
                              >
                                <button>Edit</button>
                              </div>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
              {editedMeetingList && editedMeetingList.length == 0 && (
                <h4 style={{ textAlign: "center", color: "#044c74" }}>
                  No meetings
                </h4>
              )}
            </Scrollbars>
          </div>
          <br />
          <div className="create-meeting">
            <div className="main-heading" ref={myRef}>
              {editMode ? (
                <span>Edit Master meeting to create a new class meeting</span>
              ) : (
                <span>Create New Master Meeting</span>
              )}
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                if (editMode) {
                  editMeetingHandler();
                } else {
                  createMeetingHandler();
                }
              }}
            >
              <div className="form-group">
                <label htmlFor="meeting_title">Meeting Title</label>
                <input
                  type="text"
                  name="meeting_title"
                  id="meeting_title"
                  value={newMeeting.meeting_title}
                  required
                  onChange={(e) => {
                    setNewMeeting({
                      ...newMeeting,
                      meeting_title: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="form-group">
                <label htmlFor="description">Description</label>
                <input
                  type="text"
                  name="description"
                  id="description"
                  required
                  value={newMeeting.description}
                  onChange={(e) => {
                    setNewMeeting({
                      ...newMeeting,
                      description: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="form-group">
                <label htmlFor="time">Time</label>
                <input
                  type="datetime-local"
                  name="time"
                  id="time"
                  defaultValue={newMeeting.time}
                  required
                  onChange={(e) => {
                    setNewMeeting({
                      ...newMeeting,
                      time: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="form-group">
                <label htmlFor="duration">Duration</label>
                <input
                  type="number"
                  name="duration"
                  id="duration"
                  defaultValue={newMeeting.duration}
                  required
                  onChange={(e) => {
                    setNewMeeting({
                      ...newMeeting,
                      duration: e.target.value,
                    });
                  }}
                />
              </div>
              {/*meetinglink, otp */}
              <div className="form-group">
                <label htmlFor="meeting_link">Meeting Link</label>
                <input
                  type="text"
                  name="meeting_link"
                  id="meeting_link"
                  value={newMeeting.meeting_link}
                  required
                  onChange={(e) => {
                    setNewMeeting({
                      ...newMeeting,
                      meeting_link: e.target.value,
                    });
                  }}
                />
              </div>

              <div className="form-group">
                <label htmlFor="otp">OTP</label>
                <input
                  type="tel"
                  name="otp"
                  id="otp"
                  value={newMeeting.otp}
                  required
                  onChange={(e) => {
                    setNewMeeting({
                      ...newMeeting,
                      otp: e.target.value,
                    });
                  }}
                />
              </div>

              <div className="form-group">
                <label htmlFor="live_status">Status</label>
                <select
                  name="live_status"
                  id="live_status"
                  value={newMeeting.live_status}
                  required
                  onChange={(e) => {
                    setNewMeeting({
                      ...newMeeting,
                      live_status: e.target.value,
                    });
                  }}
                >
                  <option value={true}>Live</option>
                  <option value={false}>Not Live</option>
                </select>
              </div>

              <div className="form-group">
                {editMode ? (
                  <>
                    <button type="submit">Update Meeting</button>
                    <button type="button" onClick={cancelEditHandler}>
                      Cancel
                    </button>
                  </>
                ) : (
                  <button type="submit">Create Meeting</button>
                )}
              </div>
            </form>
          </div>
        </>
      )}
    </div>
  );
};

export default ScheduleManagement;
