import React from 'react';
import './Error404.css';

const Error404 = (props) => {
  console.log(props)
  return (
    <React.Fragment>
        <div className="Error404">
            <h3>The following error occured</h3>
            <h3>{props.msg}</h3>
        </div>
    </React.Fragment>
  );
};

export default Error404;