import React, { useEffect } from "react";
import PopUp from "../UiComponents/Popup/PopUp";
import "./EditCourse.css";
import { useState, useContext } from "react";
import { AdminContext } from "../../../../context/admin-context";
import { useHttpClient } from "../../../../Components/Hooks/http-hook";
import LoadingSpinner from "../../../../Components/Input/LoadingSpinner";
const swal = require("sweetalert2");

const EditCourse = ({ course, showPopup, setShowPopup, onEdit }) => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [buttonLoading, setButtonLoading] = useState(false);
  const admin = useContext(AdminContext);

  const initialCourseState = {
    course_name: course.course_name,
    course_description: course.course_description,
    active_tag: course.active_tag,
  };

  const [courseData, setCourseData] = useState(initialCourseState);

  const [validationErrors, setValidationErrors] = useState({
    course_name: false,
    course_description: false,
  });

  useEffect(() => {
    setCourseData(initialCourseState);
  }, [course]);

  const resetForm = () => {
    setCourseData(initialCourseState);
    setValidationErrors({
      course_name: false,
      course_description: false,
    });
  };

  const handlechange = (e) => {
    const { name, value } = e.target;

    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: false,
    }));

    setCourseData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const validateForm = () => {
    const requiredFields = [
      "course_name",
      "course_description",
    ];
    const newValidationErrors = {};

    requiredFields.forEach((field) => {
      if (!courseData[field]) {
        newValidationErrors[field] = true;
      }
    }
    );

    setValidationErrors((prev) => ({
      ...prev,
      ...newValidationErrors,
    }));

    return Object.keys(newValidationErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      setButtonLoading(false);
      return;
    }
    setButtonLoading(true);
    try {
      const response = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/db/course/${course.course_id}`,
        "PUT",
        JSON.stringify(courseData),
        {
          "Content-Type": "application/json",
          "Refresh-Token": admin.refresh_token,
          Authorization: admin.token,
        }
      );
      if (response) {
        setButtonLoading(false);
        setShowPopup(false);
        onEdit({
          ...course,
          ...courseData
         } );
        swal.fire({
          title: "Success",
          text: "Course Updated Successfully",
          icon: "success",
          confirmButtonText: "Ok",
        });
      }
    } catch (err) {
      setButtonLoading(false);
      setShowPopup(false);
      swal.fire({
        title: "Error Occured",
        text: err.message,
        icon: "error",
        confirmButtonText: "Ok",
      });
    }
  };

  useEffect(() => {
    if (error) {
      setButtonLoading(false);
      setShowPopup(false);
      swal.fire({
        title: "Error!",
        text: error || "Something went wrong, please try again later.",
        icon: "error",
        confirmButtonText: "Close",
      });
      clearError();
    }
  }
  , [error, clearError, setShowPopup]);

  return (
    <PopUp
      title={course.course_name}
      onClose={() => {
        resetForm();
        setShowPopup(false);
      }}
      show={showPopup}
    >
      {buttonLoading ? (
        <div className="edit_course_popup__loading">
            <LoadingSpinner />
        </div>
      ) : (
      <form onSubmit={handleSubmit}>
      <div className="edit_course_popup__content">
        <div className="edit_course_popup__content-row">
          <div className="edit_course_popup__content-row-title">
            <p>Course ID</p>
          </div>
          <div className="edit_course_popup__content-row-value">
            <p>{course.course_id}</p>
          </div>
        </div>
        <div className="edit_course_popup__content-row">
          <div className="edit_course_popup__content-row-title">
            <p>Course Name</p>
          </div>
          <div className="edit_course_popup__content-row-value">
            <input
              type="text"
              defaultValue={course.course_name}
              onChange={handlechange}
              name="course_name"
              className={validationErrors.course_name ? "input_val_error" : ""}
            />
            {validationErrors.course_name && (
              <small className="input_val_error-text">
                Course Name is required
              </small>
            )}
          </div>
        </div>
        <div className="edit_course_popup__content-row">
          <div className="edit_course_popup__content-row-title">
            <p>Course Description</p>
          </div>
          <div className="edit_course_popup__content-row-value">
            <input
              type="text"
              defaultValue={
                (course.course_description && course.course_description) || ""
              }
              onChange={handlechange}
              name="course_description"
              className={
                validationErrors.course_description ? "input_val_error" : ""
              }
            />
            {validationErrors.course_description && (
              <small className="input_val_error-text">
                Course Description is required
              </small>
            )}
          </div>
        </div>
        <div className="edit_course_popup__content-row">
          <div className="edit_course_popup__content-row-title">
            <p>Is Active</p>
          </div>
          <div className="edit_course_popup__content-row-value">
            <select
              onChange={handlechange}
              name="active_tag"
              defaultValue={(course.active_tag && course.active_tag) || "true"}
            >
              <option value="true">Live</option>
              <option value="false">Not Live</option>
            </select>
          </div>
        </div>
        <div className="edit_course_popup__content-row">
          <div className="edit_course_popup__content-row-title">
            <p>Start Date</p>
          </div>
          <div className="edit_course_popup__content-row-value">
            <p>{course.course_start_date}</p>
          </div>
        </div>
        <div className="edit_course_popup__content-row">
          <div className="edit_course_popup__content-row-title">
            <p>Created On</p>
          </div>
          <div className="edit_course_popup__content-row-value">
            <p>{course.course_create_timestamp}</p>
          </div>
        </div>
        <div className="edit_course_popup__content-row">
          <div className="edit_course_popup__content-row-title">
            <p>Last Updated</p>
          </div>
          <div className="edit_course_popup__content-row-value">
            <p>{course.course_update_timestamp}</p>
          </div>
        </div>
      </div>
      <div className="edit_course_popup__buttons">
        <button
          className="edit_course_popup__buttons-cancel"
          type="button"
          onClick={() => {
            resetForm();
            setShowPopup(false);
          }}
        >
          Cancel
        </button>
        <button
          className="edit_course_popup__buttons-save"
          type="submit"
        >
          Save
        </button>
      </div>
      </form>
      )}
    </PopUp>
  );
};

export default EditCourse;
