import React from 'react'
import {useEffect, useState, useContext } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import {  AiFillEye } from "react-icons/ai"
import { MdExpandMore, MdDoubleArrow } from "react-icons/md";
import { Scrollbars } from "react-custom-scrollbars-2";

import './AdminCampusProgrammeMse.css'
import { useHttpClient } from '../../Components/Hooks/http-hook';
import { AdminContext } from '../../context/admin-context';

const AdminCampusProgrammeMse = () => {
  let { cid, bid, mse } = useParams();
  const [mseElementsInfo, setMseElementsInfo] = useState({})
  const [selectedMseElement, setSelectedMseElement] = useState("")
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const admin = useContext(AdminContext);
  const getMseElementInfo = async() => {
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/s3/mse-elements-submissions/${bid}/${mse}`,
        "GET"
      );
      if (responseData.ok) {
        console.log(responseData.data)
        return responseData.data
      }
    } catch (err) {
      console.log("got error while getting programmes", err)
    }
  };

  useEffect(() => {
    getMseElementInfo().then(
      response => setMseElementsInfo(response)
    )   
  }, [])

  const selectMseElement = (element) => {
    setSelectedMseElement(element)
  }

  return (
    <div className='mse-submissions-admin-page'>
        <div class="dropdown dropdown-modules">
            <button class="dropbtn dropbtn-modules">MSE Elements<MdExpandMore style={{fontSize: "1.5rem", float: "right"}}></MdExpandMore></button>
            <div class="mse-dropdown-content">
                {mseElementsInfo && Object.entries(mseElementsInfo).map(([key, value]) => {
                return(
                    <a onClick={()=>selectMseElement(key)}>{key.slice(10,)}</a>
                    )
                })} 
            </div>
        </div>
        <Scrollbars
        autoHide
        autoHideTimeofut={1000}
        autoHideDuration={310}
        autoHeightMin={300}
        autoHeightMax={800}
        thumbMinSize={30}
        universal={true}
        style={{
        width: `100%`,
        height: "30rem",
        background: "#f5f5f0",
        borderRadius: "6px",
        }}
        >
          <div className='imgs-container'>
              {selectedMseElement && Object.entries(mseElementsInfo[selectedMseElement]).map((item, value) => {
                console.log(item)
                return(
                    <div className='indv-img'>
                        <p>{item[1].filename}</p>
                        <img style={{width:"95%",height:"250px"}} key={item[1].filename} src={item[1].base64_str}/>
                    </div>
                )
              })}                              
          </div>   
        </Scrollbars>
    </div>
  )
}

export default AdminCampusProgrammeMse