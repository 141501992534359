import React from "react";

import "./MatchingScore.css";
import ProgressBar from "./ProgressBar";

const MatchingScore = ({ matchingData }) => {
  return (
    <div className="match-score-section">
      <div className="match-title">Matching Score :</div>

      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}
      >
        <ProgressBar progress={60} />
      </div>

      <ul className="match-list-container">
        {matchingData.map((data) => (
          <div className="match-list" key={data.id}>
            <div className="match-title" style={{ fontSize: "16px" }}>
              {data.name}
            </div>
            <hr />
            <div className="match-list-item">
              {data.value.map((item, index) => (
                <p
                  className="match-list-item-value"
                  style={{ width: data.width }}
                  key={index}
                >
                  {item}
                </p>
              ))}
            </div>
          </div>
        ))}
      </ul>
    </div>
  );
};

export default MatchingScore;
