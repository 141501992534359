import React, { useState, useEffect, useRef } from "react";
import "./AptitudeTest.css";
import { useParams } from "react-router-dom";
import MathJax from "react-mathjax";
import $ from "jquery";

const AptitudeTest = () => {
  const [showAnswer, setShowAnswer] = useState(null);
  const [stateBool, setStateBool] = useState(false);
  const [currPageNumber, setCurrPageNumber] = useState(0);
  const [pageArray, setPageArray] = useState([]);
  const [answerValue, setAnswerValue] = useState(null);
  const [maxMark, setmaxMark] = useState(0);
  const [currentMark, setcurrentMark] = useState(0);
  const [clickedOption, setClickedOption] = useState(null);
  const [selectedQues, setSelectedQues] = useState(null);
  const [isAnsSubmitted, setIsAnsSubmitted] = useState(false);
  const OmrSheet = [];

  const TopicList = [
    {
      name: "Aptitude",
      no_of_ques: 36,
      questions: [
        {
          ques_no: 1,
          is_ques_text_mathematical: false,
          quesText:
            "A can do a work in 15 days and B in 20 days. If they work on it together for 4 days, then the fraction of the work that is left is :",
          is_options_mathematical: true,
          options: [
            {
              option_No: "A",
              val: "\\frac{1}{4}",
            },
            {
              option_No: "B",
              val: "\\frac{1}{10}",
            },
            {
              option_No: "C",
              val: "\\frac{7}{15}",
            },
            {
              option_No: "D",
              val: "\\frac{8}{15}",
            },
          ],
          correct_option: "D",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 2,
          is_ques_text_mathematical: false,
          quesText:
            "A can lay railway track between two given stations in 16 days and B can do the same job in 12 days. With help of C, they did the job in 4 days only. Then, C alone can do the job in:",
          is_options_mathematical: true,
          options: [
            {
              option_No: "A",
              val: "{9}\\frac{1}{5}",
            },
            {
              option_No: "B",
              val: "{9}\\frac{2}{5}",
            },
            {
              option_No: "C",
              val: "{9}\\frac{3}{5}",
            },
            {
              option_No: "D",
              val: "10",
            },
          ],
          correct_option: "C",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 3,
          is_ques_text_mathematical: false,
          quesText:
            "A, B and C can do a piece of work in 20, 30 and 60 days respectively. In how many days can A do the work if he is assisted by B and C on every third day?",
          is_options_mathematical: false,
          options: [
            {
              option_No: "A",
              val: "12 days",
            },
            {
              option_No: "B",
              val: "15 days",
            },
            {
              option_No: "C",
              val: "16 days",
            },
            {
              option_No: "D",
              val: "18 days",
            },
          ],
          correct_option: "B",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 4,
          is_ques_text_mathematical: false,
          quesText:
            "A is thrice as good as workman as B and therefore is able to finish a job in 60 days less than B. Working together, they can do it in:",
          is_options_mathematical: false,
          options: [
            {
              option_No: "A",
              val: "20 days",
            },
            {
              option_No: "B",
              val: "22.5 days",
            },
            {
              option_No: "C",
              val: "25 days",
            },
            {
              option_No: "D",
              val: "30 days",
            },
          ],
          correct_option: "B",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 5,
          is_ques_text_mathematical: false,
          quesText:
            "A can do a work in 15 days and B in 20 days. If they work on it together for 4 days, then the fraction of the work that is left is :",
          is_options_mathematical: true,
          options: [
            {
              option_No: "A",
              val: "\\frac{1}{4}",
            },
            {
              option_No: "B",
              val: "\\frac{1}{10}",
            },
            {
              option_No: "C",
              val: "\\frac{7}{15}",
            },
            {
              option_No: "D",
              val: "\\frac{8}{15}",
            },
          ],
          correct_option: "D",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 6,
          is_ques_text_mathematical: false,
          quesText:
            "A can lay railway track between two given stations in 16 days and B can do the same job in 12 days. With help of C, they did the job in 4 days only. Then, C alone can do the job in:",
          is_options_mathematical: true,
          options: [
            {
              option_No: "A",
              val: "{9}\\frac{1}{5}",
            },
            {
              option_No: "B",
              val: "{9}\\frac{2}{5}",
            },
            {
              option_No: "C",
              val: "{9}\\frac{3}{5}",
            },
            {
              option_No: "D",
              val: "10",
            },
          ],
          correct_option: "C",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 7,
          is_ques_text_mathematical: false,
          quesText:
            "A, B and C can do a piece of work in 20, 30 and 60 days respectively. In how many days can A do the work if he is assisted by B and C on every third day?",
          is_options_mathematical: false,
          options: [
            {
              option_No: "A",
              val: "12 days",
            },
            {
              option_No: "B",
              val: "15 days",
            },
            {
              option_No: "C",
              val: "16 days",
            },
            {
              option_No: "D",
              val: "18 days",
            },
          ],
          correct_option: "B",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 8,
          is_ques_text_mathematical: false,
          quesText:
            "A is thrice as good as workman as B and therefore is able to finish a job in 60 days less than B. Working together, they can do it in:",
          is_options_mathematical: false,
          options: [
            {
              option_No: "A",
              val: "20 days",
            },
            {
              option_No: "B",
              val: "22.5 days",
            },
            {
              option_No: "C",
              val: "25 days",
            },
            {
              option_No: "D",
              val: "30 days",
            },
          ],
          correct_option: "B",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 9,
          is_ques_text_mathematical: false,
          quesText:
            "A can do a work in 15 days and B in 20 days. If they work on it together for 4 days, then the fraction of the work that is left is :",
          is_options_mathematical: true,
          options: [
            {
              option_No: "A",
              val: "\\frac{1}{4}",
            },
            {
              option_No: "B",
              val: "\\frac{1}{10}",
            },
            {
              option_No: "C",
              val: "\\frac{7}{15}",
            },
            {
              option_No: "D",
              val: "\\frac{8}{15}",
            },
          ],
          correct_option: "D",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 10,
          is_ques_text_mathematical: false,
          quesText:
            "A can lay railway track between two given stations in 16 days and B can do the same job in 12 days. With help of C, they did the job in 4 days only. Then, C alone can do the job in:",
          is_options_mathematical: true,
          options: [
            {
              option_No: "A",
              val: "{9}\\frac{1}{5}",
            },
            {
              option_No: "B",
              val: "{9}\\frac{2}{5}",
            },
            {
              option_No: "C",
              val: "{9}\\frac{3}{5}",
            },
            {
              option_No: "D",
              val: "10",
            },
          ],
          correct_option: "C",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 11,
          is_ques_text_mathematical: false,
          quesText:
            "A, B and C can do a piece of work in 20, 30 and 60 days respectively. In how many days can A do the work if he is assisted by B and C on every third day?",
          is_options_mathematical: false,
          options: [
            {
              option_No: "A",
              val: "12 days",
            },
            {
              option_No: "B",
              val: "15 days",
            },
            {
              option_No: "C",
              val: "16 days",
            },
            {
              option_No: "D",
              val: "18 days",
            },
          ],
          correct_option: "B",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 12,
          is_ques_text_mathematical: false,
          quesText:
            "A is thrice as good as workman as B and therefore is able to finish a job in 60 days less than B. Working together, they can do it in:",
          is_options_mathematical: false,
          options: [
            {
              option_No: "A",
              val: "20 days",
            },
            {
              option_No: "B",
              val: "22.5 days",
            },
            {
              option_No: "C",
              val: "25 days",
            },
            {
              option_No: "D",
              val: "30 days",
            },
          ],
          correct_option: "B",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 13,
          is_ques_text_mathematical: false,
          quesText:
            "A can do a work in 15 days and B in 20 days. If they work on it together for 4 days, then the fraction of the work that is left is :",
          is_options_mathematical: true,
          options: [
            {
              option_No: "A",
              val: "\\frac{1}{4}",
            },
            {
              option_No: "B",
              val: "\\frac{1}{10}",
            },
            {
              option_No: "C",
              val: "\\frac{7}{15}",
            },
            {
              option_No: "D",
              val: "\\frac{8}{15}",
            },
          ],
          correct_option: "D",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 14,
          is_ques_text_mathematical: false,
          quesText:
            "A can lay railway track between two given stations in 16 days and B can do the same job in 12 days. With help of C, they did the job in 4 days only. Then, C alone can do the job in:",
          is_options_mathematical: true,
          options: [
            {
              option_No: "A",
              val: "{9}\\frac{1}{5}",
            },
            {
              option_No: "B",
              val: "{9}\\frac{2}{5}",
            },
            {
              option_No: "C",
              val: "{9}\\frac{3}{5}",
            },
            {
              option_No: "D",
              val: "10",
            },
          ],
          correct_option: "C",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 15,
          is_ques_text_mathematical: false,
          quesText:
            "A, B and C can do a piece of work in 20, 30 and 60 days respectively. In how many days can A do the work if he is assisted by B and C on every third day?",
          is_options_mathematical: false,
          options: [
            {
              option_No: "A",
              val: "12 days",
            },
            {
              option_No: "B",
              val: "15 days",
            },
            {
              option_No: "C",
              val: "16 days",
            },
            {
              option_No: "D",
              val: "18 days",
            },
          ],
          correct_option: "B",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 16,
          is_ques_text_mathematical: false,
          quesText:
            "A is thrice as good as workman as B and therefore is able to finish a job in 60 days less than B. Working together, they can do it in:",
          is_options_mathematical: false,
          options: [
            {
              option_No: "A",
              val: "20 days",
            },
            {
              option_No: "B",
              val: "22.5 days",
            },
            {
              option_No: "C",
              val: "25 days",
            },
            {
              option_No: "D",
              val: "30 days",
            },
          ],
          correct_option: "B",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 17,
          is_ques_text_mathematical: false,
          quesText:
            "A can do a work in 15 days and B in 20 days. If they work on it together for 4 days, then the fraction of the work that is left is :",
          is_options_mathematical: true,
          options: [
            {
              option_No: "A",
              val: "\\frac{1}{4}",
            },
            {
              option_No: "B",
              val: "\\frac{1}{10}",
            },
            {
              option_No: "C",
              val: "\\frac{7}{15}",
            },
            {
              option_No: "D",
              val: "\\frac{8}{15}",
            },
          ],
          correct_option: "D",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 18,
          is_ques_text_mathematical: false,
          quesText:
            "A can lay railway track between two given stations in 16 days and B can do the same job in 12 days. With help of C, they did the job in 4 days only. Then, C alone can do the job in:",
          is_options_mathematical: true,
          options: [
            {
              option_No: "A",
              val: "{9}\\frac{1}{5}",
            },
            {
              option_No: "B",
              val: "{9}\\frac{2}{5}",
            },
            {
              option_No: "C",
              val: "{9}\\frac{3}{5}",
            },
            {
              option_No: "D",
              val: "10",
            },
          ],
          correct_option: "C",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 19,
          is_ques_text_mathematical: false,
          quesText:
            "A, B and C can do a piece of work in 20, 30 and 60 days respectively. In how many days can A do the work if he is assisted by B and C on every third day?",
          is_options_mathematical: false,
          options: [
            {
              option_No: "A",
              val: "12 days",
            },
            {
              option_No: "B",
              val: "15 days",
            },
            {
              option_No: "C",
              val: "16 days",
            },
            {
              option_No: "D",
              val: "18 days",
            },
          ],
          correct_option: "B",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 20,
          is_ques_text_mathematical: false,
          quesText:
            "A is thrice as good as workman as B and therefore is able to finish a job in 60 days less than B. Working together, they can do it in:",
          is_options_mathematical: false,
          options: [
            {
              option_No: "A",
              val: "20 days",
            },
            {
              option_No: "B",
              val: "22.5 days",
            },
            {
              option_No: "C",
              val: "25 days",
            },
            {
              option_No: "D",
              val: "30 days",
            },
          ],
          correct_option: "B",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 21,
          is_ques_text_mathematical: false,
          quesText:
            "A can do a work in 15 days and B in 20 days. If they work on it together for 4 days, then the fraction of the work that is left is :",
          is_options_mathematical: true,
          options: [
            {
              option_No: "A",
              val: "\\frac{1}{4}",
            },
            {
              option_No: "B",
              val: "\\frac{1}{10}",
            },
            {
              option_No: "C",
              val: "\\frac{7}{15}",
            },
            {
              option_No: "D",
              val: "\\frac{8}{15}",
            },
          ],
          correct_option: "D",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 22,
          is_ques_text_mathematical: false,
          quesText:
            "A can lay railway track between two given stations in 16 days and B can do the same job in 12 days. With help of C, they did the job in 4 days only. Then, C alone can do the job in:",
          is_options_mathematical: true,
          options: [
            {
              option_No: "A",
              val: "{9}\\frac{1}{5}",
            },
            {
              option_No: "B",
              val: "{9}\\frac{2}{5}",
            },
            {
              option_No: "C",
              val: "{9}\\frac{3}{5}",
            },
            {
              option_No: "D",
              val: "10",
            },
          ],
          correct_option: "C",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 23,
          is_ques_text_mathematical: false,
          quesText:
            "A, B and C can do a piece of work in 20, 30 and 60 days respectively. In how many days can A do the work if he is assisted by B and C on every third day?",
          is_options_mathematical: false,
          options: [
            {
              option_No: "A",
              val: "12 days",
            },
            {
              option_No: "B",
              val: "15 days",
            },
            {
              option_No: "C",
              val: "16 days",
            },
            {
              option_No: "D",
              val: "18 days",
            },
          ],
          correct_option: "B",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 24,
          is_ques_text_mathematical: false,
          quesText:
            "A is thrice as good as workman as B and therefore is able to finish a job in 60 days less than B. Working together, they can do it in:",
          is_options_mathematical: false,
          options: [
            {
              option_No: "A",
              val: "20 days",
            },
            {
              option_No: "B",
              val: "22.5 days",
            },
            {
              option_No: "C",
              val: "25 days",
            },
            {
              option_No: "D",
              val: "30 days",
            },
          ],
          correct_option: "B",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 25,
          is_ques_text_mathematical: false,
          quesText:
            "A can do a work in 15 days and B in 20 days. If they work on it together for 4 days, then the fraction of the work that is left is :",
          is_options_mathematical: true,
          options: [
            {
              option_No: "A",
              val: "\\frac{1}{4}",
            },
            {
              option_No: "B",
              val: "\\frac{1}{10}",
            },
            {
              option_No: "C",
              val: "\\frac{7}{15}",
            },
            {
              option_No: "D",
              val: "\\frac{8}{15}",
            },
          ],
          correct_option: "D",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 26,
          is_ques_text_mathematical: false,
          quesText:
            "A can lay railway track between two given stations in 16 days and B can do the same job in 12 days. With help of C, they did the job in 4 days only. Then, C alone can do the job in:",
          is_options_mathematical: true,
          options: [
            {
              option_No: "A",
              val: "{9}\\frac{1}{5}",
            },
            {
              option_No: "B",
              val: "{9}\\frac{2}{5}",
            },
            {
              option_No: "C",
              val: "{9}\\frac{3}{5}",
            },
            {
              option_No: "D",
              val: "10",
            },
          ],
          correct_option: "C",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 27,
          is_ques_text_mathematical: false,
          quesText:
            "A, B and C can do a piece of work in 20, 30 and 60 days respectively. In how many days can A do the work if he is assisted by B and C on every third day?",
          is_options_mathematical: false,
          options: [
            {
              option_No: "A",
              val: "12 days",
            },
            {
              option_No: "B",
              val: "15 days",
            },
            {
              option_No: "C",
              val: "16 days",
            },
            {
              option_No: "D",
              val: "18 days",
            },
          ],
          correct_option: "B",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 28,
          is_ques_text_mathematical: false,
          quesText:
            "A is thrice as good as workman as B and therefore is able to finish a job in 60 days less than B. Working together, they can do it in:",
          is_options_mathematical: false,
          options: [
            {
              option_No: "A",
              val: "20 days",
            },
            {
              option_No: "B",
              val: "22.5 days",
            },
            {
              option_No: "C",
              val: "25 days",
            },
            {
              option_No: "D",
              val: "30 days",
            },
          ],
          correct_option: "B",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 29,
          is_ques_text_mathematical: false,
          quesText:
            "A can do a work in 15 days and B in 20 days. If they work on it together for 4 days, then the fraction of the work that is left is :",
          is_options_mathematical: true,
          options: [
            {
              option_No: "A",
              val: "\\frac{1}{4}",
            },
            {
              option_No: "B",
              val: "\\frac{1}{10}",
            },
            {
              option_No: "C",
              val: "\\frac{7}{15}",
            },
            {
              option_No: "D",
              val: "\\frac{8}{15}",
            },
          ],
          correct_option: "D",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 30,
          is_ques_text_mathematical: false,
          quesText:
            "A can lay railway track between two given stations in 16 days and B can do the same job in 12 days. With help of C, they did the job in 4 days only. Then, C alone can do the job in:",
          is_options_mathematical: true,
          options: [
            {
              option_No: "A",
              val: "{9}\\frac{1}{5}",
            },
            {
              option_No: "B",
              val: "{9}\\frac{2}{5}",
            },
            {
              option_No: "C",
              val: "{9}\\frac{3}{5}",
            },
            {
              option_No: "D",
              val: "10",
            },
          ],
          correct_option: "C",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 31,
          is_ques_text_mathematical: false,
          quesText:
            "A, B and C can do a piece of work in 20, 30 and 60 days respectively. In how many days can A do the work if he is assisted by B and C on every third day?",
          is_options_mathematical: false,
          options: [
            {
              option_No: "A",
              val: "12 days",
            },
            {
              option_No: "B",
              val: "15 days",
            },
            {
              option_No: "C",
              val: "16 days",
            },
            {
              option_No: "D",
              val: "18 days",
            },
          ],
          correct_option: "B",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 32,
          is_ques_text_mathematical: false,
          quesText:
            "A is thrice as good as workman as B and therefore is able to finish a job in 60 days less than B. Working together, they can do it in:",
          is_options_mathematical: false,
          options: [
            {
              option_No: "A",
              val: "20 days",
            },
            {
              option_No: "B",
              val: "22.5 days",
            },
            {
              option_No: "C",
              val: "25 days",
            },
            {
              option_No: "D",
              val: "30 days",
            },
          ],
          correct_option: "B",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 33,
          is_ques_text_mathematical: false,
          quesText:
            "A can do a work in 15 days and B in 20 days. If they work on it together for 4 days, then the fraction of the work that is left is :",
          is_options_mathematical: true,
          options: [
            {
              option_No: "A",
              val: "\\frac{1}{4}",
            },
            {
              option_No: "B",
              val: "\\frac{1}{10}",
            },
            {
              option_No: "C",
              val: "\\frac{7}{15}",
            },
            {
              option_No: "D",
              val: "\\frac{8}{15}",
            },
          ],
          correct_option: "D",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 34,
          is_ques_text_mathematical: false,
          quesText:
            "A can lay railway track between two given stations in 16 days and B can do the same job in 12 days. With help of C, they did the job in 4 days only. Then, C alone can do the job in:",
          is_options_mathematical: true,
          options: [
            {
              option_No: "A",
              val: "{9}\\frac{1}{5}",
            },
            {
              option_No: "B",
              val: "{9}\\frac{2}{5}",
            },
            {
              option_No: "C",
              val: "{9}\\frac{3}{5}",
            },
            {
              option_No: "D",
              val: "10",
            },
          ],
          correct_option: "C",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 35,
          is_ques_text_mathematical: false,
          quesText:
            "A, B and C can do a piece of work in 20, 30 and 60 days respectively. In how many days can A do the work if he is assisted by B and C on every third day?",
          is_options_mathematical: false,
          options: [
            {
              option_No: "A",
              val: "12 days",
            },
            {
              option_No: "B",
              val: "15 days",
            },
            {
              option_No: "C",
              val: "16 days",
            },
            {
              option_No: "D",
              val: "18 days",
            },
          ],
          correct_option: "B",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 36,
          is_ques_text_mathematical: false,
          quesText:
            "A is thrice as good as workman as B and therefore is able to finish a job in 60 days less than B. Working together, they can do it in:",
          is_options_mathematical: false,
          options: [
            {
              option_No: "A",
              val: "20 days",
            },
            {
              option_No: "B",
              val: "22.5 days",
            },
            {
              option_No: "C",
              val: "25 days",
            },
            {
              option_No: "D",
              val: "30 days",
            },
          ],
          correct_option: "B",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
      ],
    },
    {
      name: "Verbal Ability",
      no_of_ques: 36,
      questions: [
        {
          ques_no: 1,
          is_ques_text_mathematical: false,
          quesText:
            "A can do a work in 15 days and B in 20 days. If they work on it together for 4 days, then the fraction of the work that is left is :",
          is_options_mathematical: true,
          options: [
            {
              option_No: "A",
              val: "\\frac{1}{4}",
            },
            {
              option_No: "B",
              val: "\\frac{1}{10}",
            },
            {
              option_No: "C",
              val: "\\frac{7}{15}",
            },
            {
              option_No: "D",
              val: "\\frac{8}{15}",
            },
          ],
          correct_option: "D",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 2,
          is_ques_text_mathematical: false,
          quesText:
            "A can lay railway track between two given stations in 16 days and B can do the same job in 12 days. With help of C, they did the job in 4 days only. Then, C alone can do the job in:",
          is_options_mathematical: true,
          options: [
            {
              option_No: "A",
              val: "{9}\\frac{1}{5}",
            },
            {
              option_No: "B",
              val: "{9}\\frac{2}{5}",
            },
            {
              option_No: "C",
              val: "{9}\\frac{3}{5}",
            },
            {
              option_No: "D",
              val: "10",
            },
          ],
          correct_option: "C",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 3,
          is_ques_text_mathematical: false,
          quesText:
            "A, B and C can do a piece of work in 20, 30 and 60 days respectively. In how many days can A do the work if he is assisted by B and C on every third day?",
          is_options_mathematical: false,
          options: [
            {
              option_No: "A",
              val: "12 days",
            },
            {
              option_No: "B",
              val: "15 days",
            },
            {
              option_No: "C",
              val: "16 days",
            },
            {
              option_No: "D",
              val: "18 days",
            },
          ],
          correct_option: "B",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 4,
          is_ques_text_mathematical: false,
          quesText:
            "A is thrice as good as workman as B and therefore is able to finish a job in 60 days less than B. Working together, they can do it in:",
          is_options_mathematical: false,
          options: [
            {
              option_No: "A",
              val: "20 days",
            },
            {
              option_No: "B",
              val: "22.5 days",
            },
            {
              option_No: "C",
              val: "25 days",
            },
            {
              option_No: "D",
              val: "30 days",
            },
          ],
          correct_option: "B",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 5,
          is_ques_text_mathematical: false,
          quesText:
            "A can do a work in 15 days and B in 20 days. If they work on it together for 4 days, then the fraction of the work that is left is :",
          is_options_mathematical: true,
          options: [
            {
              option_No: "A",
              val: "\\frac{1}{4}",
            },
            {
              option_No: "B",
              val: "\\frac{1}{10}",
            },
            {
              option_No: "C",
              val: "\\frac{7}{15}",
            },
            {
              option_No: "D",
              val: "\\frac{8}{15}",
            },
          ],
          correct_option: "D",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 6,
          is_ques_text_mathematical: false,
          quesText:
            "A can lay railway track between two given stations in 16 days and B can do the same job in 12 days. With help of C, they did the job in 4 days only. Then, C alone can do the job in:",
          is_options_mathematical: true,
          options: [
            {
              option_No: "A",
              val: "{9}\\frac{1}{5}",
            },
            {
              option_No: "B",
              val: "{9}\\frac{2}{5}",
            },
            {
              option_No: "C",
              val: "{9}\\frac{3}{5}",
            },
            {
              option_No: "D",
              val: "10",
            },
          ],
          correct_option: "C",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 7,
          is_ques_text_mathematical: false,
          quesText:
            "A, B and C can do a piece of work in 20, 30 and 60 days respectively. In how many days can A do the work if he is assisted by B and C on every third day?",
          is_options_mathematical: false,
          options: [
            {
              option_No: "A",
              val: "12 days",
            },
            {
              option_No: "B",
              val: "15 days",
            },
            {
              option_No: "C",
              val: "16 days",
            },
            {
              option_No: "D",
              val: "18 days",
            },
          ],
          correct_option: "B",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 8,
          is_ques_text_mathematical: false,
          quesText:
            "A is thrice as good as workman as B and therefore is able to finish a job in 60 days less than B. Working together, they can do it in:",
          is_options_mathematical: false,
          options: [
            {
              option_No: "A",
              val: "20 days",
            },
            {
              option_No: "B",
              val: "22.5 days",
            },
            {
              option_No: "C",
              val: "25 days",
            },
            {
              option_No: "D",
              val: "30 days",
            },
          ],
          correct_option: "B",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 9,
          is_ques_text_mathematical: false,
          quesText:
            "A can do a work in 15 days and B in 20 days. If they work on it together for 4 days, then the fraction of the work that is left is :",
          is_options_mathematical: true,
          options: [
            {
              option_No: "A",
              val: "\\frac{1}{4}",
            },
            {
              option_No: "B",
              val: "\\frac{1}{10}",
            },
            {
              option_No: "C",
              val: "\\frac{7}{15}",
            },
            {
              option_No: "D",
              val: "\\frac{8}{15}",
            },
          ],
          correct_option: "D",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 10,
          is_ques_text_mathematical: false,
          quesText:
            "A can lay railway track between two given stations in 16 days and B can do the same job in 12 days. With help of C, they did the job in 4 days only. Then, C alone can do the job in:",
          is_options_mathematical: true,
          options: [
            {
              option_No: "A",
              val: "{9}\\frac{1}{5}",
            },
            {
              option_No: "B",
              val: "{9}\\frac{2}{5}",
            },
            {
              option_No: "C",
              val: "{9}\\frac{3}{5}",
            },
            {
              option_No: "D",
              val: "10",
            },
          ],
          correct_option: "C",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 11,
          is_ques_text_mathematical: false,
          quesText:
            "A, B and C can do a piece of work in 20, 30 and 60 days respectively. In how many days can A do the work if he is assisted by B and C on every third day?",
          is_options_mathematical: false,
          options: [
            {
              option_No: "A",
              val: "12 days",
            },
            {
              option_No: "B",
              val: "15 days",
            },
            {
              option_No: "C",
              val: "16 days",
            },
            {
              option_No: "D",
              val: "18 days",
            },
          ],
          correct_option: "B",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 12,
          is_ques_text_mathematical: false,
          quesText:
            "A is thrice as good as workman as B and therefore is able to finish a job in 60 days less than B. Working together, they can do it in:",
          is_options_mathematical: false,
          options: [
            {
              option_No: "A",
              val: "20 days",
            },
            {
              option_No: "B",
              val: "22.5 days",
            },
            {
              option_No: "C",
              val: "25 days",
            },
            {
              option_No: "D",
              val: "30 days",
            },
          ],
          correct_option: "B",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 13,
          is_ques_text_mathematical: false,
          quesText:
            "A can do a work in 15 days and B in 20 days. If they work on it together for 4 days, then the fraction of the work that is left is :",
          is_options_mathematical: true,
          options: [
            {
              option_No: "A",
              val: "\\frac{1}{4}",
            },
            {
              option_No: "B",
              val: "\\frac{1}{10}",
            },
            {
              option_No: "C",
              val: "\\frac{7}{15}",
            },
            {
              option_No: "D",
              val: "\\frac{8}{15}",
            },
          ],
          correct_option: "D",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 14,
          is_ques_text_mathematical: false,
          quesText:
            "A can lay railway track between two given stations in 16 days and B can do the same job in 12 days. With help of C, they did the job in 4 days only. Then, C alone can do the job in:",
          is_options_mathematical: true,
          options: [
            {
              option_No: "A",
              val: "{9}\\frac{1}{5}",
            },
            {
              option_No: "B",
              val: "{9}\\frac{2}{5}",
            },
            {
              option_No: "C",
              val: "{9}\\frac{3}{5}",
            },
            {
              option_No: "D",
              val: "10",
            },
          ],
          correct_option: "C",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 15,
          is_ques_text_mathematical: false,
          quesText:
            "A, B and C can do a piece of work in 20, 30 and 60 days respectively. In how many days can A do the work if he is assisted by B and C on every third day?",
          is_options_mathematical: false,
          options: [
            {
              option_No: "A",
              val: "12 days",
            },
            {
              option_No: "B",
              val: "15 days",
            },
            {
              option_No: "C",
              val: "16 days",
            },
            {
              option_No: "D",
              val: "18 days",
            },
          ],
          correct_option: "B",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 16,
          is_ques_text_mathematical: false,
          quesText:
            "A is thrice as good as workman as B and therefore is able to finish a job in 60 days less than B. Working together, they can do it in:",
          is_options_mathematical: false,
          options: [
            {
              option_No: "A",
              val: "20 days",
            },
            {
              option_No: "B",
              val: "22.5 days",
            },
            {
              option_No: "C",
              val: "25 days",
            },
            {
              option_No: "D",
              val: "30 days",
            },
          ],
          correct_option: "B",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 17,
          is_ques_text_mathematical: false,
          quesText:
            "A can do a work in 15 days and B in 20 days. If they work on it together for 4 days, then the fraction of the work that is left is :",
          is_options_mathematical: true,
          options: [
            {
              option_No: "A",
              val: "\\frac{1}{4}",
            },
            {
              option_No: "B",
              val: "\\frac{1}{10}",
            },
            {
              option_No: "C",
              val: "\\frac{7}{15}",
            },
            {
              option_No: "D",
              val: "\\frac{8}{15}",
            },
          ],
          correct_option: "D",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 18,
          is_ques_text_mathematical: false,
          quesText:
            "A can lay railway track between two given stations in 16 days and B can do the same job in 12 days. With help of C, they did the job in 4 days only. Then, C alone can do the job in:",
          is_options_mathematical: true,
          options: [
            {
              option_No: "A",
              val: "{9}\\frac{1}{5}",
            },
            {
              option_No: "B",
              val: "{9}\\frac{2}{5}",
            },
            {
              option_No: "C",
              val: "{9}\\frac{3}{5}",
            },
            {
              option_No: "D",
              val: "10",
            },
          ],
          correct_option: "C",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line: "A^'s\\1\\day^'s\\work\\=\\ \\frac{1}{15} \\;",
            },
            {
              ans_Line: "B^'s\\1\\day^'s\\work\\=\\ \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^'s\\1\\day^'s\\work\\=\\ \\left( \\frac{1}{15}+frac{1}{20}\\ \\right) \\ = frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^'s\\4\\day^'s\\work\\=\\ \\left( \\frac{7}{60}*4\\ \\right) \\ = frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore\\Remaining\\work\\=\\  \\left( \\1-frac{7}{15}\\ \\right) \\ = frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 19,
          is_ques_text_mathematical: false,
          quesText:
            "A, B and C can do a piece of work in 20, 30 and 60 days respectively. In how many days can A do the work if he is assisted by B and C on every third day?",
          is_options_mathematical: false,
          options: [
            {
              option_No: "A",
              val: "12 days",
            },
            {
              option_No: "B",
              val: "15 days",
            },
            {
              option_No: "C",
              val: "16 days",
            },
            {
              option_No: "D",
              val: "18 days",
            },
          ],
          correct_option: "B",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 20,
          is_ques_text_mathematical: false,
          quesText:
            "A is thrice as good as workman as B and therefore is able to finish a job in 60 days less than B. Working together, they can do it in:",
          is_options_mathematical: false,
          options: [
            {
              option_No: "A",
              val: "20 days",
            },
            {
              option_No: "B",
              val: "22.5 days",
            },
            {
              option_No: "C",
              val: "25 days",
            },
            {
              option_No: "D",
              val: "30 days",
            },
          ],
          correct_option: "B",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 21,
          is_ques_text_mathematical: false,
          quesText:
            "A can do a work in 15 days and B in 20 days. If they work on it together for 4 days, then the fraction of the work that is left is :",
          is_options_mathematical: true,
          options: [
            {
              option_No: "A",
              val: "\\frac{1}{4}",
            },
            {
              option_No: "B",
              val: "\\frac{1}{10}",
            },
            {
              option_No: "C",
              val: "\\frac{7}{15}",
            },
            {
              option_No: "D",
              val: "\\frac{8}{15}",
            },
          ],
          correct_option: "D",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 22,
          is_ques_text_mathematical: false,
          quesText:
            "A can lay railway track between two given stations in 16 days and B can do the same job in 12 days. With help of C, they did the job in 4 days only. Then, C alone can do the job in:",
          is_options_mathematical: true,
          options: [
            {
              option_No: "A",
              val: "{9}\\frac{1}{5}",
            },
            {
              option_No: "B",
              val: "{9}\\frac{2}{5}",
            },
            {
              option_No: "C",
              val: "{9}\\frac{3}{5}",
            },
            {
              option_No: "D",
              val: "10",
            },
          ],
          correct_option: "C",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 23,
          is_ques_text_mathematical: false,
          quesText:
            "A, B and C can do a piece of work in 20, 30 and 60 days respectively. In how many days can A do the work if he is assisted by B and C on every third day?",
          is_options_mathematical: false,
          options: [
            {
              option_No: "A",
              val: "12 days",
            },
            {
              option_No: "B",
              val: "15 days",
            },
            {
              option_No: "C",
              val: "16 days",
            },
            {
              option_No: "D",
              val: "18 days",
            },
          ],
          correct_option: "B",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 24,
          is_ques_text_mathematical: false,
          quesText:
            "A is thrice as good as workman as B and therefore is able to finish a job in 60 days less than B. Working together, they can do it in:",
          is_options_mathematical: false,
          options: [
            {
              option_No: "A",
              val: "20 days",
            },
            {
              option_No: "B",
              val: "22.5 days",
            },
            {
              option_No: "C",
              val: "25 days",
            },
            {
              option_No: "D",
              val: "30 days",
            },
          ],
          correct_option: "B",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 25,
          is_ques_text_mathematical: false,
          quesText:
            "A can do a work in 15 days and B in 20 days. If they work on it together for 4 days, then the fraction of the work that is left is :",
          is_options_mathematical: true,
          options: [
            {
              option_No: "A",
              val: "\\frac{1}{4}",
            },
            {
              option_No: "B",
              val: "\\frac{1}{10}",
            },
            {
              option_No: "C",
              val: "\\frac{7}{15}",
            },
            {
              option_No: "D",
              val: "\\frac{8}{15}",
            },
          ],
          correct_option: "D",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 26,
          is_ques_text_mathematical: false,
          quesText:
            "A can lay railway track between two given stations in 16 days and B can do the same job in 12 days. With help of C, they did the job in 4 days only. Then, C alone can do the job in:",
          is_options_mathematical: true,
          options: [
            {
              option_No: "A",
              val: "{9}\\frac{1}{5}",
            },
            {
              option_No: "B",
              val: "{9}\\frac{2}{5}",
            },
            {
              option_No: "C",
              val: "{9}\\frac{3}{5}",
            },
            {
              option_No: "D",
              val: "10",
            },
          ],
          correct_option: "C",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 27,
          is_ques_text_mathematical: false,
          quesText:
            "A, B and C can do a piece of work in 20, 30 and 60 days respectively. In how many days can A do the work if he is assisted by B and C on every third day?",
          is_options_mathematical: false,
          options: [
            {
              option_No: "A",
              val: "12 days",
            },
            {
              option_No: "B",
              val: "15 days",
            },
            {
              option_No: "C",
              val: "16 days",
            },
            {
              option_No: "D",
              val: "18 days",
            },
          ],
          correct_option: "B",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 28,
          is_ques_text_mathematical: false,
          quesText:
            "A is thrice as good as workman as B and therefore is able to finish a job in 60 days less than B. Working together, they can do it in:",
          is_options_mathematical: false,
          options: [
            {
              option_No: "A",
              val: "20 days",
            },
            {
              option_No: "B",
              val: "22.5 days",
            },
            {
              option_No: "C",
              val: "25 days",
            },
            {
              option_No: "D",
              val: "30 days",
            },
          ],
          correct_option: "B",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 29,
          is_ques_text_mathematical: false,
          quesText:
            "A can do a work in 15 days and B in 20 days. If they work on it together for 4 days, then the fraction of the work that is left is :",
          is_options_mathematical: true,
          options: [
            {
              option_No: "A",
              val: "\\frac{1}{4}",
            },
            {
              option_No: "B",
              val: "\\frac{1}{10}",
            },
            {
              option_No: "C",
              val: "\\frac{7}{15}",
            },
            {
              option_No: "D",
              val: "\\frac{8}{15}",
            },
          ],
          correct_option: "D",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 30,
          is_ques_text_mathematical: false,
          quesText:
            "A can lay railway track between two given stations in 16 days and B can do the same job in 12 days. With help of C, they did the job in 4 days only. Then, C alone can do the job in:",
          is_options_mathematical: true,
          options: [
            {
              option_No: "A",
              val: "{9}\\frac{1}{5}",
            },
            {
              option_No: "B",
              val: "{9}\\frac{2}{5}",
            },
            {
              option_No: "C",
              val: "{9}\\frac{3}{5}",
            },
            {
              option_No: "D",
              val: "10",
            },
          ],
          correct_option: "C",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 31,
          is_ques_text_mathematical: false,
          quesText:
            "A, B and C can do a piece of work in 20, 30 and 60 days respectively. In how many days can A do the work if he is assisted by B and C on every third day?",
          is_options_mathematical: false,
          options: [
            {
              option_No: "A",
              val: "12 days",
            },
            {
              option_No: "B",
              val: "15 days",
            },
            {
              option_No: "C",
              val: "16 days",
            },
            {
              option_No: "D",
              val: "18 days",
            },
          ],
          correct_option: "B",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 32,
          is_ques_text_mathematical: false,
          quesText:
            "A is thrice as good as workman as B and therefore is able to finish a job in 60 days less than B. Working together, they can do it in:",
          is_options_mathematical: false,
          options: [
            {
              option_No: "A",
              val: "20 days",
            },
            {
              option_No: "B",
              val: "22.5 days",
            },
            {
              option_No: "C",
              val: "25 days",
            },
            {
              option_No: "D",
              val: "30 days",
            },
          ],
          correct_option: "B",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 33,
          is_ques_text_mathematical: false,
          quesText:
            "A can do a work in 15 days and B in 20 days. If they work on it together for 4 days, then the fraction of the work that is left is :",
          is_options_mathematical: true,
          options: [
            {
              option_No: "A",
              val: "\\frac{1}{4}",
            },
            {
              option_No: "B",
              val: "\\frac{1}{10}",
            },
            {
              option_No: "C",
              val: "\\frac{7}{15}",
            },
            {
              option_No: "D",
              val: "\\frac{8}{15}",
            },
          ],
          correct_option: "D",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 34,
          is_ques_text_mathematical: false,
          quesText:
            "A can lay railway track between two given stations in 16 days and B can do the same job in 12 days. With help of C, they did the job in 4 days only. Then, C alone can do the job in:",
          is_options_mathematical: true,
          options: [
            {
              option_No: "A",
              val: "{9}\\frac{1}{5}",
            },
            {
              option_No: "B",
              val: "{9}\\frac{2}{5}",
            },
            {
              option_No: "C",
              val: "{9}\\frac{3}{5}",
            },
            {
              option_No: "D",
              val: "10",
            },
          ],
          correct_option: "C",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 35,
          is_ques_text_mathematical: false,
          quesText:
            "A, B and C can do a piece of work in 20, 30 and 60 days respectively. In how many days can A do the work if he is assisted by B and C on every third day?",
          is_options_mathematical: false,
          options: [
            {
              option_No: "A",
              val: "12 days",
            },
            {
              option_No: "B",
              val: "15 days",
            },
            {
              option_No: "C",
              val: "16 days",
            },
            {
              option_No: "D",
              val: "18 days",
            },
          ],
          correct_option: "B",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 36,
          is_ques_text_mathematical: false,
          quesText:
            "A is thrice as good as workman as B and therefore is able to finish a job in 60 days less than B. Working together, they can do it in:",
          is_options_mathematical: false,
          options: [
            {
              option_No: "A",
              val: "20 days",
            },
            {
              option_No: "B",
              val: "22.5 days",
            },
            {
              option_No: "C",
              val: "25 days",
            },
            {
              option_No: "D",
              val: "30 days",
            },
          ],
          correct_option: "B",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
      ],
    },
    {
      name: "Logical Reasoning",
      no_of_ques: 36,
      questions: [
        {
          ques_no: 1,
          is_ques_text_mathematical: false,
          quesText:
            "A can do a work in 15 days and B in 20 days. If they work on it together for 4 days, then the fraction of the work that is left is :",
          is_options_mathematical: true,
          options: [
            {
              option_No: "A",
              val: "\\frac{1}{4}",
            },
            {
              option_No: "B",
              val: "\\frac{1}{10}",
            },
            {
              option_No: "C",
              val: "\\frac{7}{15}",
            },
            {
              option_No: "D",
              val: "\\frac{8}{15}",
            },
          ],
          correct_option: "D",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 2,
          is_ques_text_mathematical: false,
          quesText:
            "A can lay railway track between two given stations in 16 days and B can do the same job in 12 days. With help of C, they did the job in 4 days only. Then, C alone can do the job in:",
          is_options_mathematical: true,
          options: [
            {
              option_No: "A",
              val: "{9}\\frac{1}{5}",
            },
            {
              option_No: "B",
              val: "{9}\\frac{2}{5}",
            },
            {
              option_No: "C",
              val: "{9}\\frac{3}{5}",
            },
            {
              option_No: "D",
              val: "10",
            },
          ],
          correct_option: "C",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 3,
          is_ques_text_mathematical: false,
          quesText:
            "A, B and C can do a piece of work in 20, 30 and 60 days respectively. In how many days can A do the work if he is assisted by B and C on every third day?",
          is_options_mathematical: false,
          options: [
            {
              option_No: "A",
              val: "12 days",
            },
            {
              option_No: "B",
              val: "15 days",
            },
            {
              option_No: "C",
              val: "16 days",
            },
            {
              option_No: "D",
              val: "18 days",
            },
          ],
          correct_option: "B",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 4,
          is_ques_text_mathematical: false,
          quesText:
            "A is thrice as good as workman as B and therefore is able to finish a job in 60 days less than B. Working together, they can do it in:",
          is_options_mathematical: false,
          options: [
            {
              option_No: "A",
              val: "20 days",
            },
            {
              option_No: "B",
              val: "22.5 days",
            },
            {
              option_No: "C",
              val: "25 days",
            },
            {
              option_No: "D",
              val: "30 days",
            },
          ],
          correct_option: "B",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 5,
          is_ques_text_mathematical: false,
          quesText:
            "A can do a work in 15 days and B in 20 days. If they work on it together for 4 days, then the fraction of the work that is left is :",
          is_options_mathematical: true,
          options: [
            {
              option_No: "A",
              val: "\\frac{1}{4}",
            },
            {
              option_No: "B",
              val: "\\frac{1}{10}",
            },
            {
              option_No: "C",
              val: "\\frac{7}{15}",
            },
            {
              option_No: "D",
              val: "\\frac{8}{15}",
            },
          ],
          correct_option: "D",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 6,
          is_ques_text_mathematical: false,
          quesText:
            "A can lay railway track between two given stations in 16 days and B can do the same job in 12 days. With help of C, they did the job in 4 days only. Then, C alone can do the job in:",
          is_options_mathematical: true,
          options: [
            {
              option_No: "A",
              val: "{9}\\frac{1}{5}",
            },
            {
              option_No: "B",
              val: "{9}\\frac{2}{5}",
            },
            {
              option_No: "C",
              val: "{9}\\frac{3}{5}",
            },
            {
              option_No: "D",
              val: "10",
            },
          ],
          correct_option: "C",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 7,
          is_ques_text_mathematical: false,
          quesText:
            "A, B and C can do a piece of work in 20, 30 and 60 days respectively. In how many days can A do the work if he is assisted by B and C on every third day?",
          is_options_mathematical: false,
          options: [
            {
              option_No: "A",
              val: "12 days",
            },
            {
              option_No: "B",
              val: "15 days",
            },
            {
              option_No: "C",
              val: "16 days",
            },
            {
              option_No: "D",
              val: "18 days",
            },
          ],
          correct_option: "B",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 8,
          is_ques_text_mathematical: false,
          quesText:
            "A is thrice as good as workman as B and therefore is able to finish a job in 60 days less than B. Working together, they can do it in:",
          is_options_mathematical: false,
          options: [
            {
              option_No: "A",
              val: "20 days",
            },
            {
              option_No: "B",
              val: "22.5 days",
            },
            {
              option_No: "C",
              val: "25 days",
            },
            {
              option_No: "D",
              val: "30 days",
            },
          ],
          correct_option: "B",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 9,
          is_ques_text_mathematical: false,
          quesText:
            "A can do a work in 15 days and B in 20 days. If they work on it together for 4 days, then the fraction of the work that is left is :",
          is_options_mathematical: true,
          options: [
            {
              option_No: "A",
              val: "\\frac{1}{4}",
            },
            {
              option_No: "B",
              val: "\\frac{1}{10}",
            },
            {
              option_No: "C",
              val: "\\frac{7}{15}",
            },
            {
              option_No: "D",
              val: "\\frac{8}{15}",
            },
          ],
          correct_option: "D",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 10,
          is_ques_text_mathematical: false,
          quesText:
            "A can lay railway track between two given stations in 16 days and B can do the same job in 12 days. With help of C, they did the job in 4 days only. Then, C alone can do the job in:",
          is_options_mathematical: true,
          options: [
            {
              option_No: "A",
              val: "{9}\\frac{1}{5}",
            },
            {
              option_No: "B",
              val: "{9}\\frac{2}{5}",
            },
            {
              option_No: "C",
              val: "{9}\\frac{3}{5}",
            },
            {
              option_No: "D",
              val: "10",
            },
          ],
          correct_option: "C",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 11,
          is_ques_text_mathematical: false,
          quesText:
            "A, B and C can do a piece of work in 20, 30 and 60 days respectively. In how many days can A do the work if he is assisted by B and C on every third day?",
          is_options_mathematical: false,
          options: [
            {
              option_No: "A",
              val: "12 days",
            },
            {
              option_No: "B",
              val: "15 days",
            },
            {
              option_No: "C",
              val: "16 days",
            },
            {
              option_No: "D",
              val: "18 days",
            },
          ],
          correct_option: "B",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 12,
          is_ques_text_mathematical: false,
          quesText:
            "A is thrice as good as workman as B and therefore is able to finish a job in 60 days less than B. Working together, they can do it in:",
          is_options_mathematical: false,
          options: [
            {
              option_No: "A",
              val: "20 days",
            },
            {
              option_No: "B",
              val: "22.5 days",
            },
            {
              option_No: "C",
              val: "25 days",
            },
            {
              option_No: "D",
              val: "30 days",
            },
          ],
          correct_option: "B",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 13,
          is_ques_text_mathematical: false,
          quesText:
            "A can do a work in 15 days and B in 20 days. If they work on it together for 4 days, then the fraction of the work that is left is :",
          is_options_mathematical: true,
          options: [
            {
              option_No: "A",
              val: "\\frac{1}{4}",
            },
            {
              option_No: "B",
              val: "\\frac{1}{10}",
            },
            {
              option_No: "C",
              val: "\\frac{7}{15}",
            },
            {
              option_No: "D",
              val: "\\frac{8}{15}",
            },
          ],
          correct_option: "D",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 14,
          is_ques_text_mathematical: false,
          quesText:
            "A can lay railway track between two given stations in 16 days and B can do the same job in 12 days. With help of C, they did the job in 4 days only. Then, C alone can do the job in:",
          is_options_mathematical: true,
          options: [
            {
              option_No: "A",
              val: "{9}\\frac{1}{5}",
            },
            {
              option_No: "B",
              val: "{9}\\frac{2}{5}",
            },
            {
              option_No: "C",
              val: "{9}\\frac{3}{5}",
            },
            {
              option_No: "D",
              val: "10",
            },
          ],
          correct_option: "C",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 15,
          is_ques_text_mathematical: false,
          quesText:
            "A, B and C can do a piece of work in 20, 30 and 60 days respectively. In how many days can A do the work if he is assisted by B and C on every third day?",
          is_options_mathematical: false,
          options: [
            {
              option_No: "A",
              val: "12 days",
            },
            {
              option_No: "B",
              val: "15 days",
            },
            {
              option_No: "C",
              val: "16 days",
            },
            {
              option_No: "D",
              val: "18 days",
            },
          ],
          correct_option: "B",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 16,
          is_ques_text_mathematical: false,
          quesText:
            "A is thrice as good as workman as B and therefore is able to finish a job in 60 days less than B. Working together, they can do it in:",
          is_options_mathematical: false,
          options: [
            {
              option_No: "A",
              val: "20 days",
            },
            {
              option_No: "B",
              val: "22.5 days",
            },
            {
              option_No: "C",
              val: "25 days",
            },
            {
              option_No: "D",
              val: "30 days",
            },
          ],
          correct_option: "B",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 17,
          is_ques_text_mathematical: false,
          quesText:
            "A can do a work in 15 days and B in 20 days. If they work on it together for 4 days, then the fraction of the work that is left is :",
          is_options_mathematical: true,
          options: [
            {
              option_No: "A",
              val: "\\frac{1}{4}",
            },
            {
              option_No: "B",
              val: "\\frac{1}{10}",
            },
            {
              option_No: "C",
              val: "\\frac{7}{15}",
            },
            {
              option_No: "D",
              val: "\\frac{8}{15}",
            },
          ],
          correct_option: "D",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 18,
          is_ques_text_mathematical: false,
          quesText:
            "A can lay railway track between two given stations in 16 days and B can do the same job in 12 days. With help of C, they did the job in 4 days only. Then, C alone can do the job in:",
          is_options_mathematical: true,
          options: [
            {
              option_No: "A",
              val: "{9}\\frac{1}{5}",
            },
            {
              option_No: "B",
              val: "{9}\\frac{2}{5}",
            },
            {
              option_No: "C",
              val: "{9}\\frac{3}{5}",
            },
            {
              option_No: "D",
              val: "10",
            },
          ],
          correct_option: "C",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 19,
          is_ques_text_mathematical: false,
          quesText:
            "A, B and C can do a piece of work in 20, 30 and 60 days respectively. In how many days can A do the work if he is assisted by B and C on every third day?",
          is_options_mathematical: false,
          options: [
            {
              option_No: "A",
              val: "12 days",
            },
            {
              option_No: "B",
              val: "15 days",
            },
            {
              option_No: "C",
              val: "16 days",
            },
            {
              option_No: "D",
              val: "18 days",
            },
          ],
          correct_option: "B",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 20,
          is_ques_text_mathematical: false,
          quesText:
            "A is thrice as good as workman as B and therefore is able to finish a job in 60 days less than B. Working together, they can do it in:",
          is_options_mathematical: false,
          options: [
            {
              option_No: "A",
              val: "20 days",
            },
            {
              option_No: "B",
              val: "22.5 days",
            },
            {
              option_No: "C",
              val: "25 days",
            },
            {
              option_No: "D",
              val: "30 days",
            },
          ],
          correct_option: "B",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 21,
          is_ques_text_mathematical: false,
          quesText:
            "A can do a work in 15 days and B in 20 days. If they work on it together for 4 days, then the fraction of the work that is left is :",
          is_options_mathematical: true,
          options: [
            {
              option_No: "A",
              val: "\\frac{1}{4}",
            },
            {
              option_No: "B",
              val: "\\frac{1}{10}",
            },
            {
              option_No: "C",
              val: "\\frac{7}{15}",
            },
            {
              option_No: "D",
              val: "\\frac{8}{15}",
            },
          ],
          correct_option: "D",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 22,
          is_ques_text_mathematical: false,
          quesText:
            "A can lay railway track between two given stations in 16 days and B can do the same job in 12 days. With help of C, they did the job in 4 days only. Then, C alone can do the job in:",
          is_options_mathematical: true,
          options: [
            {
              option_No: "A",
              val: "{9}\\frac{1}{5}",
            },
            {
              option_No: "B",
              val: "{9}\\frac{2}{5}",
            },
            {
              option_No: "C",
              val: "{9}\\frac{3}{5}",
            },
            {
              option_No: "D",
              val: "10",
            },
          ],
          correct_option: "C",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 23,
          is_ques_text_mathematical: false,
          quesText:
            "A, B and C can do a piece of work in 20, 30 and 60 days respectively. In how many days can A do the work if he is assisted by B and C on every third day?",
          is_options_mathematical: false,
          options: [
            {
              option_No: "A",
              val: "12 days",
            },
            {
              option_No: "B",
              val: "15 days",
            },
            {
              option_No: "C",
              val: "16 days",
            },
            {
              option_No: "D",
              val: "18 days",
            },
          ],
          correct_option: "B",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 24,
          is_ques_text_mathematical: false,
          quesText:
            "A is thrice as good as workman as B and therefore is able to finish a job in 60 days less than B. Working together, they can do it in:",
          is_options_mathematical: false,
          options: [
            {
              option_No: "A",
              val: "20 days",
            },
            {
              option_No: "B",
              val: "22.5 days",
            },
            {
              option_No: "C",
              val: "25 days",
            },
            {
              option_No: "D",
              val: "30 days",
            },
          ],
          correct_option: "B",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 25,
          is_ques_text_mathematical: false,
          quesText:
            "A can do a work in 15 days and B in 20 days. If they work on it together for 4 days, then the fraction of the work that is left is :",
          is_options_mathematical: true,
          options: [
            {
              option_No: "A",
              val: "\\frac{1}{4}",
            },
            {
              option_No: "B",
              val: "\\frac{1}{10}",
            },
            {
              option_No: "C",
              val: "\\frac{7}{15}",
            },
            {
              option_No: "D",
              val: "\\frac{8}{15}",
            },
          ],
          correct_option: "D",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 26,
          is_ques_text_mathematical: false,
          quesText:
            "A can lay railway track between two given stations in 16 days and B can do the same job in 12 days. With help of C, they did the job in 4 days only. Then, C alone can do the job in:",
          is_options_mathematical: true,
          options: [
            {
              option_No: "A",
              val: "{9}\\frac{1}{5}",
            },
            {
              option_No: "B",
              val: "{9}\\frac{2}{5}",
            },
            {
              option_No: "C",
              val: "{9}\\frac{3}{5}",
            },
            {
              option_No: "D",
              val: "10",
            },
          ],
          correct_option: "C",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 27,
          is_ques_text_mathematical: false,
          quesText:
            "A, B and C can do a piece of work in 20, 30 and 60 days respectively. In how many days can A do the work if he is assisted by B and C on every third day?",
          is_options_mathematical: false,
          options: [
            {
              option_No: "A",
              val: "12 days",
            },
            {
              option_No: "B",
              val: "15 days",
            },
            {
              option_No: "C",
              val: "16 days",
            },
            {
              option_No: "D",
              val: "18 days",
            },
          ],
          correct_option: "B",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 28,
          is_ques_text_mathematical: false,
          quesText:
            "A is thrice as good as workman as B and therefore is able to finish a job in 60 days less than B. Working together, they can do it in:",
          is_options_mathematical: false,
          options: [
            {
              option_No: "A",
              val: "20 days",
            },
            {
              option_No: "B",
              val: "22.5 days",
            },
            {
              option_No: "C",
              val: "25 days",
            },
            {
              option_No: "D",
              val: "30 days",
            },
          ],
          correct_option: "B",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 29,
          is_ques_text_mathematical: false,
          quesText:
            "A can do a work in 15 days and B in 20 days. If they work on it together for 4 days, then the fraction of the work that is left is :",
          is_options_mathematical: true,
          options: [
            {
              option_No: "A",
              val: "\\frac{1}{4}",
            },
            {
              option_No: "B",
              val: "\\frac{1}{10}",
            },
            {
              option_No: "C",
              val: "\\frac{7}{15}",
            },
            {
              option_No: "D",
              val: "\\frac{8}{15}",
            },
          ],
          correct_option: "D",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 30,
          is_ques_text_mathematical: false,
          quesText:
            "A can lay railway track between two given stations in 16 days and B can do the same job in 12 days. With help of C, they did the job in 4 days only. Then, C alone can do the job in:",
          is_options_mathematical: true,
          options: [
            {
              option_No: "A",
              val: "{9}\\frac{1}{5}",
            },
            {
              option_No: "B",
              val: "{9}\\frac{2}{5}",
            },
            {
              option_No: "C",
              val: "{9}\\frac{3}{5}",
            },
            {
              option_No: "D",
              val: "10",
            },
          ],
          correct_option: "C",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 31,
          is_ques_text_mathematical: false,
          quesText:
            "A, B and C can do a piece of work in 20, 30 and 60 days respectively. In how many days can A do the work if he is assisted by B and C on every third day?",
          is_options_mathematical: false,
          options: [
            {
              option_No: "A",
              val: "12 days",
            },
            {
              option_No: "B",
              val: "15 days",
            },
            {
              option_No: "C",
              val: "16 days",
            },
            {
              option_No: "D",
              val: "18 days",
            },
          ],
          correct_option: "B",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 32,
          is_ques_text_mathematical: false,
          quesText:
            "A is thrice as good as workman as B and therefore is able to finish a job in 60 days less than B. Working together, they can do it in:",
          is_options_mathematical: false,
          options: [
            {
              option_No: "A",
              val: "20 days",
            },
            {
              option_No: "B",
              val: "22.5 days",
            },
            {
              option_No: "C",
              val: "25 days",
            },
            {
              option_No: "D",
              val: "30 days",
            },
          ],
          correct_option: "B",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 33,
          is_ques_text_mathematical: false,
          quesText:
            "A can do a work in 15 days and B in 20 days. If they work on it together for 4 days, then the fraction of the work that is left is :",
          is_options_mathematical: true,
          options: [
            {
              option_No: "A",
              val: "\\frac{1}{4}",
            },
            {
              option_No: "B",
              val: "\\frac{1}{10}",
            },
            {
              option_No: "C",
              val: "\\frac{7}{15}",
            },
            {
              option_No: "D",
              val: "\\frac{8}{15}",
            },
          ],
          correct_option: "D",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 34,
          is_ques_text_mathematical: false,
          quesText:
            "A can lay railway track between two given stations in 16 days and B can do the same job in 12 days. With help of C, they did the job in 4 days only. Then, C alone can do the job in:",
          is_options_mathematical: true,
          options: [
            {
              option_No: "A",
              val: "{9}\\frac{1}{5}",
            },
            {
              option_No: "B",
              val: "{9}\\frac{2}{5}",
            },
            {
              option_No: "C",
              val: "{9}\\frac{3}{5}",
            },
            {
              option_No: "D",
              val: "10",
            },
          ],
          correct_option: "C",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 35,
          is_ques_text_mathematical: false,
          quesText:
            "A, B and C can do a piece of work in 20, 30 and 60 days respectively. In how many days can A do the work if he is assisted by B and C on every third day?",
          is_options_mathematical: false,
          options: [
            {
              option_No: "A",
              val: "12 days",
            },
            {
              option_No: "B",
              val: "15 days",
            },
            {
              option_No: "C",
              val: "16 days",
            },
            {
              option_No: "D",
              val: "18 days",
            },
          ],
          correct_option: "B",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
        {
          ques_no: 36,
          is_ques_text_mathematical: false,
          quesText:
            "A is thrice as good as workman as B and therefore is able to finish a job in 60 days less than B. Working together, they can do it in:",
          is_options_mathematical: false,
          options: [
            {
              option_No: "A",
              val: "20 days",
            },
            {
              option_No: "B",
              val: "22.5 days",
            },
            {
              option_No: "C",
              val: "25 days",
            },
            {
              option_No: "D",
              val: "30 days",
            },
          ],
          correct_option: "B",
          is_answer_mathematical: true,
          answer_lines: [
            {
              ans_Line:
                "A^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{15} \\ ;",
            },
            {
              ans_Line:
                "B^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\  \\frac{1}{20} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 1 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{1}{15}+\\frac{1}{20} \\   \\right) \\ = \\frac{7}{60} \\ ;",
            },
            {
              ans_Line:
                "(A+B)^{'}s \\ 4 \\ day^{'}s \\ work \\ = \\   \\left( \\frac{7}{60}*4 \\  \\right) \\ = \\frac{7}{15} \\ ;",
            },
            {
              ans_Line:
                "Therefore \\ Remaining \\ work \\ = \\   \\left(  \\ 1-\\frac{7}{15} \\  \\right) \\ = \\frac{8}{15} \\ ;",
            },
          ],
        },
      ],
    },
  ];

  const examTime = 120; //in minutes
  const initialTime = examTime * 60 * 1000; // initial time in milliseconds, defaults to 60000

  window.$ = $;

  // const [timeLeft, { start, pause, resume, reset }] = useCountDown(initialTime, interval);

  const [timeSecLeft, setTimeSecLeft] = useState(initialTime);
  const [minLeft, setMinLeft] = useState(null);
  const [secLeft, setSecLeft] = useState(null);

  const displayTimeLeft = (seconds) => {
    let minutesLeft = Math.floor(seconds / 60);
    let secondsLeft = seconds % 60;
    setMinLeft(minutesLeft);
    setSecLeft(secondsLeft);
  };

  useEffect(() => {
    // exit early when we reach 0
    if (!timeSecLeft) return;

    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setTimeSecLeft(timeSecLeft - 1000);
      displayTimeLeft(timeSecLeft / 1000 - 1);
      //  setTimeSecLeft(displayTimeLeft(timeSecLeft));
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
  }, [timeSecLeft]);

  // }

  const handleClickedOption = (v) => {
    if (selectedQues != v.ques) {
      setIsAnsSubmitted(false);
    }
    setClickedOption(v.option);
    setSelectedQues(v.ques);

    // console.log("call ho raha hu", v.option , v.ques);
  };

  const handleOMRSheet = (v) => {
    if (OmrSheet.findIndex((a) => a.quesNo === v[0].ques) == -1) {
      OmrSheet.push({
        quesNo: v[0].ques,
        selectedOption: v[0].soption,
        rightOption: v[0].roption,
        isCorrect: v[0].soption == v[0].roption ? true : false,
      });
      setcurrentMark(currentMark + 1);
    } else {
      OmrSheet.splice(
        OmrSheet.findIndex((a) => a.quesNo === v[0].ques),
        1,
        {
          quesNo: v[0].ques,
          selectedOption: v[0].soption,
          rightOption: v[0].roption,
          isCorrect: v[0].soption == v[0].roption ? true : false,
        }
      );
    }
    setIsAnsSubmitted(true);

    // console.log("call ho raha hu", v, v[0].ques, v[0].soption, v[0].roption, OmrSheet);
  };

  // const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

  // const myRef = useRef(null);
  // const scrollTop = async () => scrollToRef(myRef);

  let tName = useParams().tid;

  return (
    <div className="apptest-page">
      <h3>{tName} Test</h3>
      <div className="practice-test">
        <div style={{ padding: 50 }}>
          <div className="timer-box" id="timer-box">
            <h3>Attempted: {currentMark}/36 </h3>

            <div>
              <p>Exam Duration: {examTime} Minutes</p>
            </div>
            <div>
              {minLeft == null || secLeft == null ? (
                <p>Time: {examTime} minutes & 00 seconds Left</p>
              ) : minLeft > 9 ? (
                minLeft > 99 ? (
                  secLeft > 9 ? (
                    <p>
                      Time: {minLeft} minutes & {secLeft} seconds Left
                    </p>
                  ) : (
                    <p>
                      Time: {minLeft} minutes &  {secLeft} seconds Left
                    </p>
                  )
                ) : secLeft > 9 ? (
                  <p>
                    Time:  {minLeft} minutes & {secLeft} seconds Left
                  </p>
                ) : (
                  <p>
                    Time:  {minLeft} minutes &  {secLeft} seconds Left
                  </p>
                )
              ) : secLeft > 9 ? (
                <p>
                  Time:   {minLeft} minutes & {secLeft} seconds Left
                </p>
              ) : (
                <p>
                  Time:   {minLeft} minutes &  {secLeft} seconds Left
                </p>
              )}
            </div>
            <hr />
            <div>Total No. of Questions: {maxMark}</div>
            <div>  </div>
            <div>Total Question Attempted: {currentMark}</div>
          </div>
          {TopicList &&
            TopicList.map((item) => {
              if (item.name === tName) {
                return (
                  <div>
                    <ul
                      style={{
                        listStyle: "number",
                        fontWeight: "bold",
                        fontSize: "19px",
                      }}
                    >
                      {item.questions.map((ques) => {
                        return (
                          <div className="question-box">
                            <li>
                              <span className="ques-text">
                                {ques.is_ques_text_mathematical && (
                                  <span className="ques-math-text">
                                    <MathJax.Provider>
                                      <MathJax.Node
                                        inline
                                        formula={ques.quesText}
                                      />
                                    </MathJax.Provider>
                                  </span>
                                )}
                                {!ques.is_ques_text_mathematical && (
                                  <span className="ques-normal-text">
                                    {ques.quesText}
                                  </span>
                                )}
                              </span>
                            </li>
                            <div className="ques-options">
                              {ques.is_options_mathematical &&
                                ques.options.map((option) => {
                                  return (
                                    <div
                                      className={
                                        clickedOption == option.option_No &&
                                        selectedQues == ques.ques_no
                                          ? "selected-option"
                                          : "optionValue1"
                                      }
                                      onClick={() =>
                                        handleClickedOption({
                                          option: `${option.option_No}`,
                                          ques: `${ques.ques_no}`,
                                        })
                                      }
                                    >
                                      {option.option_No})
                                      <span className="option-text">
                                        <MathJax.Provider>
                                          <MathJax.Node
                                            inline
                                            formula={option.val}
                                          />
                                        </MathJax.Provider>
                                      </span>
                                    </div>
                                  );
                                })}
                              {!ques.is_options_mathematical &&
                                ques.options.map((option) => {
                                  return (
                                    <div
                                      className={
                                        clickedOption == option.option_No &&
                                        selectedQues == ques.ques_no
                                          ? "selected-option"
                                          : "optionValue1"
                                      }
                                      onClick={() =>
                                        handleClickedOption({
                                          option: `${option.option_No}`,
                                          ques: `${ques.ques_no}`,
                                        })
                                      }
                                    >
                                      {option.option_No})
                                      <span className="option-text">
                                        {option.val}
                                      </span>
                                    </div>
                                  );
                                })}
                            </div>

                            {/* //right side ka box 
                                                // Exam Submit ka button rahega
                                                // right side pe timer rahega
                                                //right side pe all ques ka status box rahega ki koun sa box ho chuka hai(green) aur koun sa nahi hua (white)
                                                //total ques attempted out of total ques
                                                //ye sara content ek box me rahega jo page ke sath float karega */}
                            <div className="answer-apti">
                              {OmrSheet.findIndex(
                                (a) => a.quesNo === selectedQues
                              ) == -1
                                ? clickedOption &&
                                  selectedQues == ques.ques_no && (
                                    <div>
                                      {!isAnsSubmitted && (
                                        <button
                                          className="button-style-css"
                                          onClick={() =>
                                            handleOMRSheet([
                                              {
                                                ques: `${ques.ques_no}`,
                                                roption: `${ques.correct_option}`,
                                                soption: `${clickedOption}`,
                                              },
                                            ])
                                          }
                                        >
                                          Submit Answer
                                          {console.log(
                                            "hi ha ha",
                                            OmrSheet.findIndex(
                                              (a) => a.quesNo === selectedQues
                                            )
                                          )}
                                        </button>
                                      )}
                                    </div>
                                  )
                                : clickedOption &&
                                  selectedQues == ques.ques_no && (
                                    <div>
                                      {isAnsSubmitted ? (
                                        <div>
                                          <div>
                                            {" "}
                                            <span>✔</span> Answer Successfully
                                            Submitted
                                          </div>
                                        </div>
                                      ) : (
                                        <div>
                                          <div className="alreadyAnswered-warning">
                                            <div className="warning-line1">
                                              You have Already answered this
                                              question and have Submitted Option
                                              (
                                              {
                                                OmrSheet[
                                                  OmrSheet.findIndex(
                                                    (a) =>
                                                      a.quesNo === selectedQues
                                                  )
                                                ].selectedOption
                                              }
                                              ).
                                            </div>
                                            <div className="warning-line2">
                                              Click "Resubmit-Answer" Button if
                                              you want to change your Answer.
                                            </div>
                                            {console.log(
                                              "hi ha ha",
                                              OmrSheet.findIndex(
                                                (a) => a.quesNo === selectedQues
                                              )
                                            )}
                                          </div>
                                          <button
                                            className="button-style-css"
                                            onClick={() =>
                                              handleOMRSheet([
                                                {
                                                  ques: `${ques.ques_no}`,
                                                  roption: `${ques.correct_option}`,
                                                  soption: `${clickedOption}`,
                                                },
                                              ])
                                            }
                                          >
                                            Resubmit-Answer
                                          </button>
                                        </div>
                                      )}
                                    </div>
                                  )}
                            </div>
                          </div>
                        );
                      })}
                    </ul>
                  </div>
                );
              }
            })}
        </div>
      </div>
    </div>
  );
};

export default AptitudeTest;
