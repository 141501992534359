import React, { useEffect, useState, useContext } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import FileBase64 from 'react-file-base64';

import './StudentAssignmentPop.css'
import { useHttpClient } from '../../Components/Hooks/http-hook'
import Auth from '../Auth/Auth';
import { AuthContext } from '../../context/auth-context';
import ErrorModal from "../../Components/Input/ErrorModel";
import LoadingSpinner from "../../Components/Input/LoadingSpinner";

const StudentAssignmentPop = (props) => {
    const [isPop, setIsPop] = useState(true)
    const [zipFileDetails, setZipFileDetails] = useState({})
    const [fileDetails, setFileDetails] = useState()

    let { cid } = useParams();
    console.log(cid)
    console.log(props.assignmentDetails.skills)
    const auth = useContext(AuthContext);

    const { isLoading, error, sendRequest, clearError } = useHttpClient();

    const handleCreateAssignment = async(title) => {

      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/s3/assignment/${title}`,
          "GET",
        );
  
        if (responseData.ok == true) {
            console.log('log added')
            return responseData.data
          } else {
              alert(responseData.msg)
          }
      } 
      catch (err) {console.log(err)}  
    }

    const handleDownload = () => {
      const bytes = atob(zipFileDetails.base64);
      let length = bytes.length;
      let out = new Uint8Array(length);
  
      while (length--) {
          out[length] = bytes.charCodeAt(length);
      }
  
      let blob = new Blob([out], { type: 'application/pdf' });
      // const blob = base64toBlob(fileDetails.base64)
      console.log(blob, 'blob')
      const url = URL.createObjectURL(blob)
      
      const link = document.createElement('a');
      link.href = url;
      const filename = zipFileDetails.filename
      console.log(filename)
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      console.log(url)
      link.click();
    }
    
    useEffect(() => {
      handleCreateAssignment(props.assignmentDetails.title).then(
        response => setZipFileDetails(response)
        )
    }, [])

    const handleSubmitAssignment = async() => {
      // event.preventDefault();

      try {
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/s3/assignment/${cid}`,
          "POST",
          JSON.stringify({
            title: `${props.assignmentDetails.title}`,
            assignment_id: `${props.assignmentDetails.assignmentId}`,
            file_details: fileDetails,
            status: `${props.assignmentDetails.status}`,
            skills: props.assignmentDetails.skills,
            deadline: `${props.assignmentDetails.deadline}`,
            surge_id: `${auth.surge_id}`
          }),
          {
            "Content-Type": "application/json"
          }
        );
  
        if (responseData.ok == true) {
              alert(responseData.msg + ' Refresh the page and come back to see updated status of assignment')
          } else {
              alert(responseData.msg)
          }
      } 
      catch (err) {console.log(err)}
  
  }

  return (
    <React.Fragment>
    <ErrorModal error={error} onClear={clearError} />
    {isLoading && <LoadingSpinner asOverlay />}
    {
      !isLoading && (
        <div className='student-assignment-pop'>
        {isPop &&
            <div className='box'>
                <p style={{"fontWeight":"bold","fontSize":"20px","textAlign":"center"}}>{props.assignmentDetails.title}</p>
                <span className="close-icon" onClick={props.handleClose}>x</span>
                <div className='assign-info'>
                  <div className='file-content'>
                    <div className='add-heading'>
                    <p>Download Assignment Files</p>
                    <button onClick={handleDownload}>Download</button>              
                    </div>
                    <div className='submission'>
                      <p>Submit your Assignment</p>
                      <div>
                        <FileBase64
                            type="file" 
                            multiple={true} 
                            onDone={
                                (files) => {
                                    const fileDetails = files.map(file => ({ "name": file.name, "base64": file.base64 }));
                                    setFileDetails(fileDetails);
                                }
                            } 
                            onChange={
                                (e) => {
                                    const files = e.target.files;
                                    const fileDetails = [];
                                    for (let i = 0; i < files.length; i++) {
                                        const file = files[i];
                                        const reader = new FileReader();
                                        reader.onload = (event) => {
                                            fileDetails.push({ "name": file.name, "base64": event.target.result });
                                            if (fileDetails.length === files.length) {
                                                // all files have been processed
                                                setFileDetails(fileDetails);
                                            }
                                        };
                                        reader.readAsDataURL(file);
                                    }
                                }
                            }
                        >                     
                        </FileBase64>                    
                    </div>
                    </div>
                  </div>
                  <div className='submit-status'>
                    <p>Deadline : {props.assignmentDetails.deadline}</p>
                    <p>Skills Tested</p>
                    <ul>
                      {props.assignmentDetails.skills.skills.map((skill) => {
                        return (
                          <li>{skill}</li>
                        )
                      })}  
                    </ul>              
                    <p>{props.assignmentDetails.status == "submitted" ? props.assignmentDetails.status + ": " + props.assignmentDetails.submitTime : ""}</p>
                    <p>{props.assignmentDetails.status == "Graded" ? props.assignmentDetails.status + ": " + props.assignmentDetails.grade + " Scored" : "Yet to Grade"}</p>
                  </div>
                </div>
                    {fileDetails && fileDetails.map((file, i) => {
                        return (
                            <p>{file.name} uploaded</p>
                        )
                    })
                    }
                  <button className='assingment-submit' onClick={()=>handleSubmitAssignment()}>Submit</button>         
            </div>
        }
    </div>

      )
      }
      </React.Fragment>
  )
}

export default StudentAssignmentPop